import {map} from 'rxjs/operators';
import {Component} from "@angular/core";
import {PricingService} from "../service/pricing.services";
import {UserService} from "../service/user.service";


@Component({
  selector: 'credit-creator',
  templateUrl: './credit-creator.component.html',
})
export class CreditCreatorComponent {
  receiverUserId;
  receiverCompanyId;
  calculationType;
  calculationParams = {};
  validationType;
  validationParams = {};
  title;
  reason;
  calculationTypes;
  calculationParamFields;
  validationTypes;
  validationParamFields;

  constructor(private pricingService: PricingService,
              public userService:UserService) {

  }

  ngOnInit() {
    this.pricingService.getCreditCalculationTypes().pipe(
      map(types => {
        this.calculationType = types[0];
        this.fetchCalcParams();
        return types;
      }))
      .subscribe(types => this.calculationTypes = types);
    this.pricingService.getCreditValidationTypes().pipe(
      map(types => {
        this.validationType = types[0];
        this.fetchValidParams();
        return types;
      }))
      .subscribe(types => this.validationTypes = types);
  }

  fetchCalcParams() {
    this.pricingService.getCreditCalculationParams(this.calculationType)
      .subscribe(params => this.calculationParamFields = params);
  }

  fetchValidParams() {
    this.pricingService.getCreditValidationParams(this.validationType).subscribe(params => this.validationParamFields = params);
  }

  submit() {
    let receiverType = this.getReceiverType();
    //Construct the credit object as defined by the backend
    let credit = {
      reason: this.reason,
      title: this.title,
      calcType: this.calculationType,
      calcParams: this.calculationParams,
      validType: this.validationType,
      validParams: this.validationParams,
      receiverType: receiverType,
      receiverId: this.getReceiverId(receiverType)
    };
    //call the create method for the credit
    this.pricingService.createCredit(credit).subscribe(token => console.debug("The token is: " + token));
  }

  getReceiverType(): string {
    if (this.receiverUserId != undefined && this.receiverUserId != "")
      return "USER";
    else if (this.receiverCompanyId != undefined && this.receiverCompanyId != "")
      return "COMPANY";
    return "UNDEFINED"
  }

  getReceiverId(type: string): string {
    if (type == "USER")
      return this.receiverUserId;
    else
      return this.receiverCompanyId;
  }
}


