import {merge as observableMerge, Observable, of as observableOf} from 'rxjs';
import {defaultIfEmpty, filter, map, tap} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {getBaseUrl} from "../util/httpclient";


@Injectable()
export class LanguageService {

  languageMap: Map<string, string>;
  languageDialectMap: Map<string, string>;
  codeCacheMap = new Map<string, string>();
  charBasedCache;
  right2LeftCache;

  private LANGUAGE_API = getBaseUrl() + "/api/v1/languages";

  constructor(private http: HttpClient) {
    this.getLanguageMapObservable().subscribe(m => this.languageMap = m);
    this.getDialectLanguageMapObservable().subscribe(m => this.languageDialectMap = m);
  }

  getLanguages(): Observable<Array<string>> {
    return this.http.get<string[]>(this.LANGUAGE_API + "/codes")
  }

  getDialectLanguages(): Observable<Array<string>> {
    return this.http.get<string[]>(this.LANGUAGE_API + "/dialect/codes")
  }

  getCharBasedLanguages(): Observable<Array<string>> {
    if (this.charBasedCache == undefined)
      return this.http.get<string[]>(this.LANGUAGE_API + "/charbased/codes").pipe(tap(cb => {
        this.charBasedCache = cb;
      }));
    else return observableOf(this.charBasedCache);
  }

  getRight2LeftLanguages(): Observable<Array<string>> {
    if (this.right2LeftCache == undefined)
      return this.http.get<string[]>(this.LANGUAGE_API + "/right2left/codes").pipe(tap(r2l => {
        this.right2LeftCache = r2l;
      }));
    else return observableOf(this.right2LeftCache);
  }

  getLanguageMap(): Observable<Map<string, string>> {
    if (this.languageMap)
      return observableOf(this.languageMap);
    else
      return this.getLanguageMapObservable();
  }

  getDialectLanguageMap(): Observable<Map<string, string>> {
    if (this.languageDialectMap)
      return observableOf(this.languageDialectMap);
    else
      return this.getDialectLanguageMapObservable();
  }

  private getLanguageMapObservable(): Observable<Map<string, string>> {
    return this.http.get(this.LANGUAGE_API).pipe(map(this.arrayToMap));
  }

  private getDialectLanguageMapObservable(): Observable<Map<string, string>> {
    return this.http.get(this.LANGUAGE_API + "/dialect").pipe(map(this.arrayToMap));
  }

  codeToDescription(code: string): Observable<string> {
    if (this.codeCacheMap.has(code))
      return observableOf(this.codeCacheMap.get(code));
    return observableMerge(this.getLanguageMap(), this.getDialectLanguageMap()).pipe(
      filter(v => v.has(code)),
      map(v => v.get(code)),
      map(v => {
        this.codeCacheMap.set(code, v);
        return v;
      }),
      defaultIfEmpty(code));
  }

  //Maps cannot be simply constructed from a json string, that is why there is a workaround in place
  arrayToMap(a: Array<string>) {
    let map = new Map<string, string>();
    a
      .map(value => JSON.parse(value))
      .forEach(v => map.set(v.key, v.value));
    return map;
  }

}
