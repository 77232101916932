<h2 style="margin: 25px 0;">Mimic user</h2>

<div *ngIf="!userService.isCurrentUserAdmin">
  You do not have the correct privileges to access this page.
</div>

<div *ngIf="userService.isCurrentUserAdmin == true">
  <div class="mimicuser">
    <input type="text" placeholder="Enter mail of user to mimic"
           title="Enter mail of user to mimic"
           [(ngModel)]="mimicUserMail"/>
    <button [disabled]="!isMimicButtonValid()"
            (click)="mimicUser()">Mimic
    </button>
  </div>
</div>
