
	  <mat-dialog-content class="projectDialog">
		  <h3>Upload a PDF version of file</h3>
		  <h3 style="margin-bottom: 25px;">{{this.srcFile.name}}</h3>
		  <p>This PDF file will be available for the translator/reviser and provides them with greater context on the
			  content.<br/>
			  This will improve the quality of the translation.</p>
		  <div style="display: flex; justify-content: center; align-items: center; margin: 15px 0 5px 0; width: 100%;">
			  <label *ngIf="docPdf" style="font-size: 15px; padding: 3px;">
				  {{docPdf.name}} <span *ngIf="docPdf.size">({{docPdf.size | filesize}})</span>
			  </label>
		  <label *ngIf="isDocPdfFile()" for="uploadFile"
				 class="buttonLabel" style="color: white;" title="Select another file">Change</label>
			  <input id="uploadFile" type="file" (change)="selectFile($event)" placeholder="Upload PDF"
					 style="display: none;"
					 accept=".pdf, application/pdf"/>
		  </div>
		  <p *ngIf="errorMsg" style="color: red;">{{errorMsg}}</p>
		  <div class="buttons">
		  <label *ngIf="!docPdf" for="uploadFile"
				 class="buttonLabel confirm" style="color: white;" title="Select a PDF file">Select a PDF</label>
		  <label class="buttonLabel confirm" style="display: flex;" title="Remove uploaded PDF"
				 *ngIf="docPdf && !isDocPdfFile()" (click)="removeDocPdf()">
			  <span *ngIf="!isRemoving">Remove PDF</span>
			  <img *ngIf="isRemoving" src="../../../assets/loading_small_orb_whiteonblue.gif"
				   alt="Removing..." style="width: 15px;"/>
		  </label>
		  <button *ngIf="isDocPdfFile()" [disabled]="!isDocPdfFile()"
				  class="confirm" (click)="upload()">Upload & close
		  </button>
			  <button class="cancel" (click)="dialogRef.close()">Cancel</button>
		  </div>
	  </mat-dialog-content>