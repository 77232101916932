import * as tslib_1 from "tslib";
import { ElementRef, EventEmitter, OnInit } from "@angular/core";
import { FileService } from "../../service/file.service";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ErrorSnackbarComponent } from "../../error.snackbar.component";
import { Subject } from "rxjs";
import { sleep } from "../../util/apputil";
import { UploadDataService } from "./upload-data.service";
export class FileAcceptorComponent {
    constructor(fileService, uploadDataService, snackbar, dialog) {
        this.fileService = fileService;
        this.uploadDataService = uploadDataService;
        this.snackbar = snackbar;
        this.dialog = dialog;
        this.uploadedFiles = [];
        this.uploadedDocFiles = [];
        this.onFileUpload = new EventEmitter();
        this.onFileLanguageUpdate = new EventEmitter();
        this.onFileRemove = new EventEmitter();
        this.onDocFileUpload = new EventEmitter();
        this.fileUpload = "";
        this.docFileUpload = "";
        this.sourceLanguageCount = 0;
    }
    ngOnInit() {
        this.calcSourceLangCount();
    }
    uploadFiles(event) {
        let fileList = event.target.files;
        if (fileList.length > 0) {
            // Check for duplicate files
            for (var i = 0; i < fileList.length; i++) {
                let file = fileList[i];
                // Check if there is already a file with the same name in the project and show an error snackbar
                if (this.uploadedFiles.find(v => v.name === file.name)) {
                    let ref = this.snackbar.openFromComponent(ErrorSnackbarComponent, {
                        duration: 3000,
                        verticalPosition: "top",
                        data: {
                            errorText: `
                Sorry, you cannot add 2 files with the same name. <br/>
                A file with name <span style="font-weight: bold">${file.name}</span> already exists.`
                        }
                    });
                    return;
                }
            }
            // Loop and upload the files
            for (var i = 0; i < fileList.length; i++) {
                let file = fileList[i];
                // Create a subject to send progress events to
                const progress = new Subject();
                // Store the subject as observable in a data provider, so we keep track of it even when navigating away from the page
                this.uploadDataService.addUploadData(this.pId, file.name, progress);
                // We are uploading the source file in an async block so the rest of the application does not wait for the
                // upload to complete
                (() => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    // Upload the file through the file service
                    let start = new Date().getTime();
                    this.fileService.uploadProjectFileWithProgress(file, this.pId, null, progress).subscribe(data => {
                        let end = new Date();
                        console.log("Project " + this.pId + ": upload of " + file.name + " in " + (end.getTime() - start) + " ms");
                    }, error => console.error("Error uploading source file " + file.name + " => ", error), () => {
                        progress.complete();
                        this.uploadDataService.removeUploadData(this.pId, file.name);
                    });
                }))();
                this.onFileUpload.emit(file);
                this.uploadedFiles.push(file);
            }
        }
        this.fileUpload = null;
    }
    fakeProgress(prgrss, filename) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // todo: debug - remove when no longer necessary
            // todo: https://malcoded.com/posts/angular-file-upload-component-with-express
            let start = 10;
            while (start <= 100) {
                prgrss.next(start);
                start = start + 1;
                if (start % 25 === 0)
                    yield sleep(30 * 1000);
                else
                    yield sleep(500);
            }
            prgrss.complete();
            this.uploadDataService.removeUploadData(this.pId, filename);
        });
    }
    openDocUploadDialog() {
        let dialogRef = this.dialog.open(DocumentationUploadDialog, {
            width: '500px',
            height: '350px',
            data: { srcFiles: this.uploadedFiles, docFiles: this.uploadedDocFiles }
        });
        dialogRef.afterClosed().subscribe(files => {
            if (files)
                this.uploadDocFiles(files);
        });
    }
    uploadDocFiles(event) {
        let file = event.docfile;
        // Check if there is already a documentation file with the same name in the project and show an error if so
        if (this.uploadedDocFiles.find(v => v.name === file.name)) {
            let ref = this.snackbar.openFromComponent(ErrorSnackbarComponent, {
                duration: 3000,
                verticalPosition: "top",
                data: { errorText: 'Sorry, you cannot add 2 files with the same name.' }
            });
            return;
        }
        // We are uploading the documentation file in an async block so the rest of the application does not wait for the
        // upload to complete
        (() => tslib_1.__awaiter(this, void 0, void 0, function* () {
            // Upload the file through the file service
            let start = new Date().getTime();
            // 20 second delay to fake lengthy upload (only for debugging)
            // await sleep(20*1000);
            // If a source4pdf is specified, then we need to upload this as a "doc-PDF" (=PDF version of a source document)
            if (event.source4pdf != null)
                this.fileService.uploadPdfDocFile(file, this.pId, event.source4pdf).subscribe(data => {
                    let end = new Date();
                    console.log("Project " + this.pId + ": upload of " + file.name + " [pdf-documentation] in " + (end.getTime() - start) + " ms");
                    this.snackbar.open("Added documentation file '" + file.name + "' to project.", "", {
                        duration: 2000
                    });
                }, error => console.error("Error uploading documentation file", error));
            else
                this.fileService.uploadDocFile(file, this.pId).subscribe(data => {
                    let end = new Date();
                    console.log("Project " + this.pId + ": upload of " + file.name + " [documentation] in " + (end.getTime() - start) + " ms");
                    this.snackbar.open("Added documentation file '" + file.name + "' to project.", "", {
                        duration: 2000
                    });
                }, error => console.error("Error uploading documentation file", error));
        }))();
        this.onDocFileUpload.emit(event);
        this.docFileUpload = null;
    }
    getPdf4Source(file) {
        if (this.uploadedDocFiles) {
            return this.uploadedDocFiles.filter(f => f.docinfo != null && f.docinfo.source4pdf != null && f.docinfo.source4pdf === file.name)[0];
        }
        else
            return null;
    }
    removeFile(fileToRemove) {
        this.uploadedFiles.splice(this.uploadedFiles.indexOf(fileToRemove), 1);
        this.onFileRemove.emit(fileToRemove);
        this.calcSourceLangCount();
    }
    updateLanguage(file, $event) {
        let eventMsg = Object();
        eventMsg.filename = file.name;
        eventMsg.lang = $event;
        this.calcSourceLangCount();
        this.onFileLanguageUpdate.emit(eventMsg);
    }
    calcSourceLangCount() {
        this.sourceLanguageCount = this.uploadedFiles.map(f => f.sourcelang).filter(onlyUnique).length;
    }
    openFileBrowser() {
        if (this.uploadInput)
            this.uploadInput.nativeElement.click();
    }
}
function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
}
// TODO: enable "drag & drop" for adding files
// TODO: allow the user to add multiple files in the dialog (instead of only one)
// => TODO: allow the user to upload multiple files at once (by selecting a directory or a zip file)
// TODO: in case of a documentation-PDF being added, replace the previous one if there was any for the same source file?
export class DocumentationUploadDialog {
    constructor(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.srcFiles = [];
        this.docFiles = [];
    }
    ngOnInit() {
        if (this.data) {
            this.srcFiles = this.data.srcFiles;
            this.docFiles = this.data.docFiles;
        }
        this.errorMsg = null;
    }
    selectFile(event) {
        let fileList = event.target.files;
        if (fileList.length > 0) {
            let file = fileList[0];
            if (this.docFiles && this.docFiles.find(v => v.name === file.name)) {
                this.errorMsg = "Sorry, you cannot add 2 files with the same name, and a file with the name " + file.name + " already exists.";
                return;
            }
            this.errorMsg = null;
            this.docFile = file;
        }
    }
    isPdf() {
        if (this.docFile) {
            return this.docFile.name.toLowerCase().endsWith(".pdf");
        }
        return false;
    }
    upload() {
        this.dialogRef.close({
            docfile: this.docFile,
            source4pdf: this.selectedSource4Pdf ? this.selectedSource4Pdf.name : null
        });
    }
}
