import {AfterViewInit, Component, ElementRef, Input, ViewChild} from "@angular/core";
import {NgForm} from "@angular/forms";
import {InvoiceService} from "../service/invoice.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {UserService} from "../service/user.service";
import {getPaymentImage} from "../util/userutil";


@Component({
  selector: "payment-settings",
  templateUrl: './payment.setting.component.html',
  styleUrls: ['./user.settings.component.css']
})
export class PaymentSettingComponent implements AfterViewInit {

  @Input()
  user;

  @ViewChild('cardInfo', { static: true }) cardInfo: ElementRef;
  card: any;
  cardHandler = this.onChange.bind(this);
  error: string;

  currentCard;
  paymentImage: string;
  changeMode = false;

  constructor(public invoiceService: InvoiceService,
    public userService: UserService,
    private snackbar: MatSnackBar, ) {

  }

  ngOnInit() {
    this.currentCard = undefined;
    this.fetchPaymentMethod();
  }

  fetchPaymentMethod() {
    this.invoiceService.findPaymentMethod(this.user.id).subscribe(v => {
      if (v !== undefined && v.card !== undefined ) {
        this.currentCard = v.card;
        console.debug("User " + this.user.id + ": current card", this.currentCard);
        this.paymentImage = this.getPaymentImageUrl(this.currentCard.brand);
      }
    }
    )
  }

  onChange({ error }) {
    if (error) {
      this.error = error.message;
    } else {
      this.error = null;
    }
  }

  ngAfterViewInit() {
    this.card = elements.create('card');
    this.card.mount(this.cardInfo.nativeElement);
    this.card.addEventListener('change', this.cardHandler);
  }

  ngOnDestroy() {
    if (this.card != null) {
      this.card.removeEventListener('change', this.cardHandler);
      this.card.destroy();
    }
  }

  clearPaymentMethods() {
    this.invoiceService.clearPaymentMethod(this.user.id).subscribe(v => {
      this.currentCard = undefined;
      this.snackbar.open("Payment methods are cleared.", "", { duration: 2000 });
    }
    )
  }

  changePaymentMethods() {
    this.changeMode = true;
  }

  onSubmit(form: NgForm) {
    this.invoiceService.getPaymentIntent()
      .subscribe(sk => {
        let that = this;
        stripe.handleCardSetup(
          sk, this.card
        ).then(function (result) {
          if (result.error) {
            console.debug("Error setting up card for Stripe", result.error);
          } else {
            that.invoiceService.storeUserPaymentMethod(result).subscribe(() => {
              that.fetchPaymentMethod();
              that.snackbar.open('Payment method updated', '', { duration: 2000 })
            });
            that.changeMode = false;
          }
        })
      })
  }

  isTranslator(): boolean {
    return this.userService.isUserTranslator(this.user);
  }

  private getPaymentImageUrl(paymentMethod: string) {
    return './../../assets/paymentmethod/' + getPaymentImage(paymentMethod);
  }
}
