import {Pipe, PipeTransform} from '@angular/core';


@Pipe({name: 'filenamePipe'})
export class FilenamePipe implements PipeTransform {

  constructor() {

  }

  transform(value: string, length: number, keepExtension: boolean): string {
    if (value === undefined) {
      return '';
    }
    if(!keepExtension) {
      if (value.lastIndexOf('.') > 0) {
        value = value.slice(0, value.lastIndexOf('.'))
      }
    }
    let l = length;
    let preInterrupt = (l - 1) / 2;
    let postInterrupt = l - preInterrupt - 1;
    if (value.length <= l)
      return value;
    else {
      return value.substr(0, preInterrupt) + "…" + value.substr(value.length - postInterrupt)
    }
  }
}
