/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./util/safehtml.pipe";
import * as i2 from "@angular/platform-browser";
import * as i3 from "@angular/material/dialog";
import * as i4 from "@angular/common";
import * as i5 from "./simple-dialog.component";
var styles_SimpleDialog = [".mainDiv[_ngcontent-%COMP%] { display: flex; flex-direction: column; align-items: flex-start; background-color: inherit; }", ".mainDiv[_ngcontent-%COMP%]   h1[_ngcontent-%COMP%] { margin: 5px 0 10px 0; }", ".mainDiv[_ngcontent-%COMP%]   h4[_ngcontent-%COMP%] { font-size: 18px; margin: 5px 0 10px 0; }", ".dialogHtmlDiv[_ngcontent-%COMP%] { margin: 5px 0 10px 0; }", ".buttons[_ngcontent-%COMP%] { width: 100%; display: flex; align-items: center; justify-content: space-evenly; margin-top: 25px; }", ".dialogButton[_ngcontent-%COMP%] { flex: 1 1 auto; max-width: 45%; }"];
var RenderType_SimpleDialog = i0.ɵcrt({ encapsulation: 0, styles: styles_SimpleDialog, data: {} });
export { RenderType_SimpleDialog as RenderType_SimpleDialog };
function View_SimpleDialog_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dialogTitle; _ck(_v, 1, 0, currVal_0); }); }
function View_SimpleDialog_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dialogMessage; _ck(_v, 1, 0, currVal_0); }); }
function View_SimpleDialog_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "dialogHtmlDiv"]], [[8, "innerHTML", 1]], null, null, null, null)), i0.ɵppd(1, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 0, 0, _ck(_v, 1, 0, i0.ɵnov(_v.parent, 0), _co.dialogHtml)); _ck(_v, 0, 0, currVal_0); }); }
export function View_SimpleDialog_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i1.SafeHtmlPipe, [i2.DomSanitizer]), (_l()(), i0.ɵeld(1, 0, null, null, 10, "mat-dialog-content", [["class", "mainDiv mat-dialog-content"]], null, null, null, null, null)), i0.ɵdid(2, 16384, null, 0, i3.MatDialogContent, [], null, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SimpleDialog_1)), i0.ɵdid(4, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SimpleDialog_2)), i0.ɵdid(6, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SimpleDialog_3)), i0.ɵdid(8, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(9, 0, null, null, 2, "div", [["class", "buttons"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 1, "button", [["class", "dialogButton"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dialogRef.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(11, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dialogTitle; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.dialogMessage; _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.dialogHtml; _ck(_v, 8, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.dialogButtonName; _ck(_v, 11, 0, currVal_3); }); }
export function View_SimpleDialog_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "simple-dialog", [], null, null, null, View_SimpleDialog_0, RenderType_SimpleDialog)), i0.ɵdid(1, 114688, null, 0, i5.SimpleDialog, [i3.MatDialogRef, i3.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SimpleDialogNgFactory = i0.ɵccf("simple-dialog", i5.SimpleDialog, View_SimpleDialog_Host_0, {}, {}, []);
export { SimpleDialogNgFactory as SimpleDialogNgFactory };
