/**
 * This enum lists the possible statistical categories.
 * The name must match the name of the category returned from the server, and the sequence in which they are listed is
 * the sequence in which they'll appear on the project screen.
 */
export enum StatCategory {
  context_match,
  perfect_match,
  imperfect_match,
  fuzzy_match,
  mt_match,
  no_match
}

export function getStatCategory(catName: string): StatCategory {
  let n = catName.toLowerCase().trim();
  return StatCategory[n];
}

export function isStatCategory(catName: string, statCat: StatCategory): boolean {
  return getStatCategory(catName) === statCat;
}

export function getCategoryDesc(catName: string) {
  switch (getStatCategory(catName)) {
    case StatCategory.context_match:
      return "Context match";
    case StatCategory.perfect_match:
      return "100% match";
    case StatCategory.imperfect_match:
      return "99% match";
    case StatCategory.fuzzy_match:
      return "Fuzzy match";
    case StatCategory.mt_match:
      return "MT match";
    default:
      return "No match"
  }
}

export function getDefaultPercentage(catName: string): number {
  switch (getStatCategory(catName)) {
    case StatCategory.context_match:
      return 0;
    case StatCategory.perfect_match:
      return 10;
    case StatCategory.imperfect_match:
      return 30;
    case StatCategory.fuzzy_match:
      return 50;
    case StatCategory.mt_match:
      return 85;
    default:
      return 100
  }
}
