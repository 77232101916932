import {filter} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {UserSigninDialog} from "../usercomponent/user.signin.component";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {UserJoinDialog} from "../usercomponent/user.join.component";
import {SimpleDialog} from "../simple-dialog.component";
import {UserService} from "./user.service";
import {JoinDialogOutcome, JoinDialogResult, JoinType} from "../usercomponent/user.join.model";

@Injectable()
export class LogInService {

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private userService: UserService) {
  }

  public openSignInPopup(callback: string = undefined): MatDialogRef<UserSigninDialog> {
    let dialogRef = this.dialog.open(UserSigninDialog, {
      width: '300px'
    });
    dialogRef.afterClosed().pipe(filter(r => r == "join")).subscribe(r => this.openJoinDialog(callback));
    dialogRef.afterClosed().subscribe(result => console.log(result));
    return dialogRef;
  }

  public openJoinDialog(callback?: string, companyToken?: string, groupToken?: string): MatDialogRef<UserJoinDialog> {
    if (callback === undefined || callback.length === 0) {
      callback = this.router.url
    }
    let dialogRef = this.dialog.open(UserJoinDialog, {
      width: '300px',
      data: {callback: callback, companyToken: companyToken, groupToken: groupToken}
    });
    dialogRef.afterClosed().pipe(
      filter(r => r != null),
      filter(r => r.result == JoinDialogOutcome.SIGN_IN)
    ).subscribe(r => this.openSignInPopup());
    dialogRef.afterClosed().pipe(
      filter(r => r != null),
      filter(r => r.result == JoinDialogOutcome.SUCCESS && r.join == JoinType.MAIL)
    ).subscribe(r => {
      let successText = "An email has been sent to your email address.\n" +
        "Please check your inbox and click the link to activate your account.";
      this.dialog.open(SimpleDialog, {
        width: '300px',
        panelClass: 'simpleDialog',
        data: {dialogMessage: successText}
      });
      this.checkAndAcceptInvite(r);
    });
    dialogRef.afterClosed().pipe(
      filter(r => r != null),
      filter(r => r.result == JoinDialogOutcome.ERROR)
    ).subscribe(r => {
      let errorText = 'Unable to log in.<br/><small>Are you already registered maybe?</small>';
      this.dialog.open(SimpleDialog, {
        width: '300px',
        panelClass: 'simpleDialog',
        data: {dialogMessage: errorText}
      });
      console.error("Error during join attempt", r.reason);
    });
    return dialogRef;
  }

  private checkAndAcceptInvite(dialogResult: JoinDialogResult): void {
    // Check whether there's an invite ID, and if so, mark it as accepted
    let inviteId = sessionStorage.getItem("inviteId");
    if (inviteId != undefined && dialogResult.user != undefined && dialogResult.user.id != undefined) {
      this.userService.acceptInvite(inviteId, dialogResult.user.id).subscribe(
        a => console.debug("User " + dialogResult.user.id + ": invite accepted", a)
      );
    }
  }
}
