export const environment = {
  production: false,
  host: 'gatewaystaging.lilo.global',
  protocol: 'https',
  port: '443',
  localPort: undefined,
  socketHost: 'gatewaystaging.lilo.global',
  socketProtocol: 'wss',
  socketPort: '443',
  isDebug: true,
  stripeKeyPub: 'pk_test_37TjVQZOo7qfZojPi2w99k9H',
  callbackURI: 'https://staging.lilo.global/cb',
  appURI:'https://staging.lilo.global'
};
