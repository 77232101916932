/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../projectcomponent/project.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./hubspot-page.component";
var styles_HubspotPageComponent = [i0.styles];
var RenderType_HubspotPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HubspotPageComponent, data: {} });
export { RenderType_HubspotPageComponent as RenderType_HubspotPageComponent };
function View_HubspotPageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u2713"]))], null, null); }
function View_HubspotPageComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "a", [], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["link"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.page.absolute_url, ""); _ck(_v, 1, 0, currVal_0); }); }
function View_HubspotPageComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["style", "width: 200px"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.page.screenshot_preview_url, ""); _ck(_v, 0, 0, currVal_0); }); }
function View_HubspotPageComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["style", "width: 200px"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.masterPage.screenshot_preview_url, ""); _ck(_v, 0, 0, currVal_0); }); }
export function View_HubspotPageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "module"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleSelect() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HubspotPageComponent_1)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HubspotPageComponent_2)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HubspotPageComponent_3)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HubspotPageComponent_4)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.isSelected; _ck(_v, 4, 0, currVal_1); var currVal_3 = _co.page.absolute_url; _ck(_v, 8, 0, currVal_3); var currVal_4 = _co.page.screenshot_peview_url; _ck(_v, 10, 0, currVal_4); var currVal_5 = ((!_co.page.screenshot_peview_url && _co.masterPage) && _co.masterPage.screenshot_preview_url); _ck(_v, 12, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.page.page_title; _ck(_v, 2, 0, currVal_0); var currVal_2 = _co.page.id; _ck(_v, 6, 0, currVal_2); }); }
export function View_HubspotPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "hubspot-page", [], null, null, null, View_HubspotPageComponent_0, RenderType_HubspotPageComponent)), i1.ɵdid(1, 114688, null, 0, i3.HubspotPageComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HubspotPageComponentNgFactory = i1.ɵccf("hubspot-page", i3.HubspotPageComponent, View_HubspotPageComponent_Host_0, { page: "page", allPages: "allPages" }, { onSelect: "onSelect" }, []);
export { HubspotPageComponentNgFactory as HubspotPageComponentNgFactory };
