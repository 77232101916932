import {environment} from "../../environments/environment";


export function getBaseUrl() {
  let protocol = environment.protocol;
  let host = environment.host;
  let port = environment.port;
  return constructUrl(host, protocol, port);
}

export function getBaseSocketUrl() {
  let protocol = environment.socketProtocol;
  let host = environment.socketHost;
  let port = environment.socketPort;
  return constructUrl(host, protocol, port) + "/wsocket";
}

export function constructUrl(host: string, protocol: string, port: string) {
  return (protocol != undefined ? (protocol.endsWith("://") ? protocol : protocol + "://") : "http://") +
    host +
    (port != undefined ? ":" + port : "");
}

/**
 * This function is to normalize any diacritics in the file's name (eg. ë, ú, ã, ...).
 *
 * Sometimes these characters are interpreted as a character plus the diacritic: for example e+¨ (e%CC%88) versus ë as
 * one character (%C3%AB). Since these two interpretations are encoded differently, and since the backend always interprets
 * these characters "as one", we need to normalize the file's name here to reflect this. So any "base character + diacritic"
 * situation gets changed into its respective, single character variant.
 * Otherwise, the gateway would receive e+¨ as "e%CC%88", decode it, and encode it again as %C3%AB, which would cause
 * the file to not be found.
 *
 * Using this function is only really necessary for new files that get uploaded. Once they're uploaded the file names
 * should be set correctly.
 *
 * @see https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/normalize
 * @param fileName
 */
export function normalizeFileName(fileName: string) {
  return fileName.normalize('NFC');
}
