<div class="rating-full" *ngIf="userId">
  <div *ngFor="let review of reviews" class="review">
    <div style="display: flex">
      <div style="display: flex">
        <div class="user" style="display: flex">
          <img class="mugshot" src={{mugshotURL()}}>
          <div>
            <h4 *ngIf="review.writer">{{review.writer.name}}</h4>
            <h5>{{review.createiondate}}</h5>
          </div>
        </div>
      </div>
      <div class="stars">
        <div *ngFor="let score of review.scores">
          <div>
            <label>{{score.category.name}}</label>
            <rating [rating]="score.rate" style="float: right"></rating>
          </div>
        </div>
      </div>
    </div>
    <div class="posts">
      <div class="post">
        <p>
          {{review.comment}}
        </p>
      </div>
    </div>
  </div>
</div>
