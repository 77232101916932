/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./index-main.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../dropentrycomponent/pre-upload.component.ngfactory";
import * as i4 from "../dropentrycomponent/pre-upload.component";
import * as i5 from "../service/project.service";
import * as i6 from "../service/file.service";
import * as i7 from "./util/upload-data.service";
import * as i8 from "../service/user.service";
import * as i9 from "../service/converter.service";
import * as i10 from "@angular/router";
import * as i11 from "./index-main.component";
var styles_IndexMainComponent = [i0.styles];
var RenderType_IndexMainComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_IndexMainComponent, data: {} });
export { RenderType_IndexMainComponent as RenderType_IndexMainComponent };
function View_IndexMainComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "maintext"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Hiring translation pros has never been easier"])), (_l()(), i1.ɵeld(3, 0, null, null, 7, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Pay less for better quality;"])), (_l()(), i1.ɵeld(5, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Connect directly with native translators;"])), (_l()(), i1.ɵeld(8, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Upload your file, watch lilo work its magic and get a free instant quote. "]))], null, null); }
function View_IndexMainComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "backImage"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["src", "../../assets/artwork/art.png"]], null, null, null, null, null))], null, null); }
export function View_IndexMainComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "main-component"], ["style", "position: relative"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_IndexMainComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_IndexMainComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "fileupload"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "pre-upload", [], null, null, null, i3.View_PreUploadComponent_0, i3.RenderType_PreUploadComponent)), i1.ɵdid(7, 114688, null, 0, i4.PreUploadComponent, [i5.ProjectService, i6.FileService, i7.UploadDataService, i8.UserService, i9.ConverterService, i10.Router], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.size != "small"); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.size != "small"); _ck(_v, 4, 0, currVal_1); _ck(_v, 7, 0); }, null); }
export function View_IndexMainComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "index-main", [], null, null, null, View_IndexMainComponent_0, RenderType_IndexMainComponent)), i1.ɵdid(1, 49152, null, 0, i11.IndexMainComponent, [], null, null)], null, null); }
var IndexMainComponentNgFactory = i1.ɵccf("index-main", i11.IndexMainComponent, View_IndexMainComponent_Host_0, { size: "size" }, {}, []);
export { IndexMainComponentNgFactory as IndexMainComponentNgFactory };
