<div *ngIf="currentCard !== undefined" style="display: flex;">
  <div style="width: 100%;">
    <label>Current card:</label>
    <span
      style="font-size: small; height: 30px; display: flex; align-items: center;"
    >
      <img
        *ngIf="paymentImage"
        class="cardImage"
        src="{{ paymentImage }}"
        title="{{ currentCard.brand }}"
        alt="{{ currentCard.brand }}"
      />
      <span style="font-weight: bold;"
        >XXXX XXXX XXXX {{ currentCard.last4 }}</span
      >
    </span>
  </div>
  <label (click)="changeCardInfo()" style="cursor: pointer; ">change</label>
</div>
<div [hidden]="currentCard !== undefined">
  <form
    #payment="ngForm"
    (ngSubmit)="storeCreditCard()"
    class="checkout"
    style="width:350px;"
  >
    <div class="form-row">
      <div id="card-info" #cardInfo style="padding-top: 14px; min-width: 350px;"></div>
      <div id="card-errors" role="alert" *ngIf="error">{{ error }}</div>
    </div>
  </form>
</div>
