<div *ngIf="translator !== undefined" class="translatorCardFlipContainer"
     [ngClass]="{translatorCardSelectedFlipContainer: isSelected, cardFlipped: flipped, lastCard: isLast}">

  <div class="translatorCardFlipper">

    <div [ngClass]="{translatorCard: true, selectedTranslator: isSelected, translatorCardFront: true}">
      <div
        [ngClass]="{translatorType: true, translatorTypeSpecial: translator.isRecommended != undefined || translator.isSuggested != undefined || translator.internal == true}">
        <p *ngIf="translator.internal">internal</p>
        <div *ngIf="translator.internal != true">
          <p *ngIf="translator.isRecommended">recommended</p>
          <p *ngIf="translator.isSuggested">suggested</p>

        </div>
      </div>
      <div class="translatorMainInfo" (click)="selectTranslator()">
        <div class="profiletop">
          <span *ngIf="isNative()" title="This is a native speaker.">NATIVE</span>&nbsp;
        </div>
        <div class="profilepic" title="{{translator.name}}}">
          <mugshot [user]="translator" [width]="'100px'" [height]="'100px'" [showIncomplete]="false"></mugshot>
        </div>
        <div class="profileName" title="{{translator.name}}">
          <h5>{{translator.firstName|filenamePipe:20:true}}<br/>
            {{translator.lastName|filenamePipe:20:true}}</h5>
        </div>
        <div class="availability">
          <span *ngIf="daysUnavailable > 0" [title]="unavailabilityTitle()">Limited availability
            <img src="../../../assets/icon_warning-info.svg" />
          </span>
        </div>
        <div *ngIf="translator['internal']==false" class="responsiveness">
      <span *ngIf="translator.responsiveness != undefined">Replies within: <span
        class="responsivenessValue">{{translator.responsiveness}}</span></span>
        </div>
        <div *ngIf="translator['internal']==false" class="rating">
          <rating-small-component [rating]=translator.rating
                                  [reviewCount]=translator.reviewcount></rating-small-component>
        </div>
      </div>
      <div [ngClass]="{translatorPrice: true, internalPrice: translator['internal']}" *ngIf='!isQuote'>
        <!--Notify that service fee is included for internals-->
        <p class="servicefeelabel" *ngIf='translator!=null && translator["internal"]'>Service fee</p>
        <h6>
          <!--todo: add loading icon to price label (until all stats are in?)-->
          <price [price]="getPrice()" [undefinedDefault]="'--'"></price>
        </h6>
      </div>
      <div class="flipbutton" (click)="flipped=!flipped">
        <img src="../../../assets/icon_more-info.svg" alt="More info" title="More info">
      </div>
    </div>

    <div [ngClass]="{translatorCard: true, selectedTranslator: isSelected, translatorCardBack: true}">
      <div
        [ngClass]="{translatorType: true, translatorTypeSpecial: translator.isRecommended != undefined || translator.isSuggested != undefined}">
        <p *ngIf="translator.isRecommended">recommended</p>
        <p *ngIf="translator.isSuggested">suggested</p>
      </div>
      <div class="translatorMainInfo" (click)="selectTranslator()">
        <div class="description">
          <p
            *ngIf="translator.description == undefined || translator.description == null || translator.description.length == 0"
            style="color:var(--dark-gray); margin: 10px 0 25px 0;">No description.</p>
          <p
            *ngIf="translator.description != undefined && translator.description != null && translator.description.length > 0">
            {{translator.description|textcutPipe:300}}
          </p>
          <a style="font-size: 14px; color: var(--second-color); text-decoration: underline;"
            routerLink="/profile/{{translator.id}}" routerLinkActive="active"
            target="blank"
             >read more</a>
        </div>
        <div *ngIf="translator.domains && translator['internal'] == false" class="domains">
          <p *ngFor="let domain of translator.domains">{{domain}}</p>
        </div>
      </div>
      <div [ngClass]="{translatorPrice: true, internalPrice: translator['internal']}" *ngIf='!isQuote'>
        <!--Notify that service fee is included for internals-->
        <p class="servicefeelabel" *ngIf='translator!=null && translator["internal"]'>Service fee</p>
        <h6>
          <!--todo: add loading icon to price label (until all stats are in?)-->
          <price [price]="getPrice()" [undefinedDefault]="'--'"></price>
        </h6>
      </div>
      <div class="flipbutton" (click)="flipped=!flipped">
        <img src="../../../assets/icon_turn-card.svg" alt="Flip card" title="Flip card">
      </div>
    </div>

  </div>
</div>
