export interface DynamicTableHeader {
  key: string;
  value: string;
  index?: number;
  isSelected?: boolean;
  type?: ColumnType;
}

export interface DynamicTableModel {
  headers: DynamicTableHeader[];
  data: any[];
  selected: boolean[];
}

export enum ColumnType {
  DATE
}
