<div *ngIf="project" class="projectCard">
  <div class="frontpageDiv">
    <img *ngIf="frontpageImage" [src]="frontpageImage" class="page" alt="File preview" [@fadeInOut]>
    <img *ngIf="!frontpageImage" [src]="docTypeImage" class="page" alt="File type icon"
         (error)="dtimg.src = getUnknownDoctypeImage()" #dtimg [@fadeInOut]>
    <!-- fake 'pages' (max 5) -->
    <div *ngFor="let f of filePageStack; index as i" class="page fakePage"
         [style.top.px]="36+(i*5)" [style.left.px]="8+(i*8)" [style.z-index]="5-i"></div>
  </div>
  <div class="titleDiv">
    <p class="status" [ngStyle]="{'color': getStatusColor()}">{{status}}</p>
    <p class="title">{{project.title|filenamePipe:19:true}}</p>
  </div>
  <div class="projectCardHover">
    <p class="status">{{status}}</p>
    <p class="title">{{project.title|filenamePipe:19:true}}</p>
    <p class="status">
      {{sourceLanguage? sourceLanguage : "--"}}
      <span style="font-size: 14px;">&#x25B6;</span>
      {{getTargetLangDisplay()}}</p>
  </div>
</div>
