import {
  Component,
  ElementRef,
  Input,
  OnInit,
  QueryList,
  Renderer2,
  SimpleChanges,
  ViewChild,
  ViewChildren
} from "@angular/core";
import {Task} from "../dto/job";
import {AcceptDialog, RejectDialog} from "./task-detail.component";
import {TaskService} from "../service/task.service";
import {ProjectService} from "../service/project.service";
import {MatDialog} from '@angular/material';
import {filter} from 'rxjs/operators';
import {Router} from '@angular/router';
import {SERVICE_SWORN_TRANSLATION} from "../mock-activities";
import {getSourceFiles, getUserName} from "../util/jobutil";
import {LanguageService} from "../service/language.service";
import {StatisticsService} from "../service/statistics.service";
import {getCategoryDesc, StatCategory} from "../util/statutil";
import {Project} from '../dto/project';


type DateRow = { label: string, value: Date, color?: string };

@Component({
  selector: 'task-overview',
  templateUrl: './task-overview.component.html',
  styleUrls: ['./task.component.css'],
})
export class TaskOverviewComponent implements OnInit {

  @Input() task: Task;
  @Input() project: Project;
  @Input() revisionNeeded = false;

  activityName: string;
  totalWordcount: number = undefined;
  taskStats: Map<string, number>;
  statArray;
  charBasedSource: boolean = false;
  flipped = false;

  @ViewChildren("taskOverviewFront", {read: ElementRef})
  frontPane: QueryList<any>;
  @ViewChild("taskOverviewBack", {read: ElementRef, static: false})
  backPane: ElementRef<any>;
  @ViewChild("taskOverviewFake", {read: ElementRef, static: false})
  fakePane: ElementRef<any>;

  constructor(private taskService: TaskService,
              private projectService: ProjectService,
              private statisticsService: StatisticsService,
              private languageService: LanguageService,
              private dialog: MatDialog,
              private renderer: Renderer2,
              private router: Router) {

  }

  ngOnInit(): void {
    this.initProject();
    this.determineCharBasedSource();
  }

  ngAfterViewInit(): void {
    this.calcPaneSizes();
    // Debounce so the view has time to update to proper size
    this.frontPane.changes.debounceTime(50)
      .subscribe((p: QueryList<any>) => {
        this.calcPaneSizes();
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.task) {
      this.task = changes.task.currentValue;
      this.determineCharBasedSource();
    }
    if (changes.project) {
      this.project = changes.project.currentValue;
      this.initProject();
    }
  }

  private calcPaneSizes(): void {
    let maxHeight = this.frontPane.first.nativeElement.offsetHeight;
    this.setPaneHeight(maxHeight, this.backPane);
    this.setPaneHeight(maxHeight, this.fakePane);
  }

  private setPaneHeight(height: number, pane: any): void {
    if (pane.nativeElement.offsetHeight != height) {
      var heightPx = height + "px";
      this.renderer.setStyle(pane.nativeElement, "height", heightPx);
    }
  }

  getTaskDates(): DateRow[] {
    if (this.task) {
      if (this.task.canceldate) {
        // Task was canceled by customer
        return [{label: 'Canceled on', value: this.task.canceldate}];
      } else if (this.task.completed) {
        // Task is completed: show expected and completion date
        let dates: DateRow[] = [{label: 'To deliver by', value: this.task.expecteddate, color: undefined}];
        if (this.task.completed > this.task.expecteddate)
          dates.push({label: 'Delivered on', value: this.task.completed, color: 'orange'});
        else dates.push({label: 'Delivered on', value: this.task.completed, color: undefined});
        return dates;
      } else if (this.task.rejectdate) {
        // Task is rejected: show send and reject date
        return [
          {label: 'Received on', value: this.task.senddate},
          {label: 'Declined on', value: this.task.rejectdate}
        ];
      } else if (this.task.acceptdate) {
        // Task is accepted: show accept and expected date
        return [
          {label: 'Accepted on', value: this.task.acceptdate},
          {label: 'To deliver by', value: this.task.expecteddate}
        ];
      } else {
        // Task is not accepted or rejected yet: show send and due date
        return [
          {label: 'Received on', value: this.task.senddate},
          {label: 'Project due by', value: this.task.duedate}
        ];
      }
    }
    return [];
  }

  isToAccept(): boolean {
    return !this.task.acceptdate && !this.task.rejectdate && !this.task.canceldate;
  }

  isAccepted(): boolean {
    return this.task.acceptdate && !this.task.canceldate;
  }

  isCompleted(): boolean {
    return this.task.completed && !this.task.canceldate;
  }

  initProject(): void {
    if (this.project) {
      this.initTotalWordcount();
      this.initStats();
    } else {
      this.totalWordcount = undefined;
    }
  }

  private initTotalWordcount(): void {
    this.totalWordcount = 0;
    getSourceFiles(this.project)
      .forEach(f => {
        if (f.wordcount != undefined)
          this.totalWordcount = this.totalWordcount + parseInt(f.wordcount);
      });
  }

  private initStats(): void {
    this.statisticsService.getTaskStatistics(this.task.id)
      .subscribe(stats => {
        this.taskStats = stats;
        this.statArray = [];
        Object.keys(StatCategory)
          .forEach((v, i) => {
            if (this.taskStats.get(v) != null) {
              this.statArray.push({label: getCategoryDesc(v), value: this.taskStats.get(v)})
            }
          });
      });
  }

  private determineCharBasedSource(): void {
    this.languageService.getCharBasedLanguages().subscribe(cbl => {
      if (cbl && this.task)
        this.charBasedSource = cbl.indexOf(this.task.sourcelanguage) >= 0;
      else this.charBasedSource = false;
    });
  }

  getRequesterName(): string {
    return getUserName(this.project.requestedby);
  }

  isSwornTranslation(): boolean {
    return this.task.service === SERVICE_SWORN_TRANSLATION
  }

  acceptTask(): void {
    if (this.totalWordcount == undefined) this.initTotalWordcount();
    let suggestedDate = new Date();
    if (this.totalWordcount != undefined) {
      //Calculated the time needed from now to do the task
      //A translator typically handles 300 words per hour
      let hoursNeeded = Math.ceil(this.totalWordcount / 300);
      //Since they normally only work for 8 hours a day, we need to re calculate the amount of hours
      hoursNeeded = (Math.floor(hoursNeeded / 8) * 24) + (hoursNeeded % 8);
      if (hoursNeeded == 0) hoursNeeded = 1;
      //The suggested date is thus the current time + the amount of hours needed
      suggestedDate.setHours(suggestedDate.getHours() + hoursNeeded, suggestedDate.getMinutes(), 0, 0);
    } else {
      // Add 48 hours by default if no word count can be found
      suggestedDate.setHours(suggestedDate.getHours() + 48, 0, 0, 0);
    }
    let taskDueDate = new Date(this.task.duedate);
    // Only set the suggested date to the task due date if the latter is not in the past
    // This forces the translator to propose an alternative delivery date if that's the case
    let now = new Date();
    if (suggestedDate > taskDueDate && now < taskDueDate)
      suggestedDate = taskDueDate;
    let dialogRef = this.dialog.open(AcceptDialog, {
      width: '500px',
      maxHeight: '70%',
      data: {
        message: "", date: suggestedDate, dueDate: taskDueDate, swornTranslation: this.isSwornTranslation(),
        revisionNeeded: this.revisionNeeded
      }
    });
    dialogRef.afterClosed().pipe(filter(r => r === "accept")).subscribe(r => {
        this.taskService.acceptTask(this.task.id, dialogRef.componentInstance.date).subscribe(res => {
          this.task = res;
        })
      }
    );
    dialogRef.afterClosed().pipe(filter(r => r === "counterproposal")).subscribe(r => {
      this.taskService.counterProposeTask(this.task, dialogRef.componentInstance.proposal)
        .subscribe(res => {
          this.router.navigate(['/tdashboard'])
        });
      console.debug("Counter proposal has been made with: " + JSON.stringify(dialogRef.componentInstance.proposal));
    });
  }

  rejectTask(): void {
    let dialogRef = this.dialog.open(RejectDialog, {
      width: '600px',
      height: '475px',
      data: {
        message: "", placeholder: "Bad timing, not my expertise, too small, try my colleague X, ...",
        langpair: this.task.sourcelanguage + "_" + this.task.targetlanguage,
        activity: this.activityName
      }
    });
    dialogRef.afterClosed().pipe(
      filter(t => t == "success"))
      .subscribe(result => {
        this.taskService.rejectTask(this.task.id, dialogRef.componentInstance.message, dialogRef.componentInstance.suggestedColleague)
          .subscribe(res => {
            this.task = res;
            this.router.navigate(['/tdashboard']);
          });
      });
    dialogRef.afterClosed().pipe(
      filter(t => t == "counterproposal"))
      .subscribe(result => {
        this.taskService.counterProposeTask(this.task, dialogRef.componentInstance.counterProposal)
          .subscribe(res => {
            this.router.navigate(['/tdashboard'])
          });
        console.debug("Counter proposal has been made with: " + JSON.stringify(dialogRef.componentInstance.counterProposal));
      })
  }
}
