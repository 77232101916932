import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';


@Component({
  selector: 'help-dialog',
  template: `
    <h2 mat-dialog-title>
      <img src="../../assets/icons8-help-32.png" style="vertical-align: bottom;"/>
      {{getTitle()}}
    </h2>
    <mat-dialog-content>
      <div id="helptext">
        <div *ngIf="helpHtmlContent" [innerHTML]="helpHtmlContent | safeHtml"></div>
        <!--Below code is to embed a PDF--><!--
        <object data="../../assets/test.pdf" type="application/pdf" width="100%" height="100%">
            <iframe src="../../assets/test.pdf" style="border: none;" width="100%" height="100%">
                This browser does not support PDFs. Please download the PDF to view it: <a href="/pdf/sample-3pp.pdf">Download PDF</a>
            </iframe>
        </object>-->
        <!--Below code is to embed an HTML-->
        <iframe *ngIf="helpHtmlUrl" style="width: 100%; height: 300px;"
                src="{{helpHtmlUrl}}"></iframe>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions>
      <div style="width: 100%; text-align: center;">
        <button (click)="closeDialog()">Close</button>
      </div>
    </mat-dialog-actions>
  `,
  styleUrls: [
    './help.dialog.component.css'
  ]
})
export class HelpDialogComponent {

  helpTitle: string;
  helpHtmlUrl: string;
  helpHtmlContent: string;

  constructor(private dialogRef: MatDialogRef<any>,
              @Inject(MAT_DIALOG_DATA) private data: any) {
  }

  ngOnInit() {
    if (this.data) {
      this.helpHtmlUrl = this.data.helpHtmlUrl;
      this.helpHtmlContent = this.data.helpHtmlContent;
      this.helpTitle = this.data.helpTitle;
    }
  }

  getTitle() {
    if (this.helpTitle)
      return this.helpTitle;
    else return "Help";
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
