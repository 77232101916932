/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../project.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/forms";
import * as i4 from "./credit-card.component";
import * as i5 from "../../service/invoice.service";
var styles_CreditCardComponent = [i0.styles];
var RenderType_CreditCardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CreditCardComponent, data: {} });
export { RenderType_CreditCardComponent as RenderType_CreditCardComponent };
function View_CreditCardComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["class", "cardImage"]], [[8, "src", 4], [8, "title", 0], [8, "alt", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.paymentImage, ""); var currVal_1 = i1.ɵinlineInterpolate(1, "", _co.currentCard.brand, ""); var currVal_2 = i1.ɵinlineInterpolate(1, "", _co.currentCard.brand, ""); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_CreditCardComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["style", "display: flex;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["style", "width: 100%;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Current card:"])), (_l()(), i1.ɵeld(4, 0, null, null, 4, "span", [["style", "font-size: small; height: 30px; display: flex; align-items: center;"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CreditCardComponent_2)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [["style", "font-weight: bold;"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["XXXX XXXX XXXX ", ""])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "label", [["style", "cursor: pointer; "]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changeCardInfo() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["change"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.paymentImage; _ck(_v, 6, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.currentCard.last4; _ck(_v, 8, 0, currVal_1); }); }
function View_CreditCardComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["id", "card-errors"], ["role", "alert"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.error; _ck(_v, 1, 0, currVal_0); }); }
export function View_CreditCardComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { cardInfo: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CreditCardComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 9, "div", [], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 8, "form", [["class", "checkout"], ["novalidate", ""], ["style", "width:350px;"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngSubmit"], [null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 6).onReset() !== false);
        ad = (pd_1 && ad);
    } if (("ngSubmit" === en)) {
        var pd_2 = (_co.storeCreditCard() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 16384, null, 0, i3.ɵangular_packages_forms_forms_z, [], null, null), i1.ɵdid(6, 4210688, [["payment", 4]], 0, i3.NgForm, [[8, null], [8, null]], null, { ngSubmit: "ngSubmit" }), i1.ɵprd(2048, null, i3.ControlContainer, null, [i3.NgForm]), i1.ɵdid(8, 16384, null, 0, i3.NgControlStatusGroup, [[4, i3.ControlContainer]], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 3, "div", [["class", "form-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, [[1, 0], ["cardInfo", 1]], null, 0, "div", [["id", "card-info"], ["style", "padding-top: 14px; min-width: 350px;"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CreditCardComponent_3)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.currentCard !== undefined); _ck(_v, 2, 0, currVal_0); var currVal_9 = _co.error; _ck(_v, 12, 0, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.currentCard !== undefined); _ck(_v, 3, 0, currVal_1); var currVal_2 = i1.ɵnov(_v, 8).ngClassUntouched; var currVal_3 = i1.ɵnov(_v, 8).ngClassTouched; var currVal_4 = i1.ɵnov(_v, 8).ngClassPristine; var currVal_5 = i1.ɵnov(_v, 8).ngClassDirty; var currVal_6 = i1.ɵnov(_v, 8).ngClassValid; var currVal_7 = i1.ɵnov(_v, 8).ngClassInvalid; var currVal_8 = i1.ɵnov(_v, 8).ngClassPending; _ck(_v, 4, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }); }
export function View_CreditCardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "credit-card", [], null, null, null, View_CreditCardComponent_0, RenderType_CreditCardComponent)), i1.ɵdid(1, 4440064, null, 0, i4.CreditCardComponent, [i5.InvoiceService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CreditCardComponentNgFactory = i1.ɵccf("credit-card", i4.CreditCardComponent, View_CreditCardComponent_Host_0, { userId: "userId" }, { cardChangeEmitter: "cardChangeEmitter" }, []);
export { CreditCardComponentNgFactory as CreditCardComponentNgFactory };
