/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./tooltip.component";
var styles_TooltipComponent = [".tooltip-container[_ngcontent-%COMP%] {\n        background-color: black;\n        color: #fff;\n        display: inline-block;\n        padding: 0.5em;\n        position: fixed;\n        \n        transition: all ease 0.8s;\n        visible: hidden;\n      }"];
var RenderType_TooltipComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_TooltipComponent, data: {} });
export { RenderType_TooltipComponent as RenderType_TooltipComponent };
export function View_TooltipComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { tooltipContainer: 0 }), (_l()(), i0.ɵeld(1, 0, [[1, 0]], null, 2, "div", [["class", "tooltip-container"]], [[4, "top", null], [4, "left", null], [4, "visibility", null]], null, null, null, null)), i0.ɵdid(2, 16384, null, 0, i1.TooltipContainerDirective, [], null, null), i0.ɵncd(null, 0)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.top; var currVal_1 = _co.left; var currVal_2 = _co.visible; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_TooltipComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_TooltipComponent_0, RenderType_TooltipComponent)), i0.ɵdid(1, 114688, null, 0, i1.TooltipComponent, ["tooltipConfig"], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TooltipComponentNgFactory = i0.ɵccf("ng-component", i1.TooltipComponent, View_TooltipComponent_Host_0, {}, {}, ["*"]);
export { TooltipComponentNgFactory as TooltipComponentNgFactory };
