<div>
  <h1>Availability</h1>
  <div class="row">
    <p style="margin: 20px 0;">To improve the service toward your customers, it is important that they know when you are
      available and when you
      are not.<br/>
      Below you can specify when you are not able to take on tasks. You can optionally enter a 'description' to
      clarify as to why you are not available during this period.</p>
  </div>
  <div *ngIf="user" class="row">
    <div *ngIf="user.unavailabilities">
      <table class="tblUnavailability">
        <thead>
        <tr>
          <td>From</td>
          <td>Until</td>
          <td>Description</td>
          <td *ngIf="isEditMode()"></td>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let unavailability of sortedUnavailabilities()">
          <td class="tablev">
            <div *ngIf="!isEditMode()">{{unavailability.from|date}}</div>
            <div *ngIf="isEditMode()">
              <input type="date" [(ngModel)]="unavailability.from"
                     placeholder="Choose a date">
            </div>
          </td>
          <td class="tablev">
            <div *ngIf="!isEditMode()">{{unavailability.to|date}}</div>
            <div *ngIf="isEditMode()">
              <input type="date" [(ngModel)]="unavailability.to" placeholder="Choose a date">
            </div>
          </td>
          <td class="tablev">
            <div *ngIf="!isEditMode()">{{unavailability.description}}</div>
            <div *ngIf="isEditMode()">
            <textarea
              [(ngModel)]="unavailability.description"></textarea>
            </div>
          </td>
          <td class="tablev" *ngIf="isEditMode()">
            <img class="btnRemove" src="../../assets/minus.png" (click)="remove(unavailability)"/>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div style="margin-top: 15px;">
      <button class="btnSmall"
              *ngIf="!isEditMode() && !isAddMode() && user.unavailabilities && user.unavailabilities.length>0"
              (click)="setEditMode('edit')">Edit
      </button>
      <button class="btnSmall" *ngIf="!isEditMode()&& !isAddMode()" (click)="setEditMode('add')">Add</button>
      <button class="btnSmall" *ngIf="isEditMode()&& !isAddMode()" (click)="saveUnavailabilities()">Save</button>
    </div>
  </div>
  <event-registration-component *ngIf="!isEditMode() && isAddMode()"
                                (eventEmitter)="addUnavailability($event)"></event-registration-component>
</div>
