
    <div class="oneLine">
      <input matInput [matDatepicker]="picker"
             [(ngModel)]="selectedDate" [min]="minDate" [max]="maxDate"
             (dateInput)="checkDate()"
             (dateChange)="selectDate($event)"
             placeholder="yyyy-mm-dd"
             [title]="tooltip">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
      <timepicker [hours]="getHours()" [minutes]="getMinutes()" [minTime]="minTime" [checkMinimum]="isMinDay()"
                  (timeSelected)="selectTime($event)"
                  (invalidTimeSelected)="flagInvalidDate($event)"
                  style="flex: 1 1 auto;"></timepicker>
    </div>
  