import {filter, isEmpty, map, mergeMap, switchMap} from 'rxjs/operators';
import {Component, Inject, Input, OnDestroy} from "@angular/core";
import * as FileSaver from "file-saver";
import {Task} from "../dto/job";
import {TaskService} from "../service/task.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {ProjectService} from "../service/project.service";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {FileService} from "../service/file.service";
import {UserService} from "../service/user.service";
import {LogInService} from "../service/login.service";
import {environment} from "../../environments/environment";
import {HelpDialogComponent} from "../util/help.dialog.component";
import {Vendor} from "../dto/vendor";
import {VendorSelectorDialog} from "../projectcomponent/util/vendor-selector.component";
import {ChatService} from "../service/chat.service";
import {HttpClient} from "@angular/common/http";
import {getDocFiles, getFileExtension, getGeneralDocFiles, getSourceFiles, isTranslationTask} from "../util/jobutil";
import {SERVICE_EDITING, SERVICE_SWORN_TRANSLATION} from '../mock-activities';
import {SubscriptionHandler} from '../util/subscription.handler';
import {ENABLE_QUOTE} from "../dto/project";


/**
 * Created by jefv on 3/11/2017.
 */
@Component({
  selector: 'task-detail',
  templateUrl: 'task-detail.component.html',
  styleUrls: ['task-dashb.component.css']
})
export class TaskDetailComponent implements OnDestroy {

  @Input() task: Task;
  activityName: string;
  project: any;
  sourceFiles: any[];
  docFiles: any[];
  generalDocFiles: any[];
  projectDueDate: Date;
  requestedBy;
  revisionNeeded = false;
  isDebug = environment.isDebug;
  subscriptionHandler = new SubscriptionHandler(this);

  constructor(private taskService: TaskService,
              private projectService: ProjectService,
              public userService: UserService,
              private route: ActivatedRoute,
              private dialog: MatDialog,
              private loginService: LogInService,
              private fileService: FileService,
              private chatService: ChatService,
              private router: Router,
              private http: HttpClient,) {
  }

  ngOnInit(): void {
    if (!this.userService.hasTranslatorRole()) {
      this.userService.signInFromLocalStorage().pipe(
        isEmpty(),
        filter(v => v),)
        .subscribe(
          () => Promise.resolve().then(() => this.loginService.openSignInPopup())
        );
    }
    this.route.params.pipe(
      switchMap((params: Params) => this.taskService.getTask(params['id'])))
      .subscribe(task => {
        this.setTask(task);
        this.initProjectData();
        // Listen for task updates
        const taskSubscription = this.taskService.enrollForTask(task.id).pipe(
          map(m => m.task_id),
          mergeMap(t => this.taskService.getTask(t)))
          .subscribe(nt => {
            if (nt.id == task.id) this.setTask(nt);
          });
        this.subscriptionHandler.push(taskSubscription);
      });
  }

  ngOnDestroy(): void {
  }

  setTask(task: Task): void {
    this.task = task;
    this.activityName = this.isServiceSpecificTranslation() ? this.task.service : this.task.activity;
    if (isTranslationTask(this.task)) {
      this.taskService.hasNextTask(this.task).subscribe(b => this.revisionNeeded = b);
    }
  }

  isServiceSpecificTranslation(): boolean {
    return this.task.service === SERVICE_SWORN_TRANSLATION
      || this.task.service === SERVICE_EDITING;
  }

  initProjectData(): void {
    this.projectService.getProject(this.task.projectId).subscribe(res => {
        this.project = res;
        this.sourceFiles = getSourceFiles(this.project);
        this.docFiles = getDocFiles(this.project);
        // Filter out the custom documentation PDFs, because they are displayed separately
        this.generalDocFiles = getGeneralDocFiles(this.project);
        this.projectDueDate = res.duedate;
        this.requestedBy = res.requestedby;
        // this.initTotalWordcount();
      }
    );
  }

  getPdf4Source(fileName: string): void {
    if (this.docFiles) {
      return this.docFiles.filter(f => f.docinfo != null && f.docinfo.source4pdf != null && f.docinfo.source4pdf === fileName)[0];
    } else return null;
  }

  downloadDoc(file: any): void {
    this.fileService.downloadDoc(this.task.projectId, file.name).subscribe(
      n => {
        FileSaver.saveAs(n, file.name, true);
      }
    )
  }


  openTaskHelp(): void {
    this.http.get('assets/help/task-help.html', {responseType: 'text'})
      .subscribe(helptext => {
          let dialogRef = this.dialog.open(HelpDialogComponent, {
            maxWidth: '700px',
            maxHeight: '85%',
            data: {helpTitle: "How to handle a task?", helpHtmlContent: helptext}
          });
        }
      );
  }

  openDocHelp(): void {
    let helpText = `
      <p>Below you will find a list of documentation files the customer has provided with the project, for your convenience.</p>
      <p>This could be a wide range of information that could aid you with your task, such as:
      <ul>
        <li>Terminology lists;</li>
        <li>Previous versions of similar documents;</li>
        <li>Relevant reference material.</li>
      </ul>
      Click a file to download it.</p>
      <p style="font-weight: bold; color: var( --alternative-color);">Please note these files are strictly for your
      reference and DO NOT NEED TO BE TRANSLATED.</p>
    `;
    let dialogRef = this.dialog.open(HelpDialogComponent, {
      width: '700px',
      height: '400px',
      data: {helpTitle: "Documentation files", helpHtmlContent: helpText}
    });
  }
}


@Component({
  selector: 'translation-upload-dialog',
  template: `
      <div>
          <div>
              <h4 style="margin-bottom: 15px;">You are uploading the translated file for</h4>
              <div style="display: flex; flex-direction: column; align-items: center;">
                  <frontpage [pId]="task.projectId" [fileName]="taskFile.name"
                             [showIconIfUnavailable]="false"></frontpage>
                  <h4><strong>{{taskFile.name}}</strong></h4>
              </div>
          </div>
          <div *ngIf="!uploading && taskFilePath == null">
              <p style="margin-top: 15px;">Please make sure the file you upload is fully translated and that you took
                  all necessary guidelines into account.<br/>
                  After it is uploaded, the file will be marked as completed. When all files within your task are
                  complete, the task itself will become available for invoicing.</p>
              <p *ngIf="uploadError" style="color: red; margin-top: 25px;">
            <span *ngIf="uploadError == 'noxliff'">Oops, the file you tried to upload did not seem to be an XLIFF, with extension <strong>.xlf</strong>. <br/>
                Ensure you select the correct one and try again. If you do feel it is correct, contact us through the 'Can we help?' function on the lower right.
            </span>
                  <span *ngIf="uploadError == 'other'">Drat! There was an error uploading your file. <br/>
                Please try again, and if the problem persists, contact us through the 'Can we help?' function on the lower right.
            </span>
              </p>
              <div style="margin: 25px; display: flex; justify-content: space-evenly; align-items: center;">
                  <label for="uploadFile" class="lblUpload">Select file</label>
                  <input id="uploadFile" type="file" (change)="upload($event)" accept="{{expectedFileTypes}}"
                         placeholder="Upload" style="display: none;"/>
                  <button (click)="dialogRef.close()">Cancel</button>
              </div>
          </div>
          <div *ngIf="uploading" style="margin-top: 25px;">
              <p>Hang on while we validate your file...</p>
              <div style="width: 100%; text-align: center;"><img src="../../assets/loading.gif"
                                                                 style="width:100px; height:100px;"></div>
          </div>
          <div *ngIf="taskFilePath != null">
              <p style="margin-top: 25px;">Selected file:
                  <span style="color: var(--second-color); font-weight: bold;">{{selectedFile.name}}</span>
                  <span *ngIf="selectedFile.size" style="font-size: 12px;"> &nbsp;({{selectedFile.size | filesize}}
                      )</span></p>
              <p style="margin-top: 25px;">By continuing you are confirming that you have translated the file
                  completely and to the best of your ability.</p>
              <div style="margin: 25px; display: flex; justify-content: space-evenly; align-items: flex-start;">
                  <button (click)="confirmUpload()">Upload</button>
                  <button (click)="dialogRef.close()">Cancel</button>
              </div>
          </div>
      </div>`,
  styleUrls: ["./task-dashb.component.css"]
})
export class TranslationUploadDialog {

  task;
  taskFile;
  processType: string;
  expectedFileTypes = ".xlf, .xlif, .xliff";
  selectedFile: File;
  uploading = false;
  taskFilePath = null;
  uploadError = null;

  constructor(public dialogRef: MatDialogRef<TranslationUploadDialog>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private fileService: FileService) {
  }

  ngOnInit(): void {
    if (this.data) {
      this.task = this.data.task;
      this.taskFile = this.data.taskFile;
      if (this.taskFile.processType == 'MANUAL')
        this.processType = this.taskFile.processType;
      if (this.data.expectedFileTypes)
        this.expectedFileTypes = this.data.expectedFileTypes;
    }
  }

  upload(event: any): void {
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      this.uploadError = null;
      this.selectedFile = fileList[0];
      if (this.processType == null || this.processType != 'MANUAL') {
        // If the process type is not MANUAL, we expect the translator to upload an XLIFF
        let currentExt = getFileExtension(this.selectedFile.name).toLowerCase();
        if (currentExt !== "xlf" && currentExt !== "xlif" && currentExt !== "xliff")
          this.uploadError = "noxliff"
      }
      if (this.uploadError == null) {
        this.uploading = true;
        this.fileService.uploadTranslation(this.selectedFile, this.taskFile.name, this.task, this.processType)
          .subscribe(
            data => {
              console.log('Uploaded translation of ' + this.taskFile.name + ' for task ' + this.task.id, data);
              this.taskFilePath = data
            }, error => {
              console.error("Error uploading " + this.selectedFile.name, error);
              this.uploadError = "other";
              this.uploading = false;
            }, () => this.uploading = false
          );
      }
    }
  }

  confirmUpload(): void {
    this.dialogRef.close(this.taskFilePath);
  }
}


@Component({
  selector: 'task-reject-dialog',
  template: `
      <div class="rejectDialog">
          <h4>Decline task</h4>
          <p>
              We're very sorry to hear you are not able to accept this task.<br/>
          </p>
          <mat-expansion-panel [expanded]="false">
              <mat-expansion-panel-header>
                  <mat-panel-title>
                      <p>If the reason is <strong>the deadline</strong>, you have the option to do a counter proposal.
                      </p>
                  </mat-panel-title>
              </mat-expansion-panel-header>
              <div>
                  <div>
                      <div>
                          <datetime (dateSelected)="counterProposal.date = $event"></datetime>
                          <textarea [(ngModel)]="counterProposal.text"
                                    placeholder="Please motivate why you propose a different due date."
                                    style="width:100%;"></textarea>
                      </div>
                      <small>Please note that if the user accepts your proposal the task is automatically accepted
                          (you\'ll be notified via mail).
                      </small>
                  </div>
                  <button (click)="submitProposal()">Submit proposal</button>
              </div>
          </mat-expansion-panel>
          <p>
              If there is another reason you would like to decline, we would very much appreciate it if you could
              share the reason why you are not able to, so we can pass this on to the customer that chose you for this
              task.<br/>
              <small>If, for example, not the best time, the customer might be willing to change it to
                  accommodate.</small>
          </p>
          <textarea [(ngModel)]="message" (keypress)="checkDeadline($event)" [placeholder]="placeholder"></textarea>
          <div *ngIf="possibleDeadlineIssues">
              <small style="color: red;">If you have issues with the deadline, you can do a counter proposal, see above.
              </small>
          </div>
          <div
                  style="border: 1px solid darkgray; display: flex; flex-direction: column; padding: 2px; margin-top: 10px; margin-bottom: 10px;">
              <div *ngIf="!suggestedColleague" style="display: flex; justify-content: space-between;">
                  <label>Do you know someone that would be ideal for this task?</label>
                  <button (click)="openColleagues()" style="font-size: smaller; margin: 2px;">Suggest someone!
                  </button>
              </div>
              <div *ngIf="suggestedColleague" style="display: flex;">
                  <label style="flex: 1 1 auto; display: flex; align-items: center; justify-content: space-between;">
                      <span style="color: inherit;">Alternative suggestion for task: </span>
                      <span>{{suggestedColleague.name}}</span>
                  </label>
                  <span style="vertical-align: middle; display: flex; align-items: center;">
                    <img (click)="openColleagues()" src="../../assets/edit.png" title="Edit suggestion"
                         style="margin-left: 5px;"/>
                    <img (click)="selectColleague(undefined)" src="../../assets/minus.png" title="Remove suggestion"/>
                 </span>
              </div>
          </div>
          <div style="display: flex; justify-content: center; margin-top: 10px;">
              <button (click)="closeDialog()">Decline</button>
              <button (click)="dialogRef.close()">Cancel</button>
          </div>
      </div>`,
  styleUrls: ["./task-dashb.component.css"]
})
export class RejectDialog {

  message: string;
  placeholder: string;
  langpair: string;
  activity: string;
  suggestedColleague: any = undefined;
  counterProposal = {date: undefined, text: undefined};
  possibleDeadlineIssues = false;

  constructor(public dialogRef: MatDialogRef<RejectDialog>,
              private dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
    if (this.data) {
      this.message = this.data.message;
      this.placeholder = this.data.placeholder;
      this.langpair = this.data.langpair;
      this.activity = this.data.activity;
    }
  }

  closeDialog(): void {
    this.dialogRef.close("success");
  }

  openColleagues(): void {
    let dialogRef = this.dialog.open(VendorSelectorDialog, {
      width: '80%',
      height: '80%',
      data: {langpair: this.langpair, activity: this.activity, showPricing: false}
    });
    dialogRef.afterClosed().subscribe(result => {
        if (result instanceof Vendor) {
          this.selectColleague(result)
        }
      }
    );
  }

  selectColleague(colleague): void {
    this.suggestedColleague = colleague;
  }

  checkDeadline(event): void {
    let checkString = this.message.toLowerCase();
    //TODO improve this matching or find a smarter way to handle this.
    this.possibleDeadlineIssues = checkString.includes("duedate")
      || checkString.includes("due date")
      || checkString.includes("deadline")
      || checkString.includes("no time");
  }

  submitProposal(): void {
    this.dialogRef.close("counterproposal");
  }
}


@Component({
  selector: 'task-accept-dialog',
  template: `
      <div class="acceptDialog" style="position: relative;">
          <h4>Accept task</h4>
          <p style="margin: 15px 0;">
        <span *ngIf="dueDate">
            The customer requested the delivery of this project by <br/>
            <span style="font-weight: bold;">{{dueDate | date:'EEEE, MMMM dd, y'}}</span>, at
            <span style="font-weight: bold;">{{dueDate | date:'HH:mm'}}</span>.<br/><br/>
            <span *ngIf="revisionNeeded">There is <span style="font-weight: bold;">a revision task following your translation</span>.
              This also needs to be done within this timeframe.<br/><br/></span>
            With that in mind, please specify when you expect to deliver the task by.
            </span>
              <span *ngIf="!dueDate">Please specify when you expect to deliver the task by.</span>
          </p>
          <div>
              <label>Expected delivery date</label>
              <datetime [minDate]="minDueDate" [inputDate]="date" [defaultToToday]="false" minTime="now"
                        (dateSelected)="setDate($event)"
                        (invalidDateSelected)="setInvalidDate(true)"></datetime>
              <div *ngIf="tooLate">
                  <div>
                      <p style="color: red; margin: 10px 0;">Your expected delivery date is later than the date
                          requested by the
                          customer.
                          You can propose to deliver by this later date, but the customer will need to accept this
                          change.</p>
                      <textarea [(ngModel)]="proposal.text"
                                placeholder="Please motivate why you propose a different delivery date."
                                style="width:100%;"></textarea>
                      <p style="font-size: small;">Please note that if the user accepts your proposal the task is
                          automatically
                          accepted
                          - you'll be notified of this via mail.
                      </p>
                  </div>
              </div>
              <div *ngIf="isSworn">
                  <div>
                      <label>Extra cost for Sworn translation</label>
                      <input id="extraCost" type="number" step="1" [(ngModel)]="proposal.extraCost"/>
                      <label>Only fill in an extra cost if needed, if not this can be left empty.</label>
                      <p style="font-size: small;">If the user accepts your extra cost the task is automatically
                          accepted
                          - you'll be notified of this via mail.
                      </p>
                  </div>
              </div>
          </div>
          <div style="display: flex; justify-content: center; margin-top: 10px;">
              <button *ngIf="tooLate || (proposal.extraCost > 0)" type="submit"
                      class="btnCounterPropose"
                      (click)="counterPropose()"
                      [disabled]="invalidDate">Propose task change
              </button>
              <button *ngIf="!tooLate && !(proposal.extraCost > 0)" type="submit"
                      (click)="closeDialog()"
                      [disabled]="invalidDate">Confirm
              </button>
              <button (click)="dialogRef.close()">Cancel</button>
          </div>
      </div>`,
  styleUrls: ["./task-dashb.component.css"]
})
export class AcceptDialog {

  message: string;
  date: Date;
  dueDate: Date;
  minDueDate: Date = new Date();
  tooLate = false;
  invalidDate = false;
  isSworn = false;
  proposal = {date: undefined, text: undefined, extraCost: undefined};
  revisionNeeded = false;

  constructor(public dialogRef: MatDialogRef<AcceptDialog>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
    if (this.data) {
      this.message = this.data.message;
      this.date = this.data.date;
      this.dueDate = this.data.dueDate;
      this.revisionNeeded = this.data.revisionNeeded;
      this.tooLate = this.date > this.dueDate;
      this.invalidDate = this.date < new Date();
      this.isSworn = ENABLE_QUOTE && this.data.swornTranslation;
    }
  }

  setDate(dd: any): void {
    if (dd) {
      this.date = dd;
      this.tooLate = this.date > this.dueDate;
      this.setInvalidDate(false);
    }
  }

  closeDialog(): void {
    this.dialogRef.close("accept");
  }

  counterPropose(): void {
    this.proposal.date = this.date;
    this.dialogRef.close("counterproposal");
  }

  setInvalidDate(invalid: boolean): void {
    this.invalidDate = invalid;
  }
}
