import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Component, Inject} from "@angular/core";


@Component({
  selector: 'simple-dialog',
  template: `
	  <mat-dialog-content class="mainDiv">
		  <h1 *ngIf="dialogTitle">{{dialogTitle}}</h1>
		  <h4 *ngIf="dialogMessage">{{dialogMessage}}</h4>
		  <div *ngIf="dialogHtml" class="dialogHtmlDiv" [innerHTML]="dialogHtml | safeHtml"></div>
		  <div class="buttons">
			  <button class="dialogButton" (click)="dialogRef.close()">{{dialogButtonName}}</button>
		  </div>
	  </mat-dialog-content>`,
  styles: [
    '.mainDiv { display: flex; flex-direction: column; align-items: flex-start; background-color: inherit; }',
    '.mainDiv h1 { margin: 5px 0 10px 0; }',
    '.mainDiv h4 { font-size: 18px; margin: 5px 0 10px 0; }',
    '.dialogHtmlDiv { margin: 5px 0 10px 0; }',
    '.buttons { width: 100%; display: flex; align-items: center; justify-content: space-evenly; margin-top: 25px; }',
    '.dialogButton { flex: 1 1 auto; max-width: 45%; }']
})
export class SimpleDialog {

  dialogTitle: string;
  dialogMessage: string;
  dialogHtml: string;
  dialogButtonName: string = "Close";

  constructor(public dialogRef: MatDialogRef<SimpleDialog>,
              @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  ngOnInit(): void {
    if (this.data.dialogTitle)
      this.dialogTitle = this.data.dialogTitle;
    if (this.data.dialogMessage)
      this.dialogMessage = this.data.dialogMessage;
    if (this.data.dialogHtml)
      this.dialogHtml = this.data.dialogHtml;
    if (this.data.dialogButtonName != null)
      this.dialogButtonName = this.data.dialogButtonName
  }
}


