<div *ngIf="translators != undefined" #translatorList class="translatorList" (window:resize)="onListResize()">

  <div *ngIf="translators.length > 0">
    <div class="scrollButton scrollButtonLeft" *ngIf="leftScrollEnabled">
      <!--Add a blur effect at the position of the scroll arrows-->
      <span class="scrollBlurArrow" (click)="scrollLeft()">
        <img src="../../../assets/icon_arrow_right.svg" alt="Scroll left">
      </span>
      <span *ngFor="let i of [8,7,6,5,4,3,2,1]" class="scrollBlur" [ngStyle]="{'opacity':'0.'+i}"></span>
    </div>
    <div #translatorCardScroll class="translatorCardList" [style.transform]="'translateX(' + getTransX() + 'px)'">
      <!-- The change in CSS class from 'cardSlideStart' to 'cardSlideEnd' on expanding the "bar" triggers a "slide in"
      effect (from right to left) of the translator cards. The delay based on index was added so the cards slide in in
      a staggered manner, and the z-index was set to ensure the first card is on top. -->
      <translator-card #{{i}} [ngClass]="{'cardSlideStart': !expanded, 'cardSlideEnd': expanded }"
                       [ngStyle]="{ 'transition-delay': (expanded?((i*0.3)):0)+'s', 'z-index': translators.length-i }"
                       *ngFor="let translator of translators; let i = index"
                       [attr.data-index]="i" [index]="i"
                       [translator]="translator" [isSelected]="selected === translator.id"
                       [targetLang]="this.langpair.target" [isLast]="(i+1) === translators.length"
                       [duedate]="duedate"
                       [isQuote]="isQuote"
                       (translatorSelect)="onTranslatorSelect($event)"></translator-card>
    </div>
    <div class="scrollButton scrollButtonRight" *ngIf="rightScrollEnabled">
      <!--Add a blur effect at the position of the scroll arrows-->
      <span *ngFor="let i of [1,2,3,4,5,6,7,8]" class="scrollBlur" [ngStyle]="{'opacity':'0.'+i}"></span>
      <span class="scrollBlurArrow" (click)="scrollRight()">
        <img src="../../../assets/icon_arrow_right.svg" alt="Scroll right">
      </span>
    </div>
  </div>

  <div *ngIf="translators.length === 0" class="notranslators">
    <h4 style="color: orange; font-size: 18px; margin: 15px 0;">Oops, it seems we have no one available at the moment
      for this language. 😩</h4>
    <div>
      <div *ngIf="!contactRequestResult">
        <p>If you leave us your email address, we will look for suitable translators and get back to you as soon as
          possible!</p>
        <form name="form" (ngSubmit)="f.form.valid && onSubmitContactRequest()" #f="ngForm" novalidate>
          <div class="emailDiv">
            <span style="margin-left: 15px; margin-right: 10px;font-size: 15px;">Email</span>
            <input type="text" class="form-control" name="email" placeholder="Enter your e-mail address"
                   [ngClass]="{'is-invalid': f.submitted && email.invalid}" style="width: 160px;"
                   [(ngModel)]="userEmail" #email="ngModel" required email/>
            <button style="width: auto; margin: 0 15px;">Send!</button>
            <div *ngIf="f.submitted && email.invalid">
              <span class="invalidFeedback" *ngIf="email.errors.required">Email is required.</span>
              <span class="invalidFeedback" *ngIf="!email.errors.required && email.errors.email">Email must be a valid email address.</span>
            </div>
          </div>
        </form>
      </div>
      <div *ngIf="contactRequestResult" style="font-weight: bold;">{{contactRequestResult}}</div>
      <p>Or, if you have someone in mind yourself, you can simply invite them to join lilo!<br/>
        They can use the following link to sign up:
        <a href="https://www.lilo.global/newtranslator" target="_blank"
           style="font-style: italic">www.lilo.global/newtranslator</a></p>
    </div>
  </div>

</div>
