/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./review.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./rating.component.ngfactory";
import * as i3 from "./rating.component";
import * as i4 from "@angular/common";
import * as i5 from "./rating.small.component";
var styles_RatingSmallComponent = [i0.styles];
var RenderType_RatingSmallComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RatingSmallComponent, data: {} });
export { RenderType_RatingSmallComponent as RenderType_RatingSmallComponent };
function View_RatingSmallComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["style", "color: #888888"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "small", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["(", ")"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.reviewCount; _ck(_v, 2, 0, currVal_0); }); }
export function View_RatingSmallComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "rating", [], null, null, null, i2.View_RatingComponent_0, i2.RenderType_RatingComponent)), i1.ɵdid(1, 114688, null, 0, i3.RatingComponent, [], { rating: [0, "rating"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RatingSmallComponent_1)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.rating; _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.reviewCount != null); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_RatingSmallComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "rating-small-component", [], null, null, null, View_RatingSmallComponent_0, RenderType_RatingSmallComponent)), i1.ɵdid(1, 49152, null, 0, i5.RatingSmallComponent, [], null, null)], null, null); }
var RatingSmallComponentNgFactory = i1.ɵccf("rating-small-component", i5.RatingSmallComponent, View_RatingSmallComponent_Host_0, { rating: "rating", reviewCount: "reviewCount" }, {}, []);
export { RatingSmallComponentNgFactory as RatingSmallComponentNgFactory };
