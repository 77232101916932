import {Component, EventEmitter, Input, Output, ViewChild} from "@angular/core";
import {ProjectService} from "../service/project.service";
import {ActivatedRoute, Router} from "@angular/router";
import {EditorService} from "./service/editor.service";
import {EditorComponent} from "./editor.component";
import {SimpleDialog} from "../simple-dialog.component";
import {MatDialog} from "@angular/material/dialog";


@Component({
  selector: 'final-check-editor',
  templateUrl: 'final-check-editor.component.html',
  styleUrls: ['editor.component.css'],
})
export class FinalCheckEditorComponent {
  @Input()
  pId: string;
  @Input()
  source: string;
  @Input()
  target: string;
  @Input()
  filename: string;

  fileLoc = undefined;
  fileGroupId = undefined;

  @ViewChild("editor", { static: false })
  editor: EditorComponent;

  @Output()
  finalizeEmitter = new EventEmitter<any>();
  @Output()
  changeEmitter = new EventEmitter<any>();

  constructor(private router: Router,
              private route: ActivatedRoute,
              private projectService: ProjectService,
              private editorService: EditorService,
              private dialog: MatDialog,
  ) {

  }

  ngOnInit() {
    this.fileGroupId = this.pId + "_" + this.source + "_" + this.target + "_FDC";
    this.loadFile();
  }

  loadFile() {
    return this.editorService.getFinalCheckFile(this.pId, this.source, this.target, this.filename)
      .subscribe(data => {
        this.fileLoc = data.filelocation;
      })
  }

  saveFinalCheckFile() {
    this.editorService.completeFinalCheckFile(this.fileLoc, this.fileGroupId, this.filename, this.pId, this.source + "_" + this.target)
      .subscribe(data => {
        if (JSON.parse(data)) {
          //Successfully saved
          let dialogRef = this.dialog.open(SimpleDialog, {
            width: "400px",
            panelClass: 'simpleDialog',
            data: {dialogMessage: this.filename + " is successfully finalized. We're preparing the final file now."}
          });
        } else {
          //No changes detected
          let dialogRef = this.dialog.open(SimpleDialog, {
            width: "400px",
            panelClass: 'simpleDialog',
            data: {dialogMessage: this.filename + " is successfully finalized. No changes were detected."}
          });
        }
        this.finalizeEmitter.emit("finalized")
      })
  }

  change(event: any) {
    this.changeEmitter.emit(event)
  }
}
