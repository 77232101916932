import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {Observable} from "rxjs";
import {filter, map} from "rxjs/operators";


@Component({
  selector: 'colleague-select-list',
  template: '<div>' +
    '<div class="searchColleagues">' +
    ' <input type="text"  [(ngModel)]="searchText"' +
    '           placeholder="Search colleagues" (input)="filterColleagues()" id="colleagueSearchInput" /></div>' +
    '<div class="colleaguesList">' +
    '<div *ngFor="let colleague of filteredColleagues" class="colleagueItem" (click)="toggleColleague(colleague)">' +
    '<button [ngClass]="{colleagueItemSelected:isSelected(colleague)}">{{colleague.firstName}} {{colleague.lastName}}' +
    '<span *ngIf="isSelected(colleague)" class="colleagueSelected">✓</span></button>' +
    '</div>' +
    '</div>' +
    '</div>',
  styleUrls: ['colleague-select.component.css'],
})
export class ColleagueSelectListComponent implements OnInit, AfterViewInit {

  @Input()
  selectedColleagues: any[] = [];
  @Input()
  colleagues: any[] = [];

  searchText: string = undefined;

  @Output()
  eventEmitter = new EventEmitter<any>();

  /*todo check why the observable itself cannot be used in the *ngFor with async*/
  filteredColleaguesObservable: Observable<any[]>;
  filteredColleagues: any[] = [];

  constructor() {

  }

  ngOnInit() {
    this.filteredColleaguesObservable = Observable.from(this.colleagues)
      .pipe(
        map(a => <any>a),
        map(a => {
          return {user: a, filterValue: a.firstName + ' ' + a.lastName}
        }),
        filter(a => {
          if (this.searchText === undefined) {
            return true;
          }
          return a.filterValue.toLocaleLowerCase().indexOf(this.searchText.toLocaleLowerCase()) >= 0
        }),
        map(a => a.user)
      ).toArray()
    this.filterColleagues();
  }

  ngAfterViewInit() {
    this.focusOnSearchInput();
  }

  focusOnSearchInput() {
    document.getElementById('colleagueSearchInput').focus();
  }

  toggleColleague(colleague: any) {
    const index = this.selectedColleagues.indexOf(colleague);
    if (index >= 0) {
      this.onRemove(colleague)
    } else {
      this.onSelect(colleague)
    }
  }

  // No emitting needed because the array is the same object through the components
  onSelect(colleague: any) {
    this.selectedColleagues.push(colleague);
    this.eventEmitter.emit({colleague: colleague, type: 'add'})
    this.searchText = undefined;
    this.focusOnSearchInput();
  }

  // No emitting needed because the array is the same object through the components
  onRemove(colleague: any) {
    this.selectedColleagues.splice(this.selectedColleagues.indexOf(colleague), 1);
    this.eventEmitter.emit({colleague: colleague, type: 'remove'})
  }

  isSelected(colleague: any) {
    return this.selectedColleagues.indexOf(colleague) >= 0;
  }

  filterColleagues() {
    if (this.searchText === undefined) {
      this.filteredColleagues = this.colleagues;
    } else {
      this.filteredColleagues = [];
      this.filteredColleaguesObservable.subscribe(a => {
        this.filteredColleagues = a
      })
    }
  }
}
