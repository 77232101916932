<div class="container">
  <div class="header">

    <!--todo: change logo to "orb" when the menu scrolls out of view-->
    <a href="https://about.lilo.global/home?stay=true"><img class="logo" src="../assets/brand.svg"></a>

    <div class="hamburger">
      <label for="chkHamburger" id="lblHamburger" ><img src="../assets/icon_list_active.svg" /></label>
      <input type="checkbox" id="chkHamburger" />

      <nav class="navbar">

        <!--TODO: enable "invite" once the general terms and conditions is in order (@See JGO)-->
        <button *ngIf="isUserAuthenticated && isTranslator() && isDebug()" (click)="openInviteDialog()" class="btnInvite">
          Invite
        </button>
        <a *ngIf="!isUserAuthenticated || (!isTranslator() || isEnableRequest())"
          (click)="newProject()">Request a translation</a>
        <a *ngIf="isUserAuthenticated && (!isTranslator() || isEnableRequest())" (click)="navigate(['pdashboard'])"
          routerLinkActive="active">Your projects</a>
        <a *ngIf="!isUserAuthenticated" (click)="openJoinDialog('newtranslator')">I'm a freelance translator</a>
        <a *ngIf="isUserAuthenticated && !isTranslator()" routerLink="/newtranslator" routerLinkActive="active">I'm a
          freelance translator</a>
        <a *ngIf="isUserAuthenticated && (isTranslator() || isInternalTranslator())" routerLink="/tdashboard"
          routerLinkActive="active">Your tasks</a>
        <a *ngIf="isUserAuthenticated && isTranslator()"  (click)="navigate(['invoice','new'])" routerLinkActive="active">Register
          invoice</a>

        <a *ngIf="isUserAuthenticated && _userService.isCurrentUserAdmin" routerLink="/adashboard"
          routerLinkActive="active">Admin</a>

        <a *ngIf="!isUserAuthenticated" (click)="openJoinDialog()">Join</a>
        <button *ngIf="!isUserAuthenticated" (click)="signInPopup()">Sign in</button>

        <a *ngIf="isUserAuthenticated" (click)="navigate(['user', user.id])" routerLinkActive="active"
           class="mugshotlink" style="display: flex; width: 45px; margin-right: 6px; position: relative;" title="Go to your profile">
          <mugshot [user]="user" [width]="'45px'" [height]="'45px'" [showIncomplete]="true"></mugshot>
        </a>
        <a *ngIf="isUserAuthenticated" (click)="logout()" class="logout" style="text-decoration: underline;">Logout</a>

      </nav>
    </div>

  </div>

  <div class="main">
    <router-outlet (activate)="onActivate($event)"></router-outlet>
  </div>

  <div class="footer">
    <div>Copyright lilo &middot; designed by <a href="http://www.kpot.be" target="_blank" class="akpot">kpot</a></div>
    <div style="display: flex; justify-content: flex-end; align-items: center; margin-right: 160px;">
      <div *ngIf="isOrigDebug" class="footerDebug">
        <a *ngIf="isDebug()" (click)="toggleDebug()">(turn off debug)</a>
        <a *ngIf="!isDebug()" (click)="toggleDebug()">(turn on debug)</a>
      </div>
      <div>
        <a href="https://about.lilo.global/aboutus">About</a><span class="asep"> &middot; </span>
        <a href="https://about.lilo.global/howitworks">How it works</a><span class="asep"> &middot; </span>
        <a href="https://about.lilo.global/whyuselilo">Why use lilo</a><span class="asep"> &middot; </span>
        <a href="https://about.lilo.global/lilo-is-hiring">Careers</a>
      </div>
      <div style="margin-left: 10px;">
        <a href="https://www.linkedin.com/company/liloglobal/" target="_blank"><img src="../assets/linkedin.png"
                                                                                    style="height: 25px;"/></a><span class="asep">&nbsp;</span>
        <a href="https://www.facebook.com/LiLoGlobal/" target="_blank"><img src="../assets/facebook.png"
                                                                            style="height: 25px;"/></a>
      </div>
    </div>
  </div>

</div>
