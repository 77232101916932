
	  <div class="dateAndTime">
		  <div class="oneLine">
			  <input matInput [matDatepicker]="picker"
					 [(ngModel)]="date" [min]="minDate" [max]="maxDate" [title]="tooltip"
					 (dateChange)="dateChange()"
					 placeholder="yyyy-mm-dd">
			  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
		  </div>
		  <mat-datepicker #picker></mat-datepicker>
		  <div style="float: right; display: flex;">
			  <input type="number" class="timeinput" [(ngModel)]="hours" (change)="dateChange()" [max]="23" [min]="0"/>:
			  <input type="number" class="timeinput" [(ngModel)]="minutes" (change)="dateChange()" [max]="59"
					 [min]="0"/>
		  </div>
	  </div>
  