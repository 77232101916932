<div>
  <h1>Integrations</h1>
  <div>
    <h3>Hubspot</h3>
    <div>
    <textarea
      [(ngModel)]="user.hubspot.hapiKey"></textarea>
      <button (click)="testHubspot()">Test</button>
      <div *ngIf="hubspotTestMessage">{{hubspotTestMessage}}</div>
      <div><button (click)="createHubspotProject()">Translate hubspot pages</button></div>
    </div>
    <button (click)="save()">Save</button>
  </div>
</div>
