import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from "@angular/core";
import {DateAdapter, MAT_DATE_FORMATS, NativeDateAdapter} from "@angular/material/core";
import {TimeComponent} from "./time.component";


export const NATIVE_DATE_FORMATS = {
  parse: {
    dateInput: {month: 'short', year: 'numeric', day: 'numeric'}
  },
  display: {
    // dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
    dateInput: 'input',
    monthYearLabel: {year: 'numeric', month: 'short'},
    dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
    monthYearA11yLabel: {year: 'numeric', month: 'long'},
  }
};

export class AppDateAdapter extends NativeDateAdapter {

  format(date: Date, displayFormat: Object): string {
    if (displayFormat === 'input') {
      return formatDate(date);
    } else
      return super.format(date, displayFormat);
  }

  parse(value: any): Date {
    try {
      let d = new Date(value);
      d.setHours(0, 0, 0);
      return d;
    } catch (e) {
      throw e;
    }
  }
}

@Component({
  selector: "datetime",
  template: `
    <div class="oneLine">
      <input matInput [matDatepicker]="picker"
             [(ngModel)]="selectedDate" [min]="minDate" [max]="maxDate"
             (dateInput)="checkDate()"
             (dateChange)="selectDate($event)"
             placeholder="yyyy-mm-dd"
             [title]="tooltip">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
      <timepicker [hours]="getHours()" [minutes]="getMinutes()" [minTime]="minTime" [checkMinimum]="isMinDay()"
                  (timeSelected)="selectTime($event)"
                  (invalidTimeSelected)="flagInvalidDate($event)"
                  style="flex: 1 1 auto;"></timepicker>
    </div>
  `,
  styles: [
    '.oneLine { display: flex; justify-content: flex-start; align-items: center; overflow: auto; width: 100%; }',
    // To resize the date input element
    '.mat-input-element { min-width:100px !important; }',
    'input{border-width: 1px; border-style: inset;" [style.color]="textColor}'
  ],
  providers: [
    {provide: DateAdapter, useClass: AppDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: NATIVE_DATE_FORMATS},
  ],
})
export class DateTimeComponent implements OnInit {

  @Input()
  minDate: Date;
  @Input()
  minTime: string;
  @Input()
  maxDate: Date;
  @Input()
  inputDate: Date;
  @Input()
  defaultToToday: boolean = true;
  @Output()
  dateSelected = new EventEmitter<Date>();
  @Output()
  invalidDateSelected = new EventEmitter<Date>();

  selectedDate: Date;
  tooltip = "Pick a date. (yyyy-mm-dd)";
  textColor = "inherit";

  @ViewChild(TimeComponent, {static: true})
  timeComponent: TimeComponent;
  currentTime: string;
  validTime: boolean = false;


  constructor() {
  }

  ngOnInit(): void {
    this.initSelectedDate();
    this.minDate = this.initTimeOnDate(this.minDate);
    this.maxDate = this.initTimeOnDate(this.maxDate);
    this.currentTime = this.dateToTime(this.inputDate);
    // Check whether the selected date is valid
    this.checkDate();
    this.validTime = this.updateTime();
  }

  reInit(newInputDate: Date): void {
    this.inputDate = newInputDate;
    this.initSelectedDate()
  }

  private initSelectedDate(): void {
    if (!this.inputDate && this.defaultToToday) {
      // If no selected date given, then use today
      this.inputDate = new Date();
    } else if (this.inputDate && !(this.inputDate instanceof Date)) {
      // If a selected date is given, but it is not a Date object, then attempt to create a Date object
      this.inputDate = new Date(this.inputDate);
    }
    if (this.inputDate)
      this.selectedDate = this.initTimeOnDate(new Date(this.inputDate));
  }

  private initTimeOnDate(d: Date): Date {
    // Set default time for date
    if (d != undefined)
      d.setHours(23, 59, 0, 0);
    return d;
  }

  checkDate(): void {
    if (this.isValid()) {
      this.textColor = "inherit";
    } else {
      this.textColor = "red";
    }
  }

  isValid(): boolean {
    let sd = this.initTimeOnDate(this.selectedDate);
    if (sd == null) {
      this.tooltip = "Invalid date format. Dates must be of format 'yyyy-mm-dd'.";
      return false;
    }
    if (this.minDate != null && this.minDate > sd) {
      this.tooltip = "Date is earlier than the mimimum date (" + formatDate(this.minDate) + ").";
      return false;
    }
    if (this.maxDate != null && this.maxDate < sd) {
      this.tooltip = "Date is newer than the maximum date (" + formatDate(this.maxDate) + ").";
      return false;
    }
    this.tooltip = "Pick a date. (yyyy-mm-dd)";
    return true;
  }

  setSelectedDate(v: Date): void {
    this.selectedDate = new Date(v);
    this.currentTime = this.dateToTime(this.selectedDate);
    this.timeComponent.setTime(this.currentTime);
    this.checkDate();
  }

  selectDate(v: Date): void {
    this.validTime = this.updateTime();
    if (this.isValid() && this.validTime)
      this.dateSelected.emit(this.getFullDate());
    else
      this.flagInvalidDate(this.getFullDate());
  }

  isMinDay(): boolean {
    return this.minDate != undefined && this.selectedDate != undefined && this.selectedDate.getUTCDate() == this.minDate.getUTCDate()
  }

  private getFullDate(time: string = this.currentTime): Date {
    let dateTime = new Date(this.selectedDate.getTime());
    if (time != undefined) {
      let hours = parseInt(this.currentTime.substr(0, 2));
      let minutes = parseInt(this.currentTime.substr(3, 2));
      dateTime.setHours(hours, minutes, 0, 0);
    } else {
      dateTime = this.initTimeOnDate(dateTime);
    }
    return dateTime;
  }

  getHours(): string {
    if (this.currentTime != undefined)
      return "" + parseInt(this.currentTime.substr(0, 2));
  }

  getMinutes(): string {
    if (this.currentTime != undefined) {
      let index = this.currentTime.indexOf(":");
      return "" + parseInt(this.currentTime.substr(index + 1));
    }
  }

  dateToTime(d: Date): string {
    if (d != undefined) {
      return d.getHours() + ":" + d.getMinutes();
    }
    return null;
  }

  selectTime(time: string): void {
    this.currentTime = time;
    this.validTime = true;
    if (this.isValid()) {
      this.dateSelected.emit(this.getFullDate());
    } else
      this.flagInvalidDate(this.getFullDate(time));
  }

  flagInvalidDate(date: Date): void {
    this.validTime = false;
    this.invalidDateSelected.emit(date);
  }

  /**
   * Returns whether or not the 'time' currently selected is valid.
   */
  private updateTime(): boolean {
    if (this.timeComponent != undefined) {
      let sd = this.initTimeOnDate(this.selectedDate);
      // If the selected date is equal to or earlier than minimum date
      if (sd != undefined && this.minDate != undefined
        && sd.getUTCDate() <= this.minDate.getUTCDate()) {
        // If no time specified yet, or time is smaller than minimum time
        if (!this.currentTime || this.currentTime <= this.timeComponent.actualMinTime) {
          this.currentTime = this.timeComponent.actualMinTime;
        }
      }
      // This field is normally kept up to date through @Input(), however on the moment of validate time it is not yet up to date
      this.timeComponent.checkMinimum = this.isMinDay();
      return this.timeComponent.validateTime();
    }
    return true;
  }
}

/**
 * Format the date according to the international notation ('yyyy-mm-dd').
 *
 * @param d date to format
 */
export function formatDate(d: Date): string {
  const day = d.getDate().toLocaleString(undefined, {minimumIntegerDigits: 2});
  const month = (d.getMonth() + 1).toLocaleString(undefined, {minimumIntegerDigits: 2});
  const year = d.getFullYear();
  return `${year}-${month}-${day}`;
}

/**
 * Format the date-time according to the international notation ('yyyy-mm-ddThh:mm').
 *
 * @param d date to format
 */
export function formatDateForDatabase(d: Date): string {
  const day = d.getDate().toLocaleString(undefined, {minimumIntegerDigits: 2});
  const month = (d.getMonth() + 1).toLocaleString(undefined, {minimumIntegerDigits: 2});
  const year = d.getFullYear();
  return `${year}-${month}-${day}T${d.getHours()}:${d.getMinutes()}`;
}
