<div>
  <div style="display: flex; justify-content: space-between;">

    <div *ngIf="taskFile" style="flex: 1 1 auto;">
      <h1>{{taskFile.task.activity}} - {{taskFile.filename}}</h1>
      <div style="width: 50%;">
        <p *ngIf="taskFile.task.duedate" class="dataLine">
          <span class="dataKey">Due date:</span>
          <span class="dataValue">{{taskFile.task.duedate|date:'EEE, MMM dd, y, HH:mm'}}</span>
        </p>
        <p *ngIf="taskFile.task.expecteddate" class="dataLine">
          <span class="dataKey">Expected completion date:</span>
          <span class="dataValue">{{taskFile.task.expecteddate|date:'EEE, MMM dd, y, HH:mm'}}</span>
        </p>
        <p *ngIf="taskFile.task.completed" class="dataLine">
          <span class="dataKey">Actual completion date:</span>
          <span class="dataValue">{{taskFile.task.completed|date:'EEE, MMM dd, y, HH:mm'}}</span>
        </p>
      </div>
      <p *ngIf="warning" style="color: red;">{{warning}}</p>
    </div>
    <div class="editorButtons">
      <button class="editorButton" title="Back to task page"
              (click)="backToTask()"
      ><img src="../../assets/back_arrow_white.png"></button>
      <button class="editorButton" title="Complete & send file"
              (click)="saveTaskFile()"
              [disabled]="!enableFinalize()"
      ><img src="../../assets/checkmark_white.png">Finalize
      </button>
    </div>
  </div>

  <editor *ngIf="taskFile && taskId"
          [sourceLang]="taskFile.task.sourcelanguage" [targetLang]="taskFile.task.targetlanguage"
          [fileLocation]="taskFile.filelocation" [fileGroupId]="taskId" [projectId]="taskFile.task.projectId" [fileName]="taskFile.filename"
          [editable]="editable()" #editor></editor>
  <div style="display: flex">
    <div style="width: 90%;">
      <p *ngIf="warning" style="color: red;">{{warning}}</p>
    </div>
    <div class="editorButtons" >
      <button class="editorButton" title="Back to task page"
              (click)="backToTask()"
      ><img src="../../assets/back_arrow_white.png"></button>
      <button class="editorButton" title="Complete & send file"
              (click)="saveTaskFile()"
              [disabled]="!enableFinalize()"
      ><img src="../../assets/checkmark_white.png">Finalize
      </button>
    </div>
  </div>
</div>
