<div class="accordion languageBar">
  <div (click)="toggleCollapse()" [ngClass]="{languageBarTitle: true, languageBarTitleCollapse: isCollapsed}">
    <div style="display: flex; flex: 1 1  auto; align-items: center;">
      <img src="../../assets/icon_arrow_down.svg"
           [ngClass]="{'collapseIcon': true, 'collapseIconOpen': !isCollapsed, 'collapseIconClosed': isCollapsed}"/>
      <h5>{{langpair.target|languagePipe|async}}</h5>
    </div>
    <div class="translatorsSmall">
      <div class="profilepic" *ngIf="translatorUser === undefined">
        <img alt="No translator" src="../../../assets/profile_icon.svg" title="No translator selected"/>
      </div>
      <mugshot *ngIf="translatorUser !== undefined" [user]="translatorUser" [width]="'30px'" [height]="'30px'"
               [showIncomplete]="false"></mugshot>
      <div class="profilepic" *ngIf="reviserUser === undefined">
        <img alt="No reviser" src="../../../assets/profile_icon.svg" title="No reviser selected"/>
      </div>
      <mugshot *ngIf="reviserUser !== undefined" [user]="reviserUser" [width]="'30px'" [height]="'30px'"
               [showIncomplete]="false"></mugshot>
      <div *ngIf="revisersCount > 1" class="extraRevCount" title="{{revisersCount-1}} more revisers selected">
        +{{revisersCount - 1}}</div>
    </div>
    <div class="totalPrice" *ngIf='!isQuote'>
      <price [price]="getTotalPrice()"></price>
    </div>
  </div>
  <div id="collapseBasic" [ngClass]="{'languageBarDetails': true, 'languageBarDetailsCollapse': isCollapsed}">
    <div class="translatorSelector">
      <h3>Translator</h3>
      <div *ngIf="translationTask == undefined || translationTask.senddate == undefined">
        <translator-list [translators]="translators"
                         [service]="service"
                         [activity]="'Translation'" [projectId]="projectId" [langpair]="langpair"
                         [duedate]="duedate"
                         [selected]="translationTask != undefined? translationTask.vendorId : undefined"
                         [expanded]="!isCollapsed"
                         [isQuote] = "isQuote"
                         (onSelectionChange)="onTranslatorSelected($event)"
                         (onSelectedPriceChange)="recalcTotalPricing()"></translator-list>
      </div>
      <div *ngIf="translationTask != undefined && translationTask.senddate != undefined">
        <project-task [task]="translationTask" [isQuote]="isQuote"
                      (taskChangeEmitter)="handleTaskChange($event, 'Translation', undefined)"></project-task>
      </div>
    </div>
    <div *ngIf="service === undefined || service === 'Translation'">
      <div style="text-align: center; margin-top: 5px;"
          *ngIf="!projectFinal && revisionRounds[0] === undefined && filteredRevisers !== undefined && filteredRevisers[0] !== undefined && filteredRevisers[0].length > 0">
        <!--Only show when there are still revisers left (from the filter)-->
        <button class="addReviser" (click)="addRevision(0)">+ Add a reviser</button>
      </div>
      <div class="translatorSelector" *ngFor="let sequence of revisionRounds; let i = index">
        <div
          *ngIf="!projectFinal && (revisionTasks[i] == undefined || revisionTasks[i].senddate == undefined) && filteredRevisers !== undefined && filteredRevisers[i] !== undefined && filteredRevisers[i].length > 0"
          [ngClass]="{listSection: true, disabledSection: revisionRounds[i] === undefined}">
          <h3 *ngIf="revisionRounds[i] !== undefined">Reviser</h3>
          <translator-list [translators]="getFilteredRevisers(i)"
                          [service]="service"
                          [activity]="'Revision'" [projectId]="projectId" [langpair]="langpair"
                          [duedate]="duedate"
                          [selected]="revisionTasks[i] != undefined? revisionTasks[i].vendorId : undefined"
                          [expanded]="!isCollapsed"
                          [loadPrices]="revisionRounds[i] !== undefined"
                          [isQuote] = "isQuote"
                          (onSelectionChange)="onReviserSelected(i, $event)"
                          (onSelectedPriceChange)="recalcTotalPricing()"></translator-list>
        </div>
        <div *ngIf="revisionTasks[i] != undefined && revisionTasks[i].senddate != undefined">
          <h3>Reviser</h3>
          <project-task [task]="revisionTasks[i]"
                        (taskChangeEmitter)="handleTaskChange($event, 'Revision', i)"></project-task>
        </div>
        <!--Only if there is a current task
              and if there are 2 or more revisers (the filter can only remove 1, it is always an option)-->
        <button class="addReviser" (click)="addRevision(i+1)"
                *ngIf="revisionTasks[i] !== undefined && revisionRounds[i+1] === undefined && revisers != undefined && revisers.length > 1">
          + Add an extra reviser
        </button>
      </div>
    </div>
    <div class="nextButtonBar">
      <button (click)="goToNext()" style="font-weight: 700; float: right;">Next</button>
    </div>
  </div>
</div>
