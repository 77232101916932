export class Job {
  pId: string;
  file: string;
  source: string;
  target: string;
  completed: string;
  vendor: string;
  tasktypes: string[] = ["Translation"];

  getLangPair(): string {
    return this.source + "_" + this.target;
  }
}

export class TaskSpec {
  projectId: string;
  sourcelanguage: string;
  targetlanguage: string;
  activity: string;
  service: string;
}

export class Task {
  vendor: string;
  vendorId: string;
  activity: string;
  service: string;
  sequence: number;
  sourcelanguage: string;
  targetlanguage: string;
  price: number;
  basePrice: number;
  percentageSet: number;
  duedate: Date;
  senddate: Date;
  canceldate: Date;
  acceptdate: Date;
  rejectdate: Date;
  counterProposal: any;
  completed: Date;
  expecteddate: Date;
  comments: string;
  jobs: Job[] = [];
  title: string;
  projectId: string;
  id: string;
}

export class TaskFile {
  task: Task;
  filelocation: string;
  filename: string;
  //wordcount: string;
}

export class Pricing {
  translation: number;
  revision: number;
  mt: number;
  lilo: number;
  vatRate: number;
  credit: any;
  /**
   * The 'mtsaving' field is informative only, and is not to be used when calculating the total price.
   * It is already included in the price for translation (since the translator can offer a rebate for it).
   */
  mtsaving: number;
  /**
   * The 'tmsaving' field is informative only, and is not to be used when calculating the total price.
   */
  tmsaving: number;

  taskCount: number;


  public mtSavings(): number {
    // Subtract the cost of MT from the savings to know what you really save in total thanks to MT
    if (this.mtsaving)
      return this.mtsaving - this.mt;
    return null;
  }

  public mtCost(): number {
    // Get the MT savings (if any) and revert them
    // If no savings, use the base MT cost
    let mtSavings = this.mtSavings();
    if (mtSavings)
      return mtSavings * -1;
    else
      return this.mt;
  }

  public totalSavings(): number {
    let mtSavings = this.mtSavings();
    return (this.tmsaving != undefined ? this.tmsaving : 0) + (mtSavings != undefined ? mtSavings : 0)
  }

  public totalExclFee(): number {
    return (this.translation != undefined ? this.translation : 0) + (this.revision != undefined ? this.revision : 0)
  }

  public total(inclCredit: boolean = true): number {
    let creditSaving = 0;
    if (inclCredit && this.credit != null && this.credit.saving != null) {
      creditSaving = this.credit.saving;
    }
    return this.translation + this.revision + this.mt + this.lilo - creditSaving;
  }

  public vatTotal(): number {
    if (this.vatRate)
      return this.total() * this.vatRate;
    else
      return 0;
  }

  public totalIncVat(): number {
    return this.total() + this.vatTotal();
  }
}

export class DetailedPricing extends Pricing {
  langPairDetails: any;


  public totalWithTasksOnly(): number {
    if (this.langPairDetails != undefined) {
      return Object.keys(this.langPairDetails)
        .map((v, i, a) => v)
        .map(v => this.langPairDetails[v])
        .map(p => <Pricing>p)
        .filter(p => p.taskCount > 0)
        .map(p => p.total())
        .reduce((p1, p2) => p1 + p2, 0)
    }
    return this.taskCount > 0 ? this.total() : 0;
  }

  public totalInclVATWithTaskOnly(): number {
    return this.totalWithTasksOnly()*(this.vatRate+1)
  }
}
