<div style="display: flex; width: 100%; text-align: left;">
  <div *ngIf="sortedReviews != undefined" style="width: inherit;">

    <div *ngIf="sortedReviews.length > 0" style="display: flex; width: inherit; align-items: center;" #reviewScrollList
         (window:resize)="calcItemWidth()">
     <span #scrollLeftArrow (click)="scrollLeft()"
           [ngClass]="{'scrollerArrow': true, 'scrollerArrowVisible': currentIndex > 0}">
        <img src="../../../assets/icon_scroll.svg" style="transform: rotate(90deg);" alt="scroll left">
      </span>
      <div class="reviewScroller" id="reviewList">
        <div *ngFor="let rev of sortedReviews; let i = index"
             class="reviewScrollItemWrapper"
             [style.width.px]="scrollerWidth != undefined? scrollerWidth:'auto'"
             [style.transform]="'translateX(' + getTransX() + 'px)'">
          <review-item id="reviewItem_{{i}}" [attr.data-index]="i" [index]="i"
                       [review]="rev" [currentUser]="user"
                       [ngClass]="{'reviewScrollItem': true}" style="max-width: 75%;"
          ></review-item>
        </div>
      </div>
      <span #scrollRightArrow (click)="scrollRight()"
            [ngClass]="{'scrollerArrow': true, 'scrollerArrowVisible': sortedReviews.length > 1 && currentIndex < (sortedReviews.length -1)}">
        <img src="../../../assets/icon_scroll.svg" style="transform: rotate(-90deg);" alt="scroll right">
      </span>
    </div>

    <div *ngIf="sortedReviews.length === 0" class="noReviews">
      <p>No reviews yet.</p>
    </div>

  </div>
</div>
