import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from "@angular/core";


@Component({
  selector: 'new-message',
  templateUrl: './new.message.component.html',
  styleUrls: ['./chat.component.css'],
})
export class NewMessageComponent {

  @Input() placeholderText;
  @Input() user;
  @Output() sendEmitter = new EventEmitter<any>();
  @ViewChild('newMessageArea', {static: false}) newMessageTxtArea: ElementRef;

  userId;
  userName;
  message;

  ngOnInit() {
    if (this.user != undefined) {
      this.userId = this.user.id;
      this.userName = this.user.firstName + " " + this.user.lastName;
    }
  }

  processInput(event, location) {
    if (event.ctrlKey && event.key === 'Enter') {
      // If CTRL + Enter, enter a newline at the specified location (if none, just add it to the back)
      if (location != null && location >= 0 && location < this.message.length) {
        let newText = this.message.substring(0, location);
        newText += '\n';
        newText += this.message.substring(location);
        this.message = newText;
      } else
        this.message += '\n';
    } else if (event.key === 'Enter') {
      // If normal Enter, "consume" the event and send the message
      event.preventDefault();
      this.sendMessage();
    }
  }

  sendMessage() {
    this.sendEmitter.emit({
      text: this.message,
      user: {
        id: this.userId,
        name: this.userName
      }
    });
    this.message = undefined;
  }
}
