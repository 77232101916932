import {Component, EventEmitter, Input, Output} from "@angular/core";

@Component({
  selector: 'rating',
  template: `<span  (mouseout)="editingRating = rating" (click)="setRate()">
  <span (mouseover)="rate(1)" class="fa fa-star {{starChecked(1)}}"></span>
  <span (mouseover)="rate(25)" class="fa fa-star {{starChecked(25)}}"></span>
  <span (mouseover)="rate(50)" class="fa fa-star {{starChecked(50)}}"></span>
  <span (mouseover)="rate(75)" class="fa fa-star {{starChecked(75)}} "></span>
  <span (mouseover)="rate(100)" class="fa fa-star {{starChecked(100)}}"></span>
  </span>`,
  styleUrls: ["./rating.component.css"]
})


export class RatingComponent {
  @Input()
  rating: number;

  editingRating = 0;

  @Output()
  onStarSelected = new EventEmitter<number>();

  ngOnInit() {
    this.editingRating = this.rating;
  }

  public starChecked(from: number): string {
    if (this.editingRating >= from) {
      return "checked"
    } else return "unchecked"
  }

  public rate(v: number) {
    // if(this.editing)
    this.editingRating = v;
  }

  public setRate() {
    this.rating = this.editingRating;
    this.onStarSelected.emit(this.rating);
  }
}
