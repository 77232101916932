import {filter, map, switchMap} from 'rxjs/operators';
import {Component} from "@angular/core";
import {UserService} from "../service/user.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {SimpleDialog} from "../simple-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {InvoiceService} from "../service/invoice.service";
import {environment} from "../../environments/environment";


@Component({
  selector: "user-settings",
  templateUrl: './user.settings.component.html',
  styleUrls: ['./user.settings.component.css'],
})
export class UserSettingsComponent {

  public user;
  page = "profile";

  hasPaymentMethod = false;
  accessAllowed: boolean = undefined;
  isDebug = environment.isDebug;

  constructor(private route: ActivatedRoute,
              public userService: UserService,
              public invoiceService: InvoiceService,
              private router: Router,
              private dialog: MatDialog,) {

  }

  ngOnInit(): void {
    this.route.params.pipe(
      switchMap((params: Params) => this.userService.getUser(params['id'])))
      .subscribe(user => this.setUser(user));
    this.route.params.pipe(map(p => p['page']),
      filter(p => p != null),)
      .subscribe(p => this.page = p);
  }

  setUser(user) {
    this.user = user;
    this.invoiceService.hasPaymentMethodRecord(this.user.id).subscribe(
      v => {
        this.hasPaymentMethod = v;
      },
      e => this.hasPaymentMethod = false
    );
    this.setAccessAllowed();
    this.userService.loginEmitter.subscribe(v => this.setAccessAllowed());
  }

  private setAccessAllowed() {
    // Only the current user is allowed access to his/her own data, or an admin
    this.accessAllowed = (this.userService.isLoggedIn() &&
      (this.userService.isCurrentUser(this.user) || this.userService.isCurrentUserAdmin));
    // console.debug("Access allowed to " + this.user.id + "? " + this.accessAllowed + " (is logged in? " + this.userService.isLoggedIn() + ") (is current user? " + this.userService.isCurrentUser(this.user) + ") (is admin? " + this.userService.isCurrentUserAdmin + ")");
  }

  goto(page: string) {
    this.page = page;
    this.scrollToTop()
  }

  getPage() {
    return this.page;
  }

  onCompanyEvent(company: any) {
    this.user.companyId = company.id;
    this.user.companyName = company.name;
    this.userService.updateUserCompanyInfo(this.user).subscribe();
  }

  isMailAuthenticated(): boolean {
    return this.user && this.user.mailAuthKey != null;
  }

  isTranslator(): boolean {
    return this.userService.isUserTranslator(this.user);
  }

  logout() {
    this.subscribeToLogout();
  }

  public subscribeToLogout() {
    this.userService.signOut().subscribe({
      next: () => {
        this.user = null;
        let dialogRef = this.dialog.open(SimpleDialog, {
          width: "400px",
          panelClass: 'simpleDialog',
          data: {dialogMessage: "You have successfully logged out."}
        });
        dialogRef.afterClosed().subscribe(r =>
          this.router.navigateByUrl("")
        )
      },
      complete: () => {
        // Completed
      }
    });
  }

  scrollToTop() {
    window.scrollTo(0, 0);
  }

}
