import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Component, Inject} from "@angular/core";


@Component({
  selector: 'waiting-dialog',
  template: `
    <div>
        <h4 [innerHTML]=waitingText></h4>
        <div style="display: flex; justify-content: center; align-items: center;">
            <img src="../assets/loading.gif" style="width: 150px; height: 150px;">
        </div>
    </div>`,
})
export class WaitingDialog {

  waitingText: string;

  constructor(public dialogRef: MatDialogRef<WaitingDialog>,
              @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  ngOnInit(): void {
    this.waitingText = this.data.waitingText;
  }
}


