/**
 * Gets the corresponding image for the given "payment method".
 */
export function getPaymentImage(paymentMethod: string, disabled: boolean = false) {
  // Values for 'brand' field on 'card' object (from Stripe):
  // American Express, Diners Club, Discover, JCB, MasterCard, UnionPay, Visa, or Unknown.
  // (https://stripe.com/docs/api#card_object)
  const paymentMethodLowered = paymentMethod.toLowerCase()
  let suffix = (disabled ? "_disabled" : "");
  if (paymentMethodLowered === 'visa')
    return "visa-curved-128px" + suffix + ".png";
  if (paymentMethodLowered === 'mastercard')
    return "mastercard-curved-128px" + suffix + ".png";
  if (paymentMethodLowered === 'american express')
    return "american-express-curved-128px" + suffix + ".png";
  if (paymentMethodLowered === 'discover')
    return "discover-curved-128px" + suffix + ".png";
  // if (paymentMethod == 'Diners Club')
  //   return "mastercard-curved-128px" + suffix + ".png";
  // if (paymentMethod == 'JCB')
  //   return "mastercard-curved-128px" + suffix + ".png";
  // if(paymentMethod == 'UnionPay')
  //   return "mastercard-curved-128px" + suffix + ".png";
  return "icons8-credit-card-100" + suffix + ".png";
}

/**
 * Determines the best text color (black or white) for the given background color.
 *
 * @param hexBgColor background color in HEX format
 */
export function getTextColor(hexBgColor: string) {
  if (hexBgColor.indexOf('#') === 0) {
    hexBgColor = hexBgColor.slice(1);
  }
  // Convert 3-digit hex to 6-digits.
  if (hexBgColor.length === 3) {
    hexBgColor = hexBgColor[0] + hexBgColor[0] + hexBgColor[1] + hexBgColor[1] + hexBgColor[2] + hexBgColor[2];
  }
  if (hexBgColor.length !== 6) {
    throw new Error('Invalid HEX color.');
  }
  var r = parseInt(hexBgColor.slice(0, 2), 16),
    g = parseInt(hexBgColor.slice(2, 4), 16),
    b = parseInt(hexBgColor.slice(4, 6), 16);
  // http://stackoverflow.com/a/3943023/112731
  return (r * 0.299 + g * 0.587 + b * 0.114) > 186
    ? '#000000'
    : '#FFFFFF';
}
