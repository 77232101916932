<div class="main" *ngIf="user !== undefined">

  <div class="section">
    <div class="sectionLeft mugshot">
      <mugshot [user]="user" [width]="'150px'" [height]="'150px'" [showIncomplete]="false"></mugshot>
    </div>
    <div class="sectionRight">
      <h1>Hello!<br/>I'm <span class="highlight">{{getUserName()}}</span>.</h1>
    </div>
  </div>

  <div class="section" *ngIf="showDescription()">
    <div class="sectionLeft">
      <h3 class="title">Allow me to introduce myself.</h3>
    </div>
    <div class="sectionRight">
      <p style="white-space: pre-wrap;">{{user.description}}</p>
    </div>
  </div>

  <div class="section" *ngIf="showLocation()">
    <div class="sectionLeft">
      <!-- todo: implement google maps! => https://blog.ng-book.com/angular-and-google-maps-a-tutorial/ -->
      <img src="../../assets/artwork/magnifier.png" style="width: 200px;" alt="your lilo translator">
    </div>
    <div class="sectionRight">
      <h3 class="title">
        <span *ngIf="hasMotherTongue()">My native language is <span
          class="highlight">{{user.motherTongue|languagePipe|async}}</span></span>
        <span *ngIf="hasMotherTongue() && !hasLocation()">.</span>
        <span *ngIf="hasMotherTongue() && hasLocation()">, and </span>
        <span *ngIf="hasLocation()">I live in <span *ngIf="user.city">{{user.city}}, </span>{{user.country}}.</span>
      </h3>
    </div>
  </div>

  <div class="section" *ngIf="competenceGroups.length > 0">
    <div class="sectionLeft">
      <table class="competenceTable">
        <tr *ngFor="let type of getTaskActivities()" class="mainRow">
          <td>I&nbsp;<span class="highlight" style="font-weight: bold;">{{getActivityVerb(type)}}</span>&nbsp;from&nbsp;
          </td>
          <td>
            <table>
              <tr *ngFor="let source of getSourceLanguages(type)" class="langPairBlock">
                <td><span style="min-width: 80px;" class="langItem">{{source.name}}</span>&nbsp;to&nbsp;</td>
                <td>
                  <ul>
                    <li *ngFor="let target of getTargetLanguages(type, source.code)"
                        class="langItem">{{target.name}}</li>
                  </ul>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </div>
    <div class="sectionRight">
      <div *ngIf="user.domains && user.domains.length > 0" class="domains" title="My expertise">
        <p *ngFor="let domain of user.domains">{{domain}}</p>
      </div>
      <div *ngIf="!user.domains || user.domains.length == 0">
        <img src="../../assets/artwork/translate.png" style="width: 200px;" alt="translation with lilo">
      </div>
    </div>
  </div>

  <div class="section singleSection" *ngIf="reviews">
    <h3 class="title">What people are saying about me.</h3>
    <review-scroller [reviews]="reviews" [user]="user" style="width: 100%;"></review-scroller>
  </div>

  <!--  todo: implement a section where people can drop their files and order a project with this translator ("Interested? Order a project with me.") -->
  <!--  todo: implement a section with some stats: # tasks done, # words translated, on time delivery %, replies within X days, ... -->

</div>

<div class="main loading" *ngIf="user === undefined">
  <img src="../../assets/loading_logo_1.gif" style="width: 100px;">
  <span>Locating user ...</span>
</div>
