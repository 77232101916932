/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./help.dialog.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./safehtml.pipe";
import * as i3 from "@angular/platform-browser";
import * as i4 from "@angular/material/dialog";
import * as i5 from "@angular/common";
import * as i6 from "./help.dialog.component";
var styles_HelpDialogComponent = [i0.styles];
var RenderType_HelpDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HelpDialogComponent, data: {} });
export { RenderType_HelpDialogComponent as RenderType_HelpDialogComponent };
function View_HelpDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(1, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 0, 0, _ck(_v, 1, 0, i1.ɵnov(_v.parent, 0), _co.helpHtmlContent)); _ck(_v, 0, 0, currVal_0); }); }
function View_HelpDialogComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "iframe", [["style", "width: 100%; height: 300px;"]], [[8, "src", 5]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.helpHtmlUrl, ""); _ck(_v, 0, 0, currVal_0); }); }
export function View_HelpDialogComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.SafeHtmlPipe, [i3.DomSanitizer]), (_l()(), i1.ɵeld(1, 0, null, null, 3, "h2", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(2, 81920, null, 0, i4.MatDialogTitle, [[2, i4.MatDialogRef], i1.ElementRef, i4.MatDialog], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["src", "../../assets/icons8-help-32.png"], ["style", "vertical-align: bottom;"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), (_l()(), i1.ɵeld(5, 0, null, null, 6, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(6, 16384, null, 0, i4.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [["id", "helptext"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HelpDialogComponent_1)), i1.ɵdid(9, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HelpDialogComponent_2)), i1.ɵdid(11, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 4, "mat-dialog-actions", [["class", "mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(13, 16384, null, 0, i4.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(14, 0, null, null, 2, "div", [["style", "width: 100%; text-align: center;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 1, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeDialog() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Close"]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_2 = _co.helpHtmlContent; _ck(_v, 9, 0, currVal_2); var currVal_3 = _co.helpHtmlUrl; _ck(_v, 11, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 2).id; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.getTitle(); _ck(_v, 4, 0, currVal_1); }); }
export function View_HelpDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "help-dialog", [], null, null, null, View_HelpDialogComponent_0, RenderType_HelpDialogComponent)), i1.ɵdid(1, 114688, null, 0, i6.HelpDialogComponent, [i4.MatDialogRef, i4.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HelpDialogComponentNgFactory = i1.ɵccf("help-dialog", i6.HelpDialogComponent, View_HelpDialogComponent_Host_0, {}, {}, []);
export { HelpDialogComponentNgFactory as HelpDialogComponentNgFactory };
