/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../project.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../util/lang-select.component.ngfactory";
import * as i3 from "../../util/lang-select.component";
import * as i4 from "@angular/common";
import * as i5 from "./langpair-select.component";
var styles_LangpairSelectComponent = [i0.styles];
var RenderType_LangpairSelectComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LangpairSelectComponent, data: {} });
export { RenderType_LangpairSelectComponent as RenderType_LangpairSelectComponent };
function View_LangpairSelectComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["style", "display: flex;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "langpairSelect"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["from "])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "lang-select", [], null, [[null, "eventEmitter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("eventEmitter" === en)) {
        var pd_0 = (_co.onSourceLanguageUpdate($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_LangSelectComponent_0, i2.RenderType_LangSelectComponent)), i1.ɵdid(5, 114688, [[1, 4], ["sourcelangselect", 4]], 0, i3.LangSelectComponent, [], { selectedLangs: [0, "selectedLangs"], single: [1, "single"], dialect: [2, "dialect"] }, { eventEmitter: "eventEmitter" }), (_l()(), i1.ɵeld(6, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" to "])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "lang-select", [], null, [[null, "eventEmitter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("eventEmitter" === en)) {
        var pd_0 = (_co.onTargetLanguageUpdate($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_LangSelectComponent_0, i2.RenderType_LangSelectComponent)), i1.ɵdid(9, 114688, [[2, 4], ["targetlangselect", 4]], 0, i3.LangSelectComponent, [], { selectedLangs: [0, "selectedLangs"] }, { eventEmitter: "eventEmitter" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedParameters["sourceLanguage"]; var currVal_1 = true; var currVal_2 = false; _ck(_v, 5, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.selectedParameters["targetLanguages"]; _ck(_v, 9, 0, currVal_3); }, null); }
function View_LangpairSelectComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["style", "display: flex;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "langpairSelect"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Language "])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "lang-select", [], null, [[null, "eventEmitter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("eventEmitter" === en)) {
        var pd_0 = (_co.onSourceLanguageUpdate($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_LangSelectComponent_0, i2.RenderType_LangSelectComponent)), i1.ɵdid(5, 114688, [[1, 4], ["sourcelangselect", 4]], 0, i3.LangSelectComponent, [], { selectedLangs: [0, "selectedLangs"], single: [1, "single"], dialect: [2, "dialect"] }, { eventEmitter: "eventEmitter" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedParameters["sourceLanguage"]; var currVal_1 = true; var currVal_2 = true; _ck(_v, 5, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_LangpairSelectComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "nextButton"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.emitParameters() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Next "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.selectedParameters.isValid(); _ck(_v, 0, 0, currVal_0); }); }
export function View_LangpairSelectComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { sourceLangSelect: 0 }), i1.ɵqud(671088640, 2, { targetLangSelect: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["style", "display: flex; align-items: flex-start;"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LangpairSelectComponent_1)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LangpairSelectComponent_2)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LangpairSelectComponent_3)), i1.ɵdid(8, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.selectedParameters.getService() === "Translation"); _ck(_v, 4, 0, currVal_0); var currVal_1 = (_co.selectedParameters.getService() === "Editing"); _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.hasChanges; _ck(_v, 8, 0, currVal_2); }, null); }
export function View_LangpairSelectComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "param-select", [], null, null, null, View_LangpairSelectComponent_0, RenderType_LangpairSelectComponent)), i1.ɵdid(1, 114688, null, 0, i5.LangpairSelectComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LangpairSelectComponentNgFactory = i1.ɵccf("param-select", i5.LangpairSelectComponent, View_LangpairSelectComponent_Host_0, { selectedParameters: "selectedParameters" }, { eventEmitter: "eventEmitter", changeEmitter: "changeEmitter" }, []);
export { LangpairSelectComponentNgFactory as LangpairSelectComponentNgFactory };
