<div *ngIf="file != undefined" [title]="file.name" class="taskFileCard">

  <div class="taskFileFront">

    <div class="taskFileImage">
      <div class="taskFileImageWrapper">
        <frontpage [pId]="this.task.projectId" [fileName]="file.name" class="taskFileFrontpage"></frontpage>
      </div>
    </div>
    <div class="taskFileDetails">
      <p class="taskFileStatus" style="margin-bottom: 5px;">
        <span *ngIf="isJobCompleted" style="color: var(--soft-green)">Completed</span>
        <span *ngIf="!isJobCompleted && isJobAvailable != undefined && !isJobAvailable" style="color: var(--dark-gray)">
        File not ready yet</span>&nbsp;
      </p>
      <h6>{{file.name|filenamePipe:25:true}}</h6>
      <p style="margin-top: 5px;">{{getWordCount()}}
        <span *ngIf="isCharBasedSource()">characters</span>
        <span *ngIf="!isCharBasedSource()">words</span>
      </p>
    </div>

    <div class="taskFileHover">
      <div class="taskFileHoverActions">
        <div *ngIf="!isManual()">
          <div class="btnDiv" style="text-align: center; margin-bottom: 5px;">
            <button *ngIf="enableEditor()" class="btnSmall"
                    [disabled]="!isXliffAvailable" (click)="openInEditor()" title="Open file in editor">
              open in editor
            </button>
          </div>
          <p *ngIf="enableEditor()">- OR -</p>
          <div class="btnDiv" style="text-align: center; margin-bottom: 5px;">
            <button class="btnSmall" style="flex: 1 1 100px;" title="Download XLIFF file"
                    [disabled]="!isXliffAvailable" (click)="downloadXliff()">
              <img src="../../assets/download_white.png"/>download XLIFF
            </button>
            <div *ngIf="task.acceptdate" style="display: flex; flex: 1 1 100px;">
              <button class="btnSmall" style="flex: 1 1 100px;" title="Upload translated XLIFF file"
                      [disabled]="!isXliffAvailable" (click)="openUploadDialog()">
                <img src="../../assets/upload_white.png">upload XLIFF
              </button>
            </div>
          </div>
        </div>
        <div *ngIf="isManual()">
          <p>Unfortunately, we were not able to create a translatable XLIFF for this file.<br/>
            This file will need to be handled in the original file format.</p>
          <div class="btnDiv">
            <button (click)="downloadOrig()" class="btnSmall"
                    title="Download source file">
              <img src="../../assets/download_white.png"/>download original
            </button>
            <button *ngIf="task.acceptdate" class="btnSmall" (click)="openManualUploadDialog()"
                    title="Upload translated file">
              <img src="../../assets/upload_white.png">upload translation
            </button>
          </div>
        </div>
      </div>
      <div class="moreFilesDiv" *ngIf="!task.rejectdate">
        <p>Additional file(s)</p>
        <div class="btnDiv">
          <button class="btnSmall"
                  *ngIf="allowOrigFileDownload()" (click)="downloadOrig()" title="Download original file">
            <img src="../../assets/download_white.png"/>Original
          </button>
          <button class="btnSmall"
                  [disabled]="!isPDFAvailable" (click)="downloadPDF()" title="Download PDF version">
            <img src="../../assets/download_white.png"/>PDF
          </button>
          <button class="btnSmall"
                  [disabled]="!isTMXAvailable" (click)="downloadTMX()" title="Download TMX file">
            <img src="../../assets/download_white.png"/>TMX
          </button>
        </div>
      </div>
    </div>

  </div>

  <div *ngIf="!isJobCompleted && isJobAvailable != undefined && isJobAvailable" style="width: 100%;">
    <div *ngIf="!isManual()">
      <div id="taskDownloadActions" class="taskFileActions" *ngIf="!hasBegunTask()">
        <button *ngIf="enableEditor()" class="btnSmall btnAction" style="flex: 1 1 100px;"
                [disabled]="!isXliffAvailable"
                (click)="openInEditor()" title="Open file in editor">
          open in editor
        </button>
        <p *ngIf="enableEditor()" style="font-size: smaller;">- OR -</p>
        <button class="btnSmall btnAction" style="flex: 1 1 100px;" [disabled]="!isXliffAvailable"
                (click)="downloadXliff()" title="Download XLIFF file">
          download XLIFF
        </button>
      </div>
      <div id="taskUploadActions" class="taskFileActions" *ngIf="hasBegunTask()">
        <button *ngIf="enableEditor() && !hasDownloadedLast()" class="btnSmall btnAction" style="flex: 1 1 100px;"
                [disabled]="!isXliffAvailable"
                (click)="openInEditor()" title="Open file in editor">
          open in editor
        </button>
        <button *ngIf="!enableEditor() || hasDownloadedLast()" class="btnSmall btnAction" style="flex: 1 1 100px;"
                [disabled]="!isXliffAvailable"
                (click)="openUploadDialog()" title="Upload translated XLIFF file">
          upload translated XLIFF
        </button>
      </div>
    </div>
    <div *ngIf="isManual()" class="taskFileActions">
      <button class="btnSmall btnAction" style="flex: 1 1 100px;"
              (click)="downloadOrig()" title="Download original file">
        download original
      </button>
      <button *ngIf="task.acceptdate != undefined" class="btnSmall btnAction" style="flex: 1 1 100px;"
              (click)="openManualUploadDialog()" title="Upload translated file">
        upload translation
      </button>
    </div>
  </div>
</div>
