import {Pipe, PipeTransform} from '@angular/core';


@Pipe({name: 'returnToBreakPipe'})
export class ReturnToBreakPipe implements PipeTransform {

  constructor() {

  }

  transform(value: string): string {
    if(value===undefined)
      return undefined;
    return value.replace(/\n/g, "<br/>");
  }
}
