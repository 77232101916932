<div>
  <div class="row">
    <h2>Add a period during which you are unavailable</h2>
  </div>
  <div class="row">
    <label>Start date</label>
    <input type="date" [min]="getFromMinDate()" [(ngModel)]="event.from"
           placeholder="Choose a date"/>
  </div>
  <div class="row">
    <label>End date</label>
    <input type="date" [min]="getToMinDate()" [(ngModel)]="event.to"
           placeholder="Choose a date"/>
  </div>
  <div class="row">
    <label>Description</label>
    <textarea
      placeholder="Customers will want to know whether you will be able to answer requests, so you can optionally describe why you are unavailable."
      [(ngModel)]="event.description"></textarea>
  </div>
  <div class="row">
    <button [disabled]="!isValid()" (click)="add()">Add</button>
  </div>
</div>
