<div>
  <h2>Translate your hubspot pages</h2>
  <div>
    <language-selector (onLanguageSelect)="languageSelected($event)" title='Language'></language-selector>
  </div>
  <div *ngIf="pages" class="boxing">
    <div *ngFor="let page of pages">
      <div *ngIf="page.language && page.language.startsWith(filterLanguage)" >
        <hubspot-page [page]="page" [allPages]="pages" (onSelect)="pageClick($event)"></hubspot-page>
      </div>
    </div>
  </div>

  <div *ngIf="selectedPages.length>0">
    <button (click)="submit()">Create project</button>
    <img *ngIf="creatingProject" src="../../assets/loading_small.gif"/>
  </div>
</div>
