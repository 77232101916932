/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./cost-calc.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../util/price.component.ngfactory";
import * as i3 from "../util/price.component";
import * as i4 from "../service/country.service";
import * as i5 from "@angular/common";
import * as i6 from "@angular/router";
import * as i7 from "../util/language.pipe";
import * as i8 from "../service/language.service";
import * as i9 from "../dropentrycomponent/pre-upload.component.ngfactory";
import * as i10 from "../dropentrycomponent/pre-upload.component";
import * as i11 from "../service/project.service";
import * as i12 from "../service/file.service";
import * as i13 from "../projectcomponent/util/upload-data.service";
import * as i14 from "../service/user.service";
import * as i15 from "../service/converter.service";
import * as i16 from "./cost-calc.component";
import * as i17 from "../service/pricing.services";
import * as i18 from "../service/task.service";
import * as i19 from "../service/analysis.service";
import * as i20 from "../service/vendor.service";
var styles_CostCalcComponent = [i0.styles];
var RenderType_CostCalcComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CostCalcComponent, data: {} });
export { RenderType_CostCalcComponent as RenderType_CostCalcComponent };
function View_CostCalcComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "h5", [["style", "margin-bottom: 25px;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Lowest price: "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "price", [], null, null, null, i2.View_PriceComponent_0, i2.RenderType_PriceComponent)), i1.ɵdid(3, 114688, null, 0, i3.PriceComponent, [i4.CountryService], { price: [0, "price"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.lowestPrices[_v.parent.parent.context.$implicit]; _ck(_v, 3, 0, currVal_0); }, null); }
function View_CostCalcComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["style", "margin-left: 50px; margin-top: 50px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "h4", [["style", "margin-bottom: 50px;"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["From ", " "])), i1.ɵppd(3, 1), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["to ", ""])), i1.ɵppd(7, 1), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(9, 0, null, null, 3, "h5", [["style", "margin-bottom: 25px;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["On average: "])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "price", [], null, null, null, i2.View_PriceComponent_0, i2.RenderType_PriceComponent)), i1.ɵdid(12, 114688, null, 0, i3.PriceComponent, [i4.CountryService], { price: [0, "price"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CostCalcComponent_3)), i1.ɵdid(14, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.prices[_v.parent.context.$implicit]; _ck(_v, 12, 0, currVal_2); var currVal_3 = (_co.lowestPrices[_v.parent.context.$implicit] !== undefined); _ck(_v, 14, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 4).transform(i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent, 0), _co.sourcelang)))); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 8).transform(i1.ɵunv(_v, 6, 0, _ck(_v, 7, 0, i1.ɵnov(_v.parent.parent, 0), _v.parent.context.$implicit)))); _ck(_v, 6, 0, currVal_1); }); }
function View_CostCalcComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CostCalcComponent_2)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.prices[_v.context.$implicit] !== undefined); _ck(_v, 2, 0, currVal_0); }, null); }
function View_CostCalcComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["style", "text-align: right;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["See more "])), (_l()(), i1.ɵeld(2, 0, null, null, 5, "a", [["routerLinkActive", "active"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 671744, [[2, 4]], 0, i6.RouterLinkWithHref, [i6.Router, i6.ActivatedRoute, i5.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(4, 1720320, null, 2, i6.RouterLinkActive, [i6.Router, i1.ElementRef, i1.Renderer2, [2, i6.RouterLink], [2, i6.RouterLinkWithHref]], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 1, { links: 1 }), i1.ɵqud(603979776, 2, { linksWithHrefs: 1 }), (_l()(), i1.ɵted(-1, null, ["details"])), (_l()(), i1.ɵted(-1, null, ["."]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = i1.ɵinlineInterpolate(1, "/pdetail/", _co.projectId, ""); _ck(_v, 3, 0, currVal_2); var currVal_3 = "active"; _ck(_v, 4, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).target; var currVal_1 = i1.ɵnov(_v, 3).href; _ck(_v, 2, 0, currVal_0, currVal_1); }); }
export function View_CostCalcComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i7.LanguagePipe, [i8.LanguageService]), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["style", "position: relative; display: flex;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "fileupload"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "pre-upload", [], null, [[null, "projectCreatedEmitter"], [null, "fileUploadedEmitter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("projectCreatedEmitter" === en)) {
        var pd_0 = (_co.setProjectId($event) !== false);
        ad = (pd_0 && ad);
    } if (("fileUploadedEmitter" === en)) {
        var pd_1 = (_co.setFile($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i9.View_PreUploadComponent_0, i9.RenderType_PreUploadComponent)), i1.ɵdid(4, 114688, null, 0, i10.PreUploadComponent, [i11.ProjectService, i12.FileService, i13.UploadDataService, i14.UserService, i15.ConverterService, i6.Router], null, { projectCreatedEmitter: "projectCreatedEmitter", fileUploadedEmitter: "fileUploadedEmitter" }), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["style", "display: flex;"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CostCalcComponent_1)), i1.ɵdid(8, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CostCalcComponent_4)), i1.ɵdid(10, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 4, 0); var currVal_0 = _co.targetLanguages; _ck(_v, 8, 0, currVal_0); var currVal_1 = (_co.projectId != undefined); _ck(_v, 10, 0, currVal_1); }, null); }
export function View_CostCalcComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "cost-calc", [], null, null, null, View_CostCalcComponent_0, RenderType_CostCalcComponent)), i1.ɵdid(1, 49152, null, 0, i16.CostCalcComponent, [i11.ProjectService, i17.PricingService, i18.TaskService, i12.FileService, i19.AnalysisService, i20.VendorService], null, null)], null, null); }
var CostCalcComponentNgFactory = i1.ɵccf("cost-calc", i16.CostCalcComponent, View_CostCalcComponent_Host_0, {}, {}, []);
export { CostCalcComponentNgFactory as CostCalcComponentNgFactory };
