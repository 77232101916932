/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./util/safehtml.pipe";
import * as i2 from "@angular/platform-browser";
import * as i3 from "@angular/common";
import * as i4 from "./confirm.dialog";
import * as i5 from "@angular/material/dialog";
var styles_ConfirmDialog = [".mainDiv[_ngcontent-%COMP%] { display: flex; flex-direction: column; align-items: flex-start; background-color: inherit; }", ".mainDiv[_ngcontent-%COMP%]   h1[_ngcontent-%COMP%] { margin: 5px 0 10px 0; }", ".mainDiv[_ngcontent-%COMP%]   h4[_ngcontent-%COMP%] { font-size: 18px; margin: 5px 0 10px 0; }", ".confirmHtmlDiv[_ngcontent-%COMP%] { margin: 5px 0 10px 0; }", ".buttons[_ngcontent-%COMP%] { width: 100%; display: flex; align-items: center; justify-content: space-evenly; margin-top: 25px; }", ".confirm[_ngcontent-%COMP%] { flex: 1 1 auto; max-width: 45%; }", ".cancel[_ngcontent-%COMP%] { flex: 1 1 auto; max-width: 45%; background-color: var(--alternative-color) }", ".cancel[_ngcontent-%COMP%]:hover { background-color: var(--alternative-hover-color) }"];
var RenderType_ConfirmDialog = i0.ɵcrt({ encapsulation: 0, styles: styles_ConfirmDialog, data: {} });
export { RenderType_ConfirmDialog as RenderType_ConfirmDialog };
function View_ConfirmDialog_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.confirmTitle; _ck(_v, 1, 0, currVal_0); }); }
function View_ConfirmDialog_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.confirmMessage; _ck(_v, 1, 0, currVal_0); }); }
function View_ConfirmDialog_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "confirmHtmlDiv"]], [[8, "innerHTML", 1]], null, null, null, null)), i0.ɵppd(1, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 0, 0, _ck(_v, 1, 0, i0.ɵnov(_v.parent, 0), _co.confirmHtml)); _ck(_v, 0, 0, currVal_0); }); }
export function View_ConfirmDialog_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i1.SafeHtmlPipe, [i2.DomSanitizer]), (_l()(), i0.ɵeld(1, 0, null, null, 17, "div", [["class", "mainDiv"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ConfirmDialog_1)), i0.ɵdid(3, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ConfirmDialog_2)), i0.ɵdid(5, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ConfirmDialog_3)), i0.ɵdid(7, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(8, 0, null, null, 10, "div", [["class", "buttons"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 4, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(11, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i0.ɵpod(12, { confirm: 0, cancel: 1 }), (_l()(), i0.ɵted(13, null, ["", ""])), (_l()(), i0.ɵeld(14, 0, null, null, 4, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(16, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i0.ɵpod(17, { cancel: 0, confirm: 1 }), (_l()(), i0.ɵted(18, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.confirmTitle; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.confirmMessage; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.confirmHtml; _ck(_v, 7, 0, currVal_2); var currVal_3 = _ck(_v, 12, 0, !_co.invertStyle, _co.invertStyle); _ck(_v, 11, 0, currVal_3); var currVal_5 = _ck(_v, 17, 0, !_co.invertStyle, _co.invertStyle); _ck(_v, 16, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.confirmText; _ck(_v, 13, 0, currVal_4); var currVal_6 = _co.cancelText; _ck(_v, 18, 0, currVal_6); }); }
export function View_ConfirmDialog_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "confirm-dialog", [], null, null, null, View_ConfirmDialog_0, RenderType_ConfirmDialog)), i0.ɵdid(1, 114688, null, 0, i4.ConfirmDialog, [i5.MatDialogRef, i5.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ConfirmDialogNgFactory = i0.ɵccf("confirm-dialog", i4.ConfirmDialog, View_ConfirmDialog_Host_0, {}, {}, []);
export { ConfirmDialogNgFactory as ConfirmDialogNgFactory };
