
      <div class="acceptDialog" style="position: relative;">
          <h4>Accept task</h4>
          <p style="margin: 15px 0;">
        <span *ngIf="dueDate">
            The customer requested the delivery of this project by <br/>
            <span style="font-weight: bold;">{{dueDate | date:'EEEE, MMMM dd, y'}}</span>, at
            <span style="font-weight: bold;">{{dueDate | date:'HH:mm'}}</span>.<br/><br/>
            <span *ngIf="revisionNeeded">There is <span style="font-weight: bold;">a revision task following your translation</span>.
              This also needs to be done within this timeframe.<br/><br/></span>
            With that in mind, please specify when you expect to deliver the task by.
            </span>
              <span *ngIf="!dueDate">Please specify when you expect to deliver the task by.</span>
          </p>
          <div>
              <label>Expected delivery date</label>
              <datetime [minDate]="minDueDate" [inputDate]="date" [defaultToToday]="false" minTime="now"
                        (dateSelected)="setDate($event)"
                        (invalidDateSelected)="setInvalidDate(true)"></datetime>
              <div *ngIf="tooLate">
                  <div>
                      <p style="color: red; margin: 10px 0;">Your expected delivery date is later than the date
                          requested by the
                          customer.
                          You can propose to deliver by this later date, but the customer will need to accept this
                          change.</p>
                      <textarea [(ngModel)]="proposal.text"
                                placeholder="Please motivate why you propose a different delivery date."
                                style="width:100%;"></textarea>
                      <p style="font-size: small;">Please note that if the user accepts your proposal the task is
                          automatically
                          accepted
                          - you'll be notified of this via mail.
                      </p>
                  </div>
              </div>
              <div *ngIf="isSworn">
                  <div>
                      <label>Extra cost for Sworn translation</label>
                      <input id="extraCost" type="number" step="1" [(ngModel)]="proposal.extraCost"/>
                      <label>Only fill in an extra cost if needed, if not this can be left empty.</label>
                      <p style="font-size: small;">If the user accepts your extra cost the task is automatically
                          accepted
                          - you'll be notified of this via mail.
                      </p>
                  </div>
              </div>
          </div>
          <div style="display: flex; justify-content: center; margin-top: 10px;">
              <button *ngIf="tooLate || (proposal.extraCost > 0)" type="submit"
                      class="btnCounterPropose"
                      (click)="counterPropose()"
                      [disabled]="invalidDate">Propose task change
              </button>
              <button *ngIf="!tooLate && !(proposal.extraCost > 0)" type="submit"
                      (click)="closeDialog()"
                      [disabled]="invalidDate">Confirm
              </button>
              <button (click)="dialogRef.close()">Cancel</button>
          </div>
      </div>