/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./mugshot.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./mugshot.component";
import * as i4 from "../service/user.service";
var styles_MugshotComponent = [i0.styles];
var RenderType_MugshotComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MugshotComponent, data: {} });
export { RenderType_MugshotComponent as RenderType_MugshotComponent };
function View_MugshotComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "profileIncomplete"]], null, null, null, null, null))], null, null); }
function View_MugshotComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["alt", "Profile picture"], ["class", "mugshot"]], [[8, "src", 4], [4, "width", null], [4, "height", null]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.profilePic; var currVal_1 = _co.width; var currVal_2 = _co.height; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_MugshotComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [["profileOrb", 1]], null, 1, "div", [["class", "profileOrb"]], [[4, "background-color", null], [4, "color", null], [4, "fontSize", null]], null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.profileBgColor; var currVal_1 = _co.profileTextColor; var currVal_2 = _co.fontSize; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.userInitials; _ck(_v, 1, 0, currVal_3); }); }
export function View_MugshotComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["style", "position: relative;"]], [[4, "width", null], [4, "height", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MugshotComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MugshotComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MugshotComponent_3)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.showIncomplete && !_co.profileComplete); _ck(_v, 2, 0, currVal_2); var currVal_3 = _co.profilePicExists; _ck(_v, 4, 0, currVal_3); var currVal_4 = !_co.profilePicExists; _ck(_v, 6, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.width; var currVal_1 = _co.height; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_MugshotComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mugshot", [], null, null, null, View_MugshotComponent_0, RenderType_MugshotComponent)), i1.ɵdid(1, 638976, null, 0, i3.MugshotComponent, [i4.UserService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MugshotComponentNgFactory = i1.ɵccf("mugshot", i3.MugshotComponent, View_MugshotComponent_Host_0, { user: "user", width: "width", height: "height", showIncomplete: "showIncomplete" }, {}, []);
export { MugshotComponentNgFactory as MugshotComponentNgFactory };
