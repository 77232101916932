import {map} from 'rxjs/operators';
import {Component} from "@angular/core";
import {ActivatedRoute, Params} from "@angular/router";
import {UserService} from "../service/user.service";
import {FileService} from "../service/file.service";
import {InvoiceService} from "../service/invoice.service";
import * as FileSaver from "file-saver";
import {LogInService} from "../service/login.service";

@Component({
  selector: "invoice",
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.css'],
})
export class InvoiceComponent {

  public invoiceId: string;
  public company;
  public user;
  public invoice;

  constructor(private route: ActivatedRoute,
              private invoiceService: InvoiceService,
              private userService: UserService,
              private fileService: FileService,
              private loginService: LogInService) {
  }

  ngOnInit(): void {
    this.route.params.pipe(
      map((params: Params) => params['id']))
      .subscribe(invoiceId => {
        this.invoiceId = null;
        this.company = null;
        this.user = this.userService.getCurrentUser();
          if (this.user == undefined) {
            this.userService.loginEmitter.subscribe(v => this.ngOnInit())
          }
          //If there is an invoice id, this component is used as a view on the invoice data
          //If the invoice id is "new", then we are in registration mode
          if (invoiceId == 'new') {
            //In case it is a new invoice, we can use the current user & company and we do not wait for the invoice's data
            this.invoice = {};
            this.invoice.type = "PURCHASE";
            this.fetchEntityInfo();
          } else {
            this.invoiceId = invoiceId;
            this.invoiceService.getInvoice(invoiceId).subscribe(invoice => {
              this.invoice = invoice;
              this.fetchEntityInfo();
            });
          }
        }
      );
  }

  private fetchEntityInfo(): void {
    //Try fetching the company info from the invoice
    if (this.invoice != null && this.invoice.company != null) {
      this.userService.findCompanyInfo(this.invoice.company.id).subscribe(company => this.company = company);
    } else if (this.invoice != null && this.invoice.user != null) {//If there is no company linked to the invoice, fetch the company from the user on the invoice
      this.userService.getUser(this.invoice.user.id).subscribe(user => this.user = user);
    } else {//If there is no invoice (or no user on an invoice) fetch the company from the user that is already set (if there is one already
      if (this.user) {
        if (this.user.companyId != null) {
          this.userService.findCompanyInfo(this.user.companyId).subscribe(company => {
            this.company = company
          });
        }
      } else {//If the user was not yet set, subscribe to the userEmitter (which will emit a value once userService has set the user.
        this.userService.userEmitter.subscribe(v => {
          if (this.user == undefined) {
            this.user = v;
            this.fetchEntityInfo();
          }
        });
      }
    }
  }

  public downloadInvoicePDF(): void {
    let entityId = this.getInvoiceEntityId();
    this.fileService.downloadInvoicePDF(entityId, this.invoice.id).subscribe(
      n => {
        FileSaver.saveAs(n, this.invoice.number + ".pdf", true);
      }
    );
  }

  private getInvoiceEntityId(): string {
    if (this.company != null && this.company.id != null)
      return this.company.id;
    return this.user.id;
  }
}
