export class Vendor {
  id: string;
  name: string;
  firstName: string; // needed for initials in mugshot
  lastName: string; // needed for initials in mugshot
  mugshot: string;
  description: string;
  rating: number;
  reviewcount: number;
  responsiveness: string;
  basePrice: number;
  city: string;
  country: string;
  motherTongue: string;
  competences?: any[];
  domains?: any[];
  experiences?: [{
    from: string;
    to?: string;
    location: string;
    description: string;
  }];
  percentageSet?: any;
  internal: boolean = false;
  companyId: string;

  constructor(id: string, firstname: string, lastname: string, mugshot: string, description: string, motherTongue: string,
              city: string, country: string, rating: number, reviewcount: number, responsiveness: string,
              basePrice: number, competences?: Competence[], domains?: any[],
              percentageSet?: any, internal: boolean = false, companyId: string = null) {
    this.id = id;
    if (firstname == undefined) this.firstName = "";
    else this.firstName = firstname;
    if (lastname == undefined) this.lastName = "";
    else this.lastName = lastname;
    this.name = this.firstName + " " + this.lastName;
    this.mugshot = mugshot;
    this.description = description;
    this.rating = rating;
    this.reviewcount = reviewcount;
    this.responsiveness = responsiveness;
    this.basePrice = basePrice;
    this.competences = competences;
    this.domains = domains;
    this.percentageSet = percentageSet;
    this.internal = internal;
    this.companyId = companyId;
    this.motherTongue = motherTongue;
    this.country = country;
    this.city = city;
  }
}

export class Competence {
  activity: string;
  target: string;
  sources: string[];
  domains: string[];
  basePrice: number = 0;
  reduction: Reduction;

  toString(): string {
    return this.activity + " " + this.target + " " + this.basePrice;
  }
}

export class Reduction {
  mtMatch: number;
  fuzzyMatch: number;
  nnMatch: number;
  hunderdMatch: number;
  perfectMatch: number;
  contextMatch: number;
}
