/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./waiting.dialog";
import * as i2 from "@angular/material/dialog";
var styles_WaitingDialog = [];
var RenderType_WaitingDialog = i0.ɵcrt({ encapsulation: 2, styles: styles_WaitingDialog, data: {} });
export { RenderType_WaitingDialog as RenderType_WaitingDialog };
export function View_WaitingDialog_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "h4", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "div", [["style", "display: flex; justify-content: center; align-items: center;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "img", [["src", "../assets/loading.gif"], ["style", "width: 150px; height: 150px;"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.waitingText; _ck(_v, 1, 0, currVal_0); }); }
export function View_WaitingDialog_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "waiting-dialog", [], null, null, null, View_WaitingDialog_0, RenderType_WaitingDialog)), i0.ɵdid(1, 114688, null, 0, i1.WaitingDialog, [i2.MatDialogRef, i2.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var WaitingDialogNgFactory = i0.ɵccf("waiting-dialog", i1.WaitingDialog, View_WaitingDialog_Host_0, {}, {}, []);
export { WaitingDialogNgFactory as WaitingDialogNgFactory };
