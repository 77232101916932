
    <span matTooltip='{{tooltipText()}}'>
        <span class='currPrice' *ngIf="price === undefined || price === null">{{undefinedDefault}}</span>
        <span class='currPrice' *ngIf="price != null" [ngStyle]="getCurrStyles()">
            {{price*rate | currency:displayCurrency:display:digitsInfo}}
        </span>
        <span class='eurPrice' *ngIf='price != null && showBase && baseCurrency != displayCurrency' style='font-size: smaller;'>
            ({{price | currency:baseCurrency:display:digitsInfo}})
        </span>
    </span>
  