import { stringify } from 'querystring';

export const ACTIVITY_TRANSLATION = 'Translation';
export const ACTIVITY_REVISION = 'Revision';
export const ACTIVITY_SWORN_TRANSLATION = 'Sworn translation';
export const ACTIVITY_EDITING = 'Editing';

export const ACTIVITIES: string[] = [
  ACTIVITY_TRANSLATION, ACTIVITY_REVISION, ACTIVITY_SWORN_TRANSLATION, ACTIVITY_EDITING
];

export const SERVICE_TRANSLATION = 'Translation';
export const SERVICE_SWORN_TRANSLATION = 'Sworn translation';
export const SERVICE_EDITING = 'Editing';

export const SERVICES: string[] = [SERVICE_TRANSLATION, SERVICE_SWORN_TRANSLATION, SERVICE_EDITING];

export const DEPENDENCY_SOURCE_LANG = 'Source language';
export const DEPENDENCY_TARGET_LANG = 'Target language';

export const DEFAULT_DEPENDENCIES = [DEPENDENCY_SOURCE_LANG, DEPENDENCY_TARGET_LANG];
export const EDITING_DEPENDENCIES = [DEPENDENCY_SOURCE_LANG];

export interface ServiceParam {
  toDataString(): string;
  compare(param: ServiceParam): number;
}

export class TranslationParameter implements ServiceParam {
  source: string;
  target: string;
  creationdate: string;

  toDataString(): string {
    return this.source + '_' + this.target
  }

  compare(param: ServiceParam): number {
    if (param instanceof TranslationParameter) {
      return this.target.localeCompare(param.target);
    }
    return -1;
  }
}

export class EditingParameter implements ServiceParam {
  source: string;
  creationdate: string;
  toDataString(): string {
    return this.source;
  }

  compare(param: ServiceParam): number {
    if (param instanceof EditingParameter) {
      return this.source.localeCompare(param.source);
    }
    return -1;
  }
}

export interface ServiceParameters {
  isValid(): boolean;
  getService(): string;
  contains(param: ServiceParam): boolean;
  toParamList(): ServiceParam[];
}

export class TranslationParameters implements ServiceParameters {
  sourceLanguage: string[];
  targetLanguages: string[];

  constructor(sourceLanguage: string[] = [],
    targetLanguages: string[] = []) {
    this.sourceLanguage = sourceLanguage;
    this.targetLanguages = targetLanguages;
  }

  isValid(): boolean {
    return this.sourceLanguage[0] != null && this.targetLanguages[0] != null
  }

  getService(): string {
    return SERVICE_TRANSLATION
  }

  contains(params: any): boolean{
    if (params['source'] != null && params['target'] != null){
      return this.sourceLanguage.indexOf(params['source']) >= 0 && this.targetLanguages.indexOf(params['target']) >= 0
    }
    return false;
  }

  toParamList(): TranslationParameter[] {
    if (this.sourceLanguage[0] == null) {
      return [];
    }
    return this.targetLanguages.map(t => {
      const translationParam = new TranslationParameter();
      translationParam.source = this.sourceLanguage[0];
      translationParam.target = t;
      return translationParam
    });
  }
}

export class EditingParameters implements ServiceParameters {
  sourceLanguage: string[];

  constructor(sourceLanguage: string[] = []) {
    this.sourceLanguage = sourceLanguage;
  }

  isValid(): boolean {
    return this.sourceLanguage[0] != null;
  }

  getService(): string {
    return SERVICE_EDITING;
  }

  contains(params: any): boolean{
    if (params['source'] != null){
      return this.sourceLanguage.indexOf(params['source']) >= 0;
    }
    return false;
  }

  toParamList(): EditingParameter[] {
    if (this.sourceLanguage[0] == null) {
      return [];
    }
    const param = new EditingParameter();
    param.source = this.sourceLanguage[0];
    return [param];
  }
}

export function initServiceParamForService(service: string, sourceLang: string, targetLanguages: string[]): ServiceParameters {
  switch (service) {
    case SERVICE_EDITING: {
      return new EditingParameters();
    }
    default: {
      const serviceParameter = new TranslationParameters();
      if (serviceParameter['sourceLanguage'] != null && sourceLang != null) {
        serviceParameter['sourceLanguage'] = [sourceLang];
      }
      if (serviceParameter['targetLanguages'] != null && targetLanguages != null) {
        serviceParameter['targetLanguages'] = targetLanguages;
      }
      return serviceParameter;
    }
  }
}
