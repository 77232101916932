import {Component} from "@angular/core";
import {ActivatedRoute} from "@angular/router";

//TODO make normal page (not needing to load angular shizzle)
@Component({
  selector: 'oauth-callback-component',
  template: '<h1>Thanks for logging in!</h1>',
  styleUrls: ['./user.settings.component.css']
})
export class OauthCallbackComponent {
  constructor(private activeRoute: ActivatedRoute){

  }

  ngOnInit(){
    this.activeRoute.queryParams
      .subscribe(params => {
        //TODO check state
        let code = params['code'];
        localStorage.setItem("linkedInAuthCode", code);
        window.close();
      });
  }
}
