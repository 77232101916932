import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';


@Component({
  selector: 'editor-setting.component',
  templateUrl: 'editor-setting.component.html',
})
export class EditorSettingsComponent {

  hideAffix: boolean;
  hideNoText: boolean;

  constructor(
    public dialogRef: MatDialogRef<EditorSettingsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  ngOnInit(): void {
    this.hideAffix = this.data.hideAffix;
    this.hideNoText = this.data.hideNoText;
  }

  cancelClick(): void {
    this.dialogRef.close("cancel");
  }

  confirmClick(): void {
    this.dialogRef.close("confirm");
  }

}
