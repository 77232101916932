import {Component, EventEmitter, Input, Output} from "@angular/core";
import {ErrorSnackbarComponent} from "../../error.snackbar.component";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Subject} from "rxjs";
import {FileService} from "../../service/file.service";
import {UploadDataService} from "../util/upload-data.service";


@Component({
  selector: 'documentation-file-add',
  template: `
    <div>
      <file-add [label]="'Add documentation files'"  [infoText]="infoText"
      [id]="'docUpload'" (onFileUpload)="uploadFiles($event)"></file-add>
  </div>`,
  styleUrls: ['../project.component.css'],
})
export class DocumentationFileAddComponent {

  @Input() uploadedFiles = [];
  @Input() entityId;
  @Input() entityType;
  @Output() onFileUpload = new EventEmitter<any>();

  infoText = 'Reference material, Terminology, Product information, ...';

  constructor(
    private fileService: FileService,
  ) {


  }

  ngOnInit() {
    if (this.uploadedFiles != null && this.uploadedFiles.length > 0) {
      this.infoText = null;
    }
  }

  uploadFiles(event): void {
    if (this.uploadedFiles == null) {
      this.uploadedFiles = [];
    }
    const fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      // Loop and upload the files
      for (let i = 0; i < fileList.length; i++) {
        const file: File = fileList[i];
        this.fileService.uploadDocFile(file, this.entityId, this.entityType).subscribe();
        this.onFileUpload.emit({docfile: file});
        this.infoText = null;
      }
    }
  }
}
