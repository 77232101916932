import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren
} from "@angular/core";
import {Observable, Subscription} from "rxjs";
import {debounceTime, filter, switchMap, tap} from "rxjs/operators";
import {PricingService} from "../../service/pricing.services";
import {TaskSpec} from "../../dto/job";
import {UserService} from '../../service/user.service';
import {isTranslationActivity} from "../../util/jobutil";
import {SubscriptionHandler} from '../../util/subscription.handler';


@Component({
  selector: 'translator-list',
  templateUrl: './translator-list.component.html',
  styleUrls: ['../project.component.css']
})
export class TranslatorListComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {

  @Input()
  projectId;
  @Input()
  activity;
  @Input()
  service;
  @Input()
  langpair: any;
  @Input()
  duedate: Date;
  @Input()
  translators: any[] = undefined;
  @Input()
  selected: string = undefined;
  @Input()
  loadPrices = true;
  @Input()
  expanded = false;
  @Input()
  isQuote = false;

  @Output()
  onSelectionChange = new EventEmitter<any>();
  @Output()
  onSelectedPriceChange = new EventEmitter<any>();
  @Output()
  onPricesRecalc = new EventEmitter<any>();

  priceCalcEmitter = new EventEmitter();
  priceCalculateSubscription: Subscription;

  @ViewChild("translatorList", { read: ElementRef, static: false })
  translatorCardList: ElementRef<any>;
  @ViewChildren("translatorCardScroll", { read: ElementRef })
  translatorCardScroller: QueryList<any>;

  listViewWidth;
  scroller: ElementRef<any>;
  listScrollWidth;
  scrollOffset = 0;
  rightScrollEnabled = false;
  leftScrollEnabled = false;

  mtCost;
  serviceFee;
  initialized = false;

  subscriptionHandler = new SubscriptionHandler(this);

  constructor(private pricingService: PricingService,
    private userService: UserService) {
  }

  ngOnInit() {
    this.createCalcListPriceObs();
    this.calcListPrices();
    const priceFeedSubscription = this.pricingService.enrollForProjectAndLangPair(this.projectId, this.langpair.source + "_" + this.langpair.target)
      .filter(m => {
        // No need to recalculate the individual translator prices if a task was created or canceled
        return m.action != null && m.action !== "TASK_CREATE" && m.action !== "TASK_CANCEL";
      })
      .pipe(
        tap(() => this.calcListPrices()),
      )
      .subscribe(a => {
      });
    this.subscriptionHandler.push(priceFeedSubscription);
    this.initialized = true;
  }

  ngOnDestroy() {

  }

  ngAfterViewInit() {
    this.setScrollElement(this.translatorCardScroller.first);
    // The list and scroller @ViewChild element references were not always already initialized at this point (due to
    // the *ngIf clauses in the HTML); to work around this, we use @ViewChildren and subscribe to changes, so we
    // know when they're "ready"
    const scrollSubsription = this.translatorCardScroller.changes.subscribe((tcs: QueryList<any>) => {
      this.setScrollElement(tcs.first);
    });
    this.subscriptionHandler.push(scrollSubsription);
  }

  private setScrollElement(scrollElement: ElementRef<any>) {
    this.scroller = scrollElement;
    if (this.scroller != undefined)
      // Calculating the scroll sizes is wrapped in a setTimeout() function, to prevent the following error:
      // Error: "ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked. Previous value: 'ngIf: false'. Current value: 'ngIf: true'."
      setTimeout(() => {
        this.calcScrollWidths();
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    // We added an "initialized" flag here to prevent the prices to be calculated twice
    // What happens otherwise is that ngOnChange is executed BEFORE ngOnInit on initialization
    // No need to recalculate the prices if the content of the arrays is the same
    if (this.initialized && changes.translators != null
      && !this.sameTranslators(changes.translators.previousValue, changes.translators.currentValue)) {
      this.translators = changes.translators.currentValue;
      this.calcListPrices();
    }
  }

  private sameTranslators(oldTranslatorsList: any[], newTranslatorsList: any[]): boolean {
    if (oldTranslatorsList == null) {
      return newTranslatorsList == null;
    }
    return JSON.stringify(oldTranslatorsList.map(t => t.id)) === JSON.stringify(newTranslatorsList.map(t => t.id))
  }

  createCalcListPriceObs() {
    const taskSpec = this.createTaskSpec();
    let debounceTimer = 10;
    const priceCalculateSubscription = this.priceCalcEmitter
      .pipe(
        tap(() => {
          if (this.translators != null) {
            this.translators.forEach(t => {
              t['price'] = undefined;
              t['serviceFee'] = undefined;
              t['mtCost'] = undefined;
            })
          }
        }),
        debounceTime(debounceTimer),
        tap(() => debounceTimer = 500),
        filter(() => this.translators != null && this.translators.length > 0),
        tap(() => {
          if (isTranslationActivity(this.activity)) {
            this.pricingService.getServiceFeeAndMTCost(taskSpec.projectId, this.langpair)
              .pipe(
                tap(c => {
                  this.translators.forEach(t => {
                    const oldMtCost = t['mtcost'];
                    const oldServiceFee = t['serviceFee'];
                    t['serviceFee'] = c['serviceFee'];
                    t['mtCost'] = c['mtCost'];
                    if (t['serviceFee'] !== oldServiceFee || t['mtCost'] !== oldMtCost) {
                      this.onSelectedPriceChange.emit(t);
                    }
                  });
                })
              ).subscribe(() => {
                this.onPricesRecalc.emit('Recalculated')
              },
                error =>
                  console.error('Error calculating translator prices', error),
                () => {
                  // Never reaches the 'complete' function, because the observable never completes
                }
              );
          }
        }),
        switchMap(() => {
          // Because the first 3 translators are shown first, we first only calculate those (+1 extra, just in case)
          // In case there are more than 4, we calculate them right after the first translators
          // This is done to speed up the interface
          const firstTranslators = this.pricingService.getPriceForTaskSpecAndVendors(taskSpec, this.translators.slice(0, 4))
            .flatMap(p => p)
            .map(price => {
              this.setTranslatorPrice(this.translators, price['translatorId'], price['price']);
            });
          let restTranslators = Observable.create();
          if (this.translators.length > 4) {
            var i, j, chunk = 25;
            for (i = 4, j = this.translators.length; i < j; i += chunk) {
              let chunks = this.pricingService.getPriceForTaskSpecAndVendors(taskSpec, this.translators.slice(i, i + chunk))
                .flatMap(p => p)
                .map(price => {
                  this.setTranslatorPrice(this.translators, price['translatorId'], price['price']);
                });
              restTranslators = restTranslators.merge(chunks);
            }
          }
          return firstTranslators
            .merge(restTranslators);
        })
      )
      .subscribe(() => {
        this.onPricesRecalc.emit('Recalculated');
      },
        error =>
          console.error("Error calculating translator prices", error),
        () => {
          // Never reaches the 'complete' function, because the observable never completes
        });
    this.subscriptionHandler.push(priceCalculateSubscription);
  }

  calcListPrices() {
    if (this.loadPrices) {
      this.priceCalcEmitter.emit('go')
    }
  }

  private setTranslatorPrice(translators: any[], translatorId: string, price: any) {
    if (translators === undefined || this.translators.length === 0) {
      return;
    }

    translators
      .filter(translator => translator.id === translatorId)
      .forEach(translator => {
        let oldPrice = translator['price'];
        translator['price'] = price;
        // Emit an event when the price of the selected translator changes
        if (this.selected != null && translator.id === this.selected) {
          if (price !== oldPrice) {
            this.onSelectedPriceChange.emit(translator);
          }
        }
      });
  }

  onTranslatorSelect(selectedTranslator: any) {
    this.selected = selectedTranslator !== undefined ? selectedTranslator.id : undefined;
    this.onSelectionChange.next(selectedTranslator);
  }

  calcScrollWidths() {
    if (this.initialized && this.translatorCardList != undefined) {
      this.listViewWidth = this.translatorCardList.nativeElement.clientWidth;
      if (this.scroller != undefined) {
        this.listScrollWidth = this.scroller.nativeElement.clientWidth;
        this.calcScrollArrows();
      }
    }
  }

  private calcScrollArrows() {
    this.leftScrollEnabled = this.scrollOffset > 0;
    this.rightScrollEnabled = this.scrollOffset + this.listViewWidth < this.listScrollWidth;
  }

  getTransX() {
    return this.scrollOffset * -1;
  }

  scrollRight() {
    let temp = this.scrollOffset + this.listViewWidth - 50;
    if (temp > (this.listScrollWidth - this.listViewWidth))
      this.scrollOffset = this.listScrollWidth - this.listViewWidth;
    else this.scrollOffset = temp;
    this.calcScrollArrows();
  }

  scrollLeft() {
    let temp = this.scrollOffset - this.listViewWidth + 50;
    if (temp < 0) this.scrollOffset = 0;
    else this.scrollOffset = temp;
    this.calcScrollArrows();
  }

  userEmail = undefined;
  contactRequestResult = undefined;

  onSubmitContactRequest() {
    this.userService.sendContactRequest(this.userEmail,
      this.createTaskSpec(),
      this.userService.isLoggedIn() ? this.userService.getCurrentUser().id : undefined).subscribe(v => {
        this.contactRequestResult = "Great! We received your query and will get back to you as soon as possible.";
      }, error => {
        console.error("Error sending contact request", error);
        this.contactRequestResult = "An error occurred sending your request. " +
          "Please try again and, if the problem persists, contact us directly at info@lilo.global.";
      });
  }

  createTaskSpec(): TaskSpec {
    let taskSpec = new TaskSpec();
    taskSpec.activity = this.activity;
    taskSpec.service = this.service;
    taskSpec.projectId = this.projectId;
    taskSpec.sourcelanguage = this.langpair.source;
    taskSpec.targetlanguage = this.langpair.target;
    return taskSpec;
  }

  onListResize() {
    // Re-calculate scroll widths when the screen is resized
    this.calcScrollWidths();
  }
}
