<div>
  <h1>Credits & reductions</h1>
  <div>
    <p>Did you receive a token? Fill it in here and click validate.</p>
    <input [(ngModel)]="validationToken"/>
    <button (click)="validateToken()">Validate</button>
  </div>
  <div *ngIf="credits">
    <h3>Credits overview</h3>
    <div class="inviteHead" style="display: flex">
      <div>title</div>
      <div>Description</div>
    </div>
    <div *ngFor="let credit of credits">
      <div class="inviteRow" style="display: flex">
        <div>{{credit.title}}</div>
        <div>{{credit.reason}}</div>
      </div>
    </div>
  </div>
</div>
