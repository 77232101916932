import {Component, Input, OnInit} from '@angular/core';


@Component({
  selector: 'error404',
  template: `
    <div style="width:100%; height: 100%; min-height: 80vh; display: flex; flex-direction: column; justify-content: center; align-items: center;">
      <h1 style="padding: 10px 25px 10px 25px; margin: 15px 5px 45px 5px;; font-weight: bold; border: 2px solid var(--main-color); border-radius: 5px; background-color: var(--second-color); color: white;">404 - Page not found</h1>
      <img src='../../assets/artwork/magnifier.png' style="height:  200px;"/>
      <p style="margin: 5px;">Oh no! This page does not exist.</p>
      <p style="margin:5px;">Hit the back button on your browser to return or try one of the links in the top menu.</p>
    </div>`,
})
export class Error404Component implements OnInit {

  @Input()
  message;


  constructor() {
  }

  ngOnInit() {

  }

}
