
    <div style="position: relative;">
      <div *ngIf="project == undefined"
        style="display: flex; justify-content: center; align-items: center; flex-direction: column; margin-top: 150px;">
      <img src="../../assets/loading_logo_1.gif" style="width: 100px; margin-bottom: 20px;">
      <span>Retrieving project ...</span>
      </div>
      <div *ngIf="project != undefined && projectState=='create'">
      <project-create [project]="project" [section]="section"
              [noForceRequester]="noForceRequester"></project-create>
      </div>
      <div *ngIf="project != undefined && projectState=='followup'">
        <project-followup [project]="project"></project-followup>
      </div>
		<div *ngIf="project != undefined" class="chat">
      <div>
      <project-chat [projectId]="project.id"></project-chat>
      </div>
		  </div>
	  </div>