import {animate, state, style, transition, trigger} from "@angular/animations";
import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {Vendor} from "../../dto/vendor";
import {Observable} from "rxjs";


@Component({
  selector: 'vendor-list',
  template: `
    <div class="boxing">
        <div *ngFor="let vendor of (vendors|async)" [@appearIn]="\'in\'" class="module">
          <vendor-profile [profile]="vendor" [basePrice]="vendor.basePrice"
                          [taskSpec]="taskSpec"
                          [showPricing]="showPricing"
                          [maxDescriptionSize]="250"
                          [maxDomainCount]="3"
                          (click)="selectVendor(vendor)"></vendor-profile>
        </div>
    </div>`,
  styleUrls: ['./vendor-selector.component.css'],
  animations: [trigger('appearIn', [
    state('in', style({opacity: 1})),
    transition('void => *', [
      style({
        opacity: 0
      }),
      animate('0.5s ease-in')
    ])
  ])]
})
export class VendorListComponent implements OnInit {

  @Input()
  vendors: Observable<Vendor>;
  @Input()
  taskSpec;
  @Input()
  showPricing: boolean = false;
  @Output()
  eventEmitter = new EventEmitter<Vendor>();

  ngOnInit() {

  }

  selectVendor(v: Vendor) {
    this.eventEmitter.next(v);
  }

}
