
    <div>
      <div>
        <div style="text-align: right; width: 100%; font-size: larger;" (click)="dialogRef.close()">X</div>
      </div>
      <input type="text" name="search" class="searchField" placeholder="Search for translator" [(ngModel)]="filterText"
             (change)="setFilteredVendors()"/>
      <vendor-list (eventEmitter)="selectVendor($event)" [vendors]="filteredVendors"
                   [showPricing]="showPricing" [taskSpec]="taskSpec"></vendor-list>
      <button (click)="dialogRef.close()" class="btnSmall">Close</button>
    </div>
  