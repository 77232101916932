<div>
  <div class="close">
    <p (click)="dialogRef.close()">X</p>
  </div>
  <div style="display: flex" *ngIf="!hasToken()">
    <!--Do not show linkedin or google login when using the companytoken joining -> needs to be reworked more for this to work-->
    <div style="width: 100%" [hidden]="auth2Joining">
      <div class="row" style="display: flex">
        <div class="linkedin" (click)="joinWithLinkedIn()" style="width: 100%;">Join with LinkedIn</div>
      </div>
      <div class="row">
        <div id="googleBtn" class="google" style="width: 100%;">Join with Google</div>
      </div>
    </div>
    <img src="../../assets/loading.gif" style="max-width:40px;" *ngIf="auth2Joining">
  </div>
  <div class="divider" *ngIf="!hasToken()">
    <span>or</span>
  </div>
  <form name="emailJoin" (submit)="joinViaMail()" class="joinForm">
    <div class="row">
      <label>E-mail address</label> <input type="email" name="email" [(ngModel)]="user.email" style="width: 100%;"
                                           required email #email="ngModel"/>
      <div *ngIf="email.invalid && (email.dirty || email.touched)"
           class="alert alert-danger">
        <div *ngIf="email.errors.required">
          E-mail is required.
        </div>
        <div *ngIf="email.errors.email && !email.errors.required">
          Ooops... this is not a valid email address.
        </div>
      </div>
    </div>
    <div class="row">
      <label>First name</label> <input type="text" name="firstname" [(ngModel)]="user.firstname" style="width: 100%;"
                                       required #firstname="ngModel" />
      <div *ngIf="firstname.invalid && (firstname.dirty || firstname.touched)"
           class="alert alert-danger">
        <div *ngIf="firstname.errors.required">
          First name is required.
        </div>
      </div>
    </div>
    <div class="row">
      <label>Last name</label> <input type="text" name="lastname" [(ngModel)]="user.lastname" style="width: 100%;"
                                      required #lastname="ngModel"/>
      <div *ngIf="lastname.invalid && (lastname.dirty || lastname.touched)"
           class="alert alert-danger">
        <div *ngIf="lastname.errors.required">
          Last name is required.
        </div>
      </div>
    </div>
    <div class="row">
      <button type="submit" autofocus style="width: 100%;" [disabled]="email.invalid || firstname.invalid || lastname.invalid || joining">
        Join via e-mail
      </button>
      <img src="../../assets/loading.gif"
           style="max-width:40px;" *ngIf="joining">
    </div>
  </form>
  <div class="divider"></div>
  <div class="row" style="text-align: center;">
    <span style="font-size: small">Already have an account? </span> <a (click)="signInPopup()">Sign in</a>
  </div>
</div>
