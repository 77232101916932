import {Pipe, PipeTransform} from '@angular/core';
import {LanguageService} from "../service/language.service";
import {Observable} from "rxjs";


@Pipe({name: 'languagePipe'})
export class LanguagePipe implements PipeTransform {

  constructor(public languageService: LanguageService) {

  }

  transform(value: string): Observable<string> {
    return this.languageService.codeToDescription(value);
  }
}
