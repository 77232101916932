import {Component, Input} from "@angular/core";
import {FileService} from "../../service/file.service";


@Component({
  selector: 'target-file',
  templateUrl: './target-file.component.html',
  styleUrls: ['../project.component.css'],
})
export class TargetFileComponent {

  @Input()
  file: any;
  @Input()
  pId: string;
  @Input()
  langPair: string;

  constructor(private fileService: FileService) {

  }

  ngOnInit() {

  }

  // Download is done when clicking on the entire tile
  // download() {
  //   this.fileService.downloadFinal(this.pId, this.langPair, this.file.name)
  //     .subscribe( n => {
  //       FileSaver.saveAs(n, this.file.name, true);
  //     })
  // }
}
