/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./lang-select.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./language.pipe";
import * as i4 from "../service/language.service";
import * as i5 from "@angular/forms";
import * as i6 from "./lang-select-list.component";
var styles_LangSelectListComponent = [i0.styles];
var RenderType_LangSelectListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LangSelectListComponent, data: {} });
export { RenderType_LangSelectListComponent as RenderType_LangSelectListComponent };
function View_LangSelectListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "languageItemSelected"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00A0 "])), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", "selected"], ["src", "../../assets/icon_accept.svg"]], null, null, null, null, null))], null, null); }
function View_LangSelectListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "languageItem"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleLang(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(3, { "background-color": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 5, "button", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", ""])), i1.ɵppd(6, 1), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LangSelectListComponent_2)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, (_co.isSelected(_v.context.$implicit) ? "var(--background-gray)" : "transparent")); _ck(_v, 2, 0, currVal_0); var currVal_2 = _co.isSelected(_v.context.$implicit); _ck(_v, 9, 0, currVal_2); }, function (_ck, _v) { var currVal_1 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 7).transform(i1.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i1.ɵnov(_v.parent, 0), _v.context.$implicit)))); _ck(_v, 5, 0, currVal_1); }); }
export function View_LangSelectListComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i3.LanguagePipe, [i4.LanguageService]), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "searchLangs"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 5, "input", [["id", "languageSearchInput"], ["placeholder", "Search languages"], ["type", "text"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 4).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 4)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 4)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.searchText = $event) !== false);
        ad = (pd_4 && ad);
    } if (("input" === en)) {
        var pd_5 = (_co.filterLangs() !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 16384, null, 0, i5.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i5.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i5.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i5.DefaultValueAccessor]), i1.ɵdid(6, 671744, null, 0, i5.NgModel, [[8, null], [8, null], [8, null], [6, i5.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i5.NgControl, null, [i5.NgModel]), i1.ɵdid(8, 16384, null, 0, i5.NgControlStatus, [[4, i5.NgControl]], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "languagesList"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LangSelectListComponent_1)), i1.ɵdid(11, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.searchText; _ck(_v, 6, 0, currVal_7); var currVal_8 = _co.filteredLangs; _ck(_v, 11, 0, currVal_8); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 8).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 8).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 8).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 8).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 8).ngClassValid; var currVal_5 = i1.ɵnov(_v, 8).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 8).ngClassPending; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_LangSelectListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "lang-select-list", [], null, null, null, View_LangSelectListComponent_0, RenderType_LangSelectListComponent)), i1.ɵdid(1, 4308992, null, 0, i6.LangSelectListComponent, [i4.LanguageService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LangSelectListComponentNgFactory = i1.ɵccf("lang-select-list", i6.LangSelectListComponent, View_LangSelectListComponent_Host_0, { selectedLangs: "selectedLangs", languages: "languages", dialect: "dialect" }, { eventEmitter: "eventEmitter" }, []);
export { LangSelectListComponentNgFactory as LangSelectListComponentNgFactory };
