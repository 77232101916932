import {RouterModule, Routes} from "@angular/router";
import {NgModule} from "@angular/core";
import {TaskDashboardComponent} from "./taskcomponent/task-dashboard.component";
import {TaskDetailComponent} from "./taskcomponent/task-detail.component";
import {IndexMainComponent} from "./projectcomponent/index-main.component";
import {UserSettingsComponent} from "./usercomponent/user.settings.component";
import {MailActivationScreen} from "./usercomponent/user.mailactivation.component";
import {NewTranslatorComponent} from "./usercomponent/new.translator.component";
import {InvoiceComponent} from "./invoicecomponent/invoice.component";
import {InvoiceOverviewComponent} from "./invoicecomponent/invoice.overview.component";
import {RatingFullComponent} from "./reviewcomponent/review.full.component";
import {ReviewCreateComponent} from "./reviewcomponent/review.create.component";
import {ProfileInfoComponent} from "./usercomponent/profile.info.component";
import {InvitedComponent} from "./usercomponent/invited.component";
import {ChatComponent} from "./chatcomponent/chat.component";
import {CreditCreatorComponent} from "./creditcomponent/credit-creator.component";
import {TranslatorOverviewComponent} from "./transoverviewcomponent/translator-overview.component";
import {AdminDashboardComponent} from "./admin/admin-dashb.component";
import {FolderViewerComponent} from "./admin/folderviewcomponent/folder-viewer.component";
import {HubspotProjectCreateComponent} from "./hubspotcomponent/hubspot-project-create.component";
import {TransCheckComponent} from "./transcheckcomponent/trans-check.component";
import {TaskEditorComponent} from "./editor/task-editor.component";
import {TranslatorSummaryComponent} from "./transoverviewcomponent/translator-summary.component";
import {OauthCallbackComponent} from "./usercomponent/oauth-callback.component";
import {ProjectFollowupListComponent} from "./admin/followupcomponent/project-followup-list.component";
import {Error404Component} from "./util/error.404.component";
import {ProjectDetailsComponent} from "./projectcomponent/project-details.component";
import {ProjectDashboardComponent} from "./dashboard/project-dashb.component";
import {UserProfileComponent} from "./usercomponent/user-profile.component";
import {TmManagementComponent} from "./admin/tmmgmtcomponent/tm-management.component";
import {CustomerGroupListComponent} from "./admin/user/customergroup/customer-group-list.component";
import {ProjectCleanupComponent} from "./admin/project/cleanupcomponent/project-cleanup.component";
import {MimicUserComponent} from "./admin/user/mimicuser/mimicuser.component";

const routes: Routes = [
  {path: 'pdashboard', component: ProjectDashboardComponent},
  {path: 'tdashboard', component: TaskDashboardComponent},
  {path: 'newproject', component: IndexMainComponent},
  {path: '', component: IndexMainComponent},
  // {path: '', redirectTo: "newtranslator", pathMatch: 'full'},
  {path: 'pdetail/:id', component: ProjectDetailsComponent},
  {path: 'tdetail/:id', component: TaskDetailComponent},
  {path: 'editor/:id/:file', component: TaskEditorComponent},
  {path: 'user/:id/:page', component: UserSettingsComponent},
  {path: 'user/:id', component: UserSettingsComponent},
  {path: 'mailauth/:authkey', component: MailActivationScreen},
  {path: 'newtranslator', component: NewTranslatorComponent},
  {path: 'join/:inviteid', component: InvitedComponent},
  {path: 'join', component: InvitedComponent},
  {path: 'invoicing/:id', component: InvoiceOverviewComponent},
  {path: 'invoice/:id', component: InvoiceComponent},
  {path: 'review/:id', component: RatingFullComponent},
  {path: 'newreview/:id', component: ReviewCreateComponent},
  {path: 'profileinfo/:id', component: ProfileInfoComponent},
  {path: 'profile/:id', component: UserProfileComponent},
  {path: 'chat', component: ChatComponent},
  {path: 'creditscreator', component: CreditCreatorComponent},
  {path: 'hubspotproject', component: HubspotProjectCreateComponent},
  {path: 'adashboard', component: AdminDashboardComponent},
  {path: 'admin/translatoroverview', component: TranslatorOverviewComponent},
  {path: 'admin/translatorsummary', component: TranslatorSummaryComponent},
  {path: 'admin/folderviewer', component: FolderViewerComponent},
  {path: 'admin/folderviewer/:type/:id', component: FolderViewerComponent},
  {path: 'admin/projectfollowup', component: ProjectFollowupListComponent},
  {path: 'admin/tmmanagement', component: TmManagementComponent},
  {path: 'admin/customergroups', component: CustomerGroupListComponent},
  {path: 'admin/projectcleanup', component: ProjectCleanupComponent},
  {path: 'admin/mimicuser', component: MimicUserComponent},
  {path: 'translationcheck/:id/:langPair', component: TransCheckComponent},
  {path: 'cb', component: OauthCallbackComponent},
  {path: '404', component: Error404Component},
  {path: '**', redirectTo: '/404'} // This always needs to be the last route item!
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule {
}
