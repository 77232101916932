import {Component, ViewChild} from "@angular/core";
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {VendorService} from "../service/vendor.service";
import {UserService} from "../service/user.service";


@Component({
  selector: 'translator-summary',
  templateUrl: 'translator-summary.component.html',
  styleUrls: ['./translator-summary.component.css']
})
export class TranslatorSummaryComponent {

  availCompResults;
  availCompData = new MatTableDataSource<any>();
  availCompCols = ['activity', 'source', 'target', 'count'];
  @ViewChild(MatSort, { static: false }) sort1: MatSort;

  sourceLang;
  targetLang;
  activity;

  foundCompResults;
  foundCompData = new MatTableDataSource<any>();
  foundCompCols = ['firstname', 'lastname', 'price'];

  constructor(private vendorService: VendorService,
              public userService: UserService) {

  }

  ngOnInit() {
    this.vendorService.getAvailableCompetences().subscribe(r => {
      this.availCompResults = r;
      this.availCompData.data = this.availCompResults;
    });
  }

  ngAfterViewInit() {
    this.availCompData.sort = this.sort1;
  }

  @ViewChild(MatSort, { static: false }) set matSort(ms: MatSort) {
    this.sort1 = ms;
    this.availCompData.sort = this.sort1;
  }

  search(comp: any) {
    if (comp.activity) this.activity = comp.activity;
    else this.activity = undefined;
    if (comp.source) this.sourceLang = comp.source;
    else this.sourceLang = undefined;
    if (comp.target) this.targetLang = comp.target;
    else this.targetLang = undefined;
    let langPair;
    if (this.sourceLang != undefined && this.targetLang != undefined)
      langPair = this.sourceLang + "_" + this.targetLang;
    else if (this.sourceLang != undefined)
      langPair = this.sourceLang;
    else if (this.targetLang != undefined)
      langPair = this.targetLang;
    this.vendorService.searchCompetences(langPair, this.activity).subscribe(r => {
      this.foundCompResults = r;
      this.foundCompData.data = this.foundCompResults;
    });
  }

  getPrice(vendor: any) {
    if (vendor.competences && vendor.competences[0])
      return vendor.competences[0].price;
    else return undefined;
  }

}
