/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./error.404.component";
var styles_Error404Component = [];
var RenderType_Error404Component = i0.ɵcrt({ encapsulation: 2, styles: styles_Error404Component, data: {} });
export { RenderType_Error404Component as RenderType_Error404Component };
export function View_Error404Component_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["style", "width:100%; height: 100%; min-height: 80vh; display: flex; flex-direction: column; justify-content: center; align-items: center;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h1", [["style", "padding: 10px 25px 10px 25px; margin: 15px 5px 45px 5px;; font-weight: bold; border: 2px solid var(--main-color); border-radius: 5px; background-color: var(--second-color); color: white;"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["404 - Page not found"])), (_l()(), i0.ɵeld(3, 0, null, null, 0, "img", [["src", "../../assets/artwork/magnifier.png"], ["style", "height:  200px;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "p", [["style", "margin: 5px;"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Oh no! This page does not exist."])), (_l()(), i0.ɵeld(6, 0, null, null, 1, "p", [["style", "margin:5px;"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Hit the back button on your browser to return or try one of the links in the top menu."]))], null, null); }
export function View_Error404Component_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "error404", [], null, null, null, View_Error404Component_0, RenderType_Error404Component)), i0.ɵdid(1, 114688, null, 0, i1.Error404Component, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var Error404ComponentNgFactory = i0.ɵccf("error404", i1.Error404Component, View_Error404Component_Host_0, { message: "message" }, {}, []);
export { Error404ComponentNgFactory as Error404ComponentNgFactory };
