import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {UserService} from "../service/user.service";
import {getUserInitials} from "../util/jobutil";
import {getTextColor} from "../util/userutil";


@Component({
  selector: 'mugshot',
  template: `
	  <div style="position: relative;" [style.width]="width" [style.height]="height">
		  <div *ngIf="showIncomplete && !profileComplete" class="profileIncomplete"></div>
		<img *ngIf="profilePicExists" [src]="profilePic" alt="Profile picture"
			 class="mugshot" [style.width]="width" [style.height]="height"/>
		<div *ngIf="!profilePicExists" class="profileOrb" #profileOrb
			 [style.background-color]="profileBgColor" [style.color]="profileTextColor" [style.fontSize]="fontSize"
		>{{userInitials}}</div>
	  </div>
  `,
  styleUrls: [
    './mugshot.component.css'
  ]
})
export class MugshotComponent implements OnChanges {

  @Input() user;
  @Input() width;
  @Input() height;
  @Input() showIncomplete = true;

  userInitials: string;
  fontSize: string;
  profileBgColor: string = "#4ebdcc";
  profileTextColor: string = "white";
  profilePic;
  profilePicExists = false;
  profileComplete = true;


  constructor(private userService: UserService) {

  }

  ngOnInit(): void {
    // Adjust the font size based on the given dimensions
    if (this.height == null)
      this.height = "100%";
    else this.determineFontSize(this.height);
    if (this.width == null)
      this.width = "100%";
    else this.determineFontSize(this.width);
    // Set user
    if (this.user != null) this.setUser();
    // Monitor changes to the displayed user
    this.userService.userUpdated$.subscribe(u => {
      if (this.user != null && u.id === this.user.id) {
        this.user = u;
        this.setUser();
      }
    });
  }

  /**
   * Scales the font size based on the size of the container (if any was given).
   */
  private determineFontSize(dimension: string) {
    if (this.fontSize == null && dimension.indexOf("px") > 0) {
      let size = Number(dimension.slice(0, dimension.length - 2));
      this.fontSize = size / 2 + "px";
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('user')) {
      if (JSON.stringify(changes.user.currentValue) !== JSON.stringify(changes.user.previousValue)) {
        this.user = changes.user.currentValue;
        this.setUser();
      }
    }
  }

  private setUser() {
    this.userInitials = getUserInitials(this.user);
    this.setProfileColor(this.user);
    this.loadProfilePic();
    this.calcProfileComplete();
  }

  private setProfileColor(user: any) {
    if (user.pcolor != null && user.pcolor.length > 0) {
      this.profileBgColor = user.pcolor;
      this.profileTextColor = getTextColor(this.profileBgColor);
    } else {
      this.userService.getProfileColor(user.id).subscribe(c => {
        this.profileBgColor = c;
        this.profileTextColor = getTextColor(this.profileBgColor);
      }, error => {
        console.log("Error getting color", error);
      })
    }
  }

  loadProfilePic() {
    if (this.user.id) {
      this.userService.getProfilePic(this.user.id)
        .subscribe(next => {
            this.profilePic = next;
            this.profilePicExists = true;
          }, error => this.profilePicExists = false
        );
    } else {
      this.profilePicExists = false;
    }
  }

  calcProfileComplete() {
    if (this.user != null) {
      this.profileComplete = this.user.id != null &&
        this.user.firstName != null &&
        this.user.lastName != null &&
        this.user.email != null;
    } else this.profileComplete = true;
  }
}
