<h1 mat-dialog-title>File complete?</h1>
<div mat-dialog-content>

  <div *ngIf="enableConfirm()">
    <p>
      Are you sure you want to finalize and complete the file? It will no longer be available once you confirm.<br/>
      <!-- todo: check if last file and if so, indicate:
		"Since this is the last file you need to complete for your task, your task will become available for invoicing."-->
      You have modified {{modifiedCount}} out of {{totalCount}} segments ({{((modifiedCount / totalCount) * 100) |
      number:'1.0-0'}}%).
    </p>
    <p *ngIf="hasDueDate() && onTime()">Thanks for your timely delivery!</p>
    <p *ngIf="hasDueDate() && !onTime()">Please note your delivery is
      <!--todo: check why the countdown label appears on a new line?-->
      <countdown [enddate]="task.duedate" [showoverdue]="true" [showsuffixlabel]="false"></countdown>
      overdue.
    </p>
  </div>
  <div *ngIf="!enableConfirm()">
    <p>
      Before you can finalize and complete the file, you must ensure you have <b>inserted all the required references
      for
      each segment</b>.<br/>
      <br/>
      The system currently detects <u title="{{tooltipMissingRefs}}">{{rowsWithMissingRefs.length}} row(s)</u> where
      one or more references are missing. You can identify these by the warning icon
      <img src="../../assets/warning.png" style="width: 15px; height: 15px;"/> in the right-most column.
  </div>

</div>
<div mat-dialog-actions style="display: flex; align-items: center; justify-content: space-evenly;">
  <button mat-button
          title="{{enableConfirm()? 'Finalize your file':'Unable to finalize your file: resolve any issues first!'}}"
          (click)="confirmClick()" [disabled]="!enableConfirm()">Confirm
  </button>
  <button mat-button (click)="cancelClick()">Cancel</button>
</div>
