import {filter, map, publish, tap} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {ConnectableObservable, Observable, Subject, zip} from "rxjs";
import {DetailedPricing, Pricing, TaskSpec} from "../dto/job";
import {HttpClient, HttpParams} from "@angular/common/http";
import {UserService} from "./user.service";
import {IntervalObservable} from "rxjs/observable/IntervalObservable";
import {Vendor} from "../dto/vendor";
import {getBaseSocketUrl, getBaseUrl} from "../util/httpclient";
import {DomSanitizer} from '@angular/platform-browser';
import { toLangPairString } from '../util/jobutil';



@Injectable()
export class PricingService {

  public messages: Observable<any>;
  private ws: Subject<any>;

  private PRICING_API = getBaseUrl() + "/api/v1/pricing";
  private CREDIT_API = getBaseUrl() + "/api/v1/credits";
  private FILES_API = getBaseUrl() + "/api/v1/files";

  private PRICING_SOCKET_URL = getBaseSocketUrl() + "/pricing/feed";


  constructor(private http: HttpClient, private userService: UserService) {

  }

  initPricingFeed() {
    this.ws = Observable.webSocket(this.PRICING_SOCKET_URL);
    //Keep the socket alive (every 4 minutes, server timeout is set to 5 minutes)
    IntervalObservable.create(30000).subscribe(o =>
      this.ws.next({'action': 'keepalive'})
    );
    this.messages = this.makeHot(this.ws).pipe(filter(m => m != null));
    // this.messages.subscribe(msg => console.log("> Received " + JSON.stringify(msg) + " @" + this.PRICING_SOCKET_URL),
    //   error => console.error(error));
  }

  //To know the difference between a cold and a hot observable check https://angular-2-training-book.rangle.io/handout/observables/cold_vs_hot_observables.html
  // or here : https://medium.com/@benlesh/hot-vs-cold-observables-f8094ed53339
  //Not really sure if it is needed to use a hot observable, but it does give more of a live messaging, also found other examples which were much more complicated, might have missed something there.
  makeHot<T>(cold: Observable<T>): Observable<T> {
    let obs = cold.pipe(publish()) as ConnectableObservable<T>;
    obs.connect();
    return obs;
  }

  enrollForProject(pId: string): Observable<any> {
    return this.enroll(pId).map(msg => msg.data);
  }

  enrollForProjectAndLangPair(pId: string, langPair: string, enrollNoLangPairAlso: boolean = true): Observable<any> {
    // If the 'enrollNoLangPairAlso' flag is false, then ONLY events that have a language pair specified that is equal
    // to the given one will be returned; if the events have no language pair specified, they are ignored and will not be returned
    // If the 'enrollNoLangPairAlso' flag is true, then events that have a language pair equal to the given one OR
    // none at all are returned
    return this.enroll(pId).pipe(
      filter(msg => {
        return (msg.langpair === undefined && enrollNoLangPairAlso) || msg.langpair == langPair;
      }),
      map(msg => msg.data));
  }

  private enroll(pId: string): Observable<any> {
    if (!this.ws || this.ws.closed)
      this.initPricingFeed();
    //To enroll you have to send a message to the socket with the action "enroll" and to which objects you want to enroll
    this.ws.next({'action': 'enroll', 'pid': pId});
    //The JSON structure of the message normally has the objects parameters to identify and a "data" object which contains the actual information
    //E.g. {"pid":"10"}
    return this.messages.pipe(filter(msg => msg.pid == pId))
  }

  getTotalProjectPrice(pId: string): Observable<DetailedPricing> {
    return this.http.get(this.PRICING_API + "/project/" + pId).pipe(
      map(p => this.extractData(p)),
      map(p => <DetailedPricing>p)
    );
  }

  getTotalFilePrice(pId: string, fileName: string): Observable<number> {
    return this.http.get(this.PRICING_API + "/file/" + pId + "/" + encodeURIComponent(fileName)).pipe(
      map(this.extractData),
      map(p => {
          return p.total();
        }
      ),);
  }

  getPriceForTask(tId: string): Observable<number> {
    return this.http.get(this.PRICING_API + "/task/" + tId).pipe(
      map(v => {
        return <number>v
      })
    );
  }

  getPriceForTaskSpecAndVendor(taskSpec: TaskSpec, vendorId: string, basePrice: number): Observable<number> {
    let params = new HttpParams().set('pid', taskSpec.projectId)
      .set('langpair', encodeURIComponent(toLangPairString({source: taskSpec.sourcelanguage, target: taskSpec.targetlanguage})))
      .set('activity', taskSpec.activity);
    if (taskSpec.service != null) {
        params = params.set('service', taskSpec.service.toLowerCase());
    }
    if (basePrice && basePrice > 0) {
      params = params.set('basePrice', '' + basePrice);
    }
    return this.http.get(this.PRICING_API + '/vendor/' + vendorId,
      {params: params}).pipe(
      map(p => {
          return Number(p).valueOf();
        }
      ));
  }

  getPriceForTaskSpecAndVendors(taskSpec: TaskSpec, vendors: Vendor[]): Observable<any> {
    let params: HttpParams;
    params = new HttpParams().set('translators', JSON.stringify(vendors.map(v => v.id)))
        .set('pid', taskSpec.projectId)
        .set('langpair', encodeURIComponent(toLangPairString({source: taskSpec.sourcelanguage, target: taskSpec.targetlanguage})))
        .set('activity', taskSpec.activity.toLowerCase());
    if (taskSpec.service != null) {
        params = params.set('service', taskSpec.service.toLowerCase());
    }
    return this.http.get(this.PRICING_API + '/vendor'
      , {params: params}).pipe(
      map(p => {
          return p
        }
      ));
  }

  getLangPairPrice(pId: string, source: String, target: String): Observable<Pricing> {
    return this.http.get(this.PRICING_API + "/langpair/" + pId + "/" + encodeURIComponent(toLangPairString({source: source, target: target}))).pipe(
      map(p => this.extractData(p)));
  }

  getServiceFee(projectId: string, langpair: any): Observable<number> {
    return this.http.get(this.PRICING_API + "/servicefee/langpair/" + projectId + "/" + encodeURIComponent(toLangPairString(langpair)))
      .pipe(
        map(p => Number(p).valueOf())
      )
  }

  getMTCost(projectId: string, langpair: any): Observable<number> {
    let lp;
    if (typeof langpair == "string")
      lp = langpair;
    else lp = toLangPairString(langpair);
    return this.http.get(this.PRICING_API + "/mtcost/langpair/" + projectId + "/" + encodeURIComponent(lp))
      .pipe(
        map(p => Number(p).valueOf())
      )
  }

  getServiceFeeAndMTCost(projectId: string, langpair: any): Observable<any> {
    return zip(this.getServiceFee(projectId, langpair), this.getMTCost(projectId, langpair))
      .map(v => {
        return { serviceFee: v[0], mtCost: v[1] }
      })
  }

  getCreditCalculationTypes() {
    return this.http.get(this.CREDIT_API + "/calc/types")
  }

  getCreditCalculationParams(calculationType: any) {
    return this.http.get(this.CREDIT_API + "/calc/params/" + calculationType);
  }

  getCreditValidationTypes() {
    return this.http.get(this.CREDIT_API + "/valid/types")
  }

  getCreditValidationParams(validationType: any) {
    return this.http.get(this.CREDIT_API + "/valid/params/" + validationType);
  }

  createCredit(credit: {}) {
    //Fill in the user id from the session storage
    credit["uid"] = JSON.parse(sessionStorage.getItem("user")).id;
    //Call the post method
    return this.http.post(this.CREDIT_API, JSON.stringify(credit), {})
  }

  getCreditsOverview(userId: string) {
    return this.http.get(this.CREDIT_API + "/user/overview/" + userId)
  }

  validateCredit(userId: string, token: string) {
    return this.http.post(this.CREDIT_API + "/validate/" + token, JSON.stringify({user: userId}), {})
  }

  downloadPricingPdf(projectId: string) {
    return this.http.get(this.PRICING_API+"/previewpdf/"+projectId, {responseType: 'blob'})
  }

  private extractData(res: any): Pricing {
    // We specifically create a new Pricing object here, since if you just "cast" to it you won't have access to the methods
    // of the Pricing object
    // I assume this is because the object is sent over as JSON object from the server, which contains only data and no functions
    let p = res || Pricing;
    let pricing = p.hasOwnProperty("langPairDetails") ? new DetailedPricing() : new Pricing();
    pricing.vatRate = p.vatRate;
    pricing.lilo = p.lilo;
    pricing.mt = p.mt;
    pricing.mtsaving = p.mtsaving;
    pricing.tmsaving = p.tmsaving;
    pricing.revision = p.revision;
    pricing.translation = p.translation;
    pricing.credit = p.credit;
    pricing.taskCount = p.taskCount;
    if (p.hasOwnProperty("langPairDetails")) {
      (<DetailedPricing>pricing).langPairDetails = p.langPairDetails;
      Object.keys(p.langPairDetails)
        .forEach((v, i, a) => {
          (<DetailedPricing>pricing).langPairDetails[v] = this.extractData(p.langPairDetails[v]);
        });
    }
    return pricing;
  }
}
