import {Component, Input, OnInit} from "@angular/core";
import {ProjectService} from "../service/project.service";
import {FileService} from "../service/file.service";
import {Task} from "../dto/job";
import {TaskService} from "../service/task.service";
import {map} from 'rxjs/operators';


@Component({
  selector: 'task-file-list',
  templateUrl: './task-file-list.component.html',
  styleUrls: ['task.component.css'],
})
export class TaskFileListComponent implements OnInit {

  @Input()
  task;
  @Input()
  taskFiles;
  @Input()
  docFiles;

  availableJobs;
  availableXliffs;
  availableTmxs;
  availablePdfs;

  constructor(private projectService: ProjectService,
              private taskService: TaskService,
              private fileService: FileService,) {

  }

  ngOnInit(): void {
    this.setTask(this.task);
  }

  setTask(task: Task) {
    this.task = task;
    this.taskService.availableJobs(this.task).subscribe(j => {
      this.availableJobs = j;
    });
    this.fileService.availableXliffs(this.task).pipe(map(v => {
      return v;
    })).subscribe(v => {
      this.availableXliffs = v;
    });
    this.fileService.availablePdfs(this.task).subscribe(v => this.availablePdfs = v);
    this.fileService.availableTMXs(this.task).subscribe(v => this.availableTmxs = v);
  }

  getPdf4Source(fileName: string) {
    if (this.docFiles) {
      return this.docFiles.filter(f => f.docinfo != null && f.docinfo.source4pdf != null && f.docinfo.source4pdf === fileName)[0];
    } else return null;
  }

}
