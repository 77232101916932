import {filter, isEmpty} from 'rxjs/operators';
import {Component, ViewChild} from "@angular/core";
import {MatDialog} from '@angular/material/dialog';
import {TaskFile} from "../dto/job";
import {TaskService} from "../service/task.service";
import {ProjectService} from "../service/project.service";
import {UserService} from "../service/user.service";
import {ActivatedRoute, Router} from "@angular/router";
import {LogInService} from "../service/login.service";
import {EditorService} from "./service/editor.service";
import {EditorSaveComponent} from "./editor-save.component";
import {EditorComponent} from "./editor.component";


@Component({
  selector: 'task-editor',
  templateUrl: 'task-editor.component.html',
  styleUrls: ['editor.component.css'],
})
export class TaskEditorComponent {
  taskId: string;
  taskFile: TaskFile;
  warning: string;

  @ViewChild("editor", { static: false })
  editor: EditorComponent;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private taskService: TaskService,
              private projectService: ProjectService,
              public userService: UserService,
              private loginService: LogInService,
              private editorService: EditorService,
              private dialog: MatDialog,
  ){

  }

  ngOnInit(){
    if (!this.userService.hasTranslatorRole()) {
      this.userService.signInFromLocalStorage().pipe(
        isEmpty(),
        filter(v => v),)
        .subscribe(
          () => Promise.resolve().then(() => this.loginService.openSignInPopup())
        );
    }
    this.route.params
      .subscribe(params => {
        this.taskId = params['id'];
        this.editorService.getTaskFile(params['id'], params['file']).subscribe(data => {
          this.setTaskFile(data);
        });
      });
  }

  setTaskFile(taskFile: TaskFile) {
    this.taskFile = taskFile;
    this.warning = undefined;
    if (this.isTaskCancelled())
      this.warning = "This task has been canceled and is not editable.";
    else if (this.isTaskCompleted())
      this.warning = "This task has been completed and is no longer editable.";
    else if (!this.isTaskAccepted())
      this.warning = "This task has not yet been accepted and is therefor not yet editable. \n" +
        "You must accept the task (on the task detail page) before you are able to start it.";
    // console.debug("TASK ==> \n" + JSON.stringify(taskFile, null, 2));
    // Flag editor access, so we can indicate this in the task page (only if currently logged in user is effectively
    // the assigned translator)
    if (this.userService.isLoggedIn() && this.userService.isCurrentUserId(this.taskFile.task.vendorId))
      this.taskService.setEditorAccessDate(this.taskFile.task.id, this.taskFile.filename).subscribe();
  }

  isTaskAccepted() {
    return this.taskFile && this.taskFile.task.acceptdate;
  }

  isTaskCompleted() {
    return this.taskFile && this.taskFile.task.completed;
  }

  isTaskCancelled() {
    return this.taskFile && this.taskFile.task.canceldate;
  }

  editable() {
    return this.isTaskAccepted() &&
      !this.isTaskCompleted() &&
      !this.isTaskCancelled();
  }

  enableFinalize() {
    return this.editor && this.editor.content &&
      this.editable();
  }

  backToTask() {
    this.router.navigate(['/tdetail', this.taskId]);
  }

  saveTaskFile() {
    let dialogRef = this.dialog.open(EditorSaveComponent, {
      width: '500px',
      height: '50%',
      data: {task: this.taskFile.task, rows: this.editor.rows}
    });
    dialogRef.afterClosed().subscribe(r => {
      if (r === "confirm") {
        this.editorService.completeTaskFile(this.taskFile.filelocation, this.taskId, this.taskFile.filename)
          .subscribe(data => {
//          console.log('Task file completion result: ' + data);
            if (Boolean(data)) {
              // Redirect back to task page if successful
              this.router.navigate(['/tdetail', this.taskId]);
            }
          });
      }
    });
  }

}
