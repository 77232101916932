import {Component, Input, OnInit} from "@angular/core";
import {PricingService} from "../service/pricing.services";
import { FileService } from '../service/file.service';


@Component({
  selector: 'documentation-settings',
  templateUrl: './documentation.setting.component.html',
  styleUrls: ['./user.settings.component.css']
})
export class DocumentationSettingComponent implements OnInit {

  @Input()
  companyId;

  documentationFiles = [];

  constructor(private fileService: FileService) {

  }

  ngOnInit() {
    this.fileService.getCompanyDocumentationList(this.companyId).subscribe(f => {
      this.documentationFiles = f});
  }

  addFile(file: any) {
    this.documentationFiles.push(file.docfile)
  }
}
