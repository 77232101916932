
    <div>
        <p>Select a file to add as documentation to the project.<br/>
        These files will be available during the translation process as reference material.</p>
        <div>
            <div style="display: flex; flex-direction: column; justify-content: flex-start; align-items: flex-start;">
                <label *ngIf="docFile" style="text-align: left; margin-right: 30px;">
                    {{docFile.name}} <span *ngIf="docFile.size">({{docFile.size | filesize}})</span>
                </label>
                <label for="uploadFile" class="lblUpload" style="border-radius: 3px; margin-left: 25px;">
                    <span *ngIf="!docFile" title="Select a file">Select a file</span>
                    <span *ngIf="docFile" title="Select another file">Change</span>
                </label>
                <input id="uploadFile" type="file" (change)="selectFile($event)" placeholder="Upload documentation" style="display: none;"/>
             </div>
             <div *ngIf="isPdf() && srcFiles ">
                <p>Is this file a PDF version of one of the source files? If so, please indicate which one it relates to:</p>
                <select id="selectSource4Pdf" [(ngModel)]="selectedSource4Pdf">
                    <option [ngValue]="na">N/A</option>
                    <option *ngFor="let srcFile of srcFiles" [ngValue]="srcFile">{{srcFile.name}}</option>
                </select>           
            </div>   
        </div>
        <p *ngIf="errorMsg" style="color: red;">{{errorMsg}}</p>
        <div style="margin-top: 25px; text-align: center;">
            <button (click)="upload()" [disabled]="!docFile">OK</button>
            <button (click)="dialogRef.close()">Cancel</button>    
        </div>
    </div>