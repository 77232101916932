<div *ngIf="file != undefined" [title]="file.name" class="targetFileCard">
  <div class="targetFileImage">
    <div class="targetFileImageWrapper">
      <frontpage [pId]="pId" [fileName]="file.name" class="targetFileFrontpage"></frontpage>
      <!--todo: display file size on hover-->
      <!--<div class="targetFileHover">
        <p style="text-align: center;">{{file.name}}</p>
      </div>-->
    </div>
  </div>
  <div class="targetFileDetails">
    <h6>{{file.name|filenamePipe:16:true}}</h6>
    <!-- Download is not triggered by clicking on this component but is done when clicking on the entire tile, in which this div is included -->
    <div class="targetFileDownload">
      <img src="../../../assets/icon_download_coloured.svg" title="download" class="targetFileDownloadColoured"
           alt="download file">
      <img src="../../../assets/download_icon.svg" title="download" class="targetFileDownloadWhite" alt="download file">
    </div>
  </div>
</div>
