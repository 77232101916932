export interface JoinDialogResult {
  result: JoinDialogOutcome,
  join?: JoinType,
  user?: any,
  reason?: any,
}

export enum JoinDialogOutcome {
  SUCCESS,
  ERROR,
  CANCEL,
  SIGN_IN
}

export enum JoinType {
  MAIL,
  LINKEDIN,
  GOOGLE,
}
