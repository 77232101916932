import {map} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {DomSanitizer} from '@angular/platform-browser';
import {TaskFile} from "../../dto/job";
import {MatSnackBar} from "@angular/material/snack-bar";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {TransUnit} from "../dto/transunit";
import {getBaseUrl} from "../../util/httpclient";


@Injectable()
export class EditorService {

  private EDITOR_API = getBaseUrl() + "/api/v1/editor";


  constructor(private http: HttpClient,
              private sanitizer: DomSanitizer,
              private snackbar: MatSnackBar) {
    console.debug("EditorService initializing => connects to " + this.EDITOR_API);
  }

  getTaskFile(taskId: string, fileName: string): Observable<TaskFile> {
    return this.http.get<TaskFile>(this.EDITOR_API + "/task/" + taskId + "/" + encodeURIComponent(fileName));
  }

  getFinalCheckFile(projectId: string, source: string, target: string, fileName: string): Observable<any> {
    return this.http.get(this.EDITOR_API + "/finalcheck/" + projectId + "/" +
      encodeURIComponent(source + "_" + target) + "/" +
      encodeURIComponent(fileName));
  }

  getContent(fileLocation: string, taskId: string): Observable<TransUnit[]> {
    let url = this.EDITOR_API + "/content/" + taskId + "?fileloc=" + encodeURIComponent(fileLocation);
    return this.http.get<TransUnit[]>(url, {responseType: 'json'});
  }

  getBestRepeatedMatch(fileLocation: string, taskId: string, pId: string, tuId: string, segId: string): Observable<any> {
    let url = this.EDITOR_API + "/repetition/" + taskId + "/" + tuId + "/" + segId + "?pid=" + pId + "&fileloc=" + encodeURIComponent(fileLocation);
    let obs: Observable<any> = this.http.get(url, {responseType: 'json'});
    return obs.pipe(map(data => {
      if (data != null) {
        let repeatedMatch = data.segment;
        repeatedMatch.id = segId;
        repeatedMatch.score = data.score;
        repeatedMatch.origin = new Object();
        repeatedMatch.origin.file = data.file;
        repeatedMatch.origin.fileloc = data.fileloc;
        repeatedMatch.origin.tuid = data.tuid;
        repeatedMatch.origin.segid = data.segid;
        let o = Object();
        o.segment = repeatedMatch;
        o.currentTuId = tuId;
        return o;
      } else return null;
    }));
  }

  postModifiedSegment(fileLocation: string, taskId: string, tuId: string, segId: string, newSegment: any[]) {
    // We do a JSON.stringify() on the segment value to escape illegal characters, such as "\", that would make the
    // parsing of the JSON fail
    // Note that the stringify() function surrounds the string with quotes (")
    let body = "{\"segment\": " + JSON.stringify(newSegment) + "}";
    let jsonObj = JSON.parse(body);
    let url = this.EDITOR_API + "/segment/" + taskId + "/" + tuId + "/" + segId + "?fileloc=" + encodeURIComponent(fileLocation);
    return this.http.post(url, jsonObj, {responseType: 'text'});
  }

  completeTaskFile(fileLocation: string, taskId: string, fileName: string) {
    let url = this.EDITOR_API + "/task/" + taskId + "/" + encodeURIComponent(fileName) + "?fileloc=" + encodeURIComponent(fileLocation);
    return this.http.post(url, "", {responseType: 'text'});
  }

  completeFinalCheckFile(fileLocation: string, fileGroupId: string, fileName: string, projectId: string, langpair: string) {
    let url = this.EDITOR_API + "/finalcheck/" + fileGroupId + "/" + projectId + "/" + encodeURIComponent(langpair) + "/" + encodeURIComponent(fileName);
    let params = new HttpParams().set("fileloc", fileLocation);
    return this.http.post(url, "", {responseType: "text", params: params});
  }


  fetchMetaData(fileGroupId: string, fileName: string) {
    let url = this.EDITOR_API + "/metadata/" + fileGroupId + "?filename=" + encodeURIComponent(fileName);
    return this.http.get(url, {responseType: "json"})
  }

  translateWithGoogle(sourceLang: string, targetLang: string, toTranslateText: string) {
    // Apparently not possible from here, produces the following error(s):
    // Cross-Origin Request Blocked: The Same Origin Policy disallows reading the remote resource at https://translate.googleapis.com/translate_a/single?client=gtx&sl=EN&tl=NL&dt=t&q=Figuur%20this%20is%20a%20test%20caption.
    // (Reason: missing token ‘authorization’ in CORS header ‘Access-Control-Allow-Headers’ from CORS preflight channel).[Learn More]
    // Cross-Origin Request Blocked: The Same Origin Policy disallows reading the remote resource at https://translate.googleapis.com/translate_a/single?client=gtx&sl=EN&tl=NL&dt=t&q=Figuur%20this%20is%20a%20test%20caption.
    // (Reason: CORS request did not succeed).
    // TODO: find a workaround for the above?
    const httpOptions = {
      headers: new HttpHeaders({
        // 'Access-Control-Allow-Origin': '*',
        // 'Authorization': 'authkey',
        // 'Access-Control-Allow-Headers': 'Access-Control-Allow-Origin, Authorization'
      }),
    };
    let gTransUrl = "http://translate.googleapis.com/translate_a/single?client=gtx" +
      "&sl=" + this.transformLangCode(sourceLang) +
      "&tl=" + this.transformLangCode(targetLang) +
      "&dt=t" +
      "&q=" + encodeURIComponent(toTranslateText);
    return this.http.get(gTransUrl, httpOptions);
  }

  private transformLangCode(langCode: string) {
    return langCode.substring(0, 2).toUpperCase();
  }
}
