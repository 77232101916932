import {Component, Inject} from "@angular/core";
import {MAT_SNACK_BAR_DATA} from '@angular/material/snack-bar';


@Component({
  selector: 'error-snackbar',
  template: '<h5 [innerHTML]=errorText></h5>',
  styles: [
    'h5{color:red;}',
  ]
})
export class ErrorSnackbarComponent {
  errorText: string;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {
  }

  ngOnInit() {
    this.errorText = this.data.errorText;
  }
}
