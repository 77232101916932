import {BrowserModule} from "@angular/platform-browser";
import {NgModule} from "@angular/core";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {AppComponent} from "./app.component";
import {AppRoutingModule} from "./app-routing.module";
import {ProjectService} from "./service/project.service";
import {ProjectDashboardComponent} from "./dashboard/project-dashb.component";
import {ProjectCreateComponent} from "./projectcomponent/project-create.component";
import {DocumentationUploadDialog, FileAcceptorComponent} from "./projectcomponent/util/file-acceptor.component";
import {AnalyzerComponent} from "./projectcomponent/util/analyzer.component";
import {PricingComponent} from "./projectcomponent/util/pricing.component";
import {LanguageSelectorComponent} from "./projectcomponent/util/language-selector.component";
import {VendorSelectorComponent, VendorSelectorDialog} from "./projectcomponent/util/vendor-selector.component";
import {VendorProfileComponent} from "./projectcomponent/util/vendor-profile.component";
import {LanguageService} from "./service/language.service";
import {VendorService} from "./service/vendor.service";
import {JobSpecComponent} from "./projectcomponent/util/job-spec.component";
import {FileService} from "./service/file.service";
import {FileInfoComponent} from "./projectcomponent/util/file-info.component";
import {ActivityService} from "./service/activity.service";
import {ProjectCardComponent} from "./projectcomponent/project-card.component";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {UserService} from "./service/user.service";
import {LoggerService} from "./service/logger.service";
import {HTTP_INTERCEPTORS, HttpClientJsonpModule, HttpClientModule} from "@angular/common/http";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatButtonModule} from "@angular/material/button";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatChipsModule} from "@angular/material/chips";
import {MatNativeDateModule} from "@angular/material/core";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatDialogModule} from "@angular/material/dialog";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatListModule} from "@angular/material/list";
import {MatMenuModule} from "@angular/material/menu";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {MatSortModule} from "@angular/material/sort";
import {MatTableModule} from "@angular/material/table";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatExpansionModule} from '@angular/material/expansion';
import {AmazingTimePickerModule} from 'amazing-time-picker';
import {SimpleDialog} from "./simple-dialog.component";
import {WaitingDialog} from "./waiting.dialog";
import {TaskService} from "./service/task.service";
import {TaskDashboardComponent} from "./taskcomponent/task-dashboard.component";
import {TaskListComponent} from "./taskcomponent/task-list.component";
import {
  AcceptDialog,
  RejectDialog,
  TaskDetailComponent,
  TranslationUploadDialog
} from "./taskcomponent/task-detail.component";
import {TaskInfoComponent} from "./projectcomponent/util/task-info.component";
import {StatisticsService} from "./service/statistics.service";
import {PricingService} from "./service/pricing.services";
import {IndexDashboardComponent} from "./projectcomponent/index-dashb.component";
import {IndexMainComponent} from "./projectcomponent/index-main.component";
import {UserSettingsComponent} from "./usercomponent/user.settings.component";
import {UserSigninDialog} from "./usercomponent/user.signin.component";
import {UserJoinDialog} from "./usercomponent/user.join.component";
import {ProfileSettingsComponent} from "./usercomponent/profile.setting.component";
import {CompetenceSettingsComponent} from "./usercomponent/competence.setting.component";
import {CompanySettingsComponent} from "./usercomponent/company.setting.component";
import {MailActivationScreen} from "./usercomponent/user.mailactivation.component";
import {PasswordSettingComponent} from "./usercomponent/password.setting.component";
import {AvailabilitySettingComponent} from "./usercomponent/availability.setting.component";
import {EventRegistrationComponent} from "./usercomponent/event.registration.component";
import {NewTranslatorComponent} from "./usercomponent/new.translator.component";
import {DragndropDirective} from './util/dragndrop.directive';
import {LanguagePipe} from "./util/language.pipe";
import {AnalysisService} from "./service/analysis.service";
import {NamePipe} from "./util/name.pipe";
import {FilenamePipe} from "./util/filename.pipe";
import {FilesizePipe} from "./util/filesize.pipe";
import {RatingComponent} from "./reviewcomponent/rating.component";
import {FrontpageComponent} from "./util/frontpage.component";
import {TimeComponent} from "./util/time.component";
import {ColleaguesInfoComponent} from "./taskcomponent/colleagues-info.component";
import {TaskCardComponent} from "./taskcomponent/task-card.component";
import {ConfirmDialog} from "./confirm.dialog";
import {InvoiceComponent} from "./invoicecomponent/invoice.component";
import {SalesInvoiceComponent} from "./invoicecomponent/sales-invoice.component";
import {InvoiceService} from "./service/invoice.service";
import {InvoiceOverviewComponent} from "./invoicecomponent/invoice.overview.component";
import {RatingOverviewComponent} from "./reviewcomponent/rating.overview.component";
import {RatingSmallComponent} from "./reviewcomponent/rating.small.component";
import {RatingFullComponent} from "./reviewcomponent/review.full.component";
import {ReviewPostComponent} from "./reviewcomponent/review.post.component";
import {ReviewCreateComponent} from "./reviewcomponent/review.create.component";
import {ReviewService} from "./service/review.service";
import {ProfileInfoComponent} from "./usercomponent/profile.info.component";
import {ProfileInfoDialogComponent} from "./usercomponent/profile.info.dialog.component";
import {JWTInterceptor} from "./service/JWTInterceptor";
import {CountryService} from "./service/country.service";
import {MultiLanguageSelectionComponent} from "./projectcomponent/util/language-bar.component";
import {VendorListComponent} from "./projectcomponent/util/vendor-list.component";
import {ProfilePicUploadComponent} from "./usercomponent/profile.pic.upload.component";
import {DomainComponent} from "./util/domain.component";
import {DomainService} from "./service/domain.service";
import {ErrorSnackbarComponent} from "./error.snackbar.component";
import {PaymentSettingComponent} from "./usercomponent/payment.setting.component";
import {TaskActivityComponent} from "./projectcomponent/util/task-activity.component";
import {LogInService} from "./service/login.service";
import {AboutComponent} from "./info/about.component";
import {TaskDetailFileComponent} from "./taskcomponent/task-detail-file.component";
import {EditorComponent} from "./editor/editor.component";
import {EditorService} from "./editor/service/editor.service";
import {CountdownComponent} from "./util/countdown.component";
import {SafePipe} from "./info/safe.pipe";
import {SafeHtmlPipe} from "./util/safehtml.pipe";
import {FileMetaDataDialog} from "./projectcomponent/util/file-meta-data.dialog";
import {UserInviteDialog} from "./usercomponent/user.invite.dialog";
import {InvitedComponent} from "./usercomponent/invited.component";
import {InviteSettingComponent} from "./usercomponent/invite.setting.component";
import {PriceComponent} from "./util/price.component";
import {ChatComponent, ChatNotification} from "./chatcomponent/chat.component";
import {MessageComponent} from "./chatcomponent/message.component";
import {NewMessageComponent} from "./chatcomponent/new.message.component";
import {ChatService} from "./service/chat.service";
import {EditorSaveComponent} from "./editor/editor-save.component";
import {EditorSettingsComponent} from "./editor/editor-setting.component";
import {ContenteditableDirective} from "./editor/contenteditable.directive";
import {CreditCreatorComponent} from "./creditcomponent/credit-creator.component";
import {CreditSettingComponent} from "./usercomponent/credit.setting.component";
import {HelpDialogComponent} from "./util/help.dialog.component";
import {TranslatorOverviewComponent} from "./transoverviewcomponent/translator-overview.component";
import {TranslatorSummaryComponent} from "./transoverviewcomponent/translator-summary.component";
import {ProjectListComponent} from "./projectcomponent/project-list.component";
import {MtService} from "./service/mt.service";
import {TmService} from "./service/tm.service";
import {AngularDraggableModule} from 'angular2-draggable';
import {TooltipComponent, TooltipContainerDirective} from "./util/tooltip.component";
import {TooltipDirective} from "./util/tooltip.directive";
import {ProjectCardTooltipComponent} from "./projectcomponent/util/project-card-tooltip.component";
import {DateTimeComponent} from "./util/datetime.component";
import {DateTime2Component} from "./util/datetime2.component";
import {CounterProposalComponent} from "./taskcomponent/counter-proposal.component";
import {AdminDashboardComponent} from "./admin/admin-dashb.component";
import {FolderViewerComponent} from "./admin/folderviewcomponent/folder-viewer.component";
import {
  AddFolderDialog,
  FolderViewComponent,
  RenameFileDialog
} from "./admin/folderviewcomponent/folder-view.component";
import {ProjectFollowupListComponent} from "./admin/followupcomponent/project-followup-list.component";
import {IntegrationsSettingComponent} from "./usercomponent/integrations.setting.component";
import {HubspotService} from "./service/hubspot.service";
import {HubspotProjectCreateComponent} from "./hubspotcomponent/hubspot-project-create.component";
import {HubspotPageComponent} from "./hubspotcomponent/hubspot-page.component";
import {CookieService} from "ngx-cookie-service";
import {UploadDataService} from "./projectcomponent/util/upload-data.service";
import {TextcutPipe} from "./util/textcut.pipe";
import {TransCheckComponent} from "./transcheckcomponent/trans-check.component";
import {TaskEditorComponent} from "./editor/task-editor.component";
import {FinalCheckEditorComponent} from "./editor/final-check-editor.component";
import {OauthCallbackComponent} from "./usercomponent/oauth-callback.component";
import {InlayoutComponent} from "./editor/inlayout.component";
import {RootComponent} from "./root.component";
import {DropEntryComponent} from "./dropentrycomponent/drop.entry.component";
import {PreUploadComponent} from "./dropentrycomponent/pre-upload.component";
import {PreUploadFileComponent} from "./dropentrycomponent/pre-upload-file.component";
import {SourceFileListComponent} from "./projectcomponent/filecomponents/source-file-list.component";
import {SourceFileComponent} from "./projectcomponent/filecomponents/source-file.component";
import {FileAddComponent} from "./projectcomponent/filecomponents/file-add.component";
import {SourceFileAddComponent} from "./projectcomponent/filecomponents/source-file-add.component";
import {DocumentationFileAddComponent} from './projectcomponent/filecomponents/documentation-file-add.component';
import {DocumentationListComponent} from './projectcomponent/filecomponents/documentationcomponents/documentation-list.component';
import {DocumentationFileComponent} from './projectcomponent/filecomponents/documentationcomponents/documentation-file.component';
import {LangpairSelectComponent} from "./projectcomponent/langselectcomponent/langpair-select.component";
import {LangSelectComponent} from "./util/lang-select.component";
import {LangSelectListComponent} from "./util/lang-select-list.component";
import {Error404Component} from "./util/error.404.component";
import {LanguageBarComponent} from "./projectcomponent/languagebar/language-bar.component";
import {AccordionModule, CollapseModule} from 'ngx-foundation';
import {TranslatorListComponent} from "./projectcomponent/languagebar/translator-list.component";
import {TranslatorCardComponent} from "./projectcomponent/languagebar/translator-card.component";
import {FirstnameBreakPipe} from "./util/firstname-break.pipe";
import {ProjectInfoCreateComponent} from "./projectcomponent/projectinfocomponents/project-info-create.component";
import {ProjectUserInfoComponent} from "./projectcomponent/userinfocomponents/project-user-info.component";
import {ReturnToBreakPipe} from "./util/return-to-break.pipe";
import {ProjectPaymentComponent} from "./projectcomponent/paymentcomponents/project-payment.component";
import {CreditCardComponent} from "./projectcomponent/paymentcomponents/credit-card.component";
import {PaypalComponent} from "./projectcomponent/paymentcomponents/paypal.component";
import {ProjectPricingOverviewComponent} from "./projectcomponent/pricingoverviewcomponents/project-pricing-overview.component";
import {ProjectDetailsComponent} from "./projectcomponent/project-details.component";
import {ProjectFollowupComponent} from "./projectcomponent/project-followup.component";
import {ProjectInfoComponent} from "./projectcomponent/projectinfocomponents/project-info.component";
import {TargetFileListComponent} from "./projectcomponent/filecomponents/target-file-list.component";
import {LangpairTargetFilesComponent} from "./projectcomponent/filecomponents/langpair-target-files.component";
import {TargetFileComponent} from "./projectcomponent/filecomponents/target-file.component";
import {ProjectTaskComponent} from "./projectcomponent/languagebar/project-task.component";
import {ProjectUpdateOverviewComponent} from './projectcomponent/pricingoverviewcomponents/project-update-overview.component';
import {MugshotComponent} from "./usercomponent/mugshot.component";
import {ProjectChatComponent} from './projectcomponent/projectchatcomponents/project-chat.component';
import {ConverterService} from './service/converter.service';
import {ColleagueSelectComponent} from './projectcomponent/projectinfocomponents/colleague-select.component';
import {ColleagueSelectListComponent} from './projectcomponent/projectinfocomponents/colleague-select-list.component';
import {CostCalcComponent} from './costcalcomponent/cost-calc.component';
import {DocumentationPDFDialog} from "./projectcomponent/filecomponents/documentation-pdf-dialog";
import {UserProfileComponent} from "./usercomponent/user-profile.component";
import {ReviewScrollerComponent} from "./usercomponent/reviewcomponents/review-scroller.component";
import {ReviewItemComponent} from "./usercomponent/reviewcomponents/review.item.component";
import {CompetenceListComponent} from "./usercomponent/competence-list.component";
import {TaskOverviewComponent} from "./taskcomponent/task-overview.component";
import {TaskFileComponent} from "./taskcomponent/task-file.component";
import {TaskFileListComponent} from "./taskcomponent/task-file-list.component";
import {TmManagementComponent} from "./admin/tmmgmtcomponent/tm-management.component";
import {GTACInputComponent} from './util/gtac-input.component';
import {DocumentationSettingComponent} from './usercomponent/documentation.setting.component';
import {CustomerGroupListComponent} from "./admin/user/customergroup/customer-group-list.component";
import {DynamicTableComponent} from "./util/component/dynamic-table/dynamic-table.component";
import {CommonModule, DatePipe} from "@angular/common";
import {PurchaseInvoiceComponent} from "./invoicecomponent/purchase-invoice.component";
import {ProjectCleanupComponent} from "./admin/project/cleanupcomponent/project-cleanup.component";
import { MimicUserComponent } from "./admin/user/mimicuser/mimicuser.component";

@NgModule({
  declarations: [
    IndexMainComponent,
    IndexDashboardComponent,
    ProjectDashboardComponent, ProjectListComponent,
    AdminDashboardComponent,
    RootComponent,
    AppComponent,
    DropEntryComponent,
    PreUploadComponent,
    PreUploadFileComponent,
    ProjectCreateComponent,
    ProjectDetailsComponent,
    ProjectFollowupComponent,
    FileAcceptorComponent,
    AnalyzerComponent,
    PricingComponent,
    LanguageSelectorComponent,
    VendorSelectorComponent,
    VendorListComponent,
    VendorSelectorDialog,
    VendorProfileComponent,
    JobSpecComponent,
    TaskActivityComponent,
    FileInfoComponent,
    ProjectCardComponent,
    RejectDialog,
    AcceptDialog,
    TranslationUploadDialog,
    DocumentationUploadDialog,
    SimpleDialog,
    WaitingDialog,
    ErrorSnackbarComponent,
    ConfirmDialog,
    FileMetaDataDialog,
    MultiLanguageSelectionComponent,
    TaskDashboardComponent,
    TaskListComponent,
    TaskDetailComponent,
    TaskDetailFileComponent,
    TaskInfoComponent,
    CounterProposalComponent,
    UserSettingsComponent,
    UserJoinDialog,
    UserSigninDialog,
    UserInviteDialog,
    ProfileSettingsComponent,
    ProfilePicUploadComponent,
    CompetenceSettingsComponent,
    CompanySettingsComponent,
    PasswordSettingComponent,
    AvailabilitySettingComponent,
    PaymentSettingComponent,
    InviteSettingComponent,
    CreditSettingComponent,
    IntegrationsSettingComponent,
    DocumentationSettingComponent,
    EventRegistrationComponent,
    MailActivationScreen,
    NewTranslatorComponent,
    DragndropDirective,
    LanguagePipe,
    NamePipe,
    FilenamePipe,
    FilesizePipe,
    TextcutPipe,
    SafePipe,
    SafeHtmlPipe,
    FirstnameBreakPipe,
    ReturnToBreakPipe,
    RatingComponent,
    FrontpageComponent,
    TimeComponent,
    DateTimeComponent,
    DateTime2Component,
    PriceComponent,
    ColleaguesInfoComponent,
    TaskCardComponent,
    InvoiceComponent,
    SalesInvoiceComponent,
    PurchaseInvoiceComponent,
    InvoiceOverviewComponent,
    RatingOverviewComponent,
    RatingSmallComponent,
    RatingFullComponent,
    ReviewPostComponent,
    ReviewCreateComponent,
    ProfileInfoComponent,
    ProfileInfoDialogComponent,
    HelpDialogComponent,
    DomainComponent,
    AboutComponent,
    EditorComponent,
    EditorSaveComponent,
    EditorSettingsComponent,
    TaskEditorComponent,
    InlayoutComponent,
    CountdownComponent,
    InvitedComponent,
    ChatComponent,
    MessageComponent,
    NewMessageComponent,
    ChatNotification,
    ContenteditableDirective,
    CreditCreatorComponent,
    TranslatorOverviewComponent, TranslatorSummaryComponent,
    TooltipComponent, TooltipDirective, TooltipContainerDirective,
    ProjectCardTooltipComponent,
    FolderViewerComponent, FolderViewComponent, RenameFileDialog, AddFolderDialog,
    ProjectFollowupListComponent,
    TmManagementComponent,
    HubspotProjectCreateComponent,
    HubspotPageComponent,
    TransCheckComponent,
    FinalCheckEditorComponent,
    OauthCallbackComponent,
    Error404Component,
    SourceFileListComponent,
    SourceFileComponent,
    FileAddComponent,
    SourceFileAddComponent,
    DocumentationFileAddComponent,
    DocumentationFileComponent,
    DocumentationListComponent,
    LangpairSelectComponent,
    LangSelectComponent,
    LangSelectListComponent,
    LanguageBarComponent,
    TranslatorListComponent,
    TranslatorCardComponent,
    ProjectInfoCreateComponent,
    ProjectUserInfoComponent,
    ProjectPaymentComponent,
    CreditCardComponent,
    PaypalComponent,
    ProjectPricingOverviewComponent,
    ProjectInfoComponent,
    TargetFileListComponent,
    LangpairTargetFilesComponent,
    TargetFileComponent,
    ProjectTaskComponent,
    ProjectUpdateOverviewComponent,
    MugshotComponent,
    ProjectChatComponent,
    ColleagueSelectComponent,
    ColleagueSelectListComponent,
    CostCalcComponent,
    DocumentationPDFDialog,
    UserProfileComponent,
    ReviewScrollerComponent, ReviewItemComponent,
    CompetenceListComponent,
    TaskFileComponent, TaskFileListComponent, TaskOverviewComponent,
    GTACInputComponent,
    DynamicTableComponent,
    CustomerGroupListComponent,
    ProjectCleanupComponent,
    MimicUserComponent
  ],
  entryComponents: [SimpleDialog, WaitingDialog, ErrorSnackbarComponent,
    ConfirmDialog, RejectDialog, AcceptDialog, UserSigninDialog, UserJoinDialog, UserInviteDialog, VendorSelectorDialog,
    EventRegistrationComponent, TranslationUploadDialog, MultiLanguageSelectionComponent, FileMetaDataDialog,
    EditorSaveComponent, EditorSettingsComponent, ChatNotification, ProfileInfoDialogComponent, TooltipComponent,
    ProjectCardTooltipComponent, HelpDialogComponent, RenameFileDialog, AddFolderDialog, DocumentationUploadDialog,
    DocumentationPDFDialog
  ],
  exports: [
    HttpClientModule
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    MatTooltipModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    AppRoutingModule,
    HttpClientModule,
    HttpClientJsonpModule,
    MatDialogModule,
    MatSnackBarModule,
    MatButtonModule,
    MatListModule,
    MatMenuModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatExpansionModule,
    MatChipsModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatCheckboxModule,
    AmazingTimePickerModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    AngularDraggableModule,
    AccordionModule.forRoot(),
    CollapseModule.forRoot(),
  ],
  providers: [
    DatePipe,
    ProjectService,
    UploadDataService,
    LanguageService,
    DomainService,
    CountryService,
    ActivityService,
    StatisticsService,
    AnalysisService,
    PricingService,
    VendorService,
    FileService,
    UserService,
    TaskService,
    ReviewService,
    InvoiceService,
    LogInService,
    LoggerService,
    EditorService,
    ChatService,
    MtService,
    TmService,
    ConverterService,
    HubspotService,
    CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JWTInterceptor,
      multi: true
    },
    {provide: 'apiKey', useValue: '86ouotrrf9uueg'},
  ],
  bootstrap: [RootComponent]
})
export class AppModule {

  ngDoBootstrap() {
  }
}
