import {Component, EventEmitter, Input, Output} from '@angular/core';
import { FileService } from '../../../service/file.service';
import * as FileSaver from "file-saver";


@Component({
  selector: 'documentation-file',
  template: `
	  <div *ngIf="file" class="documentationItem documentationFile" style="" [title]="file.name">
		  <div class="documentationFileInfo">
        <div class="documentationFileInfoImage">
          <frontpage [fileName]="file.name" [height]="'85px'" [width]="'70px'"></frontpage>
          <div class="sourceFileHover">
          <div class="sourceFileDownload">
              <div (click)="download()">
                <img src="../../../assets/download_icon.svg" title="Download" alt="Download file">
              </div>
            </div>
            <div *ngIf="allowRemoval" class="sourceFileDelete" (click)="emitFileRemove()">
              <img src="../../../assets/delete_icon_resized.svg" title="Remove" alt="Remove file">
            </div>
          </div>
        </div>
			  <h5 [ngStyle]="{'text-decoration': file.warning != null ? 'line-through' : 'initial'}">{{file.name|filenamePipe:24:true}}</h5>
      </div>
	  </div>`,
  styleUrls: ['../../project.component.css'],
})
export class DocumentationFileComponent {

  @Input()
  file = undefined;
  @Input()
  entityId;
  @Input()
  entityType;
  @Input()
  allowRemoval = false;
  @Output()
  removeEmitter = new EventEmitter();

  constructor(private fileservice: FileService) {
  }

  emitFileRemove() {
    this.removeEmitter.emit(this.file)
  }

  download() {
    this.fileservice.downloadDoc(this.entityId, this.file.name, this.entityType).subscribe(
      n => {
        FileSaver.saveAs(n, this.file.name, true);
      }
    )
  }
}
