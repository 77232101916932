<div *ngIf="taskFiles != undefined">

  <div *ngIf="taskFiles.length > 0">
    <div class="taskFileTileView">
      <task-file *ngFor="let file of taskFiles" class="taskFileTile"
                 [file]="file" [task]="task"
                 [availableJobs]="availableJobs" [availableXliffs]="availableXliffs"
                 [availablePDFs]="availablePdfs" [availableTMXs]="availableTmxs"
                 [source4pdf]="getPdf4Source(file.name)"></task-file>
    </div>
  </div>

  <div *ngIf="taskFiles.length === 0">
    <p>No files ready yet.</p>
  </div>

</div>
