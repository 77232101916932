/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../project.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "./colleague-select.component.ngfactory";
import * as i4 from "./colleague-select.component";
import * as i5 from "../../service/user.service";
import * as i6 from "@angular/common";
import * as i7 from "../../util/datetime2.component.ngfactory";
import * as i8 from "@angular/material/core";
import * as i9 from "../../util/datetime2.component";
import * as i10 from "@angular/cdk/platform";
import * as i11 from "./project-info-create.component";
import * as i12 from "../../service/project.service";
var styles_ProjectInfoCreateComponent = [i0.styles];
var RenderType_ProjectInfoCreateComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProjectInfoCreateComponent, data: {} });
export { RenderType_ProjectInfoCreateComponent as RenderType_ProjectInfoCreateComponent };
function View_ProjectInfoCreateComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "infoField"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "infoLabel"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 8, "div", [["class", "optionDiv checkboxWithLabelDiv"]], [[8, "title", 0]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 5, "input", [["id", "allowMT"], ["type", "checkbox"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "change"], [null, "ngModelChange"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onChange($event.target.checked) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 4).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("change" === en)) {
        var pd_2 = (_co.allowMTChanged() !== false);
        ad = (pd_2 && ad);
    } if (("ngModelChange" === en)) {
        var pd_3 = ((_co.allowMT = $event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 16384, null, 0, i2.CheckboxControlValueAccessor, [i1.Renderer2, i1.ElementRef], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.CheckboxControlValueAccessor]), i1.ɵdid(6, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(8, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 1, "label", [["for", "allowMT"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Allow the usage of MT"]))], function (_ck, _v) { var _co = _v.component; var currVal_8 = _co.allowMT; _ck(_v, 6, 0, currVal_8); }, function (_ck, _v) { var currVal_0 = "Allow the system to leverage machine translation using DeepL, potentially reducing the cost of your project."; _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 8).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 8).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 8).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 8).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 8).ngClassValid; var currVal_6 = i1.ɵnov(_v, 8).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 8).ngClassPending; _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
function View_ProjectInfoCreateComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "checkboxWithLabelDiv saveDefaultShares"], ["title", "Save and share my future project(s) by default with the current selection"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "input", [["id", "saveShares"], ["type", "checkbox"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "change"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onChange($event.target.checked) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("ngModelChange" === en)) {
        var pd_2 = ((_co.saveSharesAsDefault = $event) !== false);
        ad = (pd_2 && ad);
    } if (("change" === en)) {
        var pd_3 = (_co.saveSharesChanged() !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, null, 0, i2.CheckboxControlValueAccessor, [i1.Renderer2, i1.ElementRef], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.CheckboxControlValueAccessor]), i1.ɵdid(4, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(6, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "label", [["for", "saveShares"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Save as default shares"]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.saveSharesAsDefault; _ck(_v, 4, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 6).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 6).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 6).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 6).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 6).ngClassValid; var currVal_5 = i1.ɵnov(_v, 6).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 6).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_ProjectInfoCreateComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "infoField"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "infoLabel"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["class", "optionDiv shareProject"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Share my project with"])), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["style", "display: flex; flex-direction: column;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "colleague-select", [], null, [[null, "selectedColleaguesChange"], [null, "onSelectedColleaguesChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedColleaguesChange" === en)) {
        var pd_0 = ((_co.projectShares = $event) !== false);
        ad = (pd_0 && ad);
    } if (("onSelectedColleaguesChange" === en)) {
        var pd_1 = (_co.saveProjectShares($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_ColleagueSelectComponent_0, i3.RenderType_ColleagueSelectComponent)), i1.ɵdid(7, 638976, null, 0, i4.ColleagueSelectComponent, [i5.UserService], { selectedColleagues: [0, "selectedColleagues"] }, { onSelectedColleaguesChange: "onSelectedColleaguesChange" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProjectInfoCreateComponent_3)), i1.ɵdid(9, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.projectShares; _ck(_v, 7, 0, currVal_0); var currVal_1 = _co.defaultSharesChanged; _ck(_v, 9, 0, currVal_1); }, null); }
export function View_ProjectInfoCreateComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 34, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "infoField"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "label", [["class", "infoLabel"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Name"])), (_l()(), i1.ɵeld(4, 0, null, null, 5, "input", [["class", "infoInput"], ["type", "text"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "change"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 5).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 5)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 5)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.project.title = $event) !== false);
        ad = (pd_4 && ad);
    } if (("change" === en)) {
        var pd_5 = (_co.setTitle() !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 16384, null, 0, i2.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DefaultValueAccessor]), i1.ɵdid(7, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(9, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i1.ɵeld(10, 0, null, null, 6, "div", [["class", "infoField"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "label", [["class", "infoLabel"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Due date"])), (_l()(), i1.ɵeld(13, 0, null, null, 3, "datetime2", [["class", "infoInput"]], null, [[null, "selectedDateChange"], [null, "minDateChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedDateChange" === en)) {
        var pd_0 = ((_co.duedate = $event) !== false);
        ad = (pd_0 && ad);
    } if (("minDateChange" === en)) {
        var pd_1 = ((_co.minDueDate = $event) !== false);
        ad = (pd_1 && ad);
    } if (("selectedDateChange" === en)) {
        var pd_2 = (_co.setProjectDueDate() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i7.View_DateTime2Component_0, i7.RenderType_DateTime2Component)), i1.ɵprd(4608, null, i8.DateAdapter, i9.AppDateAdapter, [[2, i8.MAT_DATE_LOCALE], i10.Platform]), i1.ɵdid(15, 638976, [["duedatepicker", 4]], 0, i9.DateTime2Component, [], { minDate: [0, "minDate"], selectedDate: [1, "selectedDate"] }, { selectedDateChange: "selectedDateChange" }), i1.ɵprd(256, null, i8.MAT_DATE_FORMATS, i9.NATIVE_DATE_FORMATS, []), (_l()(), i1.ɵeld(17, 0, null, null, 10, "div", [["class", "infoField"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 0, "div", [["class", "infoLabel"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 8, "div", [["class", "proposedDueDate"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 7, "small", [], null, null, null, null, null)), i1.ɵprd(512, null, i6.ɵNgClassImpl, i6.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(22, 278528, null, 0, i6.NgClass, [i6.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(23, { valid: 0, invalid: 1 }), (_l()(), i1.ɵted(-1, null, [" Translators will most likely not accept such a tight deadline."])), (_l()(), i1.ɵeld(25, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(26, 0, null, null, 1, "u", [["style", "cursor: pointer;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setProjectDueDateToProposed() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Go back to the suggested date."])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProjectInfoCreateComponent_1)), i1.ɵdid(29, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProjectInfoCreateComponent_2)), i1.ɵdid(31, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(32, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(33, 0, null, null, 1, "button", [["class", "nextButton"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.storeProjectInfo() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Next"]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.project.title; _ck(_v, 7, 0, currVal_7); var currVal_8 = _co.minDueDate; var currVal_9 = _co.duedate; _ck(_v, 15, 0, currVal_8, currVal_9); var currVal_10 = _ck(_v, 23, 0, !_co.isDueDateBeforeProposal, _co.isDueDateBeforeProposal); _ck(_v, 22, 0, currVal_10); var currVal_11 = (_co.mtValid === true); _ck(_v, 29, 0, currVal_11); var currVal_12 = _co.userService.isLoggedIn(); _ck(_v, 31, 0, currVal_12); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 9).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 9).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 9).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 9).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 9).ngClassValid; var currVal_5 = i1.ɵnov(_v, 9).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 9).ngClassPending; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_ProjectInfoCreateComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "project-info-create", [], null, null, null, View_ProjectInfoCreateComponent_0, RenderType_ProjectInfoCreateComponent)), i1.ɵdid(1, 638976, null, 0, i11.ProjectInfoCreateComponent, [i12.ProjectService, i5.UserService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProjectInfoCreateComponentNgFactory = i1.ɵccf("project-info-create", i11.ProjectInfoCreateComponent, View_ProjectInfoCreateComponent_Host_0, { project: "project", proposedDueDate: "proposedDueDate", mtValid: "mtValid", defaultShares: "defaultShares" }, { onAllowMtChanged: "onAllowMtChanged", onSaveSharesChanged: "onSaveSharesChanged", eventEmitter: "eventEmitter" }, []);
export { ProjectInfoCreateComponentNgFactory as ProjectInfoCreateComponentNgFactory };
