import {Component, Input} from "@angular/core";
import {PricingService} from "../service/pricing.services";


@Component({
  selector: 'credit-settings',
  templateUrl: './credit.setting.component.html',
  styleUrls:['./user.settings.component.css']
})
export class CreditSettingComponent {
  credits;
  validationToken;

  @Input()
  user;

  constructor(private pricingService:PricingService){

  }

  ngOnInit(){
    this.initCredits();
  }

  initCredits(){
    this.pricingService.getCreditsOverview(this.user.id)
      .subscribe(credits => this.credits=credits)
  }

  validateToken(){
    this.pricingService.validateCredit(this.user.id, this.validationToken).subscribe(r => {
      if (r == "success")
        this.initCredits();
    })
  }
}
