
	  <div>
		  <div class="searchLangs">
			  <input type="text" [(ngModel)]="searchText"
					 placeholder="Search languages" (input)="filterLangs()" id="languageSearchInput"/></div>
		  <div class="languagesList">
			  <div *ngFor="let lang of filteredLangs" class="languageItem"
				   [ngStyle]="{'background-color': (isSelected(lang)?'var(--background-gray)' : 'transparent')}"
				   (click)="toggleLang(lang)">
				  <button>
			  {{lang|languagePipe|async}}<span *ngIf="isSelected(lang)" class="languageItemSelected">&nbsp;
                <img src="../../assets/icon_accept.svg" alt="selected">
          </span>
				  </button>
			  </div>
		  </div>
	  </div>