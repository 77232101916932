import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from "@angular/core";
import {InvoiceService} from "../../service/invoice.service";
import {getPaymentImage} from "../../util/userutil";


@Component({
  selector: 'credit-card',
  templateUrl: './credit-card.component.html',
  styleUrls: ['../project.component.css'],
})
export class CreditCardComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input()
  userId;
  @Output()
  cardChangeEmitter = new EventEmitter<any>();

  @ViewChild('cardInfo', { static: false }) cardInfo: ElementRef;
  card: any;
  cardHandler = this.onChange.bind(this);
  error: string;

  paymentImage;
  paymentImageDisabled;

  currentCard = undefined;

  constructor(private invoiceService: InvoiceService) {

  }

  ngOnInit() {
    this.invoiceService.findPaymentMethod(this.userId).subscribe(v => {
      if (v !== undefined && v.card !== undefined ) {
        this.currentCard = v.card;
        this.paymentImage = this.getPaymentImageUrl(this.currentCard.brand);
      }
    }
    )
  }

  ngAfterViewInit(): void {
    this.initCardInfoView()
  }

  ngOnDestroy() {
    if (this.card != null) {
      this.card.removeEventListener('change', this.cardHandler);
      this.card.destroy();
    }
  }

  onChange({ error }) {
    if (error) {
      this.error = error.message;
    } else {
      this.error = null;
      this.cardChangeEmitter.emit(this.card);
    }
  }

  changeCardInfo() {
    this.currentCard = undefined;
  }

  initCardInfoView() {
    if (this.cardInfo !== undefined) {
      this.card = elements.create('card', {style: {base: {'max-width': '200px'}}});
      this.card.mount(this.cardInfo.nativeElement);
      this.card.addEventListener('change', this.cardHandler);
    }
  }

  async storeCreditCard(): Promise<any> {
    const localThis = this;
    if (this.currentCard !== undefined){
      return Promise.resolve();
    }
    await this.invoiceService.getPaymentIntent()
      .toPromise()
      .then(sk => {
        return stripe.handleCardSetup(
          sk, this.card
        )
      })
      .then(function (result) {
        console.debug("Stored credit card for user " , result);
        if (result.error) {
          throw result.error;
        } else {
          return localThis.invoiceService.storeUserPaymentMethod(result).toPromise()
        }
      })
  }

  private getPaymentImageUrl(paymentMethod: string) {
    return './../../assets/paymentmethod/' + getPaymentImage(paymentMethod);
  }
}
