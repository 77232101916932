<div class="paymentComponent">
  <div class="infoField" *ngIf="loaded && user!=undefined && user.id!=undefined && currentCard==undefined">
    <input type="radio" id="creditcard" [value]="'STRIPE'" name="paymentMethod" [(ngModel)]="project.paymentmethod" (change)="paymentMethodChange($event)" *ngIf="payLaterAllowed || paypalAllowed"/>
    <label class="infoLabel" for="creditcard">Pay with credit card</label>
      <div class="infoInput" style="min-width: 350px;">
            <credit-card [userId]="user.id" style="width: 100%; "></credit-card>
      </div>
  </div>
  <div class="infoField" *ngIf="user!=undefined && user.id!=undefined && currentCard!=undefined">
    <input type="radio" id="creditcard2" [value]="'STRIPE'" name="paymentMethod" [(ngModel)]="project.paymentmethod" (change)="paymentMethodChange($event)" *ngIf="payLaterAllowed || paypalAllowed"/>
    <label class="infoLabel" for="creditcard2">Pay with credit card</label>
    <div class="infoInput" style="background: none;">
        <p style="width: 80%;">ending {{currentCard.last4}}</p>
        <p style="text-align: right; width: 20%;" (click)="removeCard()"><u>change</u></p>
    </div>
  </div>
  <div class="infoField" *ngIf="payLaterAllowed">
    <input type="radio" id="banktransfer" [value]="'BANKTRANSFER'" name="paymentMethod" [(ngModel)]="project.paymentmethod" (change)="paymentMethodChange($event)" />
    <label class="infoLabel" for="banktransfer">Pay later</label>
  </div>
  <div class="infoField" *ngIf="paypalAllowed">
    <input type="radio" id="paypal" [value]="'PAYPAL'" name="paymentMethod" [(ngModel)]="project.paymentmethod" (change)="paymentMethodChange($event)"/>
    <label class="infoLabel" for="paypal">Pay via paypal</label>
  </div>
  <div style="max-width: 80%; display: flex;">
    <p [hidden]="project.paymentmethod!='PAYPAL'" class="paymentStoreInfo">With paypal, the amount will be captured upon delivery of the last file or after 29 days (whichever comes first).</p>
  </div>


</div>
<p class="paymentStoreInfo">🔒  Your details are securely stored with our payment processing partner using bank-grade encryption.</p>

