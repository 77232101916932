/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@angular/material/tooltip";
import * as i3 from "@angular/cdk/overlay";
import * as i4 from "@angular/cdk/scrolling";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/cdk/bidi";
import * as i8 from "@angular/platform-browser";
import * as i9 from "./price.component";
import * as i10 from "../service/country.service";
var styles_PriceComponent = [];
var RenderType_PriceComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PriceComponent, data: {} });
export { RenderType_PriceComponent as RenderType_PriceComponent };
function View_PriceComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "currPrice"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.undefinedDefault; _ck(_v, 1, 0, currVal_0); }); }
function View_PriceComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "span", [["class", "currPrice"]], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgStyleImpl, i1.ɵNgStyleR2Impl, [i0.ElementRef, i0.KeyValueDiffers, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgStyle, [i1.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), (_l()(), i0.ɵted(3, null, [" ", " "])), i0.ɵppd(4, 4)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getCurrStyles(); _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i0.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i0.ɵnov(_v.parent, 0), (_co.price * _co.rate), _co.displayCurrency, _co.display, _co.digitsInfo)); _ck(_v, 3, 0, currVal_1); }); }
function View_PriceComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [["class", "eurPrice"], ["style", "font-size: smaller;"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" (", ") "])), i0.ɵppd(2, 4)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i0.ɵnov(_v.parent, 0), _co.price, _co.baseCurrency, _co.display, _co.digitsInfo)); _ck(_v, 1, 0, currVal_0); }); }
export function View_PriceComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i1.CurrencyPipe, [i0.LOCALE_ID]), (_l()(), i0.ɵeld(1, 16777216, null, null, 7, "span", [], null, [[null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; if (("longpress" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i0.ɵnov(_v, 2)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i0.ɵnov(_v, 2)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 212992, null, 0, i2.MatTooltip, [i3.Overlay, i0.ElementRef, i4.ScrollDispatcher, i0.ViewContainerRef, i0.NgZone, i5.Platform, i6.AriaDescriber, i6.FocusMonitor, i2.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i7.Directionality], [2, i2.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i8.HAMMER_LOADER]], { message: [0, "message"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PriceComponent_1)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PriceComponent_2)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PriceComponent_3)), i0.ɵdid(8, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", _co.tooltipText(), ""); _ck(_v, 2, 0, currVal_0); var currVal_1 = ((_co.price === undefined) || (_co.price === null)); _ck(_v, 4, 0, currVal_1); var currVal_2 = (_co.price != null); _ck(_v, 6, 0, currVal_2); var currVal_3 = (((_co.price != null) && _co.showBase) && (_co.baseCurrency != _co.displayCurrency)); _ck(_v, 8, 0, currVal_3); }, null); }
export function View_PriceComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "price", [], null, null, null, View_PriceComponent_0, RenderType_PriceComponent)), i0.ɵdid(1, 114688, null, 0, i9.PriceComponent, [i10.CountryService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PriceComponentNgFactory = i0.ɵccf("price", i9.PriceComponent, View_PriceComponent_Host_0, { baseCurrency: "baseCurrency", displayCurrency: "displayCurrency", price: "price", digitsInfo: "digitsInfo", display: "display", showBase: "showBase", currStyles: "currStyles", undefinedDefault: "undefinedDefault" }, {}, []);
export { PriceComponentNgFactory as PriceComponentNgFactory };
