/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./task-dashb.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./colleagues-info.component";
import * as i4 from "../service/task.service";
var styles_ColleaguesInfoComponent = [i0.styles];
var RenderType_ColleaguesInfoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ColleaguesInfoComponent, data: {} });
export { RenderType_ColleaguesInfoComponent as RenderType_ColleaguesInfoComponent };
function View_ColleaguesInfoComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "p", [["style", "display: flex; justify-content: space-between;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["style", "flex: 0 0 auto;"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ":"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.activity; _ck(_v, 4, 0, currVal_0); var currVal_1 = _v.context.$implicit.vendor; _ck(_v, 6, 0, currVal_1); }); }
function View_ColleaguesInfoComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Other tasks in same project"])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ColleaguesInfoComponent_2)), i1.ɵdid(5, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.colleagueInfo; _ck(_v, 5, 0, currVal_0); }, null); }
export function View_ColleaguesInfoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ColleaguesInfoComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.colleagueInfo && (_co.colleagueInfo.length > 0)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ColleaguesInfoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "colleagues-info", [], null, null, null, View_ColleaguesInfoComponent_0, RenderType_ColleaguesInfoComponent)), i1.ɵdid(1, 114688, null, 0, i3.ColleaguesInfoComponent, [i4.TaskService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ColleaguesInfoComponentNgFactory = i1.ɵccf("colleagues-info", i3.ColleaguesInfoComponent, View_ColleaguesInfoComponent_Host_0, { pId: "pId", currentTask: "currentTask" }, {}, []);
export { ColleaguesInfoComponentNgFactory as ColleaguesInfoComponentNgFactory };
