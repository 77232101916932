import {Component, EventEmitter, Input, Output} from "@angular/core";
import {switchMap, tap} from 'rxjs/operators';
import {UserService} from "../../service/user.service";
import {CountryService} from "../../service/country.service";
import {LogInService} from "../../service/login.service";


@Component({
  selector: 'project-user-info',
  templateUrl: './project-user-info.component.html',
  styleUrls: ['../project.component.css'],
})
export class ProjectUserInfoComponent {

  @Input()
  project;
  @Output()
  eventEmitter = new EventEmitter<any>();

  user: any;
  company: any;
  isNewCompany = false;
  edit = false;

  countries: any[] = [];


  constructor(private userService: UserService,
              private countryService: CountryService,
              private loginService: LogInService) {

  }

  ngOnInit() {
    this.userService.loginEmitter.subscribe(() => this.setUserAndCompany());
    this.setUserAndCompany();
    this.countryService.getCountries().subscribe(countries => this.countries = countries)
  }

  setUserAndCompany() {
    this.user = this.userService.getCurrentUser();
    if (this.user != undefined) {
      if (this.user.companyId != undefined)
        this.userService.findCompanyInfo(this.user.companyId)
          .subscribe(c => {
            this.company = c;
            this.isNewCompany = false;
          });
      else {
        this.company = {};
        this.isNewCompany = true;
        this.edit = true;
      }
    }
  }

  storeUserInfo() {
    let companyObs = this.company.id != undefined ?
      this.userService.updateCompanyInfo(this.company).map(r => this.company.id) :
      this.userService.createCompanyInfo(this.company, this.user);
    this.userService.updateUserData(this.user)
      .pipe(
        tap(v => this.userService.getUser(this.user.id).subscribe(u => {
          if (this.userService.isCurrentUser(u))
            this.userService.updateCurrentUser(u);
        }))
      )
      .merge(
        companyObs
          .pipe(
            switchMap(c => {
              return this.userService.findCompanyInfo(c);
            }),
            tap(company => this.company = company),
            switchMap(c => {
              this.user.companyName = c.name;
              this.user.companyId = c.id;
              return this.userService.updateUserCompanyInfo(this.user)
            })
          )
      )
      .subscribe(() => this.edit = false);
    this.eventEmitter.emit("INFO STORED");
  }

  openJoin() {
    this.loginService.openJoinDialog("");
  }

  openSignIn() {
    this.loginService.openSignInPopup("");
  }
}
