<div class="domainComponent">
  <!--todo: layout is a little messed up :-( -->
  <label>Select your domain expertise from the dropdown, or just start typing below.</label>
  <form>
    <div>
      <mat-form-field>
        <input type="text" placeholder="Select all that apply" matInput [formControl]="myControl" [matAutocomplete]="auto"
               (keyup.enter)="add()" class="domainDropdown" #domaininputter/>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="add()">
          <mat-option *ngFor="let option of filteredOptions|async" [value]="option">
            {{ option }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <input type="hidden"/>
    <div *ngIf="domains && domains.length>0">
      <mat-form-field style="margin-left: 10px;">
        <mat-chip-list>
          <mat-basic-chip *ngFor="let domain of domains">
            {{domain}} <img class="btnRemove" src="../../assets/minus.png" (click)="remove(domain)"/>
          </mat-basic-chip>
        </mat-chip-list>
      </mat-form-field>
    </div>
  </form>
</div>
