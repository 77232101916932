<div *ngIf="!userService.isLoggedIn()">
  In order to select a colleague you need to log in first.
</div>

<div *ngIf="userService.isLoggedIn()">
  <div class="colleagueSelectMain" style="display: flex; flex-wrap: wrap;">
    <div *ngIf="filteredSelection !== undefined" style="display: flex; flex-wrap: wrap;">
      <div *ngFor="let colleague of filteredSelection" class="colleagueButton">
        <div (click)="removeColleague(colleague)" class="colleagueDiv">
          <p>{{colleague.firstName}} {{colleague.lastName}}</p><div class="remove">×</div>
        </div>
      </div>
    </div>
    <div class="colleagueButton openColleaguePick">
        <div (click)="toggleColleagueChooser()" class="colleagueChooseDiv">
          <div>
            <p *ngIf="filteredSelection === undefined || filteredSelection.length === 0">choose colleague(s)</p>
            <p *ngIf="filteredSelection !== undefined && filteredSelection.length > 0">add colleague(s)</p>
          </div>
        </div>
        <div *ngIf="colleagueDialogOpen" class="colleagueListDialog">
            <div style="width: 100%; text-align: right"><span (click)="closeColleagueChooser()" style="cursor:pointer;">×</span></div>
          <div *ngIf="colleagues === undefined">
              Loading...
            </div>
          <div *ngIf="colleagues !== undefined && colleagues.length === 0">
            No other users found for your company.
            </div>
          <div *ngIf="colleagues !== undefined && colleagues.length > 0">
            <colleague-select-list [selectedColleagues]="filteredSelection" [colleagues]="colleagues"
                                   (eventEmitter)="onColleagueUpdate($event)"></colleague-select-list>
            </div>
            <div (click)="closeColleagueChooser()" class="colleagueListDialogClose">Close</div>
        </div>
      </div>
  </div>
</div>
