<div *ngIf="project" class="projectInfoSection">
  <h1><textarea class="txtTitle" aria-label="Project title"
                [(ngModel)]="project.title" (input)="titleChange($event)"
                placeholder="Your project's title"></textarea></h1>
  <h5><span *ngIf="targetlangs"> From </span><strong>{{sourcelang|languagePipe|async}}</strong><span *ngIf="targetlangs"> to <strong>{{targetlangs}}</strong></span></h5>
  <h5 *ngIf="!editDueDate">Due date {{project.duedate|date:'y/MM/dd, HH:mm'}}<span (click)="toggleEditDueDate()" style="cursor: pointer;">✎</span></h5>
  <div *ngIf="editDueDate">
    <datetime2
    class="infoInput" #duedatepicker
    [(selectedDate)]="project.duedate" [(minDate)]="minDueDate"
    (selectedDateChange)="setProjectDueDate()"></datetime2>
    <label>Changing the deadline will only effect for new selected translators.</label>
  </div>
  <h5
    *ngIf="project.requestedby !== undefined && userService.isLoggedIn() && !userService.isCurrentUser(project.requestedby)">
    Requested by {{getRequesterName()}}</h5>
  <div>
    <div class="optionDiv shareProject">
      <label>Share project with</label>
      <colleague-select [(selectedColleagues)]="project.shares"
                        (onSelectedColleaguesChange)="saveShares($event)"></colleague-select>
    </div>
  </div>
</div>
