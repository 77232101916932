import {Directive, EventEmitter, HostListener, Output} from "@angular/core";


@Directive({
    selector: "[drag-n-drop]",
  }
)

export class DragndropDirective {

  @Output()
  private fileChangeEmitter: EventEmitter<FileList> = new EventEmitter();


  @HostListener('drop', ['$event'])
  public onDrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    let files = evt.dataTransfer.files;
    if (files.length > 0) {
      this.fileChangeEmitter.emit(files);
    }
  }

  @HostListener('dragover', ['$event'])
  public onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    //TODO make clear on the component that dragging is occurring and thus that you are able to drop files on the component.
  }
}
