/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./editor.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./editor.component.ngfactory";
import * as i4 from "./editor.component";
import * as i5 from "./service/editor.service";
import * as i6 from "@angular/material/dialog";
import * as i7 from "../service/file.service";
import * as i8 from "./task-editor.component";
import * as i9 from "@angular/router";
import * as i10 from "../service/task.service";
import * as i11 from "../service/project.service";
import * as i12 from "../service/user.service";
import * as i13 from "../service/login.service";
var styles_TaskEditorComponent = [i0.styles];
var RenderType_TaskEditorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TaskEditorComponent, data: {} });
export { RenderType_TaskEditorComponent as RenderType_TaskEditorComponent };
function View_TaskEditorComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "p", [["class", "dataLine"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "dataKey"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Due date:"])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "span", [["class", "dataValue"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵppd(5, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v.parent.parent, 0), _co.taskFile.task.duedate, "EEE, MMM dd, y, HH:mm")); _ck(_v, 4, 0, currVal_0); }); }
function View_TaskEditorComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "p", [["class", "dataLine"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "dataKey"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Expected completion date:"])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "span", [["class", "dataValue"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵppd(5, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v.parent.parent, 0), _co.taskFile.task.expecteddate, "EEE, MMM dd, y, HH:mm")); _ck(_v, 4, 0, currVal_0); }); }
function View_TaskEditorComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "p", [["class", "dataLine"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "dataKey"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Actual completion date:"])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "span", [["class", "dataValue"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵppd(5, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v.parent.parent, 0), _co.taskFile.task.completed, "EEE, MMM dd, y, HH:mm")); _ck(_v, 4, 0, currVal_0); }); }
function View_TaskEditorComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["style", "color: red;"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.warning; _ck(_v, 1, 0, currVal_0); }); }
function View_TaskEditorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["style", "flex: 1 1 auto;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", " - ", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 6, "div", [["style", "width: 50%;"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TaskEditorComponent_2)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TaskEditorComponent_3)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TaskEditorComponent_4)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TaskEditorComponent_5)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.taskFile.task.duedate; _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.taskFile.task.expecteddate; _ck(_v, 7, 0, currVal_3); var currVal_4 = _co.taskFile.task.completed; _ck(_v, 9, 0, currVal_4); var currVal_5 = _co.warning; _ck(_v, 11, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.taskFile.task.activity; var currVal_1 = _co.taskFile.filename; _ck(_v, 2, 0, currVal_0, currVal_1); }); }
function View_TaskEditorComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "editor", [], null, null, null, i3.View_EditorComponent_0, i3.RenderType_EditorComponent)), i1.ɵdid(1, 4308992, [[1, 4], ["editor", 4]], 0, i4.EditorComponent, [i5.EditorService, i6.MatDialog, i7.FileService], { sourceLang: [0, "sourceLang"], targetLang: [1, "targetLang"], fileLocation: [2, "fileLocation"], fileGroupId: [3, "fileGroupId"], projectId: [4, "projectId"], fileName: [5, "fileName"], editable: [6, "editable"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.taskFile.task.sourcelanguage; var currVal_1 = _co.taskFile.task.targetlanguage; var currVal_2 = _co.taskFile.filelocation; var currVal_3 = _co.taskId; var currVal_4 = _co.taskFile.task.projectId; var currVal_5 = _co.taskFile.filename; var currVal_6 = _co.editable(); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
function View_TaskEditorComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["style", "color: red;"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.warning; _ck(_v, 1, 0, currVal_0); }); }
export function View_TaskEditorComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), i1.ɵqud(671088640, 1, { editor: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 21, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 8, "div", [["style", "display: flex; justify-content: space-between;"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TaskEditorComponent_1)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 5, "div", [["class", "editorButtons"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "button", [["class", "editorButton"], ["title", "Back to task page"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.backToTask() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "img", [["src", "../../assets/back_arrow_white.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "button", [["class", "editorButton"], ["title", "Complete & send file"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.saveTaskFile() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "img", [["src", "../../assets/checkmark_white.png"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Finalize "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TaskEditorComponent_6)), i1.ɵdid(13, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(14, 0, null, null, 9, "div", [["style", "display: flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 2, "div", [["style", "width: 90%;"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TaskEditorComponent_7)), i1.ɵdid(17, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(18, 0, null, null, 5, "div", [["class", "editorButtons"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 1, "button", [["class", "editorButton"], ["title", "Back to task page"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.backToTask() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 0, "img", [["src", "../../assets/back_arrow_white.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 2, "button", [["class", "editorButton"], ["title", "Complete & send file"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.saveTaskFile() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 0, "img", [["src", "../../assets/checkmark_white.png"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Finalize "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.taskFile; _ck(_v, 5, 0, currVal_0); var currVal_2 = (_co.taskFile && _co.taskId); _ck(_v, 13, 0, currVal_2); var currVal_3 = _co.warning; _ck(_v, 17, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = !_co.enableFinalize(); _ck(_v, 9, 0, currVal_1); var currVal_4 = !_co.enableFinalize(); _ck(_v, 21, 0, currVal_4); }); }
export function View_TaskEditorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "task-editor", [], null, null, null, View_TaskEditorComponent_0, RenderType_TaskEditorComponent)), i1.ɵdid(1, 114688, null, 0, i8.TaskEditorComponent, [i9.Router, i9.ActivatedRoute, i10.TaskService, i11.ProjectService, i12.UserService, i13.LogInService, i5.EditorService, i6.MatDialog], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TaskEditorComponentNgFactory = i1.ɵccf("task-editor", i8.TaskEditorComponent, View_TaskEditorComponent_Host_0, {}, {}, []);
export { TaskEditorComponentNgFactory as TaskEditorComponentNgFactory };
