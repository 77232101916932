import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from "@angular/core";
import {DateAdapter, MAT_DATE_FORMATS, NativeDateAdapter} from "@angular/material/core";


export const NATIVE_DATE_FORMATS = {
  parse: {
    dateInput: {month: 'short', year: 'numeric', day: 'numeric'}
  },
  display: {
    // dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
    dateInput: 'input',
    monthYearLabel: {year: 'numeric', month: 'short'},
    dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
    monthYearA11yLabel: {year: 'numeric', month: 'long'},
  }
};

export class AppDateAdapter extends NativeDateAdapter {

  format(date: Date, displayFormat: Object): string {
    if (displayFormat === 'input') {
      return formatDate(date);
    } else
      return super.format(date, displayFormat);
  }

  parse(value: any): Date {
    try {
      let d = new Date(value);
      d.setHours(0, 0, 0);
      return d;
    } catch (e) {
      throw e;
    }
  }
}

@Component({
  selector: "datetime2",
  template: `
	  <div class="dateAndTime">
		  <div class="oneLine">
			  <input matInput [matDatepicker]="picker"
					 [(ngModel)]="date" [min]="minDate" [max]="maxDate" [title]="tooltip"
					 (dateChange)="dateChange()"
					 placeholder="yyyy-mm-dd">
			  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
		  </div>
		  <mat-datepicker #picker></mat-datepicker>
		  <div style="float: right; display: flex;">
			  <input type="number" class="timeinput" [(ngModel)]="hours" (change)="dateChange()" [max]="23" [min]="0"/>:
			  <input type="number" class="timeinput" [(ngModel)]="minutes" (change)="dateChange()" [max]="59"
					 [min]="0"/>
		  </div>
	  </div>
  `,
  styles: [
    '.dateAndTime { display: flex; justify-content: flex-start; align-items: center; width: 100%; }',
    '.oneLine { display: flex; justify-content: flex-start; align-items: center; overflow: auto; width: 100%; }',
    // To resize the date input element
    '.mat-input-element { min-width:80px !important; }',
    '.timeinput {max-width: 40px; text-align: right}'
  ],
  providers: [
    {provide: DateAdapter, useClass: AppDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: NATIVE_DATE_FORMATS},
  ],
})
export class DateTime2Component implements OnInit, OnChanges {

  @Input()
  minDate: Date;
  @Input()
  minTime: string;
  @Input()
  maxDate: Date;
  @Input()
  selectedDate: Date;
  @Output()
  selectedDateChange = new EventEmitter<Date>();

  date: Date;
  minutes: number = 59;
  hours: number = 23;

  tooltip = "Pick a date. (yyyy-mm-dd)";

  constructor() {

  }

  ngOnInit() {
    if (this.selectedDate != undefined) {
      if (typeof this.selectedDate == "string")
        this.selectedDate = new Date(this.selectedDate);
      this.date = this.selectedDate;
      this.hours = this.selectedDate.getHours();
      this.minutes = this.selectedDate.getMinutes();
    } else {
      this.date = new Date();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty("selectedDate")) {
      this.ngOnInit();
    }
  }

  dateChange() {
    if (this.hours > 23) this.hours = 23;
    if (this.hours < 0) this.hours = 0;
    if (this.minutes > 59) this.minutes = 59;
    if (this.minutes < 0) this.minutes = 0;
    let toUpdateDate = new Date(this.date);
    toUpdateDate.setHours(this.hours);
    toUpdateDate.setMinutes(this.minutes);
    this.date = toUpdateDate;
    this.selectedDate = this.date;
    this.selectedDateChange.emit(this.selectedDate);
  }
}

export function formatDate(d: Date) {
  const day = d.getDate().toLocaleString(undefined, {minimumIntegerDigits: 2});
  const month = (d.getMonth() + 1).toLocaleString(undefined, {minimumIntegerDigits: 2});
  const year = d.getFullYear();
  return `${year}-${month}-${day}`;
}
