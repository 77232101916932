/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./user.settings.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./invited.component";
import * as i4 from "@angular/router";
import * as i5 from "../service/user.service";
import * as i6 from "../service/login.service";
var styles_InvitedComponent = [i0.styles];
var RenderType_InvitedComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InvitedComponent, data: {} });
export { RenderType_InvitedComponent as RenderType_InvitedComponent };
function View_InvitedComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Please check your mailbox for an email to activate your account."]))], null, null); }
export function View_InvitedComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Welcome to lilo"])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Your partner for taking charge of your translation projects."])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InvitedComponent_1)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.joinWithMailSuccess; _ck(_v, 6, 0, currVal_0); }, null); }
export function View_InvitedComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "invite-landing-page", [], null, null, null, View_InvitedComponent_0, RenderType_InvitedComponent)), i1.ɵdid(1, 114688, null, 0, i3.InvitedComponent, [i4.ActivatedRoute, i5.UserService, i6.LogInService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InvitedComponentNgFactory = i1.ɵccf("invite-landing-page", i3.InvitedComponent, View_InvitedComponent_Host_0, {}, {}, []);
export { InvitedComponentNgFactory as InvitedComponentNgFactory };
