import {Component, OnInit} from "@angular/core";
import {from as observableFrom, Subject} from 'rxjs';
import {Project} from "../dto/project";
import {ProjectService} from "../service/project.service";
import {ActivatedRoute, Router} from "@angular/router";
import {animate, state, style, transition, trigger} from '@angular/animations';
import {getProjectStatus} from "../util/jobutil";
import {debounceTime} from 'rxjs/operators';
import {UserService} from "../service/user.service";


@Component({
  selector: 'project-dashboard',
  templateUrl: './project-dashb.component.html',
  styleUrls: ['./project-dashb.component.css'],
  animations: [trigger('appearIn', [
    state('in', style({opacity: 1})),
    transition('void => *', [
      style({
        opacity: 0
      }),
      animate('0.5s ease-in')
    ])
  ])]
})
/* todo: make a general dashboard component, that is composed of elements based on the user:
    - for a customer it should contain this one, + a statistics (reporting) section and news section
    - for a translator it should contain a task dashboard component and a news section */
export class ProjectDashboardComponent implements OnInit {

  allState = "All";
  selectedStates = ["Draft", "Active"];
  projectStates = ["Draft", "Active", "Completed", "Canceled"];

  quickAccess: Project[] = [];
  projects: Project[] = undefined;
  filteredProjects: Project[] = undefined;
  filterText;
  filterInput: Subject<string> = new Subject<string>();

  displayedProjects: Project[] = undefined;
  initTableSize: number = 20;
  tableSize: number = this.initTableSize;

  isFromCreated = false;
  createdId: string = undefined;


  constructor(private projectService: ProjectService,
              public userService: UserService,
              private router: Router,
              private activatedRoute: ActivatedRoute) {
    this.filterInput.asObservable().pipe(
      debounceTime(500),) // wait half a second after the last event before emitting it
      .subscribe(element => {
        this.setFilteredProjects();
      });
  }

  ngOnInit(): void {
    this.loadQuickAccess();
    this.loadProjects();
    this.activatedRoute.queryParams
      .filter(params => params.hasOwnProperty("createdId"))
      .subscribe(params => {
        this.isFromCreated = true;
        this.createdId = params.createdId;
      })
  }

  private loadQuickAccess() {
    this.projectService.getQuickAccessProjects(-1, 4).subscribe(d => {
      if (d != null)
        this.quickAccess = d.slice(0, 4);
      else this.quickAccess = []
    });
  }

  /**
   * https://blog.angular-university.io/angular-material-data-table/
   */
  private loadProjects() {
    let start = Date.now();
    let obs;
    if (this.selectedStates.length === 0) {
      // If no status selected, don't show any projects
      obs = observableFrom([]);
    } else if (this.selectedStates.indexOf(this.allState) > -1)
      obs = this.projectService.getMyProjects();
    else
      obs = this.projectService.getMyProjectsWithStatus(this.selectedStates);
    obs.subscribe(projects => {
        this.projects = this.sort(projects);
        this.setFilteredProjects();
        console.debug("Loading " + this.filteredProjects.length + " projects took " + (Date.now() - start) + " ms.");
      },
      error => {
        this.projects = [];
        this.filteredProjects = [];
        console.error("Error loading projects", error);
      });
  }

  gotoProject(id: string) {
    this.router.navigate(['/pdetail', id]);
  }

  selectState(state: string) {
    if (this.selectedStates.indexOf(state) > -1) {
      // State was already selected, so unselect it
      this.selectedStates = this.selectedStates.filter(i => i !== state);
      // When a state is removed, remove all projects associated with this state
      // In case it was the "all" state, remove all projects
      if (state === this.allState) {
        this.projects = [];
      } else {
        this.projects = this.projects.filter(p => getProjectStatus(p) !== state);
      }
      this.setFilteredProjects()
    } else {
      if (state === this.allState) {
        // If "all" was clicked, then remove all other selections
        this.selectedStates = [];
      } else if (this.selectedStates.indexOf(this.allState) > -1) {
        // If "all" is in the current selections and another is chosen, then remove "all"
        this.selectedStates = this.selectedStates.filter(i => i !== this.allState)
      }
      this.selectedStates.push(state);
      // Reload projects
      this.loadProjects();
    }
  }

  sort(projects: Project[]) {
    return projects.sort((p1, p2) => {
      if (p1.creationdate && p2.creationdate) {
        if (p1.creationdate > p2.creationdate)
          return -1;
        if (p1.creationdate < p2.creationdate)
          return 1;
        else return 0;
      } else if (p1.creationdate)
        return -1;
      else if (p2.creationdate)
        return 1;
      else return 0;
    });
  }

  setFilteredProjects() {
    // Apply any filter
    if (this.filterText && this.filterText.length > 0)
      this.filteredProjects = this.projects
        .map(v => filterProject(v, this.filterText))
        .filter(v => v != undefined);
    else this.filteredProjects = this.projects;
    // Then limit the list based on the desired table size
    this.setDisplayedProjects()
  }

  filterProjects() {
    // Send user input through "subject" so it gets "debounced"
    // Otherwise, the input is sent with EACH KEYSTROKE
    this.filterInput.next();
  }

  isListExpanded() {
    return this.tableSize > this.initTableSize;
  }

  setDisplayedProjects() {
    if (this.isListExpanded())
      this.displayedProjects = this.filteredProjects;
    else this.displayedProjects = this.filteredProjects.slice(0, this.initTableSize)
  }

  toggleListSize() {
    // TODO: implement actual loading from server when list is expanded/collapsed?
    if (this.isListExpanded())
      this.tableSize = this.initTableSize;
    else this.tableSize = this.filteredProjects.length;
    this.setDisplayedProjects();
  }

  newProject() {
    let p = new Project();
    this.projectService.initProject(p, this.userService.getRemoteUserIp()).subscribe(result => {
        let projectId = result.id;
        this.router.navigate(['/pdetail', projectId]);
      },
      err => {
        console.error("Error initializing project", err);
      }
    );
  }
}

function filterProject(project: Project, text: string): Project {
  if (text) {
    let searchString = project.title;
    if (project.files) {
      project.files.forEach(f => searchString = searchString + " " + f.name)
    }
    if (searchString.trim().toLocaleLowerCase().indexOf(text.trim().toLocaleLowerCase()) >= 0)
      return project;
    else
      return undefined;
  }
  return project;
}
