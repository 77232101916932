import {filter} from 'rxjs/operators';
import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {Project} from "../dto/project";
import {ProjectService} from "../service/project.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {UserService} from "../service/user.service";
import {ConfirmDialog} from "../confirm.dialog";
import {SimpleDialog} from "../simple-dialog.component";
import {PricingService} from "../service/pricing.services";
import {TaskService} from '../service/task.service';
import {LogInService} from '../service/login.service';
import {getGeneralDocFiles, getSourceFiles} from "../util/jobutil";
import {SubscriptionHandler} from '../util/subscription.handler';


@Component({
  selector: 'project-followup',
  templateUrl: './project-followup.component.html',
  styleUrls: ['./project.component.css'],
})
export class ProjectFollowupComponent implements OnInit, OnDestroy {

  @Input()
  project: Project;

  hasChanges = false;
  validTaskCount = false;
  canCancel = false;
  isCompleted = false;
  isCanceled = false;

  sourceLanguage;
  sourceFiles = [];
  docFiles = [];

  taskCountSubscription = undefined;

  subscriptionHandler = new SubscriptionHandler(this);

  constructor(private projectService: ProjectService,
              private taskService: TaskService,
              public userService: UserService,
              private loginService: LogInService,
              private pricingService: PricingService,
              private router: Router,
              private route: ActivatedRoute,
              private dialog: MatDialog) {

  }

  ngOnInit(): void {
    this.hasChanges = this.project.startdate === null || this.project.startdate === undefined;
    this.isCompleted = this.project.chargeid !== undefined;
    this.isCanceled = this.project.canceled !== undefined && this.project.canceled !== null;
    this.sourceLanguage = this.project.langpairs !== undefined && this.project.langpairs.length > 0 ?
      this.project.langpairs[0].source : undefined;
    this.sourceFiles = getSourceFiles(this.project);
    this.docFiles = getGeneralDocFiles(this.project)
    const projectSubscription = this.projectService.enrollForProject(this.project.id)
      .pipe(
        filter(v => v.action == 'PROJECT_CHECKOUT_REMOVE' || v.action == 'PROJECT_START' || v.action == 'PROJECT_QUOTE_ACCEPT'),
      )
      .subscribe(v => {
          this.hasChanges = (v.action == 'PROJECT_CHECKOUT_REMOVE' || v.action == 'PROJECT_QUOTE_ACCEPT');
          if (v.action == 'PROJECT_QUOTE_ACCEPT') {
            this.project.isQuote = false;
          }
        }
      );
    this.subscriptionHandler.push(projectSubscription);
    if (this.taskCountSubscription === undefined) {
      this.taskCountSubscription = this.taskService.enrollForProject(this.project.id).subscribe(a => this.checkTaskCount());
      this.subscriptionHandler.push(this.taskCountSubscription);
    }
    this.taskService.getTaskInfoForProject(this.project.id)
      .pipe(
        filter(t => t.acceptdate != undefined),
      )
      .isEmpty()
      .subscribe(b => {
        this.canCancel = b;
      });
    this.route.queryParams
      .forEach((params: Params) => {
        if (params.hasOwnProperty('gototask')) {
          this.openLanguageBar = params.gototask;
        }
      });
    this.checkTaskCount();
  }

  ngOnDestroy() {

  }

  collapseOrigFiles = true;

  toggleCollapseOrigFiles() {
    this.collapseOrigFiles = !this.collapseOrigFiles
  }


  onDocFileUploaded(docInfo: any): void {
    if (docInfo != null && docInfo.docfile != null) {
      this.projectService.addDocFileData(docInfo.docfile.name, docInfo.source4pdf, this.project.id).subscribe(
        data => {
          this.docFiles.push(data);
        },
        error => {
          console.error(error);
        }
      )
    }
  }

  openLanguageBar = undefined;

  setOpenLanguageBar(lp: any) {
    this.openLanguageBar = lp != undefined ? lp.target : undefined;
  }

  checkTaskCount() {
    this.taskService.getTaskCountPerLangPair(this.project.id)
      .map(m =>
        Object.values(m).filter(e => e > 0)
      )
      .map(lps => {
        return lps.length;
      })
      .subscribe(lpCount => {
        this.validTaskCount = lpCount === this.project.langpairs.length;
      });
  }

  updateOrderSubmit = false;

  updateOrder() {
    this.updateOrderSubmit = true;
    let userId = this.project.requestedby.id;
    // Calculate the pricing again and when that is done, start the project
    // Note that if we manually adjusted the pricingElement of the project, it will not be overwritten
    this.pricingService.getTotalProjectPrice(this.project.id)
      .switchMap(pricing => {
        console.debug("Project " + this.project.id + ": UPDATING", pricing);
        return this.projectService.startProject(this.project, userId, pricing, false, this.userService.getRemoteUserIp())
      }).subscribe(v =>
        // redirect to project dashboard
        this.router.navigateByUrl("pdashboard"),
      error => {
        console.error("Error updating project", error);
        this.updateOrderSubmit = false
      }
    );
  }

  cancelProject() {
    let dialogRef = this.dialog.open(ConfirmDialog, {
      width: '450px',
      panelClass: 'confirmDialog',
      data: {
        confirmTitle: 'Are you sure?',
        // confirmMessage: 'If you cancel your project, you will have to pay a fee to the translators who already confirmed to do the job.',
        confirmText: 'Yes, cancel project',
        cancelText: 'No, take me back',
        invertStyle: true
      }
    });
    dialogRef.afterClosed().pipe(filter(t => t === "confirm"))
      .subscribe(p => {
          this.projectService.cancelProject(this.project).subscribe(v => {
            let successDialog = this.dialog.open(SimpleDialog, {
              width: '400px',
              panelClass: 'simpleDialog',
              data: {dialogMessage: 'Your project has been canceled.'}
            });
            successDialog.afterOpen().subscribe(_ =>
              this.router.navigateByUrl("")
            )
          });
        }
      )
  }

  openLogin() {
    this.loginService.openSignInPopup();
  }

}
