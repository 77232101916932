import * as tslib_1 from "tslib";
import { filter } from 'rxjs/operators';
import { OnChanges, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UserService } from "../../service/user.service";
import { FileService } from "../../service/file.service";
import * as FileSaver from "file-saver";
import { ConfirmDialog } from "../../confirm.dialog";
import { SelectionModel } from "@angular/cdk/collections";
export class FolderViewComponent {
    constructor(userService, fileService, dialog, snackbar) {
        this.userService = userService;
        this.fileService = fileService;
        this.dialog = dialog;
        this.snackbar = snackbar;
        this.dirCount = 0;
        this.fileCount = 0;
        this.displayedColumns = ['select', 'icon', 'name', 'size', 'lastmodified'];
        this.dataSource = new MatTableDataSource();
        this.selection = new SelectionModel(true, []);
        this.fileUpload = "";
    }
    ngOnInit() {
        this.subDir = null;
        this.updateFileContent(false);
    }
    ngAfterViewInit() {
        this.dataSource.sort = this.sort;
    }
    ngOnChanges() {
        this.subDir = null;
        this.updateFileContent(false);
    }
    set matSort(ms) {
        this.sort = ms;
        this.dataSource.sort = this.sort;
    }
    rowClicked(e) {
        //  console.debug(e);
        if (e.dir === "true" && e.hasOwnProperty("path")) {
            this.subDir = e.path + (e.path.length > 0 && !e.path.endsWith("/") ? "/" : "") + e.name;
            this.updateFileContent(false);
        }
        else {
            this.fileService.download(this.type, this.id, this.subDir, e.name).subscribe(res => {
                FileSaver.saveAs(res, e.name, true);
            });
        }
    }
    refreshFileContent() {
        this.updateFileContent(true);
    }
    updateFileContent(showSnackbar) {
        this.fileService.fileContent(this.type, this.id, this.subDir).subscribe(data => {
            this.content = data;
            console.debug("File content for " + this.type + " " + this.id + " " + (this.subDir ? " and subdir '" + this.subDir + "'" : ""), this.content);
            this.dataSource.data = this.content;
            // Clear selection each time content is updated
            this.selection.clear();
            // Calculate how many directories/files
            this.dirCount = 0;
            this.fileCount = 0;
            this.dataSource.data.forEach(row => {
                if (row.dir === "true")
                    this.dirCount++;
                else
                    this.fileCount++;
            });
            if (showSnackbar) {
                this.snackbar.open("Reloaded view for " + this.type + " " + this.id + (this.subDir ? ": /" + this.subDir : ""), "", {
                    duration: 2000
                });
            }
        });
    }
    goUp() {
        let idx = this.subDir.lastIndexOf("/");
        if (idx > 0) {
            this.subDir = this.subDir.substring(0, idx);
        }
        else {
            this.subDir = null;
        }
        this.updateFileContent(false);
    }
    addFile(event) {
        let fileList = event.target.files;
        if (fileList.length > 0) {
            let file = fileList[0];
            this.fileService.fileExists(this.type, this.id, this.subDir, file.name).subscribe(result => {
                if (result === "true") {
                    this.openFileExistsDialog(file);
                }
                else
                    this.upload(file, false);
            });
        }
    }
    openFileExistsDialog(file) {
        let confDialog = this.dialog.open(ConfirmDialog, {
            width: '400px',
            panelClass: 'confirmDialog',
            data: {
                confirmMessage: 'File ' + file.name + ' already exists in this folder. Do you wish to replace it?',
                confirmText: 'Yes, replace',
                cancelText: 'No, cancel'
            }
        });
        confDialog.afterClosed().pipe(filter(t => t === "confirm"))
            .subscribe(p => {
            this.upload(file, true);
        });
    }
    upload(file, confirmedReplace) {
        (() => tslib_1.__awaiter(this, void 0, void 0, function* () {
            // Upload the file through the file service
            let start = new Date().getTime();
            this.fileService.addFile(file, this.type, this.id, this.subDir, confirmedReplace).subscribe(data => {
                let end = new Date();
                this.snackbar.open("File added for " + this.type + " " + this.id + (this.subDir ? " to " + this.subDir : "") +
                    ": " + file.name + " uploaded in " + (end.getTime() - start) + " ms", "", {
                    duration: 2000
                });
                console.log("File added for " + this.type + " " + this.id + (this.subDir ? " to " + this.subDir : "") +
                    ": " + file.name + " uploaded in " + (end.getTime() - start) + " ms");
                this.updateFileContent(false);
            }, error => {
                // Open snackbar if error
                console.log("Error adding file", error);
                this.snackbar.open("Error adding file for " + this.type + " " + this.id + (this.subDir ? " to " + this.subDir : "") +
                    ": " + error.status + " " + error.statusText, "", {
                    duration: 5000
                });
            });
        }))();
    }
    removeSelected() {
        if (this.selection.selected.length == 0) {
            this.snackbar.open("Nothing selected to remove! Please select one or more.", "", {
                duration: 4000
            });
        }
        else {
            let dirNumber = 0;
            let fileNumber = 0;
            let nonEmptyDir = false;
            this.selection.selected.forEach(row => {
                if (row.dir === "true") {
                    if (!nonEmptyDir)
                        nonEmptyDir = (row.count > 0);
                    dirNumber++;
                }
                else
                    fileNumber++;
            });
            // Do not allow removal of non-empty dirs
            if (nonEmptyDir) {
                this.snackbar.open("One or more non-empty folders were selected! Only empty folders can be removed.", "", {
                    duration: 4000
                });
            }
            else {
                // Compose confirm message based on selected files/folders
                let msg = "";
                if (dirNumber > 0 && fileNumber > 0) {
                    msg = "the " + dirNumber + " selected folder(s) and " + fileNumber + " selected file(s)";
                }
                else if (dirNumber > 0) {
                    msg = (dirNumber > 1 ? "the " + dirNumber + " selected folders" : "folder \'" + this.selection.selected[0].name + "\'");
                }
                else if (fileNumber > 0) {
                    msg = (fileNumber > 1 ? "the " + fileNumber + " selected files" : "file \'" + this.selection.selected[0].name + "\'");
                }
                let confDialog = this.dialog.open(ConfirmDialog, {
                    width: '400px',
                    panelClass: 'confirmDialog',
                    data: {
                        confirmMessage: 'Are you sure you wish to remove ' + msg + '?',
                        confirmText: 'Yes',
                        cancelText: 'No'
                    }
                });
                confDialog.afterClosed().pipe(filter(t => t === "confirm"))
                    .subscribe(p => {
                    let start = new Date().getTime();
                    this.fileService.removeFile(this.selection.selected, this.type, this.id, this.subDir).subscribe(data => {
                        let end = new Date();
                        this.snackbar.open("Successfully removed " + this.selection.selected.length + " file(s) and/or folder(s) for " + this.type + " " + this.id +
                            (this.subDir ? " at " + this.subDir : "") +
                            " (" + (end.getTime() - start) + " ms)", "", {
                            duration: 2000
                        });
                        this.updateFileContent(false);
                    }, error => {
                        // Open snackbar if error
                        console.log("Error removing file(s)/folder(s)", error);
                        this.snackbar.open("Error removing " + this.selection.selected.length + " file(s) and/or folder(s) for " + this.type + " " + this.id +
                            (this.subDir ? " in " + this.subDir : "") +
                            ": " + error.status + " " + error.statusText, "", {
                            duration: 5000
                        });
                    });
                });
            }
        }
    }
    renameSelected() {
        if (this.selection.selected.length == 0) {
            this.snackbar.open("No file selected to rename! Please select one.", "", {
                duration: 4000
            });
        }
        else if (this.selection.selected.length == 1) {
            // Only allow rename for one file at a time
            let fname = this.selection.selected[0].name;
            let isDir = this.selection.selected[0].dir;
            let dialogRef = this.dialog.open(RenameFileDialog, {
                width: '500px',
                data: { currentFileName: fname, isDir: isDir }
            });
            dialogRef.afterClosed().pipe(filter(t => {
                // Only proceed if a name was given and if it was not equal to the current name
                return t != undefined && t.trim().length > 0 && t.trim() !== fname.trim();
            }))
                .subscribe(nn => {
                let newName = nn.trim();
                this.fileService.renameFile(newName, this.type, this.id, this.subDir, fname).subscribe(data => {
                    let end = new Date();
                    this.snackbar.open((isDir ? "Folder " : "File ") + fname + " renamed to " + newName +
                        " for " + this.type + " " + this.id + (this.subDir ? " in " + this.subDir : ""), "", {
                        duration: 2000
                    });
                    this.updateFileContent(false);
                }, error => {
                    // Open snackbar if error
                    console.log("Error renaming " + (isDir ? "folder" : "file"), error);
                    this.snackbar.open("Error renaming " + (isDir ? "folder " : "file ") + fname + " for " + this.type + " " + this.id + (this.subDir ? " in " + this.subDir : "") +
                        ": " + error.status + " " + error.statusText, "", {
                        duration: 5000
                    });
                });
            });
        }
        else {
            this.snackbar.open("More than one file and/or folder selected to rename! Please select only one.", "", {
                duration: 4000
            });
        }
    }
    createNewFolder() {
        let dialogRef = this.dialog.open(AddFolderDialog, {
            width: '500px',
            data: {}
        });
        dialogRef.afterClosed().pipe(filter(t => {
            // Only proceed if a name was given and if it was not equal to the current name
            return t != undefined && t.trim().length > 0;
        }))
            .subscribe(nn => {
            let folderName = nn.trim();
            this.fileService.createDir(folderName, this.type, this.id, this.subDir).subscribe(data => {
                let end = new Date();
                this.snackbar.open("Folder " + folderName + " created for " + this.type + " " + this.id + (this.subDir ? " in " + this.subDir : ""), "", {
                    duration: 2000
                });
                this.updateFileContent(false);
            }, error => {
                // Open snackbar if error
                console.log("Error creating folder", error);
                this.snackbar.open("Error creating folder for " + this.type + " " + this.id + (this.subDir ? " in " + this.subDir : "") +
                    ": " + error.status + " " + error.statusText, "", {
                    duration: 5000
                });
            });
        });
    }
    enableFileOps() {
        // Don't allow file operations when in the #bin directory
        return this.subDir == null || !(this.subDir.indexOf("#bin") == 0);
    }
    /** Check whether the number of selected elements matches the total number of selectable rows. */
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        let numRows = 0;
        this.dataSource.data.forEach(row => {
            numRows++;
        });
        return numSelected == numRows;
    }
    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        this.isAllSelected() ?
            this.selection.clear() :
            this.dataSource.data.forEach(row => {
                this.selection.select(row);
            });
    }
}
export class RenameFileDialog {
    constructor(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
    }
    ngOnInit() {
        this.currentFileName = this.data.currentFileName;
        this.newFileName = this.data.currentFileName;
        this.isDir = this.data.isDir;
    }
    continue() {
        this.dialogRef.close(this.newFileName);
    }
}
export class AddFolderDialog {
    constructor(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
    }
    ngOnInit() {
        this.newFolderName = "";
    }
    continue() {
        this.dialogRef.close(this.newFolderName);
    }
}
