<div *ngIf="userService.hasTranslatorRole()" style="position: relative;">

  <div *ngIf="task" class="contentWithSideBox">

    <div style="flex: 1 1 auto; margin-right: 375px;">
      <div style="display: flex; justify-content: flex-start; align-items: center;">
        <h1 style="flex: 0 1 auto; padding-right: 10px; margin: 40px 0 15px 0;">
          {{activityName}}
          - {{(task.sourcelanguage|languagePipe|async)}}<span *ngIf="task.targetlanguage"> to {{(task.targetlanguage|languagePipe|async)}}</span></h1>
        <button class="btnHelp" style="flex: 0 1 auto; margin-right: 40px;"
                (click)="openTaskHelp()">
          <img src="../../assets/icons8-help-32.png" class="imgIcon"/>
        </button>
      </div>
      <div *ngIf="task.title">
        <h3 style="color: darkgray;">{{task.title}}</h3>
      </div>

      <div class="side" *ngIf="project">
        <task-overview [task]="task" [project]="project" [revisionNeeded]="revisionNeeded"></task-overview>
      </div>

      <div *ngIf="sourceFiles" class="jobs">
        <task-file-list [task]="task" [taskFiles]="sourceFiles" [docFiles]="docFiles"></task-file-list>
      </div>

      <div *ngIf="generalDocFiles && generalDocFiles.length > 0" style="margin-top: 50px;">
        <hr style="width: 50%;">
        <div style="display: flex; justify-content: flex-start; align-items: center;">
          <h3 style="flex: 0 1 auto; padding-right: 10px; margin-top: 0px;">Documentation</h3>
          <button class="btnHelp" style="flex: 0 1 auto; margin-right: 40px;"
                  (click)="openDocHelp()">
            <img src="../../assets/icons8-help-32.png" class="imgIcon"/>
          </button>
        </div>
        <p style=" font-size: smaller; font-weight: bold; color: var(--warning-color); margin-top: 3px;">
          Please note these files are strictly for your reference and DO NOT NEED TO BE TRANSLATED.
        </p>
        <div>
          <documentation-list [files]="generalDocFiles" [entityId]="project.id" [entityType]="'project'" [allowRemoval]="false">
          </documentation-list>
        </div>
      </div>
    </div>

  </div>

  <div *ngIf="task != undefined" class="chat">
    <project-chat [projectId]="task.projectId"></project-chat>
  </div>
</div>

<div *ngIf="!userService.hasTranslatorRole()">
  <h3>Please log in as a translator.</h3>
</div>
