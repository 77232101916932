<div *ngIf="file != undefined" [title]="file.name">
  <div class="sourceFileImage">
    <frontpage [pId]="pId" [fileName]="file.name"></frontpage>
    <div class="sourceFileHover">
      <div class="sourceFileDownload">
        <div (click)="download()">
          <img src="../../../assets/download_icon.svg" title="Download" alt="Download file">
        </div>
      </div>
      <div *ngIf="allowRemoval == true" class="sourceFileDelete" (click)="remove()">
        <img src="../../../assets/delete_icon_resized.svg" title="Remove" alt="Remove file">
      </div>
    </div>
  </div>
  <div class="sourceFileDetails">
    <p>{{file.name|filenamePipe:18:true}}</p>
    <p>Wordcount:
      <span *ngIf="file.wordcount != undefined"
            [ngClass]="(wordCountChanged?'valueUpdated':'')" style="padding: 3px;">{{file.wordcount}}</span>
      <span *ngIf="isManual && allowRemoval" (click)="openFileMetaData()"
            [ngClass]="{'wordcountEdit': true, 'bounceIt': file.wordcount == undefined}"
            title="Specify wordcount">✎</span>
    </p>
    <p>Language:
      <span *ngIf="file.sourcelang != undefined"
            [ngClass]="(languageChanged?'valueUpdated':'')"
            style="padding: 3px;">{{file.sourcelang|languagePipe|async}}</span>
    </p>
  </div>
  <p class="fileWarning"
     *ngIf="expectedLanguage != null && file.sourcelang != null && expectedLanguage != file.sourcelang">
    This will be treated as a <strong>{{expectedLanguage|languagePipe|async}}</strong> document.
  </p>
  <p class="fileWarning" style="cursor: pointer;"
     *ngIf="isManual == true && !isManualService() && !file.name.endsWith('.pdf')" (click)="openFileMetaData()">
    Oops! We don't support this file format.
    <a *ngIf="file.wordcount === undefined"><br/>Please set wordcount manually.</a>
  </p>
  <p class="fileWarning" style="cursor: pointer;"
     title="Upload a PDF of how the file looks on screen, to provide the translator with more context."
     *ngIf="pdfFailed == true">
    <a (click)="uploadDocPdf()">Upload PDF version.</a>
  </p>
</div>
