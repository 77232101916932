<div *ngIf="review">
  <div class="reviewItem">
    <div *ngIf="poster" style="display: flex; align-items: center;">
      <mugshot [user]="poster" [width]="'80px'" [height]="'80px'" [showIncomplete]="false"></mugshot>
      <div style="margin-left: 20px;">
        <h5 style="font-weight: bold;">{{getPosterName()}}</h5>
      </div>
    </div>
    <div class="reviewData">
      <div *ngIf="review.scores && review.scores.length > 0" class="reviewStars">
        <img *ngIf="starCount >= 1" src="../../../assets/star.svg" alt="translator rating - 1*">
        <img *ngIf="starCount >= 2" src="../../../assets/star.svg" alt="translator rating - 2*">
        <img *ngIf="starCount >= 3" src="../../../assets/star.svg" alt="translator rating - 3*">
        <img *ngIf="starCount >= 4" src="../../../assets/star.svg" alt="translator rating - 4*">
        <img *ngIf="starCount >= 5" src="../../../assets/star.svg" alt="translator rating - 5*">
      </div>
      <div class="reviewComment">
        <p>{{review.comment}}</p>
      </div>
    </div>
  </div>
  <p style="font-size: 12px; float: right; margin-top: 7px;">Posted on {{review.creationdate|date:'y/MM/dd, HH:mm'}}</p>
</div>
