import {map, tap} from 'rxjs/operators';
import {Component, Input, OnInit} from "@angular/core";
import {Project} from '../dto/project';
import {Observable} from 'rxjs';
import {ProjectListComponent} from '../projectcomponent/project-list.component';

@Component({
  selector: "sales-invoice",
  templateUrl: './sales-invoice.component.html',
  styleUrls: ['./invoicing.component.css'],
})
export class SalesInvoiceComponent implements OnInit {

  @Input()
  public invoice: any;
  public projects: Project[] = [];
  public listCols = ProjectListComponent.invoiceCols;

  constructor() {
  }

  public ngOnInit(): void {
    Observable.from(this.invoice.projects)
      .pipe(
        map(p => p as Project),
        tap(p => this.projects.push(p))
      ).subscribe()
  }

}
