/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../project.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./source-file.component.ngfactory";
import * as i3 from "./source-file.component";
import * as i4 from "../../service/project.service";
import * as i5 from "../../service/file.service";
import * as i6 from "../../service/analysis.service";
import * as i7 from "@angular/material/dialog";
import * as i8 from "@angular/common";
import * as i9 from "./source-file-list.component";
var styles_SourceFileListComponent = [i0.styles];
var RenderType_SourceFileListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SourceFileListComponent, data: {} });
export { RenderType_SourceFileListComponent as RenderType_SourceFileListComponent };
function View_SourceFileListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "source-file", [["class", "sourceFile"]], null, [[null, "onFileRemove"], [null, "onDocPdfUpload"], [null, "onFileMetaUpdate"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onFileRemove" === en)) {
        var pd_0 = (_co.removeFile($event) !== false);
        ad = (pd_0 && ad);
    } if (("onDocPdfUpload" === en)) {
        var pd_1 = (_co.docFileUploaded($event) !== false);
        ad = (pd_1 && ad);
    } if (("onFileMetaUpdate" === en)) {
        var pd_2 = (_co.fileDataUpdated($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_SourceFileComponent_0, i2.RenderType_SourceFileComponent)), i1.ɵdid(1, 770048, null, 0, i3.SourceFileComponent, [i4.ProjectService, i5.FileService, i6.AnalysisService, i7.MatDialog], { file: [0, "file"], pId: [1, "pId"], expectedLanguage: [2, "expectedLanguage"], service: [3, "service"], allowRemoval: [4, "allowRemoval"] }, { onFileRemove: "onFileRemove", onDocPdfUpload: "onDocPdfUpload", onFileMetaUpdate: "onFileMetaUpdate" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.pId; var currVal_2 = _co.expectedLanguage; var currVal_3 = _co.service; var currVal_4 = _co.allowRemoval; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
function View_SourceFileListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "sourceFileList"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SourceFileListComponent_2)), i1.ɵdid(2, 278528, null, 0, i8.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.files; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_SourceFileListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_SourceFileListComponent_1)), i1.ɵdid(1, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.files != undefined); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_SourceFileListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "source-file-list", [], null, null, null, View_SourceFileListComponent_0, RenderType_SourceFileListComponent)), i1.ɵdid(1, 114688, null, 0, i9.SourceFileListComponent, [i4.ProjectService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SourceFileListComponentNgFactory = i1.ɵccf("source-file-list", i9.SourceFileListComponent, View_SourceFileListComponent_Host_0, { files: "files", pId: "pId", expectedLanguage: "expectedLanguage", service: "service", allowRemoval: "allowRemoval" }, { onFileRemove: "onFileRemove", onDocFileAdded: "onDocFileAdded", onFileMetaUpdate: "onFileMetaUpdate" }, []);
export { SourceFileListComponentNgFactory as SourceFileListComponentNgFactory };
