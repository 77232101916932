<h2>Translator summary</h2>

<div *ngIf="!userService.isCurrentUserAdmin">
  You do not have the correct privileges to access this page.
</div>

<div *ngIf="userService.isCurrentUserAdmin">

  <div *ngIf="availCompResults" class="mat-elevation-z8">
    <mat-table [dataSource]="availCompData" matSort>
      <ng-container matColumnDef="activity">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Activity</mat-header-cell>
        <mat-cell *matCellDef="let element">{{element.activity}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="source">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Source language</mat-header-cell>
        <mat-cell *matCellDef="let element"><span *ngIf="element.source">{{element.source|languagePipe|async}}</span></mat-cell>
      </ng-container>
      <ng-container matColumnDef="target">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Target language</mat-header-cell>
        <mat-cell *matCellDef="let element"><span *ngIf="element.target">{{element.target|languagePipe|async}}</span></mat-cell>
      </ng-container>
      <ng-container matColumnDef="count">
        <mat-header-cell *matHeaderCellDef mat-sort-header>User count</mat-header-cell>
        <mat-cell *matCellDef="let element" (click)="search(element)" class="subDirLink">{{element.count}}</mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="availCompCols"></mat-header-row>
      <mat-row *matRowDef="let row; columns: availCompCols;"></mat-row>
    </mat-table>
  </div>

  <div *ngIf="foundCompResults">
    <hr style="margin-bottom: 20px; margin-top: 50px;">
    <span style="font-weight: bold; font-size: small;">Displaying results for:</span>
    <div style="display: flex; justify-content: flex-start; margin-bottom: 15px; font-size: small;">
      <span style="margin-right: 15px;"><u>Activity</u>: {{activity}}</span>
      <span style="margin-right: 15px;" *ngIf="sourceLang"><u>Source language</u>: {{sourceLang}}</span>
      <span style="margin-right: 15px;" *ngIf="targetLang"><u>Target language</u>: {{targetLang}}</span>
    </div>
    <div class="mat-elevation-z8">
      <mat-table [dataSource]="foundCompData">
        <ng-container matColumnDef="firstname">
          <mat-header-cell *matHeaderCellDef>First name</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.firstName}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="lastname">
          <mat-header-cell *matHeaderCellDef>Last name</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.lastName}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="price">
          <mat-header-cell *matHeaderCellDef>Price</mat-header-cell>
          <mat-cell *matCellDef="let element"><span *ngIf="getPrice(element)">€{{getPrice(element)}}</span></mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="foundCompCols"></mat-header-row>
        <mat-row *matRowDef="let row; columns: foundCompCols;"></mat-row>
      </mat-table>
    </div>
  </div>

</div>
