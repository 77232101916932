import {map} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {getBaseUrl} from "../util/httpclient";


@Injectable()
export class MtService {

  private MT_API = getBaseUrl() + "/api/v1/mt";

  constructor(private http: HttpClient) {

  }

  isDeepLEnabled(): Observable<boolean> {
    return this.http.get(this.MT_API + "/deepl/enabled", {responseType: 'text'}).pipe(
      map(result => {
        return result === "true";
      }));
  }

  isSupportedLang4DeepL(langCode: string): Observable<boolean> {
    let url = this.MT_API + "/deepl/language/" + encodeURIComponent(langCode) + "/valid";
    return this.http.get(url, {responseType: 'text'}).pipe(
      map(result => {
        return result === "true";
      }));
  }

  isSupportedLangPair4DeepL(langPair: string): Observable<boolean> {
    return this.http.get(this.MT_API + "/deepl/langpair/" + encodeURIComponent(langPair) + "/valid", {responseType: 'text'}).pipe(
      map(result => {
        return result === "true";
      }));
  }
}
