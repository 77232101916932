import {filter} from 'rxjs/operators';
import {Component, Input} from "@angular/core";
import {TaskService} from "../service/task.service";


@Component({
  selector: 'colleagues-info',
  templateUrl: 'colleagues-info.component.html',
  styleUrls: ['task-dashb.component.css']
})
export class ColleaguesInfoComponent {

  @Input()
  pId;
  @Input()
  currentTask;
  colleagueInfo = [];

  constructor(public taskService: TaskService) {

  }

  ngOnInit() {
    // Only show tasks for the same language pair
    this.taskService.getTaskInfoForProject(this.pId).pipe(
      filter(v => {
        return v.id != this.currentTask.id &&
          v.sourcelanguage == this.currentTask.sourcelanguage &&
          v.targetlanguage == this.currentTask.targetlanguage
        }
      ))
      .subscribe(v => this.colleagueInfo.push(v))
  }
}
