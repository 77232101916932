/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../projectcomponent/project.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../util/frontpage.component.ngfactory";
import * as i4 from "../util/frontpage.component";
import * as i5 from "../service/file.service";
import * as i6 from "../editor/final-check-editor.component.ngfactory";
import * as i7 from "../editor/final-check-editor.component";
import * as i8 from "@angular/router";
import * as i9 from "../service/project.service";
import * as i10 from "../editor/service/editor.service";
import * as i11 from "@angular/material/dialog";
import * as i12 from "../util/language.pipe";
import * as i13 from "../service/language.service";
import * as i14 from "../util/filename.pipe";
import * as i15 from "./trans-check.component";
import * as i16 from "@angular/common/http";
var styles_TransCheckComponent = [i0.styles];
var RenderType_TransCheckComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TransCheckComponent, data: {} });
export { RenderType_TransCheckComponent as RenderType_TransCheckComponent };
function View_TransCheckComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", " "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["style", "cursor: pointer"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openHelp() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["src", "../../assets/icons8-help-32.png"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.project.title; _ck(_v, 1, 0, currVal_0); }); }
function View_TransCheckComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" to ", ""])), i1.ɵppd(2, 1), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 3).transform(i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 0), _co.target)))); _ck(_v, 1, 0, currVal_0); }); }
function View_TransCheckComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Last change:"]))], null, null); }
function View_TransCheckComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Last finalized:"]))], null, null); }
function View_TransCheckComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "warning"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Some changes have not been finalized."]))], null, null); }
function View_TransCheckComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TransCheckComponent_6)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵppd(5, 2), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TransCheckComponent_7)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), i1.ɵppd(10, 2), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TransCheckComponent_8)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.parent.context.$implicit.metaData && _v.parent.context.$implicit.metaData.change); _ck(_v, 2, 0, currVal_0); var currVal_2 = (_v.parent.context.$implicit.metaData && _v.parent.context.$implicit.metaData.saved); _ck(_v, 7, 0, currVal_2); var currVal_4 = _co.hasUnsavedChanges(_v.parent.context.$implicit); _ck(_v, 12, 0, currVal_4); }, function (_ck, _v) { var currVal_1 = i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v.parent.parent.parent, 2), _v.parent.context.$implicit.metaData.change, "EEE, MMM dd, y, HH:mm")); _ck(_v, 4, 0, currVal_1); var currVal_3 = i1.ɵunv(_v, 9, 0, _ck(_v, 10, 0, i1.ɵnov(_v.parent.parent.parent, 2), _v.parent.context.$implicit.metaData.saved, "EEE, MMM dd, y, HH:mm")); _ck(_v, 9, 0, currVal_3); }); }
function View_TransCheckComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["style", "margin-right: 10px; cursor: pointer; max-height: 200px;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openEditor(_v.context.$implicit.name) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵppd(3, 3), (_l()(), i1.ɵeld(4, 0, null, null, 1, "frontpage", [], null, null, null, i3.View_FrontpageComponent_0, i3.RenderType_FrontpageComponent)), i1.ɵdid(5, 245760, null, 0, i4.FrontpageComponent, [i5.FileService], { fileName: [0, "fileName"], pId: [1, "pId"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 7, "div", [["class", "fileinfo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [["style", "display: flex;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["# words:\u00A0"])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TransCheckComponent_5)), i1.ɵdid(13, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _v.context.$implicit.name; var currVal_2 = _co.pId; _ck(_v, 5, 0, currVal_1, currVal_2); var currVal_4 = _v.context.$implicit.metaData; _ck(_v, 13, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent, 1), _v.context.$implicit.name, 24, true)); _ck(_v, 2, 0, currVal_0); var currVal_3 = _v.context.$implicit.wordcount; _ck(_v, 11, 0, currVal_3); }); }
function View_TransCheckComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["style", "display: flex; min-height: 300px; margin: 30px;"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TransCheckComponent_4)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.sourcefiles; _ck(_v, 2, 0, currVal_0); }, null); }
function View_TransCheckComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["style", "border-top: 1px black solid;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "final-check-editor", [], null, [[null, "finalizeEmitter"], [null, "changeEmitter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("finalizeEmitter" === en)) {
        var pd_0 = (_co.finalized(_v.parent.context.$implicit.name) !== false);
        ad = (pd_0 && ad);
    } if (("changeEmitter" === en)) {
        var pd_1 = (_co.changes(_v.parent.context.$implicit.name) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i6.View_FinalCheckEditorComponent_0, i6.RenderType_FinalCheckEditorComponent)), i1.ɵdid(2, 114688, null, 0, i7.FinalCheckEditorComponent, [i8.Router, i8.ActivatedRoute, i9.ProjectService, i10.EditorService, i11.MatDialog], { pId: [0, "pId"], source: [1, "source"], target: [2, "target"], filename: [3, "filename"] }, { finalizeEmitter: "finalizeEmitter", changeEmitter: "changeEmitter" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.pId; var currVal_1 = _co.source; var currVal_2 = _co.target; var currVal_3 = _v.parent.context.$implicit.name; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_TransCheckComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TransCheckComponent_11)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.selectedFile == _v.context.$implicit.name); _ck(_v, 2, 0, currVal_0); }, null); }
function View_TransCheckComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TransCheckComponent_10)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.sourcefiles; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_TransCheckComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i12.LanguagePipe, [i13.LanguageService]), i1.ɵpid(0, i14.FilenamePipe, []), i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(3, 0, null, null, 13, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 8, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TransCheckComponent_1)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 5, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", " "])), i1.ɵppd(9, 1), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TransCheckComponent_2)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TransCheckComponent_3)), i1.ɵdid(14, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TransCheckComponent_9)), i1.ɵdid(16, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.project; _ck(_v, 6, 0, currVal_0); var currVal_2 = _co.target; _ck(_v, 12, 0, currVal_2); var currVal_3 = _co.project; _ck(_v, 14, 0, currVal_3); var currVal_4 = _co.sourcefiles; _ck(_v, 16, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 10).transform(i1.ɵunv(_v, 8, 0, _ck(_v, 9, 0, i1.ɵnov(_v, 0), _co.source)))); _ck(_v, 8, 0, currVal_1); }); }
export function View_TransCheckComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "trans-check", [], null, null, null, View_TransCheckComponent_0, RenderType_TransCheckComponent)), i1.ɵdid(1, 114688, null, 0, i15.TransCheckComponent, [i9.ProjectService, i5.FileService, i10.EditorService, i8.ActivatedRoute, i8.Router, i11.MatDialog, i16.HttpClient], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TransCheckComponentNgFactory = i1.ɵccf("trans-check", i15.TransCheckComponent, View_TransCheckComponent_Host_0, {}, {}, []);
export { TransCheckComponentNgFactory as TransCheckComponentNgFactory };
