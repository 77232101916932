<div>

  <div style="display: flex;">
    <div style="width: 70%">

      <div *ngIf="isDebug && views.length > 1" style="display: flex; align-items: center;">
        <label for="views"
               style="flex: 0 0; text-align: left; font-size: 10pt; margin-right: 5px; padding: 0;">View:</label>
        <!--Style is set here to make it as small as possible-->
        <select id="views"
                style="flex: 1 1; max-width: 200px; font-size:9pt; border: solid 1px; height:20px; margin-top:1%; margin-bottom: 1%;"
                [(ngModel)]="currentView"
                (change)="changeEditorView()">
          <option *ngFor="let view of views" [ngValue]="view" style="font-size:9pt;">{{view}}</option>
        </select>
      </div>
    </div>
    <div style="width: 30%; flex: 0 0 auto; display: flex; flex-direction: column">
      <div class="noteHeader" style="flex: 1 1 auto;">
        <h5 style="margin: 10px 0px 5px 5px;">
          <img src="../../assets/shortcuts.png"
               style="width: 20px; height: 20px; vertical-align: text-bottom; margin-right: 5px;"/>
          <a style="text-decoration: underline;" (click)="setShowShortcuts(!showShortcuts)">
            Shortcuts
          </a>
        </h5>
        <h5 style="margin: 10px 0px 5px 15px;">
          <img src="../../assets/icons8-settings-48.png"
               style="width: 20px; height: 20px; vertical-align: text-bottom; margin-right: 5px;"/>
          <a style="text-decoration: underline;" (click)="openSettings()">
            Settings
          </a>
        </h5>
        <button
          style="height: 30px; width: 30px; margin-left: 15px; padding: 1px; min-height: 30px; vertical-align: middle; border-radius: 5px;"
          [ngStyle]="{'background-color': showWhitespaces? 'var(--btn-bg-color)' : 'gray', 'border': showWhitespaces? 'inset black 2px' : 'none'}"
          (click)="setShowWhitespaces(!showWhitespaces)">&para;
        </button>
      </div>
      <div class="stickyNote" style="flex: 1 1 auto;" *ngIf="showShortcuts" ngDraggable>
        <ul>
          <li>Use <span class="stickyNoteBold">CTRL + UP or DOWN</span> arrow to navigate between segments.</li>
          <li>Use <span class="stickyNoteBold">CTRL + ALT + 1</span> to revert your changes to a modified segment.</li>
          <li>Use <span class="stickyNoteBold">CTRL + ALT + 2</span> to copy the TM proposal to the segment (if
            present).
          </li>
          <li>Use <span class="stickyNoteBold">CTRL + ALT + 3</span> to copy the MT proposal to the segment (if
            present).
          </li>
          <li>Use <span class="stickyNoteBold">CTRL + ALT + 4</span> to copy the repeated match to the segment (if
            present).
          </li>
          <li>If there are missing references:
            <ul>
              <li>Use <span class="stickyNoteBold">CTRL + ALT + R</span> in text to insert selected reference.</li>
              <li>Use <span class="stickyNoteBold">CTRL + ALT + 0</span> to toggle between dropdown and text.</li>
              <li>Use <span class="stickyNoteBold">CTRL + ENTER</span> in dropdown to insert selected reference into
                text.
              </li>
            </ul>
        </ul>
        <div style="width: 100%; text-align: right;">
          <a style="text-decoration: underline; margin-right: 10px;" (click)="setShowShortcuts(false)">Hide</a>
        </div>
      </div>
    </div>
  </div>

  <!-- Great reference:
  https://blog.angular-university.io/angular-material-data-table/ -->

  <div *ngIf="loadingContent" style="display: flex; align-items: center; justify-content: center;">
    <img src="../../assets/loading.gif">
  </div>
  <div *ngIf="errorMsg" class="errorDiv">
    <p style="color: red; margin: 1px;">{{errorMsg}}</p>
    <p style="color: red; margin: 1px;">Please try again and if the problem reoccurs, contact us at <a
      style="color:inherit;" href="mailto:info@lilo.global">info@lilo.global</a>.</p>
  </div>

  <div *ngIf="content" style="margin-top: 25px;">

    <div class="mat-elevation-z8">
      <div style="display: flex;">
        <inlayout *ngIf="isDebug && inlayoutview" [element]="clickedRow" [fileName]="fileName" [projectId]="projectId"
                  style="width: 90%; flex-grow:1; flex-shrink: 2; flex-basis: 300px; max-width: 600pt"></inlayout>
        <div
          style="max-height: 600px; overflow: scroll; overflow-scrolling: auto; width: 100%; flex-shrink: 1; flex-grow: 2; flex-basis: 300px; min-width: 500pt;">
          <mat-table #table [dataSource]="dataSource" matSort [trackBy]="trackById">
            <ng-container matColumnDef="id">
              <mat-header-cell *matHeaderCellDef mat-sort-header>ID</mat-header-cell>
              <mat-cell *matCellDef="let element" class="cellClass">{{element.id}}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="source">
              <mat-header-cell *matHeaderCellDef>Source - {{sourceLang|languagePipe|async}}
              </mat-header-cell>
              <mat-cell *matCellDef="let element" class="cellClass">
                <span class="segmentText">{{prepForDisplay(getTextFromSeg(element.sourceSeg, element.refs))}}</span>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="target">
              <mat-header-cell *matHeaderCellDef>Target - {{targetLang|languagePipe|async}}
              </mat-header-cell>
              <mat-cell *matCellDef="let element; index as i" #editCell>

                <div *ngIf="!isClickedRow(element)"
                     (click)="onSelect(element, false)"
                     style="margin: 2px; width: 100%; cursor: pointer;" [ngStyle]="{'color': getTextColor(element) }">
                  <span class="segmentText">{{prepForDisplay(element.currentSeg)}}</span>
                </div>

                <!-- When clicked, display the editing textarea for this segment -->
                <div *ngIf="isClickedRow(element)" class="cellEditor">
                  <div style="flex: 1 1 auto; display: flex;">
                <textarea class="cellTextArea" #txtArea
                          matTextareaAutosize matAutosizeMinRows="3" matAutosizeMaxRows="10"
                          [disabled]="!editable"
                          [(ngModel)]="element.currentSeg"
                          (ngModelChange)="segmentChanged(element)"
                          (focusout)="focusOut(element, txtArea.selectionStart)"
                          (keydown.control.enter)="moveToNext(element)"
                          (keydown.control.arrowdown)="moveToNext(element)"
                          (keydown.control.alt.arrowdown)="moveToNext(element)"
                          (keydown.control.arrowup)="moveToPrev(element)"
                          (keydown.control.alt.arrowup)="moveToPrev(element)"
                          (keyup.control.alt.1)="copyToCurrentSeg(element, element.targetSeg)"
                          (keyup.control.alt.2)="copyToCurrentSeg(element, element.tmSeg)"
                          (keyup.control.alt.3)="copyToCurrentSeg(element, element.mtSeg)"
                          (keyup.control.alt.4)="copyToCurrentSeg(element, element.repeatedMatch)"
                          (keyup.control.alt.0)="focusMissingRefList()"
                          (keyup.control.alt.r)="insertFirstMissingRef(element, txtArea)">
                </textarea>
                    <div style="flex: 0 0 30px; display: flex; flex-direction: column; align-items: center;">
                      <button class="btnIcon" style="flex: 0 0 15px; margin: 1px; text-align: center;"
                              title="Close row"
                              (click)="onSelect(undefined, false)">
                        <img src="../../assets/close.png"/>
                      </button>
                      <!-- TODO: enable G-translate button for DEV purposes when we figure out how to make it work --><!--
                  <button class="btnIcon" style="flex: 0 0 15px; margin: 1px; text-align: center;"
                          title="Translate it!"
                          (click)="translateWithGoogle(element)"
                          [disabled]="!editable">
                    <img src="../../assets/gtranslate.png"/>
                  </button>-->
                    </div>
                  </div>

                  <div *ngIf="element.refs" class="cellSegment">
                    <label># references: {{element.refs.length}}</label>
                    <div *ngIf="element.missingRefs" class="refWarningPanel">
                      <img src="../../assets/warning.png" style="width: 20px; height: 20px; margin: 1px 5px 1px 1px;"
                           title="Warning: segment not saved!"/>
                      <a (click)="openRefHelp()"
                         style="text-decoration: underline; color: inherit; margin-left: 2px; margin-right: 10px;"
                         title="Click for more info">
                        insert {{element.missingRefs.length}} reference(s):</a>
                      <select class="refList" #missingRefList
                              [disabled]="!editable"
                              [(ngModel)]="refToInsert"
                              (keyup.enter)="insertMissingRef(element)"
                              (keyup.control.enter)="insertMissingRef(element)"
                              (keyup.control.c)="copyMissingRef(element.refs)"
                              (keyup.control.alt.0)="focusTxtArea(element)">
                        <option *ngFor="let ref of element.missingRefs" [ngValue]="ref">
                          {{toSymbol(ref, element.refs)}}
                        </option>
                      </select>
                      <button (click)="insertMissingRef(element)" title="Insert reference"
                              class="btnIcon" style="height: 20px; width: 20px; min-height: 20px;">
                        <img src="../../assets/add_white.png" style="width: 20px; height: 20px;">
                      </button>
                    </div>
                  </div>

                  <div *ngIf="element.repeatedMatch" class="cellSegment">
                    <label style="flex: 1 1 100px; text-align: left;">
                      <span style="font-weight: bold;">Repeated match ({{element.repeatedMatch.score}}%):</span><br/>
                      {{getTextFromSeg(element.repeatedMatch, element.refs)}}
                    </label>

                    <!-- TODO: implement dialog with more info on the matching repeated matches, and a go-to function -->
                    <!-- Until the dialog is implemented, this button is disabled and only serves as a "placeholder" for the tooltip -->
                    <button style="flex: 0 0 15px; cursor: default; background-color: inherit;"
                            class="btnIcon"
                            [title]="getRepInfo(element)"
                            (click)="copyToCurrentSeg(element, element.repeatedMatch)"
                            [disabled]="true">
                      <img *ngIf="isSameFile(element)" src="../../assets/search.png"/>
                      <img *ngIf="!isSameFile(element)" src="../../assets/search_darkgrey.png"/>
                    </button>
                    <button style="flex: 0 0 15px;"
                            class="btnIcon"
                            title="Copy repeated match to segment (CTRL+ALT+4)"
                            (click)="copyToCurrentSeg(element, element.repeatedMatch)"
                            [disabled]="!editable">
                      <img src="../../assets/import.png"/>
                    </button>
                  </div>

                  <div *ngIf="element.tmSeg" class="cellSegment">
                    <label style="flex: 1 1 100px; text-align: left;">
                      <span style="font-weight: bold;">TM ({{element.tmSeg.score}}):</span><br/>
                      {{getTextFromSeg(element.tmSeg, element.refs)}}
                    </label>
                    <button style="flex: 0 0 15px;"
                            class="btnIcon"
                            title="Copy TM proposal to segment (CTRL+ALT+2)"
                            (click)="copyToCurrentSeg(element, element.tmSeg)"
                            [disabled]="!editable">
                      <img src="../../assets/import.png"/>
                    </button>
                  </div>

                  <div *ngIf="element.mtSeg" class="cellSegment">
                    <label style="flex: 1 1 100px; text-align: left;">
                      <span style="font-weight: bold;">MT:</span><br/>
                      {{getTextFromSeg(element.mtSeg, element.refs)}}
                    </label>
                    <button style="flex: 0 0 15px;"
                            class="btnIcon"
                            title="Copy MT proposal to segment (CTRL+ALT+3)"
                            (click)="copyToCurrentSeg(element, element.mtSeg)"
                            [disabled]="!editable">
                      <img src="../../assets/import.png"/>
                    </button>
                  </div>

                  <div *ngIf="element.modified" class="cellSegment">
                    <p style="flex: 1 1 100px; text-align: left; margin: 1px;">You have modified this segment.<span
                      *ngIf="element.noText"> It is marked as "<strong>no text</strong>".</span></p>
                    <button style="flex: 0 0 15px;"
                            class="btnIcon"
                            title="Revert changes (CTRL+ALT+1)"
                            (click)="copyToCurrentSeg(element, element.targetSeg)"
                            [disabled]="!editable">
                      <img src="../../assets/undo.png" style="width: 23px; height: 23px;"/>
                    </button>
                  </div>
                  <div *ngIf="!element.modified && element.noText" class="cellSegment">
                    <p style="flex: 1 1 100px; text-align: left; margin: 1px;"
                       title="A segment marked as &quot;no text&quot; contains no translatable text, only whitespace and references.">
                      This is a "<strong>no text</strong>" segment.</p>
                  </div>

                  <!--todo: add countdown to due date on top-->
                  <!--todo: add button to roll back to last saved version? (only applicable if there are missing references)-->
                  <!--todo: implement better keyboard navigation (to move to next segment, to insert tm proposal, ...)!-->
                  <!--todo: when deleting a reference, also delete the "anchor" automatically-->
                  <!--todo: autocomplete based on MT/TM results? or even query tm/mt on-the-fly?-->
                  <!--todo: when hovering over either source or target, indicate pre-/suffix whitespace (since any whitespace affixes are trimmed, typically there shouldn't be any so indicating them would probaby be a good idea)-->
                </div>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="status">
              <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
              <mat-cell *matCellDef="let element" class="cellClass" style="flex-wrap: wrap;">
                <div *ngIf="!isClickedRow(element)"
                     (click)="onSelect(element, false)"
                     style="display: flex; flex-wrap: wrap; justify-content: flex-end; cursor: pointer;">
                  <img src="../../assets/icons8-edit-50.png" style="width: 15px; height: 15px;"
                       title="You have modified this segment."
                       *ngIf="element.modified && (element.missingRefs == null || element.missingRefs.length == 0)"/>
                  <img src="../../assets/warning.png" style="width: 15px; height: 15px;"
                       title="Warning: missing references! This segment was not saved."
                       *ngIf="element.modified && (element.missingRefs != null && element.missingRefs.length > 0)"/>
                  <img src="../../assets/warning.png" style="width: 15px; height: 15px;"
                       title="Warning: missing references! These must be added before finalizing the file."
                       *ngIf="!element.modified && (element.missingRefs != null && element.missingRefs.length > 0)"/>
                  <span *ngIf="element.noText" style="text-decoration: line-through; font-size: 10px; color: green;"
                        title="This segment contains no text, only whitespace and references.">abc</span>
                </div>
              </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
          </mat-table>
        </div>
      </div>
      <mat-paginator #paginator
                     [pageSize]="25"
                     [pageSizeOptions]="[10, 25, 50, 100, 250, 500, 1000, 2000, 3000]"
                     [showFirstLastButtons]="true">
      </mat-paginator>
    </div>
  </div>

  <div style="display: flex; justify-content: space-between; align-items: center;">
    <div style="margin-top: 10px;">
      Modified: {{getProgressCount()}}/{{getTotalCount()}} segments -- No text: {{noTextCount}} segments
    </div>
  </div>

</div>

