import {Component, Input} from "@angular/core";
import {UserService} from "../service/user.service";
import {MatDialog} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {LanguageService} from "../service/language.service";
import {CountryService} from "../service/country.service";
import {FileService} from "../service/file.service";
import {FormControl} from "@angular/forms";
import {Observable} from "rxjs";
import {map, startWith} from "rxjs/operators";
import {ProfileInfoDialogComponent} from "./profile.info.dialog.component";
import {ActivityService} from "../service/activity.service";


@Component({
  selector: "profile-settings",
  templateUrl: './profile.setting.component.html',
  styleUrls: ['./user.settings.component.css']
})
export class ProfileSettingsComponent {

  @Input() user;
  @Input() isTranslator: boolean = false;

  languages;
  dialectLanguages;
  activities;
  countries;
  currencies = [];
  timezones = [];

  filteredTimezones: Observable<any[]>;
  timezoneCtrl = new FormControl();
  internalTranslator: boolean = false;
  activity;
  source;
  target;


  constructor(private snackbar: MatSnackBar,
              private dialog: MatDialog,
              public userService: UserService,
              private languageService: LanguageService,
              private activityService: ActivityService,
              private countryService: CountryService,
              private fileService: FileService) {

  }

  ngOnInit(): void {
    this.languageService.getLanguages().subscribe(l => this.languages = l);
    this.languageService.getDialectLanguages().subscribe(l => this.dialectLanguages = l);
    this.activityService.getActivities().subscribe(a => this.activities = a);
    this.countryService.getCountries().subscribe(c => this.countries = c);
    this.countryService.getCurrencies().subscribe(c => this.currencies = c);
    this.countryService.getTimezones()
      .subscribe(t => {
        this.timezones = t;
        this.prefillFromBrowserData();
        let timeZone = this.getZoneForId(this.user.timezone);
        this.timezoneCtrl.setValue(timeZone);
        this.filteredTimezones = this.timezoneCtrl.valueChanges
          .pipe(
            startWith<string | any>(''),
            map(timezone => typeof timezone === 'string' ? timezone : timezone.value),
            map(timezone => {
              let v = timezone ? this.filterTimezones(timezone) : this.timezones.slice();
              return v;
              }
            )
          );
      });
    if (!this.isTranslator)
      this.isTranslator = this.userService.isUserTranslator(this.user);
    this.internalTranslator = this.userService.isUserInternalTranslator(this.user);
  }

  getSaveObservables(): Observable<any> {
    if (this.userService.isCurrentUser(this.user))
      sessionStorage.setItem("currency", this.user.currency);
    this.user.isTranslator = this.isTranslator;
    return this.userService.updateUserData(this.user);
  }

  reloadUser(r: any) {
    // Only need to reload if the current user is the one being edited
    if (this.userService.isCurrentUser(this.user))
      this.userService.reloadUser();
  }

  prefillFromBrowserData() {
    // Check on "startsWith('(GMT')" for the older users that got their TZ saved with 'value' iso 'zone ID' and reset their TZ
    if (this.user.timezone == undefined || this.user.timezone.startsWith("(GMT")) {
      let date = new Date();
      let timezoneOffset = date.getTimezoneOffset();
      if (this.isDayLightSaving(date)) {
        timezoneOffset = timezoneOffset + 60
      }
      let number = timezoneOffset / 60 * -1; //Convert the timezoneOffset to the GMT code
      let myTimeZones = this.timezones.filter(tz => tz.code == "" + number);//Filter only on the timezones which have the same code
      if (myTimeZones.length > 0) {
        this.user.timezone = myTimeZones[0].jzoneid;
      } else
      // Default to 'Europe/London' if none other found
        this.user.timezone = "Europe/London";
    }
  }

  private isDayLightSaving(date) {
    let dateOffset = date.getTimezoneOffset();
    let dayLightSavingOffset = new Date(date.getYear(), 6).getTimezoneOffset();
    return dateOffset === dayLightSavingOffset
  }

  displayTimezone(tz: any) {
    if (tz && !(typeof tz == 'string'))
      return tz.value;
    return tz;
  }

  private getZoneForId(tzId: string): any {
    let fv = tzId.toLowerCase();
    let tzones = this.timezones.filter(tz => {
      return tz.jzoneid.toLowerCase().indexOf(fv) >= 0
    });
    if (tzones.length > 0)
      return tzones[0];
    else return null;
  }

  private filterTimezones(value: string): any[] {
    let fv = value.toLowerCase();
    return this.timezones.filter(tz => {
      return tz.value.toLowerCase().indexOf(fv) >= 0
    })
  }

  openDialog() {
    let dialogRef = this.dialog.open(ProfileInfoDialogComponent, {
      maxWidth: '90%',
      minWidth: '65%',
      maxHeight: '85%',
      data: {id: this.user.id, isTranslator: this.isTranslator}
    });
  }

  clearInternalCompetences() {
    this.user.internalCompetences = null;
  }

  removeInternalCompetence(competence) {
    this.user.internalCompetences.splice(this.user.internalCompetences.indexOf(competence), 1)
  }

  addInternalCompetence() {
    if (this.activity != undefined && this.source != undefined && this.target != undefined) {
      if (this.user.internalCompetences == undefined)
        this.user.internalCompetences = [];
      let competence = Object();
      competence.activity = this.activity;
      competence.source = this.source;
      competence.target = this.target;
      this.user.internalCompetences.push(competence);
      this.activity = undefined;
      this.source = undefined;
      this.target = undefined;
    }
  }

  save() {
    if (this.timezoneCtrl.value && this.timezoneCtrl.value.jzoneid)
      this.user.timezone = this.timezoneCtrl.value.jzoneid;
    //Handle the internal competences before saving
    //In case there is a competence filled in, add that competences, most likely they forgot to click the add
    this.addInternalCompetence();
    //In case the user is not an internal translator (anymore) clear the internal competences
    if (!this.internalTranslator)
      this.clearInternalCompetences();
    //Get the save observable and subscribe to it to actually do the save
    this.getSaveObservables().subscribe(result => {
        this.snackbar.open("Profile updated", '', {
          duration: 2000
        })
      },
      error => console.error("Error updating user " + this.user.id, error))
  }
}
