/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./task-dashb.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./task-card.component.ngfactory";
import * as i3 from "./task-card.component";
import * as i4 from "@angular/router";
import * as i5 from "../service/project.service";
import * as i6 from "@angular/common";
import * as i7 from "./task-list.component";
var styles_TaskListComponent = [i0.styles];
var RenderType_TaskListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TaskListComponent, data: {} });
export { RenderType_TaskListComponent as RenderType_TaskListComponent };
function View_TaskListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "module"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "task-card", [], null, null, null, i2.View_TaskCardComponent_0, i2.RenderType_TaskCardComponent)), i1.ɵdid(2, 114688, null, 0, i3.TaskCardComponent, [i4.Router, i5.ProjectService], { task: [0, "task"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }, null); }
function View_TaskListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "boxing"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_TaskListComponent_2)), i1.ɵdid(2, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i6.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.tasks)); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_TaskListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TaskListComponent_1)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tasks; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_TaskListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "task-list", [], null, null, null, View_TaskListComponent_0, RenderType_TaskListComponent)), i1.ɵdid(1, 114688, null, 0, i7.TaskListComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TaskListComponentNgFactory = i1.ɵccf("task-list", i7.TaskListComponent, View_TaskListComponent_Host_0, { tasks: "tasks", title: "title", emptyText: "emptyText" }, {}, []);
export { TaskListComponentNgFactory as TaskListComponentNgFactory };
