/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
import * as i2 from "@angular/forms";
import * as i3 from "../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@angular/material/button";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "./editor-setting.component";
var styles_EditorSettingsComponent = [];
var RenderType_EditorSettingsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EditorSettingsComponent, data: {} });
export { RenderType_EditorSettingsComponent as RenderType_EditorSettingsComponent };
export function View_EditorSettingsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "h1", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i0.ɵdid(1, 81920, null, 0, i1.MatDialogTitle, [[2, i1.MatDialogRef], i0.ElementRef, i1.MatDialog], null, null), (_l()(), i0.ɵted(-1, null, ["Editor settings"])), (_l()(), i0.ɵeld(3, 0, null, null, 24, "div", [["class", "mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i0.ɵdid(4, 16384, null, 0, i1.MatDialogContent, [], null, null), (_l()(), i0.ɵeld(5, 0, null, null, 1, "p", [["style", "margin-bottom: 15px;"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Adjust your editor settings. "])), (_l()(), i0.ɵeld(7, 0, null, null, 20, "div", [["style", "padding: 3px;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 9, "div", [["style", "display: flex; justify-content: space-between;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 1, "label", [["style", "flex: 0 0 auto; padding: 0;"], ["title", "Choose whether or not you wish to hide the references that surround a segment. (Default: yes)"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Hide surrounding references? "])), (_l()(), i0.ɵeld(11, 0, null, null, 6, "span", [], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 5, "input", [["style", "margin: 3px;"], ["type", "checkbox"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "change"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (i0.ɵnov(_v, 13).onChange($event.target.checked) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 13).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("ngModelChange" === en)) {
        var pd_2 = ((_co.hideAffix = $event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i0.ɵdid(13, 16384, null, 0, i2.CheckboxControlValueAccessor, [i0.Renderer2, i0.ElementRef], null, null), i0.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.CheckboxControlValueAccessor]), i0.ɵdid(15, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i0.ɵdid(17, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i0.ɵeld(18, 0, null, null, 9, "div", [["style", "display: flex; justify-content: space-between;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(19, 0, null, null, 1, "label", [["style", "flex: 0 0 auto; padding: 0;"], ["title", "Choose whether or not you wish to hide the segments that contain no text (only whitespace or references). (Default: yes)"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Hide \"no text\" segments? "])), (_l()(), i0.ɵeld(21, 0, null, null, 6, "span", [], null, null, null, null, null)), (_l()(), i0.ɵeld(22, 0, null, null, 5, "input", [["style", "margin: 3px;"], ["type", "checkbox"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "change"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (i0.ɵnov(_v, 23).onChange($event.target.checked) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 23).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("ngModelChange" === en)) {
        var pd_2 = ((_co.hideNoText = $event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i0.ɵdid(23, 16384, null, 0, i2.CheckboxControlValueAccessor, [i0.Renderer2, i0.ElementRef], null, null), i0.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.CheckboxControlValueAccessor]), i0.ɵdid(25, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i0.ɵdid(27, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i0.ɵeld(28, 0, null, null, 7, "div", [["class", "mat-dialog-actions"], ["mat-dialog-actions", ""], ["style", "justify-content: space-around; margin-top: 20px;"]], null, null, null, null, null)), i0.ɵdid(29, 16384, null, 0, i1.MatDialogActions, [], null, null), (_l()(), i0.ɵeld(30, 0, null, null, 2, "button", [["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirmClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i0.ɵdid(31, 180224, null, 0, i4.MatButton, [i0.ElementRef, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i0.ɵted(-1, 0, ["Save"])), (_l()(), i0.ɵeld(33, 0, null, null, 2, "button", [["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancelClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i0.ɵdid(34, 180224, null, 0, i4.MatButton, [i0.ElementRef, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i0.ɵted(-1, 0, ["Cancel"]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_8 = _co.hideAffix; _ck(_v, 15, 0, currVal_8); var currVal_16 = _co.hideNoText; _ck(_v, 25, 0, currVal_16); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = i0.ɵnov(_v, 17).ngClassUntouched; var currVal_2 = i0.ɵnov(_v, 17).ngClassTouched; var currVal_3 = i0.ɵnov(_v, 17).ngClassPristine; var currVal_4 = i0.ɵnov(_v, 17).ngClassDirty; var currVal_5 = i0.ɵnov(_v, 17).ngClassValid; var currVal_6 = i0.ɵnov(_v, 17).ngClassInvalid; var currVal_7 = i0.ɵnov(_v, 17).ngClassPending; _ck(_v, 12, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_9 = i0.ɵnov(_v, 27).ngClassUntouched; var currVal_10 = i0.ɵnov(_v, 27).ngClassTouched; var currVal_11 = i0.ɵnov(_v, 27).ngClassPristine; var currVal_12 = i0.ɵnov(_v, 27).ngClassDirty; var currVal_13 = i0.ɵnov(_v, 27).ngClassValid; var currVal_14 = i0.ɵnov(_v, 27).ngClassInvalid; var currVal_15 = i0.ɵnov(_v, 27).ngClassPending; _ck(_v, 22, 0, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15); var currVal_17 = (i0.ɵnov(_v, 31).disabled || null); var currVal_18 = (i0.ɵnov(_v, 31)._animationMode === "NoopAnimations"); _ck(_v, 30, 0, currVal_17, currVal_18); var currVal_19 = (i0.ɵnov(_v, 34).disabled || null); var currVal_20 = (i0.ɵnov(_v, 34)._animationMode === "NoopAnimations"); _ck(_v, 33, 0, currVal_19, currVal_20); }); }
export function View_EditorSettingsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "editor-setting", [["class", "component"]], null, null, null, View_EditorSettingsComponent_0, RenderType_EditorSettingsComponent)), i0.ɵdid(1, 114688, null, 0, i7.EditorSettingsComponent, [i1.MatDialogRef, i1.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EditorSettingsComponentNgFactory = i0.ɵccf("editor-setting.component", i7.EditorSettingsComponent, View_EditorSettingsComponent_Host_0, {}, {}, []);
export { EditorSettingsComponentNgFactory as EditorSettingsComponentNgFactory };
