import * as tslib_1 from "tslib";
import { ElementRef, EventEmitter, OnInit } from "@angular/core";
import { InvoiceService } from "../../service/invoice.service";
import { PricingService } from '../../service/pricing.services';
export class PaypalComponent {
    constructor(invoiceService, pricingService) {
        this.invoiceService = invoiceService;
        this.pricingService = pricingService;
        this.approveEmitter = new EventEmitter();
        // This is the pu that is used to create the order and is given
        // afterwards to the server to store in the db
        this.cachedPurchaseUnit = undefined;
    }
    ngOnInit() {
        paypal
            .Buttons({
            style: {
                layout: 'vertical',
                color: 'blue',
                shape: 'pill',
                label: 'checkout'
            },
            createOrder: (data, actions) => {
                return this.createPaypalOrder(actions);
            },
            onApprove: (data, actions) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                let orderData;
                yield actions.order.authorize().then(function (authorization) {
                    // Get the authorization id
                    const authorizationID = authorization.purchase_units[0]
                        .payments.authorizations[0].id;
                    console.debug('Authorazation id', authorizationID);
                    orderData = { orderID: data.orderID,
                        authorizationID: authorizationID };
                });
                // set the pu that is created during order creation
                orderData.pu = this.cachedPurchaseUnit;
                this.invoiceService.storeProjectPaypal(this.project.id, orderData)
                    .subscribe(a => console.debug('Authorization saved'), e => console.error(e));
                this.approveEmitter.emit(orderData);
                return {};
            }),
            onError: err => {
                console.log(err);
            }
        })
            .render(this.paypalElement.nativeElement);
    }
    createPaypalOrder(actions) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let order = yield this.pricingService.getTotalProjectPrice(this.project.id)
                .map(p => p.totalIncVat())
                .map(p => Math.round(p * 100) / 100)
                .map(p => {
                return this.getPaypalPurchaseUnit('' + p);
            })
                .map(pu => {
                // Cache the pruchase unit that is used so it can be used for the db as well
                this.cachedPurchaseUnit = pu;
                return {
                    purchase_units: [pu]
                };
            })
                .map(o => {
                return actions.order.create(o);
            }).toPromise();
            console.debug(order);
            return order;
        });
    }
    getPaypalPurchaseUnit(price) {
        return {
            description: this.project.title,
            amount: {
                currency_code: 'EUR',
                value: price
            }
        };
    }
}
