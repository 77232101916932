<div *ngIf="competences">

  <mat-table #table [dataSource]="competenceData" matSort>
    <ng-container matColumnDef="selectBox">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let element"><input type="checkbox" (change)="selectionChanged($event, element)"/>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="activity">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Task type</mat-header-cell>
      <mat-cell *matCellDef="let element" class="mat-cell-title">{{element.activity}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="sourceLang">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Source language</mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.sourceLang| languagePipe|async}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="targetLang">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Target language</mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.targetLang| languagePipe|async}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="price">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Price per unit</mat-header-cell>
      <mat-cell *matCellDef="let element" style="display:flex; align-items: center;">
        <p style="margin-right: 5px; font-size: inherit;">€</p>
        <input type="number" step="0.001" style="flex: 1 1 auto; border: 0; font-size: inherit;"
               [(ngModel)]="element.price" (change)="priceChanged($event, element)"/>
        <p *ngIf="isCharacterBasedLang(element.sourceLang)" style="font-size: smaller;">&nbsp;(per character)</p>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="availCols"></mat-header-row>
    <mat-row *matRowDef="let row; columns: availCols;" style="cursor: pointer;"></mat-row>
  </mat-table>
</div>
