import {filter, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Vendor} from "../dto/vendor";
import {Observable} from "rxjs";
import {HttpClient, HttpParams} from "@angular/common/http";
import {getBaseUrl} from "../util/httpclient";
import { SERVICE_TRANSLATION } from '../mock-activities';


@Injectable()
export class VendorService {

  private USER_API = getBaseUrl() + "/api/v1/users";
  private COMPETENCE_API = getBaseUrl() + "/api/v1/competences";


  constructor(private http: HttpClient) {
  }

  getAvailableCompetences(): Observable<any[]> {
    return this.http.get(this.COMPETENCE_API + "/find/langpair/available").pipe(map(a => a as any[]))
  }

  searchCompetences(languagePair: string, activity: string, userId: string = null): Observable<any[]> {
    let params = new HttpParams().set("userId", userId);
    return this.http.get(this.COMPETENCE_API + "/find/langpair/" + activity + "/" + encodeURIComponent(languagePair),
      {params: params}).pipe(
      map(a => a as any[]))
  }

  getCompetences(languagePair: string, activity: string, userId: string = null): Observable<Vendor[]> {
    return this.searchCompetences(languagePair, activity, userId).pipe(
      map(a => a.map(v => this.extractToVendor(v))))
  }

  getBestCompetences(languagePair: string, activity: string, userId: string): Observable<Vendor[]> {
    let params = new HttpParams().set("userId", userId)
      .append("limit", "3");
    return this.http.get(this.COMPETENCE_API + "/bestmatches/" + activity + "/" + encodeURIComponent(languagePair),
      {params: params}).pipe(
      map(a => a as any[]),
      map(a => a.map(v => this.extractToVendor(v))),)
  }

  getBestProjectCompetences(projectId: string, languagePair: string, activity: string, service: string = SERVICE_TRANSLATION, count: string = undefined): Observable<Vendor[]> {
    let params = new HttpParams().set('limit', count)
        .set('activity', activity)
        .set('service', service)
        .set('langpair', encodeURIComponent(languagePair))
        .set('pid', projectId);
    if (service == null) {
      params = params.set('service', SERVICE_TRANSLATION);
    }
    return this.http.get(this.COMPETENCE_API + '/search',
      {params: params}).pipe(
      map(a => a as any[]),
      map(a => a.map(v => this.extractToVendor(v))), )
  }

  getVendor(id: string): Observable<Vendor> {
    return this.http.get(this.USER_API + "/find/" + id).pipe(filter(a => a != null),map(v => this.extractToVendor(v)),)
  }

  private extractToVendor(json: any) {
    // console.debug("vendor from server", json);
    //TODO complete additional data or don't work with the Vendor object anymore and just with "any"
    let rating = 0;
    let reviewcount = 0;
    let reviewsum = json.reviewsummary;
    if (reviewsum != null) {
      let scores = reviewsum.scores;
      rating = this.calcRatingFromScores(scores);
      reviewcount = reviewsum.count;
    }
    if (json.internalCompetences != null) {
      json.competences = json.internalCompetences;
    }
    let responsivety: string;
    if (json.responsiveness != null && json.responsiveness.average != null) {
      const average = json.responsiveness.average;
      if (average < 60) {
        responsivety = '1 hour';
      } else if (average / 60 < 24) {
        responsivety = (Number(average / 60) + 1).toFixed(0) + ' hours';
      } else {
        responsivety = (Number(average / 1440) + 1).toFixed(0) + ' days';
      }
    }
    let price = 0;
    if (json.competences && json.competences[0])
      price = json.competences[0].price;
    return new Vendor(
      json.id, json.firstName, json.lastName, "", json.description, json.language, json.city, json.location,
      rating, reviewcount, responsivety, price, json.competences, json.domains, json.percentageSet,
      json.internalCompetences != undefined, json.companyId);
  }

  private calcRatingFromScores(scores) {
    let rating = 0;
    for (let score of scores)
      rating += score.rate;
    return rating / scores.length;
  }
}
