import {Component, EventEmitter, Input, Output} from "@angular/core";
import {FileService} from "../service/file.service";


@Component({
  selector: 'profile-pic-upload',
  templateUrl: './profile.pic.upload.component.html',
  styleUrls: ['./user.settings.component.css']
})
export class ProfilePicUploadComponent {

  @Input()
  userid;
  fileUpload = "";
  @Output()
  picEmitter = new EventEmitter<string>();

  constructor(private fileService: FileService) {

  }

  uploadFiles(event) {
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      let file: File = fileList[0];
      this.fileService.uploadProfilePicture(file, this.userid)
        .subscribe(v => this.picEmitter.next(v));
    }
  }
}
