import {filter, flatMap, map} from 'rxjs/operators';
import {Component, Input, SimpleChanges} from "@angular/core";
import {FileService} from "../../service/file.service";
import {HttpClient} from '@angular/common/http';
import {MatDialog} from '@angular/material';
import {HelpDialogComponent} from '../../util/help.dialog.component';
import * as FileSaver from "file-saver";
import {getSourceFiles, toLangPairString} from "../../util/jobutil";


@Component({
  selector: 'langpair-target-files',
  templateUrl: './langpair-target-files.component.html',
  styleUrls: ['../project.component.css'],
})
export class LangpairTargetFilesComponent {

  @Input()
  project;
  @Input()
  langPair: any;
  @Input()
  viewAsTiles: boolean;

  sourceFiles = [];
  downloadableFilesInfo = [];


  constructor(private fileService: FileService,
    private dialog: MatDialog,
    private http: HttpClient) {

  }

  ngOnInit() {
    let langpairString = toLangPairString({source: this.langPair.source, target: this.langPair.target});
    this.sourceFiles = getSourceFiles(this.project);
    this.downloadableFilesInfo = [];
    this.fileService.fetchAvailableFinals(this.project.id, langpairString)
      .pipe(
        filter(f => f!=undefined),
        map(f => <Array<string>>f),
        flatMap(f => f),
        map(filename => this.sourceFiles.find(f => f.name === filename))
      )
      .subscribe(f => {
        this.downloadableFilesInfo.push(f);
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.viewAsTiles) {
      this.viewAsTiles = changes.viewAsTiles.currentValue;
    }
  }

  openTransCheckHelp() {
    this.http.get('assets/help/transcheck-help.html', {responseType: 'text'})
      .subscribe(helptext => {
          this.dialog.open(HelpDialogComponent, {
            width: '700px',
            maxHeight: '65%',
            data: {helpTitle: "Why and how should you do translation checking?", helpHtmlContent: helptext}
          });
        }
      );
  }

  download(file: any) {
    const langpairString = toLangPairString(this.langPair);
    this.fileService.downloadFinal(this.project.id, langpairString, file.name)
      .subscribe( n => {
        FileSaver.saveAs(n, file.name, true);
      })
  }

  getLpString() {
    return toLangPairString(this.langPair);
  }
}
