<div *ngIf="tableModel != null">
  <table>
    <thead>
    <tr class="dtable-header">
      <th class="dtable-header-cell dtable-select-cell dtable-cell" *ngIf="selectable">
        <input type="checkbox" title="Select all" (click)="toggleAllSelected()" [(ngModel)]="masterCheckboxValue">
      </th>
      <th *ngFor="let header of tableModel.headers" class="dtable-header-cell dtable-cell">{{header.value}}</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let row of tableModel.data; index as i" class="dtable-row">
      <td class="dtable-select-cell dtable-cell" *ngIf="selectable">
        <input type="checkbox" title="Select" (click)="toggleRowSelected(row, i)" [(ngModel)]="tableModel.selected[i]">
      </td>
      <td *ngFor="let column of tableModel.headers" class="dtable-cell" (click)="onCellClick(row, column)">
        {{getCellValue(row, column)}}
      </td>
    </tr>
    </tbody>
  </table>
  <p *ngIf="!hasData()" class="dtable-msg-nodata">
    {{noDataMessage != null ? noDataMessage : 'No data available'}}</p>
  <p *ngIf="showRowCount && hasData()" class="dtable-msg-rowcount">{{tableModel.data.length}} row(s)</p>
</div>
