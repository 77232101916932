/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./colleague-select.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/forms";
import * as i4 from "./colleague-select-list.component";
var styles_ColleagueSelectListComponent = [i0.styles];
var RenderType_ColleagueSelectListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ColleagueSelectListComponent, data: {} });
export { RenderType_ColleagueSelectListComponent as RenderType_ColleagueSelectListComponent };
function View_ColleagueSelectListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "colleagueSelected"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u2713"]))], null, null); }
function View_ColleagueSelectListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "colleagueItem"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleColleague(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "button", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(4, { colleagueItemSelected: 0 }), (_l()(), i1.ɵted(5, null, ["", " ", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ColleagueSelectListComponent_2)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 4, 0, _co.isSelected(_v.context.$implicit)); _ck(_v, 3, 0, currVal_0); var currVal_3 = _co.isSelected(_v.context.$implicit); _ck(_v, 7, 0, currVal_3); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit.firstName; var currVal_2 = _v.context.$implicit.lastName; _ck(_v, 5, 0, currVal_1, currVal_2); }); }
export function View_ColleagueSelectListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "searchColleagues"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "input", [["id", "colleagueSearchInput"], ["placeholder", "Search colleagues"], ["type", "text"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 3)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 3)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.searchText = $event) !== false);
        ad = (pd_4 && ad);
    } if (("input" === en)) {
        var pd_5 = (_co.filterColleagues() !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 16384, null, 0, i3.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i3.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.DefaultValueAccessor]), i1.ɵdid(5, 671744, null, 0, i3.NgModel, [[8, null], [8, null], [8, null], [6, i3.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i3.NgControl, null, [i3.NgModel]), i1.ɵdid(7, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "colleaguesList"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ColleagueSelectListComponent_1)), i1.ɵdid(10, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.searchText; _ck(_v, 5, 0, currVal_7); var currVal_8 = _co.filteredColleagues; _ck(_v, 10, 0, currVal_8); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 7).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 7).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 7).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 7).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 7).ngClassValid; var currVal_5 = i1.ɵnov(_v, 7).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 7).ngClassPending; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_ColleagueSelectListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "colleague-select-list", [], null, null, null, View_ColleagueSelectListComponent_0, RenderType_ColleagueSelectListComponent)), i1.ɵdid(1, 4308992, null, 0, i4.ColleagueSelectListComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ColleagueSelectListComponentNgFactory = i1.ɵccf("colleague-select-list", i4.ColleagueSelectListComponent, View_ColleagueSelectListComponent_Host_0, { selectedColleagues: "selectedColleagues", colleagues: "colleagues" }, { eventEmitter: "eventEmitter" }, []);
export { ColleagueSelectListComponentNgFactory as ColleagueSelectListComponentNgFactory };
