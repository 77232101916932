/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./editor.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./editor.component.ngfactory";
import * as i3 from "./editor.component";
import * as i4 from "./service/editor.service";
import * as i5 from "@angular/material/dialog";
import * as i6 from "../service/file.service";
import * as i7 from "@angular/common";
import * as i8 from "./final-check-editor.component";
import * as i9 from "@angular/router";
import * as i10 from "../service/project.service";
var styles_FinalCheckEditorComponent = [i0.styles];
var RenderType_FinalCheckEditorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FinalCheckEditorComponent, data: {} });
export { RenderType_FinalCheckEditorComponent as RenderType_FinalCheckEditorComponent };
function View_FinalCheckEditorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["style", "flex: 1 1 auto; height: 10%;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.filename; _ck(_v, 2, 0, currVal_0); }); }
function View_FinalCheckEditorComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "editor", [], null, [[null, "changeEmitter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("changeEmitter" === en)) {
        var pd_0 = (_co.change($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_EditorComponent_0, i2.RenderType_EditorComponent)), i1.ɵdid(1, 4308992, [[1, 4], ["editor", 4]], 0, i3.EditorComponent, [i4.EditorService, i5.MatDialog, i6.FileService], { sourceLang: [0, "sourceLang"], targetLang: [1, "targetLang"], fileLocation: [2, "fileLocation"], fileGroupId: [3, "fileGroupId"], projectId: [4, "projectId"], fileName: [5, "fileName"], editable: [6, "editable"] }, { changeEmitter: "changeEmitter" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.source; var currVal_1 = _co.target; var currVal_2 = _co.fileLoc; var currVal_3 = _co.fileGroupId; var currVal_4 = _co.pId; var currVal_5 = _co.filename; var currVal_6 = true; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
export function View_FinalCheckEditorComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { editor: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 15, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["style", "display: flex; justify-content: space-between;"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FinalCheckEditorComponent_1)), i1.ɵdid(4, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "editorButtons"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "button", [["class", "editorButton"], ["title", "Complete & send file"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.saveFinalCheckFile() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "img", [["src", "../../assets/checkmark_white.png"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Finalize "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FinalCheckEditorComponent_2)), i1.ɵdid(10, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 5, "div", [["style", "display: flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 0, "div", [["style", "width: 90%"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 3, "div", [["class", "editorButtons"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 2, "button", [["class", "editorButton"], ["title", "Complete & send file"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.saveFinalCheckFile() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 0, "img", [["src", "../../assets/checkmark_white.png"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Finalize "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.filename; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.fileLoc; _ck(_v, 10, 0, currVal_1); }, null); }
export function View_FinalCheckEditorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "final-check-editor", [], null, null, null, View_FinalCheckEditorComponent_0, RenderType_FinalCheckEditorComponent)), i1.ɵdid(1, 114688, null, 0, i8.FinalCheckEditorComponent, [i9.Router, i9.ActivatedRoute, i10.ProjectService, i4.EditorService, i5.MatDialog], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FinalCheckEditorComponentNgFactory = i1.ɵccf("final-check-editor", i8.FinalCheckEditorComponent, View_FinalCheckEditorComponent_Host_0, { pId: "pId", source: "source", target: "target", filename: "filename" }, { finalizeEmitter: "finalizeEmitter", changeEmitter: "changeEmitter" }, []);
export { FinalCheckEditorComponentNgFactory as FinalCheckEditorComponentNgFactory };
