import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {AmazingTimePickerService} from 'amazing-time-picker';
import {TimePickerConfig} from "amazing-time-picker/src/app/atp-library/definitions";


@Component({
  selector: "timepicker",
  template: `
    <input type='time' value='{{timeField}}' title="{{tooltip}}" [style.color]="textColor" placeholder="hh:mm"
           (click)='open()'/>
  `
})
export class TimeComponent implements OnInit {

  @Input()
  hours;
  @Input()
  minutes;
  @Input()
  minTime;
  @Input()
  checkMinimum = false;
  @Output()
  timeSelected = new EventEmitter<string>();
  @Output()
  invalidTimeSelected = new EventEmitter<string>();

  timeField;
  actualMinTime = undefined;
  tooltip = "Pick a date.";
  textColor = "inherit";


  constructor(private atp: AmazingTimePickerService) {
  }

  ngOnInit(): void {
    this.timeField = makeTimeField(this.hours, this.minutes);
    this.actualMinTime = determineMinTime(this.minTime);
    this.validateTime();
  }

  open(): void {
    let config: TimePickerConfig = {
      theme: 'material-blue',
      preference: {
        labels: {ok: 'OK', cancel: 'Cancel'}
      }
    };
    if (this.timeField != undefined) {
      config.time = this.timeField;
    }
    /*
		if (this.minTime) {
		  config.rangeTime = {
			start: this.minTime,
			end: '23:59'
		  }
		}*/
    const amazingTimePicker = this.atp.open(config);
    amazingTimePicker.afterClose().subscribe(time => {
      this.setTime(time)
    });
  }

  setTime(time): void {
    this.hours = this.extractHour(time);
    this.minutes = this.extractMin(time);
    this.timeField = makeTimeField(this.hours, this.minutes);
    if (this.validateTime())
      this.timeSelected.emit(time);
    else
      this.invalidTimeSelected.emit(time);
  }

  validateTime(): boolean {
    if (this.checkTime(this.timeField)) {
      this.textColor = "inherit";
      return true;
    } else {
      this.textColor = "red";
      return false;
    }
  }

  extractHour(time: string): Number {
    if (time != undefined) {
      let idx = time.indexOf(":");
      if (idx > 0) {
        let hrs = parseInt(time.substring(0, idx).trim());
        if (hrs >= 0 && hrs < 24) {
          return hrs;
        }
      }
    }
    return null;
  }

  extractMin(time: string): Number {
    if (time != undefined) {
      let idx = time.indexOf(":");
      if (idx >= 0 && (idx + 1) < time.length) {
        let min = parseInt(time.substring(idx + 1).trim());
        if (min >= 0 && min < 60) {
          return min;
        }
      }
    }
    return null;
  }

  private checkTime(time: any): boolean {
    if (this.checkMinimum && this.actualMinTime != undefined) {
      if (time < this.actualMinTime) {
        this.tooltip = "Time cannot be earlier than " + this.actualMinTime + ".";
        return false;
      }
    }
    this.tooltip = "Pick a time.";
    return true;
  }
}

export function makeTimeField(hrs, min): string {
  let time;
  if (hrs != undefined) {
    if (!(hrs instanceof Number)) hrs = parseInt(hrs);
    time = hrs.toLocaleString(undefined, {minimumIntegerDigits: 2});
  } else time = "23";
  time += ":";
  if (min != undefined) {
    if (!(min instanceof Number)) min = parseInt(min);
    time += min.toLocaleString(undefined, {minimumIntegerDigits: 2});
  } else time += "59";
  return time;
}

export function determineMinTime(minTime: string): string {
  if (minTime == undefined)
    return undefined;
  let curMinTime;
  // If the minimum time is 'now', determine what 'now' is
  if (minTime != undefined && minTime.startsWith("now", 0)) {
    let now = new Date();
    let offsetHrs: number = 0;
    // Check whether 'now' needs to be offset by x hours
    if (minTime.startsWith("now+", 0)) {
      try {
        offsetHrs = parseInt(minTime.substring("now+".length));
      } catch (e) {
      }
    } else if (minTime.startsWith("now-", 0)) {
      try {
        offsetHrs = (parseInt(minTime.substring("now-".length)) * -1);
      } catch (e) {
      }
    }
    curMinTime = makeTimeField((now.getHours() + offsetHrs), now.getMinutes());
  } else
    curMinTime = minTime;
  return curMinTime;
}
