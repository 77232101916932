import {Component, Input} from "@angular/core";
import {CountryService} from "../service/country.service";


@Component({
  selector: "price",
  template: `
    <span matTooltip='{{tooltipText()}}'>
        <span class='currPrice' *ngIf="price === undefined || price === null">{{undefinedDefault}}</span>
        <span class='currPrice' *ngIf="price != null" [ngStyle]="getCurrStyles()">
            {{price*rate | currency:displayCurrency:display:digitsInfo}}
        </span>
        <span class='eurPrice' *ngIf='price != null && showBase && baseCurrency != displayCurrency' style='font-size: smaller;'>
            ({{price | currency:baseCurrency:display:digitsInfo}})
        </span>
    </span>
  `
})
export class PriceComponent {
  @Input()
  baseCurrency = 'EUR';
  @Input()
  displayCurrency = sessionStorage.getItem("currency");
  @Input()
  price;
  @Input()
  digitsInfo = '1.2-2';
  @Input()
  display = 'symbol';
  @Input()
  showBase = false;
  @Input()
  currStyles: any;
  @Input()
  undefinedDefault;

  rate = 1;


  constructor(private countryService: CountryService) {

  }

  ngOnInit() {
    if (this.baseCurrency == this.displayCurrency) {
      this.rate = 1;
    } else {
      this.countryService.getExchangeRate(this.baseCurrency, this.displayCurrency).subscribe(rate => {
        this.rate = rate;
      });
    }
  }

  tooltipText(): string {
    if (this.baseCurrency === this.displayCurrency)
      return undefined;
    else {
      return "The pricing in " + this.displayCurrency + " is merely indicative. " +
        "It is calculated with " + this.baseCurrency + " as base currency, and an exchange rate of " + this.rate + "."
    }
  }

  getCurrStyles() {
    if (this.currStyles)
      return this.currStyles;
    else return "";
  }

}
