import {Component, Input} from "@angular/core";
import * as FileSaver from "file-saver";
import {FileService} from "../service/file.service";
import {LanguageService} from "../service/language.service";
import {SimpleDialog} from "../simple-dialog.component";
import {HelpDialogComponent} from "../util/help.dialog.component";
import {TranslationUploadDialog} from "./task-detail.component";
import {MatDialog, MatSnackBar} from '@angular/material';
import {Router} from '@angular/router';
import {TaskService} from "../service/task.service";
import {UserService} from "../service/user.service";


@Component({
  selector: 'task-file',
  templateUrl: './task-file.component.html',
  styleUrls: ['task.component.css'],
})
export class TaskFileComponent {

  @Input() file;
  @Input() task;

  @Input() availableJobs: Array<string>;
  @Input() availableXliffs: Array<string>;
  @Input() availablePDFs;
  @Input() availableTMXs;
  @Input() source4pdf;

  isJobCompleted = false;
  isJobAvailable = undefined;
  isXliffAvailable = false;
  isPDFAvailable = false;
  isTMXAvailable = false;

  taskJob;
  right2LeftLangs;
  charBasedLangs;

  constructor(
    private taskService: TaskService,
    private fileService: FileService,
    private languageService: LanguageService,
    private userService: UserService,
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
    private router: Router) {

  }

  ngOnInit() {
    this.languageService.getRight2LeftLanguages().subscribe(r2l => this.right2LeftLangs = r2l);
    this.languageService.getCharBasedLanguages().subscribe(cbl => this.charBasedLangs = cbl);
    this.setTaskJob();
  }

  ngDoCheck() {
    this.isJobCompleted = this.task.jobs.find(j => j.file == this.file.name && j.completed != null) != undefined;
    this.isJobAvailable = this.availableJobs != null && this.availableJobs.find(f => f.startsWith(this.file.name)) != undefined;
    this.isXliffAvailable = this.availableXliffs != null && this.availableXliffs.find(f => f.startsWith(this.file.name)) != undefined;
    this.isPDFAvailable = this.source4pdf != null ||
      (this.availablePDFs != null && this.availablePDFs.find(f => f.startsWith(this.file.name)) != undefined);
    this.isTMXAvailable = this.availableTMXs != null && this.availableTMXs.find(f => f.startsWith(this.file.name)) != undefined;
    /*console.debug("task " + this.task.id + ", file " + this.file + " => " +
      "job complete? " + this.isJobCompleted + " - " +
      "job available? " + this.isJobAvailable + " - " +
      "xliff available? " + this.isXliffAvailable);*/
  }

  setTaskJob() {
    this.taskJob = this.task.jobs.find(f => f.file.indexOf(this.file.name) >= 0);
  }

  isManual() {
    return this.file.processType != undefined && this.file.processType == 'MANUAL';
  }

  getWordCount() {
    return this.file.wordcount;
  }

  allowOrigFileDownload() {
    // Only show if not manual and pdf is not available
    // If manual, then the file will already be available as "main" download (so no need to add it to the additional files)
    return !this.isManual() && !this.isPDFAvailable
  }

  enableEditor(): boolean {
    return !(this.task.rejectdate != undefined) && !this.isRight2LeftLanguage();
  }

  isRight2LeftLanguage(): boolean {
    return this.right2LeftLangs != undefined &&
      (this.right2LeftLangs.indexOf(this.task.sourcelanguage) >= 0 || this.right2LeftLangs.indexOf(this.task.targetlanguage) >= 0);
  }

  isCharBasedSource(): boolean {
    if (this.charBasedLangs)
      return this.charBasedLangs.indexOf(this.task.sourcelanguage) >= 0;
    return false;
  }

  downloadOrig() {
    if (!this.isManual()) {
      let dialogRef = this.dialog.open(SimpleDialog, {
        width: '400px',
        panelClass: 'simpleDialog',
        data: {
          dialogHtml: "<p>" +
            "You are downloading the original source file. This file should <strong>not be used to translate with</strong>. <br/>" +
            "<br/>" +
            "To translate, use the available XLIFF instead.</p>",
          dialogButtonName: "Got it!"
        }
      });
      dialogRef.afterClosed().subscribe(a => {
        this.fileService.downloadSource(this.task.projectId, this.file.name).subscribe(
          n => {
            FileSaver.saveAs(n, this.file.name, true);
          });
      });
    } else {
      this.fileService.downloadSource(this.task.projectId, this.file.name).subscribe(
        n => {
          this.setJobDownloadDate();
          FileSaver.saveAs(n, this.file.name, true);
        });
    }
  }

  openInEditor() {
    this.router.navigate(['/editor', this.task.id, this.file.name]);
  }

  downloadXliff() {
    this.fileService.downloadXliff(this.file.name, this.task).subscribe(
      n => {
        this.setJobDownloadDate();
        FileSaver.saveAs(n, this.file.name + ".xlf", true);
      }
    );
  }

  private setJobDownloadDate() {
    // Flag that the file was downloaded, so we can indicate this in the task page (only if currently logged in user is
    // effectively the assigned translator)
    if (this.userService.isLoggedIn() && this.userService.isCurrentUserId(this.task.vendorId))
      try {
        this.taskService.setJobDownloadDate(this.task.id, this.file.name).subscribe();
      } catch (e) {
        console.error("Error setting job download date for task " + this.task.id + " and file " + this.file.name)
      }
  }

  downloadSDLXliff() {
    this.fileService.downloadSDLXliff(this.file.name, this.task).subscribe(
      n => {
        FileSaver.saveAs(n, this.file.name + ".sdlxliff", true);
      }
    );
  }

  downloadTMX() {
    this.fileService.downloadTMX(this.file.name, this.task).subscribe(
      n => {
        FileSaver.saveAs(n, this.file.name + ".tmx", true);
      }
    );
  }

  downloadPDF() {
    if (this.source4pdf != null) {
      this.fileService.downloadDoc(this.task.projectId, this.source4pdf.name).subscribe(
        n => {
          FileSaver.saveAs(n, this.file.name + "_custom.pdf", true);
        }
      )
    } else
      this.fileService.downloadPDF(this.file.name, this.task).subscribe(
        n => {
          FileSaver.saveAs(n, this.file.name + ".pdf", true);
        }
      );
  }

  openTradosHelpDialog() {
    let helpText = `
      <p>Do you want to handle the file with Trados? Great!</p>
      <p>We have already prepared the file so it can be easily opened with SDL Trados Studio.<br/>
         First, click "Download for Trados", which will download the SDLXliff file we have prepared for you.
         If Trados is properly installed, you can then open it by double clicking the downloaded file.</p>
      <p>When the translation is completed, you can just upload the SDLXliff file again (please only upload <i>.sdlxliff</i> file, not the <i>.sdlproj</i> file).</p>
      <p>If there is a TMX file available (see "Additional file(s)"), don't forget to download and import it into your Trados project to gain the leverage from the customer's translation memory.</p>
    `;
    let dialogRef = this.dialog.open(HelpDialogComponent, {
      width: '700px',
      height: '500px',
      data: {helpTitle: "How to handle a file with Trados?", helpHtmlContent: helpText}
    });
  }

  public getLangPair() {
    return this.task.sourcelanguage + "_" + this.task.targetlanguage;
  }

  openUploadDialog() {
    let dialogRef = this.dialog.open(TranslationUploadDialog, {
      width: '550px',
      data: {task: this.task, taskFile: this.file}
    });
    dialogRef.afterClosed().subscribe(filePath => this.confirmTaskUpload(filePath, null))
  }

  openManualUploadDialog() {
    let dialogRef = this.dialog.open(TranslationUploadDialog, {
      width: '550px',
      data: {
        task: this.task, taskFile: this.file,
        expectedFileTypes: /(?:\.([^.]+))?$/.exec(this.file.name).toString()
      }
    });
    dialogRef.afterClosed().subscribe(filePath => this.confirmTaskUpload(filePath, "MANUAL"))
  }

  confirmTaskUpload(filePath: any, processType: string) {
    if (filePath != null) {
      this.fileService.confirmTranslation(filePath, this.file.name, this.task, processType).subscribe(data => {
        console.debug('Confirmed translation of ' + this.file.name + ' for task ' + this.task.id, data);
      }, error => {
        console.error('Unable to confirm translation file upload of ' + this.file.name + ' for task ' + this.task.id, error);
        this.snackbar.open("Error uploading translation. Please try again.", "", {
          duration: 4000
        });
      });
    }
  }

  hasBegunTask() {
    return this.task.acceptdate != undefined && this.taskJob != null &&
      (this.taskJob.lasteditoraccessdate != undefined || this.taskJob.lastdownloaddate != undefined);
  }

  hasDownloadedLast() {
    // Determine whether the user downloaded an XLIFF last (return true) or opened the editor (return false)
    // If neither, undefined is returned
    if (this.taskJob != undefined) {
      if (this.taskJob.lasteditoraccessdate != undefined && this.taskJob.lastdownloaddate != undefined) {
        return this.taskJob.lasteditoraccessdate < this.taskJob.lastdownloaddate;
      } else if (this.taskJob.lasteditoraccessdate != undefined) {
        return false;
      } else if (this.taskJob.lastdownloaddate != undefined) {
        return true;
      } else {
        return undefined;
      }
    }
  }
}
