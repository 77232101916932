<div class="documentation-list-component">
  <div *ngIf="files.length > 0" class="documentationFiles" id="uploadedFilesDiv">
    <div *ngFor="let f of files">
      <documentation-file
        [file]="f"
        [entityId]="entityId"
        [entityType]="entityType"
        [allowRemoval]="allowRemoval"
        (removeEmitter)="removeFile($event)"
      ></documentation-file>
    </div>
  </div>
</div>
