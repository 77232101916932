<div class="chatDiv">
  <div class="chatHeader" (click)="toggleCollapse()">
    <div style="float: left;"><strong>Project chat</strong></div>
    <div style="float: right;"><img src="../../../assets/icon_down.svg" alt="Expand/collapse" class="chatIcon"
                                    [ngClass]="{'invertChatIcon':isChatExpanded}"/></div>
  </div>
  <div [ngClass]="{'chatBody':true, 'chatBodyCollapsed':!isChatExpanded, 'chatBodyExpanded':isChatExpanded}">
    <chat [projectId]="projectId" style="display: flex; min-height: 270px; position: relative;"></chat>
  </div>
</div>
