/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./vendor-selector.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./vendor-profile.component.ngfactory";
import * as i3 from "./vendor-profile.component";
import * as i4 from "../../service/vendor.service";
import * as i5 from "../../service/user.service";
import * as i6 from "../../service/pricing.services";
import * as i7 from "../../service/statistics.service";
import * as i8 from "@angular/router";
import * as i9 from "@angular/common";
import * as i10 from "./vendor-list.component";
var styles_VendorListComponent = [i0.styles];
var RenderType_VendorListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_VendorListComponent, data: { "animation": [{ type: 7, name: "appearIn", definitions: [{ type: 0, name: "in", styles: { type: 6, styles: { opacity: 1 }, offset: null }, options: undefined }, { type: 1, expr: "void => *", animation: [{ type: 6, styles: { opacity: 0 }, offset: null }, { type: 4, styles: null, timings: "0.5s ease-in" }], options: null }], options: {} }] } });
export { RenderType_VendorListComponent as RenderType_VendorListComponent };
function View_VendorListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "module"]], [[24, "@appearIn", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "vendor-profile", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectVendor(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_VendorProfileComponent_0, i2.RenderType_VendorProfileComponent)), i1.ɵdid(2, 770048, null, 0, i3.VendorProfileComponent, [i4.VendorService, i5.UserService, i6.PricingService, i7.StatisticsService, i8.Router], { profile: [0, "profile"], basePrice: [1, "basePrice"], taskSpec: [2, "taskSpec"], showPricing: [3, "showPricing"], maxDescriptionSize: [4, "maxDescriptionSize"], maxDomainCount: [5, "maxDomainCount"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _v.context.$implicit; var currVal_2 = _v.context.$implicit.basePrice; var currVal_3 = _co.taskSpec; var currVal_4 = _co.showPricing; var currVal_5 = 250; var currVal_6 = 3; _ck(_v, 2, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, function (_ck, _v) { var currVal_0 = "in"; _ck(_v, 0, 0, currVal_0); }); }
export function View_VendorListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "boxing"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_VendorListComponent_1)), i1.ɵdid(2, 278528, null, 0, i9.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i9.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.vendors)); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_VendorListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "vendor-list", [], null, null, null, View_VendorListComponent_0, RenderType_VendorListComponent)), i1.ɵdid(1, 114688, null, 0, i10.VendorListComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var VendorListComponentNgFactory = i1.ɵccf("vendor-list", i10.VendorListComponent, View_VendorListComponent_Host_0, { vendors: "vendors", taskSpec: "taskSpec", showPricing: "showPricing" }, { eventEmitter: "eventEmitter" }, []);
export { VendorListComponentNgFactory as VendorListComponentNgFactory };
