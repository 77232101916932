import {Component, OnInit} from "@angular/core";
import {UserService} from "../../../service/user.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {CustomerGroup} from "../../../types/customer-group";
import {ColumnType, DynamicTableHeader} from "../../../util/component/dynamic-table/dynamic-table.model";
import {DynamicTableClickEvent} from "../../../util/component/dynamic-table/dynamic-table.component";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'customer-group-list',
  templateUrl: 'customer-group-list.component.html',
  styleUrls: ['customer-group-list.component.css'],
})
export class CustomerGroupListComponent implements OnInit {

  public customerGroups: CustomerGroup[] = null;
  public headers: DynamicTableHeader[] = [
    {
      key: 'id',
      value: 'ID',
    },
    {
      key: 'name',
      value: 'Name',
    },
    {
      key: 'token',
      value: 'Token',
    },
    {
      key: 'tm.tmIdx',
      value: 'TM',
    },
    {
      key: 'creationdate',
      value: 'Created on',
      type: ColumnType.DATE,
    },
  ];
  public newGroupName: string = "";
  public host: string = "";

  constructor(public userService: UserService,
              private snackbar: MatSnackBar) {
    this.host = environment.appURI;
  }

  public ngOnInit(): void {
    this.retrieveGroups();
  }

  private retrieveGroups() {
    this.userService.findCustomerGroups().subscribe(groups => {
      this.customerGroups = groups;
    });
  }

  public isNewButtonValid(): boolean {
    return this.newGroupName != null && this.newGroupName.trim().length > 0;
  }

  public createNew(): void {
    let custGroup = {name: this.newGroupName.trim()};
    this.userService.createCustomerGroup(custGroup).subscribe(r => {
      this.snackbar.open("Customer group created", '', {duration: 2000});
      this.retrieveGroups();
    });
  }

  public groupSelected(event: DynamicTableClickEvent) {
    // TODO: open page with customer group details
  }
}
