<div *ngIf="projectRows && availCols != null && availCols.length > 0">
  <mat-table #table [dataSource]="projectData" matSort>
    <ng-container matColumnDef="title">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Project name</mat-header-cell>
      <mat-cell *matCellDef="let element" class="mat-cell-title"
                title="{{element.title}}"><a [routerLink]="['/pdetail/'+element.id]"
                                             routerLinkActive="router-link-active"
                                             (click)="$event.stopPropagation()"
                                             style="text-decoration: none;">{{element.title|filenamePipe:35:true}}</a>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="alerts">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Alerts</mat-header-cell>
      <mat-cell *matCellDef="let element"></mat-cell>
    </ng-container>
    <ng-container matColumnDef="sourceLang">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Source</mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.sourceLang| languagePipe|async}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="targetLangs">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Target</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <ul class="targetLangList">
          <li *ngFor="let tl of element.targetLangs | slice:0:3; let i=index">{{tl}}</li>
          <li *ngIf="element.targetLangCodes.length > 3"
              class="targetLangMoreLabel">& {{element.targetLangCodes.length - 3}} more
          </li>
        </ul>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="dueDate">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Due date</mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.dueDate|date:'yyyy-MM-dd'}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="orderedOn">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Ordered on</mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.orderedOn|date:'yyyy-MM-dd'}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="fileCount">
      <mat-header-cell *matHeaderCellDef mat-sort-header># file(s)</mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.fileCount}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
      <mat-cell *matCellDef="let element" [ngStyle]="{'color': getStatusColor(element)}">{{element.status}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="user">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Ordered by</mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.user}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="company">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Company</mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.company}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="price">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Price (VAT incl.)</mat-header-cell>
      <mat-cell *matCellDef="let element"><price [price]="element.price" [displayCurrency]="'EUR'" style="text-align: right; width: 100%;"></price></mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="availCols"></mat-header-row>
    <mat-row *matRowDef="let row; columns: availCols;" (click)="selectProject(row.id)"
             style="cursor: pointer;"></mat-row>
  </mat-table>
</div>
