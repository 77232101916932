import {filter, map} from 'rxjs/operators';
import {Component, Input, OnDestroy} from "@angular/core";
import {FileService} from "../service/file.service";
import { SubscriptionHandler } from './subscription.handler';


@Component({
  selector: 'frontpage',
  template: `
	  <div class="fileimage" [ngStyle]="{'display': (!frontpageExist && !showIconIfUnavailable) ? 'none' : 'block'}"
		   [style.width]="width" [style.height]="height">
		  <img *ngIf="frontpageExist" class="frontpage" [src]="frontpageImage" alt="Front page image">
		  <img *ngIf="!frontpageExist && showIconIfUnavailable" class="doctypeimage"
		   src="{{doctypeImage}}" alt="File type image"
		   (error)="handleDoctypeImageNotFound()">
	  </div>
  `,
  styles: [
    '.doctypeimage {height: 100%; width: 100%; position: absolute; left: 0; bottom: 0; box-shadow: 0 0 6px #00000029 !important;}',
    '.frontpage {height: 100%; width: 100%; position: absolute; left: 0; bottom: 0; box-shadow: 0 0 6px #00000029 !important;}',
    '.fileimage {position: relative;}',
  ]
})
export class FrontpageComponent implements OnDestroy{

  @Input() fileName: string;
  @Input() pId;
  @Input() showIconIfUnavailable = true;
  @Input() width;
  @Input() height;

  frontpageExist: boolean = false;
  frontpageImage: string = null;
  doctypeImage: string = null;

  subscriptionHandler = new SubscriptionHandler(this);

  constructor(private fileservice: FileService,) {
  }

  ngOnInit() {
    if (this.height == null) this.height = "100%";
    if (this.width == null) this.width = "100%";
    if (this.fileName) {
      this.doctypeImage = this.getDoctypeImageUrl();
      if (this.pId != null) {
        this.handleFrontPageImageUrl();
        const frontPageSubscription = this.fileservice.enrollForProject(this.pId).pipe(
          map(data => {
            return data
          }),
          filter(data => data.action == "FRONTPAGE_IMG" && data.filename == this.fileName),).subscribe(data => {
          this.handleFrontPageImageUrl()
        });
        this.subscriptionHandler.push(frontPageSubscription);
      }
    }
  }

  ngOnDestroy() {

  }

  handleFrontPageImageUrl() {
    if (this.fileName) {
      this.frontpageExist = false;//TODO really check if it exists, will probably need external functions for that
      this.fileservice.getFrontpage(this.pId, this.fileName)
        .subscribe(next => {
            this.frontpageImage = next;
            this.frontpageExist = true;
          }, error => {
            this.frontpageExist = false;
          }
        );
    }
  }

  public getDoctypeImageUrl(): string {
    // Most images found at https://www.flaticon.com/packs/file-type-set
    let basePath = "../../assets/doctype/";
    if (this.fileName) {
      let filename = this.fileName;
      return basePath + filename.substring(filename.lastIndexOf(".") + 1) + ".png";
    }
  }

  public handleDoctypeImageNotFound() {
    this.doctypeImage = "../../../assets/file.png";
  }
}
