/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../project.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../chatcomponent/chat.component.ngfactory";
import * as i4 from "../../chatcomponent/chat.component";
import * as i5 from "../../service/user.service";
import * as i6 from "../../service/chat.service";
import * as i7 from "@angular/material/snack-bar";
import * as i8 from "@angular/router";
import * as i9 from "./project-chat.component";
var styles_ProjectChatComponent = [i0.styles];
var RenderType_ProjectChatComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProjectChatComponent, data: {} });
export { RenderType_ProjectChatComponent as RenderType_ProjectChatComponent };
export function View_ProjectChatComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { chatComponent: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 15, "div", [["class", "chatDiv"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 8, "div", [["class", "chatHeader"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleCollapse() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["style", "float: left;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Project chat"])), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["style", "float: right;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 3, "img", [["alt", "Expand/collapse"], ["class", "chatIcon"], ["src", "../../../assets/icon_down.svg"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(9, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(10, { "invertChatIcon": 0 }), (_l()(), i1.ɵeld(11, 0, null, null, 5, "div", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(13, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(14, { "chatBody": 0, "chatBodyCollapsed": 1, "chatBodyExpanded": 2 }), (_l()(), i1.ɵeld(15, 0, null, null, 1, "chat", [["style", "display: flex; min-height: 270px; position: relative;"]], null, null, null, i3.View_ChatComponent_0, i3.RenderType_ChatComponent)), i1.ɵdid(16, 245760, [[1, 4]], 0, i4.ChatComponent, [i5.UserService, i6.ChatService, i7.MatSnackBar, i8.Router], { projectId: [0, "projectId"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "chatIcon"; var currVal_1 = _ck(_v, 10, 0, _co.isChatExpanded); _ck(_v, 9, 0, currVal_0, currVal_1); var currVal_2 = _ck(_v, 14, 0, true, !_co.isChatExpanded, _co.isChatExpanded); _ck(_v, 13, 0, currVal_2); var currVal_3 = _co.projectId; _ck(_v, 16, 0, currVal_3); }, null); }
export function View_ProjectChatComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "project-chat", [], null, null, null, View_ProjectChatComponent_0, RenderType_ProjectChatComponent)), i1.ɵdid(1, 114688, null, 0, i9.ProjectChatComponent, [i5.UserService, i6.ChatService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProjectChatComponentNgFactory = i1.ɵccf("project-chat", i9.ProjectChatComponent, View_ProjectChatComponent_Host_0, { projectId: "projectId" }, {}, []);
export { ProjectChatComponentNgFactory as ProjectChatComponentNgFactory };
