<h2 style="margin: 25px 0;">TM management</h2>

<div *ngIf="!userService.isCurrentUserAdmin">
  You do not have the correct privileges to access this page.
</div>

<div *ngIf="userService.isCurrentUserAdmin == true">
  <p>Specify a user ID to locate the corresponding TM index. <br/>
    The system will first check whether either the user or the user's company has a customer group specified. If so, the
    TM index from this group is returned. If not, then the user's company (if any) is checked and the TM index specified
    there returned. If no company, then the TM index specified on the user itself will be returned.</p>
  <div style="display: flex; align-items: end;">
    <div>
      <label for="userid">User ID</label>
      <input id="userid" type="text" placeholder="Enter a user ID" [(ngModel)]="userId" style="width: 300px;"/>
    </div>
    <button style="flex: 0 0 auto; margin: 0 0 0 20px;"
            (click)="findTm4Owner()" [disabled]="!(userId != undefined && userId.length >0)">Find
    </button>
  </div>

  <div *ngIf="tmIdxData != null">
    <hr style="margin: 35px 0; ">

    <div class="mainWithSideBox">
      <!--todo: offer more options here: download tmx, search tm, ... use tabs?-->
      <div class="mainBox">
        <h3>Upload TMX</h3>
        <div style="padding: 15px 0;">
          <label for="tmxfile">TMX file</label>
          <div style="display: flex; align-items: center;">
            <input id="tmxfile" type="file" placeholder="Select a TMX file" accept=".tmx, .txt"
                   (change)="selectTmx($event)"/>
            <button style="margin: 0 0 0 20px; min-width: 100px; display: flex; justify-content: center;"
                    (click)="uploadTmx()"
                    [disabled]="!(tmIdxData.tmindex != undefined && tmxFile != undefined && !loading)">
              <span *ngIf="!loading">Upload</span>
              <img *ngIf="loading" src="../../../assets/loading_small_orb_whiteonblue.gif" alt="Uploading..."
                   style="width: 15px;"/>
            </button>
          </div>
        </div>
        <p *ngIf="successMsg" style="margin: 25px 0 0 0; font-weight: bold; padding: 0;">{{successMsg}}</p>
      </div>

      <div class="sideBox">
        <h4>Selected TM</h4>
        <p>TM index: {{tmIdxData.tmindex}}</p>
        <p *ngIf="tmIdxData.info != undefined && tmIdxData.info.name != undefined">Name: {{tmIdxData.info.name}}</p>
        <p *ngIf="tmIdxData.info != undefined && tmIdxData.info.type != undefined">Type:
          {{tmIdxData.info.type}}-level</p>
      </div>
    </div>
  </div>
  <p *ngIf="errorMsg" style="color: orange; margin: 25px 0;">{{errorMsg}}</p>

</div>


