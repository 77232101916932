import {debounceTime, map} from 'rxjs/operators';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation
} from "@angular/core";
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {Subject} from 'rxjs';
import {FileService} from "../service/file.service";
import {EditorService} from "./service/editor.service";
import {EditorRow, Reference, Segment, TransUnit} from "./dto/transunit";
import {HelpDialogComponent} from "../util/help.dialog.component";
import {
  isNoText,
  isRef,
  segContentToText,
  textToSegContent,
  toSymbol,
  trimSegment,
  visualizeFormattingMarks
} from "./util/segmanipulator";
import {EditorSettingsComponent} from "./editor-setting.component";
import {environment} from "../../environments/environment";


@Component({
  selector: 'editor',
  templateUrl: 'editor.component.html',
  styleUrls: ['editor.component.css'],
  // "ViewEncapsulation.None" is specified here so the innerHTML set in the editable divs would properly take the CSS
  // style into account.
  encapsulation: ViewEncapsulation.None
})
export class EditorComponent {

  @Input()
  sourceLang;
  @Input()
  targetLang;
  @Input()
  fileLocation;
  @Input()
  fileGroupId;
  @Input()
  projectId;
  @Input()
  fileName;
  @Input()
  editable = false;

  @Output()
  changeEmitter = new EventEmitter<any>();

  content: TransUnit[];
  rows: EditorRow[];

  clickedRow: EditorRow;

  dataSource = new MatTableDataSource<EditorRow>();
  private sort: MatSort;
  private paginator: MatPaginator;
  showShortcuts: boolean;
  showWhitespaces: boolean;

  @ViewChildren('txtArea') textAreas: QueryList<ElementRef>;
  currentTextArea: ElementRef;
  @ViewChildren('missingRefList') missingRefList: QueryList<ElementRef>;
  refToInsert: Reference;

  userInput: Subject<EditorRow> = new Subject<EditorRow>();
  settings: Settings = new Settings();
  noTextCount = 0;

  //View settings
  displayedColumns = ['id', 'source', 'target', 'status'];
  inlayoutview = false;
  views = ['Layout, source & target', 'Layout & target', 'Source & target'];
  currentView = this.views[2];

  errorMsg: string;
  isDebug = environment.isDebug;

  constructor(public editorService: EditorService,
              private dialog: MatDialog,
              private fileService: FileService,) {
    // The input of the user is "debounced" so the modified segment does not get sent with each keystroke, but rather
    // when the user stops typing for a moment
    this.userInput.asObservable().pipe(
      map(element => {
        if (!element.modified)
          this.updateModifiedFlag(element);
        return element
      }),
      debounceTime(500),) // wait half a second after the last event before emitting it
      .subscribe(element => {
        this.modifiedSegment(element);
        this.updateModifiedFlag(element);
        this.changeEmitter.emit(element);
        // If no more missing references, then re-focus on the text area
        // TODO: this should only be done when inserting the last reference, and not each time a sentence is saved
        // if (!element.missingRefs || element.missingRefs.length == 0)
        //   this.focusTxtArea(element);
      });
  }

  ngOnInit(): void {
    // TODO: store settings both on user and task level
    this.settings = new Settings();
    this.settings.hideAffix = true;
    this.settings.hideNoText = true;
    this.errorMsg = null;
    this.setShowShortcuts(false);
    this.initContentData();
    this.scrollUp();
    this.initEditorView();
  }

  /**
   * Set the sort, paginator and the listener for the enabled textArea after the view init.
   */
  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    // Subscribe to the "textAreas" queryList
    this.textAreas.changes.subscribe(r => {
      // When a user clicks a segment, the queryList will get notified of the change and populate the list with the selected
      // item. We need to do it this way because the textAreas are not included in the DOM due to the *ngIf statement (so
      // can't be found through document.getElementId()). Since only one segment textArea is active at one time, this
      // queryList will only ever contain 1 item.
      if (r && r instanceof QueryList) {
        // Auto-focus on the first element in the list (if any)
        let textArea = (r as QueryList<ElementRef>).first;
        if (textArea) {
          // But only if it hasn't been focused yet
          // Otherwise each change to the DOM will re-focus the textarea (not sure what triggers changes yet :-P)
          if (!this.currentTextArea || textArea.nativeElement !== this.currentTextArea.nativeElement) {
            if (this.editable)
              textArea.nativeElement.focus();
            this.currentTextArea = textArea;
            this.scrollToCurrent();
          }
        } else
          this.currentTextArea = null;
      }
    });
  }

  @ViewChild(MatSort, {static: false}) set matSort(ms: MatSort) {
    this.sort = ms;
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  @ViewChild(MatPaginator, {static: false}) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  trackById(index, row) {
    return row.id;
  }

  scrollUp() {
    window.scrollTo(0, 0);
  }

  setShowShortcuts(show: boolean) {
    this.showShortcuts = show;
  }

  setShowWhitespaces(show: boolean) {
    this.showWhitespaces = show;
  }

  loadingContent = false;

  initContentData() {
    this.loadingContent = true;
    this.editorService.getContent(this.fileLocation, this.fileGroupId).subscribe(data => {
        // console.debug("FILE " + fileLocation + " ==> \n" + JSON.stringify(data, null, 2));
        try {
          this.setContent(data);
          this.errorMsg = null;
        } catch (e) {
          this.displayError(e, "An error occurred loading the file's content.");
        }
      }, error => {
        this.displayError(error, "An error occurred retrieving the file's content.");
        this.loadingContent = false;
      },
      () => {
        this.loadingContent = false;
      });
  }

  private displayError(e, msg: string) {
    console.error("Error loading file content", e);
    this.errorMsg = msg;
  }

  setContent(data) {
    this.content = data as TransUnit[];
    this.rows = this.flattenContent();
    this.loadDataSource(this.rows);
  }

  private loadDataSource(rows: EditorRow[]) {
    this.dataSource.data = rows.filter((r, i, a) => {
      // Filter out the "no text" text segments if needed
      return !r.noText || !this.settings.hideNoText
    })
      .sort((a, b) => {
        const firstField = this.numbersOnly(a.id);
        const secondField = this.numbersOnly(b.id);
        if (firstField > 0 && secondField > 0 && firstField !== secondField) {
          return this.numbersOnly(a.id) - this.numbersOnly(b.id);
        }
        return a.id.localeCompare(b.id);
      });
  }

// Get the number out of a string and create a new number with it (only for sorting)
  private numbersOnly(a: string): number {
    let result = '';
    a.split('').map(a => Number(a))
      .filter(a => !isNaN(a))
      .forEach(element => {
        result = result + element;
      });
    if (result === '') {
      return 0;
    }
    return Number(result);
  }

  flattenContent() {
    let rows = Array<EditorRow>();
    let row: EditorRow = null;
    try {
      for (let tu of this.content) {
        for (let segId of this.getSegmentIds(tu)) {
          row = new EditorRow();
          row.tuId = tu.id;
          row.segId = segId;
          row.id = tu.id + "--" + segId;
          row.sourceSeg = this.getSegWithType(tu, "SRC", segId);
          row.refs = this.inventoriseRefs(row.sourceSeg);
          row.targetSeg = this.getSegWithType(tu, "TGT", segId);
          row.tmSeg = this.getSegWithType(tu, "TM", segId);
          row.mtSeg = this.getSegWithType(tu, "MT", segId);
          row.userSeg = this.getSegWithType(tu, "USER", segId);
          // Try and trim segment (to reduce the number of references)
          trimSegment(row.sourceSeg);
          // Only trim target and user segment if source was trimmed also
          if (row.sourceSeg.trimmedContent != undefined) {
            trimSegment(row.targetSeg);
            if (row.tmSeg != null) trimSegment(row.tmSeg);
            if (row.mtSeg != null) trimSegment(row.mtSeg);
            if (row.userSeg != null) trimSegment(row.userSeg);
          }
          row.currentSeg = this.getCurrentSegText(row);
          if (row.userSeg != null) this.updateModifiedFlag(row);
          if (!row.modified) {
            // Determine missing refs
            let segmentContent = this.getCurrentSeg(row).content;
            let missingRefs = this.checkRefs(segmentContent, row.refs);
            this.setMissingRefs(row, missingRefs);
          }
          // Flag no text segments so we can filter them
          if (isNoText(row.sourceSeg.content)) {
            this.noTextCount++;
            row.noText = true;
          }
          rows.push(row);
        }
      }
      return rows;
    } catch (e) {
      if (row !== null) console.error("Error occurred while handling row " + row.id, row);
      throw e;
    }
  }

  private getCurrentSegText(row: EditorRow) {
    return this.getTextFromSeg(this.getCurrentSeg(row), row.refs);
  }

  private getCurrentSeg(row: EditorRow) {
    if (row.userSeg != null) {
      return row.userSeg;
    } else if (row.targetSeg != null) {
      return row.targetSeg;
    } else return row.sourceSeg;
  }

  getSegmentIds(tu: TransUnit) {
    let ids = Array<string>();
    for (let seg of tu.segments) {
      ids.push(seg.id);
    }
    // Filter out doubles
    return ids.filter(function (item, i, ar) {
      return ar.indexOf(item) === i;
    });
  }

  inventoriseRefs(sourceSeg: Segment) {
    let refArray: Reference[] = [];
    for (let o of sourceSeg.content) {
      if (o) {
        if (o.className && o.className.indexOf('Reference') == 0) {
          let ref = o as Reference;
          refArray.push(ref);
        }
      }
    }
    return refArray;
  }

  filter(refs: Reference[]) {
    // TODO: filter further and only show refs missing in segment
    var tmp = [];
    var filtered = [];
    // Filter out doubles
    for (var i = 0; i < refs.length; i++) {
      let jsonRef = JSON.stringify(refs[i]);
      if (tmp.indexOf(jsonRef) == -1) {
        tmp.push(jsonRef);
        filtered.push(refs[i]);
      }
    }
    return filtered;
  }

  private getSegTextWithType(tu: TransUnit, type: string, segId: string, refs: Reference[]): string {
    let seg = this.getSegWithType(tu, type, segId);
    if (seg != null) {
      return this.getTextFromSeg(seg, refs);
    } else return null;
  }

  private getSegWithType(tu: TransUnit, type: string, segId: string): Segment {
    for (let seg of tu.segments) {
      if (seg.type === type && seg.id === segId) {
        return seg;
      }
    }
    return null;
  }

  private getTextFromSeg(seg: Segment, refs: Reference[]) {
    if (seg) {
      let c = this.settings.hideAffix && seg.trimmedContent != undefined ? seg.trimmedContent : seg.content;
      return segContentToText(c, refs);
    } else
      return null;
  }

  prepForDisplay(text: string) {
    let display = text;
    if (this.showWhitespaces) {
      display = visualizeFormattingMarks(text);
    }
    // If completely empty at least put a space, that way the div will expand properly and be clickable
    if (display.length == 0) display = " ";
    return display;
  }

  toSymbol(ref: Reference, refs: Reference[]): string {
    // (Added this method because we can't use the exported method directly in the HTML)
    return toSymbol(ref, refs);
  }

  onSelect(element: EditorRow, scrollTo: boolean) {
    // If there was no previous input and the source segment equals the target one, we want to automatically empty the
    // textarea so the user doesn't need to clear it manually each time
    let prevRow = this.clickedRow;
    if (prevRow && !prevRow.modified && this.isTargetEqualToSource(prevRow)) {
      // If not modified, we first restore the "target segment" as "current segment" when the user switches to another row
      prevRow.currentSeg = this.getTextFromSeg(prevRow.targetSeg, prevRow.refs);
    }
    // Switch to the new row
    this.clickedRow = element;
    if (this.clickedRow) {
      // If not modified, initialize the current row
      if (!this.clickedRow.modified) {
        this.initSelectedRow(this.clickedRow);
      }
      // Scroll to "current" to make sure the "active" one stays in view if we navigate to the previous or next textarea
      // The below actually scrolls to the previously selected text area, but this works well enough for our purposes
      if (scrollTo)
        this.scrollToCurrent();
    }
  }

  private initSelectedRow(clickedRow: EditorRow) {
    let curSegContent = [];
    // Empty the "current segment" if target is equal to source
    if (this.isTargetEqualToSource(this.clickedRow)) {
      clickedRow.currentSeg = "";
      curSegContent = [];
    } else curSegContent = this.getCurrentSeg(clickedRow).content;
    if (this.settings.hideAffix)
      curSegContent = this.appendAffix(curSegContent, clickedRow.sourceSeg.prefix, clickedRow.sourceSeg.suffix);
    // Determine the missing refs
    let missingRefs = this.checkRefs(curSegContent, clickedRow.refs);
    this.setMissingRefs(clickedRow, missingRefs);
    this.initRepeatedMatch(clickedRow);
  }

  private initRepeatedMatch(clickedRow: EditorRow) {
    // Determine whether there is a relevant repeated match
    // To do so, the editor service:
    // - connects to the project content repository to get this segment's repeated matches
    // - checks whether any have a user-specified translation (cross-file within the task)
    // - returns the one for the repeated match the highest internal match score for this segment, giving preference to internal matches
    this.editorService.getBestRepeatedMatch(this.fileLocation, this.fileGroupId, this.projectId,
      clickedRow.tuId, clickedRow.segId)
      .subscribe(data => {
        // TODO: optimize the repeated match retrieval as it might not be fast enough:
        // - maybe, when the segments are loaded, check in the background (async) whether or not they at least have a rep, so we know in advance for which to execute this code
        // - or keep track internally of the same-file matches and update them client-side (so we only need to retrieve the rep data once)
        if (data) {
          // console.debug("FILE " + this.taskFile.filelocation + " [" + clickedRow.tuId + "/" + clickedRow.segId + "] ==> \n" +
          //    "" + JSON.stringify(data, null, 2));
          // Check whether the clickedRow is still the one open, as there might be a slight delay in getting the repeated match
          // and the user might have clicked away
          if (data.currentTuId === clickedRow.tuId && data.segment.id === clickedRow.segId) {
            clickedRow.repeatedMatch = data.segment;
            // Trim the segment, to get rid of surrounding references
            trimSegment(clickedRow.repeatedMatch);
          }
        } else {
          // console.debug("FILE " + this.taskFile.filelocation + " [" + clickedRow.tuId + "/" + clickedRow.segId + "] ==> no repeated matches found");
        }
      }, error => {
        console.error("Error retrieving 'best repeated match' for " + this.clickedRow.tuId + "--" + this.clickedRow.segId, error);
      });
  }

  getRepInfo(element: EditorRow) {
    if (element.repeatedMatch) {
      return "This segment is a " + element.repeatedMatch.score + "% match of segment " + element.repeatedMatch.origin.tuid + "--" +
        element.repeatedMatch.origin.segid + (this.isSameFile(element) ? "" : " in file " + element.repeatedMatch.origin.file) + ".";
    }
    return "";
  }

  isSameFile(element: EditorRow) {
    return this.fileLocation === element.repeatedMatch.origin.fileloc;
  }

  isClickedRow(element: EditorRow) {
    return this.clickedRow && this.clickedRow.id === element.id;
  }

  getTextColor(element: EditorRow) {
    return element.modified ?
      (element.missingRefs != null && element.missingRefs.length > 0 ? 'red' : 'green') : 'black';
  }

  updateCurrentSegTexts() {
    for (let row of this.rows) {
      // If the source was trimmed to begin with
      if (row.currentSeg && row.sourceSeg.trimmedContent != undefined) {
        let text = "";
        let prefix = segContentToText(row.sourceSeg.prefix, row.refs);
        let suffix = segContentToText(row.sourceSeg.suffix, row.refs);
        if (this.settings.hideAffix) {
          // Remove the affixes if they should be hidden
          text = row.currentSeg;
          if (text.startsWith(prefix))
            text = text.substring(prefix.length);
          if (text.endsWith(suffix))
            text = text.substring(0, text.length - suffix.length);
        } else {
          // Add the affixes if they shouldn't be hidden
          text += prefix + row.currentSeg + suffix;
        }
        row.currentSeg = text;
      }
    }
  }

  segmentChanged(element: EditorRow) {
    // Send user input through "subject" so it gets "debounced"
    // Otherwise, the input is sent with EACH KEYSTROKE
    this.userInput.next(element);
  }

  focusOut(element: EditorRow, pos: number) {
    // Keep track of what position the cursor was in when the text area lost focus
    // This way, if the user inserts a reference, we can do it at that position
    element.lastPos = pos;
  }

  focusMissingRefList() {
    if (this.missingRefList) {
      // Auto-focus on the first element in the list (if any)
      if (this.missingRefList.first)
        this.missingRefList.first.nativeElement.focus();
    }
  }

  focusTxtArea(element: EditorRow) {
    if (this.textAreas) {
      // Auto-focus on the first element in the list (if any)
      if (this.textAreas.first && this.editable) {
        if (element.lastPos != undefined) {
          this.textAreas.first.nativeElement.selectionStart = element.lastPos;
          this.textAreas.first.nativeElement.selectionEnd = element.lastPos;
        }
        this.textAreas.first.nativeElement.focus();
      }
    }
  }

  scrollToCurrent() {
    if (this.textAreas) {
      // Scroll to the current text area
      if (this.textAreas.first) {
        this.textAreas.first.nativeElement.scrollIntoView({
          behavior: 'auto',
          block: 'center',
          inline: 'center'
        });
      }
    }
  }

  insertFirstMissingRef(element: EditorRow, txtArea: any) {
    if (this.refToInsert != null) {
      let txt = element.currentSeg;
      let refSymbol = toSymbol(this.refToInsert, element.refs);
      if (txtArea != null && txtArea.selectionStart != undefined) {
        let pos = txtArea.selectionStart;
        let newText = txt.substring(0, txtArea.selectionStart);
        newText += refSymbol;
        newText += txt.substring(txtArea.selectionEnd);
        element.currentSeg = newText;
        // Offset the "last position" with the inserted symbol
        element.lastPos = pos + refSymbol.length;
        txtArea.focus();
        // todo: the below should set the cursor back to where it was, but it doesn't work :(
        txtArea.setSelectionRange(element.lastPos, element.lastPos, 'none');
      } else {
        // If no position, just shove it in the back
        element.currentSeg += refSymbol;
      }
      // Since the above does not seem to trigger an ngModelChange, we do the segment update manually
      this.segmentChanged(element);
    }
  }

  insertMissingRef(element: EditorRow) {
    let txt = element.currentSeg;
    let refSymbol = toSymbol(this.refToInsert, element.refs);
    if (element.lastPos != undefined) {
      let newText = txt.substring(0, element.lastPos);
      newText += refSymbol;
      newText += txt.substring((element.lastPos));
      element.currentSeg = newText;
      // Offset the "last position" with the inserted symbol
      element.lastPos += refSymbol.length;
    } else {
      // If no "last position", just shove it in the back
      element.currentSeg += refSymbol;
    }
    // Since the above does not seem to trigger an ngModelChange, we do the segment update manually
    this.segmentChanged(element);
    // If no more missing refs, re-focus on the text area
    // ==> Due to the 'debounce' when storing segments, the missingRefs won't be updated at this point, so the txtArea won't be focused
    if (!element.missingRefs || element.missingRefs.length == 0)
      this.focusTxtArea(element);
  }

  copyMissingRef(refs: Reference[]) {
    var txtArea: any;
    try {
      // Create fake text area
      txtArea = document.createElement("textarea");
      txtArea.id = 'txt';
      txtArea.style.position = 'fixed';
      txtArea.style.top = '0';
      txtArea.style.left = '0';
      txtArea.style.opacity = '0';
      // Set text to copy as its value
      txtArea.value = toSymbol(this.refToInsert, refs);
      // Add it to the DOM
      document.body.appendChild(txtArea);
      txtArea.select();
      // Perform the copy-to-clipboard command
      var successful = document.execCommand('copy');
    } catch (err) {
      console.warn("Oops, unable to copy reference to clipboard", err);
    } finally {
      // Remove the fake text area from the DOM again
      if (txtArea)
        document.body.removeChild(txtArea);
    }
  }

  modifiedSegment(element: EditorRow) {
    let segmentContent = textToSegContent(element.currentSeg, element.refs);
    if (this.settings.hideAffix)
      segmentContent = this.appendAffix(segmentContent, element.sourceSeg.prefix, element.sourceSeg.suffix);
    let missingRefs = this.checkRefs(segmentContent, element.refs);
    this.setMissingRefs(element, missingRefs);
    // If there are missing refs, don't save
    if (!missingRefs || missingRefs.length == 0) {
      // console.log("segment => \"" + element.currentSeg + "\" => [" + segmentContent + "]");
      return this.editorService
        .postModifiedSegment(this.fileLocation, this.fileGroupId, element.tuId, element.segId, segmentContent)
        .subscribe();
    }
  }

  private setMissingRefs(element: EditorRow, missingRefs) {
    if (missingRefs && missingRefs.length > 0) {
      element.missingRefs = missingRefs;
      this.refToInsert = element.missingRefs[0];
    } else {
      element.missingRefs = null;
      this.refToInsert = null;
    }
  }

  checkRefs(content: any[], refs: Reference[]) {
    if (content == undefined || content.length == 0)
      return refs;
    // TODO: also check whether the count is correct (whether there are not too many?)
    let missingRefs: Reference[] = [];
    let refIndexMap: Map<string, number> = new Map<string, number>();
    for (let ref of refs) {
      let refIdx = -1;
      let refString = JSON.stringify(ref);
      // Get starting position of search
      let idx;
      // Check whether there already is a previous index, and whether the end of the content has already been reached
      if (refIndexMap.get(refString) != undefined) {
        if (refIndexMap.get(refString) < content.length) {
          // Add 1 to the previous index, so we start with the next element
          idx = refIndexMap.get(refString) + 1;
        } else idx = -1;
      } else idx = 0;
      if (idx >= 0) {
        let found = false;
        // Check at what index in the content the reference occurs, starting from the given index
        // (The get() from the map doesn't work, as the objects in the map are not "the same" as the ones in the content
        // array, so we manually loop through it)
        while (idx < content.length && !found) {
          let c = content[idx];
          if (isRef(c) && refString == JSON.stringify(c)) {
            found = true;
          } else idx++;
        }
        if (found) refIdx = idx;
      }
      if (refIdx >= 0) {
        // Keep track of the index where we last found this symbol, and search again next from this index
        // This is to make sure we account for all occurrences
        refIndexMap.set(refString, refIdx);
      } else {
        // If not found, store it in the "missing reference" array
        missingRefs.push(ref);
      }
    }
    return missingRefs;
  }

  private getIndexOfRef(ref: any, refMap: Map<any, number>, content: any[]): number {
    let idx: number;
    // Keep track of the index where we last found this reference, and search again next from this index
    // This is to make sure we account for all occurrences
    if (refMap.get(ref) != undefined) {
      // Explicit check on when the end of the text is reached, as indexOf would return 0 (iso the expected -1)
      if (refMap.get(ref) < content.length)
        idx = content.indexOf(ref, (refMap.get(ref) + 1));
      else idx = -1;
    } else
      idx = content.indexOf(ref);
    return idx;
  }

  private appendAffix(content: any[], prefix: any[], suffix: any[]) {
    let contentArray: any[] = [];
    if (prefix != undefined) {
      for (let i of prefix)
        contentArray.push(i);
    }
    for (let i of content)
      contentArray.push(i);
    if (suffix != undefined) {
      for (let i of suffix)
        contentArray.push(i);
    }
    // Now, run through the content again and "merge" subsequent strings
    // If we don't, we might run into issues in the backend when trying to force the affixes of the source on the user segment
    let mergedArray: any[] = [];
    let currentItem = null;
    for (let i of contentArray) {
      if (typeof i === 'string' || i instanceof String) {
        if (currentItem == null) currentItem = i;
        else currentItem += i;
      } else {
        if (currentItem != null && currentItem.length > 0)
          mergedArray.push(currentItem);
        mergedArray.push(i);
        currentItem = null;
      }
    }
    if (currentItem != null && currentItem.length > 0)
      mergedArray.push(currentItem);
    return mergedArray;
  }

  updateModifiedFlag(element: EditorRow) {
    element.modified = this.isModified(element);
  }

  private isModified(element: EditorRow) {
    return (element.currentSeg != undefined && element.currentSeg !== this.getTextFromSeg(element.targetSeg, element.refs));
  }

  private isTargetEqualToSource(element: EditorRow) {
    return this.getTextFromSeg(element.sourceSeg, element.refs) === this.getTextFromSeg(element.targetSeg, element.refs);
  }

  copyToCurrentSeg(element: EditorRow, seg: Segment) {
    if (seg) {
      element.currentSeg = this.getTextFromSeg(seg, element.refs);
      // Since the above does not seem to trigger an ngModelChange, we do the segment update manually
      this.segmentChanged(element);
    }
  }

  moveToNext(element: EditorRow) {
    try {
      // Retrieves the "as rendered" by the table (=sorted, filtered and paginated)
      let renderedData = this.dataSource.connect().value;
      // Determine index of current element
      let currentIdx = renderedData.indexOf(element);
      // Get next element
      let next = renderedData.find((item, idx) => {
        return idx === (currentIdx + 1);
      });
      // And select it
      if (next)
        this.onSelect(next, true);
    } catch (e) {
      console.error('Editor.moveToNext error: ' + e.toString());
    }
  }

  moveToPrev(element: EditorRow) {
    try {
      // Retrieves the "as rendered" by the table (=sorted, filtered and paginated)
      let renderedData = this.dataSource.connect().value;
      // Determine index of current element
      let currentIdx = renderedData.indexOf(element);
      if (currentIdx > 0) {
        // Get next element
        let prev = renderedData.find((item, idx) => {
          return idx === (currentIdx - 1);
        });
        // And select it
        if (prev)
          this.onSelect(prev, true);
      }
    } catch (e) {
      console.error('Editor.moveToPrev error: ' + e.toString());
    }
  }

  translateWithGoogle(element: EditorRow) {
    let sourceText = this.getTextFromSeg(element.sourceSeg, undefined);
    this.editorService.translateWithGoogle(this.sourceLang, this.targetLang, sourceText)
      .subscribe(data => {
        console.debug("G-TRANSLATED '" + sourceText + "' ==> \n" + JSON.stringify(data, null, 2));
      });
  }

  getTotalCount() {
    if (this.rows)
      return this.rows.length;
    else return 0;
  }

  getProgressCount() {
    if (this.rows) {
      let modifiedCount = 0;
      for (let row of this.rows) {
        if (row.modified)
          modifiedCount++;
      }
      return modifiedCount;
    } else return 0;
  }


  openRefHelp() {
    let helpText = `
        <p>This warning indicates the translation you entered is lacking some references. References represent elements of
        the text that need to be placed in the translation. These can be markup, like pieces of a text that is in a different
        font, or fields, like a variable name. We need your help to put the references in the correct place in the translated
        sentence.</p>
        <p>You can enter the references by placing the cursor on the position you want to insert it and then select the
        right reference in the drop down box, then click the "Add" button next to it to insert the reference.</p>
        <p>Save time by keeping your hands on the keyboard, when you reach a place where you want to insert a reference, press
        CTRL+ALT+0 to switch from the text area to the drop down list and use the arrows up and down to select the right
        reference, and then insert it by pressing the enter key.</p>
        <p>More information about what the reference entails can be deduced from the source file, which you can
        download from the task screen.</p>
    `;
    let dialogRef = this.dialog.open(HelpDialogComponent, {
      width: '700px',
      height: '500px',
      data: {helpTitle: "What is a reference?", helpHtmlContent: helpText}
    });
  }

  openSettings() {
    let dialogRef = this.dialog.open(EditorSettingsComponent, {
      width: '500px',
      height: '50%',
      data: {hideAffix: this.settings.hideAffix, hideNoText: this.settings.hideNoText}
    });
    dialogRef.afterClosed().subscribe(r => {
      if (r === "confirm") {
        if (this.settings.hideAffix != dialogRef.componentInstance.hideAffix) {
          this.settings.hideAffix = dialogRef.componentInstance.hideAffix;
          this.updateCurrentSegTexts();
        }
        if (this.settings.hideNoText != dialogRef.componentInstance.hideNoText) {
          this.settings.hideNoText = dialogRef.componentInstance.hideNoText;
          this.loadDataSource(this.rows);
        }
      }
    });
  }

  initEditorView() {
    //Fetch from local storage
    let editorView = localStorage.getItem("editorView");
    if (editorView != null) {
      this.currentView = editorView;
    }
    this.fileService.isSegHtmlAvailable(this.projectId, this.fileName).subscribe(f => {
      if (!f) {
        this.views = ['Source & target'];
        this.currentView = 'Source & target';
      }
      this.changeEditorView();
    });
  }

  //todo create a cleaner method to set the view (especially when more options become available)
  changeEditorView() {
    this.inlayoutview = this.currentView.toLowerCase().indexOf("layout") >= 0;
    if (this.currentView.toLowerCase().indexOf("source") >= 0) {
      if (this.inlayoutview) {
        this.displayedColumns = ['source', 'target', 'status'];
      } else {
        this.displayedColumns = ['id', 'source', 'target', 'status'];
      }
    } else {
      this.displayedColumns = ['target', 'status'];
    }
    localStorage.setItem("editorView", this.currentView);
  }
}

export class Settings {
  hideAffix: boolean;
  hideNoText: boolean;
}


