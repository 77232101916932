import {Component, Input} from "@angular/core";
import {UserService} from "../service/user.service";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'password-settings',
  template:
    '<div *ngIf="user">' +
    '<h1>Change password</h1>' +
    '<form>' +
    '<div class="row">' +
    '<p>Please enter your current and your new password.</p>' +
    '</div>' +
    '<div class="row">' +
    '<label>Current password: </label><input type="password" name="currentPw" [(ngModel)]="currentPw"/>' +
    '</div>' +
    '<div class="row">' +
    '<label>New password: </label><input type="password" name="password" [(ngModel)]="password"/>' +
    '</div>' +
    '<div class="row">' +
    '<label>Confirm new password: </label><input type="password" name="conformPassword" [(ngModel)]="confirmPassword"/>' +
    '</div>' +
    '<div class="row">' +
    '<button type="submit" *ngIf="checkPasswordEqual()" (click)="updatePassword()">Save</button>' +
    '</div>' +
    '<div class="row" *ngIf="isPasswordWrong()"><p>Password not updated, please verify if everything is filled in correctly.</p></div>' +
    '</form>' +
    '</div>',
  styleUrls: ['./user.settings.component.css']
})
export class PasswordSettingComponent {
  @Input()
  public user;
  public currentPw;
  public password;
  public confirmPassword;

  private wrongPassword = false;

  constructor(private userService: UserService,
              private snackbar: MatSnackBar,) {

  }

  public updatePassword(): void {
    if (this.user && this.checkPasswordEqual())
      this.userService.updateCurrentPassword(this.user.id, this.currentPw, this.confirmPassword).subscribe(
        next => {
          if (next == "success") {
            this.snackbar.open("Password successfully updated", '', {
              duration: 2000
            });
          }
        },
        error => {
          console.error("Error updating password", error);
          this.wrongPassword = true;
        }
      )
  }

  public checkPasswordEqual(): boolean {
    return this.password && this.password.length > 0 && this.password == this.confirmPassword;
  }

  public isPasswordWrong(): boolean {
    return this.wrongPassword;
  }
}
