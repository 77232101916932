<div class="chat">
  <div *ngIf="!user">
    Please log in to be able to send and read messages.
  </div>
  <div *ngIf="user" class="messagesDiv" id="messagesDiv">
    <div *ngIf="messages && messages.length > 0">
      <message *ngFor="let message of messages"
               [message]="message" [user]="user" [isThread]="true"></message>
    </div>
    <message
      *ngIf="newMessage != null"
      [message]="newMessage" [user]="user" [isThread]="true"></message>
    <div *ngIf="(messages == undefined || messages.length == 0) && newMessage == null">
      <p>No messages yet.</p>
    </div>
  </div>
  <div class="newMessageDiv" *ngIf="user">
    <new-message [placeholderText]="'Your message...'" [user]="getUser()"
                 (sendEmitter)="addNewMessage($event)"></new-message>
  </div>
</div>
