/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../project.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./langpair-target-files.component.ngfactory";
import * as i4 from "./langpair-target-files.component";
import * as i5 from "../../service/file.service";
import * as i6 from "@angular/material/dialog";
import * as i7 from "@angular/common/http";
import * as i8 from "../../util/language.pipe";
import * as i9 from "../../service/language.service";
import * as i10 from "./target-file-list.component";
import * as i11 from "../../service/project.service";
var styles_TargetFileListComponent = [i0.styles];
var RenderType_TargetFileListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TargetFileListComponent, data: {} });
export { RenderType_TargetFileListComponent as RenderType_TargetFileListComponent };
function View_TargetFileListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["alt", "toggle tile view"], ["src", "../../../assets/icon_tiles_active.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", "toggle list view"], ["src", "../../../assets/icon_list_not_active.svg"], ["style", "cursor: pointer;"], ["title", "Switch to list view"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setTileView(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
function View_TargetFileListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["alt", "toggle tile view"], ["src", "../../../assets/icon_tiles_not_active.svg"], ["style", "cursor: pointer;"], ["title", "Switch to tile view"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setTileView(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", "toggle list view"], ["src", "../../../assets/icon_list_active.svg"]], null, null, null, null, null))], null, null); }
function View_TargetFileListComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "fileListRow targetFileListLang"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "span", [["class", "fileListRowValue"], ["style", "margin-bottom: 15px;"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵppd(3, 1), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 1, "langpair-target-files", [], null, null, null, i3.View_LangpairTargetFilesComponent_0, i3.RenderType_LangpairTargetFilesComponent)), i1.ɵdid(6, 638976, null, 0, i4.LangpairTargetFilesComponent, [i5.FileService, i6.MatDialog, i7.HttpClient], { project: [0, "project"], langPair: [1, "langPair"], viewAsTiles: [2, "viewAsTiles"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.project; var currVal_2 = _v.context.$implicit; var currVal_3 = _co.isTileView; _ck(_v, 6, 0, currVal_1, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 4).transform(i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent, 0), _v.context.$implicit.target)))); _ck(_v, 2, 0, currVal_0); }); }
function View_TargetFileListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "fileListHeader"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "fileListHeaderValue"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Files"])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "targetFileListRows"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TargetFileListComponent_4)), i1.ɵdid(6, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getLangPairs(); _ck(_v, 6, 0, currVal_0); }, null); }
function View_TargetFileListComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "fileListRow"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "fileListLanguageColumn fileListRowValue"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵppd(3, 1), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "fileListFilesColumn fileListRowValue"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "langpair-target-files", [], null, null, null, i3.View_LangpairTargetFilesComponent_0, i3.RenderType_LangpairTargetFilesComponent)), i1.ɵdid(7, 638976, null, 0, i4.LangpairTargetFilesComponent, [i5.FileService, i6.MatDialog, i7.HttpClient], { project: [0, "project"], langPair: [1, "langPair"], viewAsTiles: [2, "viewAsTiles"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.project; var currVal_2 = _v.context.$implicit; var currVal_3 = _co.isTileView; _ck(_v, 7, 0, currVal_1, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 4).transform(i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent, 0), _v.context.$implicit.target)))); _ck(_v, 2, 0, currVal_0); }); }
function View_TargetFileListComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "fileListHeader"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "fileListLanguageColumn fileListHeaderValue"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Language"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "fileListFilesColumn fileListHeaderValue"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Files"])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "targetFileListRows"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TargetFileListComponent_6)), i1.ɵdid(8, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getLangPairs(); _ck(_v, 8, 0, currVal_0); }, null); }
export function View_TargetFileListComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i8.LanguagePipe, [i9.LanguageService]), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "targetFileList"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "listTileViewToggle"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TargetFileListComponent_1)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TargetFileListComponent_2)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TargetFileListComponent_3)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TargetFileListComponent_5)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isTileView; _ck(_v, 4, 0, currVal_0); var currVal_1 = !_co.isTileView; _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.isTileView; _ck(_v, 8, 0, currVal_2); var currVal_3 = !_co.isTileView; _ck(_v, 10, 0, currVal_3); }, null); }
export function View_TargetFileListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "target-file-list", [], null, null, null, View_TargetFileListComponent_0, RenderType_TargetFileListComponent)), i1.ɵdid(1, 114688, null, 0, i10.TargetFileListComponent, [i11.ProjectService, i5.FileService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TargetFileListComponentNgFactory = i1.ɵccf("target-file-list", i10.TargetFileListComponent, View_TargetFileListComponent_Host_0, { project: "project" }, {}, []);
export { TargetFileListComponentNgFactory as TargetFileListComponentNgFactory };
