import { empty as observableEmpty, merge as observableMerge, Observable, Subject } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';
import { EventEmitter } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";
import { Md5 } from "ts-md5/dist/md5";
import { HttpClient, HttpParams } from "@angular/common/http";
import { getBaseUrl } from "../util/httpclient";
import { environment } from "../../environments/environment";
import { CookieService } from 'ngx-cookie-service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/platform-browser";
import * as i3 from "ngx-cookie-service";
export class UserService {
    constructor(http, sanitizer, cookieService) {
        this.http = http;
        this.sanitizer = sanitizer;
        this.cookieService = cookieService;
        this.url = getBaseUrl();
        this.USER_API = this.url + "/api/v1/users";
        this.FILES_API = this.url + "/api/v1/files";
        this.COMPANY_API = this.url + "/api/v1/companies";
        this.CUSTGROUP_API = this.url + "/api/v1/custgroups";
        //Instead of using a method, it seems that Angular works better on changes on a variable
        this.isCurrentUserTranslator = false;
        this.isCurrentUserInternalTranslator = false;
        this.isCurrentUserAdmin = false;
        this.loginEmitter = new EventEmitter();
        this.userEmitter = new EventEmitter();
        // To propagate changes to for example the mugshot component
        this.userUpdatedSubj = new Subject();
        this.userUpdated$ = this.userUpdatedSubj.asObservable();
        console.debug("UserService initializing => connects to " + this.USER_API);
        this.signInFromLocalStorage().subscribe(u => {
            this.currentUser = u;
            this.userEmitter.emit(u);
        });
        //Init listener on login for tranlsator boolean
        this.loginEmitter.subscribe(v => {
            this.userEmitter.emit(this.currentUser);
            this.updateIsTranslator();
            this.updateIsAdmin();
            this.updateCurrency();
        });
        this.updateIsTranslator();
        this.updateIsAdmin();
        this.updateCurrency();
        this.getRemoteUserIp();
    }
    //TODO sign in from local storage should still fetch the db object and store the latest value
    signInFromLocalStorage() {
        let localStorageUser = localStorage.getItem("user");
        if (localStorageUser != null && localStorageUser.toString().length > 0) {
            let localStorageUserJ = JSON.parse(localStorageUser);
            return this.http.get(this.USER_API + "/auth/" + localStorageUserJ.id, { responseType: "json" }).pipe(map(r => {
                if (r) {
                    this.currentUser = r;
                    this.setLoggedInUser(r);
                    return this.currentUser;
                }
            }));
        }
        return observableEmpty();
    }
    getCurrentUser() {
        return this.currentUser;
    }
    updateCurrentUser(user) {
        if (this.isCurrentUser(user))
            this.currentUser = user;
    }
    isLoggedIn() {
        return this.getCurrentUser() != undefined;
    }
    isCurrentUser(user) {
        return this.isCurrentUserId(user.id);
    }
    isCurrentUserId(userId) {
        return this.currentUser != undefined && userId != undefined && this.currentUser.id === userId;
    }
    getUser(id) {
        return this.http.get(this.USER_API + "/find/" + id);
    }
    getRemoteUserIp() {
        if (this.remoteUserIp == null) {
            // Using JSONP to avoid CORS issues with contacting 3rd party API
            this.http.jsonp('https://api.ipify.org?format=jsonp', 'callback')
                .subscribe((res) => {
                let j = JSON.parse(JSON.stringify(res));
                this.remoteUserIp = j.ip;
                console.log("UserService => detected IP = " + this.remoteUserIp);
            }, error => {
                console.log("Error retrieving user IP", error);
            });
        }
        return this.remoteUserIp;
    }
    signInWithAuth(data) {
        return this.http.post(this.USER_API + "/signin/oauth", JSON.stringify(data), { responseType: 'json' }).pipe(map(signInData => {
            this.setLoggedInUser(signInData);
            return signInData;
        }));
    }
    signInWithMail(email, password) {
        if (email && password) {
            let data = "{'email':'" + email + "', 'password':'" + UserService.encryptPassword(password) + "'}";
            return this.http.post(this.USER_API + "/signin/mail", data, { responseType: 'json' }).pipe(map(signInData => signInData), map(signInData => {
                if (signInData && signInData.id) {
                    this.setLoggedInUser(signInData);
                    return signInData;
                }
                else {
                    throw new Error("Login failed");
                }
            }));
        }
        else if (!email) {
            throw new Error("No email address specified.");
        }
        else if (!password) {
            throw new Error("No password specified.");
        }
        else
            throw new Error("Login failed");
    }
    resetPassword(email) {
        let data = "{'email':'" + email + "'}";
        return this.http.post(this.USER_API + "/resetpassword", data, { responseType: 'text' });
    }
    joinWithAuth2(data) {
        return this.http.post(this.USER_API + "/join", JSON.stringify(data), { responseType: 'json' }).pipe(map(signInData => {
            this.setLoggedInUser(signInData);
            return signInData;
        }));
    }
    updateLoggedInUser(data) {
        // TODO: "refresh" it from the server rather than re-use the given object?
        if (data.id && this.currentUser && data.id == this.currentUser.id) {
            this.setLoggedInUser(data);
        }
    }
    setLoggedInUser(data) {
        localStorage.setItem("user", JSON.stringify(data));
        sessionStorage.setItem("user", JSON.stringify(data));
        let expireDate = new Date();
        expireDate.setMonth(expireDate.getMonth() + 3);
        //This cookie is used for hubspot to know if they need to redirect when you're on the landing page and if they are a translator or customer.
        //Be warned that changing this values might break the hubspot redirects
        this.cookieService.set("lilousertype", data.isTranslator, expireDate, undefined, "lilo.global");
        this.currentUser = data;
        this.loginEmitter.emit(true);
        this.userUpdatedSubj.next(data);
    }
    reloadUser() {
        if (this.currentUser != null) {
            this.getUser(this.currentUser.id).subscribe(u => {
                this.currentUser = u;
                this.userUpdatedSubj.next(this.currentUser);
            });
        }
    }
    signOut() {
        let user = this.currentUser;
        if (user == undefined)
            user = JSON.parse(localStorage.getItem("user"));
        localStorage.removeItem("user");
        this.currentUser = null;
        this.loginEmitter.emit(false);
        return this.http.post(this.USER_API + "/signout/" + user.id, "", { responseType: 'text' }).pipe(finalize(user = null));
    }
    authLinkedIn(code) {
        return this.http.get(this.USER_API + "/auth/linkedin/" + code);
    }
    linkedInProfile(token) {
        let httpParams = new HttpParams().set("token", token);
        return this.http.get(this.USER_API + "/linkedin/profile", { params: httpParams });
    }
    openLinkedInAuth() {
        let callbackURI = encodeURIComponent(environment.callbackURI);
        return window.open("https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=86ouotrrf9uueg&redirect_uri=" + callbackURI + "&state=fooobar" +
            "&scope=r_liteprofile%20r_emailaddress", "LINKEDIN", "width=500,height=500");
    }
    //getProfilePic is implemented in user.service, if not all components which would need to show the user profile would also require the file.service
    getProfilePic(id) {
        return this.http.get(this.FILES_API + "/users/profile/" + id, { responseType: "blob" }).pipe(map(blob => {
            let urlCreator = window.URL;
            return this.sanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(blob));
        }));
    }
    getProfileColor(id) {
        return this.http.get(this.USER_API + "/profilecolor/" + id, { responseType: 'text' });
    }
    updateUserData(user) {
        return this.http.post(this.USER_API + "/update/profile/" + user.id, user, { responseType: 'text' }).pipe(map(u => {
            this.updateLoggedInUser(user);
            return u;
        }));
    }
    updateAcceptedGTAC(version) {
        if (version != null) {
            this.cookieService.set('lilogtac', JSON.stringify(version));
        }
        else {
            this.cookieService.delete('lilogtac');
        }
        const user = this.getCurrentUser();
        if (user != null) {
            user.acceptedGTAC = { version: version };
            return this.http.post(this.USER_API + '/update/gtac/' + user.id, JSON.stringify(version), { responseType: 'text' });
        }
        else {
            return Observable.empty();
        }
    }
    updateCompetences(user) {
        // Merge 2 webservice calls in one observable to update the domain and the competences
        // Note that this means that subsequent functions are executed PER result from any of the merged observables:
        // in this case, it means the 'updateLoggedInUser' gets executed twice
        return observableMerge(this.http.post(this.USER_API + "/update/domains/" + user.id, user.domains, { responseType: 'text' }), this.http.post(this.USER_API + "/update/competences/" + user.id, user.competences, { responseType: 'text' })).pipe(map(u => {
            this.updateLoggedInUser(user);
            return u;
        }));
    }
    updatePercentageSet(user) {
        return this.http.post(this.USER_API + "/update/percentageset/" + user.id, user.percentageSet, { responseType: 'text' }).pipe(map(u => {
            this.updateLoggedInUser(user);
            return u;
        }));
    }
    updateSwornPricing(user) {
        return this.http.post(this.USER_API + '/update/swornpricing/' + user.id, { swornSurplus: user.swornSurplus, postalSurplus: user.postalSurplus }, { responseType: 'text' });
    }
    createCompanyInfo(company, userToUpdate) {
        return this.http.post(this.COMPANY_API + "/insert", company, { responseType: 'text' }).pipe(map(r => {
            return r;
        }), tap(id => {
            if (userToUpdate) {
                userToUpdate.companyId = id;
                userToUpdate.companyName = company.name;
                this.updateUserCompanyInfo(userToUpdate).subscribe();
            }
        }));
    }
    updateCompanyInfo(company) {
        return this.http.post(this.COMPANY_API + "/update/" + company.id, company, { responseType: 'text' });
    }
    findCompanyInfo(id) {
        return this.http.get(this.COMPANY_API + "/find/" + id);
    }
    findCompanyInfoByToken(token) {
        return this.http.get(this.COMPANY_API + "/findbytoken/" + token);
    }
    findCustomerGroups() {
        return this.http.get(this.CUSTGROUP_API + '/findall');
    }
    createCustomerGroup(custGroup) {
        return this.http.post(this.CUSTGROUP_API + "/insert", custGroup, { responseType: 'text' });
    }
    findColleagues() {
        if (this.currentUser === undefined) {
            return Observable.empty();
        }
        return this.http.get(this.USER_API + '/findcolleagues/' + this.currentUser.id);
    }
    findDefaultShares() {
        if (this.currentUser === undefined) {
            return Observable.empty();
        }
        return this.http.get(this.USER_API + '/find/defaultshares/' + this.currentUser.id);
    }
    renewCompanyToken(id) {
        return this.http.post(this.COMPANY_API + "/renewtoken/" + id, "", { responseType: 'json' });
    }
    updateUserCompanyInfo(user) {
        return this.http.post(this.USER_API + "/update/company/" + user.id, user, { responseType: 'text' });
    }
    static encryptPassword(password) {
        //TODO make something more secure than just an MD5 of the passwords
        return Md5.hashStr(password).toString();
    }
    joinWithMail(user) {
        // Request to send authentication mail
        return this.http.post(this.USER_API + "/sendauthmail", JSON.stringify(user), { responseType: 'text' });
    }
    activateAccount(key) {
        return this.http.post(this.USER_API + "/activateauth/" + key, "", { responseType: 'json' }).pipe(map(u => {
            this.setLoggedInUser(u);
            return u;
        }));
    }
    updatePassword(id, password) {
        let data = "{'id':'" + id + "', 'password':'" + UserService.encryptPassword(password) + "'}";
        return this.http.post(this.USER_API + "/updatepw", data, { responseType: 'text' });
    }
    updateCurrentPassword(id, currentpassword, password) {
        let data = "{'id':'" + id + "', 'currentPassword':'" + UserService.encryptPassword(currentpassword) + "', 'password':'" + UserService.encryptPassword(password) + "'}";
        return this.http.post(this.USER_API + "/updatepw", data, { responseType: 'text' });
    }
    saveUnavailabilities(user) {
        return this.http.post(this.USER_API + "/update/unavailabilities/" + user.id, user.unavailabilities, { responseType: 'text' });
    }
    //The become a translator button is only shown to users which are not yet translators
    //TODO improve the check for when a user is a translator, currently this is when the user has translation competences.
    updateIsTranslator() {
        this.isCurrentUserTranslator = this.isUserTranslator(this.currentUser);
        this.isCurrentUserInternalTranslator = this.isUserInternalTranslator(this.currentUser);
    }
    isUserTranslator(user) {
        return user != undefined && user.competences != undefined;
    }
    isUserInternalTranslator(user) {
        return user != undefined && user.internalCompetences != undefined;
    }
    hasTranslatorRole() {
        return this.isCurrentUserInternalTranslator || this.isCurrentUserTranslator;
    }
    updateIsAdmin() {
        this.isCurrentUserAdmin = this.currentUser && this.currentUser.admin != undefined &&
            (this.currentUser.admin === "true" || this.currentUser.admin === true);
    }
    updateCurrency() {
        let currency = 'EUR';
        if (this.currentUser && this.currentUser.currency)
            currency = this.currentUser.currency;
        sessionStorage.setItem("currency", currency);
    }
    sendCompanyInvite(from, inviteMail) {
        let body = { "mail": inviteMail };
        return this.http.post(this.USER_API + "/sendcompanyinvite/" + from, body, { responseType: 'text' });
    }
    uploadPictureFromLinkedIn(pictureUrl, id) {
        this.http.get(pictureUrl, { responseType: "blob" }).pipe(map(blob => {
            return blob;
        })).subscribe(v => this.uploadProfilePicture(v, id).subscribe(v => console.debug("Uploaded profile picture from LinkedIn for " + id, v), e => console.error("Error uploading profile picture", e)), e1 => console.error("Error getting profile picture from LinkedIn", e1));
    }
    uploadProfilePicture(file, userid) {
        let formData = new FormData();
        formData.append('uploadFile', file);
        formData.append('requestedname', 'profilepic.png');
        return this.http.post(this.FILES_API + "/users/profile/" + userid, formData, { responseType: 'text' });
    }
    sendInvites(addresses, inviteMessage) {
        let body = {
            addresses: addresses,
            inviteMessage: inviteMessage,
            userId: this.currentUser.id
        };
        return this.http.post(this.USER_API + "/sendinvites", JSON.stringify(body), { responseType: 'text' });
    }
    acceptInvite(inviteId, userId) {
        return this.http.post(this.USER_API + "/acceptinvite/" + inviteId + "/" + userId, "", { responseType: 'text' });
    }
    getInvites(userId) {
        return this.http.get(this.USER_API + "/invitesbyinviter/" + userId, { responseType: "json" });
    }
    getTotalPendingInviteRewards() {
        return this.http.get(this.USER_API + "/pendinginviterewards", { responseType: "json" });
    }
    validateVat(vat) {
        return this.http.get(this.COMPANY_API + "/vatnumber/isvalid/" + vat, { responseType: "json" });
    }
    sendContactRequest(userEmail, taskSpec, userId) {
        let data = "'email':'" + userEmail + "', 'task':" + JSON.stringify(taskSpec);
        if (userId != null && userId.length > 0)
            data += ", 'uid': '" + userId + "'";
        return this.http.post(this.USER_API + "/contactreq/missinglang", "{" + data + "}", { responseType: 'text' });
    }
    fetchUnavailability(userId) {
        return this.http.get(this.USER_API + '/futureunavailabilities/' + userId, { responseType: 'json' })
            .map(u => u);
    }
    getTmIndex(userId) {
        return this.http.get(this.USER_API + "/" + userId + "/tmidx", { responseType: 'json' });
    }
    mimicUser(mimicRequest) {
        return this.http.post(this.USER_API + "/admin/mimic", mimicRequest, { responseType: 'json' })
            .map(u => {
            this.currentUser = u;
            this.userEmitter.emit(u);
            this.setLoggedInUser(u);
            return u;
        });
    }
}
UserService.gtacversion = '20191223';
UserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserService_Factory() { return new UserService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.DomSanitizer), i0.ɵɵinject(i3.CookieService)); }, token: UserService, providedIn: "root" });
