/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./user.settings.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./oauth-callback.component";
import * as i3 from "@angular/router";
var styles_OauthCallbackComponent = [i0.styles];
var RenderType_OauthCallbackComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OauthCallbackComponent, data: {} });
export { RenderType_OauthCallbackComponent as RenderType_OauthCallbackComponent };
export function View_OauthCallbackComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Thanks for logging in!"]))], null, null); }
export function View_OauthCallbackComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "oauth-callback-component", [], null, null, null, View_OauthCallbackComponent_0, RenderType_OauthCallbackComponent)), i1.ɵdid(1, 114688, null, 0, i2.OauthCallbackComponent, [i3.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OauthCallbackComponentNgFactory = i1.ɵccf("oauth-callback-component", i2.OauthCallbackComponent, View_OauthCallbackComponent_Host_0, {}, {}, []);
export { OauthCallbackComponentNgFactory as OauthCallbackComponentNgFactory };
