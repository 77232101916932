<div class="taskOverviewFlipContainer" [ngClass]="{panelFlipped: flipped}">

  <div class="taskOverviewFlipper">
    <div #taskOverviewFake></div>
    <div class="taskOverviewFront sidebox" #taskOverviewFront>
      <div class="taskDateInfo">
        <div *ngFor="let d of getTaskDates()" class="taskDataVal">
          <span>{{d.label}}</span>
          <span [ngStyle]="{'color': d.color != undefined? d.color:'initial'}">{{d.value|date:'y/MM/dd, HH:mm'}}</span>
        </div>
        <div  *ngIf="isToAccept()">
          <p class="taskDateCountdown">
            <countdown [enddate]="task.duedate" [showoverdue]="true" [showsuffixlabel]="true"></countdown>
          </p>
          <p *ngIf="revisionNeeded" class="revisionRemark">
            <span style="font-weight: bold;">Please note the deadline includes a revision step as well.</span><br/>
            The reviser will be informed once you have confirmed your deadline for translation.
          </p>
        </div>
        <p *ngIf="isAccepted() && !isCompleted() && task.expecteddate" class="taskDateCountdown">
          <countdown [enddate]="task.expecteddate" [showoverdue]="true" [showsuffixlabel]="true"></countdown>
        </p>
      </div>
      <div *ngIf="project.requestedby" class="requestedByInfo">
        <p>Requested by</p>
        <div>
          <p class="taskDataVal">{{getRequesterName()}}</p>
          <p class="taskDataVal" *ngIf="project.requestedby.headline">{{project.requestedby.headline}}</p>
          <p class="taskDataVal" *ngIf="project.requestedby.companyName">{{project.requestedby.companyName}}</p>
        </div>
      </div>
      <div *ngIf="task.price" class="priceInfo">
        <p class="taskDataVal">
          <span *ngIf="!charBasedSource">Word count</span>
          <span *ngIf="charBasedSource">Character count</span>
          <span style="font-weight: bold; font-size: medium;">{{totalWordcount}}</span>
        </p>
        <p class="taskDataVal" style="font-weight: bold; font-size: medium; color: var(--second-color);">
          <span>Task total</span>
          <price [price]="task.price" [showBase]="true"></price>
        </p>
      </div>
      <!--Show colleagues information-->
      <div *ngIf="task">
        <colleagues-info [pId]="task.projectId" [currentTask]="task"></colleagues-info>
      </div>
      <div class="flipButton" (click)="flipped =! flipped">
        <img src="../../assets/icon_more-info.svg" alt="More info" title="More info">
      </div>
    </div>

    <div class="taskOverviewBack sidebox" #taskOverviewBack>
      <div *ngIf="statArray">
        <div *ngFor="let d of statArray" class="taskDataVal">
          <span>{{d.label}}</span>
          <span *ngIf="!charBasedSource">{{d.value}} words</span>
          <span *ngIf="charBasedSource">{{d.value}} characters</span>
        </div>
      </div>
      <div class="flipButton" (click)="flipped =! flipped">
        <img src="../../assets/icon_turn-card.svg" alt="Flip card" title="Flip back">
      </div>
    </div>
  </div>
</div>

<div *ngIf="task.counterProposal && isToAccept()" class="counterProposal">
  <p style="font-weight: bold;">
    You proposed an alternative delivery date
    <span *ngIf="task.counterProposal.date"
          style="font: inherit;"> of {{task.counterProposal.date|date:'y/MM/dd, HH:mm'}}</span>.
    <span style="text-decoration: underline; cursor: help;"
          title="You'll be notified, via email, when the customer accepts your proposed delivery date or decides to remove the task and choose someone else. If you change your mind, you can still accept or decline the task as long as the customer did not respond.">*</span>
  </p>
</div>

<div *ngIf="isToAccept()"
     style="display: flex; justify-content: center; margin-top: 10px;">
  <button (click)="acceptTask()" [ngClass]="{'blueButtonPulse': !task.counterProposal}">Accept</button>
  <button (click)="rejectTask()" style="background-color: var(--alternative-color);">Decline</button>
</div>
