<h1 mat-dialog-title>Editor settings</h1>
<div mat-dialog-content>

  <p style="margin-bottom: 15px;">
    Adjust your editor settings.
  </p>
  <div style="padding: 3px;">
    <div style="display: flex; justify-content: space-between;">
      <label style="flex: 0 0 auto; padding: 0;"
             title="Choose whether or not you wish to hide the references that surround a segment. (Default: yes)">
        Hide surrounding references?
      </label>
      <span><input type="checkbox" style="margin: 3px;" [(ngModel)]="hideAffix"></span>
    </div>
    <div style="display: flex; justify-content: space-between;">
      <label style="flex: 0 0 auto; padding: 0;"
             title="Choose whether or not you wish to hide the segments that contain no text (only whitespace or references). (Default: yes)">
        Hide "no text" segments?
      </label>
      <span><input type="checkbox" style="margin: 3px;" [(ngModel)]="hideNoText"></span>
    </div>
  </div>

</div>
<div mat-dialog-actions style="justify-content: space-around; margin-top: 20px;">
  <button mat-button (click)="confirmClick()">Save</button>
  <button mat-button (click)="cancelClick()">Cancel</button>
</div>
