<div *ngIf="user && accessAllowed != undefined">

  <div *ngIf="accessAllowed === true" class="contentWithSideBox">
    <div class="main">
      <profile-settings *ngIf="getPage()=='profile'" [user]="user"></profile-settings>
      <competence-settings *ngIf="getPage()=='competence'" [user]="user"></competence-settings>
      <company-settings *ngIf="getPage()=='company'"
                        [user]="user"
                        [companyId]="user.companyId" [isTranslator]="isTranslator()"
                        (companyEmitter)="onCompanyEvent($event)"></company-settings>
      <payment-settings *ngIf="getPage()=='payment'" [user]="user"></payment-settings>
      <password-settings *ngIf="getPage()=='password'" [user]="user"></password-settings>
      <availability-settings *ngIf="getPage()=='availability'" [user]="user"></availability-settings>
      <invite-settings *ngIf="getPage()=='invite'" [user]="user"></invite-settings>
      <credit-settings *ngIf="getPage()=='credit'" [user]="user">Credits & reductions</credit-settings>
      <integrations-settings *ngIf="getPage()=='integrations'" [user]="user">Integrations</integrations-settings>
      <documentation-settings *ngIf="getPage()=='documentation'" [companyId]="user.companyId">Documentations</documentation-settings>
      <invoice-overview *ngIf="getPage()=='invoicing'" [user]="user"></invoice-overview>
    </div>

    <div class="side sidebox">
      <button (click)="goto('profile')">Profile</button>
      <button (click)="goto('company')">Company</button>
      <button *ngIf="user" (click)="goto('invoicing')">Invoicing</button>
      <!--<button (click)="goto('alert')">Alerts</button>-->
      <!--<button (click)="goto('activity')">Activity</button>-->
      <div *ngIf="isTranslator()" class="sidebox">
        <button (click)="goto('competence')">Competences & pricing</button>
        <button (click)="goto('availability')">Availabilities</button>
        <button *ngIf="isDebug" (click)="goto('invite')">Invites</button>
      </div>
      <div *ngIf="!isTranslator()" class="sidebox">
        <button (click)="goto('payment')">Payment methods</button>
        <button (click)="goto('credit')">Credits & reductions</button>
        <button *ngIf="user.companyId != null" (click)="goto('documentation')">Documentation files</button>
      </div>
      <button *ngIf="isDebug" (click)="goto('integrations')">Integrations</button>
      <div *ngIf="isMailAuthenticated()" class="sidebox">
        <button (click)="goto('password')">Password</button>
      </div>
      <button *ngIf="userService.isCurrentUser(user)" (click)="logout()" style="margin-top: 25px;">Log out</button>
    </div>
  </div>

  <div *ngIf="accessAllowed === false">
    <h3>Sorry, you have no access to this user's data. 🤷‍♂️</h3>
  </div>
</div>

<div class="main loading" *ngIf="user === undefined">
  <img src="../../assets/loading_logo_1.gif" style="width: 100px;">
  <span>Locating user ...</span>
</div>
