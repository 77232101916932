import * as tslib_1 from "tslib";
import { EventEmitter, SimpleChanges, OnDestroy } from "@angular/core";
import { FileService } from "../../service/file.service";
import * as FileSaver from "file-saver";
import { AnalysisService } from "../../service/analysis.service";
import { MatDialog } from '@angular/material/dialog';
import { FileMetaDataDialog } from '../util/file-meta-data.dialog';
import { ProjectService } from "../../service/project.service";
import { DocumentationPDFDialog } from "./documentation-pdf-dialog";
import { SERVICE_SWORN_TRANSLATION, SERVICE_EDITING } from "../../mock-activities";
import { SubscriptionHandler } from '../../util/subscription.handler';
export class SourceFileComponent {
    constructor(projectService, fileService, analysisService, dialog) {
        this.projectService = projectService;
        this.fileService = fileService;
        this.analysisService = analysisService;
        this.dialog = dialog;
        this.allowRemoval = true;
        this.onFileRemove = new EventEmitter();
        this.onDocPdfUpload = new EventEmitter();
        this.onFileMetaUpdate = new EventEmitter();
        this.isManual = false;
        this.pdfFailed = false;
        this.wordCountChanged = false;
        this.languageChanged = false;
        this.subscriptionHandler = new SubscriptionHandler(this);
    }
    ngOnInit() {
        const analysisSubscription = this.analysisService.enrollForFile(this.pId, this.file.name).subscribe(data => {
            if (data.type == "wordcount") {
                if (this.file.wordcount !== data.value)
                    this.wordCountChanged = true;
                this.file.wordcount = data.value;
            }
            else if (data.type == "detectedsourcelang") {
                if (this.file.detectedsourcelang !== data.value)
                    this.languageChanged = true;
                this.file.detectedsourcelang = data.value;
                if (this.file.sourcelang == null)
                    this.file.sourcelang = data.value;
            }
        });
        this.subscriptionHandler.push(analysisSubscription);
        const projectSubscription = this.projectService.enrollForProjectAndFile(this.pId, this.file.name).subscribe(data => {
            if (data.text != undefined) {
                let json = JSON.parse(data.text);
                if (json.key === "pdfFailed") {
                    this.pdfFailed = json.value;
                }
                if (json.key === "processType") {
                    this.isManual = (json.value === "MANUAL");
                }
            }
        });
        this.subscriptionHandler.push(projectSubscription);
        this.setFile(this.file);
    }
    ngOnDestroy() {
    }
    ngOnChanges(changes) {
        if (changes.file !== undefined) {
            this.setFile(changes.file.currentValue);
        }
    }
    setFile(file) {
        this.file = file;
        this.isManual = this.file.processType == "MANUAL";
        // TODO: also indicate whether the source file has a custom PDF specified; if so, change the warning text to 'Change custom PDF.'
        this.pdfFailed = this.file.pdfFailed !== undefined ? this.file.pdfFailed : false;
    }
    download() {
        this.fileService.downloadSource(this.pId, this.file.name)
            .subscribe(n => {
            FileSaver.saveAs(n, this.file.name, true);
        });
    }
    remove() {
        if (this.allowRemoval) {
            this.onFileRemove.emit(this.file);
        }
    }
    openFileMetaData() {
        let dialogRef = this.dialog.open(FileMetaDataDialog, {
            panelClass: 'simpleDialog',
            width: "550px",
            data: { projectId: this.pId, file: this.file }
        });
        dialogRef.afterClosed().subscribe(a => {
            if (a == "success") {
                // Set language to empty
                this.file.sourcelanguage = "";
                this.file.wordcount = dialogRef.componentInstance.words;
                this.onFileMetaUpdate.emit({
                    "filename": this.file.name,
                    "wordcount": this.file.wordcount
                });
            }
        });
    }
    uploadDocPdf() {
        let dialogRef = this.dialog.open(DocumentationPDFDialog, {
            panelClass: 'simpleDialog',
            width: '550px',
            data: { projectId: this.pId, srcFile: this.file }
        });
        dialogRef.afterClosed().subscribe(files => {
            if (files)
                this.uploadDocFiles(files);
        });
    }
    uploadDocFiles(event) {
        let docPdf = event.docPdf;
        // We are uploading the documentation file in an async block so the rest of the application does not wait for the
        // upload to complete
        (() => tslib_1.__awaiter(this, void 0, void 0, function* () {
            // Upload the file through the file service
            let start = new Date().getTime();
            // 20 second delay to fake lengthy upload (only for debugging)
            // await sleep(20*1000);
            // Upload this as a "doc-PDF" (=PDF version of a source document)
            this.fileService.uploadPdfDocFile(docPdf, this.pId, this.file.name).subscribe(data => {
                let end = new Date();
                console.log("Project " + this.pId + ": upload of " + docPdf.name + " [pdf-documentation] in " + (end.getTime() - start) + " ms");
                // Add the actual file data to the project
                this.addDocFileData(docPdf, this.file.name);
            }, error => console.log("Error uploading documentation PDF for " + this.file.name, error));
        }))();
    }
    addDocFileData(docPdf, srcFileName) {
        // Note that the 'addDocFileData' function does not replace any existing custom PDF for this source file
        // This needs to be done beforehand!
        this.projectService.addDocFileData(docPdf.name, srcFileName, this.pId).subscribe(data => {
            this.onDocPdfUpload.emit(data);
        }, error => console.log("Error adding documentation PDF data for " + this.file.name, error));
    }
    isManualService() {
        return this.service === SERVICE_SWORN_TRANSLATION || this.service === SERVICE_EDITING;
    }
}
