import {Component, Directive, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';


@Directive({
  selector: '.tooltip-container'
})
export class TooltipContainerDirective {
}

@Component({
  template: `
    <div class="tooltip-container" [style.top]="top" [style.left]="left" [style.visibility]="visible">
      <ng-content></ng-content>
    </div>
  `,
  styles: [
    `
      .tooltip-container {
        background-color: black;
        color: #fff;
        display: inline-block;
        padding: 0.5em;
        position: fixed;
        /*position: absolute;*/
        transition: all ease 0.8s;
        visible: hidden;
      }
    `
  ]
})
export class TooltipComponent implements OnInit {

  top: string;
  left: string;
  visible: string = "hidden";
  @ViewChild(TooltipContainerDirective, { read: ElementRef, static: true })
  private tooltipContainer;

  constructor(@Inject('tooltipConfig') private config) {
  }

  ngOnInit() {
    // We need to surround the code to position the tooltip with setTimeout here, or we do not get the updated and correct
    // dimensions of the "boundingClientRect" (no clue why)
    setTimeout(() => {
      const {top} = this.config.host.getBoundingClientRect();
      const {right} = this.config.host.getBoundingClientRect();
      const {height} = this.tooltipContainer.nativeElement.getBoundingClientRect();
      // We align the tooltip over the top right corner of the element (used for project-card at the moment)
      // TODO: add more "config" options so one can choose the position of the tooltip
      this.top = `${top - height + 40}px`;
      this.left = `${right - 40}px`;
      // Make the tooltip visible at this point
      this.visible = `visible`;
      // });
    }, 500);
  }

}
