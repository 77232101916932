import {filter, map, mergeMap} from 'rxjs/operators';
import {Component, EventEmitter, Input, Output} from "@angular/core";
import {AnalysisService} from "../../service/analysis.service";
import {PricingService} from "../../service/pricing.services";
import {FileService} from "../../service/file.service";
import * as FileSaver from "file-saver";
import {ProjectService} from "../../service/project.service";
import {MatDialog} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {LoggerService} from "../../service/logger.service";
import {FileMetaDataDialog} from "./file-meta-data.dialog";
import {UploadDataService, UploadProgressData} from "./upload-data.service";
import {environment} from '../../../environments/environment';
import { SubscriptionHandler } from '../../util/subscription.handler';


@Component({
  selector: 'file-info',
  templateUrl: './file-info.component.html',
  styleUrls: ['file-info.component.css']
})
export class FileInfoComponent {

  @Input() file: any;
  @Input() pdf4source;
  @Input() pId;
  @Output() remover: EventEmitter<string> = new EventEmitter();
  @Output() languageUpdate: EventEmitter<string> = new EventEmitter();

  pdf4sourceName: string;
  totalPrice = 0;
  private alive = true;
  private statusMap = new Map<String, String>();
  uploadPercent: number = -1;

  subscriptionHandler = new SubscriptionHandler(this);

  constructor(private analysisService: AnalysisService,
              private pricingService: PricingService,
              private fileService: FileService,
              private projectService: ProjectService,
              private loggerService: LoggerService,
              private uploadDataService: UploadDataService,
              private snackbar: MatSnackBar,
              private dialog: MatDialog) {
    this.statusMap.set("conv2xliff", "Converted to XLIFF");
    this.statusMap.set("pdf", "Created PDF");
    this.statusMap.set("frontpage_img", "Generated frontpage image");
    this.statusMap.set("wordcount", "Calculated word count");
    this.statusMap.set("detectedsourcelang", "Detected language");
  }

  ngOnInit(): void {
    if (this.file) {
      this.file.statusText = "";
      // Check whether there is any upload observer available for this file
      let uploadObserver = this.getUploadObserver(this.file);
      if (uploadObserver != null) {
        // If so, subscribe to it and listen to progress events
        this.uploadPercent = (uploadObserver.lastVal >= 0 ? uploadObserver.lastVal : 0);
        uploadObserver.progressObs.subscribe(p => {
            this.uploadPercent = p;
          },
          error => {
          },
          () => {
            // Reset when done
            uploadObserver = null;
            this.uploadPercent = -1;
          });
      }
      this.getProgress(this.pId, this.file.name);
      const fileAnalysSubscription = this.analysisService.enrollForFile(this.pId, this.file.name).subscribe(data => {
          if (data.type == "wordcount") {
            this.file.wordcount = data.value;
          } else if (data.type == "detectedsourcelang") {
            if (this.file.sourcelang == undefined)
              this.updateFileLang(data.value, false);
            this.file.detectedsourcelang = data.value;
          }
        }
      );
      this.subscriptionHandler.push(fileAnalysSubscription);
      const logSubscription = this.loggerService.enrollForProject(this.pId).subscribe(data => {
        let filename = data.filename;
        if (filename && filename.indexOf(this.file.name) >= 0) {
          this.getProgress(this.pId, this.file.name);
        }
      });
      this.subscriptionHandler.push(logSubscription);
      //Init the file pricing once
      this.pricingService.getTotalFilePrice(this.pId, this.file.name).subscribe(total => this.totalPrice = total);
      //Then enroll in the socket and recalculate each change made
      const priceSubscription = this.pricingService.enrollForProject(this.pId).pipe(
        mergeMap(s => this.pricingService.getTotalFilePrice(this.pId, this.file.name)))
        .subscribe(total => this.totalPrice = total);
      this.subscriptionHandler.push(priceSubscription);
      const sourceUpdateSubscription = this.projectService.enrollForProject(this.pId).pipe(
        filter(data => data.action == "SOURCE_UPDATE" && data.filename == this.file.name),
        map(data => data.text),
        map(t => JSON.parse(t)),)
        .subscribe(v => {
          this.file[v.key] = v.value;
        });
      this.subscriptionHandler.push(sourceUpdateSubscription);
      if (this.pdf4source)
        this.pdf4sourceName = this.pdf4source.name;
    }
  }

  private getUploadObserver(file: any): UploadProgressData {
    // Retrieve the upload observer (if any)
    let fileProgress = this.uploadDataService.getUploadData(this.pId);
    if (fileProgress != null && fileProgress[file.name] != null) {
      return fileProgress[file.name].progressdata;
    } else return null;
  }

  private getProgress(pId: string, filename: string) {
    const dateformat = require("dateformat");
    this.loggerService.getLogs(pId, filename, null).subscribe(l => {
      this.file.statusText = "";
      l.forEach(e => {
        this.file.statusText += "- " + dateformat(Date.parse(e.timestamp), "yyyy-mm-dd HH:MM:ss.l") + ": " +
          ((e.langpair) ? "[" + e.langpair + "] " : "") +
          (this.statusMap.has(e.action.toLowerCase()) ? this.statusMap.get(e.action.toLowerCase()) : e.action) + " \n";
        if (!this.file.statusReady)
          this.file.statusReady = (e.action.toLowerCase().indexOf("conv2xliff") >= 0);
      });
    });
  }

  getStatusImageDisplay() {
    return this.file.statusReady ? "inline-block" : "none";
  }

  getStatusTooltip() {
    return this.file.statusText;
  }

  ngOnDestroy() {
    this.alive = false; // switches the IntervalObservable off
  }

  download() {
    this.fileService.downloadSource(this.pId, this.file.name).subscribe(
      n => {
        FileSaver.saveAs(n, this.file.name, true);
      }
    )
  }

  updateFileLang($event, store: boolean) {
    this.file.sourcelang = $event;
    this.languageUpdate.emit(this.file.sourcelang);
    if (store) {
      this.projectService
      .setFileLanguage(this.pId, this.file.name, $event)
      .subscribe(v => {
          if (environment.isDebug) {
            this.snackbar.open("Language updated", "", {
              duration: 2000
            })
          }
        },
        error => console.error(error))
    }
  }

  copyDetectedToSource() {
    if (this.file.detectedsourcelang != this.file.sourcelang)
      this.updateFileLang(this.file.detectedsourcelang, true);
    //else do nothing
  }

  isFileUploading() {
    return this.uploadPercent >= 0 && this.uploadPercent < 101;
  }

  removeFile() {
    this.remover.emit(this.file);
  }

  showDetailPricing() {
    // this.pricingService.getDetailedFilePrice(this.pId, this.file.name).subscribe()
    console.log("To implement")
  }

  openFileMetaData() {
    let dialogRef = this.dialog.open(FileMetaDataDialog, {
      panelClass: 'simpleDialog',
      width: "550px",
      data: {file: this.file, projectId: this.pId}
    });
    dialogRef.afterClosed().subscribe(a => {
      if (a == "success") {
        this.file.wordcount = dialogRef.componentInstance.words;
      }
    })
  }
}
