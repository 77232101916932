<!--//TODO Fully implement this page-->
<div class="profileinfo" *ngIf="userId!=undefined">
  <vendor-profile *ngIf="user" [profile]="user" [maxDescriptionSize]="undefined" [maxDomainCount]="undefined"></vendor-profile>
  <div *ngIf="user && user.competences && user.competences.length>0">
    <h3>Competences</h3>
    <ul>
      <li *ngFor="let competence of user.competences">
        {{competence.activity}}: {{competence.source|languagePipe|async}} -> {{competence.target|languagePipe|async}}
      </li>
    </ul>
  </div>
  <div>
    <h3>Reviews</h3>
    <rating-full-component [userId]="userId"></rating-full-component>
  </div>
</div>
