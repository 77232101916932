import {debounceTime, filter, map} from 'rxjs/operators';
import {Component, EventEmitter, Input, Output} from "@angular/core";
import {UserService} from "../service/user.service";
import {MatDialog} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ConfirmDialog} from "../confirm.dialog";
import {CountryService} from "../service/country.service";
import {ErrorSnackbarComponent} from "../error.snackbar.component";
import {Subject} from "rxjs";


@Component({
  selector: "company-settings",
  templateUrl: './company.setting.component.html',
  styleUrls: ['./user.settings.component.css']
})
export class CompanySettingsComponent {

  @Input() companyId;
  @Input() isTranslator = false;
  @Input() user;
  @Output() companyEmitter = new EventEmitter<any>();

  public privatePerson: boolean;
  public company;
  public countries;
  public token;
  public inviteMail;
  public isVatValid: boolean;
  public checkingVat = false;
  private vatChanges: Subject<any>;

  constructor(private userService: UserService,
              private countryService: CountryService,
              private snackbar: MatSnackBar,
              private dialog: MatDialog) {

  }

  public ngOnInit(): void {
    if (this.user.privatePerson != undefined)
      this.privatePerson = this.user.privatePerson;
    else this.privatePerson = false;
    if (this.companyId) {
      this.userService.findCompanyInfo(this.companyId).subscribe(n => this.company = n)
    } else {
      this.initNew()
    }
    this.initVatFieldUpdate();
    this.countryService.getCountries()
      .subscribe(c =>
        this.countries = c);
  }

  private initNew(): void {
    this.company = Object();
  }

  public allowPrivatePersonSelection(): boolean {
    return !this.isTranslator && !(this.company && this.company.id);
  }

  public allowCompanySelection(): boolean {
    return this.isTranslator || (this.company && !this.privatePerson);
  }

  public validateToken(): void {
    if (this.token)
      this.userService.findCompanyInfoByToken(this.token).subscribe(n => {
          this.company = n;
          this.companyId = n.id;
          this.companyEmitter.emit(this.company)
        },
        e => {
          let ref = this.snackbar.openFromComponent(ErrorSnackbarComponent, {
            duration: 2000,
            verticalPosition: "top",
            data: {errorText: 'Token could not be validated.'}
          });
        }
      )
  }

  public sendInvite(): void {
    //Check if there is already a company token
    if (this.company && !this.company.token) {
      this.userService.renewCompanyToken(this.company.id).subscribe(n => {
          this.company.token = n;
          this.sendCompanyInvite();
        }
      )
    } else {
      this.sendCompanyInvite();
    }
  }

  private sendCompanyInvite(): void {
    // todo: should this be this.user iso currentUser?
    this.userService.sendCompanyInvite(this.userService.getCurrentUser().id, this.inviteMail).subscribe(v => {
      this.snackbar.open("Invite has been sent", "", {
        duration: 2000
      });
    })
  }

  public renewToken(): void {
    let confirmText = "Warning: renewing the token will invalidate all invites that have been sent. \n" +
      "Are you sure you want to renew the token?";
    let dialogRef = this.dialog.open(ConfirmDialog, {
      width: '450px',
      height: '350px',
      panelClass: 'confirmDialog',
      data: {
        confirmMessage: confirmText, confirmText: "Yes", cancelText: "No"
      }
    });
    dialogRef.afterClosed().pipe(
      filter(a => a == "confirm"))
      .subscribe(a =>
        this.userService.renewCompanyToken(this.company.id).subscribe(n =>
          this.company.token = n
        )
      );
  }

  public save(): void {
    // Update user for 'private person' flag (if changed)
    if (this.privatePerson != this.user.privatePerson || this.user.privatePerson == undefined) {
      this.user.privatePerson = this.privatePerson;
      this.userService.updateUserData(this.user).subscribe(u =>
        this.snackbar.open("Profile updated", '', {duration: 2000})
      );
    } else if (this.user.privatePerson) {
      // Show a 'snackbar' here anyway, or it looks to the user like nothing happened
      this.snackbar.open("Profile updated", '', {duration: 2000});
    }
    // Disregard any company data if user is 'private person'
    if (!this.user.privatePerson) {
      // If the user has a customer group ID, then copy it to the company
      if (this.user.custGroupId != null)
        this.company.custGroupId = this.user.custGroupId;
      if (this.company.id) {
        // If there is already a company ID, update the company
        this.company.lastupdateby = this.user.id;
        this.userService.updateCompanyInfo(this.company).subscribe(v => {
          this.snackbar.open("Company updated", '', {
            duration: 2000
          });
          this.companyEmitter.emit(this.company);
        });
      } else {
        // If there is no company ID yet and the user is not marked as a private person, create the company
        this.company.firstUser = this.user.id;
        this.userService.createCompanyInfo(this.company, null).subscribe(id => {
          this.snackbar.open("Company created", '', {
            duration: 2000
          });
          this.companyId = id;
          this.company.id = id;
          this.companyEmitter.emit(this.company);
        });
      }
    }
  }

  public vatChange($event): void {
    this.checkingVat = true;
    this.vatChanges.next($event);
  }

  private initVatFieldUpdate(): void {
    this.vatChanges = new Subject<any>();
    //debounce so it only updates every second instead of every keystroke
    this.vatChanges.asObservable().pipe(debounceTime(1000)).subscribe(v =>
      this.userService.validateVat(this.company.vat).pipe(
        map(v => <boolean>v))
        .subscribe(
          v => {
            this.isVatValid = v;
            this.checkingVat = false;
          }
        )
    );
  }
}
