/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../../project.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../util/frontpage.component.ngfactory";
import * as i3 from "../../../util/frontpage.component";
import * as i4 from "../../../service/file.service";
import * as i5 from "@angular/common";
import * as i6 from "../../../util/filename.pipe";
import * as i7 from "./documentation-file.component";
var styles_DocumentationFileComponent = [i0.styles];
var RenderType_DocumentationFileComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DocumentationFileComponent, data: {} });
export { RenderType_DocumentationFileComponent as RenderType_DocumentationFileComponent };
function View_DocumentationFileComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "sourceFileDelete"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.emitFileRemove() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["alt", "Remove file"], ["src", "../../../assets/delete_icon_resized.svg"], ["title", "Remove"]], null, null, null, null, null))], null, null); }
function View_DocumentationFileComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [["class", "documentationItem documentationFile"], ["style", ""]], [[8, "title", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 15, "div", [["class", "documentationFileInfo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 8, "div", [["class", "documentationFileInfoImage"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "frontpage", [], null, null, null, i2.View_FrontpageComponent_0, i2.RenderType_FrontpageComponent)), i1.ɵdid(4, 245760, null, 0, i3.FrontpageComponent, [i4.FileService], { fileName: [0, "fileName"], width: [1, "width"], height: [2, "height"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 5, "div", [["class", "sourceFileHover"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "sourceFileDownload"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.download() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "img", [["alt", "Download file"], ["src", "../../../assets/download_icon.svg"], ["title", "Download"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocumentationFileComponent_2)), i1.ɵdid(10, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 5, "h5", [], null, null, null, null, null)), i1.ɵprd(512, null, i5.ɵNgStyleImpl, i5.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(13, 278528, null, 0, i5.NgStyle, [i5.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(14, { "text-decoration": 0 }), (_l()(), i1.ɵted(15, null, ["", ""])), i1.ɵppd(16, 3)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.file.name; var currVal_2 = "70px"; var currVal_3 = "85px"; _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.allowRemoval; _ck(_v, 10, 0, currVal_4); var currVal_5 = _ck(_v, 14, 0, ((_co.file.warning != null) ? "line-through" : "initial")); _ck(_v, 13, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.file.name; _ck(_v, 0, 0, currVal_0); var currVal_6 = i1.ɵunv(_v, 15, 0, _ck(_v, 16, 0, i1.ɵnov(_v.parent, 0), _co.file.name, 24, true)); _ck(_v, 15, 0, currVal_6); }); }
export function View_DocumentationFileComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i6.FilenamePipe, []), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocumentationFileComponent_1)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.file; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_DocumentationFileComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "documentation-file", [], null, null, null, View_DocumentationFileComponent_0, RenderType_DocumentationFileComponent)), i1.ɵdid(1, 49152, null, 0, i7.DocumentationFileComponent, [i4.FileService], null, null)], null, null); }
var DocumentationFileComponentNgFactory = i1.ɵccf("documentation-file", i7.DocumentationFileComponent, View_DocumentationFileComponent_Host_0, { file: "file", entityId: "entityId", entityType: "entityType", allowRemoval: "allowRemoval" }, { removeEmitter: "removeEmitter" }, []);
export { DocumentationFileComponentNgFactory as DocumentationFileComponentNgFactory };
