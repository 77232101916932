<div [className]='isSelf? "self" : ""'>
  <div class="message">
    <div class="messagetop">
      <div class="userinfo">
        <div>
          <mugshot [user]="message.user" [width]="'35px'" [height]="'35px'"></mugshot>
        </div>
        <div class="username">
          <strong *ngIf="message.user">{{message.user.name}}</strong>
          <span *ngIf="userDesc" style="color: var(--dark-gray); font-size: 10px;"><br/>{{userDesc}}</span>
        </div>
      </div>
      <div *ngIf="false && !isRead"><!--TODO Disable the read/unread functionality for now-->
        <img
          src="../../assets/new.png"
          title="New message"
          style="width: 30px; height: 30px;"
        />
      </div>
    </div>
    <div class="messagetext">{{ message.text }}</div>
    <div class="messagebottom">
      <div *ngIf="message.id != null && message.date != null"
           class="messageinfo">{{ message.date | date: "yyyy-MM-dd, HH:mm" }}</div>
      <div *ngIf="message.id == null" class="messageinfo">sending...</div>
      <!--<div class="messageaction" (click)="activateReply()" *ngIf="isThread">
      Reply
    </div>-->
    </div>
    <!--<div *ngIf="message && message.replies && message.replies.length > 0">
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h5>Replies</h5>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="replies">
        <div *ngFor="let m of message.replies" style="display: flex; justify-content: stretch;">
          <div class="replyindent" style="flex: 0 0 auto;">&#x21b3;</div>
          <message style="flex: 1 1 auto;"
                   [user]="user" [message]="m" [isThread]="false"></message>
        </div>
      </div>
    </mat-expansion-panel>
  </div>
  <div *ngIf="reply" style="display: flex">
    <div class="replyindent">&#x21b3;</div>
    <new-message [user]="user" [placeholderText]="'Leave a reply'" (sendEmitter)="addReply($event)"></new-message>
  </div>-->
  </div>
</div>
