<div style="display: flex; justify-content: space-between; margin-top: 20px;">
  <form style="flex: 1 1 auto;">

    <div>
      <h2 style="margin-bottom: 25px;">Invoice details</h2>
      <div>
        <div class="row purchaseInvoiceRow">
          <label>Invoice number</label>
          <input name="invoicenumber" type="text" class="invoicenumber"
                 [disabled]="isExisting"
                 [(ngModel)]="invoice.number"
                 placeholder="Your invoice number"/>
        </div>
        <div class="row purchaseInvoiceRow">
          <label>Invoice date</label>
          <span *ngIf="isExisting">{{invoice.date}}</span>
          <input *ngIf="!isExisting" type="date" name="invoicedate" class="invoicingdate"
                 [(ngModel)]="invoice.date"
                 placeholder="Select your invoice date">
        </div>
        <div class="row purchaseInvoiceRow">
          <label>Payment due date</label>
          <span *ngIf="isExisting">{{invoice.date}}</span>
          <input *ngIf="!isExisting" type="date" name="invoiceduedate" class="invoicingdate"
                 [(ngModel)]="invoice.duedate"
                 placeholder="Select the payment due date"/>
        </div>
        <div class="row purchaseInvoiceRow">
          <label>Invoice PDF</label>
          <button *ngIf="isExisting" class="btnDownload" (click)="downloadInvoicePDF()">
            <img src="../../assets/download_white.png">Download
          </button>
          <input *ngIf="!isExisting" name="invoicepdf" type="file"
                 [(ngModel)]="invoicePdfName"
                 (change)="setInvoicePDFFile($event)">
        </div>
      </div>
    </div>

    <div *ngIf="userService.isCurrentUserTranslator || userService.isCurrentUserAdmin">
      <div class="invoiceabletasklist" *ngIf="tasks">
        <h4>Tasks</h4>
        <div *ngIf="tasks.length > 0; else noTasks">
          <mat-selection-list #checkedTasks [disabled]="isExisting">
            <mat-list-option *ngFor="let task of tasks" [disabled]="isExisting"
                             (click)="selectionChange(checkedTasks)"
                             [value]="task">
              <h3 matLine style="font-size: 16px; margin-bottom: 10px;">{{task.activity}}
                from {{task.sourcelanguage | languagePipe | async}}
                to {{task.targetlanguage | languagePipe | async}}</h3>
              <p matLine style="margin-bottom: 4px;">Title: {{task.title}}</p>
              <p matLine style="margin-bottom: 4px;"># files: {{task.jobs.length}}</p>
              <p matLine style="margin-bottom: 4px;">Task price:
                <price [price]="task.price" displayCurrency="EUR" [showBase]="true"></price>
              </p>
              <p matLine style="margin-bottom: 4px;">Completed on: {{task.completed|date}}</p>
            </mat-list-option>
          </mat-selection-list>
        </div>
        <ng-template #noTasks>
          <p style="font-size: 16px; font-weight: bold; margin: 10px 0;">No tasks eligible to invoice.</p>
        </ng-template>
      </div>

      <!--- commented until invite is actually live
      <h3>Invitation rewards</h3>
      <p>By inviting possible customers, you will earn on the translation projects they purchase. You can invite
        possible customers by clicking the invite button on the top. A total will be shown here if there are any
        rewards ready for invoicing.</p>
      <div *ngIf="invitationRewards!=undefined && invitationRewards.length>0">
        <div *ngIf="invoiceid==null">
          <h5>You have a total of
            <price [price]="totalInvitationReward" [displayCurrency]="EUR" [showBase]="true"></price>
            on
            invitation rewards which are
            not yet invoiced.
          </h5>
          <label for="includeInvitationReward"><input type="checkbox" id="includeInvitationReward"
                                                      name="includeInvitationRerward"
                                                      [(ngModel)]="includeInvitationReward"
                                                      (change)="calculateTotal()"/>Invitation rewards
            <price [price]="totalInvitationReward" [displayCurrency]="EUR"></price>
            are included on the invoice.</label>
        </div>
        <div *ngIf="invoiceid!=null">
          <h4>The total of invitation rewards on this invoice is
            <price [price]="totalInvitationReward" [displayCurrency]="EUR"></price>
          </h4>
        </div>
      </div>
      !--->

      <div class="selecteddetails">
        <p># tasks: {{selectedTasks.length}}</p>
        <p>Invoice amount:
          <price [price]="totalInvoiceAmount" [displayCurrency]="'EUR'"></price>
        </p>
      </div>
    </div>
    <div class="btnRegister" *ngIf="!isExisting">
      <button type="submit" [disabled]="!allDataComplete()" (click)="registerInvoice()">Register invoice</button>
    </div>

  </form>

  <div *ngIf="company" class="purchaseInvoiceInfo">
    <h3>Your invoicing details</h3>
    <p>Please make sure the following company details are mentioned on the invoice:</p>
    <table class="plainTable" style="margin-left: 10px;">
      <tr>
        <td>Name</td>
        <td>{{company.name}}</td>
      </tr>
      <tr>
        <td>Address</td>
        <td>{{company.address}}</td>
      </tr>
      <tr>
        <td>Country</td>
        <td>{{company.country}}</td>
      </tr>
      <tr>
        <td>Bank account</td>
        <td>{{company.bankaccount}}</td>
      </tr>
      <tr>
        <td>VAT no</td>
        <td>{{company.vat}}</td>
      </tr>
    </table>
    <p><i>In case the information above is incorrect, please amend it in your profile.</i></p>
    <h3>Lilo's invoicing details</h3>
    <p>Please make sure the following details are also mentioned on the invoice:</p>
    <table class="plainTable" style="margin-left: 10px;">
      <tr>
        <td>Name</td>
        <td>LiLo BVBA</td>
      </tr>
      <tr>
        <td>Address</td>
        <td>Hertogstraat 127/0101 3001 Leuven</td>
      </tr>
      <tr>
        <td>Country</td>
        <td>Belgium</td>
      </tr>
      <tr>
        <td>VAT no.</td>
        <td>BE0689637039</td>
      </tr>
    </table>
  </div>
</div>
