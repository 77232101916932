/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./rating.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./rating.component";
var styles_RatingComponent = [i0.styles];
var RenderType_RatingComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RatingComponent, data: {} });
export { RenderType_RatingComponent as RenderType_RatingComponent };
export function View_RatingComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "span", [], null, [[null, "mouseout"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseout" === en)) {
        var pd_0 = ((_co.editingRating = _co.rating) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.setRate() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "span", [], [[8, "className", 0]], [[null, "mouseover"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseover" === en)) {
        var pd_0 = (_co.rate(1) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "span", [], [[8, "className", 0]], [[null, "mouseover"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseover" === en)) {
        var pd_0 = (_co.rate(25) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "span", [], [[8, "className", 0]], [[null, "mouseover"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseover" === en)) {
        var pd_0 = (_co.rate(50) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "span", [], [[8, "className", 0]], [[null, "mouseover"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseover" === en)) {
        var pd_0 = (_co.rate(75) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "span", [], [[8, "className", 0]], [[null, "mouseover"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseover" === en)) {
        var pd_0 = (_co.rate(100) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "fa fa-star ", _co.starChecked(1), ""); _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵinlineInterpolate(1, "fa fa-star ", _co.starChecked(25), ""); _ck(_v, 2, 0, currVal_1); var currVal_2 = i1.ɵinlineInterpolate(1, "fa fa-star ", _co.starChecked(50), ""); _ck(_v, 3, 0, currVal_2); var currVal_3 = i1.ɵinlineInterpolate(1, "fa fa-star ", _co.starChecked(75), " "); _ck(_v, 4, 0, currVal_3); var currVal_4 = i1.ɵinlineInterpolate(1, "fa fa-star ", _co.starChecked(100), ""); _ck(_v, 5, 0, currVal_4); }); }
export function View_RatingComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "rating", [], null, null, null, View_RatingComponent_0, RenderType_RatingComponent)), i1.ɵdid(1, 114688, null, 0, i2.RatingComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RatingComponentNgFactory = i1.ɵccf("rating", i2.RatingComponent, View_RatingComponent_Host_0, { rating: "rating" }, { onStarSelected: "onStarSelected" }, []);
export { RatingComponentNgFactory as RatingComponentNgFactory };
