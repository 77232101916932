/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./drop-entry.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./pre-upload-file.component.ngfactory";
import * as i3 from "./pre-upload-file.component";
import * as i4 from "@angular/common";
import * as i5 from "../util/dragndrop.directive";
import * as i6 from "../util/gtac-input.component.ngfactory";
import * as i7 from "../util/gtac-input.component";
import * as i8 from "../service/user.service";
import * as i9 from "ngx-cookie-service";
import * as i10 from "./pre-upload.component";
import * as i11 from "../service/project.service";
import * as i12 from "../service/file.service";
import * as i13 from "../projectcomponent/util/upload-data.service";
import * as i14 from "../service/converter.service";
import * as i15 from "@angular/router";
var styles_PreUploadComponent = [i0.styles];
var RenderType_PreUploadComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PreUploadComponent, data: {} });
export { RenderType_PreUploadComponent as RenderType_PreUploadComponent };
function View_PreUploadComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "addASourceFileDiv"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "label", [["for", "fileupload"], ["style", "line-height: unset;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["src", "../../assets/plus.png"], ["style", "width: 50px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 8, "div", [["class", "addFileLabelDiv"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "label", [["for", "fileupload"], ["style", "line-height: unset;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Add a file"])), (_l()(), i1.ɵeld(7, 0, [[1, 0], ["fileupload", 1]], null, 0, "input", [["id", "fileupload"], ["multiple", ""], ["style", "display: none;"], ["type", "file"]], [[8, "disabled", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.uploadFiles($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 3, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Word, ppt, pages, ... "])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "a", [["href", "https://about.lilo.global/formats"], ["style", "display: none;"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["see all formats"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loading; _ck(_v, 7, 0, currVal_0); }); }
function View_PreUploadComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "pre-upload-file", [], null, [[null, "removeEmitter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("removeEmitter" === en)) {
        var pd_0 = (_co.removeFile($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_PreUploadFileComponent_0, i2.RenderType_PreUploadFileComponent)), i1.ɵdid(2, 49152, null, 0, i3.PreUploadFileComponent, [], { file: [0, "file"] }, { removeEmitter: "removeEmitter" })], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }, null); }
function View_PreUploadComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "preUploadFiles"], ["id", "uploadedFilesDiv"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PreUploadComponent_3)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.files; _ck(_v, 2, 0, currVal_0); }, null); }
function View_PreUploadComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "addMoreSourceFileDiv preUploadItem"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "label", [["class", "addFilesCircle"], ["for", "fileadd"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["+"])), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "addFileLabelDiv"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "label", [["for", "fileadd"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Add more files"])), (_l()(), i1.ɵeld(6, 0, [["fileadd", 1]], null, 0, "input", [["id", "fileadd"], ["multiple", ""], ["style", "display: none;"], ["type", "file"]], [[8, "disabled", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.uploadFiles($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loading; _ck(_v, 6, 0, currVal_0); }); }
function View_PreUploadComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Next"]))], null, null); }
function View_PreUploadComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["alt", "Loading..."], ["src", "../../assets/loading_small_orb_whiteonblue.gif"]], null, null, null, null, null))], null, null); }
export function View_PreUploadComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { uploadInput: 0 }), i1.ɵqud(402653184, 2, { gtacInput: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 16, "div", [["class", "pre-upload-component"], ["drag-n-drop", ""]], null, [[null, "fileChangeEmitter"], [null, "drop"], [null, "dragover"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("drop" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onDrop($event) !== false);
        ad = (pd_0 && ad);
    } if (("dragover" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3).onDragOver($event) !== false);
        ad = (pd_1 && ad);
    } if (("fileChangeEmitter" === en)) {
        var pd_2 = (_co.onFileChange($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 16384, null, 0, i5.DragndropDirective, [], null, { fileChangeEmitter: "fileChangeEmitter" }), (_l()(), i1.ɵeld(4, 0, null, null, 6, "div", [["class", "filesSection"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PreUploadComponent_1)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PreUploadComponent_2)), i1.ɵdid(8, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PreUploadComponent_4)), i1.ɵdid(10, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 7, "div", [["class", "nextSection"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "gtac-input", [], null, null, null, i6.View_GTACInputComponent_0, i6.RenderType_GTACInputComponent)), i1.ɵdid(13, 114688, [[2, 4], ["gtac", 4]], 0, i7.GTACInputComponent, [i8.UserService, i9.CookieService], null, null), (_l()(), i1.ɵeld(14, 0, null, null, 4, "button", [], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.draftProject() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PreUploadComponent_5)), i1.ɵdid(16, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PreUploadComponent_6)), i1.ɵdid(18, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.files === undefined) || (_co.files.length == 0)); _ck(_v, 6, 0, currVal_0); var currVal_1 = (_co.files.length > 0); _ck(_v, 8, 0, currVal_1); var currVal_2 = (_co.files.length > 0); _ck(_v, 10, 0, currVal_2); _ck(_v, 13, 0); var currVal_4 = !_co.loading; _ck(_v, 16, 0, currVal_4); var currVal_5 = (_co.loading && (_co.projectId !== undefined)); _ck(_v, 18, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = (((_co.projectId === undefined) || (_co.gtacInput == null)) || (_co.gtacInput["isAccepted"] === false)); _ck(_v, 14, 0, currVal_3); }); }
export function View_PreUploadComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "pre-upload", [], null, null, null, View_PreUploadComponent_0, RenderType_PreUploadComponent)), i1.ɵdid(1, 114688, null, 0, i10.PreUploadComponent, [i11.ProjectService, i12.FileService, i13.UploadDataService, i8.UserService, i14.ConverterService, i15.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PreUploadComponentNgFactory = i1.ɵccf("pre-upload", i10.PreUploadComponent, View_PreUploadComponent_Host_0, {}, { projectCreatedEmitter: "projectCreatedEmitter", fileUploadedEmitter: "fileUploadedEmitter" }, []);
export { PreUploadComponentNgFactory as PreUploadComponentNgFactory };
