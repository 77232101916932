import {Project} from "../dto/project";
import {Task, TaskSpec} from "../dto/job";

export class Activity {
  public static readonly TRANSLATION: number = 0;
  public static readonly SWORN_TRANSLATION: number = 1;
  public static readonly REVISION: number = 2;
}

export function getProjectStatus(project: Project) {
  if (project.canceled) {
    return "Canceled";
  } else if (project.chargeid) {
    return "Completed";
  } else if ((project.startdate && project.startdate.length > 0) || (project.checkoutarchs && project.checkoutarchs.length > 0)) {
    return "Active";
  } else {
    return "Draft";
  }
}

export function getStatusColor(status: string) {
  if (status.toUpperCase() === "active".toUpperCase()) {
    return "var(--second-color)";
  } else if (status.toUpperCase() === "completed".toUpperCase()) {
    return "var(--soft-green)";
  } else if (status.toUpperCase() === "canceled".toUpperCase()) {
    return "var(--border-gray)";
  } else return "initial";
}

export function getSourceFiles(project: Project) {
  return project.files.filter(v => {
    return v.type === "SOURCE";
  });
}

export function getGeneralDocFiles(project: Project) {
  return getDocFiles(project).filter(f => f.docinfo == null || f.docinfo.source4pdf == null);
}

export function getDocFiles(project: Project) {
  return filterDocFiles(project.files);
}

export function filterDocFiles(files: any[]) {
  return files.filter(v => {
    return v.type === "DOC";
  });
}

export function extractSourceLang(langPair: string) {
  let idx = langPair.indexOf("_");
  if (idx > 0)
    return langPair.substring(0, idx);
  else return null
}

export function extractTargetLang(langPair: string) {
  let idx = langPair.indexOf("_");
  if (idx >= 0 && (idx + 1) < langPair.length)
    return langPair.substring(idx + 1);
  else return null
}

export function toLangPairString(langPair: any) {
  if (langPair.target == null) {
    return langPair.source;
  }
  return langPair.source + '_' + langPair.target;
}

export function getUserNameFromProject(project: Project, firstNameOnly: boolean) {
  if (project.requestedby != null)
    if (firstNameOnly) return getUserFirstName(project.requestedby);
    else return getUserName(project.requestedby);
  else return '';
}

export function getUserName(user: any) {
  let userName = '';
  if (user.firstName != null)
    userName += user.firstName;
  if (user.lastName != null) {
    if (userName.length > 0) userName += ' ';
    userName += user.lastName;
  }
  if (userName.length == 0 && user.name != null)
    userName = user.name;
  return userName;
}

export function getUserInitials(user: any) {
  let userInitials = '';
  if (user.firstName != null && user.firstName.length > 0)
    userInitials += user.firstName.slice(0, 1);
  if (user.lastName != null && user.lastName.length > 0)
    userInitials += user.lastName.slice(0, 1);
  if (userInitials.length > 0)
    return userInitials;
  else {
    if (user.name != null && user.name.length > 0)
      return user.name.slice(0, 1);
    else if (user.email != null && user.email.length > 0)
      return user.email.slice(0, 1);
    else return '?';
  }
}

export function getUserFirstName(user: any) {
  if (user.firstName != null && user.firstName.length > 0)
    return user.firstName;
  if (user.lastName != null && user.lastName.length > 0)
    return user.lastName;
  if (user.name != null && user.name > 0)
    return user.name;
  return '';
}

export function getFileExtension(fileName: string): string {
  let idx = fileName.lastIndexOf('.');
  if (idx >= 0 && (idx + 1) < fileName.length)
    return fileName.substr(idx + 1);
  else return "";
}

export function isTranslationTask(task: Task) {
  return isTranslationActivity(task.activity);
}

export function isTranslationActivity(activity: string) {
  return getActivitySequence(activity) == Activity.TRANSLATION;
}

export function isSwornTranslationActivity(activity: string) {
  return getActivitySequence(activity) == Activity.SWORN_TRANSLATION;
}

export function isRevisionActivity(activity: string) {
  return getActivitySequence(activity) == Activity.REVISION;
}

export function sortActivities(activities: any[]) {
  return activities.sort((a1, a2) => {
    let seq1 = getActivitySequence(a1);
    let seq2 = getActivitySequence(a2);
    if (seq1 > seq2)
      return 1;
    else if (seq1 < seq2)
      return -1;
    else
      return 0;
  });
}

export function getActivitySequence(activity: string) {
  let act = activity.toLowerCase();
  switch (act) {
    case "translation":
      return Activity.TRANSLATION;
    case "revision":
      return Activity.REVISION;
    case "sworn translation":
      return Activity.SWORN_TRANSLATION;
    default:
      return null;
  }
}
