import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Component, Inject} from "@angular/core";
import {ProjectService} from "../../service/project.service";
import {filterDocFiles, getFileExtension} from "../../util/jobutil";
import {MatSnackBar} from '@angular/material';


@Component({
  selector: 'documentation-pdf-dialog',
  template: `
	  <mat-dialog-content class="projectDialog">
		  <h3>Upload a PDF version of file</h3>
		  <h3 style="margin-bottom: 25px;">{{this.srcFile.name}}</h3>
		  <p>This PDF file will be available for the translator/reviser and provides them with greater context on the
			  content.<br/>
			  This will improve the quality of the translation.</p>
		  <div style="display: flex; justify-content: center; align-items: center; margin: 15px 0 5px 0; width: 100%;">
			  <label *ngIf="docPdf" style="font-size: 15px; padding: 3px;">
				  {{docPdf.name}} <span *ngIf="docPdf.size">({{docPdf.size | filesize}})</span>
			  </label>
		  <label *ngIf="isDocPdfFile()" for="uploadFile"
				 class="buttonLabel" style="color: white;" title="Select another file">Change</label>
			  <input id="uploadFile" type="file" (change)="selectFile($event)" placeholder="Upload PDF"
					 style="display: none;"
					 accept=".pdf, application/pdf"/>
		  </div>
		  <p *ngIf="errorMsg" style="color: red;">{{errorMsg}}</p>
		  <div class="buttons">
		  <label *ngIf="!docPdf" for="uploadFile"
				 class="buttonLabel confirm" style="color: white;" title="Select a PDF file">Select a PDF</label>
		  <label class="buttonLabel confirm" style="display: flex;" title="Remove uploaded PDF"
				 *ngIf="docPdf && !isDocPdfFile()" (click)="removeDocPdf()">
			  <span *ngIf="!isRemoving">Remove PDF</span>
			  <img *ngIf="isRemoving" src="../../../assets/loading_small_orb_whiteonblue.gif"
				   alt="Removing..." style="width: 15px;"/>
		  </label>
		  <button *ngIf="isDocPdfFile()" [disabled]="!isDocPdfFile()"
				  class="confirm" (click)="upload()">Upload & close
		  </button>
			  <button class="cancel" (click)="dialogRef.close()">Cancel</button>
		  </div>
	  </mat-dialog-content>`,
  styleUrls: ["../project.component.css"]
})
export class DocumentationPDFDialog {

  constructor(public dialogRef: MatDialogRef<DocumentationPDFDialog>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private snackbar: MatSnackBar,
              private projectService: ProjectService) {
  }

  projectId: string;
  projectFiles = [];
  srcFile;
  docPdf;
  errorMsg: string;
  isRemoving = false;


  ngOnInit(): void {
    if (this.data.projectId)
      this.projectId = this.data.projectId;
    if (this.data.srcFile)
      this.srcFile = this.data.srcFile;
    if (this.data.projectFiles)
      this.setProjectFiles(this.data.projectFiles);
    else
      this.projectService.getProject(this.projectId)
        .subscribe(p => {
          this.setProjectFiles(p.files)
        });
  }

  setProjectFiles(files: any[]) {
    this.projectFiles = files;
    this.docPdf = this.getPdf4Source(this.srcFile.name);
  }

  selectFile(event: any) {
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      let file: File = fileList[0];
      if (getFileExtension(file.name).toLowerCase() !== "pdf") {
        this.errorMsg = "Oops, file " + file.name + " does not appear to be a PDF? Please select another file.";
        return;
      }
      let docFiles = filterDocFiles(this.projectFiles);
      if (docFiles && docFiles.find(v => v.name === file.name)) {
        this.errorMsg = "Sorry, you cannot add 2 files with the same name, and a file with name " + file.name + " already exists.";
        return;
      }
      this.errorMsg = null;
      this.docPdf = file;
    }
  }

  enableUpload() {
    return this.docPdf !== undefined && (this.docPdf instanceof File);
  }

  isDocPdfFile() {
    return this.docPdf !== undefined && (this.docPdf instanceof File);
  }

  upload() {
    this.dialogRef.close({
      docPdf: this.docPdf
    });
  }

  private getPdf4Source(srcFileName: any) {
    if (this.projectFiles) {
      return filterDocFiles(this.projectFiles)
        .filter(f => {
          return f.docinfo != undefined && f.docinfo.source4pdf != undefined && f.docinfo.source4pdf === srcFileName;
        })[0];
    } else return null;
  }

  removeDocPdf() {
    if (this.docPdf != null && !this.isRemoving) {
      this.isRemoving = true;
      this.projectService.removeDocFile(this.docPdf.name, this.projectId)
        .subscribe(
          v => {
            console.debug("Project " + this.projectId + ": removed documentation file '" + this.docPdf.name + "'");
            // Remove the documentation file from the collection
            this.projectFiles.filter(v => v.type === "DOC" && v.name === this.docPdf.name)
              .forEach(f => {
                let idx = this.projectFiles.indexOf(f);
                this.projectFiles.splice(idx, 1);
              });
            this.docPdf = null;
            this.isRemoving = false;
          },
          error => {
            console.error("Error removing documentation file " + this.docPdf.name, error);
            this.snackbar.open("Unable to remove documentation file " + this.docPdf.name + ": " +
              error.status + " " + error.statusText, "", {
              duration: 2000
            });
            this.isRemoving = false;
          }, () => this.isRemoving = false
        )
    }
  }

}
