import {Component, EventEmitter, Input, Output} from "@angular/core";

@Component({
  selector: 'hubspot-page',
  templateUrl: './hubspot-page.component.html',
  styleUrls:['../projectcomponent/project.component.css']
})

export class HubspotPageComponent {

  @Input() page;
  @Input() allPages;

  masterPage;

  isSelected=false;


  @Output() onSelect = new EventEmitter<string>();

  constructor(){}

  ngOnInit(){
    //Fetch the master page
    if(this.page.translated_from_id){
      this.masterPage = this.allPages.find(p => p.id === this.page.translated_from_id);
      console.debug(this.masterPage);
      console.debug("MasterPage");
    }
  }

  toggleSelect(){
    this.isSelected=!this.isSelected;
    this.onSelect.emit(JSON.stringify({
      pageId:this.page.id,
      selected: this.isSelected
    }))
  }



}
