import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from "@angular/core";
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {DomainService} from "../service/domain.service";


@Component({
  selector: "domain",
  templateUrl: './domain.component.html',
  styleUrls: ['./domain.component.css']
})
export class DomainComponent {

  myControl: FormControl = new FormControl();
  options = [];
  filteredOptions: Observable<string[]>;
  value;

  @Input()
  domains = [];
  @Output()
  domainEmitter = new EventEmitter();
  @ViewChild('domaininputter', { static: true }) domainInputter: ElementRef;

  constructor(public domainService: DomainService) {

  }

  ngOnInit() {
    this.domainService.getDomains().subscribe(d => {
      this.options = d;
      this.initFilteredOptions();
    });
  }

  initFilteredOptions() {
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(v => v as string),
      map(val => {
          return this.filter(val as string)
        }),);
  }

  filter(val: string): string[] {
    return this.options.filter(option =>
      option.toLowerCase().indexOf(val.toLowerCase()) === 0);
  }

  public add() {
    if (this.myControl.value.toString().length > 1) {
      this.domains.push(this.myControl.value);
      this.domainEmitter.emit({action: "add", value: this.myControl.value});
      if (this.options.indexOf(this.myControl.value) < 0)
        this.options.push(this.myControl.value);
      this.myControl.setValue("");
      this.domainInputter.nativeElement.blur();
      this.initFilteredOptions()
    }
  }

  public remove(domain) {
    this.domains.splice(this.domains.indexOf(domain), 1);
    this.domainEmitter.emit({action: "remove", value: domain});
  }
}
