/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../project.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./file-add.component";
var styles_FileAddComponent = [i0.styles];
var RenderType_FileAddComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FileAddComponent, data: {} });
export { RenderType_FileAddComponent as RenderType_FileAddComponent };
function View_FileAddComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.infoText; _ck(_v, 2, 0, currVal_0); }); }
export function View_FileAddComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["style", "display: flex;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "label", [["class", "addFilesCircle"]], [[8, "htmlFor", 0]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["+"])), (_l()(), i1.ɵeld(3, 0, null, null, 6, "div", [["class", "addFileText"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "addFileLabelDiv"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "label", [], [[8, "htmlFor", 0]], null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, [["{{id}}", 1]], null, 0, "input", [["multiple", ""], ["style", "display: none;"], ["type", "file"]], [[8, "id", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.uploadFiles($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileAddComponent_1)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_4 = (_co.infoText != null); _ck(_v, 9, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.id, ""); _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵinlineInterpolate(1, "", _co.id, ""); _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.label; _ck(_v, 6, 0, currVal_2); var currVal_3 = i1.ɵinlineInterpolate(1, "", _co.id, ""); _ck(_v, 7, 0, currVal_3); }); }
export function View_FileAddComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "file-add", [], null, null, null, View_FileAddComponent_0, RenderType_FileAddComponent)), i1.ɵdid(1, 49152, null, 0, i3.FileAddComponent, [], null, null)], null, null); }
var FileAddComponentNgFactory = i1.ɵccf("file-add", i3.FileAddComponent, View_FileAddComponent_Host_0, { label: "label", infoText: "infoText", id: "id" }, { onFileUpload: "onFileUpload" }, []);
export { FileAddComponentNgFactory as FileAddComponentNgFactory };
