import {map, mergeMap} from 'rxjs/operators';
import {AfterViewInit, ApplicationRef, Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {UserService} from "../service/user.service";
import {ConfirmDialog} from "../confirm.dialog";
import {SimpleDialog} from "../simple-dialog.component";


declare const gapi: any;

@Component({
  selector: 'user-signin-dialog',
  templateUrl: 'user.signin.component.html',
  styleUrls: ['./user.settings.component.css']
})
export class UserSigninDialog implements AfterViewInit {

  email;
  password;
  passwordFieldType = "password";

  failed = false;

  constructor(public dialogRef: MatDialogRef<UserSigninDialog>,
              @Inject(MAT_DIALOG_DATA) public successText: string,
              public userservice: UserService,
              public dialog: MatDialog,
              public applicationRef: ApplicationRef,
              public router: Router
  ) {
    dialogRef.disableClose = true;
  }

  authJoining = false;

  signInWithLinkedIn() {
    this.userservice.openLinkedInAuth();
    this.authJoining = true;
    onstorage = event => {
      if (event.key == "linkedInAuthCode") {
        this.userservice.authLinkedIn(event.newValue).pipe(
          map(e => {
            localStorage.setItem("linkedInAccessToken", JSON.stringify(e));
            return e;
          }),
          mergeMap(token =>
            this.userservice.linkedInProfile(token["access_token"])
          ),
          mergeMap(profile => {
              return this.userservice.signInWithAuth(profile);
            }
          ),)
          .subscribe(
            signInData => {
              console.debug("Signing in with LinkedIn", signInData);
              this.dialogRef.close("success");
            },
            error => this.openJoinInstead(),
            () => this.authJoining = false
          )
      }
    }
  }

  //TODO Streamline with linked in process or the other way around (most conventional)
  auth2: any;

  googleInit() {
    gapi.load('auth2', () => {
      this.auth2 = gapi.auth2.init({
        client_id: '49647298930-jjon9r6naht63oq9mc9b9cc9avm5ttt4.apps.googleusercontent.com',
        cookiepolicy: 'single_host_origin',
        scope: 'profile email'
      });
      this.attachSignin(document.getElementById('googleBtn'));
    });
  }

  attachSignin(element) {
    element.onclick = () => {
      this.authJoining = true;
    };
    this.auth2.attachClickHandler(element, {},
      (googleUser) => {
        //List of fields we want to retrieve from linked in
        let userData = this.mapGoogleToUser(googleUser);
        this.userservice.signInWithAuth(userData).subscribe(
          signInData => {
            this.dialogRef.close("success");
            this.applicationRef.tick();
          },
          error => {
            this.openJoinInstead();
            this.applicationRef.tick();
          },
          () => this.authJoining = false
        );
      }
    );
  }

  ngAfterViewInit() {
    this.googleInit();
  }

  openJoinInstead() {
    this.dialogRef.close("join")
  }

  joining = false;

  signInViaMail() {
    this.joining = true;
    this.failed = false;
    try {
      this.userservice.signInWithMail(this.email, this.password).subscribe(
        r => {
          this.dialogRef.close("success")
        },
        error => {
          console.error("Error signing in via mail", error);
          this.failed = true;
          this.joining = false;
        },
        () => this.joining = false
      );
    } catch (e) {
      console.error("Login error on sign-in", e);
      this.failed = true;
      this.joining = false;
    }
  }

  resetPassword() {
    if (this.email == undefined) {
      let errorRef = this.dialog.open(SimpleDialog, {
        width: '400px',
        panelClass: 'simpleDialog',
        data: {
          dialogMessage: "Please fill in your email address in the login form first."
        }
      });
    } else {
      let confirmRef = this.dialog.open(ConfirmDialog, {
        width: '400px',
        panelClass: 'confirmDialog',
        data: {
          confirmTitle: "Are you sure?",
          confirmMessage: "If you decide to reset your password, you will receive an email at " + this.email + " with " +
            "instructions on how to proceed.",
          confirmText: 'Yes, reset',
          cancelText: 'No, nevermind',
        }
      });
      confirmRef.afterClosed().subscribe(r => {
        if (r === "confirm") {
          this.userservice.resetPassword(this.email).subscribe(
            m => {
              let successRef = this.dialog.open(SimpleDialog, {
                width: '400px',
                panelClass: 'simpleDialog',
                data: {
                  dialogMessage: "An email has been sent to " + this.email + ". Please follow the instructions."
                }
              });
              successRef.afterClosed().subscribe(r =>
                this.dialogRef.close());
            }
          )
        }
      });
    }
  }

  showPassword() {
    this.passwordFieldType = "text";
  }

  hidePassword() {
    this.passwordFieldType = "password";
  }

  private mapGoogleToUser(googleUser: any) {
    let profile = googleUser.getBasicProfile();
    return {
      googleToken: googleUser.getAuthResponse().id_token,
      id: profile.getId(),
      firstName: profile.getName(),
      email: profile.getEmail()
    }
  }
}
