/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./customer-group-list.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../util/component/dynamic-table/dynamic-table.component.ngfactory";
import * as i3 from "../../../util/component/dynamic-table/dynamic-table.component";
import * as i4 from "@angular/common";
import * as i5 from "@angular/forms";
import * as i6 from "./customer-group-list.component";
import * as i7 from "../../../service/user.service";
import * as i8 from "@angular/material/snack-bar";
var styles_CustomerGroupListComponent = [i0.styles];
var RenderType_CustomerGroupListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CustomerGroupListComponent, data: {} });
export { RenderType_CustomerGroupListComponent as RenderType_CustomerGroupListComponent };
function View_CustomerGroupListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" You do not have the correct privileges to access this page.\n"]))], null, null); }
function View_CustomerGroupListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "dynamic-table", [], [[2, "dtable-hover", null]], [[null, "onClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClick" === en)) {
        var pd_0 = (_co.groupSelected($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_DynamicTableComponent_0, i2.RenderType_DynamicTableComponent)), i1.ɵdid(2, 638976, null, 0, i3.DynamicTableComponent, [i4.DatePipe, i1.ChangeDetectorRef], { tableData: [0, "tableData"], tableHeaders: [1, "tableHeaders"], clickable: [2, "clickable"] }, { onClick: "onClick" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.customerGroups; var currVal_2 = _co.headers; var currVal_3 = true; _ck(_v, 2, 0, currVal_1, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).clickable; _ck(_v, 1, 0, currVal_0); }); }
function View_CustomerGroupListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "p", [["style", "margin: 5px 0 15px 0;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "link-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Format for invite-link for customer group:"])), (_l()(), i1.ɵted(-1, null, ["\u00A0 "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "link-format"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", "/join?grouptoken=<token>"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomerGroupListComponent_3)), i1.ɵdid(8, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 8, "div", [["class", "create-new-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 5, "input", [["placeholder", "Enter name for new group"], ["title", "Enter name for new group"], ["type", "text"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 11).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 11)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 11)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.newGroupName = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(11, 16384, null, 0, i5.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i5.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i5.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i5.DefaultValueAccessor]), i1.ɵdid(13, 671744, null, 0, i5.NgModel, [[8, null], [8, null], [8, null], [6, i5.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i5.NgControl, null, [i5.NgModel]), i1.ɵdid(15, 16384, null, 0, i5.NgControlStatus, [[4, i5.NgControl]], null, null), (_l()(), i1.ɵeld(16, 0, null, null, 1, "button", [], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.createNew() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Create "]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.customerGroups !== null); _ck(_v, 8, 0, currVal_1); var currVal_9 = _co.newGroupName; _ck(_v, 13, 0, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.host; _ck(_v, 6, 0, currVal_0); var currVal_2 = i1.ɵnov(_v, 15).ngClassUntouched; var currVal_3 = i1.ɵnov(_v, 15).ngClassTouched; var currVal_4 = i1.ɵnov(_v, 15).ngClassPristine; var currVal_5 = i1.ɵnov(_v, 15).ngClassDirty; var currVal_6 = i1.ɵnov(_v, 15).ngClassValid; var currVal_7 = i1.ɵnov(_v, 15).ngClassInvalid; var currVal_8 = i1.ɵnov(_v, 15).ngClassPending; _ck(_v, 10, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); var currVal_10 = !_co.isNewButtonValid(); _ck(_v, 16, 0, currVal_10); }); }
export function View_CustomerGroupListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h2", [["style", "margin: 25px 0;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Customer groups"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomerGroupListComponent_1)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomerGroupListComponent_2)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.userService.isCurrentUserAdmin; _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.userService.isCurrentUserAdmin == true); _ck(_v, 5, 0, currVal_1); }, null); }
export function View_CustomerGroupListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "customer-group-list", [], null, null, null, View_CustomerGroupListComponent_0, RenderType_CustomerGroupListComponent)), i1.ɵdid(1, 114688, null, 0, i6.CustomerGroupListComponent, [i7.UserService, i8.MatSnackBar], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomerGroupListComponentNgFactory = i1.ɵccf("customer-group-list", i6.CustomerGroupListComponent, View_CustomerGroupListComponent_Host_0, {}, {}, []);
export { CustomerGroupListComponentNgFactory as CustomerGroupListComponentNgFactory };
