import {Component, Input, SimpleChanges} from "@angular/core";
import {TaskService} from "../../service/task.service";
import {UserService} from "../../service/user.service";
import {ReviewService} from "../../service/review.service";
import {getUserName} from "../../util/jobutil";


@Component({
  selector: 'review-item',
  templateUrl: "./review.item.component.html",
  styleUrls: ["../user-profile.component.css"],
})
export class ReviewItemComponent {

  @Input()
  index;
  @Input()
  review;
  @Input()
  currentUser;

  poster;
  starCount: number;

  constructor(private reviewService: ReviewService,
              private taskService: TaskService,
              private userService: UserService) {

  }

  ngOnInit() {
    this.setReview();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.index) {
      this.index = changes.index.currentValue;
    }
    if (changes.review) {
      this.review = changes.review.currentValue;
      this.setReview();
    }
  }

  setReview() {
    if (this.review !== undefined) {
      if (this.review.writer != undefined)
        this.userService.getUser(this.review.writer.id).subscribe(u => {
          this.poster = u;
        });
      else {
        // Create a fake user if we have no data on who posted the review
        let u = Object();
        u.name = "Anonymous";
        u.pcolor = "#B5B5B5";
        this.poster = u;
      }
      this.calcStarCount();
    }
  }

  getPosterName() {
    return getUserName(this.poster);
  }

  private calcStarCount() {
    let scoreCount = this.review.scores.length;
    let scoreTotal = 0;
    this.review.scores.forEach(s => scoreTotal += s.rate);
    let scoreAvg = scoreTotal / (scoreCount > 0 ? scoreCount : 1);
    this.starCount = scoreAvg / 25 + 1;
  }
}
