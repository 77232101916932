import {from as observableFrom} from 'rxjs';
import {map, toArray} from 'rxjs/operators';
import {Component, EventEmitter, Input, Output, SimpleChanges, ViewChild} from "@angular/core";
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {UserService} from "../service/user.service";
import {LanguageService} from "../service/language.service";


@Component({
  selector: 'competence-list',
  templateUrl: 'competence-list.component.html',
  styleUrls: ['./competence-list.component.css']
})
export class CompetenceListComponent {

  @Input()
  competences: any[];
  private selection;
  private charBasedLanguages;

  competenceRows: CompetenceRow[] = undefined;
  competenceData = new MatTableDataSource<any>();
  availCols = ['selectBox', 'activity', 'sourceLang', 'targetLang', 'price'];
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  @Output()
  onSelectionChange = new EventEmitter<any>();
  @Output()
  onPriceChange = new EventEmitter<any>();


  constructor(public userService: UserService,
              public languageService: LanguageService) {
  }

  ngOnInit() {
    this.languageService.getCharBasedLanguages().subscribe(cb => this.charBasedLanguages = cb);
    if (this.competences != undefined) this.prepCompetenceData();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.competences != undefined) {
      this.competences = changes.competences.currentValue;
      // Reset selection
      this.selection = undefined;
      this.prepCompetenceData();
    }
  }

  ngAfterViewInit() {
    this.competenceData.sort = this.sort;
  }

  @ViewChild(MatSort, {static: false}) set matSort(ms: MatSort) {
    this.sort = ms;
    this.competenceData.sort = this.sort;
  }

  isCharacterBasedLang(lang: string) {
    if (this.charBasedLanguages)
      return this.charBasedLanguages.indexOf(lang) >= 0;
    return false;
  }

  private prepCompetenceData() {
    let start = Date.now();
    observableFrom(this.competences).pipe(map(c => {
      let row = new CompetenceRow();
      row.id = c.id;
      row.activity = c.activity;
      // todo: use actual language name here, so the sorting sorts properly
      row.sourceLang = c.source;
      row.targetLang = c.target;
      row.price = c.price;
      row.origObj = c;
      return row;
    }), toArray(),).subscribe(rows => {
      this.competenceRows = rows;
      this.competenceData.data = this.competenceRows;
      console.debug("Displaying " + this.competenceRows.length + " competences took " + (Date.now() - start) + " ms.");
    });
  }

  selectionChanged($event: Event, elem: CompetenceRow) {
    if (this.selection == undefined) this.selection = [];
    let idx = this.selection.indexOf(elem.origObj);
    if (idx >= 0)
      this.selection.splice(idx, 1);
    else this.selection.push(elem.origObj);
    this.onSelectionChange.emit(this.selection);
  }

  priceChanged($event: Event, elem: CompetenceRow) {
    let obj = Object();
    obj.competence = elem.origObj;
    obj.newprice = elem.price;
    this.onPriceChange.emit(obj);
  }
}

// Small data-class that holds the competence data for each row
class CompetenceRow {
  // Note that for the sorting to work these variable names need to be identical to the 'matColumnDef' property of the
  // column in the Material table
  id: string;
  activity: string;
  sourceLang: string;
  targetLang: string;
  price;
  origObj: any;
}
