/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./user.settings.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../projectcomponent/util/vendor-profile.component.ngfactory";
import * as i3 from "../projectcomponent/util/vendor-profile.component";
import * as i4 from "../service/vendor.service";
import * as i5 from "../service/user.service";
import * as i6 from "../service/pricing.services";
import * as i7 from "../service/statistics.service";
import * as i8 from "@angular/router";
import * as i9 from "@angular/common";
import * as i10 from "../reviewcomponent/review.full.component.ngfactory";
import * as i11 from "../reviewcomponent/review.full.component";
import * as i12 from "../service/review.service";
import * as i13 from "../service/task.service";
import * as i14 from "../util/language.pipe";
import * as i15 from "../service/language.service";
import * as i16 from "./profile.info.component";
var styles_ProfileInfoComponent = [i0.styles];
var RenderType_ProfileInfoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProfileInfoComponent, data: {} });
export { RenderType_ProfileInfoComponent as RenderType_ProfileInfoComponent };
function View_ProfileInfoComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "vendor-profile", [], null, null, null, i2.View_VendorProfileComponent_0, i2.RenderType_VendorProfileComponent)), i1.ɵdid(1, 770048, null, 0, i3.VendorProfileComponent, [i4.VendorService, i5.UserService, i6.PricingService, i7.StatisticsService, i8.Router], { profile: [0, "profile"], maxDescriptionSize: [1, "maxDescriptionSize"], maxDomainCount: [2, "maxDomainCount"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.user; var currVal_1 = undefined; var currVal_2 = undefined; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_ProfileInfoComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", ": ", " -> ", " "])), i1.ɵppd(2, 1), i1.ɵpid(131072, i9.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵppd(4, 1), i1.ɵpid(131072, i9.AsyncPipe, [i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.activity; var currVal_1 = i1.ɵunv(_v, 1, 1, i1.ɵnov(_v, 3).transform(i1.ɵunv(_v, 1, 1, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent.parent, 0), _v.context.$implicit.source)))); var currVal_2 = i1.ɵunv(_v, 1, 2, i1.ɵnov(_v, 5).transform(i1.ɵunv(_v, 1, 2, _ck(_v, 4, 0, i1.ɵnov(_v.parent.parent.parent, 0), _v.context.$implicit.target)))); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }); }
function View_ProfileInfoComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Competences"])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProfileInfoComponent_4)), i1.ɵdid(5, 278528, null, 0, i9.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.user.competences; _ck(_v, 5, 0, currVal_0); }, null); }
function View_ProfileInfoComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "profileinfo"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProfileInfoComponent_2)), i1.ɵdid(2, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProfileInfoComponent_3)), i1.ɵdid(4, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Reviews"])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "rating-full-component", [], null, null, null, i10.View_RatingFullComponent_0, i10.RenderType_RatingFullComponent)), i1.ɵdid(9, 114688, null, 0, i11.RatingFullComponent, [i12.ReviewService, i13.TaskService, i5.UserService, i8.ActivatedRoute], { userId: [0, "userId"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.user; _ck(_v, 2, 0, currVal_0); var currVal_1 = ((_co.user && _co.user.competences) && (_co.user.competences.length > 0)); _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.userId; _ck(_v, 9, 0, currVal_2); }, null); }
export function View_ProfileInfoComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i14.LanguagePipe, [i15.LanguageService]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProfileInfoComponent_1)), i1.ɵdid(2, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.userId != undefined); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ProfileInfoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "profile-info", [], null, null, null, View_ProfileInfoComponent_0, RenderType_ProfileInfoComponent)), i1.ɵdid(1, 114688, null, 0, i16.ProfileInfoComponent, [i8.ActivatedRoute, i4.VendorService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProfileInfoComponentNgFactory = i1.ɵccf("profile-info", i16.ProfileInfoComponent, View_ProfileInfoComponent_Host_0, { userId: "userId" }, {}, []);
export { ProfileInfoComponentNgFactory as ProfileInfoComponentNgFactory };
