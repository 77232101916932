import {Injectable} from '@angular/core';
import {Observable} from "rxjs";


@Injectable()
export class UploadDataService {

  uploadData: Map<String, { [fileName: string]: { progressdata: UploadProgressData } }> = new Map();

  getUploadData(pId: string) {
    return this.uploadData.get(pId);
  }

  addUploadData2(pId: string, data: { [fileName: string]: { progress: Observable<number> } }) {
    for (let key in data) {
      this.addUploadData(pId, key, data[key].progress);
    }
  }

  addUploadData(pId: string, fileName: string, progress: Observable<number>) {
    // console.log("ADD data service => " + fileName + " => " + progress);
    let tempData: { [p: string]: { progressdata: UploadProgressData } };
    if (this.uploadData.has(pId) && this.uploadData.get(pId) != null) {
      tempData = this.uploadData.get(pId);
    } else tempData = {};
    let d = new UploadProgressData(progress);
    tempData[fileName] = {progressdata: d};
    this.uploadData.set(pId, tempData);
  }

  removeUploadData(pId: string, fileName: string) {
    // console.log("REMOVE data service => " + fileName);
    if (this.uploadData.has(pId) && this.uploadData.get(pId) != null) {
      let tempData = this.uploadData.get(pId);
      if (tempData[fileName] != null) {
        delete tempData[fileName];
      }
    }
  }
}

export class UploadProgressData {

  progressObs: Observable<number>;
  lastVal: number = -1;

  constructor(progress: Observable<number>) {
    this.progressObs = progress;
    // Subscribe to the observer to keep track of the last value
    this.progressObs.subscribe(v => {
        this.lastVal = v;
      }
    )
  }
}
