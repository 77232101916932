import {map, switchMap} from 'rxjs/operators';
import {Component} from "@angular/core";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {ProjectService} from "../service/project.service";
import {FileService} from "../service/file.service";
import {extractSourceLang, extractTargetLang} from "../util/jobutil";
import {Project} from "../dto/project";
import {EditorService} from "../editor/service/editor.service";
import {HelpDialogComponent} from "../util/help.dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {HttpClient} from "@angular/common/http";


@Component({
  selector: 'trans-check',
  templateUrl: 'trans-check.component.html',
  styleUrls: ['../projectcomponent/project.component.css']
})
export class TransCheckComponent {

  pId: string = undefined;
  source: string = undefined;
  target: string = undefined;

  project: Project = undefined;
  sourcefiles: any[] = undefined;

  selectedFile = undefined;

  constructor(private projectService: ProjectService,
              private fileService: FileService,
              private editorService: EditorService,
              private route: ActivatedRoute,
              private router: Router,
              private dialog: MatDialog,
              private http: HttpClient,) {

  }

  ngOnInit() {
    this.route.params.pipe(
      switchMap((params: Params) => {
        this.pId = params['id'];
        return this.projectService.getProjectBaseInfo(params['id']);
      }))
      .subscribe(project => {
        this.setProject(<Project>project);
      });
    this.route.params.pipe(
      map((params: Params) => {
        return params['langPair'];
      }),
      map((lp: string) => {
        return {source: extractSourceLang(lp), target: extractTargetLang(lp)}
      }),)
      .subscribe((lp: any) => {
          this.source = lp.source;
          this.target = lp.target;
        }
      );
  }

  setProject(project: Project) {
    this.project = project;
    this.sourcefiles = null;
    //only show the files which have
    this.fileService.fetchAvailableFinals(this.pId, this.source + "_" + this.target).pipe(
      map(availableFiles => {
        return availableFiles as Array<string>
      }),
      map(availableFiles => {
        return this.project.files.filter(f => {
          return availableFiles.indexOf(f.name) >= 0
        })
      }),)
      .subscribe(files => {
        this.initSourceFiles(files);
      });
  }

  initSourceFiles(files: any[]) {
    this.sourcefiles = files;
    if (this.sourcefiles.length > 0)
      this.selectedFile = this.sourcefiles[0].name;
    this.sourcefiles
      .forEach(f => {
          let fileGroupId = this.pId + "_" + this.source + "_" + this.target + "_FDC";
          this.editorService.fetchMetaData(fileGroupId, f.name)
            .subscribe(i => {
              f.metaData = i;
            })
        }
      )
  }

  openEditor(fileName) {
    this.selectedFile = fileName;
  }

  finalized(fileName: string) {
    this.selectedFile = undefined;
    let sourceFile = this.findSourceFile(fileName);
    if(sourceFile!=undefined){
      if(sourceFile.metaData==undefined)
        sourceFile.metaData = {'saved': Date.now()};
      else
        sourceFile.metaData.saved = Date.now();
    }
  }

  changes(fileName: string) {
    let sourceFile = this.findSourceFile(fileName);
    if(sourceFile!=undefined){
      if(sourceFile.metaData==undefined)
        sourceFile.metaData = {'change': Date.now()};
      else
        sourceFile.metaData.change=Date.now();
    }
  }

  hasUnsavedChanges(file: any):boolean {
    if(file.metaData!=undefined && file.metaData.change!=undefined){
      return file.metaData.change >= file.metaData.saved
    }
    return false;
  }

  private findSourceFile(fileName:string):any{
    return this.sourcefiles.find(f => f.name==fileName)
  }

  openHelp() {
    this.http.get('assets/help/transcheck-help.html', {responseType: 'text'})
      .subscribe(helptext =>{
        let dialogRef = this.dialog.open(HelpDialogComponent, {
          width: '700px',
          height: '500px',
          data: {helpTitle: "Why and how should you do translation checking?", helpHtmlContent: helptext}
        });
        }
      );
  }
}
