/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./drop-entry.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../util/filename.pipe";
import * as i4 from "../util/filesize.pipe";
import * as i5 from "./pre-upload-file.component";
var styles_PreUploadFileComponent = [i0.styles];
var RenderType_PreUploadFileComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PreUploadFileComponent, data: {} });
export { RenderType_PreUploadFileComponent as RenderType_PreUploadFileComponent };
function View_PreUploadFileComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "p", [["class", "fileSize"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵppd(2, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent, 1), _co.file.size)); _ck(_v, 1, 0, currVal_0); }); }
function View_PreUploadFileComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["class", "fileWarning"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Oops! We don't support this format."]))], null, null); }
function View_PreUploadFileComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "preUploadItem preUploadFile"], ["style", ""]], [[8, "title", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "fileInfo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "h5", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(4, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(5, { "text-decoration": 0 }), (_l()(), i1.ɵted(6, null, ["", ""])), i1.ɵppd(7, 3), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PreUploadFileComponent_2)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PreUploadFileComponent_3)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 2, "div", [["class", "removeDiv"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "button", [["class", "removeFileCircle"], ["title", "Remove file"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.emitFileRemove() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 0, "img", [["alt", "Remove file"], ["src", "../../assets/delete_icon_resized.svg"], ["style", "width: 10px;"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _ck(_v, 5, 0, ((_co.file.warning != null) ? "line-through" : "initial")); _ck(_v, 4, 0, currVal_1); var currVal_3 = (_co.file.warning == null); _ck(_v, 9, 0, currVal_3); var currVal_4 = (_co.file.warning != null); _ck(_v, 11, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.file.name; _ck(_v, 0, 0, currVal_0); var currVal_2 = i1.ɵunv(_v, 6, 0, _ck(_v, 7, 0, i1.ɵnov(_v.parent, 0), _co.file.name, 24, true)); _ck(_v, 6, 0, currVal_2); }); }
export function View_PreUploadFileComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i3.FilenamePipe, []), i1.ɵpid(0, i4.FilesizePipe, []), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PreUploadFileComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.file; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_PreUploadFileComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "pre-upload-file", [], null, null, null, View_PreUploadFileComponent_0, RenderType_PreUploadFileComponent)), i1.ɵdid(1, 49152, null, 0, i5.PreUploadFileComponent, [], null, null)], null, null); }
var PreUploadFileComponentNgFactory = i1.ɵccf("pre-upload-file", i5.PreUploadFileComponent, View_PreUploadFileComponent_Host_0, { file: "file" }, { removeEmitter: "removeEmitter" }, []);
export { PreUploadFileComponentNgFactory as PreUploadFileComponentNgFactory };
