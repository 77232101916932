import {filter, map, publish} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {ConnectableObservable, Observable, Subject} from "rxjs";
import "rxjs";
import {HttpClient} from "@angular/common/http";
import {IntervalObservable} from "rxjs/observable/IntervalObservable";
import {isStatCategory, StatCategory} from "../util/statutil";
import {getBaseSocketUrl, getBaseUrl} from "../util/httpclient";


@Injectable()
export class StatisticsService {

  public messages: Observable<any>;
  private ws: Subject<any>;

  private STATISTICS_SOCKET_URL = getBaseSocketUrl() + "/statistics/feed";
  private STATISTICS_API = getBaseUrl() + "/api/v1/analysis/stats";


  constructor(private http: HttpClient) {
  }

  initStatsFeed() {
    this.ws = Observable.webSocket(this.STATISTICS_SOCKET_URL);
    //Keep the socket alive (every 4 minutes, server timeout is set to 5 minutes)
    IntervalObservable.create(30000).subscribe(o =>
      this.ws.next({'action':'keepalive'})
    );
    this.messages = this.makeHot(this.ws).pipe(filter(m => m != null));
    // this.messages.subscribe(msg => console.debug("> Received " + JSON.stringify(msg) + " @" + this.STATISTICS_SOCKET_URL),
    //   error => console.error(error));
  }

  //More info see analyses.service.ts
  makeHot<T>(cold: Observable<T>): Observable<T> {
    let obs = cold.pipe(publish()) as ConnectableObservable<T>;
    obs.connect();
    return obs;
  }

  enrollToStatFeed(pId: string, langPair: string): Observable<any> {
    if (this.ws === undefined || this.ws.closed)
      this.initStatsFeed();
    //To enroll you have to send a message to the socket with the action "enroll" and to which objects you want to enroll
    this.ws.next({'action':'enroll', 'pid': pId });
    //The JSON structure of the message normally has the objects parameters to identify and a "data" object which contains the actual information
    //E.g. {"pid":"10", "filename":"damocles.docx", "data":{"type":"sourcelang", "value":"en"}}
    return this.messages.pipe(
      map(msg => {
        return msg;
      }),
      filter(msg => {
        return msg.pid == pId && (!langPair || langPair == msg.langpair);
      }),
      )
  }

  enrollForProject(pId: string): Observable<any> {
    return this.enrollToStatFeed(pId, null).pipe(
      map(msg => msg.data),//TODO
      map(msg => {
        return msg;
      }),)
  }

  enrollForProjectAndLangPair(pId: string, langPair: string): Observable<any> {
    return this.enrollToStatFeed(pId, langPair).pipe(
    // Returning full object (and not just "data") because we need to know what language pair these stats are for
    //    .map(msg => msg.data)
      map(msg => {
        return msg;
      }))
  }

  getAllCategories(): Observable<any> {
    return this.http.get(this.STATISTICS_API + "/categories")
  }

  getCurrentProjectStatistics(pId: string): Observable<Map<string, number>> {
    let obs = this.http.get(this.STATISTICS_API + "/project/" + pId, {responseType: 'json'});
    return this.mapCurrentStatistics(obs);
  }

  /**
   * Deprecated since no stats are being calculated after MT anymore: we assume that the amount of no matches equals
   * the MT word count.
   *
   * @deprecated
   */
  getMtStatistics(pId: string): Observable<number> {
    let obs = this.http.get(this.STATISTICS_API + "/mt/project/" + pId, {responseType: 'json'});
    return this.mapMtStatistics(obs);
  }

  getCurrentLangpairStatistics(pId: string, langpair: string): Observable<Map<string, number>> {
    let obs = this.http.get(this.STATISTICS_API + "/langpair/" + pId + "/" + encodeURIComponent(langpair), {responseType: 'json'});
    return this.mapCurrentStatistics(obs);
  }

  getTaskStatistics(taskId: string): Observable<Map<string, number>> {
    let obs = this.http.get(this.STATISTICS_API + "/task/" + taskId, {responseType: 'json'});
    return this.mapCurrentStatistics(obs);
  }

  hasAllTMStats(pId: string): Observable<boolean> {
    return this.http.get<boolean>(this.STATISTICS_API + "/hasalltm/" + pId)
  }

  private mapCurrentStatistics(obs: Observable<any>): Observable<Map<string, number>> {
    return obs.pipe(
      map(values => {
        let map = new Map<string, number>();
        for (let v of values) {
          let currentValue = map.get(v.type);
          if (currentValue == null)
            currentValue = 0;
          currentValue += v.value;
          map.set(v.type, currentValue);
        }
        return map;
      }))
  }

  private mapMtStatistics(obs: Observable<any>): Observable<number> {
    return obs.pipe(
      map(result => {
        if (result && result.length > 0) {
          let count: number = 0;
          for (let v of result) {
            // Only take the "fuzzy" category into account
            // The "no match" category simply means no MT was done for those words (because it already had a good enough TM match)
            if (isStatCategory(v.type, StatCategory.fuzzy_match)) {
              count += v.value;
            }
          }
          return count;
        } else return null;
      }))
  }
}
