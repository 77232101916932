<div>

  <!--TODO Should perform an elastic search on all the possible vendors and their data-->
  <div style="">
    <div *ngIf="loading">
      <h4>Looking for the most suitable translators...</h4>
      <img src="../../../assets/loading_small.gif"
           style="width: 20px; height: 20px; vertical-align: middle; margin: 5px;"/>
    </div>
    <div *ngIf="allVendorCount <= 0 && !loading">
      <h4 style="color: darkred;">Oops, we don’t have anyone currently available for this language pair.</h4>
      <div>
        <div *ngIf="!contactRequestResult">
          However, please leave us your e-mail address, and we will look into our database of suitable translators and
          get back to you as soon as possible.
          <form name="form" (ngSubmit)="f.form.valid && onSubmitContactRequest()" #f="ngForm" novalidate>
            <div style="display: flex; align-items: center;">
              <span style="margin-left: 15px; margin-right: 10px;">E-mail:</span>
              <input type="text" class="form-control" name="email" placeholder="Enter your e-mail address"
                     [(ngModel)]="userEmail" #email="ngModel"
                     [ngClass]="{ 'is-invalid': f.submitted && email.invalid }" required email/>
              <button class="iconButton" style="width: auto; margin-right: 10px;">Go!</button>
              <div *ngIf="f.submitted && email.invalid">
                <span class="invalidFeedback" *ngIf="email.errors.required">E-mail is required.</span>
                <span class="invalidFeedback" *ngIf="!email.errors.required && email.errors.email">E-mail must be a valid e-mail address.</span>
              </div>
            </div>
          </form>
        </div>
        <div *ngIf="contactRequestResult" style="font-weight: bold;">{{contactRequestResult}}</div>
        <p>Or, if you have someone in mind yourself, you can simply invite them to join LiLo!<br/>
          They can use the following link to sign up: <span
            style="font-style: italic">www.lilo.global/newtranslator</span></p>
      </div>
    </div>
    <div *ngIf="filteredVendors">
      <div style="padding: 20px 20px 5px 20px;">
        <input type="text" name="search" class="searchField" style="margin-left: 1px;"
               placeholder="Search for translator"
               *ngIf="allVendorCount > 0"
               [(ngModel)]="filterText"
               (change)="setFilteredVendors()"/>&nbsp;
        <span style="font-style: italic"
              *ngIf="allVendorCount > 0 && vendorcount == 0">No matches for the given filter.</span>
      </div>
      <div style="display: flex; position: relative;">
        <vendor-list (eventEmitter)="selectVendor($event)"
                     [vendors]="filteredVendors"
                     [taskSpec]="taskSpec"
                     [showPricing]="pId != undefined"></vendor-list>
        <div style="position: absolute; bottom: 25px; right: -20px;" *ngIf="allVendorCount > vendorcount">
          <button (click)="showMorePartners()" class="btnMore">{{allVendorCount - vendorcount}} more...</button>
        </div>
      </div>
      <p style="font-size: smaller" *ngIf="vendorcount > 0">
        Didn't find who you are looking for? Contact us at <a href="mailto:info@lilo.global">info@lilo.global</a> and
        we'll help!</p>
    </div>
  </div>
</div>

