import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import { Observable } from 'rxjs';
import { getBaseUrl } from '../util/httpclient';

@Injectable()
export class ConverterService {

  private CONVERTER_API = getBaseUrl()+"/api/v1/conversion"

  constructor(private httpClient:HttpClient){

  }

  getConvertableFileFormats():Observable<string[]>{
    return this.httpClient.get<string[]>(this.CONVERTER_API+"/filetypes")
  }
}
