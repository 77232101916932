import {Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, ViewChild} from "@angular/core";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {FileService} from "../../service/file.service";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ErrorSnackbarComponent} from "../../error.snackbar.component";
import {Subject} from "rxjs";
import {sleep} from "../../util/apputil";
import {UploadDataService} from "./upload-data.service";


@Component({
  selector: 'file-acceptor',
  templateUrl: './file-acceptor.component.html',
  styleUrls: ['./file-acceptor.component.css'],
  animations: [trigger('appearIn', [
    state('in', style({opacity: 1})),
    transition('void => *', [
      style({
        opacity: 0
      }),
      animate('0.5s ease-in')
    ])
  ])]
})
export class FileAcceptorComponent implements OnInit {

  @Input() uploadedFiles = [];
  @Input() uploadedDocFiles = [];
  @Input() pId;

  @Output() onFileUpload = new EventEmitter<File>();
  @Output() onFileLanguageUpdate = new EventEmitter<any>();
  @Output() onFileRemove = new EventEmitter<File>();
  @Output() onDocFileUpload = new EventEmitter<File>();

  fileUpload = "";
  docFileUpload = "";
  sourceLanguageCount = 0;

  @ViewChild("fileupload", { static: true })
  uploadInput: ElementRef;

  constructor(private fileService: FileService,
              private uploadDataService: UploadDataService,
              private snackbar: MatSnackBar,
              private dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.calcSourceLangCount();
  }

  uploadFiles(event): void {
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      // Check for duplicate files
      for (var i = 0; i < fileList.length; i++) {
        let file: File = fileList[i];
        // Check if there is already a file with the same name in the project and show an error snackbar
        if (this.uploadedFiles.find(v => v.name === file.name)) {
          let ref = this.snackbar.openFromComponent(ErrorSnackbarComponent, {
            duration: 3000,
            verticalPosition: "top",
            data: {
              errorText: `
                Sorry, you cannot add 2 files with the same name. <br/>
                A file with name <span style="font-weight: bold">${file.name}</span> already exists.`
            }
          });
          return;
        }
      }
      // Loop and upload the files
      for (var i = 0; i < fileList.length; i++) {
        let file: File = fileList[i];
        // Create a subject to send progress events to
        const progress = new Subject<number>();
        // Store the subject as observable in a data provider, so we keep track of it even when navigating away from the page
        this.uploadDataService.addUploadData(this.pId, file.name, progress);
        // We are uploading the source file in an async block so the rest of the application does not wait for the
        // upload to complete
        (async () => {
          // Upload the file through the file service
          let start = new Date().getTime();
          this.fileService.uploadProjectFileWithProgress(file, this.pId, null, progress).subscribe(
            data => {
              let end = new Date();
              console.log("Project " + this.pId + ": upload of " + file.name + " in " + (end.getTime() - start) + " ms");
            },
            error => console.error("Error uploading source file " + file.name + " => ", error),
            () => {
              progress.complete();
              this.uploadDataService.removeUploadData(this.pId, file.name);
            }
          );
        })();
        this.onFileUpload.emit(file);
        this.uploadedFiles.push(file);
      }
    }
    this.fileUpload = null;
  }

  async fakeProgress(prgrss: Subject<number>, filename: string) {
    // todo: debug - remove when no longer necessary
    // todo: https://malcoded.com/posts/angular-file-upload-component-with-express
    let start = 10;
    while (start <= 100) {
      prgrss.next(start);
      start = start + 1;
      if (start % 25 === 0)
        await sleep(30 * 1000);
      else await sleep(500);
    }
    prgrss.complete();
    this.uploadDataService.removeUploadData(this.pId, filename);
  }

  openDocUploadDialog() {
    let dialogRef = this.dialog.open(DocumentationUploadDialog, {
      width: '500px',
      height: '350px',
      data: {srcFiles: this.uploadedFiles, docFiles: this.uploadedDocFiles}
    });
    dialogRef.afterClosed().subscribe(files => {
      if (files) this.uploadDocFiles(files);
    });
  }

  uploadDocFiles(event): void {
    let file: File = event.docfile;
    // Check if there is already a documentation file with the same name in the project and show an error if so
    if (this.uploadedDocFiles.find(v => v.name === file.name)) {
      let ref = this.snackbar.openFromComponent(ErrorSnackbarComponent, {
        duration: 3000,
        verticalPosition: "top",
        data: {errorText: 'Sorry, you cannot add 2 files with the same name.'}
      });
      return;
    }
    // We are uploading the documentation file in an async block so the rest of the application does not wait for the
    // upload to complete
    (async () => {
      // Upload the file through the file service
      let start = new Date().getTime();
      // 20 second delay to fake lengthy upload (only for debugging)
      // await sleep(20*1000);
      // If a source4pdf is specified, then we need to upload this as a "doc-PDF" (=PDF version of a source document)
      if (event.source4pdf != null)
        this.fileService.uploadPdfDocFile(file, this.pId, event.source4pdf).subscribe(
          data => {
            let end = new Date();
            console.log("Project " + this.pId + ": upload of " + file.name + " [pdf-documentation] in " + (end.getTime() - start) + " ms");
            this.snackbar.open(
              "Added documentation file '" + file.name + "' to project.", "", {
                duration: 2000
              });
          },
          error => console.error("Error uploading documentation file", error)
        );
      else
        this.fileService.uploadDocFile(file, this.pId).subscribe(
          data => {
            let end = new Date();
            console.log("Project " + this.pId + ": upload of " + file.name + " [documentation] in " + (end.getTime() - start) + " ms");
            this.snackbar.open(
              "Added documentation file '" + file.name + "' to project.", "", {
                duration: 2000
              });
          },
          error => console.error("Error uploading documentation file", error)
        );
    })();
    this.onDocFileUpload.emit(event);
    this.docFileUpload = null;
  }

  getPdf4Source(file: any) {
    if (this.uploadedDocFiles) {
      return this.uploadedDocFiles.filter(f => f.docinfo != null && f.docinfo.source4pdf != null && f.docinfo.source4pdf === file.name)[0];
    } else return null;
  }

  removeFile(fileToRemove: any): void {
    this.uploadedFiles.splice(this.uploadedFiles.indexOf(fileToRemove), 1);
    this.onFileRemove.emit(fileToRemove);
    this.calcSourceLangCount();
  }

  updateLanguage(file: any, $event: string) {
    let eventMsg = Object();
    eventMsg.filename = file.name;
    eventMsg.lang = $event;
    this.calcSourceLangCount();
    this.onFileLanguageUpdate.emit(eventMsg)
  }

  calcSourceLangCount() {
    this.sourceLanguageCount = this.uploadedFiles.map(f => f.sourcelang).filter(onlyUnique).length
  }

  openFileBrowser(){
    if(this.uploadInput)
      this.uploadInput.nativeElement.click()
  }
}

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}


// TODO: enable "drag & drop" for adding files
// TODO: allow the user to add multiple files in the dialog (instead of only one)
// => TODO: allow the user to upload multiple files at once (by selecting a directory or a zip file)
// TODO: in case of a documentation-PDF being added, replace the previous one if there was any for the same source file?
@Component({
  selector: 'documentation-upload-dialog',
  template: `
    <div>
        <p>Select a file to add as documentation to the project.<br/>
        These files will be available during the translation process as reference material.</p>
        <div>
            <div style="display: flex; flex-direction: column; justify-content: flex-start; align-items: flex-start;">
                <label *ngIf="docFile" style="text-align: left; margin-right: 30px;">
                    {{docFile.name}} <span *ngIf="docFile.size">({{docFile.size | filesize}})</span>
                </label>
                <label for="uploadFile" class="lblUpload" style="border-radius: 3px; margin-left: 25px;">
                    <span *ngIf="!docFile" title="Select a file">Select a file</span>
                    <span *ngIf="docFile" title="Select another file">Change</span>
                </label>
                <input id="uploadFile" type="file" (change)="selectFile($event)" placeholder="Upload documentation" style="display: none;"/>
             </div>
             <div *ngIf="isPdf() && srcFiles ">
                <p>Is this file a PDF version of one of the source files? If so, please indicate which one it relates to:</p>
                <select id="selectSource4Pdf" [(ngModel)]="selectedSource4Pdf">
                    <option [ngValue]="na">N/A</option>
                    <option *ngFor="let srcFile of srcFiles" [ngValue]="srcFile">{{srcFile.name}}</option>
                </select>           
            </div>   
        </div>
        <p *ngIf="errorMsg" style="color: red;">{{errorMsg}}</p>
        <div style="margin-top: 25px; text-align: center;">
            <button (click)="upload()" [disabled]="!docFile">OK</button>
            <button (click)="dialogRef.close()">Cancel</button>    
        </div>
    </div>`,
  styleUrls: ['./file-acceptor.component.css']
})
export class DocumentationUploadDialog {

  srcFiles = [];
  docFiles = [];
  docFile;
  selectedSource4Pdf;
  errorMsg: string;


  constructor(public dialogRef: MatDialogRef<DocumentationUploadDialog>,
              @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  ngOnInit() {
    if (this.data) {
      this.srcFiles = this.data.srcFiles;
      this.docFiles = this.data.docFiles;
    }
    this.errorMsg = null;
  }

  selectFile(event: any) {
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      let file: File = fileList[0];
      if (this.docFiles && this.docFiles.find(v => v.name === file.name)) {
        this.errorMsg = "Sorry, you cannot add 2 files with the same name, and a file with the name " + file.name + " already exists.";
        return;
      }
      this.errorMsg = null;
      this.docFile = file;
    }
  }

  isPdf() {
    if (this.docFile) {
      return this.docFile.name.toLowerCase().endsWith(".pdf");
    }
    return false;
  }

  upload() {
    this.dialogRef.close({
      docfile: this.docFile,
      source4pdf: this.selectedSource4Pdf ? this.selectedSource4Pdf.name : null
    });
  }

}

