import {Component, OnInit} from "@angular/core";
import {UserService} from "../service/user.service";


@Component({
  selector: 'admin-dashboard',
  templateUrl: './admin-dashb.component.html',
  styleUrls: ['./admin-dashb.component.css']
})
export class AdminDashboardComponent implements OnInit {

  constructor(public userService: UserService) {

  }

  ngOnInit(): void {
  }

}
