<div *ngIf="!profile">
  <h4>{{vendorName}}</h4>
</div>
<div *ngIf="profile" class="profiledetails"
     style="display: flex; justify-content: space-between; align-items: stretch; flex-direction: column;">
  <div class="baseinfo">
    <div style="display: flex;">
      <div style="margin: 5px; flex: 0 0 auto;">
        <img class="mugshot" *ngIf="profilePicExists" [src]="profilePic"/>
        <img class="mugshot" *ngIf="!profilePicExists" src="../../../assets/avatar/anom.png"/>
      </div>
      <div style="flex: 1 1 auto;">
        <h4 style="display: flex; justify-content: space-between; align-items: center;">
          {{profile.name}}
          <div style="display: flex; align-items: center; font-size: small;">
            <img src="../../../assets/internal.png" class="imgInfo"
                 title="{{profile.name}} is a colleague (internal translator) of yours."
                 *ngIf="isInternal()"/>
            <img src="../../../assets/mt.png" class="imgInfo"
                 title="{{profile.name}} offers a rebate for machine translation."
                 *ngIf="showPricing && offersMtRebate()"/>
            <a [routerLink]="['/profile/'+profile.id]" routerLinkActive="router-link-active"> <img
              src="../../../assets/info.png"
              class="imgInfo" style="cursor: pointer; margin-right: 5px;"
              title="Click for more information."
              (click)="$event.stopPropagation()"/>
                </a>
          </div>
        </h4>
        <h6>{{descriptionText|textcutPipe:maxDescriptionSize}}<span *ngIf="maxDescriptionSize!=undefined && descriptionText.length>maxDescriptionSize" (click)="openProfileInfo(profile)"  style="cursor: pointer;"> More</span></h6>
      </div>
    </div>
    <div>
      <h5 class="scoring">Rating:
        <rating-small-component [rating]=profile.rating [reviewCount]=profile.reviewcount></rating-small-component>
        <br/>
        <span *ngIf="profile.responsiveness!=undefined">Responsiveness: {{profile.responsiveness}}</span>
      </h5>
      <div>
        <mat-chip-list *ngIf="profile.domains">
          <div *ngIf="maxDomainCount!=undefined">
            <mat-chip *ngFor="let domain of profile.domains.slice(0,maxDomainCount)">{{domain}}</mat-chip>
            <mat-chip *ngIf="maxDomainCount && profile.domains.length>maxDomainCount" (click)="openProfileInfo(profile)">More...</mat-chip>
          </div>
          <div *ngIf="maxDomainCount==undefined">
            <mat-chip *ngFor="let domain of profile.domains">{{domain}}</mat-chip>
          </div>
        </mat-chip-list>
      </div>
    </div>
    <div *ngIf="!profile.internal && userService.isCurrentUserAdmin"
         style="vertical-align: middle; margin-top: 5px; text-align: left;">
      Base price:
      <price [price]="basePrice" digitsInfo="1.2-4"></price>
    </div>
  </div>

  <div *ngIf="showPricing"
       class="pricing"
       style="width: 100%; display: flex; flex-direction: row; justify-content: flex-end; align-items: flex-end;">
    <div *ngIf="totalPrice" title="Total price"
         style="margin-right: 5px; text-align: right;">
      <price [price]="totalPrice"
             [currStyles]="{ fontSize: 'x-large', fontWeight: 'bold', color: 'var(--second-color)' }"></price>
    </div>
  </div>
</div>

