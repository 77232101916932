import {Component, EventEmitter, Input, Output} from "@angular/core";


@Component({
  selector: 'lang-select',
  templateUrl: 'lang-select.component.html',
  styleUrls: ['lang-select.component.css'],
})
export class LangSelectComponent {

  @Input()
  selectedLangs: string[] = [];
  @Input()
  single = false;
  @Input()
  dialect = true;

  @Output()
  eventEmitter = new EventEmitter<any>();

  langDialogOpen = false;

  constructor() {

  }

  ngOnInit() {
    if (this.selectedLangs == undefined)
      this.selectedLangs = [];
  }

  toggleLanguageChooser() {
    this.langDialogOpen = !this.langDialogOpen;
  }

  openLanguageChooser() {
    this.langDialogOpen = true;
  }

  closeLanguageChooser() {
    this.langDialogOpen = false;
  }

  removeLanguage(language: string) {
    this.selectedLangs.splice(this.selectedLangs.indexOf(language), 1);
    this.eventEmitter.emit({language: language, type: 'remove'});
  }

  onLanguageUpdate($event: any) {
    this.eventEmitter.emit($event);
  }

  doPulse() {
    return this.selectedLangs === undefined || this.selectedLangs.length === 0;
  }

}
