import {Pipe, PipeTransform} from '@angular/core';


@Pipe({name: 'textcutPipe'})
export class TextcutPipe implements PipeTransform {

  constructor() {

  }

  transform(value: string, length: string): string {
    if(length==undefined)
      return value;
    let l = parseInt(length);
    if (value.length <= l)
      return value;
    else {
      return value.substr(0, l) + "…"
    }
  }
}
