import {sampleTime} from 'rxjs/operators';
import {Component, Input, OnChanges, OnDestroy} from "@angular/core";
import {Vendor} from "../../dto/vendor";
import {VendorService} from "../../service/vendor.service";
import {Router} from "@angular/router";
import {UserService} from "../../service/user.service";
import {PricingService} from "../../service/pricing.services";
import {StatisticsService} from "../../service/statistics.service";
import { SubscriptionHandler } from '../../util/subscription.handler';


@Component({
  selector: 'vendor-profile',
  templateUrl: './vendor-profile.component.html',
  styles: ['.mugshot {height: 80px; width: 80px; border-radius: 90%; filter: grayscale(100%); -webkit-filter: grayscale(100%);}',
    '.stars {height: 20px;}',
    '.profiledetails {padding: 3px; min-height: 250px; min-width: 250px; margin-bottom: 30px; box-shadow: 0 5px 30px rgba(156, 160, 171, 0.25) !important; position: relative; height: 90%; width: 100%}',
    '.imgInfo {height: 20px; width: 20px;}',
    '.pricing {margin-top: 10px; height: 10%; width: 100%;}',
    '.pricing h5, h4 {margin: 1px; margin-left: 5px; text-align: left;}',
    'h6 {text-align: left; margin: 1px;}',
    '::ng-deep .mat-chip{background-color: var(--btn-bg-color)!important; color:var(--btn-color) !important;}',
    '.tooltip {position: relative; }',
    '.tooltip .tooltiptext {visibility: hidden; width: 250px; background-color: #555; color: #fff; text-align: center; padding: 5px 0; border-radius: 6px;  z-index: 100; opacity: 0;    transition: opacity 0.3s;}',
    '.tooltip-bottom {top: 135%; left: 50%; margin-left: -60px;}',
    '.tooltip .tooltiptext::after {content: ""; position: absolute; bottom: 100%; left: 50%; margin-left: -5px; border-width: 5px; border-style: solid; border-color: transparent transparent #555 transparent ;}',
    '.tooltip:hover .tooltiptext {visibility: visible; opacity: 1;} '
  ]
})
export class VendorProfileComponent implements OnChanges, OnDestroy {

  @Input() profile: Vendor;
  @Input() vendorId: string;
  @Input() basePrice: number = 0;
  @Input() taskSpec;
  @Input() showPricing = false;

  profilePic;
  profilePicExists = false;
  descriptionText = "";
  @Input() maxDescriptionSize = undefined;
  @Input() maxDomainCount= undefined;
  statObserver;
  price: number = 0;
  totalPrice: number = undefined;

  subscriptionHandler = new SubscriptionHandler(this);

  constructor(private vendorService: VendorService,
              private userService: UserService,
              private pricingService: PricingService,
              private statisticsService: StatisticsService,
              private router: Router) {
  }

  ngOnInit(): void {
    if (!this.profile) {
      this.vendorService.getVendor(this.vendorId).subscribe(v => this.initProfile(v));
    } else {
      this.initProfile(this.profile);
    }
  }

  ngOnDestroy(): void {

  }

  ngOnChanges(): void {
    this.calcTotalPrice()
  }

  initProfile(v: Vendor): void {
    this.profile = v;
    this.vendorId = v.id;
    this.loadProfilePic();
    this.loadProfileDescription();
    if (this.taskSpec) {
      this.statObserver = this.createObserver(this.taskSpec.projectId, this.taskSpec.sourcelanguage + "_" + this.taskSpec.targetlanguage);
    }
  }

  isInternal() {
    if (this.profile != null && this.userService.isLoggedIn())
      return this.profile.internal &&
        this.profile.companyId != null &&
        this.profile.companyId === this.userService.getCurrentUser().companyId;
    else return false;
  }

  createObserver(pId: string, langPair: string) {
    // For every update of the project price that comes in we'll need to recalculate it for this vendor
    // To make this code less overkill we build in a sampleTime of a second
    const pricingSubscription = this.pricingService.enrollForProject(pId).pipe(
      sampleTime(1000))
      .subscribe(c => this.calcTotalPrice());
    this.subscriptionHandler.push(pricingSubscription);
  }

  openProfileInfo(vendor: Vendor): void {
    this.router.navigate(['/profileinfo', vendor.id]);
  }

  loadProfilePic() {
    this.userService.getProfilePic(this.vendorId)
      .subscribe(next => {
          this.profilePic = next;
          this.profilePicExists = true;
        }, error => this.profilePicExists = false
      );
  }

  loadProfileDescription() {
    if (this.profile && this.profile.description) {
      // if (this.profile.description.length > 120) {
      //   this.descriptionText = this.profile.description.substr(0, 117) + "..."
      // } else {
      this.descriptionText = this.profile.description;
      // }
    } else {
      this.descriptionText = "";
    }
  }

  offersMtRebate() {
    if (this.profile && this.profile.percentageSet && this.profile.percentageSet.mt_match != null)
      return this.profile.percentageSet.mt_match > 0 && this.profile.percentageSet.mt_match < 100;
    return false;
  }

  private calcTotalPrice() {
    if (this.taskSpec && this.taskSpec.projectId) {
      this.pricingService.getPriceForTaskSpecAndVendor(this.taskSpec, this.profile.id, this.basePrice)
        .subscribe(p => {
          this.totalPrice = p;
        });
    }
  }

}
