<div style="display: flex;">
  <label class="addFilesCircle" for="{{id}}">+</label>
  <div class="addFileText">
    <div class="addFileLabelDiv">
      <label for="{{id}}">{{label}}</label>
      <input id="{{id}}" type="file" style="display: none;"
        multiple (change)="uploadFiles($event)" #{{id}}
      />
    </div>
    <div *ngIf="infoText != null">
      <p>{{infoText}}</p>
    </div>
  </div>
</div>
