/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../user-profile.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./review.item.component.ngfactory";
import * as i3 from "@angular/common";
import * as i4 from "./review.item.component";
import * as i5 from "../../service/review.service";
import * as i6 from "../../service/task.service";
import * as i7 from "../../service/user.service";
import * as i8 from "./review-scroller.component";
var styles_ReviewScrollerComponent = [i0.styles];
var RenderType_ReviewScrollerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ReviewScrollerComponent, data: {} });
export { RenderType_ReviewScrollerComponent as RenderType_ReviewScrollerComponent };
function View_ReviewScrollerComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "reviewScrollItemWrapper"]], [[4, "width", "px"], [4, "transform", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "review-item", [["style", "max-width: 75%;"]], [[8, "id", 0], [1, "data-index", 0]], null, null, i2.View_ReviewItemComponent_0, i2.RenderType_ReviewItemComponent)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(4, { "reviewScrollItem": 0 }), i1.ɵdid(5, 638976, null, 0, i4.ReviewItemComponent, [i5.ReviewService, i6.TaskService, i7.UserService], { index: [0, "index"], review: [1, "review"], currentUser: [2, "currentUser"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_4 = _ck(_v, 4, 0, true); _ck(_v, 3, 0, currVal_4); var currVal_5 = _v.context.index; var currVal_6 = _v.context.$implicit; var currVal_7 = _co.user; _ck(_v, 5, 0, currVal_5, currVal_6, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.scrollerWidth != undefined) ? _co.scrollerWidth : "auto"); var currVal_1 = (("translateX(" + _co.getTransX()) + "px)"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵinlineInterpolate(1, "reviewItem_", _v.context.index, ""); var currVal_3 = _v.context.index; _ck(_v, 1, 0, currVal_2, currVal_3); }); }
function View_ReviewScrollerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [[1, 0], ["reviewScrollList", 1]], null, 13, "div", [["style", "display: flex; width: inherit; align-items: center;"]], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:resize" === en)) {
        var pd_0 = (_co.calcItemWidth() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, [[2, 0], ["scrollLeftArrow", 1]], null, 4, "span", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.scrollLeft() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(4, { "scrollerArrow": 0, "scrollerArrowVisible": 1 }), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [["alt", "scroll left"], ["src", "../../../assets/icon_scroll.svg"], ["style", "transform: rotate(90deg);"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "reviewScroller"], ["id", "reviewList"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReviewScrollerComponent_3)), i1.ɵdid(8, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(9, 0, [[3, 0], ["scrollRightArrow", 1]], null, 4, "span", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.scrollRight() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(11, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(12, { "scrollerArrow": 0, "scrollerArrowVisible": 1 }), (_l()(), i1.ɵeld(13, 0, null, null, 0, "img", [["alt", "scroll right"], ["src", "../../../assets/icon_scroll.svg"], ["style", "transform: rotate(-90deg);"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 4, 0, true, (_co.currentIndex > 0)); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.sortedReviews; _ck(_v, 8, 0, currVal_1); var currVal_2 = _ck(_v, 12, 0, true, ((_co.sortedReviews.length > 1) && (_co.currentIndex < (_co.sortedReviews.length - 1)))); _ck(_v, 11, 0, currVal_2); }, null); }
function View_ReviewScrollerComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "noReviews"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["No reviews yet."]))], null, null); }
function View_ReviewScrollerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["style", "width: inherit;"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReviewScrollerComponent_2)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReviewScrollerComponent_4)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.sortedReviews.length > 0); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.sortedReviews.length === 0); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_ReviewScrollerComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { reviewList: 0 }), i1.ɵqud(671088640, 2, { leftArrow: 0 }), i1.ɵqud(671088640, 3, { rightArrow: 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["style", "display: flex; width: 100%; text-align: left;"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReviewScrollerComponent_1)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.sortedReviews != undefined); _ck(_v, 5, 0, currVal_0); }, null); }
export function View_ReviewScrollerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "review-scroller", [], null, null, null, View_ReviewScrollerComponent_0, RenderType_ReviewScrollerComponent)), i1.ɵdid(1, 4308992, null, 0, i8.ReviewScrollerComponent, [i7.UserService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ReviewScrollerComponentNgFactory = i1.ɵccf("review-scroller", i8.ReviewScrollerComponent, View_ReviewScrollerComponent_Host_0, { reviews: "reviews", user: "user" }, {}, []);
export { ReviewScrollerComponentNgFactory as ReviewScrollerComponentNgFactory };
