/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./countdown.component";
var styles_CountdownComponent = [];
var RenderType_CountdownComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CountdownComponent, data: {} });
export { RenderType_CountdownComponent as RenderType_CountdownComponent };
function View_CountdownComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["style", "color: inherit;"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", " days, "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co._days; _ck(_v, 1, 0, currVal_0); }); }
function View_CountdownComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["style", "color: inherit;"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", " hours and "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co._hours; _ck(_v, 1, 0, currVal_0); }); }
function View_CountdownComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["style", "color: inherit;"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" overdue"]))], null, null); }
function View_CountdownComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["style", "color: inherit;"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" left"]))], null, null); }
function View_CountdownComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 13, "div", [], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgStyleImpl, i1.ɵNgStyleR2Impl, [i0.ElementRef, i0.KeyValueDiffers, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgStyle, [i1.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i0.ɵpod(3, { "color": 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CountdownComponent_2)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CountdownComponent_3)), i0.ɵdid(7, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(8, 0, null, null, 1, "span", [["style", "color: inherit;"]], null, null, null, null, null)), (_l()(), i0.ɵted(9, null, ["", " minutes"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CountdownComponent_4)), i0.ɵdid(11, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CountdownComponent_5)), i0.ɵdid(13, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, (_co._overdue ? "orange" : "var(--main-color)")); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co._days > 0); _ck(_v, 5, 0, currVal_1); var currVal_2 = ((_co._days > 0) || (_co._hours > 0)); _ck(_v, 7, 0, currVal_2); var currVal_4 = ((_co.showsuffixlabel && _co._overdue) && _co.showoverdue); _ck(_v, 11, 0, currVal_4); var currVal_5 = (_co.showsuffixlabel && !(_co._overdue && _co.showoverdue)); _ck(_v, 13, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co._minutes; _ck(_v, 9, 0, currVal_3); }); }
export function View_CountdownComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_CountdownComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co._seconds != undefined); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CountdownComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "countdown", [], null, null, null, View_CountdownComponent_0, RenderType_CountdownComponent)), i0.ɵdid(1, 114688, null, 0, i2.CountdownComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CountdownComponentNgFactory = i0.ɵccf("countdown", i2.CountdownComponent, View_CountdownComponent_Host_0, { enddate: "enddate", showoverdue: "showoverdue", showsuffixlabel: "showsuffixlabel" }, {}, []);
export { CountdownComponentNgFactory as CountdownComponentNgFactory };
