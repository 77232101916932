import {Component, OnInit} from "@angular/core";
import {Project} from "../../dto/project";
import {getProjectStatus} from "../../util/jobutil";


@Component({
  selector: 'project-card-tooltip',
  template: `
    <h4>Project &quot;{{title}}&quot;</h4>
    <ul>
    <li *ngIf="startedOn">Started on: {{startedOn|date:'yyyy-MM-dd HH:mm'}}</li>
    <li *ngIf="lastUpdatedOn">Last updated on: {{lastUpdatedOn|date:'yyyy-MM-dd HH:mm'}}</li>
    <li *ngIf="dueOn">Due on: {{dueOn|date:'yyyy-MM-dd'}}</li>
    <li *ngIf="canceledOn">Canceled on: {{canceledOn|date:'yyyy-MM-dd HH:mm'}}</li>
    <li *ngIf="status">Status: {{status}}</li>
    </ul>
    <i>Click to open.</i><br/>
    <img src="../../../assets/arrow_red_bottomleft.png" 
         style="width: 30px; height: 15px; margin-top: 5px; margin-bottom: 1px;">
    `,
  styles: ["h4, li, i {color: white;}"],
})
export class ProjectCardTooltipComponent implements OnInit {

  project: Project;
  title: string;
  startedOn: any;
  lastUpdatedOn: any;
  dueOn: any;
  canceledOn: any;
  status: string;


  constructor() {
  }

  ngOnInit() {
    this.title = "UNKNOWN";
    this.initData()
  }

  set data(p: Project) {
    this.project = p;
    this.initData();
  }

  private initData() {
    if (this.project) {
      this.title = this.project.title;
      this.startedOn = this.getFirstStartDate();
      if (this.project.startdate && this.startedOn !== this.project.startdate)
        this.lastUpdatedOn = this.project.startdate;
      this.dueOn = this.project.duedate;
      this.canceledOn = this.project.canceled;
      this.status = getProjectStatus(this.project);
    }
  }

  getFirstStartDate() {
    let firstDate;
    if (this.project.checkoutarchs && this.project.checkoutarchs.length > 0) {
      this.project.checkoutarchs.forEach(v => {
        if (!firstDate || (v.startdate && v.startdate < firstDate))
          firstDate = v.startdate;
      });
    }
    if (firstDate)
      return firstDate;
    else return this.project.startdate;
  }

}
