/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./user.settings.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/forms";
import * as i4 from "./payment.setting.component";
import * as i5 from "../service/invoice.service";
import * as i6 from "../service/user.service";
import * as i7 from "@angular/material/snack-bar";
var styles_PaymentSettingComponent = [i0.styles];
var RenderType_PaymentSettingComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PaymentSettingComponent, data: {} });
export { RenderType_PaymentSettingComponent as RenderType_PaymentSettingComponent };
function View_PaymentSettingComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" It is only necessary to add a payment method if you intend to register projects. "]))], null, null); }
function View_PaymentSettingComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["class", "cardImage"]], [[8, "src", 4], [8, "title", 0], [8, "alt", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.paymentImage, ""); var currVal_1 = i1.ɵinlineInterpolate(1, "", _co.currentCard.brand, ""); var currVal_2 = i1.ɵinlineInterpolate(1, "", _co.currentCard.brand, ""); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_PaymentSettingComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Current card:"])), (_l()(), i1.ɵeld(3, 0, null, null, 4, "span", [["style", "font-size: small; height: 30px; display: flex; align-items: center;"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PaymentSettingComponent_3)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["style", "font-weight: bold;"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["XXXX XXXX XXXX ", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.paymentImage; _ck(_v, 5, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.currentCard.last4; _ck(_v, 7, 0, currVal_1); }); }
function View_PaymentSettingComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["id", "card-errors"], ["role", "alert"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.error; _ck(_v, 1, 0, currVal_0); }); }
export function View_PaymentSettingComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { cardInfo: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 29, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Payment methods"])), (_l()(), i1.ɵeld(4, 0, null, null, 3, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" This page allows you to edit your credit card information."])), (_l()(), i1.ɵeld(6, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Please note that this is used to automatically charge you when you download a translated file from a project. "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PaymentSettingComponent_1)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PaymentSettingComponent_2)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 13, "div", [], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 12, "form", [["class", "checkout"], ["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngSubmit"], [null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 15).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 15).onReset() !== false);
        ad = (pd_1 && ad);
    } if (("ngSubmit" === en)) {
        var pd_2 = (_co.onSubmit(i1.ɵnov(_v, 15)) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(14, 16384, null, 0, i3.ɵangular_packages_forms_forms_z, [], null, null), i1.ɵdid(15, 4210688, [["checkout", 4]], 0, i3.NgForm, [[8, null], [8, null]], null, { ngSubmit: "ngSubmit" }), i1.ɵprd(2048, null, i3.ControlContainer, null, [i3.NgForm]), i1.ɵdid(17, 16384, null, 0, i3.NgControlStatusGroup, [[4, i3.ControlContainer]], null, null), (_l()(), i1.ɵeld(18, 0, null, null, 7, "div", [["class", "form-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 1, "label", [["for", "card-info"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Card information"])), (_l()(), i1.ɵeld(21, 0, [[1, 0], ["cardInfo", 1]], null, 0, "div", [["id", "card-info"], ["style", "min-width: 350px;"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PaymentSettingComponent_4)), i1.ɵdid(23, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(24, 0, null, null, 1, "button", [["type", "submit"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Save"])), (_l()(), i1.ɵeld(26, 0, null, null, 4, "div", [["style", "margin-top: 25px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(27, 0, null, null, 1, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changePaymentMethods() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(28, null, [" ", " "])), (_l()(), i1.ɵeld(29, 0, null, null, 1, "button", [], [[8, "disabled", 0]], [[null, "click"], [null, "disabledChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clearPaymentMethods() !== false);
        ad = (pd_0 && ad);
    } if (("disabledChange" === en)) {
        var pd_1 = (!(_co.currentCard = $event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Clear"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isTranslator(); _ck(_v, 9, 0, currVal_0); var currVal_1 = _co.currentCard; _ck(_v, 11, 0, currVal_1); var currVal_10 = _co.error; _ck(_v, 23, 0, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = !_co.changeMode; _ck(_v, 12, 0, currVal_2); var currVal_3 = i1.ɵnov(_v, 17).ngClassUntouched; var currVal_4 = i1.ɵnov(_v, 17).ngClassTouched; var currVal_5 = i1.ɵnov(_v, 17).ngClassPristine; var currVal_6 = i1.ɵnov(_v, 17).ngClassDirty; var currVal_7 = i1.ɵnov(_v, 17).ngClassValid; var currVal_8 = i1.ɵnov(_v, 17).ngClassInvalid; var currVal_9 = i1.ɵnov(_v, 17).ngClassPending; _ck(_v, 13, 0, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); var currVal_11 = (_co.currentCard ? "Edit" : "Add"); _ck(_v, 28, 0, currVal_11); var currVal_12 = !_co.currentCard; _ck(_v, 29, 0, currVal_12); }); }
export function View_PaymentSettingComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "payment-settings", [], null, null, null, View_PaymentSettingComponent_0, RenderType_PaymentSettingComponent)), i1.ɵdid(1, 4440064, null, 0, i4.PaymentSettingComponent, [i5.InvoiceService, i6.UserService, i7.MatSnackBar], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PaymentSettingComponentNgFactory = i1.ɵccf("payment-settings", i4.PaymentSettingComponent, View_PaymentSettingComponent_Host_0, { user: "user" }, {}, []);
export { PaymentSettingComponentNgFactory as PaymentSettingComponentNgFactory };
