<div *ngIf="downloadableFilesInfo != undefined">

  <div *ngIf="downloadableFilesInfo.length > 0">
    <div *ngIf="viewAsTiles" class="targetFileTileView">
      <target-file *ngFor="let file of downloadableFilesInfo" class="targetFileTile"
                   [file]="file" [pId]="project.id" [langPair]="getLpString()"
                   (click)="download(file)"></target-file>
    </div>
    <ul *ngIf="!viewAsTiles" class="targetFileListView">
      <li *ngFor="let file of downloadableFilesInfo">
        <a (click)="download(file)">{{file.name}}</a>
      </li>
    </ul>
    <div class="checkTranslationLink" [ngStyle]="{'justify-content': viewAsTiles? 'flex-end':'flex-start'}"
      *ngIf="this.langPair.target">
      <a routerLink="/translationcheck/{{project.id}}/{{getLpString()}}"
         routerLinkActive="active">Check translation</a>
      <span (click)="openTransCheckHelp()" style="cursor: pointer">
        <img src="../../assets/icons8-help-32.png" style="height: 20px; width: 20px;"/>
      </span>
    </div>
  </div>

  <div *ngIf="downloadableFilesInfo.length === 0">
    <p>No files ready yet.</p>
  </div>

</div>
