import {Component, EventEmitter, Input, Output} from "@angular/core";
import {LanguageService} from "../../service/language.service";

@Component({
  selector: 'language-selector',
  template: '<div *ngIf="langKeys" style="display: flex;flex-direction: row;">' +
  '<label for="language" style="flex: 0 0 70px; text-align: left; ">{{title}}</label>' +
  '<select id="language" style="flex: 1 1 160px;" [(ngModel)]="selectedLang" disabled={{getDisabled()}} (change)="selectLanguage()">' +
  '<option *ngFor="let language of langKeys" [ngValue]="language">{{language|languagePipe|async}}</option>' +
  '</select>' +
  '</div>'
})

export class LanguageSelectorComponent {

  @Input() title: string;
  @Input() @Output() selectedLang: string;
  @Input() disabled: boolean = false;

  langKeys: Array<string>;


  @Output() onLanguageSelect = new EventEmitter<string>();

  constructor(private languageService: LanguageService) {
  }

  ngOnInit() {
    this.languageService.getLanguages()
      .subscribe(v => {
          this.langKeys = v;
        }
      );
  }

  selectLanguage(): void {
    this.onLanguageSelect.emit(this.selectedLang);
  }

  languageKeys(): Iterable<string> {
    return this.langKeys;
  }

  getDisabled() {
    return this.disabled
  }
}
