<h2>Translator search</h2>

<div *ngIf="!userService.isCurrentUserAdmin">
  You do not have the correct privileges to access this page.
</div>

<div *ngIf="userService.isCurrentUserAdmin">
  <div>
    <label>Source</label>
    <select id="source" [(ngModel)]="source">
      <option *ngFor="let language of languages" value={{language}}>{{language|languagePipe|async}}</option>
    </select>
  </div>
  <div>
    <label>Target</label>
    <select id="target" [(ngModel)]="target">
      <option *ngFor="let language of dialectLanguages" value={{language}}>{{language|languagePipe|async}}</option>
    </select>
  </div>
  <div>
    <label>Activity</label>
    <select [(ngModel)]="activity">
      <option *ngFor="let activity of activities" value={{activity}}>{{activity}}</option>
    </select>
  </div>
  <domain [domains]="domains"></domain>
  <button style="margin-top: 15px; margin-bottom: 25px;" (click)="search()">Search</button>
  <div *ngIf="results">
    <vendor-list [vendors]="results" [showPricing]="true"></vendor-list>
  </div>
</div>
