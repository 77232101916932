/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../project.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./file-add.component.ngfactory";
import * as i3 from "./file-add.component";
import * as i4 from "@angular/common";
import * as i5 from "./source-file-add.component";
import * as i6 from "../../service/file.service";
import * as i7 from "../util/upload-data.service";
import * as i8 from "@angular/material/snack-bar";
var styles_SourceFileAddComponent = [i0.styles];
var RenderType_SourceFileAddComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SourceFileAddComponent, data: {} });
export { RenderType_SourceFileAddComponent as RenderType_SourceFileAddComponent };
function View_SourceFileAddComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "addMoreSourceFileDiv"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "file-add", [], null, [[null, "onFileUpload"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onFileUpload" === en)) {
        var pd_0 = (_co.uploadFiles($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_FileAddComponent_0, i2.RenderType_FileAddComponent)), i1.ɵdid(2, 49152, null, 0, i3.FileAddComponent, [], { label: [0, "label"], infoText: [1, "infoText"], id: [2, "id"] }, { onFileUpload: "onFileUpload" })], function (_ck, _v) { var currVal_0 = "Add more files"; var currVal_1 = "That need to be translated"; var currVal_2 = "fileadd"; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_SourceFileAddComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "addASourceFileDiv"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "label", [["class", "addFilesCircle"], ["for", "fileupload"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["+"])), (_l()(), i1.ɵeld(3, 0, null, null, 8, "div", [["class", "addFileLabelDiv"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "label", [["for", "fileupload"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Add a file"])), (_l()(), i1.ɵeld(7, 0, [["fileupload", 1]], null, 0, "input", [["id", "fileupload"], ["multiple", ""], ["style", "display: none;"], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.uploadFiles($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 3, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Word, ppt, pages, ... "])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "a", [["href", "https://about.lilo.global/formats"], ["style", "display:none "], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["see all formats"]))], null, null); }
export function View_SourceFileAddComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_SourceFileAddComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SourceFileAddComponent_2)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.uploadedFiles != undefined) && (_co.uploadedFiles.length > 0)); _ck(_v, 1, 0, currVal_0); var currVal_1 = ((_co.uploadedFiles == undefined) || (_co.uploadedFiles.length === 0)); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_SourceFileAddComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "source-file-add", [], null, null, null, View_SourceFileAddComponent_0, RenderType_SourceFileAddComponent)), i1.ɵdid(1, 49152, null, 0, i5.SourceFileAddComponent, [i6.FileService, i7.UploadDataService, i8.MatSnackBar], null, null)], null, null); }
var SourceFileAddComponentNgFactory = i1.ɵccf("source-file-add", i5.SourceFileAddComponent, View_SourceFileAddComponent_Host_0, { uploadedFiles: "uploadedFiles", pId: "pId" }, { onFileUpload: "onFileUpload" }, []);
export { SourceFileAddComponentNgFactory as SourceFileAddComponentNgFactory };
