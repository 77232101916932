
    <div class="main">
      <h1>Welcome,</h1>
		<h2>Become a translator @ lilo</h2>
      <h3>Start your journey here to join this new innovative community <br/>of translators and connect more easily with
        clients all over the world.</h3>
      <div *ngIf="user">
        <p>Join our translator community by specifying what translation competences you have.</p>
        <div>
          <profile-settings *ngIf="user" [user]="user" [isTranslator]="true"></profile-settings>
          <competence-settings *ngIf="user" [user]="user"></competence-settings>
          <div style="width: 100%;">
            <button (click)="gotoProfile()">Save and continue to profile
              <img src="../../assets/loading.gif" style="max-width:40px;" *ngIf="saving">
            </button>
          </div>
        </div>
      </div>
    </div>