import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {getBaseUrl} from "../util/httpclient";


@Injectable()
export class DomainService {

  domainCache: string[];

  private DOMAIN_API = getBaseUrl() + "/api/v1/domains";


  constructor(private http: HttpClient) {
    this.getDomains().subscribe(m => this.domainCache = m);
  }

  public getDomains(): Observable<string[]> {
    return this.http.get<string[]>(this.DOMAIN_API)
  }
}
