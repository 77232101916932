import {map} from 'rxjs/operators';
import {Component, Input} from "@angular/core";
import {Task} from "../dto/job";
import {Observable} from "rxjs";


/**
 * Created by jefv on 3/11/2017.
 */
@Component({
  selector: 'task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['task-dashb.component.css']
})
export class TaskListComponent {
  @Input() tasks: Observable<Task[]>;
  @Input() title: string;
  @Input() emptyText: string;

  taskCount;

  constructor() {
  }

  ngOnInit() {
    this.tasks = this.tasks.pipe(map(t => {
      this.taskCount = t.length;
      return t;
    }))
  }
}
