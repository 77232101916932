
    <div class="boxing">
        <div *ngFor="let vendor of (vendors|async)" [@appearIn]="'in'" class="module">
          <vendor-profile [profile]="vendor" [basePrice]="vendor.basePrice"
                          [taskSpec]="taskSpec"
                          [showPricing]="showPricing"
                          [maxDescriptionSize]="250"
                          [maxDomainCount]="3"
                          (click)="selectVendor(vendor)"></vendor-profile>
        </div>
    </div>