<div>
  <div class="infoField">
    <label class="infoLabel">Name</label>
    <input type="text" class="infoInput" [(ngModel)]="project.title" (change)="setTitle()"/>
  </div>
  <div class="infoField">
    <label class="infoLabel">Due date</label>
    <datetime2 class="infoInput" #duedatepicker
               [(selectedDate)]="duedate" [(minDate)]="minDueDate"
               (selectedDateChange)="setProjectDueDate()"></datetime2>
  </div>
  <div class="infoField">
    <div class="infoLabel"></div>
    <div class="proposedDueDate">
      <small [ngClass]="{valid: !isDueDateBeforeProposal, invalid: isDueDateBeforeProposal}">
        Translators will most likely not accept such a tight deadline.<br/>
        <u style="cursor: pointer;" (click)="setProjectDueDateToProposed()">Go back to the suggested date.</u></small>
    </div>
  </div>
  <div class="infoField" *ngIf="mtValid === true">
    <div class="infoLabel"></div>
    <div class="optionDiv checkboxWithLabelDiv"
         [title]="'Allow the system to leverage machine translation using DeepL, potentially reducing the cost of your project.'">
      <input type="checkbox" id="allowMT"
             (change)="allowMTChanged()" [(ngModel)]="allowMT"/>
      <label for="allowMT">Allow the usage of MT</label>
    </div>
  </div>
  <div class="infoField" *ngIf="userService.isLoggedIn()">
    <div class="infoLabel"></div>
    <div class="optionDiv shareProject">
      <label>Share my project with</label>
      <div style="display: flex; flex-direction: column;">
        <colleague-select [(selectedColleagues)]="projectShares"
                          (onSelectedColleaguesChange)="saveProjectShares($event)"></colleague-select>
        <div class="checkboxWithLabelDiv saveDefaultShares"
             title="Save and share my future project(s) by default with the current selection"
             *ngIf="defaultSharesChanged">
          <input type="checkbox" id="saveShares"
                 [(ngModel)]="saveSharesAsDefault" (change)="saveSharesChanged()"/>
          <label for="saveShares">Save as default shares</label>
        </div>
      </div>
    </div>
  </div>
  <div>
    <button class="nextButton" (click)="storeProjectInfo()">Next</button>
  </div>
</div>
