import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {getBaseUrl} from "../util/httpclient";
import {UserService} from "./user.service";


@Injectable()
export class HubspotService {

  private HUBSPOT_API = getBaseUrl() + "/api/v1/hubspot";

  constructor(private http: HttpClient,
              private userService:UserService) {

  }

  testHubspotConnection(testKey: string) {
    return this.http.get(this.HUBSPOT_API + "/connectiontest/"+testKey, {headers: this.createHeader()}).pipe(
      map(a => {
          if(a==true)
            return "success";
          return "failed"
        }
      ))
  }

  getPages(){
    return this.http.get(this.HUBSPOT_API+"/pages", {headers:this.createHeader()})
  }

  createProject(selectedPages: number[]) {
    return this.http.post(this.HUBSPOT_API+"/project", selectedPages, {headers:this.createHeader()})
  }

  private createHeader(): HttpHeaders{
    return new HttpHeaders({
      "X-API-Key": this.userService.getCurrentUser().publicApiKey
    })
  }
}
