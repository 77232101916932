
	  <div *ngIf="file" class="documentationItem documentationFile" style="" [title]="file.name">
		  <div class="documentationFileInfo">
        <div class="documentationFileInfoImage">
          <frontpage [fileName]="file.name" [height]="'85px'" [width]="'70px'"></frontpage>
          <div class="sourceFileHover">
          <div class="sourceFileDownload">
              <div (click)="download()">
                <img src="../../../assets/download_icon.svg" title="Download" alt="Download file">
              </div>
            </div>
            <div *ngIf="allowRemoval" class="sourceFileDelete" (click)="emitFileRemove()">
              <img src="../../../assets/delete_icon_resized.svg" title="Remove" alt="Remove file">
            </div>
          </div>
        </div>
			  <h5 [ngStyle]="{'text-decoration': file.warning != null ? 'line-through' : 'initial'}">{{file.name|filenamePipe:24:true}}</h5>
      </div>
	  </div>