/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./project.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./project-create.component.ngfactory";
import * as i3 from "./project-create.component";
import * as i4 from "../service/project.service";
import * as i5 from "../service/user.service";
import * as i6 from "../service/pricing.services";
import * as i7 from "../service/language.service";
import * as i8 from "../service/statistics.service";
import * as i9 from "../service/file.service";
import * as i10 from "../service/mt.service";
import * as i11 from "../service/task.service";
import * as i12 from "../service/vendor.service";
import * as i13 from "@angular/router";
import * as i14 from "@angular/common";
import * as i15 from "@angular/material/dialog";
import * as i16 from "@angular/material/snack-bar";
import * as i17 from "ngx-cookie-service";
import * as i18 from "../service/login.service";
import * as i19 from "../service/chat.service";
import * as i20 from "@angular/common/http";
import * as i21 from "./project-followup.component.ngfactory";
import * as i22 from "./project-followup.component";
import * as i23 from "./projectchatcomponents/project-chat.component.ngfactory";
import * as i24 from "./projectchatcomponents/project-chat.component";
import * as i25 from "./project-details.component";
var styles_ProjectDetailsComponent = [i0.styles];
var RenderType_ProjectDetailsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProjectDetailsComponent, data: {} });
export { RenderType_ProjectDetailsComponent as RenderType_ProjectDetailsComponent };
function View_ProjectDetailsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["style", "display: flex; justify-content: center; align-items: center; flex-direction: column; margin-top: 150px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["src", "../../assets/loading_logo_1.gif"], ["style", "width: 100px; margin-bottom: 20px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Retrieving project ..."]))], null, null); }
function View_ProjectDetailsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "project-create", [], null, null, null, i2.View_ProjectCreateComponent_0, i2.RenderType_ProjectCreateComponent)), i1.ɵdid(2, 4440064, null, 0, i3.ProjectCreateComponent, [i4.ProjectService, i5.UserService, i6.PricingService, i7.LanguageService, i8.StatisticsService, i9.FileService, i10.MtService, i11.TaskService, i12.VendorService, i13.ActivatedRoute, i14.Location, i13.Router, i15.MatDialog, i16.MatSnackBar, i17.CookieService, i18.LogInService, i19.ChatService, i20.HttpClient], { project: [0, "project"], section: [1, "section"], noForceRequester: [2, "noForceRequester"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.project; var currVal_1 = _co.section; var currVal_2 = _co.noForceRequester; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_ProjectDetailsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "project-followup", [], null, null, null, i21.View_ProjectFollowupComponent_0, i21.RenderType_ProjectFollowupComponent)), i1.ɵdid(2, 245760, null, 0, i22.ProjectFollowupComponent, [i4.ProjectService, i11.TaskService, i5.UserService, i18.LogInService, i6.PricingService, i13.Router, i13.ActivatedRoute, i15.MatDialog], { project: [0, "project"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.project; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ProjectDetailsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "chat"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "project-chat", [], null, null, null, i23.View_ProjectChatComponent_0, i23.RenderType_ProjectChatComponent)), i1.ɵdid(3, 114688, null, 0, i24.ProjectChatComponent, [i5.UserService, i19.ChatService], { projectId: [0, "projectId"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.project.id; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_ProjectDetailsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["style", "position: relative;"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProjectDetailsComponent_1)), i1.ɵdid(2, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProjectDetailsComponent_2)), i1.ɵdid(4, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProjectDetailsComponent_3)), i1.ɵdid(6, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProjectDetailsComponent_4)), i1.ɵdid(8, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.project == undefined); _ck(_v, 2, 0, currVal_0); var currVal_1 = ((_co.project != undefined) && (_co.projectState == "create")); _ck(_v, 4, 0, currVal_1); var currVal_2 = ((_co.project != undefined) && (_co.projectState == "followup")); _ck(_v, 6, 0, currVal_2); var currVal_3 = (_co.project != undefined); _ck(_v, 8, 0, currVal_3); }, null); }
export function View_ProjectDetailsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "project-details", [], null, null, null, View_ProjectDetailsComponent_0, RenderType_ProjectDetailsComponent)), i1.ɵdid(1, 114688, null, 0, i25.ProjectDetailsComponent, [i4.ProjectService, i13.ActivatedRoute, i16.MatSnackBar], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProjectDetailsComponentNgFactory = i1.ɵccf("project-details", i25.ProjectDetailsComponent, View_ProjectDetailsComponent_Host_0, {}, {}, []);
export { ProjectDetailsComponentNgFactory as ProjectDetailsComponentNgFactory };
