<div *ngIf="invoice != null" class="salesInvoice">
  <div class="mainSalesInvoiceInfo">
    <div>
      <label>Your details</label>
      <div *ngIf="invoice.company != null">
        <p *ngIf="invoice.company.name != null">{{invoice.company.name}}</p>
        <p *ngIf="invoice.company.address != null">{{invoice.company.address}}</p>
        <p *ngIf="invoice.company.country != null">{{invoice.company.country}}</p>
        <p *ngIf="invoice.company.vat != null">VAT No. {{invoice.company.vat}}</p>
      </div>
      <div *ngIf="invoice.projects != null && invoice.projects[0] != null && invoice.projects[0].requestedby != null">
        <p>{{invoice.projects[0].requestedby.firstName}} {{invoice.projects[0].requestedby.lastName}}</p>
      </div>
    </div>
    <div>
      <label>Invoice number</label>
      <p>{{invoice.number}}</p>
      <label>Invoice date</label>
      <p>{{invoice.date | date}}</p>
    </div>
  </div>
  <div class="salesInvoiceDetails">
    <project-list *ngIf="projects != null" [projects]="projects" [availCols]="listCols"></project-list>
  </div>
  <div class="pricingdetails">
    <div style="min-width: 200px;">
      <div style="width: 100%;">
        <div class="pricingdetailsrow">
          <label>Total VAT excl.:</label>
          <price [price]="invoice.price.vatexcl" [displayCurrency]="'EUR'"></price>
        </div>
        <div class="pricingdetailsrow">
          <label>VAT ({{invoice.price.vatrate * 100}}%):
          </label>
          <price [price]="invoice.price.vat" [displayCurrency]="'EUR'"></price>
        </div>
        <div class="pricingdetailsrow">
          <label>Total VAT incl.:</label>
          <price [price]="invoice.price.vatincl" [displayCurrency]="'EUR'"></price>
        </div>
      </div>
    </div>
  </div>
</div>
