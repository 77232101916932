import {Component, Input} from "@angular/core";
import {ActivatedRoute, Params} from "@angular/router";
import {VendorService} from "../service/vendor.service";


@Component({
  selector: 'profile-info',
  templateUrl: "./profile.info.component.html",
  styleUrls: ["./user.settings.component.css"],
})
export class ProfileInfoComponent {

  @Input() userId;
  user;

  //TODO Fully implement this page

  constructor(private route: ActivatedRoute,
              private vendorService: VendorService) {
  }

  ngOnInit() {
    if (!this.userId) {
      this.route.params
        .forEach((params: Params) => {
          if (params['id'])
            this.setUserId(params['id']);
        });
    }
    this.vendorService.getVendor(this.userId).subscribe(v => {
      this.user = v;
    });
  }

  private setUserId(userId: any) {
    this.userId = userId;
  }
}
