import {Injectable} from "@angular/core";
import {Task} from "../dto/job";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {getBaseUrl} from "../util/httpclient";
import {UserService} from "./user.service";

export const PAYMENT_METHOD_STRIPE = "STRIPE";
export const PAYMENT_METHOD_BANKTRANSFER = "BANKTRANSFER";
export const PAYMENT_METHOD_PAYPAL = "PAYPAL";


@Injectable()
export class InvoiceService {

  private INVOICE_API = getBaseUrl() + "/api/v1/invoices";
  private PAYMENT_API = getBaseUrl() + "/api/v1/payment";
  private PROJECT_API = getBaseUrl() + "/api/v1/projects";

  constructor(private http: HttpClient, private userService: UserService) {

  }

  registerInvoice(invoice: any, tasks: Task[], rewards: any[], company: any): Observable<any> {
    //Set some task info to the invoice
    invoice.tasks = [];
    tasks.map(t => {
      let taskInvInfo: any = {};
      taskInvInfo.id = t.id;
      taskInvInfo.price = t.price;
      taskInvInfo.data = t.activity + " " + t.sourcelanguage + " " + t.targetlanguage;
      return taskInvInfo;
    }).forEach(t => {
      invoice.tasks.push(t);
    });
    //Set some reward info to the invoice
    invoice.rewards = [];
    rewards.map(r => {
        let rewardInvInfo: any = {};
        rewardInvInfo.inviteId = r.inviteId;
        rewardInvInfo.projectId = r.projectId;
        rewardInvInfo.rewardAmount = r.rewardAmount;
        return rewardInvInfo;
      }
    ).forEach(r => invoice.rewards.push(r));
    //Set some company info to the invoice
    invoice.company = {};
    invoice.company.id = company.id;
    invoice.company.name = company.name;
    invoice.company.address = company.address;
    invoice.company.country = company.country;
    invoice.company.vat = company.vat;
    invoice.company.bankaccount = company.bankaccount;
    invoice.uploadby = this.userService.getCurrentUser();
    //Post the invoice to the service
    return this.http.post(this.INVOICE_API + "/register", JSON.stringify(invoice), {responseType: 'json'})
  }

  getInvoice(invoiceId: any): Observable<any> {
    return this.http.get(this.INVOICE_API + "/findbyid/" + invoiceId)
  }

  findRegisteredPurchaseInvoices(companyId) {
    return this.http.get(this.INVOICE_API + "/purchase/findbycompanyid/" + companyId)
  }

  findRegisteredCompanySalesInvoices(companyId) {
    return this.http.get(this.INVOICE_API + "/sales/findbycompanyid/" + companyId)
  }

  findRegisteredUserSalesInvoices(userId) {
    return this.http.get(this.INVOICE_API + "/sales/findbyuserid/" + userId)
  }

  findRegisteredProjectSalesInvoices(projectId) {
    return this.http.get(this.INVOICE_API + "/sales/findbyprojectid/" + projectId)
  }

  findPaymentMethod(userId: any): Observable<any> {
    return this.http.get(this.PAYMENT_API + "/methods/" + userId)
  }

  hasPaymentMethodRecord(userId: any): Observable<any> {
    return this.http.get(this.PAYMENT_API + "/hasrecord/" + userId)
  }

  storeUserPaymentMethod(intent: any): Observable<any> {
    return this.http.post(this.PAYMENT_API + "/storemethod/" + this.userService.getCurrentUser().id, JSON.stringify(intent), {responseType: 'json'})
  }

  storeProjectPaypal(project: string, order: any): Observable<any> {
    return this.http.post(this.PROJECT_API + '/storepaypal/' + project, JSON.stringify(order), {responseType: 'json'})
  }

  clearPaymentMethod(userId: any): Observable<any> {
    return this.http.post(this.PAYMENT_API + "/clearmethod/" + userId, "", {responseType: 'text'})
  }

  getPaymentIntent(): Observable<string> {
    return this.http.get<string>(this.PAYMENT_API + '/paymentintent')
  }
}
