/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./invoicing.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "../util/price.component.ngfactory";
import * as i5 from "../util/price.component";
import * as i6 from "../service/country.service";
import * as i7 from "./invoice.overview.component";
import * as i8 from "../service/user.service";
import * as i9 from "../service/invoice.service";
import * as i10 from "../service/project.service";
import * as i11 from "../service/task.service";
var styles_InvoiceOverviewComponent = [i0.styles];
var RenderType_InvoiceOverviewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InvoiceOverviewComponent, data: {} });
export { RenderType_InvoiceOverviewComponent as RenderType_InvoiceOverviewComponent };
function View_InvoiceOverviewComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Please note that in order to be able to register invoices for completed tasks you will need to ensure your company details are complete and correct. "])), (_l()(), i1.ɵeld(2, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" You can specify these by going to your user profile, and selecting \""])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "a", [["title", "Click here to be redirected to your company settings."]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Company"])), (_l()(), i1.ɵted(-1, null, ["\" from the right menu. "]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = i1.ɵinlineInterpolate(1, "/user/", _co.user.id, "/company"); _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 5).target; var currVal_1 = i1.ɵnov(_v, 5).href; _ck(_v, 4, 0, currVal_0, currVal_1); }); }
function View_InvoiceOverviewComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "noInvoices"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["You have no invoices available at the moment."])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InvoiceOverviewComponent_2)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.user && _co.isTranslator()); _ck(_v, 4, 0, currVal_0); }, null); }
function View_InvoiceOverviewComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h5", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["You do not have any task invoices yet."]))], null, null); }
function View_InvoiceOverviewComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["# tasks: ", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.tasks.length; _ck(_v, 1, 0, currVal_0); }); }
function View_InvoiceOverviewComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "module"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openInvoice(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["Invoice date: ", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["Due date: ", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 3, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Total: "])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "price", [], null, null, null, i4.View_PriceComponent_0, i4.RenderType_PriceComponent)), i1.ɵdid(10, 114688, null, 0, i5.PriceComponent, [i6.CountryService], { baseCurrency: [0, "baseCurrency"], price: [1, "price"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InvoiceOverviewComponent_6)), i1.ɵdid(12, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_3 = "EUR"; var currVal_4 = _v.context.$implicit.total; _ck(_v, 10, 0, currVal_3, currVal_4); var currVal_5 = (_v.context.$implicit.tasks && (_v.context.$implicit.tasks.length > 0)); _ck(_v, 12, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.number; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.date; _ck(_v, 4, 0, currVal_1); var currVal_2 = _v.context.$implicit.duedate; _ck(_v, 6, 0, currVal_2); }); }
function View_InvoiceOverviewComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Task invoices"])), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "boxing"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InvoiceOverviewComponent_4)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InvoiceOverviewComponent_5)), i1.ɵdid(7, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.purchaseRegisteredInvoices.length === 0); _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.purchaseRegisteredInvoices; _ck(_v, 7, 0, currVal_1); }, null); }
function View_InvoiceOverviewComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "boxing"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "p", [["style", "font-size: 15px; margin-right: 35px;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["You have tasks which are not yet invoiced."])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" # tasks eligible to invoice: ", " "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openNewInvoice() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Register invoice "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.invoiceableTaskCount; _ck(_v, 4, 0, currVal_0); }); }
function View_InvoiceOverviewComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h5", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["You do not have any project invoices yet."]))], null, null); }
function View_InvoiceOverviewComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["# Projects: ", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.projects.length; _ck(_v, 1, 0, currVal_0); }); }
function View_InvoiceOverviewComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "module"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openInvoice(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["Invoice date: ", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 3, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Total: "])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "price", [], null, null, null, i4.View_PriceComponent_0, i4.RenderType_PriceComponent)), i1.ɵdid(8, 114688, null, 0, i5.PriceComponent, [i6.CountryService], { price: [0, "price"], showBase: [1, "showBase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InvoiceOverviewComponent_11)), i1.ɵdid(10, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_2 = _v.context.$implicit.price.vatincl; var currVal_3 = true; _ck(_v, 8, 0, currVal_2, currVal_3); var currVal_4 = (_v.context.$implicit.projects && (_v.context.$implicit.projects.length > 0)); _ck(_v, 10, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.number; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.date; _ck(_v, 4, 0, currVal_1); }); }
function View_InvoiceOverviewComponent_13(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "tr", [["class", "clickrow"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.gotoProject(_v.context.$implicit.id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", " ", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "price", [], null, null, null, i4.View_PriceComponent_0, i4.RenderType_PriceComponent)), i1.ɵdid(9, 114688, null, 0, i5.PriceComponent, [i6.CountryService], { price: [0, "price"], showBase: [1, "showBase"] }, null)], function (_ck, _v) { var currVal_4 = _v.context.$implicit.totalprice; var currVal_5 = true; _ck(_v, 9, 0, currVal_4, currVal_5); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.title; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.requestedby.firstName; var currVal_2 = _v.context.$implicit.requestedby.lastName; _ck(_v, 4, 0, currVal_1, currVal_2); var currVal_3 = _v.context.$implicit.duedate; _ck(_v, 6, 0, currVal_3); }); }
function View_InvoiceOverviewComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["# projects in the pipeline: ", " ( "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "price", [], null, null, null, i4.View_PriceComponent_0, i4.RenderType_PriceComponent)), i1.ɵdid(4, 114688, null, 0, i5.PriceComponent, [i6.CountryService], { price: [0, "price"], showBase: [1, "showBase"] }, null), (_l()(), i1.ɵted(-1, null, [" ) "])), (_l()(), i1.ɵeld(6, 0, null, null, 11, "table", [["style", "width: 80%"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 8, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Title"])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Requested by"])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Due date"])), (_l()(), i1.ɵeld(14, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Price"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InvoiceOverviewComponent_13)), i1.ɵdid(17, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.openProjectAmount; var currVal_2 = true; _ck(_v, 4, 0, currVal_1, currVal_2); var currVal_3 = _co.unpaidProjects; _ck(_v, 17, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.unpaidProjects.length; _ck(_v, 2, 0, currVal_0); }); }
function View_InvoiceOverviewComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Project invoices"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InvoiceOverviewComponent_9)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "boxing"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InvoiceOverviewComponent_10)), i1.ɵdid(7, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InvoiceOverviewComponent_12)), i1.ɵdid(9, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.salesRegisteredInvoices.length === 0); _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.salesRegisteredInvoices; _ck(_v, 7, 0, currVal_1); var currVal_2 = ((_co.unpaidProjects != null) && (_co.unpaidProjects.length > 0)); _ck(_v, 9, 0, currVal_2); }, null); }
export function View_InvoiceOverviewComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InvoiceOverviewComponent_1)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InvoiceOverviewComponent_3)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InvoiceOverviewComponent_7)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InvoiceOverviewComponent_8)), i1.ɵdid(8, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.purchaseRegisteredInvoices && (!_co.salesRegisteredInvoices || ((_co.salesRegisteredInvoices.length == 0) && _co.isTranslator()))); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.purchaseRegisteredInvoices; _ck(_v, 4, 0, currVal_1); var currVal_2 = (_co.invoiceableTaskCount && (_co.invoiceableTaskCount > 0)); _ck(_v, 6, 0, currVal_2); var currVal_3 = (_co.salesRegisteredInvoices && !((_co.salesRegisteredInvoices.length === 0) && _co.isTranslator())); _ck(_v, 8, 0, currVal_3); }, null); }
export function View_InvoiceOverviewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "invoice-overview", [], null, null, null, View_InvoiceOverviewComponent_0, RenderType_InvoiceOverviewComponent)), i1.ɵdid(1, 114688, null, 0, i7.InvoiceOverviewComponent, [i2.ActivatedRoute, i2.Router, i8.UserService, i9.InvoiceService, i10.ProjectService, i11.TaskService, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InvoiceOverviewComponentNgFactory = i1.ɵccf("invoice-overview", i7.InvoiceOverviewComponent, View_InvoiceOverviewComponent_Host_0, { userInput: "user" }, {}, []);
export { InvoiceOverviewComponentNgFactory as InvoiceOverviewComponentNgFactory };
