<div>
  <div>
    <h1 *ngIf="project">{{project.title}} <span (click)="openHelp()" style="cursor: pointer"><img src="../../assets/icons8-help-32.png"></span></h1>
    <h2>{{source|languagePipe|async}} <span *ngIf="target"> to {{target|languagePipe|async}}</span></h2>
  </div>
  <div *ngIf="project" style="display: flex; min-height: 300px; margin: 30px;">
    <div *ngFor="let file of sourcefiles" (click)="openEditor(file.name)" style="margin-right: 10px; cursor: pointer; max-height: 200px;">
      <h3>{{file.name|filenamePipe:24:true}}</h3>
      <frontpage [pId]="pId" [fileName]="file.name" [width]></frontpage>
      <div class="fileinfo">
        <div style="display: flex;">
        <label># words:&nbsp;</label>
        <label>{{file.wordcount}}</label></div>
        <div *ngIf="file.metaData">
          <label *ngIf="file.metaData && file.metaData.change">Last change:</label>
          <span>{{file.metaData.change|date:'EEE, MMM dd, y, HH:mm'}}</span>
          <label *ngIf="file.metaData && file.metaData.saved">Last finalized:</label>
          <span>{{file.metaData.saved|date:'EEE, MMM dd, y, HH:mm'}}</span>
          <div class="warning" *ngIf="hasUnsavedChanges(file)">
              <p>Some changes have not been finalized.</p>
        </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="sourcefiles">
    <!--Create an editor component for each sourcefile (to make sure that no reuse of other files is done)
    Only show the one which has the same filename as the selected file-->
    <div *ngFor="let editorFile of sourcefiles">
      <div *ngIf="selectedFile==editorFile.name" style="border-top: 1px black solid;">
        <final-check-editor [pId]="pId" [source]="source" [target]="target"
                            [filename]="editorFile.name"
                            (finalizeEmitter)="finalized(editorFile.name)" (changeEmitter)="changes(editorFile.name)"></final-check-editor>
      </div>
    </div>
  </div>
</div>
