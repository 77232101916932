/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "./gtac-input.component";
import * as i3 from "../service/user.service";
import * as i4 from "ngx-cookie-service";
var styles_GTACInputComponent = ["input[_ngcontent-%COMP%] { margin: 0; padding: 0 }", "label[_ngcontent-%COMP%] { font-size: small; color: var(--main-color); padding: 0; margin: 0 0 0 5px;}", "a[_ngcontent-%COMP%] { color: var(--second-color); font-size: inherit; }"];
var RenderType_GTACInputComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_GTACInputComponent, data: {} });
export { RenderType_GTACInputComponent as RenderType_GTACInputComponent };
export function View_GTACInputComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [["style", "display: flex; justify-content: center; padding: 0.5em 1em;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "input", [["id", "gtac"], ["type", "checkbox"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "change"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onChange($event.target.checked) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 2).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("ngModelChange" === en)) {
        var pd_2 = ((_co.isAccepted = $event) !== false);
        ad = (pd_2 && ad);
    } if (("change" === en)) {
        var pd_3 = (_co.updateGTAC() !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 16384, null, 0, i1.CheckboxControlValueAccessor, [i0.Renderer2, i0.ElementRef], null, null), i0.ɵprd(1024, null, i1.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i1.CheckboxControlValueAccessor]), i0.ɵdid(4, 671744, null, 0, i1.NgModel, [[8, null], [8, null], [8, null], [6, i1.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i1.NgControl, null, [i1.NgModel]), i0.ɵdid(6, 16384, null, 0, i1.NgControlStatus, [[4, i1.NgControl]], null, null), (_l()(), i0.ɵeld(7, 0, null, null, 4, "label", [["for", "gtac"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" I accept the "])), (_l()(), i0.ɵeld(9, 0, null, null, 1, "a", [["href", "https://about.lilo.global/gtac"], ["target", "blank"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["general terms and conditions"])), (_l()(), i0.ɵted(-1, null, [". "]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.isAccepted; _ck(_v, 4, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 6).ngClassUntouched; var currVal_1 = i0.ɵnov(_v, 6).ngClassTouched; var currVal_2 = i0.ɵnov(_v, 6).ngClassPristine; var currVal_3 = i0.ɵnov(_v, 6).ngClassDirty; var currVal_4 = i0.ɵnov(_v, 6).ngClassValid; var currVal_5 = i0.ɵnov(_v, 6).ngClassInvalid; var currVal_6 = i0.ɵnov(_v, 6).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_GTACInputComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "gtac-input", [], null, null, null, View_GTACInputComponent_0, RenderType_GTACInputComponent)), i0.ɵdid(1, 114688, null, 0, i2.GTACInputComponent, [i3.UserService, i4.CookieService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GTACInputComponentNgFactory = i0.ɵccf("gtac-input", i2.GTACInputComponent, View_GTACInputComponent_Host_0, {}, { gtacAcceptEmitter: "gtacAcceptEmitter" }, []);
export { GTACInputComponentNgFactory as GTACInputComponentNgFactory };
