<div class="main" *ngIf="project !== undefined">
  <div [ngClass]="{'section':true, 'filesSection':true}" id="yourFilesSection">
    <div *ngIf="sourceFiles === undefined || sourceFiles.length === 0" class="nofiles">
      <h1>You don't have any files yet</h1>
      <source-file-add [pId]="project.id" [uploadedFiles]="sourceFiles"
        (onFileUpload)="onFileUploaded($event)"></source-file-add>
    </div>
    <div *ngIf="sourceFiles != undefined && sourceFiles.length > 0">
      <div class="sourceFilesTitle">
        <h1>Your files</h1>
      </div>
      <div class="fileListings">
        <div>
          <source-file-list [files]="sourceFiles" [pId]="project.id" [expectedLanguage]="sourceLang"
            [service]="project.service" (onFileRemove)="onFileRemove($event)"
            (onFileMetaUpdate)="onFileMetaUpdated($event)"></source-file-list>
          <source-file-add [pId]="project.id" [uploadedFiles]="sourceFiles"
            (onFileUpload)="onFileUploaded($event)"></source-file-add>
        </div>
        <div style="margin-top: 10px;">
          <div class="documentationFilesSection" *ngIf="docFiles != null && docFiles.length > 0">
            <div class="documentationInfo">
              <h4>Documentation files</h4>
              <p>Reference material, Terminology, Product information, ...</p>
              <label class="documentationWarning">These files will not be translated.</label>
            </div>
            <div>
            </div>
          </div>
          <documentation-list [files]="docFiles" [entityId]="project.id" [entityType]="'project'" [allowRemoval]="true">
          </documentation-list>
          <documentation-file-add [entityId]="project.id" [entityType]="'project'" [uploadedFiles]="docFiles"
            (onFileUpload)="onDocFileUploaded($event)"></documentation-file-add>
        </div>
      </div>
    </div>
  </div>
  <div id="languageSelectionSection"
    [ngClass]="{section: true, languageSection: true, disabledSection: !languageSectionEnabled}">
    <div style="display: flex;">
      <div style="display: flex;" (click)="onServiceSelectionClick()" class="serviceSelection">
        <h1>{{project.service}}</h1>
        <span (click)="openServicesHelp($event)" style="cursor: pointer"><img src="../../assets/icons8-help-32.png"
            style="height: 20px; width: 20px;" />
        </span>
        <img src="../../assets/icon_down.svg" [ngClass]="{'collapseIcon':true, 'collapseIconOpen':selectingService}" />
        <div *ngIf="selectingService === true">
          <div *ngFor="let serviceOption of services">
            <h5 (click)="onServiceSelectionChange(serviceOption)" class="serviceOption">{{serviceOption}}</h5>
          </div>
        </div>
      </div>
    </div>
    <param-select id="paramSelectComponent" [selectedParameters]="serviceParameter"
      (eventEmitter)="setServiceParams($event)" (changeEmitter)="onLanguageSelectionChange($event)"></param-select>
  </div>
  <div id="translatorSelectionSection"
    [ngClass]="{section: true, translatorSection: true, disabledSection: !translatorSectionEnabled}">
    <h1>Choose your translator</h1>
    <div *ngIf="!this.userService.isLoggedIn()" style="margin-bottom: 10px;">
      <strong>Already have an account? To optimize our matching algorithm
        <span (click)="openSignIn()" class="loginLink">sign in</span> first.</strong>
    </div>
    <div *ngIf="project.langpairs != undefined">
      <language-bar id="lb_{{ langpair.target }}" *ngFor="let langpair of project.langpairs" [projectId]="project.id"
        [service]="project.service" [langpair]="langpair" [files]="sourceFiles" [duedate]="proposedDueDate"
        [loadInternally]="false" [translators]="loadedTranslators[getSearchStringForParam(langpair)]"
        [revisers]="loadedRevisers[getSearchStringForParam(langpair)]"
        [isCollapsed]="openLanguageBar != langpair.target" [isQuote]="project.isQuote"
        (onPriceChange)="priceUpdated(langpair, $event)" (onExpand)="setOpenLanguageBar(langpair)"
        (onNext)="openNextLangPair($event)"></language-bar>
    </div>
  </div>
  <div id="projectInfoSection"
    [ngClass]="{section: true, projectInfoSection: true, disabledSection: !projectInfoSectionEnabled}">
    <h1>Project info</h1>
    <project-info-create *ngIf="project != undefined" [project]="project" [proposedDueDate]="proposedDueDate"
      [mtValid]="mtValid" [defaultShares]="defaultShares" (onSaveSharesChanged)="updateSaveSharesAsDefault($event)"
      (eventEmitter)="scrollToSection('userInfo')"></project-info-create>
  </div>
  <div id="userInfo" [ngClass]="{section: true, custDetailsSection: true, disabledSection: !custDetailsSectionEnabled}">
    <h1>Your details</h1>
    <project-user-info *ngIf="project != undefined" [project]="project"
      (eventEmitter)="userInfoStored()"></project-user-info>
  </div>
  <div id="paymentSection" [ngClass]="{section: true, paymentSection: true, disabledSection: !paymentSectionEnabled}">
    <h1>Payment</h1>
    <project-payment id="paymentComponent" *ngIf="this.userService.isLoggedIn()" [project]="project"
      (paymentMethodEmitter)="updatePaymentMethod($event)"></project-payment>
  </div>
  <div class="side" *ngIf="!project.isQuote">
    <h1>Your price</h1>
    <project-pricing-overview [project]="project" [reloadPriceTrigger]="updatedLangPrices"
      [allowPriceDownload]="paymentSectionEnabled"
      (priceCalculatedEmitter)="priceTotalCalculated($event)"></project-pricing-overview>
  </div>
  <div class="side quote" *ngIf="project.isQuote">
    <h3>Quote request</h3>
    <p>
      We can't automatically calculate the price.<br/>
      The price for sworn translations depends on a few variables such as the number of pages, whether or not an
      apostille is necessary (and for which country), etc.
    </p>
    <p>
      Please select a translator and hit the 'request quote' button. The translator might get back to you with some
      additional questions and will then provide you with a price offer.
    </p>
    <p>
      To speed up the process, add the following information in the project chat:
    </p>
    <ul>
      <li>Do you need an apostille? And for which country?</li>
      <li>Do you need a paper version or is the digital version sufficient?</li>
    </ul>
    <p>
      You will be informed as soon as the translator created the quote.
    </p>
  </div>
  <div id="orderSection" [ngClass]="{section: true, orderSection: true}">
    <div>
      <div style="float: left">
        <button class="btnCancel" (click)="cancelProject()">Cancel</button>
      </div>
      <div style="float: right; position: relative;">
        <gtac-input *ngIf="userService.getCurrentUser() == null
          || userService.getCurrentUser().acceptedgtac == null
          || userService.getCurrentUser().acceptedgtac.version == null
          || gtacVersion > userService.getCurrentUser().acceptedgtac.version" (gtacAcceptEmitter)="handleSetUser()"
          style="float: right; margin-top: -30px;" #gtac></gtac-input>
        <paypal *ngIf="project.paymentmethod === 'PAYPAL'" [project]="project" (approveEmitter)="placeOrder()"></paypal>
        <div *ngIf="project.paymentmethod != 'PAYPAL' && !project.isQuote">
          <button (click)="placeOrder()" [ngClass]="{disabledSection: !orderSectionEnabled}"
            [disabled]="orderSubmitting">
            Place order <img *ngIf="orderSubmitting" src="../../assets/loading_small.gif" /></button>
        </div>
        <div *ngIf="project.isQuote">
          <button (click)="placeOrder()" [ngClass]="{disabledSection: !orderSectionEnabled}"
            [disabled]="orderSubmitting">
            Request quote <img *ngIf="orderSubmitting" src="../../assets/loading_small.gif" /></button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="canceledProject" *ngIf="project && project.canceled">
  <h1>PROJECT IS CANCELLED</h1>
</div>
