/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./task.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./task-file.component.ngfactory";
import * as i3 from "./task-file.component";
import * as i4 from "../service/task.service";
import * as i5 from "../service/file.service";
import * as i6 from "../service/language.service";
import * as i7 from "../service/user.service";
import * as i8 from "@angular/material/dialog";
import * as i9 from "@angular/material/snack-bar";
import * as i10 from "@angular/router";
import * as i11 from "@angular/common";
import * as i12 from "./task-file-list.component";
import * as i13 from "../service/project.service";
var styles_TaskFileListComponent = [i0.styles];
var RenderType_TaskFileListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TaskFileListComponent, data: {} });
export { RenderType_TaskFileListComponent as RenderType_TaskFileListComponent };
function View_TaskFileListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "task-file", [["class", "taskFileTile"]], null, null, null, i2.View_TaskFileComponent_0, i2.RenderType_TaskFileComponent)), i1.ɵdid(1, 376832, null, 0, i3.TaskFileComponent, [i4.TaskService, i5.FileService, i6.LanguageService, i7.UserService, i8.MatDialog, i9.MatSnackBar, i10.Router], { file: [0, "file"], task: [1, "task"], availableJobs: [2, "availableJobs"], availableXliffs: [3, "availableXliffs"], availablePDFs: [4, "availablePDFs"], availableTMXs: [5, "availableTMXs"], source4pdf: [6, "source4pdf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.task; var currVal_2 = _co.availableJobs; var currVal_3 = _co.availableXliffs; var currVal_4 = _co.availablePdfs; var currVal_5 = _co.availableTmxs; var currVal_6 = _co.getPdf4Source(_v.context.$implicit.name); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
function View_TaskFileListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "taskFileTileView"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TaskFileListComponent_3)), i1.ɵdid(3, 278528, null, 0, i11.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.taskFiles; _ck(_v, 3, 0, currVal_0); }, null); }
function View_TaskFileListComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["No files ready yet."]))], null, null); }
function View_TaskFileListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TaskFileListComponent_2)), i1.ɵdid(2, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TaskFileListComponent_4)), i1.ɵdid(4, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.taskFiles.length > 0); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.taskFiles.length === 0); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_TaskFileListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_TaskFileListComponent_1)), i1.ɵdid(1, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.taskFiles != undefined); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_TaskFileListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "task-file-list", [], null, null, null, View_TaskFileListComponent_0, RenderType_TaskFileListComponent)), i1.ɵdid(1, 114688, null, 0, i12.TaskFileListComponent, [i13.ProjectService, i4.TaskService, i5.FileService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TaskFileListComponentNgFactory = i1.ɵccf("task-file-list", i12.TaskFileListComponent, View_TaskFileListComponent_Host_0, { task: "task", taskFiles: "taskFiles", docFiles: "docFiles" }, {}, []);
export { TaskFileListComponentNgFactory as TaskFileListComponentNgFactory };
