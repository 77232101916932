import {map, mergeMap} from 'rxjs/operators';
import {AfterViewInit, ApplicationRef, Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {UserService, UserToCreate} from "../service/user.service";
import {Router} from "@angular/router";
import {FileService} from "../service/file.service";
import {JoinDialogOutcome, JoinDialogResult, JoinType} from "./user.join.model";

declare const gapi: any;

@Component({
  selector: 'user-join-dialog',
  templateUrl: 'user.join.component.html',
  styleUrls: ['./user.settings.component.css']
})
export class UserJoinDialog implements AfterViewInit {

  public user = {
    email: undefined,
    firstname: undefined,
    lastname: undefined,
    companytoken: undefined,
    grouptoken: undefined,
  };
  public joining = false;
  public auth2Joining = false;

  private callback: string = undefined;
  //TODO Streamline with linked in process or the other way around (most conventional)
  private auth2: any;

  constructor(public dialogRef: MatDialogRef<UserJoinDialog>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private userservice: UserService,
              private fileService: FileService,
              private router: Router,
              private applicationRef: ApplicationRef) {
    dialogRef.disableClose = true;
  }

  public ngOnInit(): void {
    console.debug("Initializing join dialog", this.data);
    this.user.companytoken = this.data.companyToken;
    this.user.grouptoken = this.data.groupToken;
    this.callback = this.data.callback;
  }

  public ngAfterViewInit(): void {
    this.googleInit();
  }

  public hasToken(): boolean {
    return this.user.companytoken != null || this.user.grouptoken != null;
  }

  public joinWithLinkedIn(): void {
    this.auth2Joining = true;
    let liWindow = this.userservice.openLinkedInAuth();
    onstorage = event => {
      if (event.key == "linkedInAuthCode") {
        this.userservice.authLinkedIn(event.newValue).pipe(
          map(e => {
            localStorage.setItem("linkedInAccessToken", JSON.stringify(e));
            return e;
          }),
          mergeMap(token =>
            this.userservice.linkedInProfile(token["access_token"])
          ),
          mergeMap(profile => {
              return this.userservice.joinWithAuth2(profile);
            }
          ),)
          .subscribe(
            signInData => {
              this.close({result: JoinDialogOutcome.SUCCESS, join: JoinType.LINKEDIN, user: signInData});
              if (this.callback) {
                this.router.navigate([this.callback])
              }
            },
            error => {
              this.close({result: JoinDialogOutcome.ERROR, reason: error});
            },
            () => this.auth2Joining = false
          )
      }
    };
  }

  private googleInit(): void {
    gapi.load('auth2', () => {
      this.auth2 = gapi.auth2.init({
        client_id: '49647298930-jjon9r6naht63oq9mc9b9cc9avm5ttt4.apps.googleusercontent.com',
        cookiepolicy: 'single_host_origin',
        scope: 'profile email'
      });
      const googleBtn = document.getElementById('googleBtn');
      if (googleBtn != null)
        this.attachSignIn(googleBtn);
    });
  }

  private attachSignIn(element): void {
    element.onclick = () => {
      this.auth2Joining = true;
    };
    this.auth2.attachClickHandler(element, {},
      (googleUser) => {
        let profile = googleUser.getBasicProfile();
        //List of fields we want to retrieve from linked in
        let userData = this.mapGoogleToUser(googleUser);
        this.userservice.joinWithAuth2(userData).subscribe(
          signInData => {
            //TODO Try to store the picture as well
            // try {
            //   this.userservice.uploadPictureFromLinkedIn(googleUser.getBasicProfile().getImageUrl(), userData.id);
            // } catch (e) {
            //   //Do nothing if we were not able to store the picture
            // }
            this.close({result: JoinDialogOutcome.SUCCESS, join: JoinType.GOOGLE, user: signInData});
            if (this.callback) {
              this.router.navigate([this.callback])
            }
            this.applicationRef.tick();
          },
          error => {
            this.close({result: JoinDialogOutcome.ERROR, reason: error});
            this.applicationRef.tick();
          },
          () => {
            this.auth2Joining = false;
          }
        );
      }
    );
  }

  public joinViaMail(): void {
    this.joining = true;
    const user: UserToCreate = {
      mail: this.user.email,
      firstName: this.user.firstname,
      lastName: this.user.lastname,
      callbackUrl: this.callback,
      companytoken: this.user.companytoken,
      grouptoken: this.user.grouptoken,
    };
    this.userservice.joinWithMail(user)
      .subscribe(r => {
          try {
            let user = JSON.parse(r);
            this.joining = false;
            this.close({result: JoinDialogOutcome.SUCCESS, join: JoinType.MAIL, user: user});
          } catch (e) {
            console.error('Error joining user with mail: ', e);
            this.close({result: JoinDialogOutcome.ERROR, reason: e});
          }
        }
      );
  }

  public signInPopup(): void {
    this.close({result: JoinDialogOutcome.SIGN_IN});
  }

  public close(result: JoinDialogResult = {result: JoinDialogOutcome.CANCEL}): void {
    this.dialogRef.close(result);
  }

  private mapLinkedInToUser(linkedInData: any): any {
    //'id', 'first-name', 'last-name', 'email-address', 'headline', 'industry', 'summary', 'positions', 'public-profile-url'
    return {
      id: linkedInData.id,
      firstName: linkedInData.firstName,
      lastName: linkedInData.lastName,
      email: linkedInData.emailAddress,
      headline: linkedInData.headline,
      industry: linkedInData.industry,
      description: linkedInData.summary,
      positions: linkedInData.positions,
      linkedInProfileUrl: linkedInData.publiceProfileUrl
    }
  }

  private mapGoogleToUser(googleUser: any): any {
    let profile = googleUser.getBasicProfile();
    return {
      googleToken: googleUser.getAuthResponse().id_token,
      id: profile.getId(),
      firstName: profile.getName(),
      email: profile.getEmail()
    }
  }
}
