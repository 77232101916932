<div>
  <h1>Invites</h1>
  <div *ngIf="invites==undefined">
    Loading
  </div>
  <div *ngIf="invites!=undefined && invites.length==0">
    You do not have any invites yet, please use the invite button at the top to send some.
    <strong>More info on invites.</strong>
  </div>
  <div *ngIf="invites!=undefined && invites.length>0">
    <div class="inviteHead" style="display: flex">
      <div>Send to</div>
      <div>Send on</div>
      <div>State</div>
    </div>
    <div *ngFor="let invite of invites" style="display: flex" class="inviteRow">
      <div>{{invite.address}}</div>
      <div><span *ngIf="invite.senddate != undefined">{{sendDate(invite)}}</span></div>
      <div>{{inviteState(invite)}}</div>
    </div>
  </div>
</div>
