/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./app.component.ngfactory";
import * as i3 from "./app.component";
import * as i4 from "@angular/material/dialog";
import * as i5 from "./service/user.service";
import * as i6 from "./service/project.service";
import * as i7 from "@angular/router";
import * as i8 from "./service/login.service";
import * as i9 from "./projectcomponent/index-main.component.ngfactory";
import * as i10 from "./projectcomponent/index-main.component";
import * as i11 from "./costcalcomponent/cost-calc.component.ngfactory";
import * as i12 from "./costcalcomponent/cost-calc.component";
import * as i13 from "./service/pricing.services";
import * as i14 from "./service/task.service";
import * as i15 from "./service/file.service";
import * as i16 from "./service/analysis.service";
import * as i17 from "./service/vendor.service";
import * as i18 from "@angular/common";
import * as i19 from "./root.component";
var styles_RootComponent = [i0.styles];
var RenderType_RootComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RootComponent, data: {} });
export { RenderType_RootComponent as RenderType_RootComponent };
function View_RootComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "my-app", [], null, null, null, i2.View_AppComponent_0, i2.RenderType_AppComponent)), i1.ɵdid(1, 114688, null, 0, i3.AppComponent, [i4.MatDialog, i5.UserService, i6.ProjectService, i7.Router, i7.ActivatedRoute, i8.LogInService, i1.NgZone], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_RootComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "index-main", [], null, null, null, i9.View_IndexMainComponent_0, i9.RenderType_IndexMainComponent)), i1.ɵdid(1, 49152, null, 0, i10.IndexMainComponent, [], { size: [0, "size"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dropValue; _ck(_v, 1, 0, currVal_0); }, null); }
function View_RootComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "cost-calc", [], null, null, null, i11.View_CostCalcComponent_0, i11.RenderType_CostCalcComponent)), i1.ɵdid(1, 49152, null, 0, i12.CostCalcComponent, [i6.ProjectService, i13.PricingService, i14.TaskService, i15.FileService, i16.AnalysisService, i17.VendorService], null, null)], null, null); }
export function View_RootComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["style", "min-height: 95%;"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RootComponent_1)), i1.ɵdid(2, 16384, null, 0, i18.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RootComponent_2)), i1.ɵdid(4, 16384, null, 0, i18.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RootComponent_3)), i1.ɵdid(6, 16384, null, 0, i18.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isApp; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.isDrop; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.isPriceCalc; _ck(_v, 6, 0, currVal_2); }, null); }
export function View_RootComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "root-app", [], null, null, null, View_RootComponent_0, RenderType_RootComponent)), i1.ɵdid(1, 114688, null, 0, i19.RootComponent, [i7.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RootComponentNgFactory = i1.ɵccf("root-app", i19.RootComponent, View_RootComponent_Host_0, {}, {}, []);
export { RootComponentNgFactory as RootComponentNgFactory };
