<div>
  <h1>Company and billing information</h1>

  <!-- todo: make it easier for people to link themselves to their company -->
  <!-- todo: maybe suggest a company based on their email address, and have a list of accepted email addresses per company? -->
  <!-- todo: do we have a verify email address function? -->

  <p *ngIf="allowPrivatePersonSelection() && allowCompanySelection()" style="margin-bottom: 20px;">
    Please indicate whether you are affiliated with a company, or whether you are a private person.<br/>
    If you are not a private person and you think your company exists already, please request an invitation from one of
    your colleagues.<br/>
    <br/>
    Do note that once you fill in your company details and &quot;<i>save</i>&quot;, you will <u>not be able to switch
    back to private person</u>.
  </p>
  <form>
    <div *ngIf="allowPrivatePersonSelection()" style="display: flex; align-items: center;">
      <input type="checkbox" id="privatePersonId"
             [checked]="privatePerson"
             (change)="privatePerson = !privatePerson"/>
      <label for="privatePersonId" style="margin-left: 10px; padding: 0px; font-size: medium;">I'm a private
        person</label>
    </div>
    <div *ngIf="allowPrivatePersonSelection() && allowCompanySelection()" style="text-align: center; width: 75%;">
      <p>-- OR --</p>
    </div>
    <div *ngIf="allowCompanySelection() && company!=undefined">
      <p *ngIf="isTranslator">In order to invoice, we'll need some information on your legal entity.
        This data will be verified at the moment you upload your first invoice.<br/>
        Please make sure all information is correct to ensure a smooth payment process.</p>
      <div class="row">
        <label for="companyName">Company name</label>
        <input id="companyName" name="companyName" type="text" [(ngModel)]="company.name"/>
      </div>
      <div class="row">
        <label for="companyAddress">Legal entity address</label>
        <textarea id="companyAddress" name="companyAddress" [(ngModel)]="company.address"></textarea>
      </div>
      <div class="row" *ngIf="countries">
        <label for="companyCountry">Country</label>
        <select id="companyCountry" name="companyCountry" [(ngModel)]="company.country">
          <option *ngFor="let country of countries" [ngValue]="country.display">{{country.display}}</option>
        </select>
      </div>
      <div class="row">
        <label for="companyPresentative">Legal representative</label>
        <input id="companyPresentative" name="companyPresentative" type="text" [(ngModel)]="company.presentative"/>
      </div>
      <div class="row">
        <label for="traderegister">Trade register number</label>
        <input id="traderegister" name="traderegister" type="text" [(ngModel)]="company.traderegister"/>
      </div>
      <div class="row">
        <label for="vat">VAT number</label>
        <input id="vat" name="vat" type="text" [(ngModel)]="company.vat" (input)="vatChange($event)"/>
      </div>
      <div>
        <span *ngIf="isVatValid && !checkingVat" style="color: green">Your VAT number is valid.</span>
        <span *ngIf="isVatValid!=undefined && !isVatValid && !checkingVat"><span style="color: red">Your VAT seems to be invalid.</span> Can you double check?<br/>
        In case you do feel it is valid, please save your company details as is and contact us at info@lilo.global.</span>
        <span *ngIf="checkingVat"><img src="../../assets/loading_small.gif"/>Checking your VAT</span>
      </div>
      <div class="row">
        <label for="bankAccount">Bank account number</label>
        <input id="bankAccount" name="bankAccount" type="text" [(ngModel)]="company.bankaccount"/>
      </div>
      <div class="row">
        <label for="companyEmail">General email address</label>
        <input id="companyEmail" name="companyEmail" type="email" [(ngModel)]="company.email"/>
      </div>
      <div class="row">
        <label for="companyInfo">Extra info</label>
        <textarea id="companyInfo" name="companyInfo" [(ngModel)]="company.info"></textarea>
      </div>
    </div>
    <div class="row" style="margin-top: 10px;">
      <button type="submit" (click)="save()">Save</button>
    </div>
  </form>
  <div class="row colleagueInvite" *ngIf="company && company.id && !user.privatePerson">
    <h3>Invite a colleague</h3>
    <p>Do you have a colleague you want to link to this company?</p>
    <input type="email" class="emailInvite" placeholder="Your colleague's email address" [(ngModel)]="inviteMail">
    <button (click)="sendInvite()">Send invite</button>
    <!--<div>Or ask them to fill in this token under company info: {{company.token}}
      <button (click)="renewToken()">Renew token</button>
    </div>-->
  </div>
</div>
