/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../project.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./target-file.component.ngfactory";
import * as i3 from "./target-file.component";
import * as i4 from "../../service/file.service";
import * as i5 from "@angular/common";
import * as i6 from "@angular/router";
import * as i7 from "./langpair-target-files.component";
import * as i8 from "@angular/material/dialog";
import * as i9 from "@angular/common/http";
var styles_LangpairTargetFilesComponent = [i0.styles];
var RenderType_LangpairTargetFilesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LangpairTargetFilesComponent, data: {} });
export { RenderType_LangpairTargetFilesComponent as RenderType_LangpairTargetFilesComponent };
function View_LangpairTargetFilesComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "target-file", [["class", "targetFileTile"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.download(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_TargetFileComponent_0, i2.RenderType_TargetFileComponent)), i1.ɵdid(1, 114688, null, 0, i3.TargetFileComponent, [i4.FileService], { file: [0, "file"], pId: [1, "pId"], langPair: [2, "langPair"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.project.id; var currVal_2 = _co.getLpString(); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_LangpairTargetFilesComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "targetFileTileView"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LangpairTargetFilesComponent_4)), i1.ɵdid(2, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.downloadableFilesInfo; _ck(_v, 2, 0, currVal_0); }, null); }
function View_LangpairTargetFilesComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.download(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; _ck(_v, 2, 0, currVal_0); }); }
function View_LangpairTargetFilesComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ul", [["class", "targetFileListView"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LangpairTargetFilesComponent_6)), i1.ɵdid(2, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.downloadableFilesInfo; _ck(_v, 2, 0, currVal_0); }, null); }
function View_LangpairTargetFilesComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "checkTranslationLink"]], null, null, null, null, null)), i1.ɵprd(512, null, i5.ɵNgStyleImpl, i5.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i5.NgStyle, [i5.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(3, { "justify-content": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 5, "a", [["routerLinkActive", "active"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 671744, [[2, 4]], 0, i6.RouterLinkWithHref, [i6.Router, i6.ActivatedRoute, i5.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(6, 1720320, null, 2, i6.RouterLinkActive, [i6.Router, i1.ElementRef, i1.Renderer2, [2, i6.RouterLink], [2, i6.RouterLinkWithHref]], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 1, { links: 1 }), i1.ɵqud(603979776, 2, { linksWithHrefs: 1 }), (_l()(), i1.ɵted(-1, null, ["Check translation"])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "span", [["style", "cursor: pointer"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openTransCheckHelp() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "img", [["src", "../../assets/icons8-help-32.png"], ["style", "height: 20px; width: 20px;"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, (_co.viewAsTiles ? "flex-end" : "flex-start")); _ck(_v, 2, 0, currVal_0); var currVal_3 = i1.ɵinlineInterpolate(2, "/translationcheck/", _co.project.id, "/", _co.getLpString(), ""); _ck(_v, 5, 0, currVal_3); var currVal_4 = "active"; _ck(_v, 6, 0, currVal_4); }, function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 5).target; var currVal_2 = i1.ɵnov(_v, 5).href; _ck(_v, 4, 0, currVal_1, currVal_2); }); }
function View_LangpairTargetFilesComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LangpairTargetFilesComponent_3)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LangpairTargetFilesComponent_5)), i1.ɵdid(4, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LangpairTargetFilesComponent_7)), i1.ɵdid(6, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.viewAsTiles; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.viewAsTiles; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.langPair.target; _ck(_v, 6, 0, currVal_2); }, null); }
function View_LangpairTargetFilesComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["No files ready yet."]))], null, null); }
function View_LangpairTargetFilesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LangpairTargetFilesComponent_2)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LangpairTargetFilesComponent_8)), i1.ɵdid(4, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.downloadableFilesInfo.length > 0); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.downloadableFilesInfo.length === 0); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_LangpairTargetFilesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_LangpairTargetFilesComponent_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.downloadableFilesInfo != undefined); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_LangpairTargetFilesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "langpair-target-files", [], null, null, null, View_LangpairTargetFilesComponent_0, RenderType_LangpairTargetFilesComponent)), i1.ɵdid(1, 638976, null, 0, i7.LangpairTargetFilesComponent, [i4.FileService, i8.MatDialog, i9.HttpClient], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LangpairTargetFilesComponentNgFactory = i1.ɵccf("langpair-target-files", i7.LangpairTargetFilesComponent, View_LangpairTargetFilesComponent_Host_0, { project: "project", langPair: "langPair", viewAsTiles: "viewAsTiles" }, {}, []);
export { LangpairTargetFilesComponentNgFactory as LangpairTargetFilesComponentNgFactory };
