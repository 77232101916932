import {map} from 'rxjs/operators';
import {Component, Inject, Input, Output, EventEmitter, OnDestroy, OnInit} from "@angular/core";
import {UserService} from "../service/user.service";
import {ChatService} from "../service/chat.service";
import {MAT_SNACK_BAR_DATA, MatSnackBar} from "@angular/material/snack-bar";
import {Router} from "@angular/router";
import {scrollToBottom} from "../util/apputil";
import { SubscriptionHandler } from '../util/subscription.handler';


@Component({
  selector: 'chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css'],
})
export class ChatComponent implements OnDestroy {

  @Input() projectId;

  messages: any[];
  newMessage = undefined;
  currentRoute = undefined;
  user;

  subscriptionHandler = new SubscriptionHandler(this);

  constructor(private userService: UserService,
              private chatService: ChatService,
              private snackbar: MatSnackBar,
              private router: Router) {

  }

  ngOnInit() {
    this.user = this.userService.getCurrentUser();
    this.userService.userEmitter.subscribe(v =>
      this.user = v
    );
    this.currentRoute = this.router.url;
    if (this.projectId) {
      this.chatService.fetchChatMessages(this.projectId).subscribe(m => {
        this.messages = m;
        this.sortMessages();
        scrollToBottom("messagesDiv");
      });
      //add a link to the current url (as the user might already be in another project or task) (custom component is required)
      const chatSubscription = this.chatService.enrollForProjectChat(this.projectId)
        .subscribe(m => {
          const ref = this.snackbar.openFromComponent(ChatNotification, {
            duration: 5000,
            data: {url: this.currentRoute, messageContent: m.text}
          });
          this.messages.push(m.message);
          this.sortMessages();
          scrollToBottom("messagesDiv");
        });
      this.subscriptionHandler.push(chatSubscription);
    }
  }

  ngOnDestroy() {
  }

  sortMessages(desc: boolean = false) {
    this.messages = this.messages.sort((m1, m2) => {
      let mod = desc ? -1 : 1;
      if (m1.date > m2.date) {
        return mod * 1;
      } else if (m1.date < m2.date) {
        return mod * -1;
      } else return 0;
    })
  }

  getUser() {
    return this.userService.getCurrentUser();
  }

  addNewMessage(message: any) {
    this.newMessage = message;
    this.newMessage.pId = this.projectId;
    scrollToBottom("messagesDiv");
    this.chatService.createNewMessage(this.newMessage).pipe(
      map(res => {
        return res;
      }),
      map(res => res.data),)
      .subscribe(msg => {
          this.newMessage = undefined;
          this.messages.push(msg);
          this.sortMessages();
          scrollToBottom("messagesDiv");
        }, error => {
          console.debug("Error sending message", this.newMessage);
          this.newMessage = undefined;
        },
        () => {
        });
    // When an answer is given to a message mark all messages as read.
    this.markAllAsRead()
  }

  markAllAsRead() {
    if (this.getUser() != null) {
      this.markMessagesAsRead(this.messages, this.getUser().id)
    }
  }

  markMessagesAsRead(messages: any[], userId) {
    if (messages != null) {
      messages
        .map(m => {
          if (m.replies != null) {
            this.markMessagesAsRead(m.replies, userId)
          }
          return m;
        })
        .filter(m => !ChatComponent.isReadMessage(m, userId))
        .forEach(m =>
          this.chatService.markAsRead(m.id, userId).subscribe()
        )
    }
  }

  isRead(message: any) {
    if (this.getUser() == null)
      return true;
    return ChatComponent.isReadMessage(message, this.getUser().id)
  }

  static isReadMessage(message: any, userId: string) {
    // If the given user sent the message, then always assume it is "read"
    if (message.user.id == userId)
      return true;
    if (message.readBy == undefined)
      return false;
    if (message.readBy instanceof Array) {
      return (message.readBy as any[])
        .map(r => r.id)
        .indexOf(userId) >= 0;
    }
    return true;
  }
}


@Component({
  selector: "chat-notification",
  template: "<div>" +
    "<div>" +
    "<div>A new message has been sent:</div>" +
    "<div><strong>{{messageContent}}</strong></div>" +
    "</div>" +
    "<div>To view more, click <a href={{url}}>here</a>.</div>" +
    "</div>"
})
export class ChatNotification {
  messageContent = undefined;
  url = undefined;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {
  }

  ngOnInit(): void {
    this.messageContent = this.data.messageContent;
    this.url = this.data.url;
  }
}
