import {from as observableFrom, Subject} from 'rxjs';
import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from "@angular/core";
import {Project} from "../dto/project";
import {ProjectService} from "../service/project.service";
import {FileService} from "../service/file.service";
import {UploadDataService} from "../projectcomponent/util/upload-data.service";
import {Router} from "@angular/router";
import {environment} from "../../environments/environment";
import {UserService} from "../service/user.service";
import {ConverterService} from '../service/converter.service';
import {scrollToBottom} from "../util/apputil";


@Component({
  selector: 'pre-upload',
  templateUrl: './pre-upload.component.html',
  styleUrls: ['./drop-entry.component.css'],
})
export class PreUploadComponent implements OnInit {

  @Output()
  projectCreatedEmitter = new EventEmitter<string>();
  @Output()
  fileUploadedEmitter = new EventEmitter<string>();

  loading = false;

  files = [];
  projectId: string = undefined;
  convertableFormats: string[] = [];

  @ViewChild("fileupload", {static: true})
  uploadInput: ElementRef;
  @ViewChild("gtac", {static: true})
  gtacInput: ElementRef;


  constructor(private projectService: ProjectService,
              private fileService: FileService,
              private uploadDataService: UploadDataService,
              private userService: UserService,
              private converterService: ConverterService,
              private router: Router,) {
  }

  ngOnInit() {
    this.converterService.getConvertableFileFormats()
      .subscribe(v => this.convertableFormats = v);
    if (this.router.url.endsWith("newproject"))
      this.openFileChooser();
  }

  openFileChooser() {
    if (this.uploadInput != undefined) {
      let evt = document.createEvent("MouseEvents");
      evt.initEvent("click", true, false);
      this.uploadInput.nativeElement.dispatchEvent(evt);
    }
  }

  uploadFiles(event) {
    let fileList: FileList = event.target.files;
    this.onFileChange(fileList);
  }

  onFileChange(files: FileList) {
    this.files = this.files.concat(
      toArray(files)
        .map(f => this.checkWarning(f))
    );
    scrollToBottom("uploadedFilesDiv");
    let p = new Project();
    // Determine the title based on the first file's name
    p.title = this.getTitleFromFiles(files);
    // Create the project
    if (this.projectId === undefined) {
      this.loading = true;
      this.projectService.initProject(p, this.userService.getRemoteUserIp()).subscribe(result => {
          this.projectId = result.id;
          this.addFilesToProject(files);
          this.projectCreatedEmitter.emit(result.id);
        }, err => {
          console.error("Error initializing project", err);
        }, () => this.loading = false
      );
    } else {
      this.addFilesToProject(files);
    }
  }

  private addFilesToProject(files) {
    let pId = this.projectId;
    let fileNames: string[] = [];
    for (let i = 0; i < files.length; i++) {
      fileNames.push(files[i].name);
    }
    // Add the data of the source files to the project
    this.projectService.addSourceFilesData(fileNames, this.projectId).subscribe(n => {
        // We are uploading the source file in an async block so the rest of the application does not wait for the
        // upload to complete
        this.doFileUpload(files, this.projectId);
      }, e => {
        console.error("Error adding source file data", e);
      },
      () => {
      });
    fileNames.forEach(f => this.fileUploadedEmitter.next(f));
  }

  private getTitleFromFiles(files: FileList) {
    if (files && files.length > 0) {
      let file = files[0];
      let i = file.name.lastIndexOf(".");
      if (i > 0)
        return file.name.substring(0, i);
      else return file.name;
    }
    return null;
  }

  private async doFileUpload(files: FileList, pId: string) {
    observableFrom(files).subscribe(file => {
        // Create a subject to send progress events to
        const progress = new Subject<number>();
        // Store the subject as observable in a data provider, so we keep track of it even when navigating away from the page
        this.uploadDataService.addUploadData(pId, file.name, progress);
        // Upload the file itself through the file service
        let start = new Date().getTime();
        this.fileService.uploadProjectFileWithProgress(file, pId, null, progress).subscribe(
          data => {
            let end = new Date();
            console.log("Project " + pId + ": upload of " + file.name + " in " + (end.getTime() - start) + " ms");
          },
          error => console.error("Error uploading source file", error),
          () => {
            progress.complete();
            this.uploadDataService.removeUploadData(pId, file.name);
          }
        );
        progress.subscribe(
          percent => this.loading = true,
          error => this.loading = false,
          () => this.loading = false
        )

      }
    );
  }

  draftProject() {
    this.loading = true;
    this.projectService.draftProject(this.projectId, this.userService.getRemoteUserIp()).subscribe(result => {
        let hasWarnings = this.hasWarnings();
        if (top.location != self.location) {
          top.location.href = environment.appURI + '/pdetail/' + this.projectId + (!hasWarnings ? '?section=languageSelectionSection' : "");
        } else {
          this.router.navigate(['/pdetail', this.projectId], (!hasWarnings ? {queryParams: {'section': 'languageSelectionSection'}} : {}));
        }
      }, error => {
        console.error("Error drafting project", error)
      }, () => this.loading = false
    );
  }

  removeFile(file: File) {
    // TODO
    this.files.splice(this.files.indexOf(file), 1);
    this.projectService.removeSourceFile(file.name, this.projectId).subscribe(v => {
      //Do nothing
    });
  }

  checkWarning(file: File): File {
    let extension = file.name.slice(file.name.lastIndexOf(".") + 1).toLocaleLowerCase()
    if (this.convertableFormats.indexOf(extension) < 0) {
      file["warning"] = "UNCONVERTABLE";
    }
    return file;
  }

  hasWarnings(): boolean {
    return this.files
      .filter(f => f.warning != undefined)
      .length > 0;
  }
}

function toArray(fileList) {
  return Array.prototype.slice.call(fileList);
}
