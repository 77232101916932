<div>
  <div class="close">
    <p (click)="dialogRef.close()">X</p>
  </div>
  <div>
    <div style="display: flex;">
      <div style="width: 100%">
        <div class="row">
          <div class="linkedin" (click)="signInWithLinkedIn()" style="width: 100%">Sign in with LinkedIn</div>
        </div>
        <div class="row">
          <div id="googleBtn" class="google" style="width: 100%">Sign in with Google</div>
        </div>
      </div>
    </div>
    <img src="../../assets/loading.gif" style="max-width:40px;" *ngIf="authJoining">
  </div>
  <div class="divider">
    <span>or</span>
  </div>
  <form>
    <div class="row">
      <label for="email">Email address</label> <input type="email" [(ngModel)]="email" id="email" name="email"
                                                      required style="width: 100%"/><br/>
    </div>
    <div class="row">
      <label for="password">Password</label> <input type={{passwordFieldType}} [(ngModel)]="password" id="password"
                                                    name="password"
                                                    required style="width: 100%"/> <br/>
      <div class="passwordShower">
        <label (click)="showPassword()" *ngIf="passwordFieldType==='password'">Show password</label>
        <label (click)="hidePassword()" *ngIf="passwordFieldType==='text'">Hide password</label>
      </div>
    </div>
    <div class="row">
      <p *ngIf="failed" style="font-size: small"> The combination of email and password seems to be incorrect.<br/>
        Please try again or request a new password.</p>
    </div>
    <div class="row">
      <button type="submit" [disabled]="!(email && password)" (click)="signInViaMail()" style="width: 100%;">Sign in
        with email
      </button>
      <img src="../../assets/loading.gif" style="max-width:40px;" *ngIf="joining">
    </div>
  </form>
  <div class="row" style="text-align: center">
    <a (click)="resetPassword()">Forgot your password? </a>
  </div>
  <div class="divider"></div>
  <div class="row" style="text-align: center">
    <span style="font-size: small">Don't have an account yet? </span><a (click)="openJoinInstead()">Join</a>
  </div>
</div>
