import {filter, map, publish} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {ConnectableObservable, Observable, Subject} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {IntervalObservable} from "rxjs/observable/IntervalObservable";
import {getBaseSocketUrl, getBaseUrl} from "../util/httpclient";


@Injectable()
export class LoggerService {

  private LOGGER_API = getBaseUrl() + "/api/v1/logger";
  private LOGGER_SOCKET_URL = getBaseSocketUrl() + "/logger/feed";

  public messages: Observable<any>;
  private ws: Subject<any>;


  constructor(private http: HttpClient) {

  }

  initLoggerFeed() {
    this.ws = Observable.webSocket(this.LOGGER_SOCKET_URL);
    //Keep the socket alive (every 4 minutes, server timeout is set to 5 minutes)
    IntervalObservable.create(30000).subscribe(o =>
      this.ws.next({'action':'keepalive'})
    );
    this.messages = this.makeHot(this.ws).pipe(filter(m => m != null));
    // this.messages.subscribe(msg => console.debug("> Received " + JSON.stringify(msg) + " @" + this.LOGGER_SOCKET_URL),
    //   error => console.error(error));
  }

  makeHot<T>(cold: Observable<T>): Observable<T> {
    let obs = cold.pipe(publish()) as ConnectableObservable<T>;
    obs.connect();
    return obs;
  }

  /**
   * Enrolling will update the websocket-sender, so that it will send messages to your session on the objects you have enrolled in.
   * Once enrolled, an observable is created to filter out the messages for which you have enrolled but are not for the given object.
   */
  enrollForProject(pId: string): Observable<any> {
    if (!this.ws || this.ws.closed)
      this.initLoggerFeed();
    //To enroll you have to send a message to the socket with the action "enroll" and to which objects you want to enroll
    this.ws.next({'action':'enroll', 'pid': pId });
    //The JSON structure of the message normally has the objects parameters to identify and a "data" object which contains the actual information
    //E.g. {"pid":"10", "filename":"damocles.docx", "data":{"type":"sourcelang", "value":"en"}}
    return this.messages.pipe(filter(msg => {
      return msg.pid == pId;
    }),
      map(msg => msg.data),)
  }

  getLogs(pId: String, fileName: string, langPair: string): Observable<any> {
    let queryParams: String = "";
    if (fileName)
      queryParams += (((queryParams.length == 0) ? "?" : "&") + "fname=" + encodeURIComponent(fileName));
    if (langPair)
      queryParams += (((queryParams.length == 0) ? "?" : "&") + "lp=" + encodeURIComponent(langPair));
    return this.http.get(this.LOGGER_API + "/find/" + pId + queryParams, {responseType: 'json'});
  }

  getFileCounts(pId: string, langPair: string): Observable<any> {
    return this.http.get(this.LOGGER_API + "/counts/" + pId + "/" + encodeURIComponent(langPair), {responseType: 'json'});
  }

}
