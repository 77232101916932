import {Component, EventEmitter, Output} from "@angular/core";

@Component({
  selector: 'event-registration-component',
  templateUrl: './event.registration.component.html',
  styleUrls: ['./user.settings.component.css']
})
export class EventRegistrationComponent {

  event = {"from": null, "to": null, "description": null};

  minDate = new Date().toISOString().substring(0, 10);

  @Output() eventEmitter = new EventEmitter<any>();

  constructor() {
  }

  add() {
    this.eventEmitter.emit(this.event)
  }

  isValid() {
    return this.event && this.event.from && this.event.to && this.event.from < this.event.to;
  }

  getToMinDate() {
    if (this.event && this.event.from)
      return this.event.from;
    return null;
  }

  getFromMinDate() {
    return this.minDate;
  }
}
