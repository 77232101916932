import {map, merge, mergeMap, tap} from 'rxjs/operators';
import {Component, EventEmitter, Input, Output} from "@angular/core";
import {LanguageService} from "../service/language.service";
import {UserService} from "../service/user.service";
import {MatDialog} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ActivityService} from "../service/activity.service";
import {StatisticsService} from "../service/statistics.service";
import {Location} from "@angular/common";
import {Observable} from "rxjs";
import {HelpDialogComponent} from "../util/help.dialog.component";
import {getDefaultPercentage} from "../util/statutil";
import {ACTIVITY_SWORN_TRANSLATION, ACTIVITY_TRANSLATION, DEPENDENCY_SOURCE_LANG, DEPENDENCY_TARGET_LANG, ACTIVITY_EDITING} from '../mock-activities';


@Component({
  selector: "competence-settings",
  templateUrl: './competence.setting.component.html',
  styleUrls: ['./user.settings.component.css']
})
export class CompetenceSettingsComponent {

  @Input() user;
  @Output() userEmitter: EventEmitter<any>;

  activity;
  source: string[] = [];
  target: string;
  price;

  dependencies: string[];

  sourceLanguages;
  targetLanguages;

  languages;
  dialectLanguages;
  charBasedLanguages;
  activities;
  categories;

  selectedCompetences = undefined;
  isShowSave = false;
  hasSworn = false;

  swornSurplus = 0.0;
  postalSurplus = {local: 0.0, eu: 0.0, global: 0.0};

  constructor(private languageService: LanguageService,
              private activityService: ActivityService,
              private statisticsService: StatisticsService,
              private userService: UserService,
              private snackbar: MatSnackBar,
              private dialog: MatDialog,
              private location: Location) {

  }

  ngOnInit() {
    if (!this.user.domains)
      this.user.domains = [];
    this.languageService.getLanguages()
    .pipe(tap(l => this.sourceLanguages = l))
    .subscribe(l => this.languages = l);
    this.languageService.getDialectLanguages()
    .pipe(tap(l => this.targetLanguages = l))
    .subscribe(l => this.dialectLanguages = l);
    this.languageService.getCharBasedLanguages().subscribe(cb => this.charBasedLanguages = cb);
    this.activityService.getActivities().subscribe(acts => this.activities = acts);
    this.statisticsService.getAllCategories().subscribe(cats => this.categories = cats);
    this.price = 0.0;
    this.activity = ACTIVITY_TRANSLATION;
    this.activityService.getDependenciesForActivity(this.activity).subscribe(d => { this.dependencies = d; });
    this.initUser();
  }

  initUser() {
    if (this.user) {
      if (!this.user.percentageSet) {
        this.user.percentageSet = this.initPercentageSet();
      }
      if (this.user.swornSurplus != null) {
        this.swornSurplus = this.user.swornSurplus;
      }
      if (this.user.postalSurplus != null) {
        this.postalSurplus = this.user.postalSurplus;
      }
      this.checkSworn();
    }
  }

  onActivitySelect(event: any) {
    this.activityService.getDependenciesForActivity(this.activity)
      .pipe(
        tap(d => {
          if (d !== this.dependencies) {
            this.target = undefined;
            this.source = [];
          }
        })
      )
      .subscribe(d => this.dependencies = d);
    if (this.activity === ACTIVITY_EDITING ) {
      this.sourceLanguages = this.dialectLanguages;
    } else {
      this.sourceLanguages = this.languages;
    }
  }

  addCompetence() {
    if (!this.user.competences)
      this.user.competences = [];
    // A selection for source language is needed at the least for a competence to be added
    if (this.source != null && this.source.length > 0) {
      let created = [...this.user.competences];
      this.source.forEach(s => {
        if (this.target != null) {
          created.push(this.createCompetence(s, this.target));
        } else
          created.push(this.createCompetence(s, undefined));
      });
      this.user.competences = created;
      this.checkSworn();
      this.showSave();
      if (this.userService.isCurrentUser(this.user))
        this.userService.isCurrentUserTranslator = true;
      // Reset values for source and target language
      this.source = [];
      this.target = undefined;
    }
  }

  private createCompetence(source: any, target: any): any {
    let competence = Object();
    competence.activity = this.activity;
    competence.source = source;
    competence.target = target;
    competence.price = this.price;
    return competence;
  }

  isOneCharacterBasedLang(langs: string[]): boolean {
    let b = false;
    if (langs != undefined)
      langs.forEach(l => {
        if (this.isCharacterBasedLang(l))
          b = true;
      });
    return b;
  }

  isCharacterBasedLang(lang: string): boolean {
    if (this.charBasedLanguages)
      return this.charBasedLanguages.indexOf(lang) >= 0;
    return false;
  }

  getCharBasedLangs(langs: string[]) {
    let charBased = [];
    if (langs != undefined)
      langs.forEach(l => {
        if (this.isCharacterBasedLang(l))
          charBased.push(l);
      });
    return charBased;
  }

  isMotherTongue(lang: string): boolean {
    let isMotherTongue: boolean = false;
    if (this.user.language != undefined) {
      isMotherTongue = this.user.language == lang;
      if (!isMotherTongue)
      // If no match, try and split off the dialect from the given language and try again
        isMotherTongue = lang.split("-")[0] == this.user.language;
    }
    return isMotherTongue;
  }

  isValidSelectionForCompetence() {
    return this.activity != null &&
      (this.dependencies.indexOf(DEPENDENCY_SOURCE_LANG) < 0 || this.source != null && this.source.length > 0) &&
      (this.dependencies.indexOf(DEPENDENCY_TARGET_LANG) < 0 || this.target != null);
  }

  removeCompetence(competence: any) {
    if (this.user.competences != undefined) {
      const index = this.user.competences.indexOf(competence, 0);
      if (index > -1) {
        // Explicitly making a new array of competences so the ngOnChanges for the competence-list is triggered
        let current = [...this.user.competences];
        current.splice(index, 1);
        this.user.competences = current;
      }
      this.checkSworn();
      this.showSave();
    }
  }

  checkSworn() {
    this.hasSworn = this.user.competences.filter(c => c.activity.toLowerCase() === ACTIVITY_SWORN_TRANSLATION.toLowerCase()).length > 0;
  }

  showSave() {
    // console.debug("current user competences", this.user.competences);
    this.isShowSave = true;
  }

  getSaveObservables(): Observable<any> {
    if (this.activity != null && this.source != null && this.target != null && this.price != null) {
      this.addCompetence()
    }
    let swornPricingObs: Observable<any> = Observable.empty();
    if (this.hasSworn) {
      this.user.swornSurplus = this.swornSurplus;
      this.user.postalSurplus = this.postalSurplus;
      swornPricingObs = this.userService.updateSwornPricing(this.user)
    }
    return this.userService.updatePercentageSet(this.user)
      .pipe(
        merge(this.userService.updateCompetences(this.user)),
        merge(swornPricingObs)
      )
  }

  save() {
    this.getSaveObservables().subscribe(r =>
      this.snackbar.open("Competences updated", '', {
        duration: 2000
      })
    );
  }

  private initPercentageSet(): any {
    let percentageSet = {};
    this.statisticsService.getAllCategories().pipe(
      mergeMap(v => v),
      map(c => c as any),)
      .subscribe(c =>
        percentageSet[c.name.toLowerCase()] = getDefaultPercentage(c.name));
    return percentageSet;
  }

  handleDomainEvent(event) {
    this.showSave();
  }

  competencePriceChange(comp: any) {
    let idx = this.user.competences.indexOf(comp.competence);
    if (idx >= 0 && comp.newprice != undefined) {
      this.user.competences[idx].price = comp.newprice;
      this.showSave();
    }
  }

  competenceSelectionChange(selection: any) {
    this.selectedCompetences = selection;
  }

  removeSelectedCompetences() {
    if (this.selectedCompetences != undefined) {
      this.selectedCompetences.forEach(c => this.removeCompetence(c));
      // Reset selection
      this.selectedCompetences = undefined;
    }
  }

  openHelpDialog() {
    // TODO: find a better way to handle this (maybe with a separate HTML file?)
    // TODO: CSS classes don't seem to work (which is why all styles are inlined here)
    let pricingHelpHtml = `
        <table><thead>
            <tr>
                <th style="border: 1px solid lightgray; border-top-width: 0; border-left-width: 0;">Category</th>
                <th style="border: 1px solid lightgray; border-top-width: 0;">Category description</th>
                <th style="border: 1px solid lightgray; border-top-width: 0; border-right-width: 0;">Default percentage</th>
            </tr>
            </thead><tbody>
            <tr>
                <td style="border: 1px solid lightgray; border-left-width: 0;">Context match</td>
                <td style="border: 1px solid lightgray;">
                    A <i>100% match</i><sup style="border-bottom: 1px dotted lightgray;" title="See next category">*</sup>, for which both the
                    preceding and following sentences are also a <i>100% or context match</i> from the same originating
                    document.</td>
                <td style="border: 1px solid lightgray; border-right-width: 0; text-align: right;">0%</td>
            </tr>
            <tr>
                <td style="border: 1px solid lightgray; border-left-width: 0;">100% match</td>
                <td style="border: 1px solid lightgray;">A source segment for which an identical segment has been found in the customer's translation memory or in the same document.</td>
                <td style="border: 1px solid lightgray; border-right-width: 0; text-align: right;">10%</td>
            </tr>
            <tr>
                <td style="border: 1px solid lightgray; border-left-width: 0;">99% match</td>
                <td style="border: 1px solid lightgray;">A <i>100% match</i>, except for white spaces and capitalization.</td>
                <td style="border: 1px solid lightgray; border-right-width: 0; text-align: right;">30%</td>
            </tr>
            <tr>
                <td style="border: 1px solid lightgray; border-left-width: 0;">Fuzzy match</td>
                <td style="border: 1px solid lightgray;">A source segment for which a similar segment has been found in the customer's
                translation memory or in the same document, with a similarity score higher than 75%, but lower than 99%.</td>
                <td style="border: 1px solid lightgray; border-right-width: 0; text-align: right;">50%</td>
            </tr>
            <tr>
                <td style="border: 1px solid lightgray; border-left-width: 0;">MT match</td>
                <td style="border: 1px solid lightgray;">A <i>no match</i><sup style="border-bottom: 1px dotted lightgray;"  title="See next category">*</sup> for which a machine translation proposal has been generated.</td>
                <td style="border: 1px solid lightgray; border-right-width: 0; text-align: right;">85%</td>
             </tr>
            <tr>
                <td style="border: 1px solid lightgray; border-bottom-width: 0; border-left-width: 0;">No match</td>
                <td style="border: 1px solid lightgray; border-bottom-width: 0;">A source segment for which no <i>100% match</i> or <i>fuzzy match</i> has been found.</td>
                <td style="border: 1px solid lightgray; border-bottom-width: 0; border-right-width: 0; text-align: right;">100%</td>
            </tr>
        </tbody></table>`;
    let dialogRef = this.dialog.open(HelpDialogComponent, {
      width: '700px',
      height: '500px',
      data: {helpTitle: "Pricing categories & percentages", helpHtmlContent: pricingHelpHtml}
    });
  }
}
