import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {ProjectService} from '../../service/project.service';


@Component({
  selector: 'source-file-list',
  templateUrl: './source-file-list.component.html',
  styleUrls: ['../project.component.css'],
})
export class SourceFileListComponent implements OnInit {

  @Input()
  files: any[];
  @Input()
  pId: string;
  @Input()
  expectedLanguage: string;
  @Input()
  service: string;
  @Input()
  allowRemoval = true;

  @Output()
  onFileRemove = new EventEmitter<any>();
  @Output()
  onDocFileAdded = new EventEmitter<any>();
  @Output()
  onFileMetaUpdate = new EventEmitter<any>();


  constructor(private projectService: ProjectService) {

  }

  ngOnInit() {

  }

  setFileLanguage(filename: string, language: string) {
    this.files
      .filter(f => f.name === filename)
      .forEach(f => {
        if (f.sourcelang === undefined) {
          f.sourcelang = language;
          f.detectedsourcelang = language;
        }
      });
  }

  removeFile(file: File) {
    if (this.allowRemoval) {
      this.files.splice(this.files.indexOf(file), 1);
      this.onFileRemove.emit(file);
    }
  }

  docFileUploaded(docFileData: any) {
    this.onDocFileAdded.emit(docFileData);
  }

  fileDataUpdated(fileData: any) {
    this.onFileMetaUpdate.emit(fileData);
  }
}
