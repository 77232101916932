import {Component, Input, OnInit} from "@angular/core";
import {ProjectService} from "../../service/project.service";
import {Project} from "../../dto/project";
import {FileService} from "../../service/file.service";


@Component({
  selector: 'target-file-list',
  templateUrl: './target-file-list.component.html',
  styleUrls: ['../project.component.css'],
})
export class TargetFileListComponent implements OnInit {

  @Input()
  project: Project;
  isTileView: boolean = true;

  constructor(private projectService: ProjectService,
              private fileService: FileService,) {

  }

  ngOnInit(): void {

  }

  getLangPairs() {
    return this.project.langpairs;
  }

  toggleView() {
    this.isTileView = !this.isTileView
  }

  setTileView(tileView: boolean) {
    this.isTileView = tileView;
  }
}
