/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../project.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../util/frontpage.component.ngfactory";
import * as i3 from "../../util/frontpage.component";
import * as i4 from "../../service/file.service";
import * as i5 from "../../util/filename.pipe";
import * as i6 from "@angular/common";
import * as i7 from "./target-file.component";
var styles_TargetFileComponent = [i0.styles];
var RenderType_TargetFileComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TargetFileComponent, data: {} });
export { RenderType_TargetFileComponent as RenderType_TargetFileComponent };
function View_TargetFileComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "targetFileCard"]], [[8, "title", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "targetFileImage"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "targetFileImageWrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "frontpage", [["class", "targetFileFrontpage"]], null, null, null, i2.View_FrontpageComponent_0, i2.RenderType_FrontpageComponent)), i1.ɵdid(4, 245760, null, 0, i3.FrontpageComponent, [i4.FileService], { fileName: [0, "fileName"], pId: [1, "pId"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 6, "div", [["class", "targetFileDetails"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "h6", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), i1.ɵppd(8, 3), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "targetFileDownload"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "img", [["alt", "download file"], ["class", "targetFileDownloadColoured"], ["src", "../../../assets/icon_download_coloured.svg"], ["title", "download"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "img", [["alt", "download file"], ["class", "targetFileDownloadWhite"], ["src", "../../../assets/download_icon.svg"], ["title", "download"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.file.name; var currVal_2 = _co.pId; _ck(_v, 4, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.file.name; _ck(_v, 0, 0, currVal_0); var currVal_3 = i1.ɵunv(_v, 7, 0, _ck(_v, 8, 0, i1.ɵnov(_v.parent, 0), _co.file.name, 16, true)); _ck(_v, 7, 0, currVal_3); }); }
export function View_TargetFileComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i5.FilenamePipe, []), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TargetFileComponent_1)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.file != undefined); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_TargetFileComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "target-file", [], null, null, null, View_TargetFileComponent_0, RenderType_TargetFileComponent)), i1.ɵdid(1, 114688, null, 0, i7.TargetFileComponent, [i4.FileService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TargetFileComponentNgFactory = i1.ɵccf("target-file", i7.TargetFileComponent, View_TargetFileComponent_Host_0, { file: "file", pId: "pId", langPair: "langPair" }, {}, []);
export { TargetFileComponentNgFactory as TargetFileComponentNgFactory };
