<div class="competencePage">
  <div>
    <h1>Competences</h1>
    <div>
      <h3>Your areas of domain expertise</h3>
      <div *ngIf="user">
        <domain [domains]="user.domains" (domainEmitter)="handleDomainEvent($event)"></domain>
      </div>
    </div>
    <div>
      <h3>Language pairs and pricing</h3>
      <div *ngIf="languages && dialectLanguages && user">
        <div *ngIf="user.competences">
          <competence-list [competences]="user.competences" style="max-width: 95%;"
                           (onPriceChange)="competencePriceChange($event)"
                           (onSelectionChange)="competenceSelectionChange($event)"></competence-list>
          <div style="text-align: right;">
            <button [disabled]="!(selectedCompetences != undefined && selectedCompetences.length > 0)"
                    (click)="removeSelectedCompetences()">Remove selected
            </button>
          </div>
        </div>
        <div class="row addActivity" *ngIf="activities != null && dependencies != null">
          <div>
            <label for="activity">Task type</label>
            <select id="activity" [(ngModel)]="activity" (change)="onActivitySelect($event)">
              <option *ngFor="let activity of activities" [ngValue]="activity">{{activity}}</option>
            </select>
          </div>
          <div *ngIf="dependencies.indexOf('Source language') > -1">
            <label for="source">Source language</label>
            <select multiple id="source" [(ngModel)]="source" size="5" style="min-width: 270px;">
              <option *ngFor="let language of sourceLanguages" [ngValue]="language">
                {{language|languagePipe|async}}
                <span *ngIf="isMotherTongue(language)" style="color: var(--dark-gray); font-size: smaller;">&nbsp;&nbsp;[your mother tongue]</span>
              </option>
            </select>
            <div style="width: 100%; color: var(--dark-gray); display: flex; justify-content: space-between;">
              <span style="font-size: smaller;">Use CTRL + click for multi-select.</span>
              <span *ngIf="source.length > 0" style="font-size: smaller;">{{source.length}} selected.</span>
            </div>
          </div>
          <div *ngIf="dependencies.indexOf('Target language') > -1">
            <label for="target">Target language</label>
            <select id="target" [(ngModel)]="target" style="min-width: 270px;">
              <option *ngFor="let language of targetLanguages" [ngValue]="language">
                {{language|languagePipe|async}}
                <span *ngIf="isMotherTongue(language)" style="color: var(--dark-gray); font-size: smaller;">&nbsp;&nbsp;[your mother tongue]</span>
              </option>
            </select>
          </div>
          <div>
            <label for="wordprice">Price per
              <span *ngIf="source.length > 0 && getCharBasedLangs(source).length == source.length">character</span>
              <span *ngIf="getCharBasedLangs(source).length == 0">word</span>
              <span
                *ngIf="source.length > 0 && getCharBasedLangs(source).length > 0 && getCharBasedLangs(source).length != source.length">unit</span>
              (EUR)</label>
            <input id="wordprice" type="number" step="0.001" [(ngModel)]="price"/>
          </div>
          <div style="padding-top: 28px;">
            <button (click)="addCompetence()" class="btnSmall btnAddCompetence"
                    [disabled]="!isValidSelectionForCompetence()"
                    title="{{isValidSelectionForCompetence()? 'Add competence':'Select a task type and at least one source language to add a competence.'}}">
              <img src="../../assets/add.png" style="width: 20px; height: 20px; margin-right: 2px;" alt="Add">Add
            </button>
          </div>
        </div>
        <div class="row" *ngIf="isOneCharacterBasedLang(source)" style="padding-top: 10px;">
          <p *ngFor="let csl of getCharBasedLangs(source)" style="color: orange;">The price specified
            for {{csl|languagePipe|async}} is <span style="font-weight: bold;">per character</span>, due to the specific
            script typically used for this language.</p>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="hasSworn" class="swornSurplus">
    <h3>Sworn translation surplus</h3>
    <div>
      <p>You have one or more competences that indicate you offer sworn translations.</p>
      <p>Please specify the surplus you charge for this service:</p>
      <label for="swornSurplus">Sworn translation surplus (EUR)</label>
      <input id="swornSurplus" type='number' [(ngModel)]='swornSurplus' (change)="showSave()"/>
    </div>
    <br/>
    <div>
      <p>Please specify your postal costs for shipping to the different regions:</p>
      <div class="addPostalCost">
        <div>
          <label for="nationalPostalCost">Same country delivery (EUR)</label>
          <input id="nationalPostalCost" type='number' [(ngModel)]='postalSurplus.local' (change)="showSave()"/><br/>
        </div>
        <div>
          <label for="euPostalCost">EU delivery (EUR)</label>
          <input id="euPostalCost" type='number' [(ngModel)]='postalSurplus.eu' (change)="showSave()"/><br/>
        </div>
        <div>
          <label for="globalPostalCost">Worldwide delivery (EUR)</label>
          <input id="globalPostalCost" type='number' [(ngModel)]='postalSurplus.global' (change)="showSave()"/><br/>
        </div>
      </div>
    </div>
  </div>
  <div>
    <h3 style="flex: 0 1 auto; padding-right: 10px;">Your pricing percentages
      <button class="btnHelp" style="flex: 0 1 auto; margin-right: 40px;"
              (click)="openHelpDialog()">
        <img src="../../assets/icons8-help-32.png" class="imgIcon" alt="Help"/>
      </button>
    </h3>
    <div *ngIf="user && user.percentageSet">
      <label style="text-align: left; color: var(--main-color);">
        Note that these percentages are not applied as rebates, but reflect the amount of the price you get paid per
        word in these categories.<br/>
        A percentage of 100 means you will receive the full price per word in this category, whereas a percentage of 10
        means you only receive 10% of your word price.
      </label>
      <div style="display: flex; flex-wrap: wrap;">
        <div class="row percentage" *ngFor="let cat of categories">
          <label for={{cat.name}}>{{cat.desc}}</label>
          <div style="display: flex; justify-content: flex-start; align-items: center;">
            <input class="percentage" style="margin-left: 0px;" type="number" min="0" max="100"
                   id={{cat.name}}
                   (change)="showSave()"
                   [(ngModel)]="user.percentageSet[cat.name]"/>
            <span>%</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <button (click)="save()" [disabled]="!isShowSave"
            style="margin-top: 25px; margin-left: 0px;">Save
    </button>
  </div>
</div>
