<div>
  <div style="display: flex; justify-content: space-between;">
    <div *ngIf="filename" style="flex: 1 1 auto; height: 10%;">
      <h2>{{filename}}</h2>
    </div>
    <div class="editorButtons">
      <button class="editorButton" title="Complete & send file"
              (click)="saveFinalCheckFile()"
      ><img src="../../assets/checkmark_white.png">Finalize
      </button>
    </div>
  </div>
  <editor *ngIf="fileLoc"
          [sourceLang]="source" [targetLang]="target"
          [fileLocation]="fileLoc" [fileGroupId]="fileGroupId" [projectId]="pId" [fileName]="filename"
          [editable]="true"
          (changeEmitter)="change($event)"
          #editor></editor>
  <div style="display: flex">
    <div style="width: 90%"></div>
    <div class="editorButtons">
      <button class="editorButton" title="Complete & send file"
              (click)="saveFinalCheckFile()"
      ><img src="../../assets/checkmark_white.png">Finalize
      </button>
    </div>
  </div>
</div>
