import {Component, EventEmitter, Input, Output, ViewChild} from "@angular/core";
import {LangSelectComponent} from "../../util/lang-select.component";
import { ServiceParameters } from '../../mock-activities';


@Component({
  selector: 'param-select',
  template: `
    <div style="display: flex; align-items: flex-start;">
      <div style="display: flex;" *ngIf="selectedParameters.getService()==='Translation'">
        <div class="langpairSelect">
          <h3>from </h3>
          <lang-select [single]="true" [selectedLangs]="selectedParameters['sourceLanguage']" [dialect]="false"
                (eventEmitter)="onSourceLanguageUpdate($event)" #sourcelangselect></lang-select>
          <h3> to </h3>
        </div>
        <lang-select [selectedLangs]="selectedParameters['targetLanguages']" (eventEmitter)="onTargetLanguageUpdate($event)"
              #targetlangselect></lang-select>
      </div>
      <div style="display: flex;" *ngIf="selectedParameters.getService()==='Editing'">
        <div class="langpairSelect">
          <h3>Language </h3>
          <lang-select [single]="true" [selectedLangs]="selectedParameters['sourceLanguage']" [dialect]="true"
                (eventEmitter)="onSourceLanguageUpdate($event)" #sourcelangselect></lang-select>
        </div>
      </div>
		<button *ngIf="hasChanges"
				[disabled]="!selectedParameters.isValid()"
				class="nextButton" (click)="emitParameters()">Next
		</button>
	  </div>`,
  styleUrls: ['../project.component.css'],
})
export class LangpairSelectComponent {

  @Input()
  selectedParameters: ServiceParameters;

  @Output()
  eventEmitter = new EventEmitter<ServiceParameters>();
  @Output()
  changeEmitter = new EventEmitter<any>();

  @ViewChild("sourcelangselect", {static: false})
  sourceLangSelect: LangSelectComponent;
  @ViewChild("targetlangselect", {static: false})
  targetLangSelect: LangSelectComponent;

  hasChanges = false;


  ngOnInit() {
    // Already show the next button (it'll be disabled) if there are no target languages
    // That way it appears during project create, which seems natural
    // this.hasChanges = this.targetLanguages == null || this.targetLanguages.length == 0;
    console.debug(JSON.stringify(this.selectedParameters));
  }

  emitParameters() {
    this.hasChanges = false;
    this.eventEmitter.emit(this.selectedParameters);
    if (this.targetLangSelect != null) {
      this.targetLangSelect.closeLanguageChooser();
    }
    if (this.sourceLangSelect != null) {
      this.sourceLangSelect.closeLanguageChooser();
    }
  }

  onSourceLanguageUpdate(event: any) {
    event.source = true;
    this.onLanguageUpdate(event)
  }

  onTargetLanguageUpdate(event: any) {
    event.target = true;
    this.onLanguageUpdate(event)
  }

  onLanguageUpdate(event: any) {
    this.hasChanges = true;
    this.changeEmitter.emit(event);
  }
}
