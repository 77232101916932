/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./chat.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "./new.message.component";
var styles_NewMessageComponent = [i0.styles];
var RenderType_NewMessageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NewMessageComponent, data: {} });
export { RenderType_NewMessageComponent as RenderType_NewMessageComponent };
export function View_NewMessageComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { newMessageTxtArea: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "newmessage"], ["style", "display: flex;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, [[1, 0], ["newMessageArea", 1]], null, 5, "textarea", [], [[8, "placeholder", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "keydown"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 3)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 3)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.message = $event) !== false);
        ad = (pd_4 && ad);
    } if (("keydown" === en)) {
        var pd_5 = (_co.processInput($event, i1.ɵnov(_v, 2).selectionStart) !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 16384, null, 0, i2.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DefaultValueAccessor]), i1.ɵdid(5, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(7, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.sendMessage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "img", [["src", "../../assets/icon_upload.svg"], ["style", "vertical-align: middle"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_8 = _co.message; _ck(_v, 5, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.placeholderText, ""); var currVal_1 = i1.ɵnov(_v, 7).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 7).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 7).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 7).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 7).ngClassValid; var currVal_6 = i1.ɵnov(_v, 7).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 7).ngClassPending; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
export function View_NewMessageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "new-message", [], null, null, null, View_NewMessageComponent_0, RenderType_NewMessageComponent)), i1.ɵdid(1, 114688, null, 0, i3.NewMessageComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NewMessageComponentNgFactory = i1.ɵccf("new-message", i3.NewMessageComponent, View_NewMessageComponent_Host_0, { placeholderText: "placeholderText", user: "user" }, { sendEmitter: "sendEmitter" }, []);
export { NewMessageComponentNgFactory as NewMessageComponentNgFactory };
