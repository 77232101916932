import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from "@angular/core";
import {ProjectService} from "../../service/project.service";
import {Project} from "../../dto/project";
import {UserService} from '../../service/user.service';


@Component({
  selector: 'project-info-create',
  templateUrl: './project-info-create.component.html',
  styleUrls: ['../project.component.css'],
})
export class ProjectInfoCreateComponent implements OnChanges {

  @Input()
  project: Project;
  @Input()
  proposedDueDate: Date = undefined;
  @Input()
  mtValid = false;
  @Input()
  defaultShares = undefined;
  @Output()
  onAllowMtChanged = new EventEmitter<boolean>();
  @Output()
  onSaveSharesChanged = new EventEmitter<boolean>();
  @Output()
  eventEmitter = new EventEmitter<any>();

  duedate: Date;
  isDueDateBeforeProposal = false;
  minDueDate = new Date();
  allowMT = true;
  projectShares: any[];
  saveSharesAsDefault = false;
  defaultSharesChanged = false;

  constructor(private projectService: ProjectService,
              public userService: UserService) {

  }

  ngOnInit() {
    this.minDueDate = new Date();
    this.minDueDate.setUTCHours(0, 0, 0, 0);
    if (this.project.duedate != undefined) {
      this.duedate = this.project.duedate;
    } else {
      this.duedate = this.proposedDueDate;
    }
    if (this.project['mtEnabled'] != undefined)
      this.allowMT = this.project['mtEnabled'];
    else this.allowMT = this.mtValid;
    // Immediately indicate the initial value of the 'allowMT' flag to the parent page
    this.onAllowMtChanged.emit(this.allowMT);
    if (this.project.shares === undefined)
      this.projectShares = (this.defaultShares !== undefined ? this.defaultShares : []);
    else this.projectShares = this.project.shares;
    this.defaultSharesChanged = this.checkDefaultSharesChanged();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty("proposedDueDate")) {
      if (this.project.duedate === undefined) {
        this.duedate = this.proposedDueDate;
      }
      this.checkDueDate()
    } else if (changes.hasOwnProperty("defaultShares")) {
      this.defaultShares = changes.defaultShares.currentValue;
      if (this.project.shares === undefined && this.defaultShares !== undefined)
        this.projectShares = this.defaultShares;
      this.defaultSharesChanged = this.checkDefaultSharesChanged();
    } else if (changes.hasOwnProperty("mtValid")) {
      this.setMtValid(changes.mtValid.currentValue);
    }
  }

  storeProjectInfo() {
    this.setTitle();
    this.setProjectDueDate();
    this.saveCurrentProjectShares();
    this.eventEmitter.emit("STORE");
  }

  setTitle() {
    this.projectService.setTitle(this.project).subscribe();
  }

  checkDueDate() {
    let compareDate = new Date(this.proposedDueDate);
    //Some extra margin to avoid the warning for popping up because of seconds difference
    compareDate.setUTCMinutes(compareDate.getUTCMinutes() - 5);
    this.isDueDateBeforeProposal = (this.project.duedate != undefined && this.project.duedate <= compareDate);
  }

  setProjectDueDateToProposed() {
    this.duedate = new Date(this.proposedDueDate);
    this.setProjectDueDate();
  }

  setProjectDueDate() {
    this.project.duedate = this.duedate;
    this.checkDueDate();
    if (this.project.duedate == undefined)
      this.project.duedate = new Date(this.proposedDueDate);
    this.projectService.setDueDate(this.project).subscribe();
  }

  allowMTChanged() {
    this.projectService.setMtEnabledForProject(this.project.id, this.allowMT).subscribe(v => {
      this.onAllowMtChanged.emit(this.allowMT);
    })
  }

  setMtValid(mtValid: boolean) {
    this.mtValid = mtValid;
    // If no MT value set on project, then use the validity as default value for the 'mt allowed" flag (if it is
    // different from the current value, at least)
    if (this.project['mtEnabled'] === undefined && this.mtValid !== this.allowMT) {
      this.allowMT = this.mtValid;
      this.onAllowMtChanged.emit(this.allowMT);
    }
  }

  saveProjectShares(selectedColleagues: any[]) {
    this.projectShares = selectedColleagues;
    this.defaultSharesChanged = this.checkDefaultSharesChanged();
    this.saveCurrentProjectShares();
  }

  private saveCurrentProjectShares() {
    this.projectService.setShares(this.project.id, this.projectShares).subscribe(
      v => {
      },
      error => console.error("Error saving project shares", error));
  }

  saveSharesChanged() {
    this.onSaveSharesChanged.emit(this.saveSharesAsDefault);
  }

  private checkDefaultSharesChanged(): boolean {
    // Check whether the user's default shares are different from the project's shares
    // If so, we can ask them whether they want to save this current selection as their default
    if (this.defaultShares === undefined || this.defaultShares === null ||
      this.defaultShares.length === 0) {
      return !(this.projectShares === undefined || this.projectShares === null || this.projectShares.length === 0)
    } else if (this.projectShares === undefined || this.projectShares === null ||
      this.projectShares.length === 0) {
      return true;
    } else {
      if (this.defaultShares.length === this.projectShares.length) {
        // Check whether each default occurs in the project shares as well
        this.defaultShares.forEach(ds => {
          let found = false;
          this.projectShares.forEach(ps => {
            if (ps.id === ds.id) found = true;
          });
          if (!found) return true;
        });
        return false;
      } else return true;
    }
  }
}
