/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./folder-viewer.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "./folder-view.component.ngfactory";
import * as i4 from "./folder-view.component";
import * as i5 from "../../service/user.service";
import * as i6 from "../../service/file.service";
import * as i7 from "@angular/material/dialog";
import * as i8 from "@angular/material/snack-bar";
import * as i9 from "@angular/common";
import * as i10 from "./folder-viewer.component";
import * as i11 from "@angular/router";
var styles_FolderViewerComponent = [i0.styles];
var RenderType_FolderViewerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FolderViewerComponent, data: {} });
export { RenderType_FolderViewerComponent as RenderType_FolderViewerComponent };
function View_FolderViewerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" You do not have the correct privileges to access this page.\n"]))], null, null); }
function View_FolderViewerComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "option", [], null, null, null, null, null)), i1.ɵdid(1, 147456, null, 0, i2.NgSelectOption, [i1.ElementRef, i1.Renderer2, [2, i2.SelectControlValueAccessor]], { value: [0, "value"] }, null), i1.ɵdid(2, 147456, null, 0, i2.ɵangular_packages_forms_forms_y, [i1.ElementRef, i1.Renderer2, [8, null]], { value: [0, "value"] }, null), (_l()(), i1.ɵted(3, null, ["", ""]))], function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit, ""); _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit, ""); _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit; _ck(_v, 3, 0, currVal_2); }); }
function View_FolderViewerComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "folderview", [], null, null, null, i3.View_FolderViewComponent_0, i3.RenderType_FolderViewComponent)), i1.ɵdid(1, 4833280, null, 0, i4.FolderViewComponent, [i5.UserService, i6.FileService, i7.MatDialog, i8.MatSnackBar], { type: [0, "type"], id: [1, "id"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.entitytype2view, ""); var currVal_1 = i1.ɵinlineInterpolate(1, "", _co.entityid2view, ""); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_FolderViewerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 23, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 19, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Pick an 'entity type' (project, company or user) and specify the ID in the text field. Press 'view' to view the folder contents."])), (_l()(), i1.ɵeld(4, 0, null, null, 16, "div", [["style", "display: flex; align-items: center;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 7, "select", [], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "change"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onChange($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 6).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("ngModelChange" === en)) {
        var pd_2 = ((_co.entitytype = $event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 16384, null, 0, i2.SelectControlValueAccessor, [i1.Renderer2, i1.ElementRef], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.SelectControlValueAccessor]), i1.ɵdid(8, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(10, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FolderViewerComponent_3)), i1.ɵdid(12, 278528, null, 0, i9.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(13, 0, null, null, 5, "input", [["id", "entityid"], ["name", "entityid"], ["style", "width:300px;"], ["type", "text"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 14)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 14).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 14)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 14)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.entityid = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(14, 16384, null, 0, i2.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DefaultValueAccessor]), i1.ɵdid(16, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(18, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i1.ɵeld(19, 0, null, null, 1, "button", [["style", "min-height: 30px; width: 100px;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openView() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["View"])), (_l()(), i1.ɵeld(21, 0, null, null, 0, "hr", [["style", "margin-bottom: 10px; margin-top: 15px;"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FolderViewerComponent_4)), i1.ɵdid(23, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.entitytype; _ck(_v, 8, 0, currVal_7); var currVal_8 = _co.entitytypes; _ck(_v, 12, 0, currVal_8); var currVal_16 = "entityid"; var currVal_17 = _co.entityid; _ck(_v, 16, 0, currVal_16, currVal_17); var currVal_18 = _co.entityid2view; _ck(_v, 23, 0, currVal_18); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 10).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 10).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 10).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 10).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 10).ngClassValid; var currVal_5 = i1.ɵnov(_v, 10).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 10).ngClassPending; _ck(_v, 5, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_9 = i1.ɵnov(_v, 18).ngClassUntouched; var currVal_10 = i1.ɵnov(_v, 18).ngClassTouched; var currVal_11 = i1.ɵnov(_v, 18).ngClassPristine; var currVal_12 = i1.ɵnov(_v, 18).ngClassDirty; var currVal_13 = i1.ɵnov(_v, 18).ngClassValid; var currVal_14 = i1.ɵnov(_v, 18).ngClassInvalid; var currVal_15 = i1.ɵnov(_v, 18).ngClassPending; _ck(_v, 13, 0, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15); }); }
export function View_FolderViewerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Folder view"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FolderViewerComponent_1)), i1.ɵdid(3, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FolderViewerComponent_2)), i1.ɵdid(5, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.userService.isCurrentUserAdmin; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.userService.isCurrentUserAdmin; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_FolderViewerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "folderviewer", [], null, null, null, View_FolderViewerComponent_0, RenderType_FolderViewerComponent)), i1.ɵdid(1, 114688, null, 0, i10.FolderViewerComponent, [i11.ActivatedRoute, i5.UserService, i6.FileService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FolderViewerComponentNgFactory = i1.ɵccf("folderviewer", i10.FolderViewerComponent, View_FolderViewerComponent_Host_0, {}, {}, []);
export { FolderViewerComponentNgFactory as FolderViewerComponentNgFactory };
