import {Component, Input} from "@angular/core";
import {UserService} from "../service/user.service";
import {HubspotService} from "../service/hubspot.service";
import {Router} from "@angular/router";


@Component({
  selector: 'integrations-settings',
  templateUrl: './integrations.setting.component.html',
  styleUrls:['./user.settings.component.css']
})
export class IntegrationsSettingComponent {

  @Input()
  user;

  hubspotTestSuccess=undefined;
  hubspotTestMessage=undefined;


  constructor(private userService: UserService,
              private hubspotService: HubspotService,
              private router: Router){

  }

  ngOnInit(){
    if(this.user.hubspot==undefined){
      this.user.hubspot={
        'hapiKey':undefined
      }
    }
  }

  testHubspot(){
    if(this.user.hubspot==undefined || this.user.hubspot.hapiKey==undefined){
      this.hubspotTestSuccess=false;
      this.hubspotTestMessage="No hapiKey filled in."
    }else{
      this.hubspotService.testHubspotConnection(this.user.hubspot.hapiKey)
        .subscribe(v => {
          if(v==="success") {
            this.hubspotTestSuccess=true;
            this.hubspotTestMessage="Key is successful";
          }else{
            this.hubspotTestSuccess=false;
            this.hubspotTestMessage="Key invalid";
          }
        })
    }
  }

  createHubspotProject(){
    this.router.navigate(["hubspotproject"])
  }


  save() {
    this.userService.updateUserData(this.user)
      .subscribe()
  }
}
