import {filter, map, mergeMap, publish} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {ConnectableObservable, Observable, Subject} from "rxjs";
import "rxjs";
import {Job, Task} from "../dto/job";
import {Vendor} from "../dto/vendor";
import {HttpClient} from "@angular/common/http";
import {IntervalObservable} from "rxjs/observable/IntervalObservable";
import {Project} from "../dto/project";
import {getBaseSocketUrl, getBaseUrl} from "../util/httpclient";
import { toLangPairString } from '../util/jobutil';


/**
 * Created by jefv on 2/11/2017.
 */
@Injectable()
export class TaskService {

  private TASK_API = getBaseUrl() + "/api/v1/tasks";
  private TASK_SOCKET_URL = getBaseSocketUrl() + "/tasks/feed";

  public messages: Observable<any>;
  private ws: Subject<any>;


  constructor(private http: HttpClient) {

  }

  initTaskFeed() {
    this.ws = Observable.webSocket(this.TASK_SOCKET_URL);
    //Keep the socket alive (every 30 seconds, server timeout is set to 5 minutes)
    IntervalObservable.create(30000).subscribe(o =>
      this.ws.next({'action': 'keepalive'})
    );
    this.messages = this.makeHot(this.ws).pipe(filter(m => m != null));
    // this.messages.subscribe(msg => console.log("> Received " + JSON.stringify(msg) + " @" + this.TASK_SOCKET_URL),
    //   error => console.error(error));
  }

  //For more info see analyses.service.ts
  makeHot<T>(cold: Observable<T>): Observable<T> {
    let obs = cold.pipe(publish()) as ConnectableObservable<T>;
    obs.connect();
    return obs;
  }

  enrollForTask(tId: string): Observable<any> {
    if (!this.ws || this.ws.closed)
      this.initTaskFeed();
    //To enroll you have to send a message to the socket with the action "enroll" and to which objects you want to enroll
    this.ws.next({'action': 'enroll', 'tid': tId});
    //The JSON structure of the message normally has the objects parameters to identify and a "data" object which contains the actual information
    //E.g. {"tid":"10", "data":{"action":"PDF", "langname":"damocles.docx"}}
    return this.messages.pipe(filter(msg => {
        return msg.tid == tId;
      }),
      map(msg => msg.data),)
  }

  enrollForProject(pId: string): Observable<any> {
    return this.enroll(pId)
      .map(msg => msg.data)
  }

  enrollForProjectAndLangPair(pId: string, langPair: string, enrollNoLangPairAlso: boolean = true): Observable<any> {
    // If the 'enrollNoLangPairAlso' flag is false, then ONLY events that have a language pair specified that is equal
    // to the given one will be returned; if the events have no language pair specified, they are ignored and will not be returned
    // If the 'enrollNoLangPairAlso' flag is true, then events that have a language pair equal to the given one OR
    // none at all are returned
    return this.enroll(pId).pipe(
      filter(msg => {
        return (msg.langpair === undefined && enrollNoLangPairAlso) || msg.langpair == langPair;
      }),
      map(msg => msg.data));
  }

  private enroll(pId: string): Observable<any> {
    if (!this.ws || this.ws.closed)
      this.initTaskFeed();
    this.ws.next({'action': 'enroll', 'pid': pId});
    return this.messages.filter(msg => {
      return msg.pid == pId;
    });
  }

  getUnacceptedTasks(): Observable<Task[]> {
    return this.http.get<Task[]>(this.TASK_API + "/unaccepted");
  }

  getAcceptedTasks(): Observable<Task[]> {
    return this.http.get<Task[]>(this.TASK_API + "/accepted")
  }

  getCompletedTasks(): Observable<Task[]> {
    return this.http.get<Task[]>(this.TASK_API + "/completed")
  }

  getInvoiceableTasks(): Observable<Task[]> {
    return this.http.get<Task[]>(this.TASK_API + "/invoiceable")
  }

  findTaskForInvoice(invoiceId): Observable<Task[]> {
    return this.http.get<Task[]>(this.TASK_API + "/findbyinvoice/" + invoiceId)
  }

  findTaskForProject(pId: string, sourceLang: string, targetLang: string) {
    let lp = toLangPairString({source: sourceLang, target: targetLang});
    return this.http.get(this.TASK_API + "/findbylp/" + pId + "/" + lp).pipe(
      map(t => t as any[]),
      map(t => t.filter(task => !task.canceldate)),);
  }

  getTaskInfoForProject(pId: any): Observable<Task> {
    return this.http.get(this.TASK_API + "/gettaskinfobyproject/" + pId).pipe(
      map(v => v as any[]),
      mergeMap(v => v),
      map(v => v || {}),)
  }

  getTaskCount(projectId: string): Observable<Number> {
    return this.http.get<Number>(this.TASK_API + "/countbyproject/" + projectId)
  }

  getTaskCountPerLangPair(projectId: string): Observable<any> {
    return this.http.get<any>(this.TASK_API + '/countperlp/' + projectId)
  }

  getTask(taskId: string): Observable<Task> {
    return this.http.get<Task>(this.TASK_API + "/id/" + taskId)
  }

  initTask(files: any[], service: string, source: string, target: string, tasktype: string, sequence: number,
           pId: string, vendor: Vendor): Task {
    let task = new Task();
    task.vendor = vendor.name;
    task.vendorId = vendor.id;
    task.jobs = [];
    task.service = service;
    task.activity = tasktype;
    task.sequence = sequence;
    task.basePrice = vendor.basePrice;
    task.percentageSet = vendor.percentageSet;
    task.sourcelanguage = source;
    task.targetlanguage = target;
    task.projectId = pId;
    files.filter(v => {
      return v.type === "SOURCE";
    })
      .map(f => {
        let job = new Job();
        job.file = f.name;
        job.source = source;
        job.target = target;
        return job;
      }).forEach(j => task.jobs.push(j));
    return task;
  }

  addTask(files: any[], service: string, source: string, target: string, tasktype: string, sequence: number, pId: string, vendor: Vendor): Observable<any> {
    let task = this.initTask(files, service, source, target, tasktype, sequence, pId, vendor);
    return this.insertTask(task);
  }

  insertTask(task: Task): Observable<Task> {
    return this.http.post(this.TASK_API + "/insert", JSON.stringify(task), {responseType: "json"})
      .map(a => <Task>a);
  }

  cancelTask(task: Task): Observable<any> {
    if (task.id != null)
      return this.http.post(this.TASK_API + "/cancel", task.id, {responseType: "text"});
    else {
      console.warn("WARNING: Task to cancel has no ID and can thus not be canceled", task);
    }
  }

  sendTask(task: Task): Observable<any> {
    return this.http.post(this.TASK_API + "/send", task.id, {responseType: "json"});
  }

  acceptTask(taskId: string, expectedDate: Date): Observable<Task> {
    let body = {id: taskId, expectedDate: expectedDate};
    return this.http.post<Task>(this.TASK_API + "/accept", body, {responseType: "json"});
  }

  rejectTask(taskId: string, reason: string, suggestedColleague): Observable<Task> {
    let colleagueId = undefined;
    if (suggestedColleague !== undefined)
      colleagueId = suggestedColleague.id;
    let body = {
      reason: reason,
      suggestedColleague: colleagueId
    };
    return this.http.post<Task>(this.TASK_API + "/reject/" + taskId, body, {responseType: "json"});
  }

  //This method is used to check if the task is waiting to be send by the trigger from the project start
  //If so it the update should be handled within two minutes, if not, something probably went wrong in the sending of the task.
  public isProbablySending(task: Task, project: Project): boolean {
    if (task.senddate != null)
      return false;
    let projectStartDate = undefined;
    let minutes = undefined;
    if (project.startdate != null) {
      projectStartDate = new Date(project.startdate);
      minutes = Math.round((((new Date().getTime() - projectStartDate.getTime()) % 86400000) % 3600000) / 60000);
    }
    return minutes != undefined && minutes < 2;
  }

  counterProposeTask(task: Task, counterProposal: any): Observable<any> {
    return this.http.post(this.TASK_API + "/counterpropose/" + task.id, counterProposal, {responseType: "text"})
  }

  acceptProposalTask(task): Observable<any> {
    return this.http.post(this.TASK_API + "/acceptproposal/" + task.id, "", {responseType: "text"})
  }

  availableJobs(task: Task) {
    return this.http.get(this.TASK_API + "/" + task.id + "/jobs/ready")
  }

  setEditorAccessDate(taskId: string, fileName: string) {
    return this.http.post(this.TASK_API + "/seteditoraccess/" + taskId, fileName, {responseType: "text"})
  }

  setJobDownloadDate(taskId: string, fileName: string) {
    return this.http.post(this.TASK_API + "/setjobdownload/" + taskId, fileName, {responseType: "text"})
  }

  hasNextTask(task: Task): Observable<boolean> {
    // /nexttask:pId/:langpair/:currentactivity/:currentsequence"
    return this.http.get<Task>(this.TASK_API + '/nexttask/' + task.projectId + '/'
      + task.sourcelanguage + '_' + task.targetlanguage + '/' + task.activity + '/' + task.sequence)
      .map(t => t != null)
  }
}
