import { OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
/**
 * When this handler is created it will replaces the existing "onDestroy" function with the handleOnDestroy-function
 * So, when OnDestroy is called by the lifecylcehook, handleOnDestroy is called
 * That method will call the original onDestroy of the component and unscubscribe all registered subscriptions
 */
export class SubscriptionHandler {

  constructor(private component: OnDestroy) {
    // The methods are stored on the original component because this component only has access to the lifecycle hook
    this.component['SubscriptionHandlerOrigOnDestroy'] = this.component.ngOnDestroy;
    this.component.ngOnDestroy = this.handleOnDestroy;
    this.component['SubscriptionsToHandle'] = []
  }


  private handleOnDestroy() {
    // call the original onDestroy function
    this['SubscriptionHandlerOrigOnDestroy']();
    // unsubscribe all the registered subscriptions
    this['SubscriptionsToHandle'].forEach(s => s.unsubscribe());
  }
  /**
   * Register a subscription
   * @param subscription subscription will be registered
   */
  push(subscription: Subscription) {
    this.component['SubscriptionsToHandle'].push(subscription)
  }
}
