import {filter, map, mergeMap, publish, tap} from 'rxjs/operators';
import {Component, OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {TaskService} from "../service/task.service";

@Component({
  selector: 'task-dashboard',
  templateUrl: './task-dashb.component.html',
  styleUrls: [ './task-dashb.component.css' ]         ,
    animations:[trigger('appearIn', [
      state('in', style({opacity: 1})),
          transition('void => *', [
            style({
              opacity: 0
            }),
            animate('0.5s ease-in')
          ])
      ])]
})

export class TaskDashboardComponent implements OnInit {
  unacceptedTask;
  hasUnacceptedTask;
  acceptedTask;
  hasAcceptedTask;
  completedTask;
  hasCompletedTask;

  constructor(private taskService: TaskService,
              private router:Router) {
  }

  ngOnInit(): void {
    this.unacceptedTask = this.taskService.getUnacceptedTasks()
      .pipe(
        tap(tasks => this.hasUnacceptedTask = tasks.length > 0),
        );
    this.acceptedTask = this.taskService.getAcceptedTasks()
    .pipe(
      tap(tasks => this.hasAcceptedTask = tasks.length > 0),
      );
    this.completedTask = this.taskService.getCompletedTasks()
    .pipe(
      tap(tasks => this.hasCompletedTask = tasks.length > 0),
      );
  }

  gotoTask(id:number){
    this.router.navigate(['/tdetail', id]);
  }
}
