<div style="position: relative" class="main-component">
	<div class="maintext" *ngIf="size!='small'">
		<h1>Hiring translation pros has never been easier</h1>
		<p>
      Pay less for better quality;<br/><br/>
      Connect directly with native translators;<br/><br/>
      Upload your file, watch lilo work its magic and get a free instant quote.
		</p>
	</div>
	<div class="backImage" *ngIf="size!='small'">
		<img src="../../assets/artwork/art.png"/>
	</div>
	<div class="fileupload">
		<pre-upload></pre-upload>
	</div>
</div>
