<h2 style="margin: 25px 0;">Customer groups</h2>

<div *ngIf="!userService.isCurrentUserAdmin">
  You do not have the correct privileges to access this page.
</div>

<div *ngIf="userService.isCurrentUserAdmin == true">

  <p style="margin: 5px 0 15px 0;">
    <span class="link-title">Format for invite-link for customer group:</span>&nbsp;
    <span class="link-format">{{host}}/join?grouptoken=&lt;token&gt;</span>
  </p>
  <div *ngIf="customerGroups !== null">
    <dynamic-table [tableData]="customerGroups" [tableHeaders]="headers" [clickable]="true"
                   (onClick)="groupSelected($event)"></dynamic-table>
  </div>
  <div class="create-new-group">
    <input type="text" placeholder="Enter name for new group"
           title="Enter name for new group"
           [(ngModel)]="newGroupName"/>
    <button [disabled]="!isNewButtonValid()"
            (click)="createNew()">Create
    </button>
  </div>
</div>
