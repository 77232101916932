/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./invoice.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./sales-invoice.component.ngfactory";
import * as i3 from "./sales-invoice.component";
import * as i4 from "@angular/common";
import * as i5 from "./purchase-invoice.component.ngfactory";
import * as i6 from "./purchase-invoice.component";
import * as i7 from "@angular/router";
import * as i8 from "@angular/material/snack-bar";
import * as i9 from "../service/task.service";
import * as i10 from "../service/invoice.service";
import * as i11 from "../service/file.service";
import * as i12 from "../service/user.service";
import * as i13 from "./invoice.component";
import * as i14 from "../service/login.service";
var styles_InvoiceComponent = [i0.styles];
var RenderType_InvoiceComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InvoiceComponent, data: {} });
export { RenderType_InvoiceComponent as RenderType_InvoiceComponent };
function View_InvoiceComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" You are not signed in."])), (_l()(), i1.ɵeld(2, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Please sign in to continue: "])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.loginService.openSignInPopup("") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Sign in"]))], null, null); }
function View_InvoiceComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "invoiceSection"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "sales-invoice", [], null, null, null, i2.View_SalesInvoiceComponent_0, i2.RenderType_SalesInvoiceComponent)), i1.ɵdid(2, 114688, null, 0, i3.SalesInvoiceComponent, [], { invoice: [0, "invoice"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "salesInvoiceDownload"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "button", [["class", "btnDownload"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.downloadInvoicePDF() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [["src", "../../assets/download_white.png"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Download "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.invoice; _ck(_v, 2, 0, currVal_0); }, null); }
function View_InvoiceComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Tasks can be invoiced from the moment they are completed."])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" To limit the administrative effort on both ends, we suggest to only send 1 invoice per month."]))], null, null); }
function View_InvoiceComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InvoiceComponent_5)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "purchase-invoice", [], null, null, null, i5.View_PurchaseInvoiceComponent_0, i5.RenderType_PurchaseInvoiceComponent)), i1.ɵdid(4, 638976, null, 0, i6.PurchaseInvoiceComponent, [i7.Router, i8.MatSnackBar, i9.TaskService, i10.InvoiceService, i11.FileService, i12.UserService], { invoice: [0, "invoice"], user: [1, "user"], company: [2, "company"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.invoiceId == null); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.invoice; var currVal_2 = _co.user; var currVal_3 = _co.company; _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3); }, null); }
function View_InvoiceComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["You can do so by going to your user profile, and selecting \""])), (_l()(), i1.ɵeld(2, 0, null, null, 2, "a", [["title", "Click here to be redirected to your company settings."]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 671744, null, 0, i7.RouterLinkWithHref, [i7.Router, i7.ActivatedRoute, i4.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Company"])), (_l()(), i1.ɵted(-1, null, ["\" from the right menu."]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = i1.ɵinlineInterpolate(1, "/user/", _co.user.id, "/company"); _ck(_v, 3, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).target; var currVal_1 = i1.ɵnov(_v, 3).href; _ck(_v, 2, 0, currVal_0, currVal_1); }); }
function View_InvoiceComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "p", [["style", "margin-top: 20px;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["You did not register your company details yet. "])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Please complete these before registering your invoices."])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InvoiceComponent_7)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.user; _ck(_v, 6, 0, currVal_0); }, null); }
function View_InvoiceComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "invoiceSection"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InvoiceComponent_4)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InvoiceComponent_6)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.company != null) || (_co.invoiceId != null)); _ck(_v, 2, 0, currVal_0); var currVal_1 = ((_co.company == null) && (_co.invoiceId == null)); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_InvoiceComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_InvoiceComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InvoiceComponent_2)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InvoiceComponent_3)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.user == undefined); _ck(_v, 1, 0, currVal_0); var currVal_1 = ((_co.invoice != null) && (_co.invoice.type === "SALES")); _ck(_v, 3, 0, currVal_1); var currVal_2 = ((_co.invoice != null) && (_co.invoice.type === "PURCHASE")); _ck(_v, 5, 0, currVal_2); }, null); }
export function View_InvoiceComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "invoice", [], null, null, null, View_InvoiceComponent_0, RenderType_InvoiceComponent)), i1.ɵdid(1, 114688, null, 0, i13.InvoiceComponent, [i7.ActivatedRoute, i10.InvoiceService, i12.UserService, i11.FileService, i14.LogInService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InvoiceComponentNgFactory = i1.ɵccf("invoice", i13.InvoiceComponent, View_InvoiceComponent_Host_0, {}, {}, []);
export { InvoiceComponentNgFactory as InvoiceComponentNgFactory };
