/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../user-profile.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../mugshot.component.ngfactory";
import * as i3 from "../mugshot.component";
import * as i4 from "../../service/user.service";
import * as i5 from "@angular/common";
import * as i6 from "./review.item.component";
import * as i7 from "../../service/review.service";
import * as i8 from "../../service/task.service";
var styles_ReviewItemComponent = [i0.styles];
var RenderType_ReviewItemComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ReviewItemComponent, data: {} });
export { RenderType_ReviewItemComponent as RenderType_ReviewItemComponent };
function View_ReviewItemComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["style", "display: flex; align-items: center;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "mugshot", [], null, null, null, i2.View_MugshotComponent_0, i2.RenderType_MugshotComponent)), i1.ɵdid(2, 638976, null, 0, i3.MugshotComponent, [i4.UserService], { user: [0, "user"], width: [1, "width"], height: [2, "height"], showIncomplete: [3, "showIncomplete"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["style", "margin-left: 20px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h5", [["style", "font-weight: bold;"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.poster; var currVal_1 = "80px"; var currVal_2 = "80px"; var currVal_3 = false; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.getPosterName(); _ck(_v, 5, 0, currVal_4); }); }
function View_ReviewItemComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["alt", "translator rating - 1*"], ["src", "../../../assets/star.svg"]], null, null, null, null, null))], null, null); }
function View_ReviewItemComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["alt", "translator rating - 2*"], ["src", "../../../assets/star.svg"]], null, null, null, null, null))], null, null); }
function View_ReviewItemComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["alt", "translator rating - 3*"], ["src", "../../../assets/star.svg"]], null, null, null, null, null))], null, null); }
function View_ReviewItemComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["alt", "translator rating - 4*"], ["src", "../../../assets/star.svg"]], null, null, null, null, null))], null, null); }
function View_ReviewItemComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["alt", "translator rating - 5*"], ["src", "../../../assets/star.svg"]], null, null, null, null, null))], null, null); }
function View_ReviewItemComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "reviewStars"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReviewItemComponent_4)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReviewItemComponent_5)), i1.ɵdid(4, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReviewItemComponent_6)), i1.ɵdid(6, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReviewItemComponent_7)), i1.ɵdid(8, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReviewItemComponent_8)), i1.ɵdid(10, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.starCount >= 1); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.starCount >= 2); _ck(_v, 4, 0, currVal_1); var currVal_2 = (_co.starCount >= 3); _ck(_v, 6, 0, currVal_2); var currVal_3 = (_co.starCount >= 4); _ck(_v, 8, 0, currVal_3); var currVal_4 = (_co.starCount >= 5); _ck(_v, 10, 0, currVal_4); }, null); }
function View_ReviewItemComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "reviewItem"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReviewItemComponent_2)), i1.ɵdid(3, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 5, "div", [["class", "reviewData"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReviewItemComponent_3)), i1.ɵdid(6, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "reviewComment"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), (_l()(), i1.ɵeld(10, 0, null, null, 2, "p", [["style", "font-size: 12px; float: right; margin-top: 7px;"]], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["Posted on ", ""])), i1.ɵppd(12, 2)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.poster; _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.review.scores && (_co.review.scores.length > 0)); _ck(_v, 6, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.review.comment; _ck(_v, 9, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 11, 0, _ck(_v, 12, 0, i1.ɵnov(_v.parent, 0), _co.review.creationdate, "y/MM/dd, HH:mm")); _ck(_v, 11, 0, currVal_3); }); }
export function View_ReviewItemComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i5.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReviewItemComponent_1)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.review; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ReviewItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "review-item", [], null, null, null, View_ReviewItemComponent_0, RenderType_ReviewItemComponent)), i1.ɵdid(1, 638976, null, 0, i6.ReviewItemComponent, [i7.ReviewService, i8.TaskService, i4.UserService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ReviewItemComponentNgFactory = i1.ɵccf("review-item", i6.ReviewItemComponent, View_ReviewItemComponent_Host_0, { index: "index", review: "review", currentUser: "currentUser" }, {}, []);
export { ReviewItemComponentNgFactory as ReviewItemComponentNgFactory };
