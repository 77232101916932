import {Component, Input} from "@angular/core";
import {ActivatedRoute, Params} from "@angular/router";
import {VendorService} from "../service/vendor.service";
import {existsAndNotEmpty} from "../util/apputil";
import {Vendor} from "../dto/vendor";
import {
  getUserName,
  isRevisionActivity,
  isSwornTranslationActivity,
  isTranslationActivity,
  sortActivities
} from 'app/util/jobutil';
import {forkJoin} from 'rxjs';
import {LanguageService} from "../service/language.service";
import {ReviewService} from "../service/review.service";


@Component({
  selector: 'user-profile',
  templateUrl: "./user-profile.component.html",
  styleUrls: ["./user-profile.component.css"],
})
export class UserProfileComponent {

  @Input() userId;
  user: Vendor;
  taskActivities: any[] = undefined;
  competenceGroups: CompetenceGroup[] = [];
  reviews;
  generalScore;


  constructor(private route: ActivatedRoute,
              private vendorService: VendorService,
              public languageService: LanguageService,
              private reviewService: ReviewService) {
  }

  ngOnInit() {
    if (!this.userId) {
      this.route.params
        .forEach((params: Params) => {
          if (params['id']) {
            this.userId = params['id'];
            this.setUser();
          }
        });
    } else this.setUser();
  }

  private setUser() {
    this.vendorService.getVendor(this.userId).subscribe(v => {
      this.user = v;
      // console.debug("user", this.user);
      this.initCompetenceData();
      this.setReviews(this.user.id);
    });
  }

  private initCompetenceData() {
    this.taskActivities = [...new Set(sortActivities(this.user.competences.map(c => c.activity)))];
    this.taskActivities.forEach(a => {
      let group = new CompetenceGroup();
      group.activity = a;
      this.initLangPairMap(group);
      this.competenceGroups.push(group);
    });
  }

  private initLangPairMap(group: CompetenceGroup) {
    // First, group by source language
    let temp = new Map<string, any[]>();
    this.user.competences.filter(c => c.activity === group.activity).forEach(c => {
      let t = temp.get(c.source);
      if (t === undefined) t = [];
      t.push(c.target);
      temp.set(c.source, t);
    });
    // Then retrieve the actual language name for each target language so we can properly sort them
    let langpairMap = new Map<string, any[]>();
    temp.forEach((tls, sl) => {
      let obs = [];
      tls.forEach((tl) => {
        obs.push(this.getLangNameObs(tl));
      });
      forkJoin(obs).subscribe(data => {
        langpairMap.set(sl, data.sort((t1: any, t2: any) => t1.name.localeCompare(t2.name)));
        group.targetLangMap = langpairMap;
        this.initSourceLangs(group);
      });
    });
    return langpairMap;
  }

  private initSourceLangs(group: CompetenceGroup): string[] {
    let sourceLangs = [];
    // Retrieve source language names and sort them
    let obs = [];
    group.targetLangMap.forEach((v, k) => {
      obs.push(this.getLangNameObs(k));
    });
    forkJoin(obs).subscribe(data => {
      sourceLangs = data.sort((s1: any, s2: any) => s1.name.localeCompare(s2.name));
      group.sourceLangs = sourceLangs;
    });
    return sourceLangs;
  }

  getUserName() {
    return getUserName(this.user);
  }

  showDescription() {
    return existsAndNotEmpty(this.user.description);
  }

  showLocation() {
    return this.hasMotherTongue() || this.hasLocation();
  }

  hasLocation() {
    return existsAndNotEmpty(this.user.country);
  }

  hasMotherTongue() {
    return existsAndNotEmpty(this.user.motherTongue);
  }

  getTaskActivities() {
    return this.taskActivities;
  }

  getSourceLanguages(activity: string) {
    return this.competenceGroups.filter(g => g.activity === activity)[0].sourceLangs;
  }

  getTargetLanguages(activity: string, source: string) {
    return this.getLanguagePairs(activity).get(source);
  }

  private getLanguagePairs(activity: string): Map<string, any[]> {
    return this.competenceGroups.filter(g => g.activity === activity)[0].targetLangMap;
  }

  private getLangNameObs(langCode: string) {
    return this.languageService.codeToDescription(langCode)
      .map(v => {
        // Map to object so we still know which code relates to which name
        let lang = Object();
        lang.code = langCode;
        lang.name = v;
        return lang;
      });
  }

  getActivityVerb(type: any) {
    if (isTranslationActivity(type))
      return "translate";
    else if (isRevisionActivity(type))
      return "revise";
    if (isSwornTranslationActivity(type))
      return "do sworn translations";
    else return "process content"
  }

  setReviews(userId) {
    this.reviewService.getReviewsForUser(userId)
      .subscribe(reviews => {
        this.reviews = reviews;
        this.calcGeneralScore();
      })
  }

  calcGeneralScore() {
    if (this.reviews) {
      let count = 0;
      let total = 0;
      for (let rev of this.reviews) {
        for (let score of rev.scores) {
          count++;
          total += score.rate
        }
      }
      if (count > 0) this.generalScore = total / count;
      else this.generalScore = 0;
    } else
      this.generalScore = 0;
  }
}

/**
 * Data class to organize competence data per activity.
 */
export class CompetenceGroup {
  activity: string;
  sourceLangs;
  targetLangMap;
}
