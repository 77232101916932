<div class="invite" style="position: relative;">
  <div class="close" (click)="dialogRef.close()">
    X
  </div>
  <div>
    <h2>Invite a customer</h2>
    <h4>Invite a customer and get paid for each word that gets translated.</h4>
    <div>@LiLo you can get paid for doing... nothing.<br/>
      Send an invite link to a potential customer and get paid for every word they get translated.<br/>
      We value that you bring customers to the platform, bringing a customer to the platform you will not only always
      make you the preferred translator for that customers translation but you will also get paid for each other word
      that is translated (to whatever language).
    </div>
  </div>
  <div>
    <h4>Fill in one or more email addresses to send an invite link to.</h4>
    <div>When they sign in, using your link, we will store this and a total reward for you will be displayed in the
      invoicing section of your profile.
    </div>
    <div>
      <div class="inviteAddresses">
        <label>Customer e-mail</label>
        <div *ngFor="let address of addresses" style="display: flex">
          <div style="min-width: 210px">{{address}}</div>
          <img src="../../assets/minus.png" style="height: 20px; width: 20px; cursor:pointer"
               (click)="removeAddress(address)"/>
        </div>
        <input type="email" placeholder="for example jef@lilo.global" [(ngModel)]="currentValue"/>
        <button (click)="addAddress()"><img src="../../assets/add.png" style="width: 15px; height: 15px"/>Add another
        </button>
      </div>
      <div style="display: flex">
        <div class="inviteContent">
          <label>Personal message</label>
          <textarea [(ngModel)]="inviteMessage"></textarea>
        </div>
        <p style="font-size:smaller; margin: 20px">You're personal message will be added to our generic mail where the recepient
          will find a personalized link to join LiLo and a link to our info pages: <a href="https://about.lilo.global/whyuselilo" target="_blank">about.lilo.global/whyuselilo</a>
          <br/>
          To see how the recipient will receive the message by sending an invite to yourself.
        </p>
      </div>
      <!--TODO add validation if the placeholder for the invite link is not included anymore.-->
    </div>
  </div>
  <div style="float: right">
    <!--TODO loading-->
    <button (click)="sendInvites()">Send</button>
  </div>
</div>
