
	  <div class="mainDiv">
		  <h1 *ngIf="confirmTitle">{{confirmTitle}}</h1>
		  <h4 *ngIf="confirmMessage">{{confirmMessage}}</h4>
		  <div *ngIf="confirmHtml" class="confirmHtmlDiv" [innerHTML]="confirmHtml | safeHtml"></div>
		  <div class="buttons">
			  <button [ngClass]="{confirm: !invertStyle, cancel: invertStyle}" 
                (click)="confirm()">{{confirmText}}</button>
			  <button [ngClass]="{cancel: !invertStyle, confirm: invertStyle}" 
                (click)="cancel()">{{cancelText}}</button>
		  </div>
	  </div>