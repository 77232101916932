import {Component, Input} from "@angular/core";

@Component({
  selector: 'index-main',
  templateUrl: './index-main.component.html',
  styleUrls: ['./index-main.component.css'],
})
export class IndexMainComponent{

  @Input()
  size: string;

  constructor(){

  }
}
