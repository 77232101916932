import {Component, OnInit} from "@angular/core";
import {UserService} from "../../service/user.service";
import {MatDialog} from '@angular/material';
import {ConfirmDialog} from "../../confirm.dialog";
import {filter} from 'rxjs/operators';
import {TmService} from "../../service/tm.service";


@Component({
  selector: 'tm-management',
  templateUrl: 'tm-management.component.html',
  styleUrls: ['../admin-dashb.component.css'],
})
export class TmManagementComponent implements OnInit {

  userId = "";

  tmIdxData;
  tmxFile;
  loading = false;
  successMsg;
  errorMsg;


  constructor(public userService: UserService,
              private tmService: TmService,
              private dialog: MatDialog) {
  }

  ngOnInit() {

  }

  // todo: test this for customer groups!

  findTm4Owner() {
    this.resetMessages();
    if (this.userId != undefined && this.userId.length > 0) {
      this.userService.getTmIndex(this.userId).subscribe(d => {
        this.tmIdxData = d;
        if (this.tmIdxData == undefined) {
          this.errorMsg = "No TM index found for user " + this.userId + ".";
        }
      }, error => {
        console.error("Error retrieving TM index for user " + this.userId, error);
        this.errorMsg = "Error retrieving TM index for user " + this.userId + "! (see console)";
      });
    }
  }

  uploadTmx() {
    this.resetMessages();
    if (this.tmxFile != undefined) {
      let dialogRef = this.dialog.open(ConfirmDialog, {
        maxWidth: '60%',
        panelClass: 'confirmDialog',
        data: {
          confirmMessage: 'Are you sure you wish to upload \'' + this.tmxFile.name + '\' for TM index ' + this.tmIdxData.tmindex + '?',
          confirmText: 'Yup',
          cancelText: 'Nah'
        }
      });
      dialogRef.afterClosed().pipe(
        filter(t => t === "confirm"))
        .subscribe(p => {
          let start = Date.now();
          this.loading = true;
          this.tmService.uploadTmxFile(this.tmxFile, this.tmIdxData.tmindex).subscribe(count => {
            this.loading = false;
            this.successMsg = "TMX file was successfully uploaded: " + count + " segments inserted in " + (Date.now() - start) + " ms.";
            console.debug(this.successMsg);
          }, error => {
            this.loading = false;
            this.errorMsg = "Error uploading TMX file! (see console)";
            console.error("Error uploading TMX file", error);
          });
        });
    }
  }

  selectTmx(event: any) {
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      this.tmxFile = fileList[0];
    }
  }

  private resetMessages() {
    this.errorMsg = undefined;
    this.successMsg = undefined;
    this.loading = false;
  }
}
