<div *ngIf="userService.isCurrentUserAdmin">
  <div>
    <label>title</label>
    <input [(ngModel)]="title"/>
  </div>
  <div>
    <label>reason</label>
    <input [(ngModel)]="reason"/>
  </div>

  <div>
    <label>receiver id (user)</label>
    <input [(ngModel)]="receiverUserId"/>
  </div>
  <div>
    <label>receiver id (company)</label>
    <input [(ngModel)]="receiverCompanyId"/>
  </div>
  <div>
    <label>Calculation type</label>
    <select [(ngModel)]="calculationType" (change)="fetchCalcParams()">
      <option *ngFor="let type of calculationTypes">{{type}}</option>
    </select>
  </div>
  <div *ngIf="calculationParamFields!=null && calculationParamFields.length>0">
    <div *ngFor="let param of calculationParamFields">
      <label>{{param}}</label>
      <input [(ngModel)]="calculationParams[param]"/>
    </div>
  </div>
  <div>
    <label>Validation type</label>
    <select [(ngModel)]="validationType" (change)="fetchValidParams()">
      <option *ngFor="let type of validationTypes">{{type}}</option>
    </select>
  </div>
  <div>
    <button (click)="submit()">Create</button>
  </div>
</div>
