<!--suppress ALL -->
<div *ngIf="user == undefined">
  To continue, please <span class="loginLink" (click)="openJoin()">join</span> or
  <span class="loginLink" (click)="openSignIn()">sign in</span>.
</div>
<div *ngIf="user != undefined && edit === false">
  <h3>Signed in as</h3>
  <div class="userOverview">
    <p>{{user.firstName}} {{user.lastName}}<br/>
      <span *ngIf="company != undefined">{{company.name}}</span></p>
    <p *ngIf="company != undefined">
      <span [innerHTML]="company.address|returnToBreakPipe"></span><br/>
      {{company.country}}</p>
    <p>{{user.phone}}<br/>
      {{user.email}}</p>
    <div class="edit" (click)="edit = true"><u>change</u></div>
  </div>
</div>
<div class="userEdit" *ngIf="user != undefined && edit === true">
  <div class="infoField">
    <label class="infoLabel">First name</label>
    <input type="text" class="infoInput" placeholder="First name" [(ngModel)]="user.firstName"/>
  </div>
  <div class="infoField">
    <label class="infoLabel">Last name</label>
    <input type="text" class="infoInput" placeholder="Last name" [(ngModel)]="user.lastName"/>
  </div>
  <div class="infoField">
    <label class="infoLabel">Telephone</label>
    <input type="text" class="infoInput" placeholder="Telephone" [(ngModel)]="user.phone"/>
  </div>
  <div *ngIf="company!=undefined">
    <div class="infoField">
      <label class="infoLabel">Company name</label>
      <input type="text" class="infoInput" placeholder="Company name" [(ngModel)]="company.name"/>
    </div>
    <div class="infoField">
      <label class="infoLabel">Address</label>
      <textarea class="infoInput" placeholder="Company address" [(ngModel)]="company.address"></textarea>
    </div>
    <div class="infoField">
      <label class="infoLabel">Country</label>
      <select class="infoInput" [(ngModel)]="company.country">
        <option *ngFor="let country of countries" [ngValue]="country.display">{{country.display}}</option>
      </select>
    </div>
    <div class="infoField">
      <label class="infoLabel">VAT number</label>
      <input type="text" class="infoInput" placeholder="VAT number" [(ngModel)]="company.vat"/>
    </div>
  </div>
</div>
<button class="nextButton" *ngIf="edit === true" (click)="storeUserInfo()">Next</button>
