import {Component, Input} from "@angular/core";
import {TaskService} from "../service/task.service";
import {UserService} from "../service/user.service";
import {ReviewService} from "../service/review.service";
import {ActivatedRoute, Params} from "@angular/router";

@Component({
  selector: 'rating-full-component',
  templateUrl: "./review.full.component.html",
  styleUrls: ["./review.component.css"],
})

export class RatingFullComponent {

  reviews;
  generalScore;

  @Input()
  userId;


  constructor(private reviewService: ReviewService,
              private taskService: TaskService,
              private userService: UserService,
              private route: ActivatedRoute,) {

  }

  ngOnInit() {
    if (this.userId != null) {
      this.setUserId(this.userId)
    } else {
      this.route.params
        .forEach((params: Params) => this.setUserId(params['id']))
    }
  }

  setUserId(userId) {
    this.userId = userId;
    this.reviewService.getReviewsForUser(userId)
      .subscribe(reviews => {
        this.setReviews(reviews)
      })
  }

  setReviews(reviews: Array<any>) {
    this.reviews = reviews;
    if (this.reviews) {
      //TODO do it with observables
      let count = 0;
      let total = 0;
      for (let rev of this.reviews) {
        for (let score of rev.scores) {
          count++;
          total += score.rate
        }
      }
      if (count != null && count > 0)
        this.generalScore = total / count;
      else
        this.generalScore = 0;
    } else {
      this.generalScore = 0;
    }
  }

  mugshotURL(): string {
    //TODO fetch the real profilepig
    return "../../../assets/avatar/anom.png";
  }

}
