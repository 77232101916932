/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./error.snackbar.component";
import * as i2 from "@angular/material/snack-bar";
var styles_ErrorSnackbarComponent = ["h5[_ngcontent-%COMP%]{color:red;}"];
var RenderType_ErrorSnackbarComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_ErrorSnackbarComponent, data: {} });
export { RenderType_ErrorSnackbarComponent as RenderType_ErrorSnackbarComponent };
export function View_ErrorSnackbarComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "h5", [], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errorText; _ck(_v, 0, 0, currVal_0); }); }
export function View_ErrorSnackbarComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "error-snackbar", [], null, null, null, View_ErrorSnackbarComponent_0, RenderType_ErrorSnackbarComponent)), i0.ɵdid(1, 114688, null, 0, i1.ErrorSnackbarComponent, [i2.MAT_SNACK_BAR_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ErrorSnackbarComponentNgFactory = i0.ɵccf("error-snackbar", i1.ErrorSnackbarComponent, View_ErrorSnackbarComponent_Host_0, {}, {}, []);
export { ErrorSnackbarComponentNgFactory as ErrorSnackbarComponentNgFactory };
