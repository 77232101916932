import {Component, ElementRef, Input, OnInit, ViewChild} from "@angular/core";
import {UserService} from '../../service/user.service';


@Component({
  selector: 'review-scroller',
  templateUrl: './review-scroller.component.html',
  styleUrls: ['../user-profile.component.css']
})
export class ReviewScrollerComponent implements OnInit {

  @Input()
  reviews;
  @Input()
  user;

  sortedReviews;
  currentReview;
  currentIndex;

  @ViewChild("reviewScrollList", {read: ElementRef, static: false})
  reviewList: ElementRef<any>;
  @ViewChild("scrollLeftArrow", {read: ElementRef, static: false})
  private leftArrow: ElementRef<any>;
  @ViewChild("scrollRightArrow", {read: ElementRef, static: false})
  private rightArrow: ElementRef<any>;

  scrollerWidth: number;

  constructor(private userService: UserService) {
  }

  ngOnInit() {
    this.sortedReviews = this.reviews.sort((s1: any, s2: any) => {
      if (s1.creationdate < s2.creationdate)
        return 1;
      else if (s1.creationdate > s2.creationdate)
        return -1;
      else return 0;
    });
    if (this.sortedReviews.length > 0) {
      this.currentReview = this.sortedReviews[0];
      this.currentIndex = 0;
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.calcItemWidth();
    });
  }

  calcItemWidth() {
    if (this.reviewList != undefined) {
      // Calculate the width of the allocated screen space, so we can stretch the items across it
      let leftArrowWidth = (this.leftArrow != undefined ? this.leftArrow.nativeElement.clientWidth : 0);
      let rightArrowWidth = (this.rightArrow != undefined ? this.rightArrow.nativeElement.clientWidth : 0);
      this.scrollerWidth = this.reviewList.nativeElement.clientWidth - leftArrowWidth - rightArrowWidth;
    }
  }

  scrollLeft() {
    if (this.currentIndex > 0) {
      this.currentIndex = this.currentIndex - 1;
      this.currentReview = this.sortedReviews[this.currentIndex];
    }
  }

  scrollRight() {
    if (this.currentIndex < (this.sortedReviews.length - 1)) {
      this.currentIndex = this.currentIndex + 1;
      this.currentReview = this.sortedReviews[this.currentIndex];
    }
  }

  getTransX() {
    if (this.currentIndex != undefined && this.scrollerWidth != undefined) {
      return -1 * this.currentIndex * this.scrollerWidth;
    } else return 0;
  }
}
