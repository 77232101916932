<div class="boxing">
  <div *ngFor="let file of uploadedFiles" class="module" [@appearIn]="'in'">
    <file-info *ngIf="file" [file]="file" [pId]="pId" [pdf4source]="getPdf4Source(file)"
               (remover)="removeFile(file)"
               (languageUpdate)="updateLanguage(file, $event)"
    ></file-info>
  </div>
</div>
<div *ngIf="sourceLanguageCount > 1" style="float: left">
  <h3 style="color: red">Not all documents have the same language. Unfortunately, this is currently not supported.</h3>
  <h5 style="text-align: center">Please ensure you only have files for one specific language per project. Either create
    a project per document language, or adapt the language if possible.</h5>
</div>
<div class="divUpload">
  <label for="file-upload" class="labelButton" style="margin-left: 0px;">
    <img src="../../../assets/add_file_white.png" style="width: 20px; height: 20px; vertical-align: middle;">
    Add file
  </label>
  <input id="file-upload" type="file" placeholder="Upload your files"R
         [(ngModel)]="fileUpload" (change)="uploadFiles($event)" multiple
         style="display: none;"
         #fileupload/>
  <label (click)="openDocUploadDialog()" class="labelButton">
    <img src="../../../assets/add_image_white.png" style="width: 20px; height: 20px; vertical-align: middle;">
    Add documentation
  </label>
</div>
