<div class="main" *ngIf="userService.isLoggedIn()">
  <h1 *ngIf="isFromCreated" style="margin-bottom: 50px;" id="projectThankYouMsg">
    Thank you for your <a routerLink="/pdetail/{{createdId}}" routerLinkActive="active"
                          style="font: inherit; text-decoration: none;">order</a>!
  </h1>
  <h1 *ngIf="!isFromCreated" style="margin-bottom: 50px;">Your projects</h1>

  <div>
    <div class="quickAccess">
      <h3 style="padding-bottom: 30px;">Quick access</h3>
      <div class="quickAccessCards"
           [ngStyle]="{'justify-content': quickAccess.length < 3 ? 'flex-start' : 'space-around'}">
        <div class="newProjectCard" (click)="newProject()">
          <button class="newProjectCircle">+</button>
          <p class="newProjectLabel">New project</p>
        </div>
        <project-card *ngFor="let project of quickAccess" (click)="gotoProject(project.id)"
                      [@appearIn]="'in'" [project]="project"
                      [ngStyle]="{'margin-left': quickAccess.length < 3 ? '9px' : 'none'}"
                      style="flex: 0 1 250px;"></project-card>
      </div>
    </div>

    <div *ngIf="displayedProjects">
      <div class="filterOptions">
        <div class="stateButtons">
          <button [className]="(selectedStates.indexOf(allState))  > -1 ? 'stateButtonSelected' : 'stateButton'"
                  (click)="selectState(allState)"
                  title="Load all projects"><span title="{{allState}}">{{allState}}</span></button>
          <button *ngFor="let s of projectStates"
                  [className]="(selectedStates.indexOf(s)) > -1 ? 'stateButtonSelected' : 'stateButton'"
                  (click)="selectState(s)"
                  title="Load projects with status '{{s}}'"><span title="{{s}}">{{s}}</span></button>
        </div>
        <div class="otherButtons">
          <div [className]="(filterText != null && filterText.length > 0 ? 'searchDivOpen' : 'searchDiv')">
            <input type="text" name="search" class="searchField" placeholder="I'm searching for"
                   [(ngModel)]="filterText"
                   (ngModelChange)="filterProjects()"
                   (change)="setFilteredProjects()"/>
            <img src="../../assets/icon_search.svg">
          </div>
          <button class="newProjectButton" (click)="newProject()"><span class="nohover"
                                                                        style="font-size: 30px; padding-top: 2px;">+</span><span
            class="onhover">New project</span></button>
        </div>
      </div>
      <div>
        <project-list [projects]="displayedProjects"
                      (selectedProject)="gotoProject($event.id)"></project-list>
        <div *ngIf="filteredProjects.length === 0">
          <h5 style="padding: 20px 1px 5px 5px;">No projects found.</h5>
        </div>
        <div *ngIf="filteredProjects.length > initTableSize" class="showButtons">
          <button *ngIf="initTableSize >= tableSize" (click)="toggleListSize()">Show more</button>
          <button *ngIf="initTableSize < tableSize" (click)="toggleListSize()">Show less</button>
        </div>
      </div>
    </div>

    <div style="display: flex; justify-content: center;"
         *ngIf="!filteredProjects">
      <img src="../../assets/loading_logo_1.gif" style="width: 100px;">
    </div>

  </div>
</div>
