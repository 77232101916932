<h2>Folder view</h2>

<div *ngIf="!userService.isCurrentUserAdmin">
  You do not have the correct privileges to access this page.
</div>

<div *ngIf="userService.isCurrentUserAdmin">

  <div>
    <p>Pick an 'entity type' (project, company or user) and specify the ID in the text field. Press 'view' to view the
      folder contents.</p>
    <div style="display: flex; align-items: center;">
      <select [(ngModel)]="entitytype">
        <option *ngFor="let entitytype of entitytypes" value={{entitytype}}>{{entitytype}}</option>
      </select>
      <input id="entityid" type="text" name="entityid" [(ngModel)]="entityid"
             style="width:300px;"/>
      <button style="min-height: 30px; width: 100px;" (click)="openView()">View</button>
    </div>
  </div>

  <hr style="margin-bottom: 10px; margin-top: 15px;">
  <folderview *ngIf="entityid2view" type="{{entitytype2view}}" id="{{entityid2view}}"></folderview>

</div>


