
      <div class="rejectDialog">
          <h4>Decline task</h4>
          <p>
              We're very sorry to hear you are not able to accept this task.<br/>
          </p>
          <mat-expansion-panel [expanded]="false">
              <mat-expansion-panel-header>
                  <mat-panel-title>
                      <p>If the reason is <strong>the deadline</strong>, you have the option to do a counter proposal.
                      </p>
                  </mat-panel-title>
              </mat-expansion-panel-header>
              <div>
                  <div>
                      <div>
                          <datetime (dateSelected)="counterProposal.date = $event"></datetime>
                          <textarea [(ngModel)]="counterProposal.text"
                                    placeholder="Please motivate why you propose a different due date."
                                    style="width:100%;"></textarea>
                      </div>
                      <small>Please note that if the user accepts your proposal the task is automatically accepted
                          (you'll be notified via mail).
                      </small>
                  </div>
                  <button (click)="submitProposal()">Submit proposal</button>
              </div>
          </mat-expansion-panel>
          <p>
              If there is another reason you would like to decline, we would very much appreciate it if you could
              share the reason why you are not able to, so we can pass this on to the customer that chose you for this
              task.<br/>
              <small>If, for example, not the best time, the customer might be willing to change it to
                  accommodate.</small>
          </p>
          <textarea [(ngModel)]="message" (keypress)="checkDeadline($event)" [placeholder]="placeholder"></textarea>
          <div *ngIf="possibleDeadlineIssues">
              <small style="color: red;">If you have issues with the deadline, you can do a counter proposal, see above.
              </small>
          </div>
          <div
                  style="border: 1px solid darkgray; display: flex; flex-direction: column; padding: 2px; margin-top: 10px; margin-bottom: 10px;">
              <div *ngIf="!suggestedColleague" style="display: flex; justify-content: space-between;">
                  <label>Do you know someone that would be ideal for this task?</label>
                  <button (click)="openColleagues()" style="font-size: smaller; margin: 2px;">Suggest someone!
                  </button>
              </div>
              <div *ngIf="suggestedColleague" style="display: flex;">
                  <label style="flex: 1 1 auto; display: flex; align-items: center; justify-content: space-between;">
                      <span style="color: inherit;">Alternative suggestion for task: </span>
                      <span>{{suggestedColleague.name}}</span>
                  </label>
                  <span style="vertical-align: middle; display: flex; align-items: center;">
                    <img (click)="openColleagues()" src="../../assets/edit.png" title="Edit suggestion"
                         style="margin-left: 5px;"/>
                    <img (click)="selectColleague(undefined)" src="../../assets/minus.png" title="Remove suggestion"/>
                 </span>
              </div>
          </div>
          <div style="display: flex; justify-content: center; margin-top: 10px;">
              <button (click)="closeDialog()">Decline</button>
              <button (click)="dialogRef.close()">Cancel</button>
          </div>
      </div>