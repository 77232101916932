import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {getBaseUrl} from "../util/httpclient";
import {existsAndNotEmpty} from "../util/apputil";


@Injectable()
export class ReviewService {

  private REVIEW_API = getBaseUrl() + "/api/v1/reviews";
  categories = [{name: "How good is the quality?", code: "Quality"}, {
    name: "How likely are you to send new tasks?",
    code: "Reuse"
  }];


  constructor(private http: HttpClient) {
  }

  postReview(review: any) {
    if (existsAndNotEmpty(review.id))
      return this.http.post(this.REVIEW_API + "/update", JSON.stringify(review), {responseType: 'text'});
    else
      return this.http.post(this.REVIEW_API + "/insert", JSON.stringify(review), {responseType: 'text'});
  }

  getReviewsForUser(userId: string): Observable<any> {
    return this.http.get(this.REVIEW_API + "/findbyuser/" + userId);
  }

  getReviewSummaryForUser(userId: string): Observable<any> {
    return this.http.get(this.REVIEW_API + "/findbyuser/summary/" + userId);
  }

  getReviewsForTask(taskId: string) {
    return this.http.get(this.REVIEW_API + "/findbytask/" + taskId);
  }
}
