import {Component, EventEmitter, Input, Output, SimpleChanges, OnDestroy} from "@angular/core";
import {TaskService} from "../../service/task.service";
import {Task, TaskSpec} from "../../dto/job";
import {Observable, Subject, Subscription} from "rxjs";
import {debounceTime, filter, flatMap, map, switchMap, tap, auditTime} from "rxjs/operators";
import {PricingService} from "../../service/pricing.services";
import {StatisticsService} from "../../service/statistics.service";
import {UserService} from "../../service/user.service";
import {VendorService} from "../../service/vendor.service";
import { SubscriptionHandler } from '../../util/subscription.handler';
import { toLangPairString } from '../../util/jobutil';


@Component({
  selector: 'language-bar',
  templateUrl: './language-bar.component.html',
  styleUrls: ['../project.component.css'],
})
export class LanguageBarComponent implements OnDestroy{

  @Input()
  projectId: string;
  @Input()
  service: string;
  @Input()
  langpair: any;
  @Input()
  files: any[];
  @Input()
  duedate: Date;
  @Input()
  translators: any[] = undefined;
  @Input()
  revisers: any[] = undefined;
  @Input()
  isCollapsed = true;
  // With project creation the translators and revisers are loaded from the project creation component
  // This is so the preloading can be done once the languages are selected (which makes it faster)
  // With project followup we load them within this component
  @Input()
  loadInternally = false;
  @Input()
  projectFinal = false;
  @Input()
  isQuote = false;

  @Output()
  onNext = new EventEmitter<any>();
  @Output()
  onExpand = new EventEmitter<any>();
  @Output()
  onPriceChange = new EventEmitter<any>();

  translationTask: Task = undefined;
  filteredRevisers: any[][] = [];
  revisionTasks: Task[] = [];
  revisionRounds: number[] = [undefined];
  dbTasks: Task[] = [];
  translatorUser;
  reviserUser;
  revisersCount = 0;

  totalprice: number = 0;
  serviceFee: number = 0;

  taskStoreObservable = new Subject<any>();

  subscriptionHandler = new SubscriptionHandler(this);

  constructor(private taskService: TaskService,
              private pricingService: PricingService,
              private statisticsService: StatisticsService,
              private userService: UserService,
              private vendorService: VendorService
  ) {
  }

  ngOnInit() {
    this.initRecalcTotalPricingObs();
    this.initTasksFromServer();
    // Enroll to receive any updates to the tasks, so we can update the total price accordingly
    const projectLPFeedSubscription = this.taskService.enrollForProjectAndLangPair(this.projectId,
      this.langpair.source + "_" + this.langpair.target)
      .pipe(
        debounceTime(500),
        tap(v => {
          // console.debug("LanguageBar " + this.langpair.target + ": task message received", v);
          if (v.action === "TASK_CREATE" || v.action === "TASK_CANCEL") {
            let taskId = v.task_id;
            // If no task ID or..
            if (taskId === null ||
              // If a task was created that we don't know about yet or...
              (v.action === "TASK_CREATE" && !this.hasTask(taskId)) ||
              // If a task was canceled we do know about
              (v.action === "TASK_CANCEL" && this.hasTask(taskId))) {
              // Then update the tasks
                this.initTasksFromServer();
              }
            // Else we can simply assume we're already aware of the changes coming in (since we did them ourselves here)
          } else {
            this.loadProfilePics();
            this.recalcTotalPricing()
          }
        })
      ).subscribe();
    this.subscriptionHandler.push(projectLPFeedSubscription);
    this.loadProfilePics();
    if (this.loadInternally) {
      this.loadTranslatorList();
      this.loadReviserList(true);
    }
    this.initStoreObservable();
  }

  ngOnDestroy() {

  }

  ngDoCheck() {
    // if (this.translationTask === undefined && this.translators != undefined && this.translators[0] != undefined) {
    //   this.translationTask = this.taskService.initTask(this.files, this.langpair.source, this.langpair.target, "Translation", 0, this.projectId, this.translators[0]);
    //   this.recalcTotalPricing();
    //   this.loadProfilePics();
    // }
  }

  ngOnChanges(changes: SimpleChanges) {
    // If the language bar is opened too quickly, it might be that the translator or reviser list hasn't been loaded from
    // the server yet.When they are loaded (which we'll get notified of by the changes on the input field), we need to
    // update the translator lists accordingly.
    if (changes.translators !== undefined) {
      this.translators = changes.translators.currentValue;
      this.filterRevisers();
    }
    if (changes.revisers !== undefined) {
      this.revisers = changes.revisers.currentValue;
      this.filterRevisers();
    }
  }

  initTasksFromServer() {
    this.dbTasks = [];
    this.revisionTasks = [];
    this.taskService.findTaskForProject(this.projectId, this.langpair.source, this.langpair.target)
      .pipe(
        flatMap(tasks => tasks.sort((t1, t2) => t1.sequence - t2.sequence)),
        map(task => {
          this.dbTasks.push(task);
          return task;
        }),
        map(task => {
          if (task.activity === "Translation") {
            this.translationTask = task;
          } else {
            this.revisionTasks.push(task);
            this.revisionRounds[task.sequence - 1] = task.sequence;
          }
          return task
        })
      )
      .subscribe(() => {
        }, error => {
          console.error("Error initializing tasks", error);
        },
        () => {
          this.loadProfilePics();
          this.recalcTotalPricing();
          this.filterRevisers();
        }
      );
  }

  private hasTask(taskId: string) {
    return (this.translationTask != null && this.translationTask.id === taskId) ||
      (this.revisionTasks != null && this.revisionTasks.filter(t => t.id === taskId).length > 0);
  }

  /**
   * Filters the list of available revisers based on the selected translator and revisers selected in the
   * preceding revision rounds.
   */
  filterRevisers() {
    this.filteredRevisers = [];
    if (this.revisers !== undefined) {
      if (this.translationTask !== undefined) {
        const currentReviser = this.revisionTasks[0] !== undefined ? this.revisionTasks[0].vendorId : undefined;
        this.filteredRevisers[0] = this.revisers.filter(r => r.id !== this.translationTask.vendorId || r.id === currentReviser)
      } else {
        this.filteredRevisers[0] = this.revisers;
      }
      this.revisionTasks
        .filter(t => t !== undefined)
        .forEach(t => {
          const currentReviser = this.revisionTasks[t.sequence] !== undefined ? this.revisionTasks[t.sequence].vendorId : undefined;
          this.filteredRevisers[t.sequence] = this.revisers.filter(r => r.id !== t.vendorId || r.id === currentReviser)
        });
    }
  }

  initStoreObservable() {
    this.taskStoreObservable.debounceTime(500)
      .pipe(switchMap(t => this.storeTaskChanges()))
      .subscribe(() => {
      }, error => {
        console.error("Error storing tasks", error);
      },)
  }

  onTranslatorSelected(translator: any) {
    if (translator !== undefined) {
      if (this.translationTask === undefined || this.translationTask.vendorId !== translator.id) {
        this.translationTask = this.taskService.initTask(this.files, this.service, this.langpair.source,
          this.langpair.target, 'Translation', 0, this.projectId, translator);
      }
    } else {
      this.translationTask = undefined;
    }
    this.recalcTotalPricing();
    this.loadProfilePics();
    this.filterRevisers();
    this.taskStoreObservable.next(translator);
  }

  onReviserSelected(index: number, translator: any) {
    if (translator !== undefined) {
      if (this.revisionTasks[index] === undefined || this.revisionTasks[index].vendorId !== translator.id) {
        this.revisionTasks[index] = this.taskService.initTask(this.files, this.service, this.langpair.source,
          this.langpair.target, 'Revision', index + 1,
          this.projectId, translator);
      }
    } else {
      this.revisionTasks[index] = undefined;
    }
    this.recalcTotalPricing();
    this.loadProfilePics();
    this.filterRevisers();
    this.taskStoreObservable.next(translator);
  }

  addRevision(index: number) {
    this.revisionRounds[index] = index + 1;
  }

  handleTaskChange(event: any, activity: string, index: number) {
    if (event === "cancel") {
      if (activity === "Translation") {
        this.translationTask = undefined;
        this.loadTranslatorList();
      } else {
        this.revisionTasks[index] = undefined;
        this.revisionRounds[index] = index + 1;
        this.loadReviserList(true);
      }
    }
    if (event === "accept"){
      if (activity === "Translation"){
        this.translationTask.price = null;
        this.recalcTotalPricing();
      }
    }
  }

  loadTranslatorList() {

    if (this.translators == null || this.translators.length === 0) {
      let langPair = toLangPairString(this.langpair);
      this.vendorService.getBestProjectCompetences(this.projectId, langPair, 'Translation', this.service)
        .subscribe(translators => {
          if (translators.length > 0) {
            translators[0]["isSelected"] = true;
            translators[0]["isRecommended"] = true;
          }
          this.translators = translators;
        });
    }
  }

  loadReviserList(filter: boolean) {
    if (this.revisers == null) {
      let langPair = toLangPairString(this.langpair);
      this.vendorService.getBestProjectCompetences(this.projectId, langPair, 'Revision', this.service)
        .subscribe(translators => {
          if (translators.length > 0) {
            translators[0]["isRecommended"] = true;
          }
          this.revisers = translators;
          if (filter) this.filterRevisers();
        });
    }
  }

  getFilteredRevisers(index: number): any[] {
    if (this.filteredRevisers[index] !== undefined) {
      return this.filteredRevisers[index]
    }
    return this.revisers;
  }

  /**
   * So, there's a multitude of triggers invoking this method when you select a new translator.
   *
   * First off, the onTranslatorSelected is invoked, which calls this method and then creates a new task based on the selection.
   * When a new task is created, the previous one is cancelled and a new one is created: both these actions each trigger
   * (through the task feed we enrolled to in ngOnInit) again a recalculation of the pricing.
   * Furthermore, translator-list is enrolled to the price feed, which gets an event too due to the update in tasks, and
   * couples this back here.
   *
   * To lower the amount of recalculations, we post an event on the event emitter and debounce with 500ms so that the observer itself
   * is only called every 500ms after the last event is emitted
   */
  recalcTotalPricingEmitter = new EventEmitter<any>();

  recalcTotalPricing() {
    this.recalcTotalPricingEmitter.emit('recalculate');
  }

  initRecalcTotalPricingObs() {
    // Subscribe to the event emitter, debounce and switch(Map) to the other observer for the total price
    // To make sure the first events (or the events happening after the recalc is done) happen faster then while there is already
    // one running, we first set the debounce time to 10ms, while executing server calls to 500ms and afterwards to 100ms
    let debounceTimer = 10;
    const subscription = this.recalcTotalPricingEmitter.pipe(
        debounceTime(debounceTimer), // important
        tap(() => debounceTimer = 500)
      ).switchMap(() => this.createTotalPricingObs())
      .pipe(
        tap(() => debounceTimer = 100)
      )
      .subscribe(p => {
        this.setTotalPrice(p);
      }, error => {
        console.error('Error calculating total price for ' + this.langpair.target, error);
      });
    // store the subscription so it can be unsubscribed on ngOnDestroy
    this.subscriptionHandler.push(subscription)
  }

  private createTotalPricingObs(): Observable<number> {
    // create the different observers to get the total price
    const translationPriceObs = this.translationTask != null ? this.calcPriceForTask(this.translationTask) : Observable.of(.0);
    const revisionPriceObs = Observable.from(this.revisionTasks)
      .filter(t => t != null)
      .switchMap(t => this.calcPriceForTask(t));
    const serviceFeeObs = this.pricingService.getServiceFee(this.projectId, this.langpair)
      .pipe(
        tap(serviceFee => {
          this.serviceFee = serviceFee;
        }),
      );
    const mtCostObs = this.pricingService.getMTCost(this.projectId, this.langpair);
    // merge all the different observers and sum the prices
    const recalcTotalPriceObs = translationPriceObs
      .merge(revisionPriceObs, serviceFeeObs, mtCostObs)
      .reduce((p1, p2) => {
        return p1 + p2
      }, 0)
    return recalcTotalPriceObs;
  }

  private setTotalPrice(price: number) {
    const priceDifference = Math.abs(this.totalprice - price);
    if (priceDifference >= 0.01) {
      console.debug("Project " + this.projectId + " -- " + this.langpair.source + "_" + this.langpair.target + ": " +
        "updated total price to €" + price + " (was €" + this.totalprice + ")");
      this.totalprice = price;
      this.onPriceChange.emit(this.totalprice);
    }
  }

  calcPriceForTask(t: Task): Observable<number> {
    let transObs;
    if (t.price != null) {
      // The "+" turns the price from a string into a number
      transObs = Observable.of(+t.price);
    } else if (t.id != undefined) {
      transObs = this.pricingService.getPriceForTask(t.id);
    } else {
      let taskSpec = new TaskSpec();
      taskSpec.activity = t.activity;
      taskSpec.service = t.service;
      taskSpec.projectId = t.projectId;
      taskSpec.sourcelanguage = t.sourcelanguage;
      taskSpec.targetlanguage = t.targetlanguage;
      transObs = this.pricingService.getPriceForTaskSpecAndVendor(taskSpec, t.vendorId, t.basePrice);
    }
    return transObs;
  }

  storeTaskChanges(): Observable<any> {
    // Remove the old tasks which are not selected anymore
    let removeObs = Observable.merge(...this.dbTasks
      .filter(t => this.translationTask !== t && this.revisionTasks.indexOf(t) < 0)
      .map(t => this.taskService.cancelTask(t)));
    //Insert the newly created tasks
    let tasks: Task[] = [];
    if (this.translationTask != undefined && this.translationTask.id === undefined) {
      tasks.push(this.translationTask);
    }
    if (this.revisionTasks.length > 0) {
      this.revisionTasks.filter(t => t !== undefined && t.id === undefined)
        .forEach(t => tasks.push(t));
    }
    let createObs = Observable.merge(...tasks.map(t => this.taskService.insertTask(t)))
      .pipe(
        tap(t => this.dbTasks.push(t)),
      );
    return Observable.merge(...[removeObs, createObs]).finally(() => {
      this.initTasksFromServer()
    });
  }

  goToNext() {
    //Move to the next language pair (or project info)
    this.collapse();
    this.onNext.emit(this.langpair);
  }

  collapse() {
    this.isCollapsed = true;
  }

  expand() {
    this.isCollapsed = false;
    if (!this.isCollapsed) {
      this.onExpand.next(this.langpair);
    }
  }

  toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
    if (!this.isCollapsed) {
      this.onExpand.next(this.langpair);
    }
  }

  loadProfilePics() {
    if (this.translationTask !== undefined && this.translationTask.vendorId !== undefined) {
      this.userService.getUser(this.translationTask.vendorId).subscribe(user => this.translatorUser = user);
    } else {
      this.translatorUser = undefined;
    }
    if (this.revisionTasks !== undefined && this.revisionTasks.length > 0 && this.revisionTasks[0] !== undefined) {
      this.userService.getUser(this.revisionTasks[0].vendorId).subscribe(user => this.reviserUser = user);
    } else {
      this.reviserUser = undefined;
    }
    this.revisersCount = this.revisionTasks.filter(t => t !== undefined).length;
  }

  getTotalPrice() {
    // Only show price if there are any tasks (to avoid showing just the service fee)
    if (this.translationTask != null || (this.revisionTasks != null && this.revisionTasks.length > 0)) {
      return this.totalprice;
    } else {
      return undefined;
    }
  }
}
