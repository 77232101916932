<div>
  <div *ngIf="project.chargeid === undefined && project.canceled == null && !project.isQuote"
       style="display: flex; flex-direction: column; justify-content: space-between;">
    <div>
      <div *ngIf="canUpdate" style="display: inline-block; width: 100%;">
        <div style="float: left;">Price difference</div>
        <price [price]="priceDiff" style="float: right;"></price>
      </div>
      <div style="display: inline-block; width: 100%;" class="totalPriceUpdate">
        <div style="float: left;">Project total</div>
        <price *ngIf="pricing !== undefined" [price]="pricing.total()" style="float: right;"></price>
        <div *ngIf="pricing === undefined" style="float: right;">-</div>
      </div>
      <div *ngIf="pricing != null && pricing.vatRate != null && pricing.vatRate > 0"
           style="display: inline-block; width: 100%;" class="totalPriceUpdate">
        <div style="float: left;">Project total incl. VAT <span style="font-size: 12px;">({{pricing.vatRate * 100}}
          %)</span></div>
        <price [price]="pricing.totalIncVat()" style="float: right;"></price>
      </div>
    </div>
    <div style="margin-top: 25px;">
      <div *ngIf="canUpdate">
        <button *ngIf="project.paymentmethod!='PAYPAL'" class="btnUpdateOrder" (click)="updateOrder()">Update order</button>
        <paypal *ngIf="project.paymentmethod ==='PAYPAL'" [project]="project" (approveEmitter)="updateOrder()"></paypal>
      </div>
    </div>
  </div>
  <div *ngIf="project.chargeid != undefined">
    <div style="display: inline-block; width: 100%;" class="totalPriceUpdate">
      <div style="float: left;">Project total</div>
      <price *ngIf="pricing !== undefined" [price]="pricing.total()" style="float: right;"></price>
      <div *ngIf="pricing === undefined" style="float: right;">-</div>
    </div>
    <div *ngIf="pricing != null && pricing.vatRate != null && pricing.vatRate > 0"
         style="display: inline-block; width: 100%;" class="totalPriceUpdate">
      <div style="float: left;">Project total incl. VAT <span style="font-size: 12px;">({{pricing.vatRate * 100}}
        %)</span></div>
      <price [price]="pricing.totalIncVat()" style="float: right;"></price>
    </div>
    <p style="margin-top: 25px;">Project is fully completed</p>
    <div *ngIf="projectInvoices != null" style="margin-top: 10px;">
      <p *ngIf="projectInvoices.length == 1"><a
        routerLink="/invoice/{{projectInvoices[0].id}}" title="View invoice {{projectInvoices[0].number}}">View your
        invoice</a></p>
      <div *ngIf="projectInvoices.length > 1">
        <p>View your invoices:</p>
        <ul style="list-style: disc; padding-left: 15px;">
          <li *ngFor="let i of projectInvoices">&nbsp;<a routerLink="/invoice/{{i.id}}">{{i.number}}</a></li>
        </ul>
      </div>
    </div>
  </div>
  <div *ngIf="project.canceled != null">
    <p>Project is canceled</p>
  </div>
  <div class="quote" *ngIf="project.isQuote">
    <h3>Quote requested</h3>
    <p>
      Check the status under <strong>"Your translator"</strong> at the bottom of this page.
    </p>
  </div>
</div>
