<div class="infoDiv">
  <div style="display: flex; flex-direction:row-reverse; justify-content: space-between;">
    <div class="fileremove">
      <img src="../../../assets/delete.png" class="btnRemove"
           (click)="removeFile()"
           title="Remove file">
    </div>
    <div class="fileicon">
      <frontpage [pId]="pId" [fileName]="file.name"></frontpage>
      <div class="fileoverlay" *ngIf="isFileUploading()" title="Uploading...">
        <h4>{{uploadPercent}}%</h4>
        <mat-progress-bar mode="determinate" [value]="uploadPercent"></mat-progress-bar>
      </div>
    </div>
  </div>
  <div class="fileinfo">
    <h4 style="margin-bottom: 5px;">{{file.name}}
      <img src="../../../assets/check.png" class="statusImage"
           [style.display]="getStatusImageDisplay()"
           [title]="getStatusTooltip()"/>
    </h4>
    <div style="width:100%;">
      <label *ngIf="file.processType=='MANUAL'" style="color: lightcoral">Unable to perform analysis on this file.</label>
      <div>
        <language-selector title="Language:&nbsp;"
                           (onLanguageSelect)="updateFileLang($event, true)"
                           [(selectedLang)]="file.sourcelang"></language-selector>
        <div *ngIf="file.detectedsourcelang!=undefined">
          <label [style.color]="file.detectedsourcelang!=file.sourcelang ? 'lightcoral' : null">
            Detected language: {{file.detectedsourcelang|languagePipe|async}} <img
            *ngIf="file.detectedsourcelang!=file.sourcelang" title="Set detected language as source language."
            (click)="copyDetectedToSource()" src="../../../assets/icons8-copy-50.png"
            style="height: 20px; width: 20px;"/></label>
        </div>
      </div>
      <div style="display: flex">
        <label># words:&nbsp;</label>
        <label>{{file.wordcount}}</label>
        <img *ngIf="file.processType=='MANUAL'" src="../../../assets/edit.png" style="height: 15px; width: 15px;"
             (click)="openFileMetaData()">
      </div>
      <div class="bottomPart">
        <div class="pricing">
          <!--<h4>Price:
            <price [price]="totalPrice"></price>
          </h4>-->
        </div>
        <div class="btnDownload">
          <img (click)="download()" src="../../../assets/download_greenblue.png" title="Download file"/>
        </div>
      </div>
    </div>
  </div>
</div>
