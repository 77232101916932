import {forkJoin, from as observableFrom} from 'rxjs';
import {map, toArray} from 'rxjs/operators';
import {Component, EventEmitter, Input, Output, ViewChild} from "@angular/core";
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {UserService} from "../service/user.service";
import {getProjectStatus, getStatusColor, getUserName, getUserNameFromProject} from "../util/jobutil";
import {Project} from "../dto/project";
import {LanguageService} from "../service/language.service";


@Component({
  selector: 'project-list',
  templateUrl: 'project-list.component.html',
  styleUrls: ['./project-list.component.css']
})
export class ProjectListComponent {
  // TODO: implement alerts
  static defaultCols = ['title', /*'alerts',*/ 'sourceLang', 'targetLangs', 'dueDate', 'user', 'status'];
  static adminCols = ProjectListComponent.defaultCols.concat('company', 'fileCount');
  static invoiceCols = ['title', 'orderedOn', 'user', 'price'];

  private _projects: Project[];
  @Output()
  selectedProject = new EventEmitter<Project>();

  projectRows: ProjectRow[] = undefined;
  projectData = new MatTableDataSource<ProjectRow>();

  @Input()
  availCols = ProjectListComponent.defaultCols;

  @ViewChild(MatSort, {static: false}) sort: MatSort;

  userCache: Map<string, string>;

  constructor(public userService: UserService,
              public languageService: LanguageService) {

  }

  ngOnInit() {
    if (this.userService.isCurrentUserAdmin) {
      this.availCols = ProjectListComponent.adminCols;
    }
  }

  ngAfterViewInit() {
    this.projectData.sort = this.sort;
  }

  @ViewChild(MatSort, {static: false}) set matSort(ms: MatSort) {
    this.sort = ms;
    this.projectData.sort = this.sort;
  }

  get projects() {
    return this._projects;
  }

  @Input()
  set projects(p: Project[]) {
    this._projects = p;
    this.prepProjectData();
  }

  isUserAdmin(): boolean {
    return this.userService.isCurrentUserAdmin;
  }

  private prepProjectData() {
    let start = Date.now();
    this.userCache = new Map<string, string>();
    observableFrom(this.projects).pipe(map(p => {
      let row = new ProjectRow();
      row.id = p.id;
      row.title = p.title;
      row.dueDate = p.duedate;
      row.orderedOn = p.startdate;
      if (p.langpairs != null) {
        row.sourceLang = this.getSourceLang(p);
        row.targetLangCodes = this.getTargetLangs(p);
        if (row.targetLangCodes.length > 0) {
          // Get display name so we can sort correctly
          let obs = [];
          row.targetLangCodes.forEach((tl) => { obs.push(this.languageService.codeToDescription(tl)); });
          forkJoin(obs).subscribe(data => {
            row.targetLangs = data.sort((a:string, b:string) => a.toUpperCase().localeCompare(b.toUpperCase()));
          });
        }
      } else {
        row.sourceLang = '';
        row.targetLangCodes = new Array<string>();
        row.targetLangs=new Array<string>();
      }
      if (p.files != null)
        row.fileCount = p.files.length;
      else row.fileCount = 0;
      row.status = getProjectStatus(p);
      this.determineUser(p, row);
      // todo: also 'determine' company (based on user only if draft?)
      row.company = this.getCompany(p);
      row.price = p.totalprice;
      return row;
    }), toArray(),).subscribe(rows => {
      this.projectRows = rows;
      this.projectData.data = this.projectRows;
      console.debug("Displaying " + this.projectRows.length + " projects took " + (Date.now() - start) + " ms.");
    });
  }

  getProjectStatus(element: any) {
    return getProjectStatus(element as Project);
  }

  determineUser(element: any, row: ProjectRow) {
    let userName = getUserNameFromProject(element, !this.isUserAdmin());
    row.user = userName;
    // Check and populate the cache if applicable
    if (element.requestedby != null && element.requestedby.id != null) {
      if (userName.length == 0) {
        if (this.userCache.has(element.requestedby.id)) {
          row.user = this.userCache.get(element.requestedby.id)
        } else
          this.userService.getUser(element.requestedby.id).subscribe(u => {
            row.user = getUserName(u);
            this.userCache.set(element.requestedby.id, row.user);
          });
      } else if (!this.userCache.has(element.requestedby.id)) {
        this.userCache.set(element.requestedby.id, row.user);
      }
    }
  }

  getCompany(element: any) {
    if (element.requestedby != null) {
      if (element.requestedby.companyName != null)
        return element.requestedby.companyName;
      if (element.requestedby.companyId != null)
        return element.requestedby.companyId;
    }
    return '';
  }

  getSourceLang(element: any) {
    if (element.langpairs != null && element.langpairs.length > 0) {
      return element.langpairs[0].source;
    } else return '';
  }

  getTargetLangs(element: any) {
    let targetLangs = new Array<string>();
    if (element.langpairs != null && element.langpairs.length > 0) {
      element.langpairs.filter((v, k) => v.target != null).forEach((v, k) => targetLangs.push(v.target));
    }
    return targetLangs;
  }

  getStatusColor(element: any) {
    return getStatusColor(element.status)
  }

  selectProject(id: string) {
    let p = this._projects.filter(v => v.id === id);
    if (p.length > 0)
      this.selectedProject.emit(p[0]);
  }

}


// Small data-class that holds the project data for each row
class ProjectRow {
  // Note that for the sorting to work these variable names need to be identical to the 'matColumnDef' property of the
  // column in the Material table
  id: string;
  title: string;
  dueDate;
  orderedOn;
  sourceLang: string;
  targetLangCodes;
  targetLangs;
  fileCount;
  status: string;
  user;
  company;
  price;
}
