import {ChangeDetectorRef, Component, EventEmitter, HostBinding, Input, OnChanges, OnInit, Output} from "@angular/core";
import {ColumnType, DynamicTableHeader, DynamicTableModel} from "./dynamic-table.model";
import {DatePipe} from "@angular/common";

export interface DynamicTableClickEvent {
  header?: DynamicTableHeader;
  data: any;
}

export interface DynamicTableSelectEvent {
  selected: boolean;
  data: any;
}

@Component({
  selector: 'dynamic-table',
  templateUrl: 'dynamic-table.component.html',
  styleUrls: ['dynamic-table.component.css'],
})
export class DynamicTableComponent implements OnInit, OnChanges {

  @Input()
  private tableData: any[];
  @Input()
  private tableHeaders: DynamicTableHeader[];
  @Input()
  public noDataMessage: string;
  @Input()
  public showRowCount: boolean = true;
  @Input()
  @HostBinding('class.dtable-hover')
  public clickable = false;
  @Input()
  public selectable = false;
  @Output()
  public onClick = new EventEmitter<DynamicTableClickEvent>();
  @Output()
  public toggleSelected = new EventEmitter<DynamicTableSelectEvent>();

  public tableModel: DynamicTableModel = {headers: [], data: [], selected: []};
  public masterCheckboxValue = false;

  constructor(private datePipe: DatePipe,
              private cdrRef: ChangeDetectorRef) {
  }

  public ngOnInit(): void {
    this.render(this.tableHeaders, this.tableData);
  }

  public ngOnChanges(): void {
    this.render(this.tableHeaders, this.tableData);
  }

  private render(headers: DynamicTableHeader[], data: any[]): void {
    this.tableHeaders = headers.sort((x, y) => x.index - y.index);
    this.tableModel = {
      headers: headers.filter(h => h.isSelected == null || h.isSelected),
      data: data,
      // todo: provide @Input for 'selected' values
      selected: [],
    };
    this.cdrRef.detectChanges();
  }

  public getCellValue(row: any, column: DynamicTableHeader): any {
    let lastVal = row;
    column.key.split('.').forEach((keyPart: string, index: number) => {
      if (lastVal != null)
        lastVal = lastVal[keyPart];
    });
    if (lastVal != null && this.isDateColumn(column)) {
      return this.datePipe.transform(lastVal, 'y/MM/dd, HH:mm');
    } else return lastVal;
  }

  public isDateColumn(header: DynamicTableHeader): boolean {
    return header.type === ColumnType.DATE;
  }

  public onCellClick(data: any, header: DynamicTableHeader) {
    if (this.clickable)
      this.onClick.emit({header: header, data: data});
  }

  public toggleRowSelected(data: any, index: number) {
    this.tableModel.selected[index] = !this.tableModel.selected[index];
    // If the master checkbox was selected, check whether it should be deselected
    if (this.masterCheckboxValue && !this.tableModel.selected[index])
      this.masterCheckboxValue = false;
    // If the master checkbox was not selected, check whether it should be selected
    else if (!this.masterCheckboxValue && this.tableModel.selected[index])
      this.masterCheckboxValue = this.tableModel.selected.filter(s => !s).length == 0;
    this.toggleSelected.emit({selected: this.tableModel.selected[index], data: data});
  }

  public toggleAllSelected() {
    this.masterCheckboxValue = !this.masterCheckboxValue;
    this.tableData.forEach((row, index) => {
        // Only send event if selection changes
        if (this.tableModel.selected[index] != this.masterCheckboxValue) {
          this.tableModel.selected[index] = this.masterCheckboxValue;
          this.toggleSelected.emit({selected: this.masterCheckboxValue, data: row});
        }
      }
    );
  }

  public hasData(): boolean {
    return this.tableModel.data != null && this.tableModel.data.length > 0;
  }

}
