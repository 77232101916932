import {Component, Input} from "@angular/core";
import {UserService} from "../service/user.service";

@Component({
  selector: "invite-settings",
  templateUrl: './invite.setting.component.html',
  styleUrls: ['./user.settings.component.css']
})
export class InviteSettingComponent {

  @Input()
  user;
  invites;

  constructor(private userService:UserService){
  }

  ngOnInit(){
    //Fetch my invites
    this.userService.getInvites(this.user.id).subscribe(invites => this.invites = invites)
  }

  public inviteState(invite:any){
    if(invite.user!=undefined)
      return "accepted";
    return "pending";
  }

  public sendDate(invite){
    if(invite.senddate!=undefined){
      return new Date(invite.senddate).toLocaleDateString()
    }
    return "";
  }
}
