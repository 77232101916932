import {map, tap} from 'rxjs/operators';
import {Component, OnInit} from "@angular/core";
import {ActivatedRoute, Params} from "@angular/router";


@Component({
  selector: 'root-app',
  template: '<div style="min-height: 95%;">' +
    '<my-app *ngIf="isApp"></my-app>' +
    '<index-main *ngIf="isDrop" [size]="dropValue"></index-main>' +
    '<cost-calc *ngIf="isPriceCalc"></cost-calc>' +
    '</div>',
  styleUrls: ['./app.component.css'],
})
export class RootComponent implements OnInit {

  isDrop = false;
  dropValue = 'large';
  isApp = false;
  isPriceCalc = false;

  constructor(private activatedRoute: ActivatedRoute) {

  }

  ngOnInit() {
    this.activatedRoute.queryParams.pipe(
      tap((params: Params) => {
        if (params.hasOwnProperty('dropOnly')) {
          this.setDropOnly(params.dropOnly);
        } else if (params.hasOwnProperty('priceCalc')) {
          this.setPriceCalc();
        } else {
          this.setMainApp();
        }
      }))
      .subscribe(c => {
        }
      );
  }

  setDropOnly(value: string) {
    this.isDrop = true;
    this.dropValue = value;
    this.isApp = false;
    this.isPriceCalc = false;
  }

  setPriceCalc() {
    this.isDrop = false;
    this.isApp = false;
    this.isPriceCalc = true;
  }

  setMainApp() {
    this.isDrop = false;
    this.isApp = true;
    this.isPriceCalc = false;
  }

}
