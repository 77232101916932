import { Component, Input, ViewChild, AfterViewInit, ElementRef, Output, EventEmitter } from "@angular/core";
import { map, tap } from 'rxjs/operators';
import { UserService } from "../../service/user.service";
import { InvoiceService, PAYMENT_METHOD_BANKTRANSFER, PAYMENT_METHOD_STRIPE } from "../../service/invoice.service";
import { getPaymentImage } from "../../util/userutil";
import { CreditCardComponent } from "./credit-card.component";
import { PricingService } from '../../service/pricing.services';



@Component({
  selector: 'project-payment',
  templateUrl: './project-payment.component.html',
  styleUrls: ['../project.component.css'],
})
export class ProjectPaymentComponent {

  @Input()
  project;

  @Output()
  paymentMethodEmitter = new EventEmitter<string>()

  user: any;
  currentCard = undefined;
  payLaterAllowed = false;
  paypalAllowed = false;
  loaded = false;

  @ViewChild(CreditCardComponent, { static: false })
  ccComponent: CreditCardComponent;



  constructor(private userService: UserService, private invoiceService: InvoiceService, private pricingService: PricingService) {

  }

  ngOnInit() {
    this.initUser();
    if (this.project != null && this.project.paymentmethod === undefined) {
      this.project.paymentmethod = PAYMENT_METHOD_STRIPE;
    }
    this.userService.loginEmitter.subscribe(() => this.initUser());
    this.loaded = true;
  }



  initUser() {
    this.user = this.userService.getCurrentUser();
    if (this.user != undefined) {
      this.checkAllowedPaymentMethods();
      this.fetchPaymentMethod();
    }
  }

  fetchPaymentMethod() {
    this.invoiceService.findPaymentMethod(this.user.id).subscribe(v => {
      if (v != undefined && v.stripe != undefined && v.stripe.sources != undefined && v.stripe.sources.data[0] != undefined) {
        this.currentCard = v.stripe.sources.data[0];
      }
    }
    )
  }

  paymentMethodChange(event: any) {
    if (event.target.checked) {
      this.paymentMethodEmitter.emit(this.project.paymentmethod);
    }
  }

  removeCard() {
    this.currentCard = undefined;
  }

  updateCard(card: any) {
    this.currentCard = card;
  }

  checkAllowedPaymentMethods() {
    this.paypalAllowed = false;
    this.payLaterAllowed = false;
    if (this.user.payLaterAllowed != null) {
      this.payLaterAllowed = this.user.payLaterAllowed === 'true';
    }
    if (this.user.paypalAllowed != null) {
      this.paypalAllowed = this.user.paypalAllowed === 'true';
    }
    this.setDefaultPaymentMethod();
    if (this.user.companyId != null) {
      this.userService.findCompanyInfo(this.user.companyId)
        .pipe(
          tap(c => {
            if (!this.payLaterAllowed) {
              this.payLaterAllowed = c.payLaterAllowed === 'true'
            }
          }),
          tap(c => {
            if (!this.paypalAllowed) {
              this.paypalAllowed = c.paypalAllowed === 'true'
            }
          })
        ).subscribe(() => { this.setDefaultPaymentMethod() });
    }
  }

  setDefaultPaymentMethod() {
    if (this.payLaterAllowed === true) {
      if (this.project != null) {
        this.project.paymentmethod = PAYMENT_METHOD_BANKTRANSFER;
      }
    }
  }

  storePaymentDetails(): Promise<any> {
    if (this.ccComponent !== undefined && (this.project.paymentmethod === PAYMENT_METHOD_STRIPE || this.project.paymentmethod == null)) {
      return this.ccComponent.storeCreditCard();
    } else {
      return Promise.resolve();
    }
  }

  static getPaymentImageUrl(paymentMethod: string, disabled: boolean = false) {
    return './../../assets/paymentmethod/' + getPaymentImage(paymentMethod, disabled);
  }
}
