<div class="reviewcreate" *ngIf="review">
  <div>
    <h3>Post review for:</h3>
    <project-task *ngIf='task != null' [task]="task" [state]="'feedback'"></project-task>
  </div>
  <div class="scoring">
    <h5>Please enter a score</h5>
    <div class="stars" *ngIf="review.scores">
      <div *ngFor="let score of review.scores">
        <label>{{score.category.name}}</label>
        <rating [rating]="score.rate" (onStarSelected)="score.rate = $event"></rating>
      </div>
    </div>
  </div>
  <form>
    <div class="post">
      <h5>Please write a few words to clarify the given scores.</h5>
      <textarea name="comment" placeholder="Your review goes here" [(ngModel)]="review.comment"></textarea>
      <p>Please note that your feedback will be publicly available on the profile page of the translator.<br/>
        Your feedback will help other customers to choose a translator.<br/>
        If you desire you can hide your name from the feedback.</p>
      <div style="display: flex;"><input type="checkbox" [(ngModel)]="anonymous" [ngModelOptions]="{standalone: true}" id="chkanonymous">
        <label for="chkanonymous" style="color: black; font-size: 13px;">make this feedback anonymous</label></div>
    </div>
    <button type="submit" class="btnSmall" style="float: right" (click)="postReview()">
      <span *ngIf="!isExistingReview()">Post review</span>
      <span *ngIf="isExistingReview()">Update review</span>
    </button>
  </form>
</div>
