<div class="yourpricing">
  <div *ngIf="pricing" class="savings">
    <div *ngIf="this.userService.isLoggedIn()">
      <div *ngIf="project.langpairs == null">
        Please select one or more languages to see your price.
      </div>
      <div *ngIf="pricing.totalSavings() > 0">
        You save
        <strong>
          <price [price]="pricing.totalSavings()"></price>
        </strong><br/>
        <small>thanks to your translation memory<span
          *ngIf="pricing.mtsaving > 0"> and machine translation</span>.</small>
      </div>
    </div>
    <div *ngIf="!this.userService.isLoggedIn()">
      <strong>Want to see how much you can save?</strong><br/>
      <span (click)="openJoin()" class="loginLink">Join</span> or
      <span (click)="openSignIn()" class="loginLink">sign in</span>
    </div>
  </div>
  <div *ngIf="project != undefined && project.langpairs != undefined" class="langpairsDetails">
    <div *ngFor="let lp of project.langpairs"
         style="display: flex; width: 100%;" class="langpairDetails">
      <div style="width: 100%;">
        <strong>{{ lp.source | languagePipe | async }} » {{ lp.target | languagePipe | async }}</strong>
      </div>
      <div *ngIf="hasPrice(toLangPairString(lp.source, lp.target))" style="text-align: right;">
        <price [price]="getPrice(toLangPairString(lp.source, lp.target), false)"></price>
      </div>
    </div>
  </div>
  <div class="total">
    <div style="display: flex; width: 100%; justify-content: space-between;">
      <div>Total:</div>
      <div *ngIf="pricing != undefined && pricing.taskCount > 0">
        <price [price]="totalWithTasksOnly()"></price>
      </div>
    </div>
    <div *ngIf="pricing != undefined && pricing.vatRate > 0" style="display: flex; width: 100%; justify-content: space-between;">
      <div>
        VAT incl. <span style="font-size: 12px;">({{pricing.vatRate * 100}}%)</span>:
      </div>
      <div *ngIf="pricing != undefined && pricing.taskCount > 0">
        <price [price]="totalInclVATWithTaskOnly()"></price>
      </div>
    </div>
    <div class="preview" *ngIf="allowPriceDownload">
      <div (click)="downloadPriceOverview()" title="Download price preview PDF">
        <img src="../../../assets/download_icon.svg" />
      </div>
    </div>
  </div>
</div>
