/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./lang-select.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./lang-select-list.component.ngfactory";
import * as i4 from "./lang-select-list.component";
import * as i5 from "../service/language.service";
import * as i6 from "./language.pipe";
import * as i7 from "./lang-select.component";
var styles_LangSelectComponent = [i0.styles];
var RenderType_LangSelectComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LangSelectComponent, data: {} });
export { RenderType_LangSelectComponent as RenderType_LangSelectComponent };
function View_LangSelectComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "languageButton"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "languageDiv"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeLanguage(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵppd(4, 1), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "remove"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"]))], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 5).transform(i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent, 0), _v.context.$implicit)))); _ck(_v, 3, 0, currVal_0); }); }
function View_LangSelectComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["choose language(s)"]))], null, null); }
function View_LangSelectComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["add language(s)"]))], null, null); }
function View_LangSelectComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LangSelectComponent_4)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LangSelectComponent_5)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.selectedLangs.length === 0); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.selectedLangs.length > 0); _ck(_v, 4, 0, currVal_1); }, null); }
function View_LangSelectComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["choose language"]))], null, null); }
function View_LangSelectComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "languageListDialog"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "lang-select-list", [["style", "flex: 1 1 auto;"]], null, [[null, "eventEmitter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("eventEmitter" === en)) {
        var pd_0 = (_co.onLanguageUpdate($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_LangSelectListComponent_0, i3.RenderType_LangSelectListComponent)), i1.ɵdid(2, 4308992, null, 0, i4.LangSelectListComponent, [i5.LanguageService], { selectedLangs: [0, "selectedLangs"], dialect: [1, "dialect"] }, { eventEmitter: "eventEmitter" }), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["style", "flex: 0 0 auto; padding: 2px 0px 2px 15px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["style", "cursor: pointer;"], ["title", "Close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeLanguageChooser() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedLangs; var currVal_1 = _co.dialect; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_LangSelectComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { "languageButton": 0, "openlangpick": 1, "langButtonPulse": 2 }), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "languageChooseDiv"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openLanguageChooser() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LangSelectComponent_3)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LangSelectComponent_6)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LangSelectComponent_7)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, true, true, _co.doPulse()); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.single === false); _ck(_v, 6, 0, currVal_1); var currVal_2 = ((_co.single === true) && ((_co.selectedLangs === undefined) || (_co.selectedLangs.length === 0))); _ck(_v, 8, 0, currVal_2); var currVal_3 = _co.langDialogOpen; _ck(_v, 10, 0, currVal_3); }, null); }
export function View_LangSelectComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i6.LanguagePipe, [i5.LanguageService]), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "langSelectMain"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["style", "display: flex; flex-wrap: wrap;"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LangSelectComponent_1)), i1.ɵdid(4, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LangSelectComponent_2)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedLangs; _ck(_v, 4, 0, currVal_0); var currVal_1 = ((_co.selectedLangs.length === 0) || (_co.single === false)); _ck(_v, 6, 0, currVal_1); }, null); }
export function View_LangSelectComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "lang-select", [], null, null, null, View_LangSelectComponent_0, RenderType_LangSelectComponent)), i1.ɵdid(1, 114688, null, 0, i7.LangSelectComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LangSelectComponentNgFactory = i1.ɵccf("lang-select", i7.LangSelectComponent, View_LangSelectComponent_Host_0, { selectedLangs: "selectedLangs", single: "single", dialect: "dialect" }, { eventEmitter: "eventEmitter" }, []);
export { LangSelectComponentNgFactory as LangSelectComponentNgFactory };
