/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../project.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../util/filesize.pipe";
import * as i4 from "@angular/material/dialog";
import * as i5 from "./documentation-pdf-dialog";
import * as i6 from "@angular/material/snack-bar";
import * as i7 from "../../service/project.service";
var styles_DocumentationPDFDialog = [i0.styles];
var RenderType_DocumentationPDFDialog = i1.ɵcrt({ encapsulation: 0, styles: styles_DocumentationPDFDialog, data: {} });
export { RenderType_DocumentationPDFDialog as RenderType_DocumentationPDFDialog };
function View_DocumentationPDFDialog_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["(", ")"])), i1.ɵppd(2, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent, 0), _co.docPdf.size)); _ck(_v, 1, 0, currVal_0); }); }
function View_DocumentationPDFDialog_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "label", [["style", "font-size: 15px; padding: 3px;"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocumentationPDFDialog_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.docPdf.size; _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.docPdf.name; _ck(_v, 1, 0, currVal_0); }); }
function View_DocumentationPDFDialog_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "label", [["class", "buttonLabel"], ["for", "uploadFile"], ["style", "color: white;"], ["title", "Select another file"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Change"]))], null, null); }
function View_DocumentationPDFDialog_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["style", "color: red;"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errorMsg; _ck(_v, 1, 0, currVal_0); }); }
function View_DocumentationPDFDialog_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "label", [["class", "buttonLabel confirm"], ["for", "uploadFile"], ["style", "color: white;"], ["title", "Select a PDF file"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Select a PDF"]))], null, null); }
function View_DocumentationPDFDialog_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Remove PDF"]))], null, null); }
function View_DocumentationPDFDialog_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["alt", "Removing..."], ["src", "../../../assets/loading_small_orb_whiteonblue.gif"], ["style", "width: 15px;"]], null, null, null, null, null))], null, null); }
function View_DocumentationPDFDialog_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "label", [["class", "buttonLabel confirm"], ["style", "display: flex;"], ["title", "Remove uploaded PDF"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeDocPdf() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocumentationPDFDialog_7)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocumentationPDFDialog_8)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isRemoving; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.isRemoving; _ck(_v, 4, 0, currVal_1); }, null); }
function View_DocumentationPDFDialog_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "confirm"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.upload() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Upload & close "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isDocPdfFile(); _ck(_v, 0, 0, currVal_0); }); }
export function View_DocumentationPDFDialog_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i3.FilesizePipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 26, "mat-dialog-content", [["class", "projectDialog mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(2, 16384, null, 0, i4.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Upload a PDF version of file"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h3", [["style", "margin-bottom: 25px;"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 3, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["This PDF file will be available for the translator/reviser and provides them with greater context on the content."])), (_l()(), i1.ɵeld(9, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" This will improve the quality of the translation."])), (_l()(), i1.ɵeld(11, 0, null, null, 5, "div", [["style", "display: flex; justify-content: center; align-items: center; margin: 15px 0 5px 0; width: 100%;"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocumentationPDFDialog_1)), i1.ɵdid(13, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocumentationPDFDialog_3)), i1.ɵdid(15, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(16, 0, null, null, 0, "input", [["accept", ".pdf, application/pdf"], ["id", "uploadFile"], ["placeholder", "Upload PDF"], ["style", "display: none;"], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.selectFile($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocumentationPDFDialog_4)), i1.ɵdid(18, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(19, 0, null, null, 8, "div", [["class", "buttons"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocumentationPDFDialog_5)), i1.ɵdid(21, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocumentationPDFDialog_6)), i1.ɵdid(23, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocumentationPDFDialog_9)), i1.ɵdid(25, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(26, 0, null, null, 1, "button", [["class", "cancel"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dialogRef.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancel"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.docPdf; _ck(_v, 13, 0, currVal_1); var currVal_2 = _co.isDocPdfFile(); _ck(_v, 15, 0, currVal_2); var currVal_3 = _co.errorMsg; _ck(_v, 18, 0, currVal_3); var currVal_4 = !_co.docPdf; _ck(_v, 21, 0, currVal_4); var currVal_5 = (_co.docPdf && !_co.isDocPdfFile()); _ck(_v, 23, 0, currVal_5); var currVal_6 = _co.isDocPdfFile(); _ck(_v, 25, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.srcFile.name; _ck(_v, 6, 0, currVal_0); }); }
export function View_DocumentationPDFDialog_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "documentation-pdf-dialog", [], null, null, null, View_DocumentationPDFDialog_0, RenderType_DocumentationPDFDialog)), i1.ɵdid(1, 114688, null, 0, i5.DocumentationPDFDialog, [i4.MatDialogRef, i4.MAT_DIALOG_DATA, i6.MatSnackBar, i7.ProjectService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DocumentationPDFDialogNgFactory = i1.ɵccf("documentation-pdf-dialog", i5.DocumentationPDFDialog, View_DocumentationPDFDialog_Host_0, {}, {}, []);
export { DocumentationPDFDialogNgFactory as DocumentationPDFDialogNgFactory };
