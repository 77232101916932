import {Component, Input, OnChanges, OnInit, SimpleChanges, OnDestroy, Output} from "@angular/core";
import {debounceTime, switchMap} from 'rxjs/operators';
import {DetailedPricing} from "../../dto/job";
import {PricingService} from "../../service/pricing.services";
import {Project} from "../../dto/project";
import {UserService} from "../../service/user.service";
import {LogInService} from "../../service/login.service";
import * as FileSaver from "file-saver";
import { Subscription } from 'rxjs';
import { SubscriptionHandler } from '../../util/subscription.handler';
import { toLangPairString } from '../../util/jobutil';
import { EventEmitter } from '@angular/core';


@Component({
  selector: 'project-pricing-overview',
  templateUrl: './project-pricing-overview.component.html',
  styleUrls: ['../project.component.css'],
})
export class ProjectPricingOverviewComponent implements OnInit, OnChanges, OnDestroy {

  @Input()
  project: Project;
  @Input()
  reloadPriceTrigger;
  @Input()
  allowPriceDownload = false;

  pricing: DetailedPricing;

  @Output()
  priceCalculatedEmitter = new EventEmitter<DetailedPricing>()

  subscriptionHandler = new SubscriptionHandler(this);

  constructor(private pricingService: PricingService,
              public userService: UserService,
              private loginService: LogInService) {

  }

  ngOnInit() {
    this.updateTotalPrice();
    const subscription = this.pricingService.enrollForProject(this.project.id)
      .pipe(
        debounceTime(500),
        switchMap(p => this.pricingService.getTotalProjectPrice(this.project.id))
      ).subscribe(p => this.setPricing(p));
    this.subscriptionHandler.push(subscription);
  }

  ngOnDestroy() {
  }

  private updateTotalPrice() {
    // The pricing is unfortunately calculated twice each time: once when the reloadPriceTrigger changes and once when
    // the event from the websocket comes in
    // We should only need the event, but since it's so slow to come in, I've left it as is to ensure the price on the
    // page gets updated asap
    // TODO: make sure the pricing is only calculated when it needs to be
    this.pricingService.getTotalProjectPrice(this.project.id)
      .subscribe(p => this.setPricing(p));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.project != null) {
      this.updateTotalPrice();
    } else if (changes.reloadPriceTrigger != null) {
      this.updateTotalPrice();
    }
  }

  hasPrice(langPair: string) {
    return this.pricing != undefined && this.pricing.langPairDetails != undefined &&
      this.pricing.langPairDetails[langPair] != undefined &&
      this.pricing.langPairDetails[langPair].taskCount > 0;
  }

  toLangPairString(source: string, target: string){
    return toLangPairString({source: source, target: target});
  }

  getPrice(langPair: string, inclCredit: boolean = true) {
    if (this.hasPrice(langPair)) {
      return this.pricing.langPairDetails[langPair].total(inclCredit);
    } else return 0;
  }

  setPricing(pricing: DetailedPricing) {
    this.pricing = pricing;
    this.priceCalculatedEmitter.emit(pricing);
  }

  openJoin() {
    this.loginService.openJoinDialog('pdetail/' + this.project.id);
  }

  openSignIn() {
    this.loginService.openSignInPopup("pdetail/" + this.project.id);
  }

  totalWithTasksOnly(): number {
    if (this.pricing.langPairDetails != undefined && this.pricing.taskCount > 0) {
      return Object.keys(this.pricing.langPairDetails)
        .map((v, i, a) => v)
        .map(v => this.getPrice(v))
        .reduce((p1, p2) => p1 + p2, 0)
    } else return 0;
  }

  totalInclVATWithTaskOnly(): number {
    return this.totalWithTasksOnly() * (this.pricing.vatRate + 1)
  }

  downloadPriceOverview() {
    this.pricingService.downloadPricingPdf(this.project.id)
    .subscribe(f => saveAs(f, this.project.title + '.pdf')
    );
  }
}
