export class Project {
  id: string;
  title: string;
  creationdate: string;
  startdate: string;
  files: any[];
  service: string;
  langpairs: any[];
  requestedby: any;
  duedate: any;
  totalprice: number;
  canceled: any;
  chargeid: string;
  checkoutarchs: any[];
  paymentmethod: string;
  // project shares need to be instantiated as undefined, so we know when to apply the default shares
  shares: any[] = undefined;
  isQuote = false;
}

// TODO: get rid of this flag once the quote functionality is ready for production
export const ENABLE_QUOTE = false;
