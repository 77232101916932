import {Observable, of as observableOf} from 'rxjs';
import {Injectable} from "@angular/core";
import {ACTIVITIES, SERVICES, ACTIVITY_EDITING, EDITING_DEPENDENCIES, DEFAULT_DEPENDENCIES} from "../mock-activities";

/**
 * Created by jefv on 27/09/2017.
 */
@Injectable()
export class ActivityService {

  getActivities(): Observable<string[]> {
    return observableOf(ACTIVITIES);
  }

  getServices(): Observable<string[]> {
    return observableOf(SERVICES)
  }

  getDependenciesForActivity(activity: string): Observable<string[]> {
    if (ACTIVITY_EDITING === activity) {
      return observableOf(EDITING_DEPENDENCIES);
    } else {
      return observableOf(DEFAULT_DEPENDENCIES);
    }
  }
}
