<div *ngIf="uploadedFiles != undefined && uploadedFiles.length > 0" class="addMoreSourceFileDiv">
  <file-add [label]="'Add more files'" [infoText]="'That need to be translated'" (onFileUpload)="uploadFiles($event)" [id]="'fileadd'"></file-add>
</div>
<div *ngIf="uploadedFiles == undefined || uploadedFiles.length === 0" class="addASourceFileDiv">
  <label class="addFilesCircle" for="fileupload">+</label>
  <div class="addFileLabelDiv">
    <label for="fileupload"><strong>Add a file</strong></label>
    <input id="fileupload" type="file" style="display: none;" multiple
           (change)="uploadFiles($event)" #fileupload/>
    <p>Word, ppt, pages, ... <a href="https://about.lilo.global/formats" target="_blank" style="display:none ">see all
      formats</a></p><!--Display none for now because we don't have the page yet-->
  </div>
</div>
