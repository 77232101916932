import {Component, Input} from "@angular/core";
import {UserService} from "../service/user.service";
import {MatSnackBar} from "@angular/material/snack-bar";


@Component({
  selector: "availability-settings",
  templateUrl: './availability.setting.component.html',
  styleUrls: ['./user.settings.component.css']
})
export class AvailabilitySettingComponent {

  @Input()
  user;
  editMode = "none";

  constructor(private snackbar: MatSnackBar,
              private userService: UserService) {

  }

  ngOnInit() {
  }

  addUnavailability(unavailability: any) {
    if (!this.user.unavailabilities)
      this.user.unavailabilities = [];
    this.user.unavailabilities.push(unavailability);
    this.saveUnavailabilities()
  }

  remove(unavailability: any) {
    let index = this.user.unavailabilities.indexOf(unavailability, 0);
    if (index > -1)
      this.user.unavailabilities.splice(index, 1);
  }

  saveUnavailabilities() {
    this.setEditMode("none");
    this.userService.saveUnavailabilities(this.user).subscribe(
      next => {
        this.snackbar.open("Unavailabilities saved", '', {
          duration: 2000
        })
      },
      error => console.error("Error saving unavailabilities for " + this.user.id, error)
    )
  }

  sortedUnavailabilities() {
    function isFutureUnavailability(a: any): boolean {
      if (a && a.to) {
        return new Date(a.to).getTime() >= new Date().getTime();
      }
      return false;
    }
    if (this.user && this.user.unavailabilities) {
      return this.user.unavailabilities
        .filter(a => isFutureUnavailability(a))
        .sort((a, b) => {
          return a.from > b.from;
        });
    }
    return;
  }

  setEditMode(mode: string) {
    this.editMode = mode
  }

  isEditMode() {
    return this.editMode == "edit"
  }

  isAddMode() {
    return this.editMode == "add"
  }
}
