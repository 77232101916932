import {Component, Input, OnInit, ViewChild} from "@angular/core";
import {ChatService} from '../../service/chat.service';
import {UserService} from '../../service/user.service';
import { ChatComponent } from '../../chatcomponent/chat.component';


@Component({
  selector: 'project-chat',
  templateUrl: './project-chat.component.html',
  styleUrls: ['../project.component.css'],
})
export class ProjectChatComponent implements OnInit {

  @Input()
  projectId: string;

  unreadCount = undefined;
  isChatExpanded = false;


  @ViewChild(ChatComponent, { static: false }) chatComponent: ChatComponent;

  chatMarkAsRead() {
    if (this.chatComponent) {
      this.chatComponent.markAllAsRead();
    }
  }

  constructor(private userService: UserService,
              private chatService: ChatService) {

  }

  ngOnInit() {
    this.setUser();
    this.userService.loginEmitter.subscribe(_ => this.setUser());
  }

  setUser() {
    if (this.userService.isLoggedIn()) {
      this.chatService.fetchUnreadChatCount(this.projectId, this.userService.getCurrentUser().id)
        .subscribe(unreadCount => {
          this.unreadCount = unreadCount;
          if (this.unreadCount > 0) {
            this.isChatExpanded = true;
          }
        })
    }
  }

  toggleCollapse() {
    this.isChatExpanded = !this.isChatExpanded;
    if (this.isChatExpanded) {
      this.chatMarkAsRead()
    }
  }
}
