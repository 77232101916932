/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./file-info.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/progress-bar/typings/index.ngfactory";
import * as i3 from "@angular/material/progress-bar";
import * as i4 from "@angular/platform-browser/animations";
import * as i5 from "@angular/common";
import * as i6 from "../../util/language.pipe";
import * as i7 from "../../service/language.service";
import * as i8 from "../../util/frontpage.component.ngfactory";
import * as i9 from "../../util/frontpage.component";
import * as i10 from "../../service/file.service";
import * as i11 from "./language-selector.component.ngfactory";
import * as i12 from "./language-selector.component";
import * as i13 from "./file-info.component";
import * as i14 from "../../service/analysis.service";
import * as i15 from "../../service/pricing.services";
import * as i16 from "../../service/project.service";
import * as i17 from "../../service/logger.service";
import * as i18 from "./upload-data.service";
import * as i19 from "@angular/material/snack-bar";
import * as i20 from "@angular/material/dialog";
var styles_FileInfoComponent = [i0.styles];
var RenderType_FileInfoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FileInfoComponent, data: {} });
export { RenderType_FileInfoComponent as RenderType_FileInfoComponent };
function View_FileInfoComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "fileoverlay"], ["title", "Uploading..."]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", "%"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "mat-progress-bar", [["aria-valuemax", "100"], ["aria-valuemin", "0"], ["class", "mat-progress-bar"], ["mode", "determinate"], ["role", "progressbar"]], [[1, "aria-valuenow", 0], [1, "mode", 0], [2, "_mat-animation-noopable", null]], null, null, i2.View_MatProgressBar_0, i2.RenderType_MatProgressBar)), i1.ɵdid(4, 4374528, null, 0, i3.MatProgressBar, [i1.ElementRef, i1.NgZone, [2, i4.ANIMATION_MODULE_TYPE], [2, i3.MAT_PROGRESS_BAR_LOCATION]], { value: [0, "value"], mode: [1, "mode"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.uploadPercent; var currVal_5 = "determinate"; _ck(_v, 4, 0, currVal_4, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.uploadPercent; _ck(_v, 2, 0, currVal_0); var currVal_1 = (((i1.ɵnov(_v, 4).mode === "indeterminate") || (i1.ɵnov(_v, 4).mode === "query")) ? null : i1.ɵnov(_v, 4).value); var currVal_2 = i1.ɵnov(_v, 4).mode; var currVal_3 = i1.ɵnov(_v, 4)._isNoopAnimation; _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3); }); }
function View_FileInfoComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "label", [["style", "color: lightcoral"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Unable to perform analysis on this file."]))], null, null); }
function View_FileInfoComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["src", "../../../assets/icons8-copy-50.png"], ["style", "height: 20px; width: 20px;"], ["title", "Set detected language as source language."]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.copyDetectedToSource() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
function View_FileInfoComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "label", [], [[4, "color", null]], null, null, null, null)), (_l()(), i1.ɵted(2, null, [" Detected language: ", " "])), i1.ɵppd(3, 1), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileInfoComponent_4)), i1.ɵdid(6, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.file.detectedsourcelang != _co.file.sourcelang); _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.file.detectedsourcelang != _co.file.sourcelang) ? "lightcoral" : null); _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 4).transform(i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent, 0), _co.file.detectedsourcelang)))); _ck(_v, 2, 0, currVal_1); }); }
function View_FileInfoComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["src", "../../../assets/edit.png"], ["style", "height: 15px; width: 15px;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openFileMetaData() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
export function View_FileInfoComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i6.LanguagePipe, [i7.LanguageService]), (_l()(), i1.ɵeld(1, 0, null, null, 31, "div", [["class", "infoDiv"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["style", "display: flex; flex-direction:row-reverse; justify-content: space-between;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "fileremove"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["class", "btnRemove"], ["src", "../../../assets/delete.png"], ["title", "Remove file"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeFile() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "fileicon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "frontpage", [], null, null, null, i8.View_FrontpageComponent_0, i8.RenderType_FrontpageComponent)), i1.ɵdid(7, 245760, null, 0, i9.FrontpageComponent, [i10.FileService], { fileName: [0, "fileName"], pId: [1, "pId"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileInfoComponent_1)), i1.ɵdid(9, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 22, "div", [["class", "fileinfo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "h4", [["style", "margin-bottom: 5px;"]], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, ["", " "])), (_l()(), i1.ɵeld(13, 0, null, null, 0, "img", [["class", "statusImage"], ["src", "../../../assets/check.png"]], [[4, "display", null], [8, "title", 0]], null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 18, "div", [["style", "width:100%;"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileInfoComponent_2)), i1.ɵdid(16, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(17, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 1, "language-selector", [["title", "Language:\u00A0"]], null, [[null, "onLanguageSelect"], [null, "selectedLangChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onLanguageSelect" === en)) {
        var pd_0 = (_co.updateFileLang($event, true) !== false);
        ad = (pd_0 && ad);
    } if (("selectedLangChange" === en)) {
        var pd_1 = ((_co.file.sourcelang = $event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i11.View_LanguageSelectorComponent_0, i11.RenderType_LanguageSelectorComponent)), i1.ɵdid(19, 114688, null, 0, i12.LanguageSelectorComponent, [i7.LanguageService], { title: [0, "title"], selectedLang: [1, "selectedLang"] }, { onLanguageSelect: "onLanguageSelect" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileInfoComponent_3)), i1.ɵdid(21, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(22, 0, null, null, 6, "div", [["style", "display: flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(23, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["# words:\u00A0"])), (_l()(), i1.ɵeld(25, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(26, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileInfoComponent_5)), i1.ɵdid(28, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(29, 0, null, null, 3, "div", [["class", "bottomPart"]], null, null, null, null, null)), (_l()(), i1.ɵeld(30, 0, null, null, 0, "div", [["class", "pricing"]], null, null, null, null, null)), (_l()(), i1.ɵeld(31, 0, null, null, 1, "div", [["class", "btnDownload"]], null, null, null, null, null)), (_l()(), i1.ɵeld(32, 0, null, null, 0, "img", [["src", "../../../assets/download_greenblue.png"], ["title", "Download file"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.download() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.file.name; var currVal_1 = _co.pId; _ck(_v, 7, 0, currVal_0, currVal_1); var currVal_2 = _co.isFileUploading(); _ck(_v, 9, 0, currVal_2); var currVal_6 = (_co.file.processType == "MANUAL"); _ck(_v, 16, 0, currVal_6); var currVal_7 = "Language:\u00A0"; var currVal_8 = _co.file.sourcelang; _ck(_v, 19, 0, currVal_7, currVal_8); var currVal_9 = (_co.file.detectedsourcelang != undefined); _ck(_v, 21, 0, currVal_9); var currVal_11 = (_co.file.processType == "MANUAL"); _ck(_v, 28, 0, currVal_11); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.file.name; _ck(_v, 12, 0, currVal_3); var currVal_4 = _co.getStatusImageDisplay(); var currVal_5 = _co.getStatusTooltip(); _ck(_v, 13, 0, currVal_4, currVal_5); var currVal_10 = _co.file.wordcount; _ck(_v, 26, 0, currVal_10); }); }
export function View_FileInfoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "file-info", [], null, null, null, View_FileInfoComponent_0, RenderType_FileInfoComponent)), i1.ɵdid(1, 245760, null, 0, i13.FileInfoComponent, [i14.AnalysisService, i15.PricingService, i10.FileService, i16.ProjectService, i17.LoggerService, i18.UploadDataService, i19.MatSnackBar, i20.MatDialog], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FileInfoComponentNgFactory = i1.ɵccf("file-info", i13.FileInfoComponent, View_FileInfoComponent_Host_0, { file: "file", pdf4source: "pdf4source", pId: "pId" }, { remover: "remover", languageUpdate: "languageUpdate" }, []);
export { FileInfoComponentNgFactory as FileInfoComponentNgFactory };
