import {Component, Input, OnInit} from "@angular/core";
import {Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';

import {ProjectService} from "../../service/project.service";
import {Project} from "../../dto/project";
import {LanguageService} from "../../service/language.service";
import {UserService} from '../../service/user.service';
import {getUserName} from "../../util/jobutil";


@Component({
  selector: 'project-info',
  templateUrl: './project-info.component.html',
  styleUrls: ['../project.component.css'],
})
export class ProjectInfoComponent implements OnInit {

  @Input()
  project: Project;

  sourcelang = undefined;
  targetlangs = undefined;
  editDueDate = false;
  minDueDate = new Date();
  // Subject is implemented to be able to put a debounce on the "title" input field so that
  // the server's update calls are limited to once every second (see ngOnInit)
  private titleChanges: Subject<any>;


  constructor(private projectService: ProjectService,
              private languageService: LanguageService,
              public userService: UserService) {

  }

  ngOnInit() {
    if (this.project != null) {
      this.initTitleFieldUpdate();
      if (this.project.langpairs != null && this.project.langpairs.length > 0) {
        this.sourcelang = this.project.langpairs[0].source;
        Observable.from(this.project.langpairs)
          .pipe(
            map(lp => lp.target),
          )
          .concatMap(target => this.languageService.codeToDescription(target))
          .scan((t1, t2) => t1 + ", " + t2)
          .subscribe(targetLangs => this.targetlangs = targetLangs);
      }
    }
  }

  titleChange($event) {
    this.titleChanges.next($event);
  }

  private initTitleFieldUpdate() {
    this.titleChanges = new Subject<any>();
    // Debounce so it only updates every second instead of every keystroke
    this.titleChanges.asObservable().debounceTime(1000).subscribe(v =>
      this.projectService.setTitle(this.project).subscribe()
    );
  }

  saveShares(selectedColleagues: any[]) {
    this.project.shares = selectedColleagues;
    this.projectService.setShares(this.project.id, this.project.shares).subscribe();
  }

  toggleEditDueDate() {
    this.editDueDate = !this.editDueDate;
    console.debug(this.editDueDate);
  }

  setProjectDueDate() {
    this.projectService.setDueDate(this.project).subscribe();
  }

  getRequesterName() {
    return getUserName(this.project.requestedby);
  }
}
