import {Component, Input, OnDestroy} from "@angular/core";
import {Project} from "../dto/project";
import {ProjectService} from "../service/project.service";
import {getProjectStatus, getStatusColor} from "../util/jobutil";
import {FileService} from "../service/file.service";
import {filter, map} from 'rxjs/operators';
import {animate, style, transition, trigger} from '@angular/animations';
import { Subscription } from 'rxjs';
import { SubscriptionHandler } from '../util/subscription.handler';


@Component({
  selector: 'project-card',
  templateUrl: './project-card.component.html',
  styleUrls: ['./project-card.component.css'],
  animations: [trigger('fadeInOut', [
    transition(':enter', [
      style({opacity: 0}),
      animate('0.5s ease-in', style({opacity: 1}))]),
    transition(':leave', [
      animate('0.5s ease-out', style({opacity: 0}))])
  ])]
})
export class ProjectCardComponent implements OnDestroy {

  @Input() project: Project;

  sourceLanguage: string;
  targetLanguages: string[];
  files: string[];
  filePageStack: string[] = [];
  frontpageImage: string = null;
  docTypeImage: string = null;
  status = "Active";

  subscriptionHandler = new SubscriptionHandler(this);

  constructor(private projectService: ProjectService,
              private fileService: FileService) {

  }

  ngOnInit() {
    this.files = this.projectService.getFileNames(this.project, "SOURCE");
    this.status = getProjectStatus(this.project);
    this.sourceLanguage = this.projectService.getSourceLanguage(this.project);
    this.targetLanguages = this.projectService.getTargetLanguages(this.project);
    if (this.targetLanguages != null)
      this.targetLanguages.sort((a, b) => a.toUpperCase().localeCompare(b.toUpperCase()));
    if (this.files != null && this.files.length > 0) {
      let firstFile = this.files[0];
      this.filePageStack = this.createFileStack(this.files);
      this.docTypeImage = this.getDoctypeImage(firstFile);
      // Try to get a frontpage image, but only go as far as trying 5 files to prevent taxing the system too much
      // Most projects won't contain more than 5 anyway
      this.getAnyCardFrontPage(this.files.slice(0, 6));
      const frontPageSubscription = this.fileService.enrollForProject(this.project.id)
        .pipe(
          map(data => {
            return data
          }),
          filter(data => data.action == "FRONTPAGE_IMG" && this.frontpageImage == null))
        .subscribe(data => {
          this.getCardFrontPage(data.filename)
        });
      this.subscriptionHandler.push(frontPageSubscription);
    }
  }

  ngOnDestroy() {

  }

  getStatusColor() {
    return getStatusColor(getProjectStatus(this.project))
  }

  async getAnyCardFrontPage(files) {
    // Loop through the files and try to get the frontpage image sequentially
    // Since we only show on image on the project card, there is no need to retrieve more than one
    for (const fileName of files) {
      try {
        if (this.frontpageImage === null && fileName != null) {
          this.frontpageImage = await this.fileService.getFrontpage(this.project.id, fileName).toPromise();
        }
      } catch (e) {
        // A 404 error will occur here if the frontpage does not exist
      }
    }
  }

  getCardFrontPage(fileName: string) {
    if (fileName != null) {
      this.fileService.getFrontpage(this.project.id, fileName)
        .subscribe(next => {
            this.frontpageImage = next;
          }, error => {
            // No frontpage found
          }
        );
    }
  }

  createFileStack(sourceFiles) {
    if (sourceFiles.length > 1) {
      return sourceFiles.slice(1, 5);
    } else return [];
  }

  public getDoctypeImage(fileName: string): string {
    // Most images found at https://www.flaticon.com/packs/file-type-set
    let basePath = "assets/doctype/";
    return basePath + fileName.substring(fileName.lastIndexOf(".") + 1) + ".png";
  }

  public getUnknownDoctypeImage() {
    return "assets/doctype/unknown.png";
  }

  getTargetLangDisplay() {
    let langDisplay: string = "--";
    if (this.targetLanguages != null && this.targetLanguages.length > 0) {
      langDisplay = this.targetLanguages.slice(0, 3).join(", ");
      if (this.targetLanguages.length > 3)
        langDisplay += " & " + (this.targetLanguages.length - 3) + " more"
    }
    return langDisplay;
  }

}
