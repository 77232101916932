/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./frontpage.component";
import * as i3 from "../service/file.service";
var styles_FrontpageComponent = [".doctypeimage[_ngcontent-%COMP%] {height: 100%; width: 100%; position: absolute; left: 0; bottom: 0; box-shadow: 0 0 6px #00000029 !important;}", ".frontpage[_ngcontent-%COMP%] {height: 100%; width: 100%; position: absolute; left: 0; bottom: 0; box-shadow: 0 0 6px #00000029 !important;}", ".fileimage[_ngcontent-%COMP%] {position: relative;}"];
var RenderType_FrontpageComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_FrontpageComponent, data: {} });
export { RenderType_FrontpageComponent as RenderType_FrontpageComponent };
function View_FrontpageComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "img", [["alt", "Front page image"], ["class", "frontpage"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.frontpageImage; _ck(_v, 0, 0, currVal_0); }); }
function View_FrontpageComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "img", [["alt", "File type image"], ["class", "doctypeimage"]], [[8, "src", 4]], [[null, "error"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("error" === en)) {
        var pd_0 = (_co.handleDoctypeImageNotFound() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", _co.doctypeImage, ""); _ck(_v, 0, 0, currVal_0); }); }
export function View_FrontpageComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "fileimage"]], [[4, "width", null], [4, "height", null]], null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgStyleImpl, i1.ɵNgStyleR2Impl, [i0.ElementRef, i0.KeyValueDiffers, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgStyle, [i1.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i0.ɵpod(3, { "display": 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FrontpageComponent_1)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FrontpageComponent_2)), i0.ɵdid(7, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _ck(_v, 3, 0, ((!_co.frontpageExist && !_co.showIconIfUnavailable) ? "none" : "block")); _ck(_v, 2, 0, currVal_2); var currVal_3 = _co.frontpageExist; _ck(_v, 5, 0, currVal_3); var currVal_4 = (!_co.frontpageExist && _co.showIconIfUnavailable); _ck(_v, 7, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.width; var currVal_1 = _co.height; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_FrontpageComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "frontpage", [], null, null, null, View_FrontpageComponent_0, RenderType_FrontpageComponent)), i0.ɵdid(1, 245760, null, 0, i2.FrontpageComponent, [i3.FileService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FrontpageComponentNgFactory = i0.ɵccf("frontpage", i2.FrontpageComponent, View_FrontpageComponent_Host_0, { fileName: "fileName", pId: "pId", showIconIfUnavailable: "showIconIfUnavailable", width: "width", height: "height" }, {}, []);
export { FrontpageComponentNgFactory as FrontpageComponentNgFactory };
