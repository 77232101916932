/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./user.settings.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./profile.setting.component.ngfactory";
import * as i3 from "./profile.setting.component";
import * as i4 from "@angular/material/snack-bar";
import * as i5 from "@angular/material/dialog";
import * as i6 from "../service/user.service";
import * as i7 from "../service/language.service";
import * as i8 from "../service/activity.service";
import * as i9 from "../service/country.service";
import * as i10 from "../service/file.service";
import * as i11 from "./competence.setting.component.ngfactory";
import * as i12 from "./competence.setting.component";
import * as i13 from "../service/statistics.service";
import * as i14 from "@angular/common";
import * as i15 from "./new.translator.component";
import * as i16 from "@angular/router";
import * as i17 from "../service/login.service";
var styles_NewTranslatorComponent = [i0.styles];
var RenderType_NewTranslatorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NewTranslatorComponent, data: {} });
export { RenderType_NewTranslatorComponent as RenderType_NewTranslatorComponent };
function View_NewTranslatorComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "profile-settings", [], null, null, null, i2.View_ProfileSettingsComponent_0, i2.RenderType_ProfileSettingsComponent)), i1.ɵdid(1, 114688, [[2, 4]], 0, i3.ProfileSettingsComponent, [i4.MatSnackBar, i5.MatDialog, i6.UserService, i7.LanguageService, i8.ActivityService, i9.CountryService, i10.FileService], { user: [0, "user"], isTranslator: [1, "isTranslator"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.user; var currVal_1 = true; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_NewTranslatorComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "competence-settings", [], null, null, null, i11.View_CompetenceSettingsComponent_0, i11.RenderType_CompetenceSettingsComponent)), i1.ɵdid(1, 114688, [[1, 4]], 0, i12.CompetenceSettingsComponent, [i7.LanguageService, i8.ActivityService, i13.StatisticsService, i6.UserService, i4.MatSnackBar, i5.MatDialog, i14.Location], { user: [0, "user"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.user; _ck(_v, 1, 0, currVal_0); }, null); }
function View_NewTranslatorComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["src", "../../assets/loading.gif"], ["style", "max-width:40px;"]], null, null, null, null, null))], null, null); }
function View_NewTranslatorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Join our translator community by specifying what translation competences you have."])), (_l()(), i1.ɵeld(3, 0, null, null, 9, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NewTranslatorComponent_2)), i1.ɵdid(5, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NewTranslatorComponent_3)), i1.ɵdid(7, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 4, "div", [["style", "width: 100%;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 3, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.gotoProfile() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Save and continue to profile "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NewTranslatorComponent_4)), i1.ɵdid(12, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.user; _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.user; _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.saving; _ck(_v, 12, 0, currVal_2); }, null); }
export function View_NewTranslatorComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { competenceSettingComp: 0 }), i1.ɵqud(671088640, 2, { profileSettingComp: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 10, "div", [["class", "main"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Welcome,"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Become a translator @ lilo"])), (_l()(), i1.ɵeld(7, 0, null, null, 3, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Start your journey here to join this new innovative community "])), (_l()(), i1.ɵeld(9, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["of translators and connect more easily with clients all over the world."])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NewTranslatorComponent_1)), i1.ɵdid(12, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.user; _ck(_v, 12, 0, currVal_0); }, null); }
export function View_NewTranslatorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "new-translator", [], null, null, null, View_NewTranslatorComponent_0, RenderType_NewTranslatorComponent)), i1.ɵdid(1, 4308992, null, 0, i15.NewTranslatorComponent, [i6.UserService, i16.Router, i17.LogInService, i4.MatSnackBar], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NewTranslatorComponentNgFactory = i1.ɵccf("new-translator", i15.NewTranslatorComponent, View_NewTranslatorComponent_Host_0, {}, {}, []);
export { NewTranslatorComponentNgFactory as NewTranslatorComponentNgFactory };
