<h2>Project follow-up</h2>

<div *ngIf="!userService.isCurrentUserAdmin">
  You do not have the correct privileges to access this page.
</div>

<div *ngIf="userService.isCurrentUserAdmin">

  <div>
    <p>
      By default, all 'active' (= running) projects are shown here. <br/>
      You can use the 'status buttons' below to display projects in a specific status.<br/>
      <br/>
      Additionally, you can use the text field to the right of the status buttons to filter the content of the list
      further.
      The input will be matched with:</p>
    <ul style="font-size: small;">
      <li>the project's <strong>title</strong>;</li>
      <li>the name of any <strong>source file</strong> within the project;</li>
      <li>the name of the <strong>user</strong> that requested the project [for non-drafts];</li>
      <li>the name of the <strong>company</strong> of the user that requested the project [for non-drafts and if
        applicable].
      </li>
    </ul>
    <div style="display: flex; align-items: center; justify-content: space-between; margin-top: 10px;">
      <div>
        <button *ngFor="let s of projectStates" (click)="findProjects(s)"
                [className]="(s === projectState) ? 'stateButtonDisabled' : 'stateButton'"
                title="Load projects with status '{{s}}'">{{s}}</button>
      </div>
      <input type="text" name="search" class="searchField" placeholder="Filter on title, file name, user or company"
             title="Enter a value and hit enter to filter on it"
             [(ngModel)]="filterText"
             (change)="setFilteredProjects()"/>
    </div>
  </div>

  <hr style="margin-bottom: 10px; margin-top: 15px;">

  <div style="display: flex; justify-content: center;"
       *ngIf="loading">
    <img src="../../assets/loading.gif">
  </div>
  <div *ngIf="filteredProjects && filteredProjects.length == 0"
       style="margin-top: 15px; font-size: medium; font-weight: bold;">
    No projects found with status '{{projectState}}'<span *ngIf="filterText != null && filterText.length > 0"> and filter '{{filterText}}
    '</span>.
  </div>
  <div *ngIf="filteredProjects && filteredProjects.length > 0">
    <div style="display: flex; align-items: center; justify-content: space-between;">
      <p># items: {{filteredProjects.length}}</p>
      <button (click)="refresh()" class="iconButton" title="Refresh list">
        <img src="../../../assets/refresh.png">
      </button>
    </div>
    <project-list [projects]="filteredProjects" (selectedProject)="gotoProject($event)"></project-list>
  </div>

</div>


