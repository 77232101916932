<div *ngIf="user == undefined">
  You are not signed in.<br/>Please sign in to continue: <button (click)="loginService.openSignInPopup('')">Sign in</button>
</div>

<div *ngIf="invoice != null && invoice.type === 'SALES'" class="invoiceSection">
  <sales-invoice [invoice]="invoice"></sales-invoice>
  <div class="salesInvoiceDownload">
    <button class="btnDownload" (click)="downloadInvoicePDF()">
      <img src="../../assets/download_white.png">Download
    </button>
  </div>
</div>

<div *ngIf="invoice != null && invoice.type === 'PURCHASE'" class="invoiceSection">
  <div *ngIf="company != null || invoiceId != null">
    <div *ngIf="invoiceId == null">
      <p>Tasks can be invoiced from the moment they are completed.<br/>
        To limit the administrative effort on both ends, we suggest to only send 1 invoice per month.</p>
    </div>
    <purchase-invoice [invoice]="invoice" [user]="user" [company]="company"></purchase-invoice>
  </div>

  <div *ngIf="company == null && invoiceId == null">
    <p style="margin-top: 20px;">You did not register your company details yet. <br/>
      Please complete these before registering your invoices.</p>
    <p *ngIf="user">You can do so by going to your user profile, and selecting
      "<a routerLink="/user/{{user.id}}/company"
          title="Click here to be redirected to your company settings.">Company</a>"
      from the right menu.</p>
  </div>

</div>
