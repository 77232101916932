<div>
  <h1>Documentation files</h1>
  <div style="margin: 10px 0 35px 0;">
    <p>Add files here that will support the translators:</p>
    <ul>
      <li>Styleguides</li>
      <li>Terminology glossaries</li>
      <li>Generic company information</li>
      <li>...</li>
    </ul>
    <p>
      These files will be added automatically to your projects.<br />
      It is helpful for the translators to indicate for each request which
      information is relevant. You can replace documents or add new ones. <br />
      Do bear in mind that for running projects, newly added files will not be
      visible to the translators working on those projects.
    </p>
  </div>
  <documentation-list
    [files]="documentationFiles"
    [entityId]="companyId"
    [entityType]="'company'"
    [allowRemoval]="true"
  ></documentation-list>
  <documentation-file-add
    [entityId]="companyId"
    [entityType]="'company'"
    (onFileUpload)="addFile($event)"
  ></documentation-file-add>
</div>
