import { Component } from "@angular/core";
import { ProjectService } from '../service/project.service';
import { PricingService } from '../service/pricing.services';
import { Observable } from 'rxjs';
import { debounce, debounceTime, tap, filter, map, switchMapTo, flatMap } from 'rxjs/operators';
import { calcPossibleSecurityContexts } from '@angular/compiler/src/template_parser/binding_parser';
import { TaskService } from '../service/task.service';
import { FileService } from '../service/file.service';
import { Project } from '../dto/project';
import { AnalysisService } from '../service/analysis.service';
import { VendorService } from '../service/vendor.service';
import { switchMap } from 'rxjs-compat/operator/switchMap';
import { TaskSpec } from '../dto/job';
import { reduce } from 'rxjs-compat/operator/reduce';
import { TranslationParameter } from '../mock-activities';

@Component({
  selector: 'cost-calc',
  templateUrl: './cost-calc.component.html',
  styleUrls: ['./cost-calc.component.css'],
})
export class CostCalcComponent {
  projectId: string;
  project: Project;

  sourcelang: string;

  targetLanguages = ['en-GB', 'nl-BE', 'fr-BE', 'de-DE'];
  prices = {};
  lowestPrices = {};

  pricingObs: Observable<any>;

  calculatingPrices = false;

  constructor(private projectService: ProjectService,
    public pricingService: PricingService,
    private taskService: TaskService,
    private fileService: FileService,
    private analysisService: AnalysisService,
    private vendorService: VendorService,
  ) {

  }

  setProjectId(projectId: string) {
    console.debug("Following up on project ", projectId);
    this.projectId = projectId;
    this.projectService.getProject(projectId).subscribe(p => {
      this.project = p;
      if (this.project.files !== undefined && this.project.files[0] !== undefined) {
        this.enrollToAnalysisFeed(this.project.files[0].name);
        if (this.project.files[0].sourcelang !== undefined) {
          this.setSourceLanguage(this.project.files[0].sourcelang)
        }
      }
    });
  }
  setSourceLanguage(sourcelang: any) {
    this.sourcelang = sourcelang;
    Observable.from(this.targetLanguages)
      .pipe(
        filter(a => !a.startsWith(this.sourcelang)),
        map(l => {
          const serviceParam = new TranslationParameter();
          serviceParam.source = this.sourcelang;
          serviceParam.target = l;
          return serviceParam
        })
      )
      .concatMap(a => this.projectService.addLangPairData(a, this.projectId))
      .subscribe(a => {}, error => {},
        () => this.enrollToProjectFeed())
  }

  setFile(file: string) {
    if (this.projectId !== undefined) {
      this.enrollToAnalysisFeed(file);
    }
  }

  enrollToAnalysisFeed(filename: string) {
    this.analysisService.enrollForFile(this.projectId, filename)
      .pipe(
        filter(a => a.type === 'detectedsourcelang' && this.sourcelang === undefined),
        map(a => a.value),
        tap(a => this.setSourceLanguage(a))
      ).subscribe()
  }

  enrollToProjectFeed() {
    this.pricingObs = this.pricingService.enrollForProject(this.projectId);
    this.pricingObs.pipe(
      debounceTime(500),
      tap(a => this.caclPrices())
    ).subscribe();
  }

  caclPrices() {
    Observable.from(this.targetLanguages)
      .pipe(
        filter(t => !t.startsWith(this.sourcelang)),
      )
      .subscribe(l => this.calcPriceForLanguage(l))
  }

  calcPriceForLanguage(l: string): void {
    let langpair = this.sourcelang + '_' + l;
    let taskSpec = new TaskSpec();
    taskSpec.activity = 'Translation';
    taskSpec.service = 'Translation'
    taskSpec.projectId = this.projectId;
    taskSpec.sourcelanguage = this.sourcelang;
    taskSpec.targetlanguage = l;
    let priceCount = 0
    this.vendorService.getBestProjectCompetences(this.projectId, langpair, 'Translation', 'Translation', undefined)
      .pipe(
        tap(a=> console.debug('vendors ', a)),
        map(a => <[]>a),
      )
      .switchMap(vendors => this.pricingService.getPriceForTaskSpecAndVendors(taskSpec, vendors))
      .pipe(
        tap(prices => priceCount = prices.length),
        flatMap(prices => prices),
        map(price => price['price']),
        tap(price => {
          if (this.lowestPrices[l] === undefined || this.lowestPrices[l] > price) {
            this.lowestPrices[l] = price;
          }
        })
      )
      .reduce( (a, b) => {
        return a + b
      })
      .map(priceSum => priceSum / priceCount)
      .subscribe(price => this.prices[l] = price);
  }

}
