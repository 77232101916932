<h2 style="margin: 25px 0;">Project clean-up</h2>

<div *ngIf="!userService.isCurrentUserAdmin">
  You do not have the correct privileges to access this page.
</div>

<div *ngIf="userService.isCurrentUserAdmin == true">
  <div style="margin-bottom: 25px;">
    <p style="color: var(--warning-color); font-size: 20px; margin-bottom: 10px;">
      <span style="font-weight: bold;">WARNING</span>: the "clean" function removes the project data entirely!
    </p>
    <p>This includes:</p>
    <ul style="list-style: disc; padding-left: 15px; font-size: 15px;">
      <li>the PCR index;</li>
      <li>action and mail logs;</li>
      <li>task and editor data (including FDC);</li>
      <li>statistics;</li>
      <li>chats;</li>
      <li>the project folder;</li>
      <li>and of course the project record itself.</li>
    </ul>
  </div>

  <div style="display: flex; align-items: end; margin-bottom: 15px;">
    <p style="margin: 10px 15px 10px 0;">Projects with status <select id="status" [(ngModel)]="selectedStatus">
      <option *ngFor="let status of statuses" [ngValue]="status">{{status}}</option>
    </select> older than <input id="userid" type="text" [(ngModel)]="otmnths" style="width: 50px;"/>
      (must be >= 3) months.
    </p>
    <button (click)="getProjectsForCleanup()"
            [disabled]="!(otmnths != undefined && otmnths >= 3)">Find
    </button>
  </div>

  <div *ngIf="projects !== null">
    <dynamic-table [tableData]="projects" [tableHeaders]="headers"></dynamic-table>
    <br/>
    <p># projects: {{projects.length}}</p>
    <button [disabled]="projects == null || projects.length == 0"
            style="margin: 35px 0 10px 20px;"
            (click)="cleanup()">Clean 'm up
    </button>
    <p *ngIf="cleanupDone"># projects cleaned up: {{removedCount}}/{{totalCount}}</p>
  </div>
</div>
