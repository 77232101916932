import {Component, EventEmitter, Input, Output} from "@angular/core";
import {ErrorSnackbarComponent} from "../../error.snackbar.component";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Subject} from "rxjs";
import {FileService} from "../../service/file.service";
import {UploadDataService} from "../util/upload-data.service";


@Component({
  selector: 'source-file-add',
  templateUrl: './source-file-add.component.html',
  styleUrls: ['../project.component.css'],
})
export class SourceFileAddComponent {

  @Input() uploadedFiles = [];
  @Input() pId;
  @Output() onFileUpload = new EventEmitter<File>();

  constructor(
    private fileService: FileService,
    private uploadDataService: UploadDataService,
    private snackbar: MatSnackBar,
  ){

  }

  uploadFiles(event): void {
    if (this.uploadedFiles == undefined)
      this.uploadedFiles=[];
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      // Check for duplicate files
      for (var i = 0; i < fileList.length; i++) {
        let file: File = fileList[i];
        // Check if there is already a file with the same name in the project and show an error snackbar
        if (this.uploadedFiles.find(v => v.name === file.name)) {
          let ref = this.snackbar.openFromComponent(ErrorSnackbarComponent, {
            duration: 3000,
            verticalPosition: "top",
            data: {
              errorText: `
                Sorry, you cannot add 2 files with the same name. <br/>
                A file with name <span style="font-weight: bold">${file.name}</span> already exists.`
            }
          });
          return;
        }
      }
      // Loop and upload the files
      for (var i = 0; i < fileList.length; i++) {
        let file: File = fileList[i];
        // Create a subject to send progress events to
        const progress = new Subject<number>();
        // Store the subject as observable in a data provider, so we keep track of it even when navigating away from the page
        this.uploadDataService.addUploadData(this.pId, file.name, progress);
        // We are uploading the source file in an async block so the rest of the application does not wait for the
        // upload to complete
        (async () => {
          // Upload the file through the file service
          let start = new Date().getTime();
          this.fileService.uploadProjectFileWithProgress(file, this.pId, null, progress).subscribe(
            data => {
              let end = new Date();
              console.log("Project " + this.pId + ": upload of " + file.name + " in " + (end.getTime() - start) + " ms");
            },
            error => {
              console.log("Error uploading source file " + file.name + " => ", error);
              let ref = this.snackbar.openFromComponent(ErrorSnackbarComponent, {
                duration: 3000,
                verticalPosition: "top",
                data: {
                  errorText: `
                Uh oh, there was an error uploading file <span style="font-weight: bold">${file.name}</span>. <br/>
                Please remove it and try again.`
                }
              });
            },
            () => {
              progress.complete();
              this.uploadDataService.removeUploadData(this.pId, file.name);
            }
          );
        })();
        this.onFileUpload.emit(file);
        this.uploadedFiles.push(file);
      }
    }
  }

  uploadDocumentationFiles(event: any) {
    console.debug(event, 'Documentaion file upload');
  }

}
