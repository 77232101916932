<div class="pre-upload-component" drag-n-drop
     (fileChangeEmitter)="onFileChange($event)">

  <div class="filesSection">

    <div *ngIf="files === undefined || files.length == 0" class="addASourceFileDiv">
      <label for="fileupload" style="line-height: unset;"><img src="../../assets/plus.png"
                                                               style="width: 50px;"/></label>
      <div class="addFileLabelDiv">
        <label for="fileupload" style="line-height: unset;"><strong>Add a file</strong></label>
        <input id="fileupload" type="file" style="display: none;" multiple
               (change)="uploadFiles($event)" [disabled]="loading" #fileupload/>
        <p>Word, ppt, pages, ...
          <a href="https://about.lilo.global/formats" target="_blank" style="display: none;">see all formats</a>
          <!--Display none for now because we don't have the page yet-->
        </p>
      </div>
    </div>

    <div *ngIf="files.length > 0" class="preUploadFiles" id="uploadedFilesDiv">
      <div *ngFor="let f of files">
        <pre-upload-file [file]="f" (removeEmitter)="removeFile($event)"></pre-upload-file>
      </div>
    </div>

    <div *ngIf="files.length > 0" class="addMoreSourceFileDiv preUploadItem">
      <label for="fileadd" class="addFilesCircle">+</label>
      <div class="addFileLabelDiv">
        <label for="fileadd">Add more files</label>
        <input id="fileadd" type="file" style="display: none;" multiple
               (change)="uploadFiles($event)" [disabled]="loading" #fileadd/>
      </div>
    </div>
  </div>

  <div class="nextSection">
    <gtac-input #gtac></gtac-input>
    <button [disabled]="projectId === undefined || gtacInput == null || gtacInput['isAccepted'] === false"
     (click)="draftProject()">
      <strong *ngIf="!loading">Next</strong>
      <!-- Can't display loading icon on disabled button because we have no version with grey background :-( -->
      <img *ngIf="loading && projectId !== undefined" src="../../assets/loading_small_orb_whiteonblue.gif"
           alt="Loading..."/>
    </button>
  </div>

</div>
