import {Component, EventEmitter, Input, Output, OnInit, OnChanges, SimpleChanges} from "@angular/core";
import {UserService} from "../../service/user.service";
import {Router} from '@angular/router';
import { filter, tap, map } from 'rxjs/operators';
import { Subject } from 'rxjs';


@Component({
  selector: 'translator-card',
  templateUrl: './translator-card.component.html',
  styleUrls: ['../project.component.css'],
})
export class TranslatorCardComponent implements OnInit, OnChanges {

  @Input()
  translator: any = undefined;
  @Input()
  index;
  @Input()
  targetLang;
  @Input()
  duedate: Date;
  @Input()
  isSelected = false;
  @Input()
  isLast = false;
  @Input()
  isQuote = false;

  @Output()
  translatorSelect = new EventEmitter<any>();

  flipped = false;

  private duedateChanges = new Subject<any>();
  daysUnavailable = 0;
  unavailabilities = [];

  constructor(private userService: UserService,
              private router: Router) {

  }

  ngOnInit() {
    // At the moment, the user's "mother tongue" is chosen from the list of languages without dialects
    // So we need to strip the dialect from the language here if we want to match it with the user's mother tongue
    if (this.targetLang != null) {
      const idx = this.targetLang.indexOf('-');
      if (idx > 0) {
        this.targetLang = this.targetLang.substring(0, idx);
      }
    }
    // Debounce so it only updates every second instead of every keystroke
    this.duedateChanges.asObservable().debounceTime(1000).subscribe(v =>
      this.fetchUnavailability()
    );
    this.duedateChanges.next('') // trigger the first unavailability check
  }

  ngOnChanges(change: SimpleChanges) {
    if (change['duedate'] != null) {
      this.duedateChanges.next(change);
    }
  }

  isNative() {
    return this.targetLang != null && this.targetLang === this.translator.motherTongue;
  }

  getPrice() {
    let price = this.translator['price'];
    if (price == null) {
      price = 0.0;
    }
    if (this.translator['mtCost'] != null) {
      price = price + this.translator['mtCost'];
    }
    if (this.translator['serviceFee'] != null) {
      price = price + this.translator['serviceFee'];
    }
    return price;
  }

  selectTranslator() {
    // this.translator["isSelected"] = !this.translator["isSelected"];
    this.isSelected = !this.isSelected;
    this.translatorSelect.emit(this.isSelected ? this.translator : undefined);
  }

  openProfileInfo() {
    this.router.navigate(['/profile', this.translator.id]);
  }

  fetchUnavailability() {
    if (this.duedate != null) {
      const projectStartTime = new Date()
      this.userService.fetchUnavailability(this.translator.id)
        .flatMap(u => u)
        .pipe(
          filter(u => u.to != null && u.from != null),
          filter(u => this.isBetween(projectStartTime, u.from, u.to)
            || this.isBetween(this.duedate, u.from, u.to)
          ),
          tap(u => this.unavailabilities[this.unavailabilities.length] = u),
          tap(u => this.daysUnavailable = this.daysUnavailable + this.calcDaysUnavailable(u))
        )
        .subscribe();
    }
  }

  calcDaysUnavailable(unavailability: any): number {
    const from = unavailability.from > new Date().toISOString().slice(0, 10) ? unavailability.from : new Date().toISOString().slice(0, 10);
    return this.daysDiff(from, unavailability.to);
  }

  isBetween(date: Date, from: String, to: String): boolean {
    return date.toISOString().slice(0, 10) <= to.slice(0, 10) && date.toISOString().slice(0, 10) >= from.slice(0, 10);
  }

  daysDiff(from: string, to: string): number {
    const fromDate = new Date(from);
    const toDate = new Date(to);
    const diff = Math.abs(fromDate.getTime() - toDate.getTime());
    const diffDays = Math.ceil(diff / (1000 * 3600 * 24));
    return diffDays;
  }

  unavailabilityTitle(): string {
    let unavailabilityTitle = '';
    if (this.unavailabilities != null) {
      this.unavailabilities
        .map(u => '* Unavailable from ' + u.from + ' till ' + u.to + '\n')
        .forEach(s => unavailabilityTitle = unavailabilityTitle + s);
    }
    return unavailabilityTitle;
  }
}
