/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../util/countdown.component.ngfactory";
import * as i2 from "../util/countdown.component";
import * as i3 from "@angular/common";
import * as i4 from "@angular/material/dialog";
import * as i5 from "../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i6 from "@angular/material/button";
import * as i7 from "@angular/cdk/a11y";
import * as i8 from "@angular/platform-browser/animations";
import * as i9 from "./editor-save.component";
var styles_EditorSaveComponent = [];
var RenderType_EditorSaveComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EditorSaveComponent, data: {} });
export { RenderType_EditorSaveComponent as RenderType_EditorSaveComponent };
function View_EditorSaveComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Thanks for your timely delivery!"]))], null, null); }
function View_EditorSaveComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Please note your delivery is "])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "countdown", [], null, null, null, i1.View_CountdownComponent_0, i1.RenderType_CountdownComponent)), i0.ɵdid(3, 114688, null, 0, i2.CountdownComponent, [], { enddate: [0, "enddate"], showoverdue: [1, "showoverdue"], showsuffixlabel: [2, "showsuffixlabel"] }, null), (_l()(), i0.ɵted(-1, null, [" overdue. "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.task.duedate; var currVal_1 = true; var currVal_2 = false; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_EditorSaveComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Are you sure you want to finalize and complete the file? It will no longer be available once you confirm."])), (_l()(), i0.ɵeld(3, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, [" You have modified ", " out of ", " segments (", "%). "])), i0.ɵppd(5, 2), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EditorSaveComponent_2)), i0.ɵdid(7, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EditorSaveComponent_3)), i0.ɵdid(9, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = (_co.hasDueDate() && _co.onTime()); _ck(_v, 7, 0, currVal_3); var currVal_4 = (_co.hasDueDate() && !_co.onTime()); _ck(_v, 9, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.modifiedCount; var currVal_1 = _co.totalCount; var currVal_2 = i0.ɵunv(_v, 4, 2, _ck(_v, 5, 0, i0.ɵnov(_v.parent, 0), ((_co.modifiedCount / _co.totalCount) * 100), "1.0-0")); _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2); }); }
function View_EditorSaveComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 13, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 12, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Before you can finalize and complete the file, you must ensure you have "])), (_l()(), i0.ɵeld(3, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["inserted all the required references for each segment"])), (_l()(), i0.ɵted(-1, null, ["."])), (_l()(), i0.ɵeld(6, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" The system currently detects "])), (_l()(), i0.ɵeld(9, 0, null, null, 1, "u", [], [[8, "title", 0]], null, null, null, null)), (_l()(), i0.ɵted(10, null, ["", " row(s)"])), (_l()(), i0.ɵted(-1, null, [" where one or more references are missing. You can identify these by the warning icon "])), (_l()(), i0.ɵeld(12, 0, null, null, 0, "img", [["src", "../../assets/warning.png"], ["style", "width: 15px; height: 15px;"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" in the right-most column. "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", _co.tooltipMissingRefs, ""); _ck(_v, 9, 0, currVal_0); var currVal_1 = _co.rowsWithMissingRefs.length; _ck(_v, 10, 0, currVal_1); }); }
export function View_EditorSaveComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i3.DecimalPipe, [i0.LOCALE_ID]), (_l()(), i0.ɵeld(1, 0, null, null, 2, "h1", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i0.ɵdid(2, 81920, null, 0, i4.MatDialogTitle, [[2, i4.MatDialogRef], i0.ElementRef, i4.MatDialog], null, null), (_l()(), i0.ɵted(-1, null, ["File complete?"])), (_l()(), i0.ɵeld(4, 0, null, null, 5, "div", [["class", "mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i0.ɵdid(5, 16384, null, 0, i4.MatDialogContent, [], null, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EditorSaveComponent_1)), i0.ɵdid(7, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EditorSaveComponent_4)), i0.ɵdid(9, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(10, 0, null, null, 7, "div", [["class", "mat-dialog-actions"], ["mat-dialog-actions", ""], ["style", "display: flex; align-items: center; justify-content: space-evenly;"]], null, null, null, null, null)), i0.ɵdid(11, 16384, null, 0, i4.MatDialogActions, [], null, null), (_l()(), i0.ɵeld(12, 0, null, null, 2, "button", [["mat-button", ""]], [[8, "title", 0], [1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirmClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i0.ɵdid(13, 180224, null, 0, i6.MatButton, [i0.ElementRef, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"] }, null), (_l()(), i0.ɵted(-1, 0, ["Confirm "])), (_l()(), i0.ɵeld(15, 0, null, null, 2, "button", [["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancelClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i0.ɵdid(16, 180224, null, 0, i6.MatButton, [i0.ElementRef, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i0.ɵted(-1, 0, ["Cancel"]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_1 = _co.enableConfirm(); _ck(_v, 7, 0, currVal_1); var currVal_2 = !_co.enableConfirm(); _ck(_v, 9, 0, currVal_2); var currVal_6 = !_co.enableConfirm(); _ck(_v, 13, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 2).id; _ck(_v, 1, 0, currVal_0); var currVal_3 = i0.ɵinlineInterpolate(1, "", (_co.enableConfirm() ? "Finalize your file" : "Unable to finalize your file: resolve any issues first!"), ""); var currVal_4 = (i0.ɵnov(_v, 13).disabled || null); var currVal_5 = (i0.ɵnov(_v, 13)._animationMode === "NoopAnimations"); _ck(_v, 12, 0, currVal_3, currVal_4, currVal_5); var currVal_7 = (i0.ɵnov(_v, 16).disabled || null); var currVal_8 = (i0.ɵnov(_v, 16)._animationMode === "NoopAnimations"); _ck(_v, 15, 0, currVal_7, currVal_8); }); }
export function View_EditorSaveComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "editor-save", [["class", "component"]], null, null, null, View_EditorSaveComponent_0, RenderType_EditorSaveComponent)), i0.ɵdid(1, 114688, null, 0, i9.EditorSaveComponent, [i4.MatDialogRef, i4.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EditorSaveComponentNgFactory = i0.ɵccf("editor-save.component", i9.EditorSaveComponent, View_EditorSaveComponent_Host_0, {}, {}, []);
export { EditorSaveComponentNgFactory as EditorSaveComponentNgFactory };
