import {Component, OnInit} from "@angular/core";
import {UserService} from "../../../service/user.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ColumnType, DynamicTableHeader} from "../../../util/component/dynamic-table/dynamic-table.model";
import {ProjectService} from "../../../service/project.service";
import {ConfirmDialog} from "../../../confirm.dialog";
import {filter, tap} from "rxjs/operators";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'project-cleanup',
  templateUrl: 'project-cleanup.component.html',
})
export class ProjectCleanupComponent implements OnInit {

  public readonly headers: DynamicTableHeader[] = [
    {
      key: 'id',
      value: 'ID',
    },
    {
      key: 'title',
      value: 'Title',
    },
    {
      key: 'initDate',
      value: 'Init date',
      type: ColumnType.DATE,
    },
    {
      key: 'creationdate',
      value: 'Creation date',
      type: ColumnType.DATE,
    },
    {
      key: 'invoicedate',
      value: 'Invoice date',
      type: ColumnType.DATE,
    },
    {
      key: 'canceled',
      value: 'Cancel date',
      type: ColumnType.DATE,
    },
    {
      key: 'requestedby.firstName',
      value: 'First name',
    },
    {
      key: 'requestedby.lastName',
      value: 'Last name',
    },
    {
      key: 'requestedby.companyName',
      value: 'Company',
    },
  ];
  public readonly statuses = ["INIT", "DRAFT", "CANCELED"];

  public projects: any[] = null;
  public otmnths = 12;
  public selectedStatus = 'INIT';
  public removedCount = 0;
  public totalCount = 0;
  public cleanupDone = false;

  constructor(public userService: UserService,
              private projectService: ProjectService,
              private dialog: MatDialog,
              private snackbar: MatSnackBar) {

  }

  public ngOnInit(): void {
    this.getProjectsForCleanup();
  }

  public getProjectsForCleanup(): void {
    this.projectService.getProjectsForCleanup(this.otmnths, this.selectedStatus).subscribe(p => {
      this.projects = p;
      this.snackbar.open(`${this.selectedStatus} projects retrieved`, "", {
        duration: 2000
      });
    });
  }

  public cleanup(): void {
    let confDialog = this.dialog.open(ConfirmDialog, {
      width: '400px',
      panelClass: 'confirmDialog',
      data: {
        confirmTitle: 'Are you sure?',
        confirmHtml:
          '<p>This will remove ALL data for these ' + this.projects.length + ' projects.</p>' +
          '<p style="font-weight: bold; color: var(--warning-color);">This action can is final and can not be reversed!</p>',
        confirmText: 'Yup, I know what I\'m doing',
        cancelText: 'Naaah, this is way too scary'
      }
    });
    confDialog.afterClosed().pipe(
      filter(t => t === "confirm"),
      tap(() => this.totalCount = this.projects.length),
    ).subscribe(p => {
        this.projectService.cleanupProjects(this.otmnths, this.selectedStatus).subscribe(c => {
          this.getProjectsForCleanup();
            this.removedCount = c;
            this.cleanupDone = true;
          this.snackbar.open(`${this.selectedStatus} project clean-up done!`, "", {
              duration: 2000
            });
          });
        }
      );
  }
}
