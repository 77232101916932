import {Component, OnInit} from "@angular/core";
import {UserService} from "../../service/user.service";
import {FileService} from "../../service/file.service";
import {ActivatedRoute} from "@angular/router";


@Component({
  selector: 'folderviewer',
  templateUrl: 'folder-viewer.component.html',
  styleUrls: ['folder-viewer.component.css'],
})
export class FolderViewerComponent implements OnInit {

  entitytypes;
  entitytype;
  entityid;

  entityid2view;
  entitytype2view;

  constructor(private route: ActivatedRoute,
              public userService: UserService,
              private fileService: FileService) {
  }

  ngOnInit() {
    this.entitytypes = new Array();
    this.entitytypes.push("project");
    this.entitytypes.push("company");
    this.entitytypes.push("user");
    this.entitytypes.push("invoice");
    this.route.params
      .subscribe(params => {
        this.entityid = params['id'];
        // Check whether given type (if any) is in the entity types array
        // If not, just pick the first option as default
        if (params['type']) {
          if (this.entitytypes.indexOf(params['type']) > -1)
            this.entitytype = params['type'];
          else
            this.entitytype = this.entitytypes[0];
        } else this.entitytype = this.entitytypes[0];
        if (this.entityid && this.entitytype) {
          this.entityid2view = this.entityid;
          this.entitytype2view = this.entitytype;
        }
      });
  }

  openView() {
    this.entityid2view = this.entityid;
    this.entitytype2view = this.entitytype;
  }
}
