<h2>Admin dashboard</h2>

<div *ngIf="userService.isCurrentUserAdmin">
  <ul>
    <li><a routerLink="/admin/mimicuser" routerLinkActive="active">Mimic user</a></li>
    <li><a routerLink="/admin/customergroups" routerLinkActive="active">Customer groups</a></li>
    <li><a routerLink="/admin/folderviewer" routerLinkActive="active">Folder viewer</a></li>
    <li><a routerLink="/admin/projectcleanup" routerLinkActive="active">Project clean-up</a></li>
    <li><a routerLink="/admin/projectfollowup" routerLinkActive="active">Project follow-up</a></li>
    <li><a routerLink="/admin/tmmanagement" routerLinkActive="active">TM management</a></li>
    <li><a routerLink="/admin/translatoroverview" routerLinkActive="active">Translator search</a></li>
    <li><a routerLink="/admin/translatorsummary" routerLinkActive="active">Translator summary</a></li>
  </ul>
</div>

<div *ngIf="!userService.isCurrentUserAdmin">
  You do not have the correct privileges to access this page.
</div>
