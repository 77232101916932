import {defaultIfEmpty, merge} from 'rxjs/operators';
import {UserService} from "../service/user.service";
import {AfterViewInit, Component, ViewChild} from "@angular/core";
import {Router} from "@angular/router";
import {LogInService} from "../service/login.service";
import {ProfileSettingsComponent} from "./profile.setting.component";
import {MatSnackBar} from "@angular/material/snack-bar";
import {CompetenceSettingsComponent} from "./competence.setting.component";


@Component({
  selector: "new-translator",
  template: `
    <div class="main">
      <h1>Welcome,</h1>
		<h2>Become a translator @ lilo</h2>
      <h3>Start your journey here to join this new innovative community <br/>of translators and connect more easily with
        clients all over the world.</h3>
      <div *ngIf="user">
        <p>Join our translator community by specifying what translation competences you have.</p>
        <div>
          <profile-settings *ngIf="user" [user]="user" [isTranslator]="true"></profile-settings>
          <competence-settings *ngIf="user" [user]="user"></competence-settings>
          <div style="width: 100%;">
            <button (click)="gotoProfile()">Save and continue to profile
              <img src="../../assets/loading.gif" style="max-width:40px;" *ngIf="saving">
            </button>
          </div>
        </div>
      </div>
    </div>`,
  styleUrls: ['./user.settings.component.css'],
})
export class NewTranslatorComponent implements AfterViewInit {

  user;
  //Component is fetched so that when going to the next page, the save method can be called
  @ViewChild(CompetenceSettingsComponent, { static: false }) competenceSettingComp: CompetenceSettingsComponent;
  @ViewChild(ProfileSettingsComponent, { static: false }) profileSettingComp: ProfileSettingsComponent;

  constructor(private userService: UserService,
              private router: Router,
              private loginService: LogInService,
              private snackbar: MatSnackBar) {

  }

  ngOnInit() {
    this.userService.signInFromLocalStorage().pipe(defaultIfEmpty(undefined)).subscribe(u => {
      if (u == undefined) {
        Promise.resolve().then(() => {
            this.loginService.openJoinDialog("newtranslator");
            this.userService.loginEmitter.subscribe(v => {
              this.user = this.userService.getCurrentUser();
            });
          }
        )
      } else {
        this.user = u;
      }
    });
  }

  ngAfterViewInit() {
  }

  saving: boolean = false;

  gotoProfile() {
    this.saving = true;
    this.competenceSettingComp.getSaveObservables().pipe(merge(this.profileSettingComp.getSaveObservables())).subscribe(
      r => {
        this.router.navigate(["user", this.user.id]);
        this.snackbar.open("Profile updated", '', {
          duration: 2000
        });
        this.saving = false;
      },
      error => console.error("Error updating user " + this.user.id, error)
    );
  }
}
