import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';


/**
 * This component acts as a "wrapper" for the user-profile component, so it can be opened in both a dialog and through
 * a normal redirect.
 */
@Component({
  selector: 'profile-info-dialog',
  template: `
	  <p class="profileDialogTitle">
		  This is how your profile is seen by others.<br/>
		  <span *ngIf="isTranslator">Make sure to fill in all relevant information to ensure high visibility.</span></p>
	  <mat-dialog-content>
		  <user-profile [userId]="userId"></user-profile>
	  </mat-dialog-content>
	  <mat-dialog-actions style="justify-content: center;">
		  <button (click)="closeDialog()">Close</button>
	  </mat-dialog-actions>`,
  styleUrls: ["./user.settings.component.css"],
})
export class ProfileInfoDialogComponent {

  userId;
  isTranslator;

  constructor(private dialogRef: MatDialogRef<ProfileInfoDialogComponent>,
              @Inject(MAT_DIALOG_DATA) private data: any) {
  }

  ngOnInit() {
    if (this.data) {
      this.setUserId(this.data.id);
      this.isTranslator = this.data.isTranslator;
    }
  }

  private setUserId(userId: any) {
    this.userId = userId;
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
