/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./editor.component.css.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./inlayout.component";
import * as i4 from "../service/file.service";
import * as i5 from "@angular/platform-browser";
var styles_InlayoutComponent = [i0.styles];
var RenderType_InlayoutComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_InlayoutComponent, data: {} });
export { RenderType_InlayoutComponent as RenderType_InlayoutComponent };
function View_InlayoutComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.spannedHtml; _ck(_v, 0, 0, currVal_0); }); }
export function View_InlayoutComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["style", "max-height:600px; overflow:scroll; overflow-scrolling: auto;"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InlayoutComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.spannedHtml; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_InlayoutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "inlayout", [], null, null, null, View_InlayoutComponent_0, RenderType_InlayoutComponent)), i1.ɵdid(1, 114688, null, 0, i3.InlayoutComponent, [i4.FileService, i5.DomSanitizer, i1.Renderer2, i1.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InlayoutComponentNgFactory = i1.ɵccf("inlayout", i3.InlayoutComponent, View_InlayoutComponent_Host_0, { projectId: "projectId", fileName: "fileName", element: "element" }, {}, []);
export { InlayoutComponentNgFactory as InlayoutComponentNgFactory };
