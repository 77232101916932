/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../../project.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./documentation-file.component.ngfactory";
import * as i3 from "./documentation-file.component";
import * as i4 from "../../../service/file.service";
import * as i5 from "@angular/common";
import * as i6 from "./documentation-list.component";
import * as i7 from "../../../service/project.service";
var styles_DocumentationListComponent = [i0.styles];
var RenderType_DocumentationListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DocumentationListComponent, data: {} });
export { RenderType_DocumentationListComponent as RenderType_DocumentationListComponent };
function View_DocumentationListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "documentation-file", [], null, [[null, "removeEmitter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("removeEmitter" === en)) {
        var pd_0 = (_co.removeFile($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_DocumentationFileComponent_0, i2.RenderType_DocumentationFileComponent)), i1.ɵdid(2, 49152, null, 0, i3.DocumentationFileComponent, [i4.FileService], { file: [0, "file"], entityId: [1, "entityId"], entityType: [2, "entityType"], allowRemoval: [3, "allowRemoval"] }, { removeEmitter: "removeEmitter" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.entityId; var currVal_2 = _co.entityType; var currVal_3 = _co.allowRemoval; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_DocumentationListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "documentationFiles"], ["id", "uploadedFilesDiv"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocumentationListComponent_2)), i1.ɵdid(2, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.files; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_DocumentationListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "documentation-list-component"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocumentationListComponent_1)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.files.length > 0); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_DocumentationListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "documentation-list", [], null, null, null, View_DocumentationListComponent_0, RenderType_DocumentationListComponent)), i1.ɵdid(1, 114688, null, 0, i6.DocumentationListComponent, [i7.ProjectService, i4.FileService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DocumentationListComponentNgFactory = i1.ɵccf("documentation-list", i6.DocumentationListComponent, View_DocumentationListComponent_Host_0, { files: "files", allowRemoval: "allowRemoval", entityId: "entityId", entityType: "entityType" }, { fileRemovalEmmit: "fileRemovalEmmit" }, []);
export { DocumentationListComponentNgFactory as DocumentationListComponentNgFactory };
