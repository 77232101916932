import {switchMap} from 'rxjs/operators';
import {Component} from "@angular/core";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {TaskService} from "../service/task.service";
import {ReviewService} from "../service/review.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {UserService} from "../service/user.service";
import {Task} from "../dto/job";
import {existsAndNotEmpty} from "../util/apputil";


@Component({
  selector: 'review-create-component',
  templateUrl: "./review.create.component.html",
  styleUrls: ["./review.component.css"],
})
export class ReviewCreateComponent {

  task;
  review = Object();
  callback;

  anonymous = false;

  constructor(private reviewService: ReviewService,
              private taskService: TaskService,
              private userService: UserService,
              private route: ActivatedRoute,
              private router: Router,
              private snackbar: MatSnackBar) {

  }

  ngOnInit() {
    this.route.params.pipe(
      switchMap((params: Params) => this.taskService.getTask(params['id'])))
      .subscribe(t => {
        this.task = t;
        let currentUser = this.userService.getCurrentUser();
        this.setReview(t, currentUser);
      });
    this.route.queryParams
      .filter(params => params.callback)
      .subscribe(params => {
        this.callback = params.callback;
      });
  }

  isExistingReview() {
    return existsAndNotEmpty(this.review.id)
  }

  private setReview(t: Task, currentUser: any) {
    this.reviewService.getReviewsForTask(t.id).subscribe(r => {
      let existingFound = false;
      if (existsAndNotEmpty(r)) {
        for (let rev of (r as Array<any>)) {
          if (rev.writer !== undefined && rev.writer.id === currentUser.id) {
            this.review = rev;
            existingFound = true;
            break;
          }
        }
      }
      if (!existingFound) this.initReview(t, currentUser);
    });
  }

  private initReview(t: Task, currentUser: any) {
    this.review.task = Object();
    this.review.task.id = t.id;
    this.review.task.projectId = t.projectId;
    this.review.task.activity = t.activity;
    this.review.task.sourceLanguage = t.sourcelanguage;
    this.review.task.targetLanguage = t.targetlanguage;
    this.review.target = Object();
    this.review.target.id = t.vendorId;
    this.review.target.name = t.vendor;
    if (currentUser) {
      this.review.writer = Object();
      this.review.writer.id = currentUser.id;
      this.review.writer.name = currentUser.firstName + " " + currentUser.lastName;
    }
    this.review.scores = [];
    for (let category of this.reviewService.categories) {
      let score = Object();
      score.category = category;
      score.rate = 5;
      this.review.scores.push(score)
    }
  }

  postReview() {
    if (this.anonymous) {
      this.review.writer = undefined;
    }
    this.reviewService.postReview(this.review).subscribe(v => {
        this.snackbar.open("Review posted", "", {
          duration: 2000
        });
        this.redirect();
      },
      e => {
        console.error("Error posting review", e);
      }
    );
    this.redirect();
  }

  private redirect() {
    if (this.callback !== undefined)
      this.router.navigate([this.callback]);
    else
      this.router.navigate(["newproject"])
  }
}
