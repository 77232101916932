import {defaultIfEmpty} from 'rxjs/operators';
import {Component, OnInit, NgZone} from "@angular/core";
import {MatDialog} from "@angular/material/dialog";
import {UserService} from "./service/user.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {LogInService} from "./service/login.service";
import {UserInviteDialog} from "./usercomponent/user.invite.dialog";
import {environment} from "../environments/environment";
import {SimpleDialog} from "./simple-dialog.component";
import { Project } from './dto/project';
import { ProjectService } from './service/project.service';


@Component({
  selector: 'my-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {

  title = 'lilo';
  user;
  public isUserAuthenticated;
  private apiKey;

  ieWarnDismissed = false;

  constructor(private dialog: MatDialog,
              public _userService: UserService,
              private projectService: ProjectService,
              private router: Router,
              private route: ActivatedRoute,
              private loginService: LogInService,
              private ngZone: NgZone,
              ) {

  }

  ngOnInit(): void {
    // Show a warning if the user is using Internet Explorer
    if (!this.ieWarnDismissed && this.isInternetExplorer())
      this.ieWarnDialog();
    this._userService.signInFromLocalStorage().pipe(defaultIfEmpty(undefined)).subscribe(
      u => {
        if (u == undefined) {
          Promise.resolve().then(() => {
            this.checkForPopupRequest();
          });
        } else this.setUser(u);
      },
      error => {
        console.debug("An error occurred during sign-in from local storage: " + error);
      }
    );
    //Listen to the login emitter and update the authenticated field and user id after a join/signin/logout
    this._userService.loginEmitter.subscribe(n => {
        if (n === true) {
          this.setUser(this._userService.getCurrentUser());
        } else this.setUser(null);
      }
    );
    this.initDebug();
  }

  onActivate(event) {
    window.scroll(0, 0);
  }

  private setUser(user: any) {
    if (user != null) {
      this.isUserAuthenticated = true;
      this.user = user;
    } else {
      this.isUserAuthenticated = false;
      this.user = null;
    }
  }

  checkForPopupRequest() {
    this.route.queryParams
      .forEach((params: Params) => {
        if (params.hasOwnProperty('join'))
          this.joinPopup();
        else if (params.hasOwnProperty('signin'))
          this.signInPopup();
      });
  }

  isInternetExplorer() {
    console.log("User-Agent: " + window.navigator.userAgent);
    // Internet Explorer 6-11
    return /msie\s|trident\//i.test(window.navigator.userAgent);
  }

  ieWarnDialog() {
    let dialogRef = this.dialog.open(SimpleDialog, {
      width: '400px', disableClose: true, ariaLabel: "Ack, Internet Explorer!", role: "alertdialog",
      panelClass: 'simpleDialog',
      data: {
        dialogHtml: "<p>Hey there, looks like you are using Internet Explorer?! <br/>" +
          "<br/>" +
          "Sadly this browser is rather outdated and no longer supported. " +
          "We strongly advise switching to a modern browser, such as <a href='https://www.mozilla.org/en-US/firefox/' target='_blank'>Firefox</a>, " +
          "<a href='https://www.google.com/chrome/' target='_blank'>Chrome</a>, " +
          "<a href='https://www.opera.com/' target='_blank'>Opera</a> or even Edge (which comes pre-installed on recent Windows machines).<br/>" +
          "<br/>" +
          "You <b>can</b> continue with your current browser, but know that <b>some functions may be slow or not work</b>.</p>",
        dialogButtonName: "Gotcha"
      }
    });
    dialogRef.afterClosed().subscribe(v => this.ieWarnDismissed = true);
  }

  signInPopup() {
    let dialogRef = this.openSignInPopup();
  }

  joinPopup() {
    let dialogRef = this.openJoinDialog('');
  }

  openJoinDialog(callback?: string) {
    return this.loginService.openJoinDialog(callback);
  }

  openSignInPopup() {
    return this.loginService.openSignInPopup('');
  }

  openInviteDialog() {
    return this.dialog.open(UserInviteDialog, {width: '50%'});
  }

  newProject() {
    if (this.router.url === '/newproject' || this.router.url === '/') {
      const p = new Project();
      this.projectService.initProject(p, this._userService.getRemoteUserIp()).subscribe(result => {
          const projectId = result.id;
          this.router.navigate(['/pdetail', projectId]);
        },
        err => {
          console.error("Error initializing project", err);
        }
      );
    } else {
      this.router.navigate(['/newproject']);
    }
  }

  isTranslator() {
    return this._userService.isCurrentUserTranslator;
  }

  isInternalTranslator() {
    return this._userService.isCurrentUserInternalTranslator;
  }

  isEnableRequest() {
    let user = this._userService.getCurrentUser();
    if (user && user.enableRequests != undefined) {
      return user.enableRequests;
    } else
      return false;
  }

  companyId() {
    let user = this._userService.getCurrentUser();
    if (user) {
      return user.companyId;
    } else
      return undefined;
  }

  isDebug() {
    return environment.isDebug;
  }

  logout() {
    this._userService.signOut().subscribe({
      next: () => {
        this.user = null;
        this.isUserAuthenticated = false;
        let dialogRef = this.dialog.open(SimpleDialog, {
          width: "400px",
          panelClass: 'simpleDialog',
          data: {dialogMessage: "You have successfully logged out."}
        });
        dialogRef.afterClosed().subscribe(r =>
          this.router.navigateByUrl("")
        )
      },
      complete: () => {
        // Completed
      }
    });
  }


  navigate(commands: any[]): void {
    this.ngZone.run(() => this.router.navigate(commands)).then();
  }

  // Debug handling
  initDebug() {
    if (this.isDebug()) {
      this.isOrigDebug = true;
      let isDebug = localStorage.getItem("isDebug");
      if (isDebug != null)
        environment.isDebug = JSON.parse(isDebug);
    }
  }

  toggleDebug() {
    environment.isDebug = !environment.isDebug;
    if (!environment.isDebug)
      localStorage.setItem("isDebug", JSON.stringify(false));
    else
      localStorage.removeItem("isDebug")
  }

  isOrigDebug = false;
}
