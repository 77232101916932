import * as tslib_1 from "tslib";
import { AfterViewInit, ElementRef, EventEmitter, OnDestroy, OnInit } from "@angular/core";
import { InvoiceService } from "../../service/invoice.service";
import { getPaymentImage } from "../../util/userutil";
export class CreditCardComponent {
    constructor(invoiceService) {
        this.invoiceService = invoiceService;
        this.cardChangeEmitter = new EventEmitter();
        this.cardHandler = this.onChange.bind(this);
        this.currentCard = undefined;
    }
    ngOnInit() {
        this.invoiceService.findPaymentMethod(this.userId).subscribe(v => {
            if (v !== undefined && v.card !== undefined) {
                this.currentCard = v.card;
                this.paymentImage = this.getPaymentImageUrl(this.currentCard.brand);
            }
        });
    }
    ngAfterViewInit() {
        this.initCardInfoView();
    }
    ngOnDestroy() {
        if (this.card != null) {
            this.card.removeEventListener('change', this.cardHandler);
            this.card.destroy();
        }
    }
    onChange({ error }) {
        if (error) {
            this.error = error.message;
        }
        else {
            this.error = null;
            this.cardChangeEmitter.emit(this.card);
        }
    }
    changeCardInfo() {
        this.currentCard = undefined;
    }
    initCardInfoView() {
        if (this.cardInfo !== undefined) {
            this.card = elements.create('card', { style: { base: { 'max-width': '200px' } } });
            this.card.mount(this.cardInfo.nativeElement);
            this.card.addEventListener('change', this.cardHandler);
        }
    }
    storeCreditCard() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const localThis = this;
            if (this.currentCard !== undefined) {
                return Promise.resolve();
            }
            yield this.invoiceService.getPaymentIntent()
                .toPromise()
                .then(sk => {
                return stripe.handleCardSetup(sk, this.card);
            })
                .then(function (result) {
                console.debug("Stored credit card for user ", result);
                if (result.error) {
                    throw result.error;
                }
                else {
                    return localThis.invoiceService.storeUserPaymentMethod(result).toPromise();
                }
            });
        });
    }
    getPaymentImageUrl(paymentMethod) {
        return './../../assets/paymentmethod/' + getPaymentImage(paymentMethod);
    }
}
