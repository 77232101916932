import {Component, ElementRef, Input, Renderer2, ViewEncapsulation} from "@angular/core";
import {FileService} from "../service/file.service";
import {EditorRow} from "./dto/transunit";
import {DomSanitizer} from '@angular/platform-browser';


@Component({
  selector: 'inlayout',
  template: '<div style="max-height:600px; overflow:scroll; overflow-scrolling: auto;">' +
    '      <div *ngIf="spannedHtml" [innerHTML]="spannedHtml"></div>' +
    '    </div>',
  styleUrls: ['editor.component.css'],
  // "ViewEncapsulation.None" is specified here so the innerHTML set in the editable divs would properly take the CSS
  // style into account.
  encapsulation: ViewEncapsulation.None
})
export class InlayoutComponent{

  spannedHtml;

  @Input()
  projectId;
  @Input()
  fileName;

  constructor(private fileService: FileService,
              private sanitizer:DomSanitizer,
              private renderer: Renderer2, private elRef: ElementRef) {

  }

  ngOnInit(){
    this.initSpannedHtml()
  }

  handleSelection(prevElement: EditorRow, newElement: EditorRow){
    if(prevElement!=null){
      let inLayoutElement = this.elRef.nativeElement.querySelector('.seg' + prevElement.id);
      if(inLayoutElement!=null)
        this.renderer.setStyle(inLayoutElement, "border", "none");
      else
        console.warn("Project " + this.projectId + " -- file " + this.fileName + ": segment " + prevElement.id + " not found");
    }
    if(newElement!=undefined) {
      let inLayoutElement = this.elRef.nativeElement.querySelector('.seg' + newElement.id);
      if(inLayoutElement!=null) {
        this.renderer.setStyle(inLayoutElement, "border", "2px solid #4ebdcc");
        inLayoutElement.scrollIntoView({
            behavior: 'auto',
            block: 'center',
            inline: 'center'
          }
        )
      }
    }
  }

  selectSpan(spanId:string){
    // console.debug(spanId);
  }

  initSpannedHtml() {
    this.spannedHtml="Loading...";
    this.fileService.downloadSegHtml(this.projectId, this.fileName)
      .subscribe(v => {
        let reader = new FileReader();
        reader.onload = (e) => {
          let text = reader.result.toString();
          this.spannedHtml = this.sanitizer.bypassSecurityTrustHtml(text);
        };
        reader.readAsText(v)
      }, e=>{
        this.spannedHtml="Layout is not available for this file"
      });
    //TODO add eventlisteners which emit a segmentId which would then be processed by the editor to select the value
  }

  private _element:EditorRow;

  get element():EditorRow{
    return this._element
  }

  @Input()
  set element(el:EditorRow){
    this.handleSelection(this._element, el);
    this._element=el;
  }
}
