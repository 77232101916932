<div class="targetFileList">

  <div class="listTileViewToggle">
    <div *ngIf="isTileView">
      <img src="../../../assets/icon_tiles_active.svg" alt="toggle tile view"/>
      <img src="../../../assets/icon_list_not_active.svg" alt="toggle list view"
           title="Switch to list view" style="cursor: pointer;"
           (click)="setTileView(false)"/>
    </div>
    <div *ngIf="!isTileView">
      <img src="../../../assets/icon_tiles_not_active.svg" alt="toggle tile view"
           title="Switch to tile view" style="cursor: pointer;"
           (click)="setTileView(true)"/>
      <img src="../../../assets/icon_list_active.svg" alt="toggle list view"/>
    </div>
  </div>

  <div *ngIf="isTileView">
    <div class="fileListHeader">
      <div class="fileListHeaderValue">Files</div>
    </div>
    <div class="targetFileListRows">
      <div *ngFor="let lp of getLangPairs()" class="fileListRow targetFileListLang">
        <span class="fileListRowValue" style="margin-bottom: 15px;">{{lp.target | languagePipe | async}}</span>
        <langpair-target-files [project]="project" [langPair]="lp" [viewAsTiles]="isTileView"></langpair-target-files>
      </div>
    </div>
  </div>

  <div *ngIf="!isTileView">
    <div class="fileListHeader">
      <div class="fileListLanguageColumn fileListHeaderValue">Language</div>
      <div class="fileListFilesColumn fileListHeaderValue">Files</div>
    </div>
    <div class="targetFileListRows">
      <div *ngFor="let lp of getLangPairs()" class="fileListRow">
        <div class="fileListLanguageColumn fileListRowValue">{{lp.target | languagePipe | async}}</div>
        <div class="fileListFilesColumn fileListRowValue">
          <langpair-target-files [project]="project" [langPair]="lp" [viewAsTiles]="isTileView"></langpair-target-files>
        </div>
      </div>
    </div>
  </div>

  <!-- TODO: download all -->
  <!-- TODO: add checkbox in front of language and make download selected -->
</div>
