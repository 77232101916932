import {from as observableFrom} from 'rxjs';
import {filter, mergeMap, switchMap} from 'rxjs/operators';
import {Component, DoCheck, EventEmitter, Inject, Input, OnChanges, Output} from "@angular/core";
import {LanguageService} from "../../service/language.service";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {CookieService} from "ngx-cookie-service";



@Component({
  selector: 'multi-language-selection-dialog',
  template: '<div style="height: 100%;">' +
    '<h3 style="text-align: left;">Select language(s):</h3>' +
    '<div style="height: 80%; vertical-align:top;">' +
    '<select multiple style="width: 100%; height: 90%;" [(ngModel)]="selectedLanguages">' +
    '<option *ngFor="let language of languages" style="width: 100%" [value]="language">{{language|languagePipe|async}}</option>' +
    '</select> ' +
    '<p style="font-size:x-small;">Use CTRL + click to add multiple languages in one go.</p>' +
    '</div> ' +
    '<div style="display: flex; flex-wrap: wrap;" class="dialogButton">' +
    '<button (click)="add()">Add</button>' +
    '<button (click)="dialogRef.close()">Close</button>' +
    '</div>' +
    '</div>',
  styleUrls: ['../project.component.css']
})


export class MultiLanguageSelectionComponent {

  selectedLanguages = [];

  constructor(public dialogRef: MatDialogRef<MultiLanguageSelectionComponent>,
              @Inject(MAT_DIALOG_DATA) public languages: string[]) {
  }

  add() {
    this.dialogRef.close(this.selectedLanguages);
  }
}
