import {from as observableFrom} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {Component, Input} from "@angular/core";
import {Task} from "../dto/job";
import {ProjectService} from "../service/project.service";
import {Router} from "@angular/router";


@Component({
  selector: 'task-card',
  template:
    '  <div  (click)="openTask(task)" class="taskCard">' +
    '   <h2 *ngIf="projectInfo" style="word-wrap: break-word;">{{projectInfo.title}}</h2>' +
    '   <h4>{{task.activity}}: {{task.sourcelanguage|languagePipe|async}} - {{task.targetlanguage|languagePipe|async}}</h4>' +
    '   <h5># files: {{fileCount()}}</h5>' +
    '   <h5># words: {{wordcount}}</h5>' +
    '   <h5>Due date: {{task.duedate | date:\'MMMM d, y\'}}</h5>' +
    '   <h5 *ngIf="task.completed">Completion date: {{task.completed | date:\'MMMM d, y, HH:mm\'}}</h5>' +
    '   <h5 *ngIf="task.acceptdate && !task.completed">Progress: {{taskProgress()}}</h5>' +
    '   <h5 *ngIf="task.price">Price: <price [price]="task.price"></price></h5>' +
    '   <fieldset *ngIf="task.comments">' +
    '    <legend>Comments:</legend>' +
    '    <p>{{task.comments}}</p>' +
    '   </fieldset>' +
    '  </div>',
  styleUrls: ['task-dashb.component.css']
})
export class TaskCardComponent {

  @Input()
  task: Task;
  projectInfo;
  wordcount = 0;

  constructor(private router: Router,
              public projectService: ProjectService) {
  }

  ngOnInit() {
    this.projectService.getProjectBaseInfo(this.task.projectId).subscribe(info => {
      this.setProjectInfo(info);
    });
  }

  setProjectInfo(info: any) {
    this.projectInfo = info;
    this.wordcount = 0;
    observableFrom(this.projectInfo.files).pipe(
      map(file => file as any),
      filter(v => {
        return v.type === "SOURCE";
      }),
      //TODO currently the filter is excluded until the update projects after start is finished
      // .filter(file =>
      //   this.task.jobs.find(j => j.file == file.name) != null
      // )
      map(file => {
        return file;
      }),
      map(file => file.wordcount),)
      .forEach
      (wc => {
        this.wordcount += wc;
      });
  }

  openTask(task: Task) {
    this.router.navigate(['/tdetail', task.id]);
  }

  fileCount() {
    return this.task.jobs.length;
  }

  taskProgress(task: Task) {
    return "TODO";
  }
}
