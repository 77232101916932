/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./user.settings.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/material/dialog";
import * as i4 from "./user-profile.component.ngfactory";
import * as i5 from "./user-profile.component";
import * as i6 from "@angular/router";
import * as i7 from "../service/vendor.service";
import * as i8 from "../service/language.service";
import * as i9 from "../service/review.service";
import * as i10 from "./profile.info.dialog.component";
var styles_ProfileInfoDialogComponent = [i0.styles];
var RenderType_ProfileInfoDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProfileInfoDialogComponent, data: {} });
export { RenderType_ProfileInfoDialogComponent as RenderType_ProfileInfoDialogComponent };
function View_ProfileInfoDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Make sure to fill in all relevant information to ensure high visibility."]))], null, null); }
export function View_ProfileInfoDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "p", [["class", "profileDialogTitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" This is how your profile is seen by others."])), (_l()(), i1.ɵeld(2, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProfileInfoDialogComponent_1)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 3, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(6, 16384, null, 0, i3.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "user-profile", [], null, null, null, i4.View_UserProfileComponent_0, i4.RenderType_UserProfileComponent)), i1.ɵdid(8, 114688, null, 0, i5.UserProfileComponent, [i6.ActivatedRoute, i7.VendorService, i8.LanguageService, i9.ReviewService], { userId: [0, "userId"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 3, "mat-dialog-actions", [["class", "mat-dialog-actions"], ["style", "justify-content: center;"]], null, null, null, null, null)), i1.ɵdid(10, 16384, null, 0, i3.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(11, 0, null, null, 1, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeDialog() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Close"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isTranslator; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.userId; _ck(_v, 8, 0, currVal_1); }, null); }
export function View_ProfileInfoDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "profile-info-dialog", [], null, null, null, View_ProfileInfoDialogComponent_0, RenderType_ProfileInfoDialogComponent)), i1.ɵdid(1, 114688, null, 0, i10.ProfileInfoDialogComponent, [i3.MatDialogRef, i3.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProfileInfoDialogComponentNgFactory = i1.ɵccf("profile-info-dialog", i10.ProfileInfoDialogComponent, View_ProfileInfoDialogComponent_Host_0, {}, {}, []);
export { ProfileInfoDialogComponentNgFactory as ProfileInfoDialogComponentNgFactory };
