<div class="main projectFollowup nouser" *ngIf="!userService.isLoggedIn()">
  <h3>Please <span (click)="openLogin()" class="login">log in</span> to continue.</h3>
</div>
<div class="main projectFollowup" *ngIf="project !== undefined && userService.isLoggedIn()">
  <div class="side updateorder">
    <project-update-overview [project]="project" [canUpdate]="hasChanges && validTaskCount && !updateOrderSubmit"
                             (updateOrderEmitter)="updateOrder()"></project-update-overview>
  </div>
  <project-info [project]="project" class="section" style="display: block;"></project-info>
  <div class="section origFilesSection">
    <div (click)="toggleCollapseOrigFiles()" style="display: flex; cursor: pointer;">
      <h1>Original files</h1>
      <img src="../../assets/icon_down.svg"
           [ngClass]="{'collapseIcon': true, 'collapseIconOpen': !collapseOrigFiles, 'collapseIconClosed': collapseOrigFiles}"/>
    </div>
    <div [collapse]="collapseOrigFiles">
      <source-file-list [files]="sourceFiles" [pId]="project.id" [allowRemoval]="false"
        [expectedLanguage]="sourceLanguage" [service]="project.service"></source-file-list>
      <div style="margin-top: 10px;">
        <div class="documentationFilesSection" *ngIf="docFiles != null && docFiles.length > 0">
          <div class="documentationInfo">
            <h4>Documentation files</h4>
            <p>Reference material, Terminology, Product information, ...</p>
            <label class="documentationWarning">These files will not be translated.</label>
          </div>
          <div>
          </div>
        </div>
        <documentation-list [files]="docFiles" [entityId]="project.id" [entityType]="'project'" [allowRemoval]="true" >
        </documentation-list>
        <documentation-file-add [entityId]="project.id" [entityType]="'project'" [uploadedFiles]="docFiles"
                        (onFileUpload)="onDocFileUploaded($event)"></documentation-file-add>
      </div>
    </div>
  </div>
  <div class="section availableFilesSection">
    <h1>Available files</h1>
    <target-file-list [project]="project"></target-file-list>
  </div>
  <div class="section translatorSection">
    <h1>Your translator</h1>
    <div *ngIf="project.langpairs != undefined">
      <language-bar *ngFor="let langpair of project.langpairs" id="lb_{{langpair.target}}"
                    [projectId]="project.id" [langpair]="langpair" [files]="sourceFiles"
                    [duedate]="project.duedate"
                    [translators]="undefined" [revisers]="undefined"
                    [service]="project.service"
                    [isQuote]="project.isQuote"
                    [loadInternally]="true" [isCollapsed]="openLanguageBar != langpair.target"
                    (onExpand)="setOpenLanguageBar(langpair)"
                    [projectFinal]="isCompleted || isCanceled">
      </language-bar>
    </div>
  </div>
  <div class="section orderChangedSection" *ngIf="false">
    <div [ngClass]="{'section': true, 'custDetailsSection':true}" id="userInfo">
      <h1>Your details</h1>
      <project-user-info *ngIf="project != undefined" [project]="project"></project-user-info>
    </div>
    <div [ngClass]="{'section': true, 'paymentSection':true}" id="paymentSection">
      <h1>Payment</h1>
      <project-payment *ngIf="this.userService.isLoggedIn()" id="paymentComponent" [project]="project">
      </project-payment>
    </div>
  </div>
  <div class="orderChangedSection" *ngIf="!isCompleted && !isCanceled">
    <div [ngClass]="{'section': true, 'updateOrderSection':true}" id="orderSection">
      <div style="float: left">
        <button class="btnCancel" (click)="cancelProject()" [disabled]="!canCancel">Cancel</button>
      </div>
      <div style="float: right">
        <div *ngIf="project.paymentmethod ==='PAYPAL'" [hidden]="!(hasChanges && validTaskCount)||updateOrderSubmit">
          <paypal [project]="project" (approveEmitter)="updateOrder()" ></paypal>
          <img *ngIf="updateOrderSubmit" src="../../assets/loading_small.gif"/>
        </div>
        <button *ngIf="project.paymentmethod !='PAYPAL'" (click)="updateOrder()" [disabled]="!(hasChanges && validTaskCount)||updateOrderSubmit">Update order <img *ngIf="updateOrderSubmit" src="../../assets/loading_small.gif"/></button>
      </div>
    </div>
  </div>
</div>
