import {Component, OnInit} from "@angular/core";
import {UserService} from "../../../service/user.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {CustomerGroup} from "../../../types/customer-group";
import {ColumnType, DynamicTableHeader} from "../../../util/component/dynamic-table/dynamic-table.model";
import {DynamicTableClickEvent} from "../../../util/component/dynamic-table/dynamic-table.component";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'mimicuser',
  templateUrl: 'mimicuser.component.html',
  styleUrls: ['mimicuser.component.css'],
})
export class MimicUserComponent {

  public mimicUserMail: String = null;


  constructor(public userService: UserService,
    public snackbar: MatSnackBar) {
  }


  public isMimicButtonValid(): boolean {
    return this.mimicUserMail != null && this.mimicUserMail.trim().length > 0;
  }

  public mimicUser(): void {
    let mimicRequest = {mimicUserMail: this.mimicUserMail.trim()};
    this.userService.mimicUser(mimicRequest).subscribe(r => {
      this.snackbar.open("You are mimic as the requested user", '', {duration: 2000});
    });
  }

}
