
	  <div *ngIf="file" class="preUploadItem preUploadFile" style="" [title]="file.name">
		  <div class="fileInfo">
			  <h5 [ngStyle]="{'text-decoration': file.warning != null ? 'line-through' : 'initial'}">{{file.name|filenamePipe:24:true}}</h5>
			  <p *ngIf="file.warning == null" class="fileSize">{{file.size|filesize}}</p>
			  <p *ngIf="file.warning != null" class="fileWarning">Oops! We don't support this format.</p>
		  </div>
		  <div class="removeDiv">
		  <button class="removeFileCircle" title="Remove file" (click)="emitFileRemove()">
			  <img src="../../assets/delete_icon_resized.svg" alt="Remove file" style="width: 10px;">
		  </button>
		  </div>
	  </div>