export class TransUnit {
  id: string;
  srcLangCode: string;
  tgtLangCode: string;
  segments: Segment[] = [];
}

export class Segment {
  id: string;
  type: string;
  score: string;
  content: any[];
  prefix: any[];
  suffix: any[];
  trimmedContent: any[];
  origin: any;
}

export class Reference {
  type: string;
  selfClosed: boolean;
  start: boolean;
  attribs: Map<string, string>;
}

export class EditorRow {
  id: string;
  tuId: string;
  segId: string;
  sourceSeg: Segment;
  targetSeg: Segment;
  userSeg: Segment;
  currentSeg: string;
  repeatedMatch: Segment;
  tmSeg: Segment;
  mtSeg: Segment;
  modified: boolean;
  refs: Reference[];
  missingRefs: Reference[];
  lastPos: number;
  noText: boolean = false;
  // todo
  checked: boolean;
}
