import {tap} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable} from "rxjs";


@Injectable()
export class JWTInterceptor implements HttpInterceptor {

  constructor() {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let localStorageUser = localStorage.getItem("user");
    if (localStorageUser != null && localStorageUser.toString().length > 0) {
      let localStorageUserJ = JSON.parse(localStorageUser);
      let apiKey = localStorageUserJ.apiKey;
      //The key is not always added properly to use localStorage, in case apiKey is not directly available it might be available in the api object.
      //TODO cleanup sign in/mail authentication code to properly return the apiKey
      if (apiKey == undefined && localStorageUserJ.api != undefined)
        apiKey = localStorageUserJ.api.apiKey;
      if (apiKey) {
        //Followed this example: https://ryanchenkie.com/angular-authentication-using-the-http-client-and-http-interceptors
        let reqClone = req.clone({setHeaders: {Authorization: apiKey}});
        return next.handle(reqClone).pipe(tap(event => {
          }
          , (err: any) => {
            if (err instanceof HttpErrorResponse) {
              if (err.status === 401) {
                console.error("401 error", err);
                localStorage.removeItem("user");
              }
            }
            if (err.status === 500) {
            }
          }));
      }
    }
    return next.handle(req);
  }

}
