/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./chat.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./message.component.ngfactory";
import * as i3 from "./message.component";
import * as i4 from "../service/user.service";
import * as i5 from "../service/chat.service";
import * as i6 from "../service/language.service";
import * as i7 from "@angular/common";
import * as i8 from "./new.message.component.ngfactory";
import * as i9 from "./new.message.component";
import * as i10 from "./chat.component";
import * as i11 from "@angular/material/snack-bar";
import * as i12 from "@angular/router";
var styles_ChatComponent = [i0.styles];
var RenderType_ChatComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ChatComponent, data: {} });
export { RenderType_ChatComponent as RenderType_ChatComponent };
function View_ChatComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Please log in to be able to send and read messages. "]))], null, null); }
function View_ChatComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "message", [], null, null, null, i2.View_MessageComponent_0, i2.RenderType_MessageComponent)), i1.ɵdid(1, 114688, null, 0, i3.MessageComponent, [i4.UserService, i5.ChatService, i6.LanguageService], { user: [0, "user"], message: [1, "message"], isThread: [2, "isThread"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.user; var currVal_1 = _v.context.$implicit; var currVal_2 = true; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_ChatComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChatComponent_4)), i1.ɵdid(2, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.messages; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ChatComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "message", [], null, null, null, i2.View_MessageComponent_0, i2.RenderType_MessageComponent)), i1.ɵdid(1, 114688, null, 0, i3.MessageComponent, [i4.UserService, i5.ChatService, i6.LanguageService], { user: [0, "user"], message: [1, "message"], isThread: [2, "isThread"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.user; var currVal_1 = _co.newMessage; var currVal_2 = true; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_ChatComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["No messages yet."]))], null, null); }
function View_ChatComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "messagesDiv"], ["id", "messagesDiv"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChatComponent_3)), i1.ɵdid(2, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChatComponent_5)), i1.ɵdid(4, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChatComponent_6)), i1.ɵdid(6, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.messages && (_co.messages.length > 0)); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.newMessage != null); _ck(_v, 4, 0, currVal_1); var currVal_2 = (((_co.messages == undefined) || (_co.messages.length == 0)) && (_co.newMessage == null)); _ck(_v, 6, 0, currVal_2); }, null); }
function View_ChatComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "newMessageDiv"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "new-message", [], null, [[null, "sendEmitter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("sendEmitter" === en)) {
        var pd_0 = (_co.addNewMessage($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_NewMessageComponent_0, i8.RenderType_NewMessageComponent)), i1.ɵdid(2, 114688, null, 0, i9.NewMessageComponent, [], { placeholderText: [0, "placeholderText"], user: [1, "user"] }, { sendEmitter: "sendEmitter" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Your message..."; var currVal_1 = _co.getUser(); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_ChatComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "chat"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChatComponent_1)), i1.ɵdid(2, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChatComponent_2)), i1.ɵdid(4, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChatComponent_7)), i1.ɵdid(6, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.user; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.user; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.user; _ck(_v, 6, 0, currVal_2); }, null); }
export function View_ChatComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "chat", [], null, null, null, View_ChatComponent_0, RenderType_ChatComponent)), i1.ɵdid(1, 245760, null, 0, i10.ChatComponent, [i4.UserService, i5.ChatService, i11.MatSnackBar, i12.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ChatComponentNgFactory = i1.ɵccf("chat", i10.ChatComponent, View_ChatComponent_Host_0, { projectId: "projectId" }, {}, []);
export { ChatComponentNgFactory as ChatComponentNgFactory };
var styles_ChatNotification = [];
var RenderType_ChatNotification = i1.ɵcrt({ encapsulation: 2, styles: styles_ChatNotification, data: {} });
export { RenderType_ChatNotification as RenderType_ChatNotification };
export function View_ChatNotification_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["A new message has been sent:"])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["To view more, click "])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "a", [], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["here"])), (_l()(), i1.ɵted(-1, null, ["."]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.messageContent; _ck(_v, 6, 0, currVal_0); var currVal_1 = i1.ɵinlineInterpolate(1, "", _co.url, ""); _ck(_v, 9, 0, currVal_1); }); }
export function View_ChatNotification_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "chat-notification", [], null, null, null, View_ChatNotification_0, RenderType_ChatNotification)), i1.ɵdid(1, 114688, null, 0, i10.ChatNotification, [i11.MAT_SNACK_BAR_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ChatNotificationNgFactory = i1.ɵccf("chat-notification", i10.ChatNotification, View_ChatNotification_Host_0, {}, {}, []);
export { ChatNotificationNgFactory as ChatNotificationNgFactory };
