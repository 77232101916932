import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from "@angular/core";
import {InvoiceService} from "../../service/invoice.service";
import {getPaymentImage} from "../../util/userutil";
import { PricingService } from '../../service/pricing.services';

declare var paypal;

@Component({
  selector: 'paypal',
  templateUrl: './paypal.component.html',
  styleUrls: ['../project.component.css'],
})
export class PaypalComponent implements OnInit {

  @ViewChild('paypal', {static: true})
  paypalElement: ElementRef;

  @Input()
  project: any;

  @Output()
  approveEmitter = new EventEmitter<any>();


  constructor(private invoiceService: InvoiceService,
    private pricingService: PricingService) {
  }

  ngOnInit(): void {
    paypal
        .Buttons({
          style: {
            layout: 'vertical',
            color: 'blue',
            shape: 'pill',
            label: 'checkout'
          },
          createOrder: (data, actions) => {
            return this.createPaypalOrder(actions);
          },
          onApprove: async (data, actions) => {
            let orderData: any;
            await actions.order.authorize().then(function(authorization) {
                // Get the authorization id
                const authorizationID = authorization.purchase_units[0]
                .payments.authorizations[0].id;
                console.debug('Authorazation id', authorizationID);
                orderData = {orderID: data.orderID,
                  authorizationID: authorizationID};
            });
            // set the pu that is created during order creation
            orderData.pu = this.cachedPurchaseUnit;
            this.invoiceService.storeProjectPaypal(this.project.id, orderData)
                  .subscribe(a =>
                    console.debug('Authorization saved'),
                    e => console.error(e)
                  );
            this.approveEmitter.emit(orderData);
            return {};
          },
          onError: err => {
            console.log(err);
          }

        })
        .render(this.paypalElement.nativeElement);
  }


  // This is the pu that is used to create the order and is given
  // afterwards to the server to store in the db
  cachedPurchaseUnit:any = undefined;

  async createPaypalOrder(actions: any) {
    let order = <any>await this.pricingService.getTotalProjectPrice(this.project.id)
    .map(p => p.totalIncVat())
    .map(p => Math.round(p * 100) / 100)
    .map(p => {
      return this.getPaypalPurchaseUnit('' + p);
    })
    .map(pu => {
      // Cache the pruchase unit that is used so it can be used for the db as well
      this.cachedPurchaseUnit = pu;
      return {
        purchase_units: [pu]
      };
    })
    .map(o => {
      return actions.order.create(o)
    }).toPromise()
    console.debug(order);
    return order;
  }

  getPaypalPurchaseUnit(price: string): any {
    return {
      description: this.project.title,
        amount: {
          currency_code: 'EUR',
          value: price
        }
      };
  }
}
