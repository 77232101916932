<div class="projectTask">
  <div class="profilepic">
    <mugshot [user]="user" [width]="'80px'" [height]="'80px'" [showIncomplete]="false"></mugshot>
  </div>
  <div>
    <div class="projectTaskDetails">
      <div style="width: 100%">
        <h4 *ngIf="state != 'counterproposed'">{{task.vendor}}</h4>
        <div *ngIf="state == 'send'" class="projectTaskValList">
          <p>Wait for translator to respond.</p>
          <p><span class="projectTaskVal">Sent</span> {{task.senddate|date:'y/MM/dd HH:mm'}}</p>
        </div>
        <div *ngIf="state == 'accepted'" class="projectTaskValList">
          <p><span class="projectTaskVal">Expected</span> {{task.expecteddate|date:'y/MM/dd HH:mm'}}</p>
        </div>
        <div *ngIf="state == 'rejected'" class="projectTaskValList">
          <p>This task is sadly declined.</p>
        </div>
        <div *ngIf="state == 'counterproposed'" class="projectTaskValList">
          <h4 style="color: var(--alternative-color)">{{task.vendor}} responded to your request</h4>
          <p>
            <em>{{task.counterProposal.text}}</em>
          </p>
          <p *ngIf="(task.counterProposal.date|date:'y/MM/dd HH:mm') > (task.duedate|date:'y/MM/dd HH:mm')"><span class="projectTaskVal">
            Suggested alternative due date</span> {{task.counterProposal.date|date:'y/MM/dd HH:mm'}}
          </p>
          <p *ngIf="task.counterProposal.extraCost > 0">
            <span class="projectTaskVal">Suggested price for this Sworn Translation: </span>
            <price [price]="getProposalPrice()"></price>
          </p>
        </div>
        <div *ngIf="state == 'completed'" class="projectTaskValList">
          <p>Hooray, this task is completed!</p>
        </div>
        <div *ngIf="state == 'feedback'" class="projectTaskValList" style="margin-bottom: 10px;">
          <p>{{task.activity}}</p>
          <label class="projectTaskVal">Sent</label><p>{{task.senddate|date:'y/MM/dd HH:mm'}}</p>
          <label class="projectTaskVal">Expected</label><p>{{task.expecteddate|date:'y/MM/dd HH:mm'}}</p>
          <label class="projectTaskVal">Completed</label><p>{{task.completed|date:'y/MM/dd HH:mm'}}</p>
        </div>
        <div class="projectTaskValList" *ngIf="!isQuote">
          <!--Notify that service fee is included for internals-->
          <p><span class="projectTaskVal">Price</span>
            <price [price]="getPrice()"></price>
            <span *ngIf="(state == 'counterproposed') && (task.counterProposal.extraCost > 0)" style="color: var(--alternative-color)"> => new price:
              <price [price]="getProposalPrice()"></price></span>
          </p>
        </div>
      </div>
    </div>
    <div class="projectTaskActions">
      <div *ngIf="state=='send'">
        <button (click)="removeTask()">Choose another</button>
      </div>
      <div *ngIf="state=='accepted'"></div>
      <div *ngIf="state=='rejected'">
        <button (click)="removeTask()">Choose another</button>
      </div>
      <div *ngIf="state=='counterproposed'">
        <button (click)="acceptCounterProposal()">Accept</button>
        <button (click)="removeTask()">Choose another</button>
      </div>
      <div *ngIf="state=='completed'">
        <button (click)="rateTranslator()">Give feedback on translator</button>
      </div>
    </div>
  </div>
</div>
