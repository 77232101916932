import {Component, OnInit} from "@angular/core";
import {UserService} from "../../service/user.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Project} from "../../dto/project";
import {ProjectService} from "../../service/project.service";
import {getUserNameFromProject} from "../../util/jobutil";


@Component({
  selector: 'projectfollowuplist',
  templateUrl: 'project-followup-list.component.html',
  styleUrls: ['project-followup-list.component.css'],
})
export class ProjectFollowupListComponent implements OnInit {

  projectStates = ["Draft", "Running", "Completed"];
  projects: Project[] = undefined;

  filteredProjects: Project[] = undefined;
  projectState = "Running";
  filterText;
  loading: boolean = false;


  constructor(private route: ActivatedRoute,
              private router: Router,
              public userService: UserService,
              private projectService: ProjectService) {
  }

  ngOnInit() {
    this.loadProjects(this.projectState);
  }

  findProjects(status: string) {
    // Only do find if current status is different than given one
    if (this.projectState !== status) {
      this.loadProjects(status)
    }
  }

  private loadProjects(status: string) {
    let start = Date.now();
    this.projectState = status;
    this.loading = true;
    this.projectService.getAllProjects(status)
      .subscribe(projects => {
          this.projects = this.sort(projects);
          this.setFilteredProjects();
          console.debug("Loading " + this.filteredProjects.length + " projects took " + (Date.now() - start) + " ms.");
        },
        error => {
          this.loading = false;
          console.error("Error loading projects", error);
        },
        () => this.loading = false);
  }

  sort(projects: Project[]) {
    return projects.sort((p1, p2) => {
      if (p1.creationdate && p2.creationdate) {
        if (p1.creationdate > p2.creationdate)
          return -1;
        if (p1.creationdate < p2.creationdate)
          return 1;
        else return 0;
      } else if (p1.creationdate)
        return -1;
      else if (p2.creationdate)
        return 1;
      else return 0;
    });
  }

  setFilteredProjects() {
    if (this.filterText && this.filterText.length > 0)
      this.filteredProjects = this.projects
        .map(v => filterProject(v, this.filterText))
        .filter(v => v != undefined);
    else this.filteredProjects = this.projects;
  }

  refresh() {
    this.loadProjects(this.projectState);
  }

  gotoProject(p: Project) {
    // 'nfreq' (= no-force-requester) ensures the current user is not set as requester of the project if the project has
    // no requester yet (they will still be set when 'place order' is pressed though!)
    this.router.navigate(['/pdetail', p.id], {queryParams: {nfreq: 'y'}});
  }
}

function filterProject(project: Project, text: string): Project {
  if (text) {
    let searchString = project.title;
    if (project.files) {
      project.files.forEach(f => searchString = searchString + " " + f.name)
    }
    // Include user name
    searchString += getUserNameFromProject(project, false);
    // Include company name
    if (project.requestedby != null) {
      if (project.requestedby.companyName != null)
        searchString += project.requestedby.companyName;
      else if (project.requestedby.companyId != null)
        searchString += project.requestedby.companyId;
    }
    if (searchString.trim().toLocaleLowerCase().indexOf(text.trim().toLocaleLowerCase()) >= 0)
      return project;
    else
      return undefined;
  }
  return project;
}
