import {filter, map} from 'rxjs/operators';
import {Component} from "@angular/core";
import {UserService} from "../service/user.service";
import {LogInService} from "../service/login.service";
import {ActivatedRoute, Params} from "@angular/router";
import {MatDialogRef} from "@angular/material/dialog";
import {UserJoinDialog} from "./user.join.component";
import {JoinDialogOutcome, JoinType} from "./user.join.model";


@Component({
  selector: 'invite-landing-page',
  template: '<h1>Welcome to lilo</h1>' +
    '<h2>Your partner for taking charge of your translation projects.</h2>' +
    '<br/>' +
    '<p *ngIf="joinWithMailSuccess">Please check your mailbox for an email to activate your account.</p>',
  styleUrls: ['user.settings.component.css']
})
export class InvitedComponent {

  public joinWithMailSuccess = false;

  constructor(private route: ActivatedRoute,
              private userService: UserService,
              private loginService: LogInService) {

  }

  public ngOnInit(): void {
    this.route.params.pipe(
      map((params: Params) => params['inviteid']),
      filter(key => key != undefined),)
      .subscribe(key => {
          sessionStorage.setItem("inviteId", key);
        const dialogRef = this.loginService.openJoinDialog("newproject");
        this.listenForDialogResult(dialogRef);
        }
      );
    this.route.queryParams
      .subscribe(params => {
        const dialogRef = this.loginService.openJoinDialog("newproject", params['companytoken'], params['grouptoken']);
        this.listenForDialogResult(dialogRef);
        }
      );
  }

  private listenForDialogResult(dialogRef: MatDialogRef<UserJoinDialog>): void {
    dialogRef.afterClosed().pipe(
      filter(r => r != null),
      filter(r => r.result == JoinDialogOutcome.SUCCESS && r.join == JoinType.MAIL)
    ).subscribe(() => this.joinWithMailSuccess = true);
  }
}
