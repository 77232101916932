/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./time.component";
import * as i2 from "amazing-time-picker";
var styles_TimeComponent = [];
var RenderType_TimeComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TimeComponent, data: {} });
export { RenderType_TimeComponent as RenderType_TimeComponent };
export function View_TimeComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "input", [["placeholder", "hh:mm"], ["type", "time"]], [[8, "value", 0], [8, "title", 0], [4, "color", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.open() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", _co.timeField, ""); var currVal_1 = i0.ɵinlineInterpolate(1, "", _co.tooltip, ""); var currVal_2 = _co.textColor; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_TimeComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "timepicker", [], null, null, null, View_TimeComponent_0, RenderType_TimeComponent)), i0.ɵdid(1, 114688, null, 0, i1.TimeComponent, [i2.AmazingTimePickerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TimeComponentNgFactory = i0.ɵccf("timepicker", i1.TimeComponent, View_TimeComponent_Host_0, { hours: "hours", minutes: "minutes", minTime: "minTime", checkMinimum: "checkMinimum" }, { timeSelected: "timeSelected", invalidTimeSelected: "invalidTimeSelected" }, []);
export { TimeComponentNgFactory as TimeComponentNgFactory };
