import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from "@angular/core";
import {UserService} from '../../service/user.service';


@Component({
  selector: 'colleague-select',
  templateUrl: './colleague-select.component.html',
  styleUrls: ['./colleague-select.component.css'],
})
export class ColleagueSelectComponent implements OnInit {

  @Input()
  selectedColleagues: any[] = [];
  @Output()
  onSelectedColleaguesChange = new EventEmitter<any>();

  colleagues: any[];
  filteredSelection: any[];
  colleagueDialogOpen = false;

  constructor(public userService: UserService) {

  }

  ngOnInit() {
    this.userService.findColleagues()
      .subscribe(colleagues => {
        this.colleagues = colleagues;
        this.filterColleagues();
      });
    if (this.selectedColleagues === undefined) {
      this.selectedColleagues = [];
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty("selectedColleagues")) {
      this.selectedColleagues = changes.selectedColleagues.currentValue;
      if (this.colleagues != undefined) this.filterColleagues();
    }
  }

  filterColleagues() {
    this.filteredSelection = [];
    if (this.selectedColleagues !== undefined) {
      // Get the data about the selected colleagues from the "all colleagues" collection
      // Names might have changed or colleagues might be gone, so make sure to take this into account
      // TODO: maybe don't filter in followup mode? but then when saving don't save along ex-colleagues!
      this.selectedColleagues.forEach(selected => {
        for (let i = 0; i < this.colleagues.length; i++) {
          let c = this.colleagues[i];
          if (c.id === selected.id) {
            this.filteredSelection.push(c);
            break;
          }
        }
      });
    }
  }

  toggleColleagueChooser() {
    this.colleagueDialogOpen = !this.colleagueDialogOpen;
  }

  openColleagueChooser() {
    this.colleagueDialogOpen = true;
  }

  closeColleagueChooser() {
    this.colleagueDialogOpen = false;
  }

  removeColleague(colleague: any) {
    this.filteredSelection.splice(this.filteredSelection.indexOf(colleague), 1);
    this.onSelectedColleaguesChange.emit(this.filteredSelection);
  }

  onColleagueUpdate(event: any) {
    this.onSelectedColleaguesChange.emit(this.filteredSelection);
  }
}
