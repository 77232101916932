
    <h4>Project &quot;{{title}}&quot;</h4>
    <ul>
    <li *ngIf="startedOn">Started on: {{startedOn|date:'yyyy-MM-dd HH:mm'}}</li>
    <li *ngIf="lastUpdatedOn">Last updated on: {{lastUpdatedOn|date:'yyyy-MM-dd HH:mm'}}</li>
    <li *ngIf="dueOn">Due on: {{dueOn|date:'yyyy-MM-dd'}}</li>
    <li *ngIf="canceledOn">Canceled on: {{canceledOn|date:'yyyy-MM-dd HH:mm'}}</li>
    <li *ngIf="status">Status: {{status}}</li>
    </ul>
    <i>Click to open.</i><br/>
    <img src="../../../assets/arrow_red_bottomleft.png" 
         style="width: 30px; height: 15px; margin-top: 5px; margin-bottom: 1px;">
    