import {Component} from "@angular/core";
import {VendorService} from "../service/vendor.service";
import {ActivityService} from "../service/activity.service";
import {LanguageService} from "../service/language.service";
import {UserService} from "../service/user.service";


@Component({
  selector: 'translator-overview',
  templateUrl: 'translator-overview.component.html'
})
export class TranslatorOverviewComponent {

  activity;
  source;
  target;

  languages;
  dialectLanguages;
  activities;
  domains: string[] = [];

  results;

  constructor(private vendorService: VendorService,
              private activitiesService: ActivityService,
              private languageService: LanguageService,
              public userService: UserService) {

  }

  ngOnInit() {
    this.activitiesService.getActivities().subscribe(activities => this.activities = activities);
    this.languageService.getLanguages().subscribe(languages => this.languages = languages);
    this.languageService.getDialectLanguages().subscribe(languages => this.dialectLanguages = languages);
  }

  search() {
    this.results = this.vendorService.getCompetences(this.source + "_" + this.target, this.activity);
  }
}
