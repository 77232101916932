/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./user.settings.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "./profile.pic.upload.component";
import * as i4 from "../service/file.service";
var styles_ProfilePicUploadComponent = [i0.styles];
var RenderType_ProfilePicUploadComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProfilePicUploadComponent, data: {} });
export { RenderType_ProfilePicUploadComponent as RenderType_ProfilePicUploadComponent };
export function View_ProfilePicUploadComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "divUpload"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "label", [["class", "lblUpload"], ["for", "file-upload"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Change"])), (_l()(), i1.ɵeld(3, 0, null, null, 5, "input", [["accept", "image/jpeg, image/png"], ["id", "file-upload"], ["placeholder", "Upload your profile picture"], ["style", "display: none;"], ["type", "file"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "change"], [null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 4).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 4)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 4)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("change" === en)) {
        var pd_4 = (_co.uploadFiles($event) !== false);
        ad = (pd_4 && ad);
    } if (("ngModelChange" === en)) {
        var pd_5 = ((_co.fileUpload = $event) !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 16384, null, 0, i2.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DefaultValueAccessor]), i1.ɵdid(6, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(8, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.fileUpload; _ck(_v, 6, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 8).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 8).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 8).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 8).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 8).ngClassValid; var currVal_5 = i1.ɵnov(_v, 8).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 8).ngClassPending; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_ProfilePicUploadComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "profile-pic-upload", [], null, null, null, View_ProfilePicUploadComponent_0, RenderType_ProfilePicUploadComponent)), i1.ɵdid(1, 49152, null, 0, i3.ProfilePicUploadComponent, [i4.FileService], null, null)], null, null); }
var ProfilePicUploadComponentNgFactory = i1.ɵccf("profile-pic-upload", i3.ProfilePicUploadComponent, View_ProfilePicUploadComponent_Host_0, { userid: "userid" }, { picEmitter: "picEmitter" }, []);
export { ProfilePicUploadComponentNgFactory as ProfilePicUploadComponentNgFactory };
