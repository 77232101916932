import {filter, map, publish} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {ConnectableObservable, Observable, Subject} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {IntervalObservable} from "rxjs/observable/IntervalObservable";
import {getBaseSocketUrl, getBaseUrl} from "../util/httpclient";


@Injectable()
export class ChatService {

  private CHAT_API = getBaseUrl() + "/api/v1/chats";
  private CHAT_SOCKET_URL = getBaseSocketUrl() + "/chat/feed";

  public messages: Observable<any>;
  private ws: Subject<any>;

  constructor(private http: HttpClient) {

  }

  fetchChatMessages(projectId: string): Observable<any[]> {
    return this.http.get(this.CHAT_API + "/project/" + projectId, {responseType: "json"}).pipe(map(r => r as any[]))
  }

  createNewMessage(message: any): Observable<any> {
    return this.http.post(this.CHAT_API, message, {responseType: "json"})
  }

  createReply(message: any, msgId: string): Observable<any> {
    return this.http.post(this.CHAT_API + "/reply/" + msgId, message, {responseType: "json"})
  }

  fetchUnreadChatCount(projectId: string, userId: string): Observable<number> {
    return this.http.get(this.CHAT_API + "/unread/" + userId, {params: {userId: userId, projectId: projectId}}).pipe(
      map(r => r as number))
  }

  markAsRead(msgId: string, userId: string): Observable<any> {
    return this.http.post(this.CHAT_API + "/read/" + msgId + "/" + userId, "", {responseType: "text"})
  }

  initChatFeed() {
    this.ws = Observable.webSocket(this.CHAT_SOCKET_URL);
    //Keep the socket alive (every 4 minutes, server timeout is set to 5 minutes)
    IntervalObservable.create(30000).subscribe(o =>
      this.ws.next({'action':'keepalive'})
    );
    this.messages = this.makeHot(this.ws).pipe(filter(m => m != null));
  }

  //For more info see analyses.service.ts
  makeHot<T>(cold: Observable<T>): Observable<T> {
    let obs = cold.pipe(publish()) as ConnectableObservable<T>;
    obs.connect();
    return obs;
  }

  //TODO instead of enrolling for a project we should enroll for a user, this implies that the users which should receive the messages is included in the broadcast message
  enrollForProjectChat(pId: string): Observable<any> {
    if (!this.ws || this.ws.closed)
      this.initChatFeed();
    //To enroll you have to send a message to the socket with the action "enroll" and to which objects you want to enroll
    this.ws.next({'action':'enroll', 'pid': pId});
    //The JSON structure of the message normally has the objects parameters to identify and a "data" object which contains the actual information
    //E.g. {"pid":"10", "data":{"action":"PDF", "langname":"damocles.docx"}}
    let sessionUser = sessionStorage.getItem("user");
    let userId = undefined;
    if (sessionUser != null) {
      try {
        userId = JSON.parse(sessionUser).id;
      } catch (e) {
        userId = undefined;
      }
    }
    return this.messages.pipe(filter(msg => {
      return msg.pid == pId;
    }),
      filter(msg => {
        return msg.uid != userId
      }),//If you are the one sending the message, no need to get notified
      map(msg => msg.data),)
  }
}
