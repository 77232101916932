import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Component, Inject} from "@angular/core";


@Component({
  selector: 'confirm-dialog',
  template: `
	  <div class="mainDiv">
		  <h1 *ngIf="confirmTitle">{{confirmTitle}}</h1>
		  <h4 *ngIf="confirmMessage">{{confirmMessage}}</h4>
		  <div *ngIf="confirmHtml" class="confirmHtmlDiv" [innerHTML]="confirmHtml | safeHtml"></div>
		  <div class="buttons">
			  <button [ngClass]="{confirm: !invertStyle, cancel: invertStyle}" 
                (click)="confirm()">{{confirmText}}</button>
			  <button [ngClass]="{cancel: !invertStyle, confirm: invertStyle}" 
                (click)="cancel()">{{cancelText}}</button>
		  </div>
	  </div>`,
  styles: [
    '.mainDiv { display: flex; flex-direction: column; align-items: flex-start; background-color: inherit; }',
    '.mainDiv h1 { margin: 5px 0 10px 0; }',
    '.mainDiv h4 { font-size: 18px; margin: 5px 0 10px 0; }',
    '.confirmHtmlDiv { margin: 5px 0 10px 0; }',
    '.buttons { width: 100%; display: flex; align-items: center; justify-content: space-evenly; margin-top: 25px; }',
    '.confirm { flex: 1 1 auto; max-width: 45%; }',
    '.cancel { flex: 1 1 auto; max-width: 45%; background-color: var(--alternative-color) }',
    '.cancel:hover { background-color: var(--alternative-hover-color) }']
})
export class ConfirmDialog {

  confirmTitle: string;
  confirmMessage: string;
  confirmHtml: string;
  confirmText: string = "Confirm";
  cancelText: string = "Cancel";
  invertStyle: boolean = false;

  constructor(public dialogRef: MatDialogRef<ConfirmDialog>,
              @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  ngOnInit(): void {
    if (this.data.confirmTitle)
      this.confirmTitle = this.data.confirmTitle;
    if (this.data.confirmMessage)
      this.confirmMessage = this.data.confirmMessage;
    if (this.data.confirmHtml)
      this.confirmHtml = this.data.confirmHtml;
    if (this.data.confirmText)
      this.confirmText = this.data.confirmText;
    if (this.data.cancelText)
      this.cancelText = this.data.cancelText;
    if (this.data.invertStyle)
      this.invertStyle = this.data.invertStyle;
  }

  cancel() {
    this.dialogRef.close("cancel")
  }

  confirm() {
    this.dialogRef.close("confirm")
  }
}


