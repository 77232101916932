import * as tslib_1 from "tslib";
import { Observable, } from 'rxjs';
import { debounceTime, filter, map, share, tap } from 'rxjs/operators';
import { AfterViewInit, OnDestroy, OnInit } from "@angular/core";
import { ENABLE_QUOTE, Project } from "../dto/project";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";
import { ProjectService } from "../service/project.service";
import "rxjs/Rx";
import "rxjs/add/operator/map";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SimpleDialog } from "../simple-dialog.component";
import { UserService } from "../service/user.service";
import { PricingService } from "../service/pricing.services";
import { LanguageService } from "../service/language.service";
import { StatisticsService } from "../service/statistics.service";
import { ConfirmDialog } from "../confirm.dialog";
import { LogInService } from "../service/login.service";
import { FileService } from "../service/file.service";
import { FileMetaDataDialog } from "./util/file-meta-data.dialog";
import { environment } from "../../environments/environment";
import { MtService } from "../service/mt.service";
import { ChatService } from "../service/chat.service";
import { TaskService } from "../service/task.service";
import { CookieService } from "ngx-cookie-service";
import { LangpairSelectComponent } from "./langselectcomponent/langpair-select.component";
import { VendorService } from "../service/vendor.service";
import { ProjectPaymentComponent } from "./paymentcomponents/project-payment.component";
import { getGeneralDocFiles, getSourceFiles, toLangPairString } from "../util/jobutil";
import { EditingParameter, initServiceParamForService, SERVICE_TRANSLATION, SERVICES, TranslationParameter } from '../mock-activities';
import { existsAndNotEmpty } from "../util/apputil";
import { GTACInputComponent } from '../util/gtac-input.component';
import { SubscriptionHandler } from '../util/subscription.handler';
import { HelpDialogComponent } from '../util/help.dialog.component';
import { HttpClient } from '@angular/common/http';
/* tslint:disable */
/**
 * Created by jefv on 27/07/2017.
 */
export class ProjectCreateComponent {
    constructor(projectService, userService, pricingService, languageService, statisticService, fileservice, mtservice, taskService, vendorService, route, location, router, dialog, snackbar, cookieService, loginService, chatService, http) {
        this.projectService = projectService;
        this.userService = userService;
        this.pricingService = pricingService;
        this.languageService = languageService;
        this.statisticService = statisticService;
        this.fileservice = fileservice;
        this.mtservice = mtservice;
        this.taskService = taskService;
        this.vendorService = vendorService;
        this.route = route;
        this.location = location;
        this.router = router;
        this.dialog = dialog;
        this.snackbar = snackbar;
        this.cookieService = cookieService;
        this.loginService = loginService;
        this.chatService = chatService;
        this.http = http;
        this.isDebug = environment.isDebug;
        this.section = undefined;
        this.noForceRequester = undefined;
        this.services = SERVICES;
        this.sourceLang = undefined;
        this.sourceFiles = [];
        this.docFiles = [];
        this.proposedDueDate = undefined;
        this.saveSharesAsDefault = false;
        this.defaultShares = [];
        this.calculatedTotalPrice = undefined;
        this.xliffFailSubscription = undefined;
        this.xliffBetaSubscription = undefined;
        this.taskCountSubscription = undefined;
        this.statsSubscription = undefined;
        this.isDraftProject = false;
        this.hasManualFiles = false;
        this.tmStatsComplete = false;
        this.deepLEnabled = false;
        this.mtValid = false;
        this.languageSectionEnabled = false;
        this.translatorSectionEnabled = false;
        this.projectInfoSectionEnabled = false;
        this.custDetailsSectionEnabled = false;
        this.paymentSectionEnabled = false;
        this.orderSectionEnabled = false;
        this.gtacVersion = UserService.gtacversion;
        this.subscriptionHandler = new SubscriptionHandler(this);
        this.orderSubmitting = false;
        this.altFileRequestDialogOpenMap = {};
        // tslint:disable-next-line: member-ordering
        this.selectingService = false;
        this.loadedTranslators = {};
        this.loadedRevisers = {};
        this.openLanguageBar = undefined;
        this.fileObservable = new Observable(observer => this.fileObserver = observer).pipe(share());
        this.fileObservable.subscribe();
        this.langObservable = new Observable(observer => this.langObserver = observer).pipe(share());
        this.langObservable.subscribe();
    }
    ngOnInit() {
        this.checkDeepLEnabled();
        this.setProject(this.project);
        this.setDefaultShares();
        //Listen on login events so that when the user logs in, we can set the requested by
        this.userService.loginEmitter.subscribe(v => {
            if (v === true) {
                this.setDefaultShares();
                this.checkAndUpdateRequestedBy();
            }
        });
        // Listen to changes in the selected languages, to re-check whether MT is a valid option or no
        this.langObservable.subscribe(v => {
            this.checkMtValid();
        });
    }
    ngOnDestroy() {
    }
    ngAfterViewInit() {
        this.scrollToSection(this.section);
        this.section = undefined;
    }
    checkAndUpdateRequestedBy() {
        if (!this.isNoForceRequester() && this.project && this.project.requestedby === undefined && this.userService.isLoggedIn()) {
            this.projectService.setRequestedBy(this.project, this.userService.getCurrentUser()).subscribe(v => {
                //If the update was successful, update the current instance of the project with the current user, so that it is "up to date"
                if (v === "success") {
                    this.project.requestedby = this.userService.getCurrentUser();
                }
            });
        }
    }
    isNoForceRequester() {
        // Don't force the logged in user as requester for this project (if the flag is true) simply for opening the project
        // (Note that when place order is pressed, the current user is still set as requester though)
        return this.noForceRequester != undefined && this.noForceRequester === "y";
    }
    setDefaultShares() {
        if (this.userService.isLoggedIn()) {
            this.userService.findDefaultShares().subscribe(v => this.defaultShares = v);
        }
        else
            this.defaultShares = [];
    }
    setProject(project) {
        this.project = project;
        this.project.isQuote = ENABLE_QUOTE && project.service === "Sworn translation";
        this.isDraftProject = project.startdate === undefined;
        //Make sure the project's duedate is a date instead of a string
        if (typeof this.project.duedate == "string") {
            this.project.duedate = new Date(this.project.duedate);
        }
        //If there is no value yet for the project's service initialize it to Translation
        if (this.project.service === undefined) {
            this.project.service = SERVICE_TRANSLATION;
        }
        this.initFiles();
        this.serviceParameter = initServiceParamForService(this.project.service, undefined, undefined);
        let sourceLanguageObs = this.projectService.getSourceLanguageObs(project)
            .pipe(filter(l => l != null), tap(l => this.sourceLang = l), tap(l => {
            if (this.serviceParameter['sourceLanguage'] != null) {
                this.serviceParameter['sourceLanguage'][0] = l;
            }
        }));
        let targetLanguagesObs = this.projectService.getTargetLanguagesObs(project)
            .pipe(tap(l => {
            this.targetLanguages = l;
        }), tap(l => {
            if (this.serviceParameter['targetLanguages'] != null) {
                this.serviceParameter['targetLanguages'] = l;
            }
        }), tap(() => {
            if (this.serviceParameter.isValid()) {
                this.serviceParameter.toParamList().forEach(serviceParam => this.loadTranslators(serviceParam));
            }
        }));
        sourceLanguageObs.merge(targetLanguagesObs)
            .subscribe(() => {
        }, error => console.error("Error getting languages", error), () => {
            this.sortServiceParams();
            this.checkTranslatorSelectionEnable();
        });
        // Check if there is already a subscription so we don't reload the project unnecessary
        // The projectSocketObservable will listen to the project socket and will reload the project if anything comes in.
        // TODO only reload on specific actions, filter implementation needed
        if (!this.projectSocketObservable) {
            this.projectSocketObservable = this.projectService.enrollForProject(this.project.id).pipe(filter(m => {
                var obj = undefined;
                if (m.action === 'SOURCE_UPDATE' && m.text !== undefined) {
                    obj = JSON.parse(m.text);
                }
                return m.action !== 'SOURCE_UPDATE' || (obj !== undefined && obj.key === 'sourcelang' && this.sourceLang === undefined);
            }))
                .subscribe(m => {
                this.reloadProject(m.action);
            });
            this.subscriptionHandler.push(this.projectSocketObservable);
        }
        this.checkTMStats();
        this.checkMtValid();
        // this.todo = this.analysesService.getAnalysesForProject(this.project.id);
        //Also when a logged in user visits the project and it is not yet requested by anyone, fill in the requested by
        this.checkAndUpdateRequestedBy();
        if (this.userService.isLoggedIn())
            this.handleSetUser();
        else {
            this.userService.loginEmitter.subscribe(v => {
                if (v === true)
                    this.handleSetUser();
            });
        }
        this.initFiles();
        this.updateTaskCount();
        this.updateProposedDueDate("");
        this.enrollToFeeds();
    }
    handleSetUser() {
        //Check if the payment section can become enabled
        this.paymentSectionEnabled = (this.custDetailsSectionEnabled && this.userService.isLoggedIn());
        this.orderSectionEnabled = this.checkOrderSectionEnable();
        console.debug(this.gtac);
    }
    enrollToFeeds() {
        //Enroll for file feed and check if there is a convertion issue, if so,
        // show a popup where the user needs to fill in a wordcount and a warning that TM isn't an option for this project.
        if (this.xliffFailSubscription === undefined) {
            this.xliffFailSubscription = this.fileservice.enrollForProject(this.project.id).pipe(filter(data => data.action == "JOB_XLIFF_FAIL"))
                .subscribe(data => {
                this.showConversionFailDialog(data.filename);
            }); //Enroll for file feed and check if there is a convertion issue, if so,
            this.subscriptionHandler.push(this.xliffFailSubscription);
        }
        // show a popup where the user needs to fill in a wordcount and a warning that TM isn't an option for this project.
        if (this.xliffBetaSubscription === undefined) {
            this.xliffBetaSubscription = this.fileservice.enrollForProject(this.project.id).pipe(filter(data => data.action == "JOB_XLIFF_BETA"))
                .subscribe(data => {
                if (data.filename.endsWith(".pdf")) {
                    //PDF dialog is triggered on other events (load of project e.g.)
                }
                else {
                    this.showConversionFailDialog(data.filename);
                }
            });
            this.subscriptionHandler.push(this.xliffBetaSubscription);
        }
        if (this.taskCountSubscription === undefined) {
            this.taskCountSubscription = this.taskService.enrollForProject(this.project.id).subscribe(a => this.updateTaskCount());
            this.subscriptionHandler.push(this.taskCountSubscription);
        }
        if (this.statsSubscription === undefined) {
            this.statsSubscription = this.statisticService.enrollForProject(this.project.id).pipe(debounceTime(500), tap(d => this.updateProposedDueDate(d))).subscribe();
            this.subscriptionHandler.push(this.statsSubscription);
        }
    }
    /**
     * This method is called to check if all the statistics from TM are in.
     * The method is triggered when setting the project and when the pricingComponents emits pricing changes.
     */
    checkTMStats() {
        this.statisticService.hasAllTMStats(this.project.id).subscribe(v => {
            this.tmStatsComplete = v;
        });
    }
    initFiles() {
        if (this.project.files != undefined) {
            this.sourceFiles = getSourceFiles(this.project)
                .map(f => {
                if (f.sourcelang != undefined && this.sourceLang == undefined)
                    this.sourceLang = f.sourcelang;
                return f;
            });
            this.docFiles = getGeneralDocFiles(this.project);
            this.hasManualFiles = this.sourceFiles.filter(v => v.processType != null && v.processType === "MANUAL").length > 0;
            if (this.sourceFiles === undefined) {
                this.sourceFiles = [];
            }
            //Enable the languageSection
            this.languageSectionEnabled = this.sourceFiles != undefined &&
                this.sourceFiles.length > 0 &&
                this.manualFilesComplete();
            if (!existsAndNotEmpty(this.project.title)) {
                this.project.title = this.getTitleFromFiles();
            }
            this.sourceFiles
                .map(f => f.name)
                .filter(f => f.endsWith('.pdf'))
                .forEach(f => this.showPDFDialog(f));
            const exportRequestFormats = ['.pages', '.key', '.numbers'];
            this.sourceFiles
                .map(f => f.name)
                .filter(f => {
                return exportRequestFormats.indexOf(f.substring(f.lastIndexOf('.'))) > -1;
            })
                .forEach(f => this.showExportRequestDialog(f));
        }
    }
    manualFilesComplete() {
        let res = this.sourceFiles
            .filter(v => v.processType === "MANUAL")
            .filter(v => v.wordcount === undefined)
            .length === 0;
        return res;
    }
    updateTaskCount() {
        this.taskService.getTaskCountPerLangPair(this.project.id)
            .map(m => Object.values(m).filter(e => e > 0))
            .map(lps => {
            return lps.length;
        })
            .subscribe(lpCount => {
            if (this.project.langpairs !== undefined && lpCount === this.project.langpairs.length) {
                this.projectInfoSectionEnabled = true;
                this.custDetailsSectionEnabled = true;
                this.paymentSectionEnabled = this.userService.isLoggedIn();
                this.orderSectionEnabled = this.checkOrderSectionEnable();
            }
            else {
                this.orderSectionEnabled = false;
            }
        });
    }
    reloadProject(action) {
        console.debug("Project " + this.project.id + ": reloading because of " + action);
        this.projectService.getProject(this.project.id + "").subscribe(project => {
            this.setProject(project);
        });
    }
    //SetLangpairs is done when the languagepair selection's next button is clicked
    setServiceParams(params) {
        let origLanpair = [];
        if (this.project.langpairs != undefined)
            origLanpair = Array.from(this.project.langpairs).map(l => this.toServiceMap(l));
        //Search for all the languagepairs which are not in the new selection anymore
        let removalObservable = Observable.empty();
        if (this.project.langpairs != undefined) {
            removalObservable = Observable.from(origLanpair).pipe(map(lp => this.toServiceMap(lp)), filter(oldLp => !params.contains(oldLp)), map(lp => lp.toDataString()), map(lp => this.projectService.removeLangPair(this.project.id, lp))).mergeAll();
        }
        //Search for all the language pairs which are not yet set on the project
        let toAddObservable = Observable.from(params.toParamList()).pipe(filter(lp => origLanpair === undefined || origLanpair.find(alp => alp.toDataString() === lp.toDataString()) === undefined), map(lp => this.projectService.addLangPairData(lp, this.project.id))).mergeAll();
        removalObservable.merge(toAddObservable)
            .subscribe(a => console.debug("Project " + this.project.id + ": language pairs updated", a));
        this.project.langpairs = params.toParamList();
        this.project.isQuote = ENABLE_QUOTE && this.project.service == "Sworn translation";
        this.sortServiceParams();
        this.checkTranslatorSelectionEnable();
        this.updateTaskCount();
        this.checkMtValid();
        this.openNextLangPair(undefined);
    }
    checkTranslatorSelectionEnable() {
        let hasLanpairs = this.project.langpairs != undefined && this.project.langpairs.length > 0;
        let hasChanges = false;
        if (this.langpairSelectComponent != undefined) {
            hasChanges = this.langpairSelectComponent.hasChanges;
        }
        this.translatorSectionEnabled = hasLanpairs && !hasChanges;
    }
    checkOrderSectionEnable() {
        var checkOrderSectionEnable = (this.custDetailsSectionEnabled && this.languageSectionEnabled && this.userService.isLoggedIn()
            && (this.gtac == null || this.gtac.isAccepted)
            && (this.project.isQuote || (this.calculatedTotalPrice != null && this.calculatedTotalPrice > 0)));
        return checkOrderSectionEnable;
    }
    onFileUploaded(file) {
        console.debug("OnFileUpload");
        if (file != null) {
            this.projectService.addSourceFileData(file.name, this.project.id + "").subscribe(n => this.reloadProject("SOURCE FILE ADDED") //TODO find and alternative way instead of a complete refresh as it is a bit too heavy
            );
        }
    }
    onDocFileUploaded(docInfo) {
        if (docInfo != null && docInfo.docfile != null) {
            this.projectService.addDocFileData(docInfo.docfile.name, docInfo.source4pdf, this.project.id).subscribe(data => {
                this.docFiles.push(data);
            }, error => {
                this.snackbar.open("Error adding documentation file " + docInfo.docfile.name + ": " +
                    error.status + " " + error.statusText, "", {
                    duration: 2000
                });
            });
        }
    }
    /*
    removeDocFile(docFile: any) {
      if (docFile != null) {
        this.removingDocFile = docFile;
        this.projectService.removeDocFile(docFile.name, this.project.id + "")
          .subscribe(
            v => {
              console.log("Project " + this.project.id + ": removed documentation file '" + docFile.name + "'");
              this.snackbar.open("Removed documentation file " + docFile.name + ". ", "", {
                duration: 2000
              });
            },
            error => {
              this.removingDocFile = null;
              console.error("Error removing documentation file", error);
              this.snackbar.open("Unable to remove documentation file " + docFile.name + ": " +
                error.status + " " + error.statusText, "", {
                duration: 2000
              });
            },
            // Remove the documentation file from the collection
            () => {
              this.removingDocFile = null;
              let removed = this.docFiles.filter(v => v.name.indexOf(docFile.name) >= 0);
              this.docFiles.splice(this.docFiles.indexOf(removed), 1);
            }
          )
      }
    }
  
    isRemovingDocFile(file: any) {
      // Used to display loading icon during documentation file removal
      return this.removingDocFile && this.removingDocFile == file;
    }*/
    onFileRemove(file) {
        if (file != null) {
            this.projectService.removeSourceFile(file.name, this.project.id + "")
                .subscribe(v => {
                console.log("Project " + this.project.id + ": removed source file '" + file.name + "'");
            }, error => {
                console.error("Error removing source file", error);
                this.snackbar.open("Unable to remove file " + file.name + ". " +
                    "Make sure there are no accepted tasks for this file.", "", {
                    duration: 2000
                });
                this.reloadProject("SOURCE_FILE_REMOVED");
            }, 
            // Reload the screen so that all files & tasks are up to date.
            () => this.reloadProject("SOURCE_FILE_REMOVED"));
        }
    }
    onFileMetaUpdated(fileData) {
        if (fileData.hasOwnProperty("filename")) {
            // Update the meta data on the relevant files
            getSourceFiles(this.project)
                .filter(f => fileData.filename == f.name)
                .forEach(f => {
                if (fileData.hasOwnProperty("wordcount")) {
                    f.wordcount = fileData.wordcount;
                }
            });
            // Re-init files so anything dependent on wordcount being present (such as whether or not to enable the
            // language selection) gets re-triggered
            this.initFiles();
        }
    }
    placeOrder() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.orderSubmitting = true;
            // Make sure the user is logged in
            if (!this.userService.isLoggedIn()) {
                yield this.openSignInPopupAsync();
            }
            if (!this.userService.isLoggedIn()) {
                this.orderSubmitting = false;
                return;
            }
            const userId = this.userService.getCurrentUser().id;
            // Make sure the payment is properly stored
            let error;
            yield this.paymentComponent.storePaymentDetails()
                .then(() => {
                // If the project still has no duedate, use the proposed duedate that is calculated
                if (this.project.duedate === undefined) {
                    this.project.duedate = this.proposedDueDate;
                }
                // Calculate the pricing again and when that is done, start the project
                // Note that if we manually added the pricingElement to the project, it will not be overwritten
                this.pricingService.getTotalProjectPrice(this.project.id)
                    .switchMap(pricing => {
                    console.debug("Project " + this.project.id + ": STARTING", pricing);
                    return this.projectService.startProject(this.project, userId, pricing, this.saveSharesAsDefault, this.userService.getRemoteUserIp());
                })
                    .subscribe(v => this.router.navigate(['/pdashboard'], { queryParams: { 'createdId': this.project.id } })); // redirect to project dashboard
                this.cookieService.set('targets', JSON.stringify(this.project.langpairs.map(v => v.target)));
            })
                .catch(err => {
                console.error("Error storing payment details", err);
                error = err;
            });
            // If there is an error for storing the payment, it will be displayed on the Stripe component and we need to stop the order placing
            if (error) {
                console.error("Error starting project " + this.project.id, error);
                this.orderSubmitting = false;
                return;
            }
        });
    }
    cancelProject() {
        let dialogRef = this.dialog.open(ConfirmDialog, {
            width: '450px',
            panelClass: 'confirmDialog',
            data: {
                confirmTitle: 'Are you sure?',
                confirmText: 'Yes, cancel project',
                cancelText: 'No, take me back',
                invertStyle: true
            }
        });
        dialogRef.afterClosed().pipe(filter(t => t === "confirm"))
            .subscribe(p => {
            this.projectService.cancelProject(this.project).subscribe(v => {
                let successDialog = this.dialog.open(SimpleDialog, {
                    width: '400px',
                    panelClass: 'simpleDialog',
                    data: { dialogMessage: 'Your project has been canceled.' }
                });
                successDialog.afterOpen().subscribe(v => this.router.navigateByUrl(""));
            });
        });
    }
    getTitleFromFiles() {
        if (this.sourceFiles && this.sourceFiles.length > 0) {
            let file = this.sourceFiles[0];
            let i = file.name.lastIndexOf(".");
            if (i > 0)
                return file.name.substring(0, i);
            else
                return file.name;
        }
        return null;
    }
    openSignInPopupAsync() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.loginService.openSignInPopup("pdetail/" + this.project.id);
        });
    }
    openJoin() {
        this.loginService.openJoinDialog("pdetail/" + this.project.id);
    }
    openSignIn() {
        this.loginService.openSignInPopup("pdetail/" + this.project.id);
    }
    showPDFDialog(filename) {
        const dialogData = {
            confirmTitle: 'PDF detected!',
            confirmHtml: 'We detected that <i>' + filename + '</i> is a PDF file. <br/><br/>' +
                'We <b>can</b> process these, but unfortunately cannot guarantee that the layout of the translated file will correctly reflect the original. <br/>' +
                'If you have the file in its original format (docx, html, ...) we <b>strongly advise</b> to use that instead.' +
                'If not, and if you are okay with potentially needing to re-adjust the layout afterward, you can proceed with the PDF.',
            confirmText: 'Continue with PDF',
            cancelText: 'Remove PDF & upload original',
            invertStyle: true
        };
        setTimeout(() => {
            let obs = this.showAlternativeFileRequestDialog(filename, dialogData);
            if (obs != null)
                obs.subscribe(a => console.debug(a));
        }, 2000);
    }
    showExportRequestDialog(filename) {
        const dialogData = {
            confirmTitle: 'Unsupported file detected!',
            confirmHtml: '<i>' + filename + '</i> has a format we do not fully support.<br/><br/>' +
                'We <b>can</b> process these, but unfortunately cannot apply analysis, translation memory and machine translation on it. <br/>' +
                'You\'ll need to fill in the wordcount and source language manually. <br/>' +
                'If you have the file, or can export to, another format (docx, html, ...) we <b>strongly advise</b> to use that instead.' +
                'If not, and if you are okay with potentially needing to re-adjust the layout afterward, you can proceed with this file.',
            confirmText: 'Continue',
            cancelText: 'Remove file & upload alternative format',
            invertStyle: true
        };
        setTimeout(() => {
            let obs = this.showAlternativeFileRequestDialog(filename, dialogData);
            if (obs != null)
                obs.subscribe(a => {
                    if (a === 'confirm') {
                        this.showConversionFailDialog(filename);
                    }
                });
        }, 2000);
    }
    showAlternativeFileRequestDialog(filename, dialogData) {
        //Check if the dialog is already open for this file
        if (this.altFileRequestDialogOpenMap[filename] != null) {
            return;
        }
        else {
            this.altFileRequestDialogOpenMap[filename] = "OPEN";
        }
        //Check if the source file isn't already removed or marked as MANUAL
        let file = getSourceFiles(this.project).filter(f => f.name === filename)[0];
        console.debug(file, file.processType, file.altfileasked);
        if (file == null || file.altfileasked != null)
            return;
        //show the dialog
        this.projectService.setAltFileTypeAsked(this.project.id, filename).subscribe();
        let dialogRef = this.dialog.open(ConfirmDialog, {
            width: '550px',
            panelClass: 'confirmDialog',
            data: dialogData
        });
        return dialogRef.afterClosed().pipe(tap(a => {
            console.debug("dialogclose", a);
            if (a === 'confirm') {
                file.processType = 'MANUAL';
                this.projectService.setFileProcessType(this.project.id, filename, 'MANUAL').subscribe();
            }
            else {
                this.projectService.removeSourceFile(filename, this.project.id).subscribe(v => this.reloadProject("source file remove"));
            }
            this.altFileRequestDialogOpenMap[filename] = undefined;
        }));
    }
    showConversionFailDialog(filename) {
        let projectFile;
        this.sourceFiles.filter(f => f.name === filename).slice(0, 1).forEach(f => {
            projectFile = f;
        });
        let dialogRef = this.dialog.open(FileMetaDataDialog, {
            panelClass: 'simpleDialog',
            width: "550px",
            data: { projectId: this.project.id, file: projectFile }
        });
        dialogRef.afterClosed().subscribe(a => {
            if (a == "success" && this.project.files) {
                this.sourceFiles.filter(f => f.name === filename)
                    .forEach(f => {
                    f.wordcount = dialogRef.componentInstance.words;
                });
            }
        });
    }
    setDeepLEnabled(enabled) {
        this.deepLEnabled = enabled;
        this.checkMtValid();
    }
    checkMtValid() {
        // Check whether MT is enabled
        // TODO: depends on what MT impl is chosen (once we have multiple)
        if (this.deepLEnabled && this.sourceLang && this.targetLanguages && this.targetLanguages.length > 0) {
            // If MT is enabled, check whether the source language is valid
            this.mtservice.isSupportedLang4DeepL(this.sourceLang).subscribe(b => {
                this.mtValid = b;
                if (b) {
                    // Set MT valid to false first
                    this.mtValid = false;
                    this.targetLanguages.forEach(l => {
                        // Check for each target language whether it is valid: if at least one is, then MT can be chosen
                        this.mtservice.isSupportedLang4DeepL(l).subscribe(b => {
                            if (b)
                                this.mtValid = b;
                        });
                    });
                }
            });
        }
        else
            this.mtValid = false;
    }
    updateProposedDueDate(event) {
        if (this.project.startdate == null) {
            this.projectService.calculateProposedDueDate(this.project.id)
                .pipe(map(d => new Date(Date.now() + d * 60 * 60 * 1000)), tap(d => this.proposedDueDate = d))
                .subscribe(d => {
            }, error => {
                this.proposedDueDate = undefined;
            });
        }
    }
    updatePaymentMethod(value) {
        this.projectService.setPaymentMethod(this.project, value).subscribe();
    }
    checkDeepLEnabled() {
        this.mtservice.isDeepLEnabled().subscribe(b => {
            this.setDeepLEnabled(b);
        });
    }
    onServiceSelectionClick() {
        this.selectingService = !this.selectingService;
    }
    onServiceSelectionChange(service) {
        this.project.service = service;
        this.projectService.setService(this.project).subscribe();
        this.serviceParameter = initServiceParamForService(service, this.sourceLang, this.targetLanguages);
        this.serviceParameter.toParamList().forEach(p => this.loadTranslators(p));
    }
    openServicesHelp(event) {
        event.stopPropagation();
        this.http.get('assets/help/services-help.html', { responseType: 'text' })
            .subscribe(helptext => {
            this.dialog.open(HelpDialogComponent, {
                width: '700px',
                maxHeight: '65%',
                data: { helpTitle: "Services", helpHtmlContent: helptext }
            });
        });
    }
    onLanguageSelectionChange(event) {
        this.checkTranslatorSelectionEnable();
        // if (event.type === "add" && event.target === true)
        //   this.loadTranslators(event.language);
        if (event.type === "add" && event.source === true)
            this.sourceLang = event.language;
        if (event.type === 'add') {
            this.serviceParameter.toParamList()
                .filter(p => {
                return (event.source === true && p['source'] === event.language) || (event.target === true && p['target'] === event.language);
            })
                .forEach(p => this.loadTranslators(p));
        }
    }
    loadTranslators(serviceParam) {
        if (this.sourceLang != undefined) {
            let searchString = serviceParam.toDataString();
            if (this.loadedTranslators[searchString] == undefined) {
                this.vendorService.getBestProjectCompetences(this.project.id, searchString, this.serviceParameter.getService(), this.project.service)
                    .subscribe(translators => {
                    if (translators.length > 0) {
                        translators[0]["isSelected"] = true;
                        translators[0]["isRecommended"] = true;
                    }
                    this.loadedTranslators[searchString] = translators;
                });
            }
            if (this.project.service === 'Translation') {
                if (this.loadedRevisers[searchString] == undefined) {
                    this.vendorService.getBestProjectCompetences(this.project.id, searchString, "Revision", this.project.service)
                        .subscribe(translators => {
                        if (translators.length > 0) {
                            translators[0]["isRecommended"] = true;
                        }
                        this.loadedRevisers[searchString] = translators;
                    });
                }
            }
        }
    }
    getSearchStringForParam(langpair) {
        const source = langpair['source'];
        const target = langpair['target'];
        if (target != null) {
            return source + '_' + target;
        }
        return source;
    }
    //TODO make method more general
    toServiceMap(lp) {
        if (lp.target != null) {
            const serviceParam = new TranslationParameter();
            serviceParam.source = lp.source;
            serviceParam.target = lp.target;
            return serviceParam;
        }
        else {
            const serviceParam = new EditingParameter();
            serviceParam.source = lp.source;
            return serviceParam;
        }
    }
    openNextLangPair(langPair) {
        if (this.project != undefined && this.project.langpairs != undefined && this.project.langpairs[0] != undefined) {
            if (langPair === undefined) {
                this.setOpenLanguageBar(this.project.langpairs[0]);
            }
            else {
                let currentIndex = this.project.langpairs.findIndex(l => l.target == langPair.target);
                let nextPair = this.project.langpairs[currentIndex + 1];
                this.setOpenLanguageBar(nextPair);
                if (nextPair === undefined) {
                    this.projectInfoSectionEnabled = true;
                    this.custDetailsSectionEnabled = true;
                }
            }
        }
        else
            this.setOpenLanguageBar(undefined);
    }
    setOpenLanguageBar(langPair) {
        if (langPair != undefined) {
            this.openLanguageBar = langPair.target;
        }
        else {
            this.openLanguageBar = undefined;
        }
        //Delay a bit to make sure the language bar is expanded
        this.scrollToSection(this.openLanguageBar != undefined ? "lb_" + this.openLanguageBar : "projectInfoSection");
    }
    priceUpdated(langpair, event) {
        // We use this variable to trigger the price overview component to reload
        // todo: find a better way to do this, without a dummy variable
        this.updatedLangPrices = { langpair: langpair.source + "_" + langpair.target, price: event };
    }
    priceTotalCalculated(priceDetails) {
        this.calculatedTotalPrice = priceDetails.total(false);
        console.debug('priceTotalCalculated');
        this.orderSectionEnabled = this.checkOrderSectionEnable();
    }
    userInfoStored() {
        this.reloadProject("USER_INFO_CHANGE");
        this.scrollToSection("paymentSection");
    }
    scrollToSection(section) {
        Observable.create(obs => {
            setTimeout(() => {
                obs.next(this.openLanguageBar);
            }, 100);
        })
            .pipe(map(s => document.getElementById(section)))
            .subscribe(element => {
            if (element != undefined) {
                // Align the language section with the bottom of the screen
                // This way, when the user gets here from the index page the source files he added are in view
                if (section === "languageSelectionSection") {
                    element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
                }
                else {
                    element.scrollIntoView({ behavior: "smooth" });
                }
            }
        });
    }
    sortServiceParams() {
        if (this.project === undefined || this.project.langpairs === undefined || this.project.langpairs.length === 0) {
            return;
        }
        this.project.langpairs.map(a => toLangPairString(a))
            .sort((a, b) => {
            return a.localeCompare(b);
        });
    }
    updateSaveSharesAsDefault(saveShares) {
        this.saveSharesAsDefault = saveShares;
    }
}
