import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Task} from "../dto/job";
import {EditorRow} from "./dto/transunit";


@Component({
  selector: 'editor-save.component',
  templateUrl: 'editor-save.component.html',
})
export class EditorSaveComponent {

  task: Task;
  totalCount: number;
  modifiedCount: number;
  rowsWithMissingRefs: Array<EditorRow>;
  tooltipMissingRefs: string;

  constructor(
    public dialogRef: MatDialogRef<EditorSaveComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  ngOnInit(): void {
    this.task = this.data.task;
    this.totalCount = this.data.rows.length;
    this.modifiedCount = 0;
    for (let row of this.data.rows) {
      if (row.modified)
        this.modifiedCount++;
    }
    this.setRowsWithMissingRefs(this.data.rows);
  }

  private setRowsWithMissingRefs(rows: any) {
    this.rowsWithMissingRefs = Array<EditorRow>();
    if (rows) {
      rows.forEach(r => {
        if (r.missingRefs && r.missingRefs.length > 0) {
          this.rowsWithMissingRefs.push(r);
          if (this.tooltipMissingRefs == null) this.tooltipMissingRefs = "Rows with missing reference(s):";
          this.tooltipMissingRefs += "\n * Row " + r.id + ": " + r.missingRefs.length + " missing";
        }
      });
    }
//  console.log("rowsWithMissingRefs", this.rowsWithMissingRefs)
  }

  hasDueDate() {
    return this.data.task.duedate;
  }

  onTime() {
    return Date.parse(this.data.task.duedate) > new Date().getMilliseconds();
  }

  cancelClick(): void {
    this.dialogRef.close("cancel");
  }

  confirmClick(): void {
    this.dialogRef.close("confirm");
  }

  enableConfirm() {
    return !this.rowsWithMissingRefs || this.rowsWithMissingRefs.length == 0
  }

}
