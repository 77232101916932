import {Component, EventEmitter, OnInit, Output} from "@angular/core";
import {map, startWith, switchMap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {UserService} from '../service/user.service';
import {CookieService} from 'ngx-cookie-service';


@Component({
  selector: 'gtac-input',
  template: `
    <div style="display: flex; justify-content: center; padding: 0.5em 1em;">
      <input type="checkbox" id="gtac" [(ngModel)]="isAccepted" (change)="updateGTAC()"/>
      <label for="gtac">
        I accept the <a href="https://about.lilo.global/gtac" target="blank">general terms and conditions</a>.
      </label>
    </div>
  `,
  styles: [
    'input { margin: 0; padding: 0 }',
    'label { font-size: small; color: var(--main-color); padding: 0; margin: 0 0 0 5px;}',
    'a { color: var(--second-color); font-size: inherit; }'
  ]
})
export class GTACInputComponent implements OnInit {

  public isAccepted = false;

  @Output()
  gtacAcceptEmitter = new EventEmitter<boolean>();

  constructor(private userService: UserService,
              private cookieService: CookieService
  ) {

  }

  ngOnInit() {
    this.checkGTAC();
  }

  checkGTAC() {
    // This observable will check the value of the cookie and the value of the logged in user
    // If there is a value in the cookie and a logged in user without an acceptedgtac the user is updated
    const gtacCheckObs = Observable.of('')
      .pipe(
        // first fetch from the cookie
        map(v => {
          if (this.cookieService.check('lilogtac')) {
            return {version: JSON.parse(this.cookieService.get('lilogtac'))};
          } else {
            return {version: undefined};
          }
        }),
        // check if there is one stored on the user, if so return that one, if not update the user
        map(gtac => {
          const user = this.userService.getCurrentUser();
          if (user != null) {
            if (user.acceptedgtac == null) {
              user.acceptedgtac = gtac;
              this.userService.updateAcceptedGTAC(gtac.version).subscribe();
              return gtac;
            } else {
              return user.acceptedgtac;
            }
          } else {
            return gtac;
          }
        }),
        // we only need the version itself
        map(gtac => gtac.version),
      );
    // The observable needs to be triggered when a user is logged in (loginemitter) or before a user is logged in
    let obs;
    if (this.userService.getCurrentUser() == null) {
      obs = this.userService.loginEmitter
        .pipe(
          startWith(true),
          switchMap(() => gtacCheckObs)
        )
    } else {
      obs = gtacCheckObs;
    }
    obs.subscribe(gtac => {
      this.isAccepted = gtac != null && UserService.gtacversion <= gtac;
      this.gtacAcceptEmitter.emit(this.isAccepted);
    });
  }

  updateGTAC() {
    const acceptedVersion = this.isAccepted ? UserService.gtacversion : undefined;
    this.userService.updateAcceptedGTAC(acceptedVersion).subscribe();
    this.gtacAcceptEmitter.emit(this.isAccepted);
  }

}
