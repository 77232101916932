<div *ngIf="colleagueInfo && colleagueInfo.length>0">
  <h4>Other tasks in same project</h4>
  <div>
    <div *ngFor="let info of colleagueInfo">
      <div><p style="display: flex; justify-content: space-between;">
        <span style="flex: 0 0 auto;">{{info.activity}}:</span>
        <span>{{info.vendor}}</span>
      </p></div>
    </div>
  </div>
</div>
