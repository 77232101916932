<div style="height: 100%;">
    <h4 *ngIf="!isDir" style="text-align: left;">What would you like to rename file '{{currentFileName}}' to?</h4>
    <h4 *ngIf="isDir" style="text-align: left;">What would you like to rename folder '{{currentFileName}}' to?</h4>
    <div style="height: 80%; vertical-align:top;">
        <input type="text" [(ngModel)]="newFileName" style="width: 99%" placeholder="Specify new name"/>
    </div> 
    <div style="display: flex; flex-wrap: wrap; justify-content: center; margin-top: 10px;">
        <button (click)="continue()">OK</button>
        <button (click)="dialogRef.close()">Cancel</button>
    </div>
    </div>