<div>

  <div class="noInvoices"
       *ngIf="!purchaseRegisteredInvoices && (!salesRegisteredInvoices || (salesRegisteredInvoices.length == 0 && isTranslator()))">
    <h3>You have no invoices available at the moment.</h3>
    <p *ngIf="user && isTranslator()">
      Please note that in order to be able to register invoices for completed tasks you will need to ensure your company
      details are complete and correct. <br/>
      You can specify these by going to your user profile, and selecting "<a routerLink="/user/{{user.id}}/company"
                                                                             title="Click here to be redirected to your company settings.">Company</a>"
      from the right menu.
    </p>
  </div>

  <div *ngIf="purchaseRegisteredInvoices">
    <h1>Task invoices</h1>
    <div class="boxing">
      <div *ngIf="purchaseRegisteredInvoices.length === 0">
        <h5>You do not have any task invoices yet.</h5>
      </div>
      <div class="module" *ngFor="let inv of purchaseRegisteredInvoices" (click)="openInvoice(inv)">
        <h3>{{inv.number}}</h3>
        <h4>Invoice date: {{inv.date}}</h4>
        <h4>Due date: {{inv.duedate}}</h4>
        <h4>Total:
          <price [price]="inv.total" [baseCurrency]="'EUR'"></price>
        </h4>
        <h4 *ngIf="inv.tasks && inv.tasks.length>0"># tasks: {{inv.tasks.length}}</h4>
      </div>
    </div>
  </div>
  <div *ngIf="invoiceableTaskCount && invoiceableTaskCount > 0" class="boxing">
    <p style="font-size: 15px; margin-right: 35px;">You have tasks which are not yet invoiced.<br/>
      # tasks eligible to invoice: {{invoiceableTaskCount}}
    </p>
    <button (click)="openNewInvoice()">
      Register invoice
    </button>
  </div>

  <div *ngIf="salesRegisteredInvoices && !(salesRegisteredInvoices.length === 0 && isTranslator())">
    <h1>Project invoices</h1>
    <div *ngIf="salesRegisteredInvoices.length === 0">
      <h5>You do not have any project invoices yet.</h5>
    </div>
    <div class="boxing">
      <div class="module" *ngFor="let inv of salesRegisteredInvoices" (click)="openInvoice(inv)">
        <h3>{{inv.number}}</h3>
        <h4>Invoice date: {{inv.date}}</h4>
        <h4>Total:
          <price [price]="inv.price.vatincl" [showBase]="true"></price>
        </h4>
        <h4 *ngIf="inv.projects && inv.projects.length > 0"># Projects: {{inv.projects.length}}</h4>
      </div>
    </div>
    <div *ngIf="unpaidProjects != null && unpaidProjects.length > 0">
      <h3># projects in the pipeline: {{unpaidProjects.length}} (
        <price [price]="openProjectAmount" [showBase]="true"></price>
        )
      </h3>
      <table style="width: 80%">
        <tr>
          <th>Title</th>
          <th>Requested by</th>
          <th>Due date</th>
          <th>Price</th>
        </tr>
        <tr *ngFor="let project of unpaidProjects" (click)="gotoProject(project.id)" class="clickrow">
          <td>{{project.title}}</td>
          <td>{{project.requestedby.firstName}} {{project.requestedby.lastName}}</td>
          <td>{{project.duedate}}</td>
          <td>
            <price [price]="project.totalprice" [showBase]="true"></price>
          </td>
        </tr>
      </table>
    </div>
  </div>

</div>
