import {filter, map, publish} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {ConnectableObservable, Observable, Subject} from "rxjs";
import {MatSnackBar} from "@angular/material/snack-bar";
import {IntervalObservable} from "rxjs/observable/IntervalObservable";
import {getBaseSocketUrl} from "../util/httpclient";
import {environment} from '../../environments/environment';


@Injectable()
export class AnalysisService {

  public messages: Observable<any>;
  private ws: Subject<any>;

  private ANALYSES_SOCKET_URL = getBaseSocketUrl() + "/analysis/feed";

  constructor(private snackbar: MatSnackBar) {
    console.debug("AnalysisService initializing => connects to " + this.ANALYSES_SOCKET_URL);
  }

  initAnalysesFeed() {
    this.ws = Observable.webSocket(this.ANALYSES_SOCKET_URL);
    // Keep the socket alive (every 4 minutes, server timeout is set to 5 minutes)
    IntervalObservable.create(30000).subscribe(o =>
      this.ws.next({'action':'keepalive'})
    );
    this.messages = this.makeHot(this.ws).pipe(filter(m => m != null));
    // this.messages.subscribe(msg => console.log("> Received " + JSON.stringify(msg) + " @" + this.ANALYSES_SOCKET_URL),
    //   error => console.error(error));
    // Subscribe for the snackbar
    if (environment.isDebug) {
      this.messages.pipe(map(m => m.data),
        map(m => {
          return m.type + "= " + m.value;
        }),)
        .subscribe(v => this.snackbar.open(v, '', {
          duration: 2000
        }));
    }
  }

  //To know the difference between a cold and a hot observable check https://angular-2-training-book.rangle.io/handout/observables/cold_vs_hot_observables.html
  // or here : https://medium.com/@benlesh/hot-vs-cold-observables-f8094ed53339
  //Not really sure if it is needed to use a hot observable, but it does give more of a live messaging, also found other examples which were much more complicated, might have missed something there.
  makeHot<T>(cold: Observable<T>): Observable<T> {
    let obs = cold.pipe(publish()) as ConnectableObservable<T>;
    obs.connect();
    return obs;
  }

  /**
   * Enrolling will update the websocket-sender so that it will send messages to your session on the objects you have enrolled in.
   * Once enrolled, an observable is created to filter out the messages for which you have enrolled but are not for the given object (project file in this case).
   * All the analyses that is done on the file with the given name and the project will be published in the observable
   */
  enrollForFile(pId: string, file: string): Observable<any> {
    if (!this.ws || this.ws.closed)
      this.initAnalysesFeed();
    //To enroll you have to send a message to the socket with the action "enroll" and to which objects you want to enroll
    this.ws.next({'action': 'enroll', 'pid': pId, 'filename': file});
    //The JSON structure of the message normally has the objects parameters to identify and a "data" object which contains the actual information
    //E.g. {"pid":"10", "filename":"damocles.docx", "data":{"type":"sourcelang", "value":"en"}}
    return this.messages.pipe(filter(msg => {
      return msg.filename == file && msg.pid == pId;
    }),
      map(msg => msg.data),)
  }
}
