/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./file-acceptor.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./file-info.component.ngfactory";
import * as i3 from "./file-info.component";
import * as i4 from "../../service/analysis.service";
import * as i5 from "../../service/pricing.services";
import * as i6 from "../../service/file.service";
import * as i7 from "../../service/project.service";
import * as i8 from "../../service/logger.service";
import * as i9 from "./upload-data.service";
import * as i10 from "@angular/material/snack-bar";
import * as i11 from "@angular/material/dialog";
import * as i12 from "@angular/common";
import * as i13 from "@angular/forms";
import * as i14 from "./file-acceptor.component";
import * as i15 from "../../util/filesize.pipe";
var styles_FileAcceptorComponent = [i0.styles];
var RenderType_FileAcceptorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FileAcceptorComponent, data: { "animation": [{ type: 7, name: "appearIn", definitions: [{ type: 0, name: "in", styles: { type: 6, styles: { opacity: 1 }, offset: null }, options: undefined }, { type: 1, expr: "void => *", animation: [{ type: 6, styles: { opacity: 0 }, offset: null }, { type: 4, styles: null, timings: "0.5s ease-in" }], options: null }], options: {} }] } });
export { RenderType_FileAcceptorComponent as RenderType_FileAcceptorComponent };
function View_FileAcceptorComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "file-info", [], null, [[null, "remover"], [null, "languageUpdate"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("remover" === en)) {
        var pd_0 = (_co.removeFile(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } if (("languageUpdate" === en)) {
        var pd_1 = (_co.updateLanguage(_v.parent.context.$implicit, $event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_FileInfoComponent_0, i2.RenderType_FileInfoComponent)), i1.ɵdid(1, 245760, null, 0, i3.FileInfoComponent, [i4.AnalysisService, i5.PricingService, i6.FileService, i7.ProjectService, i8.LoggerService, i9.UploadDataService, i10.MatSnackBar, i11.MatDialog], { file: [0, "file"], pdf4source: [1, "pdf4source"], pId: [2, "pId"] }, { remover: "remover", languageUpdate: "languageUpdate" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.context.$implicit; var currVal_1 = _co.getPdf4Source(_v.parent.context.$implicit); var currVal_2 = _co.pId; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_FileAcceptorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "module"]], [[24, "@appearIn", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileAcceptorComponent_2)), i1.ɵdid(2, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_1 = _v.context.$implicit; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = "in"; _ck(_v, 0, 0, currVal_0); }); }
function View_FileAcceptorComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["style", "float: left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h3", [["style", "color: red"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Not all documents have the same language. Unfortunately, this is currently not supported."])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h5", [["style", "text-align: center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Please ensure you only have files for one specific language per project. Either create a project per document language, or adapt the language if possible."]))], null, null); }
export function View_FileAcceptorComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { uploadInput: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "boxing"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileAcceptorComponent_1)), i1.ɵdid(3, 278528, null, 0, i12.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileAcceptorComponent_3)), i1.ɵdid(5, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 12, "div", [["class", "divUpload"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "label", [["class", "labelButton"], ["for", "file-upload"], ["style", "margin-left: 0px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "img", [["src", "../../../assets/add_file_white.png"], ["style", "width: 20px; height: 20px; vertical-align: middle;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Add file "])), (_l()(), i1.ɵeld(10, 0, [[1, 0], ["fileupload", 1]], null, 5, "input", [["R", ""], ["id", "file-upload"], ["multiple", ""], ["placeholder", "Upload your files"], ["style", "display: none;"], ["type", "file"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "change"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 11).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 11)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 11)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.fileUpload = $event) !== false);
        ad = (pd_4 && ad);
    } if (("change" === en)) {
        var pd_5 = (_co.uploadFiles($event) !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i1.ɵdid(11, 16384, null, 0, i13.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i13.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i13.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i13.DefaultValueAccessor]), i1.ɵdid(13, 671744, null, 0, i13.NgModel, [[8, null], [8, null], [8, null], [6, i13.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i13.NgControl, null, [i13.NgModel]), i1.ɵdid(15, 16384, null, 0, i13.NgControlStatus, [[4, i13.NgControl]], null, null), (_l()(), i1.ɵeld(16, 0, null, null, 2, "label", [["class", "labelButton"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openDocUploadDialog() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 0, "img", [["src", "../../../assets/add_image_white.png"], ["style", "width: 20px; height: 20px; vertical-align: middle;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Add documentation "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.uploadedFiles; _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.sourceLanguageCount > 1); _ck(_v, 5, 0, currVal_1); var currVal_9 = _co.fileUpload; _ck(_v, 13, 0, currVal_9); }, function (_ck, _v) { var currVal_2 = i1.ɵnov(_v, 15).ngClassUntouched; var currVal_3 = i1.ɵnov(_v, 15).ngClassTouched; var currVal_4 = i1.ɵnov(_v, 15).ngClassPristine; var currVal_5 = i1.ɵnov(_v, 15).ngClassDirty; var currVal_6 = i1.ɵnov(_v, 15).ngClassValid; var currVal_7 = i1.ɵnov(_v, 15).ngClassInvalid; var currVal_8 = i1.ɵnov(_v, 15).ngClassPending; _ck(_v, 10, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }); }
export function View_FileAcceptorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "file-acceptor", [], null, null, null, View_FileAcceptorComponent_0, RenderType_FileAcceptorComponent)), i1.ɵdid(1, 114688, null, 0, i14.FileAcceptorComponent, [i6.FileService, i9.UploadDataService, i10.MatSnackBar, i11.MatDialog], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FileAcceptorComponentNgFactory = i1.ɵccf("file-acceptor", i14.FileAcceptorComponent, View_FileAcceptorComponent_Host_0, { uploadedFiles: "uploadedFiles", uploadedDocFiles: "uploadedDocFiles", pId: "pId" }, { onFileUpload: "onFileUpload", onFileLanguageUpdate: "onFileLanguageUpdate", onFileRemove: "onFileRemove", onDocFileUpload: "onDocFileUpload" }, []);
export { FileAcceptorComponentNgFactory as FileAcceptorComponentNgFactory };
var styles_DocumentationUploadDialog = [i0.styles];
var RenderType_DocumentationUploadDialog = i1.ɵcrt({ encapsulation: 0, styles: styles_DocumentationUploadDialog, data: {} });
export { RenderType_DocumentationUploadDialog as RenderType_DocumentationUploadDialog };
function View_DocumentationUploadDialog_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["(", ")"])), i1.ɵppd(2, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent, 0), _co.docFile.size)); _ck(_v, 1, 0, currVal_0); }); }
function View_DocumentationUploadDialog_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "label", [["style", "text-align: left; margin-right: 30px;"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocumentationUploadDialog_2)), i1.ɵdid(3, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.docFile.size; _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.docFile.name; _ck(_v, 1, 0, currVal_0); }); }
function View_DocumentationUploadDialog_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["title", "Select a file"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Select a file"]))], null, null); }
function View_DocumentationUploadDialog_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["title", "Select another file"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Change"]))], null, null); }
function View_DocumentationUploadDialog_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "option", [], null, null, null, null, null)), i1.ɵdid(1, 147456, null, 0, i13.NgSelectOption, [i1.ElementRef, i1.Renderer2, [2, i13.SelectControlValueAccessor]], { ngValue: [0, "ngValue"] }, null), i1.ɵdid(2, 147456, null, 0, i13.ɵangular_packages_forms_forms_y, [i1.ElementRef, i1.Renderer2, [8, null]], { ngValue: [0, "ngValue"] }, null), (_l()(), i1.ɵted(3, null, ["", ""]))], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.$implicit; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.name; _ck(_v, 3, 0, currVal_2); }); }
function View_DocumentationUploadDialog_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Is this file a PDF version of one of the source files? If so, please indicate which one it relates to:"])), (_l()(), i1.ɵeld(3, 0, null, null, 11, "select", [["id", "selectSource4Pdf"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "change"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onChange($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 4).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("ngModelChange" === en)) {
        var pd_2 = ((_co.selectedSource4Pdf = $event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 16384, null, 0, i13.SelectControlValueAccessor, [i1.Renderer2, i1.ElementRef], null, null), i1.ɵprd(1024, null, i13.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i13.SelectControlValueAccessor]), i1.ɵdid(6, 671744, null, 0, i13.NgModel, [[8, null], [8, null], [8, null], [6, i13.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i13.NgControl, null, [i13.NgModel]), i1.ɵdid(8, 16384, null, 0, i13.NgControlStatus, [[4, i13.NgControl]], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 3, "option", [], null, null, null, null, null)), i1.ɵdid(10, 147456, null, 0, i13.NgSelectOption, [i1.ElementRef, i1.Renderer2, [2, i13.SelectControlValueAccessor]], { ngValue: [0, "ngValue"] }, null), i1.ɵdid(11, 147456, null, 0, i13.ɵangular_packages_forms_forms_y, [i1.ElementRef, i1.Renderer2, [8, null]], { ngValue: [0, "ngValue"] }, null), (_l()(), i1.ɵted(-1, null, ["N/A"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocumentationUploadDialog_6)), i1.ɵdid(14, 278528, null, 0, i12.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.selectedSource4Pdf; _ck(_v, 6, 0, currVal_7); var currVal_8 = _co.na; _ck(_v, 10, 0, currVal_8); var currVal_9 = _co.na; _ck(_v, 11, 0, currVal_9); var currVal_10 = _co.srcFiles; _ck(_v, 14, 0, currVal_10); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 8).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 8).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 8).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 8).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 8).ngClassValid; var currVal_5 = i1.ɵnov(_v, 8).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 8).ngClassPending; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_DocumentationUploadDialog_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["style", "color: red;"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errorMsg; _ck(_v, 1, 0, currVal_0); }); }
export function View_DocumentationUploadDialog_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i15.FilesizePipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 23, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Select a file to add as documentation to the project."])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" These files will be available during the translation process as reference material."])), (_l()(), i1.ɵeld(6, 0, null, null, 11, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 8, "div", [["style", "display: flex; flex-direction: column; justify-content: flex-start; align-items: flex-start;"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocumentationUploadDialog_1)), i1.ɵdid(9, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 4, "label", [["class", "lblUpload"], ["for", "uploadFile"], ["style", "border-radius: 3px; margin-left: 25px;"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocumentationUploadDialog_3)), i1.ɵdid(12, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocumentationUploadDialog_4)), i1.ɵdid(14, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(15, 0, null, null, 0, "input", [["id", "uploadFile"], ["placeholder", "Upload documentation"], ["style", "display: none;"], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.selectFile($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocumentationUploadDialog_5)), i1.ɵdid(17, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocumentationUploadDialog_7)), i1.ɵdid(19, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(20, 0, null, null, 4, "div", [["style", "margin-top: 25px; text-align: center;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 1, "button", [], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.upload() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["OK"])), (_l()(), i1.ɵeld(23, 0, null, null, 1, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dialogRef.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancel"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.docFile; _ck(_v, 9, 0, currVal_0); var currVal_1 = !_co.docFile; _ck(_v, 12, 0, currVal_1); var currVal_2 = _co.docFile; _ck(_v, 14, 0, currVal_2); var currVal_3 = (_co.isPdf() && _co.srcFiles); _ck(_v, 17, 0, currVal_3); var currVal_4 = _co.errorMsg; _ck(_v, 19, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_5 = !_co.docFile; _ck(_v, 21, 0, currVal_5); }); }
export function View_DocumentationUploadDialog_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "documentation-upload-dialog", [], null, null, null, View_DocumentationUploadDialog_0, RenderType_DocumentationUploadDialog)), i1.ɵdid(1, 114688, null, 0, i14.DocumentationUploadDialog, [i11.MatDialogRef, i11.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DocumentationUploadDialogNgFactory = i1.ɵccf("documentation-upload-dialog", i14.DocumentationUploadDialog, View_DocumentationUploadDialog_Host_0, {}, {}, []);
export { DocumentationUploadDialogNgFactory as DocumentationUploadDialogNgFactory };
