import {map} from 'rxjs/operators';
import {Component} from "@angular/core";
import {Router} from "@angular/router"
import {HubspotService} from "../service/hubspot.service";
import {UserService} from "../service/user.service";


@Component({
  selector: 'hubspot-project-create',
  templateUrl: './hubspot-project-create.component.html',
  styleUrls:['../projectcomponent/project.component.css']
})
export class HubspotProjectCreateComponent {
  pages;
  filterLanguage;
  creatingProject=false;

  selectedPages = [];

  constructor(
    private userService: UserService,
    private hubspotService: HubspotService,
    private router:Router) {

  }

  ngOnInit() {
    this.hubspotService.getPages().pipe(
      map(pages => {
        console.debug(pages);
        return pages;
      }))
      .subscribe(pages => this.pages=pages)
  }

  pageClick(event){
    var sEvent = JSON.parse(event);
    var pageId = sEvent.pageId;
    var selected = sEvent.selected;
    if(!selected) {
      var pageIndex = this.selectedPages.indexOf(pageId);
      if(pageIndex>=0)
        this.selectedPages.splice(pageIndex, 1);
    }else
      this.selectedPages.push(pageId);
    console.debug(this.selectedPages)
  }

  languageSelected(language){
    this.filterLanguage= language;
    this.selectedPages=[]
  }

  submit() {
    this.creatingProject=true;
    this.hubspotService.createProject(this.selectedPages).pipe(
      map(res => res["projectId"]))
      .subscribe(res => {
          this.creatingProject=false;
          this.router.navigate(["pdetail", res])
        }
      );
  }
}


