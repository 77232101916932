import {switchMap} from 'rxjs/operators';
import {Component} from "@angular/core";
import {Project} from "../dto/project";
import {ProjectService} from "../service/project.service";
import {ActivatedRoute} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";


@Component({
  selector: 'project-details',
  template: `
    <div style="position: relative;">
      <div *ngIf="project == undefined"
        style="display: flex; justify-content: center; align-items: center; flex-direction: column; margin-top: 150px;">
      <img src="../../assets/loading_logo_1.gif" style="width: 100px; margin-bottom: 20px;">
      <span>Retrieving project ...</span>
      </div>
      <div *ngIf="project != undefined && projectState=='create'">
      <project-create [project]="project" [section]="section"
              [noForceRequester]="noForceRequester"></project-create>
      </div>
      <div *ngIf="project != undefined && projectState=='followup'">
        <project-followup [project]="project"></project-followup>
      </div>
		<div *ngIf="project != undefined" class="chat">
      <div>
      <project-chat [projectId]="project.id"></project-chat>
      </div>
		  </div>
	  </div>`,
  styleUrls: ['./project.component.css'],
})
export class ProjectDetailsComponent {

  project: Project = undefined;
  projectState = undefined;
  section = undefined;
  noForceRequester = undefined;

  constructor(private projectService: ProjectService,
              private route: ActivatedRoute,
              private snackbar: MatSnackBar,) {

  }

  ngOnInit(): void {
    // TODO: page does not load immediately when creating new project with a bunch of files from the dashboard?
    let start = Date.now();
    this.route.queryParamMap.subscribe(params => {
      this.section = params.get("section");
      this.noForceRequester = params.get("nfreq");
    });
    this.route.paramMap.pipe(
      switchMap(params => {
        let pId = params.get('id');
        try {
          return this.projectService.getProject(pId);
        } finally {
          console.debug("Project " + params.get('id') + ": getting observer took " + (Date.now() - start) + " ms");
        }
      })
    ).subscribe(project => {
      console.debug("Project " + project.id + ": retrieving project took " + (Date.now() - start) + " ms");
      this.project = project;
      this.initProjectState();
    }, error => {
      console.error("Error loading project", error);
      this.snackbar.open(
        "Error loading project : " + error.toString(), "", {
          duration: 3000
        });
    });
  }

  initProjectState() {
    if (this.project.startdate == undefined && (this.project.checkoutarchs === undefined || this.project.checkoutarchs.length === 0))
      this.projectState = "create";
    else
      this.projectState = "followup"
  }
}
