import {Component, EventEmitter, Input, Output} from "@angular/core";
import {ErrorSnackbarComponent} from "../../error.snackbar.component";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Subject} from "rxjs";
import {FileService} from "../../service/file.service";
import {UploadDataService} from "../util/upload-data.service";


@Component({
  selector: 'file-add',
  templateUrl: './file-add.component.html',
  styleUrls: ['../project.component.css'],
})
export class FileAddComponent {

  @Input()
  label = 'Add more files'
  @Input()
  infoText;
  @Input()
  id: string;

  @Output() onFileUpload = new EventEmitter<File>();

  constructor() {

  }

  uploadFiles(event): void {
    this.onFileUpload.emit(event);
  }
}
