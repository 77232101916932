import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";
import {HttpClient, HttpParams} from "@angular/common/http";
import {getBaseUrl, normalizeFileName} from "../util/httpclient";


@Injectable()
export class TmService {

  private url = getBaseUrl();
  private TM_API = this.url + "/api/v1/tm";


  constructor(private http: HttpClient, private sanitizer: DomSanitizer) {
    console.debug("TmService initializing => connects to " + this.TM_API);
  }

  uploadTmxFile(tmxFile, tmIdx: string): Observable<any> {
    let formData: FormData = new FormData();
    let fileName = normalizeFileName(tmxFile.name);
    formData.append('uploadFile', tmxFile, fileName);
    formData.append('title', fileName);
    let params: HttpParams = new HttpParams();
    params.append("debug", "y");
    return this.http.post(this.TM_API + "/tmx/" + tmIdx, formData, {
      responseType: 'text',
      params: params
    });
  }
}
