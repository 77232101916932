// todo: move static functions from editor to handle segments here
import {Reference, Segment} from "../dto/transunit";


// http://www.fileformat.info/info/unicode/category/No/list.htm
export const REF_ANCHOR: string = "\u2693";
export const REF_BASE: string = "2460";

export function isRef(o): boolean {
  return o.className && o.className.indexOf('Reference') == 0;
}

export function print(s: Segment, prefix: string) {
  console.debug(prefix ? prefix : "segment", s);
}

export function printAsString(s: Segment, prefix: string) {
  console.debug((prefix ? prefix : "segment") + ": " + JSON.stringify(s));
}

export function trimLeading(s: string): string {
  return s.replace(/^\s+/g, "");
}

export function trimTrailing(s: string): string {
  return s.replace(/\s+$/g, "");
}

export function trimSegment(s: Segment): Segment {
  // we only consider refs safe to trim IF:
  // - there is an equal amount both leading and trailing
  // - there are no other refs in the middle of the text
  // leading and trailing whitespaces are ignored and trimmed also
  // todo: this can be made smarter; the check that there is an equal amount in both affixes seems overly harsh
  s = trimSegAffix(s);
  // check whether the count of the leading refs is equal to the count of the trailing ones
  let start = Date.now();
  let prefixRefCount = 0;
  if (s.prefix && s.prefix.length > 0) {
    for (let i of s.prefix) {
      if (isRef(i)) prefixRefCount++;
    }
  }
  let suffixRefCount = 0;
  if (s.suffix && s.suffix.length > 0) {
    for (let i of s.suffix) {
      if (isRef(i)) suffixRefCount++;
    }
  }
  let invalidRefAffix = false;
  // if the counts are equal, then check whether any refs remain in the "trimmed content"
  if (prefixRefCount == suffixRefCount && s.trimmedContent) {
    for (let i of s.trimmedContent) {
      // if any ref left, then we can't trim the leading/trailing refs
      if (isRef(i)) {
        invalidRefAffix = true;
        break;
      }
    }
  } else invalidRefAffix = true;
  if (invalidRefAffix) {
    // revert the trimmed refs if invalid
    s.prefix = undefined;
    s.suffix = undefined;
    s.trimmedContent = undefined;
    // and try to just trim the whitespace then
    s = trimSegAffix(s, false);
  }
  // print(s, "==> trim RESULT");
  return s;
}

function trimSegAffix(s: Segment, trimRefs: boolean = true): Segment {
  let start = Date.now();
  // printAsString(s, "1) before trim");
  s = trimSegPrefix(s, trimRefs);
  // printAsString(s, "2) trim prefix");
  s = trimSegSuffix(s, trimRefs);
  // printAsString(s, "3) trim suffix");
  return s;
}

function trimSegPrefix(s: Segment, trimRefs: boolean = true): Segment {
  let prefix: any[] = new Array();
  let trimmed: any[] = new Array();
  let trimming = true;
  // make a deep copy of the array so we don't alter the original
  let contentToTrim = Object.assign([],
    s.trimmedContent && s.trimmedContent.length > 0 ? s.trimmedContent : s.content);
  for (let i of contentToTrim) {
    if (i) {
      if (trimming) {
        if (isRef(i)) {
          if (trimRefs)
            prefix.push(i);
          else {
            trimmed.push(i);
            trimming = false
          }
        } else {
          if (i.length > 0) {
            // search for the first non-whitespace character (\S = [^ \t\r\n\f])
            let idx = i.search(/\S/);
            // if a non-whitespace character was found and it was not the very first character, then extract the bit
            // before this character and store it in the prefix
            // stop trimming if a non-whitespace character was found
            if (idx > 0) {
              let whites = i.substring(0, idx);
              prefix.push(whites);
              let rest = i.substring(idx);
              trimmed.push(rest);
              trimming = false;
            } else if (idx === 0) {
              trimmed.push(i);
              trimming = false;
            } else {
              // no non-whitespace character found, so continue
              prefix.push(i);
            }
          }
        }
      } else trimmed.push(i);
    }
  }
  if (prefix.length > 0) {
    s.prefix = prefix;
    s.trimmedContent = trimmed;
  }
  return s;
}

function trimSegSuffix(s: Segment, trimRefs: boolean = true): Segment {
  let suffix: any[] = new Array();
  let trimmed: any[] = new Array();
  let trimming = true;
  // make a deep copy of the array so we don't alter the original
  let contentToTrim = Object.assign([],
    s.trimmedContent && s.trimmedContent.length > 0 ? s.trimmedContent : s.content);
  // start from the back
  for (let i of contentToTrim.reverse()) {
    if (i) {
      if (trimming) {
        if (isRef(i)) {
          if (trimRefs)
            suffix.push(i);
          else {
            trimmed.push(i);
            trimming = false;
          }
        } else {
          if (i.length > 0) {
            // reverse the string, so we can search backward
            let reversed = i.split("").reverse().join("");
            // search for the first non-whitespace character (\S = [^ \t\r\n\f])
            let idx = reversed.search(/\S/);
            // if a non-whitespace character was found and it was not the very first character, then extract suffix and store it
            // stop trimming if a non-whitespace character was found
            if (idx > 0) {
              let ridx = (i.length) - idx;
              let whites = i.substring(ridx);
              suffix.push(whites);
              let rest = i.substring(0, ridx);
              trimmed.push(rest);
              trimming = false;
            } else if (idx === 0) {
              trimmed.push(i);
              trimming = false;
            } else {
              // no non-whitespace character found, so continue
              suffix.push(i);
            }
          }
        }
      } else trimmed.push(i);
    }
  }
  if (suffix.length > 0) {
    s.suffix = suffix.reverse();
    s.trimmedContent = trimmed.reverse();
  }
  return s;
}

export function isNoText(content: any[]) {
  let hasText = false;
  if (content != undefined) {
    for (let o of content) {
      if (o != null && !isRef(o)) {
        hasText = o.toString().trim().length > 0;
        if (hasText) break;
      }
    }
  }
  return !hasText;
}

export function segContentToText(content: any[], refs: Reference[]): string {
  let text = "";
  if (content != undefined) {
    for (let o of content) {
      if (o) {
        if (isRef(o)) {
          if (refs) {
            text += toSymbol(o, refs);
          } else {
            // Ignore refs if none given
          }
        } else {
          text += o;
        }
      }
    }
  }
  return text;
}

export function toSymbol(ref: Reference, refs: Reference[]): string {
  let symbol;
  // Determine index of reference in the inventory, so we know what symbol to assign
  let index = -1;
  let currentAsJson = JSON.stringify(ref);
  for (let i = 0; i < refs.length; i++) {
    let refAsJson = JSON.stringify(refs[i]);
    // Use JSON representation to compare
    if (refAsJson === currentAsJson) {
      index = i;
      break;
    }
  }
  if (index >= 0) {
    // Start from \u2460 and count up
    // TODO: reorganize and check sequence
    let baseChar = parseInt(REF_BASE, 16);
    let symbolChar = (baseChar + index);
    // Prefix symbol with "anchor" to make it more unique
    symbol = REF_ANCHOR + String.fromCharCode(symbolChar);
  }
  if (!symbol)
  // "Unknown question mark" symbol, since the corresponding reference was not found
    symbol = "\uFFFD";
  return symbol;
}

export function toXml(ref: Reference): string {
  let attribText = "";
  if (ref.attribs) {
    for (let a of Object.keys(ref.attribs)) {
      attribText += " " + a;
      if (ref.attribs[a]) attribText += "=\'" + ref.attribs[a] + "\'";
    }
  }
  return "<" + (ref.start ? "" : "/") + ref.type
    + attribText +
    (ref.selfClosed ? "/" : "") + ">";
}

export function textToSegContent(segmentText: string, refs: Reference[]): any[] {
  // Convert the segment text back a content array, split between text and references
  let content: any[] = [];
  // Determine range between which a reference symbol can be (based on the given reference array)
  let rangeStart = parseInt(REF_BASE, 16);
  let rangeEnd = rangeStart + refs.length;
  let idx = segmentText.indexOf(REF_ANCHOR);
  let prevIdx = 0;
  while (idx >= 0) {
    let symbolCode = segmentText.charCodeAt(idx + 1);
    // Check whether the character following the "anchor" is in the valid range
    // If not, then we assume this is no reference but that the "anchor" occurred "naturally" O.o
    if (symbolCode >= rangeStart && symbolCode < rangeEnd) {
      // If it is a valid symbol, we need to determine what reference it is
      let ref = refs[symbolCode - rangeStart];
      // Add text before reference
      if (prevIdx < idx)
        content.push(segmentText.substring(prevIdx, idx));
      // Add reference
      content.push(ref);
      // Add 2 to skip over anchor + reference symbol
      prevIdx = idx + 2;
    }
    // Add 1 to skip over anchor
    idx = segmentText.indexOf(REF_ANCHOR, (idx + 1));
  }
  // Add any remaining text
  if (prevIdx < segmentText.length) {
    content.push(segmentText.substring(prevIdx));
  }
  return content;
}

export function visualizeFormattingMarks(text: string) {
  return visualizeLineTerminators(visualizeWhiteSpaces(text));
}

/**
 * Replace the whitespaces by symbols to visualize them.
 *
 * @see https://en.wikipedia.org/wiki/Whitespace_character Whitespace character (wikipedia)
 * @see http://jkorpela.fi/chars/spaces.html Unicode whitespaces
 * @see https://docs.microsoft.com/en-us/typography/develop/character-design-standards/whitespace Space characters
 */
export function visualizeWhiteSpaces(text: string) {
  return text
  // SPACE => ·
    .replace(/ /g, "\u00B7")
    // NO-BREAK SPACE => ⍽
    .replace(/\u00A0/g, "\u237D")
    // EN QUAD => °
    .replace(/\u2000/g, "\u00B0")
    // EM QUAD => °
    .replace(/\u2001/g, "\u00B0")
    // EN SPACE (nut) => °
    .replace(/\u2002/g, "\u00B0")
    // EM SPACE (mutton) => °
    .replace(/\u2003/g, "\u00B0")
    // THREE-PER-EM SPACE (thick space) => ¦
    .replace(/\u2004/g, "\u00A6")
    // FOUR-PER-EM SPACE (mid space) => |
    .replace(/\u2005/g, "\u007C")
    // SIX-PER-EM SPACE =>  ⌇
    .replace(/\u2006/g, "\u2307")
    // FIGURE SPACE =>  ⌇
    .replace(/\u2007/g, "\u2307")
    // PUNCTUATION SPACE =>  ⌇
    .replace(/\u2008/g, "\u2307")
    // THIN SPACE =>  ⌇
    .replace(/\u2009/g, "\u2307")
    // HAIR SPACE =>  ⌇
    .replace(/\u200A/g, "\u2307")
    // ZERO WIDTH SPACE =>  ?
    .replace(/\u200B/g, "\uFFFD");
}

/**
 * Replace the line terminators (and tabs) by symbols to visualize them.
 *
 *@see https://en.wikipedia.org/wiki/Newline#Unicode
 */
export function visualizeLineTerminators(text: string) {
  return text
  // CR+LF: CR (U+000D) followed by LF (U+000A) => ⏎
    .replace(/\n\r/g, "\u23CE")
    // LF: Line Feed, U+000A => ␊
    .replace(/\n/g, "\u240A")
    // CR: Carriage Return, U+000D => ␍
    .replace(/\r/g, "\u240D")
    // TAB => →
    .replace(/\t/g, "\u2192")
    // U+000B <control-000B> (LINE TABULATION, vertical tabulation (VT)) => ␋
    .replace(/\u000B/g, "\u240B")
    // LS: Line Separator, U+2028 => ↵
    .replace(/\u2028/g, "\u21B5")
    // PS: Paragraph Separator, U+2029 => ¶ (pilcrow sign)
    .replace(/\u2029/g, "\u00B6");
}
