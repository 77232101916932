<div *ngIf="!userService.isCurrentUserAdmin">
  You do not have the correct privileges to access this page.
</div>

<div *ngIf="userService.isCurrentUserAdmin">

  <div style="display: flex; flex-direction: row; justify-content: space-between;">
    <div style="width: 90%;">
      <h3 style="margin-bottom: 20px;">
        <img src="../../../assets/folder.png" style="width: 30px; height: 30px; vertical-align: text-bottom;">
        Folder view for <i>{{type}} {{id}}</i>
      </h3>
      <h5 *ngIf="subDir">Directory /{{subDir}} -- <a (click)="goUp()">(go up)</a></h5>
    </div>
    <div style="display: flex; align-items: flex-end;">
      <button (click)="refreshFileContent()" class="iconButton">
        <img src="../../../assets/refresh.png">
      </button>
    </div>
  </div>

  <div class="mat-elevation-z8">
    <mat-table #table [dataSource]="dataSource" matSort>
      <!-- Checkbox column -->
      <ng-container matColumnDef="select">
        <mat-header-cell *matHeaderCellDef>
          <mat-checkbox *ngIf="enableFileOps()"
                        (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()">
          </mat-checkbox>
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <mat-checkbox *ngIf="enableFileOps()"
                        (click)="$event.stopPropagation()"
                        (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)">
          </mat-checkbox>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="icon">
        <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
        <mat-cell *matCellDef="let element"><span *ngIf="element.dir">
          <img src="../../../assets/folder_yellow.png" style="width: 15px; height: 15px; vertical-align: middle;">
        </span></mat-cell>
      </ng-container>
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Name</mat-header-cell>
        <mat-cell *matCellDef="let element" (click)="rowClicked(element)" class="subDirLink">
          <span *ngIf="element.dir">
            <span style="text-decoration: underline;">{{element.name}}</span>
            <span *ngIf="element.count">({{element.count}})</span>
          </span>
          <span *ngIf="!element.dir">{{element.name}}</span>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="size">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Size</mat-header-cell>
        <mat-cell *matCellDef="let element"><span *ngIf="element.size">{{element.size | filesize}}</span></mat-cell>
      </ng-container>
      <ng-container matColumnDef="lastmodified">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Last modified</mat-header-cell>
        <mat-cell *matCellDef="let element"><span *ngIf="element.lastmodified">{{element.lastmodified | date:'yyyy-MM-dd HH:mm:ss.SSS Z'}}</span>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
  </div>

  <div style="display: flex; justify-content: space-between;">
    <div *ngIf="enableFileOps()" style="margin-top: 10px;">
      <label for="file-upload" class="labelButton">
        <img src="../../../assets/add_white.png" style="width: 20px; height: 20px; vertical-align: text-top;">
        Add
      </label>
      <input id="file-upload" type="file" (change)="addFile($event)" placeholder="Upload file to add"
             [(ngModel)]="fileUpload" style="display: none;"/>
      <label class="labelButton" (click)="removeSelected()">
        <img src="../../../assets/minus_white.png" style="width: 20px; height: 20px; vertical-align: text-top;">
        Remove
      </label>
      <label class="labelButton" (click)="renameSelected()">
        Rename
      </label><br/>
      <label class="labelButton" (click)="createNewFolder()">
        Create new folder
      </label>
    </div>
    <p>{{dirCount}} folder(s), {{fileCount}} file(s)</p>
  </div>

</div>



