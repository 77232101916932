import {map} from 'rxjs/operators';
import {Component, Input} from "@angular/core";
import {ChatService} from "../service/chat.service";
import {ChatComponent} from "./chat.component";
import {UserService} from "../service/user.service";
import {LanguagePipe} from "../util/language.pipe";
import {LanguageService} from "../service/language.service";


@Component({
  selector: 'message',
  templateUrl: './message.component.html',
  styleUrls: ['./chat.component.css'],
})
export class MessageComponent {

  profilePicExists = false;
  profilePic;

  @Input()
  user;
  @Input()
  message;
  @Input()
  isThread = false;//Indicator if it is the main message or a reply on another message

  isRead = true;
  reply = false;
  isSelf = false;
  userDesc;
  langPipe: LanguagePipe;

  constructor(private userService: UserService,
              private chatService: ChatService,
              private languageService: LanguageService) {
    this.langPipe = new LanguagePipe(languageService);
  }

  ngOnInit() {
    this.isRead = ChatComponent.isReadMessage(this.message, this.user.id);
    this.isSelf = this.user.id === this.message.user.id;
    if (!this.isSelf)
      this.setUserDesc();
    this.userService.getProfilePic(this.message.user.id)
      .subscribe(next => {
          this.profilePic = next;
          this.profilePicExists = true;
        }, error => this.profilePicExists = false
      );
  }

  setUserDesc() {
    if (this.message.user != undefined && this.message.user.type != undefined) {
      if (this.message.user.type === "customer") {
        this.userDesc = "Customer";
      } else if (this.message.user.type === "translator") {
        if (this.message.user.task != undefined) {
          let task = this.message.user.task;
          if (task.targetLanguage != undefined) {
            this.langPipe.transform(task.targetLanguage).subscribe(l => {
              if (task.activity === "Translation") {
                this.userDesc = "Translator for " + l;
              } else if (task.activity === "Revision") {
                this.userDesc = "Reviser for " + l;
              }
            });
          }
        }
        if (this.userDesc == undefined) this.userDesc = "Translator";
      }
    }
  }

  activateReply() {
    this.reply = true;
  }

  addReply(reply: any) {
    this.chatService.createReply(reply, this.message.id).pipe(
      map(res => {
        return res;
      }),
      map(res => res.data),)
      .subscribe(msg => {
        if (this.message.replies == undefined) {
          this.message.replies = [];
        }
        this.message.replies.push(msg);
      });
    this.reply = false;
  }
}
