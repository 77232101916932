import {Component, Input} from "@angular/core";

@Component({
  selector: 'rating-small-component',
  template: "<rating [rating]=rating></rating><span *ngIf='reviewCount!=null' style='color: #888888'><small>({{reviewCount}})</small></span>",
  styleUrls: ["./review.component.css"],
})
export class RatingSmallComponent {
  @Input()
  rating: number;

  @Input()
  reviewCount: number;

}
