<div style="position: relative; display: flex;">
	<div class="fileupload">
		<pre-upload (projectCreatedEmitter)="setProjectId($event)" (fileUploadedEmitter)="setFile($event)"></pre-upload>
  </div>
  <div>
    <div style="display: flex;">
        <div *ngFor="let lang of targetLanguages" >
          <div *ngIf="prices[lang] !== undefined" style="margin-left: 50px; margin-top: 50px;">
            <h4 style="margin-bottom: 50px;">From {{sourcelang|languagePipe|async}} <br/>to {{lang |languagePipe|async}}</h4>
            <h5 style="margin-bottom: 25px;">On average: <price [price]="prices[lang]"></price></h5>
            <h5 style="margin-bottom: 25px;" *ngIf="lowestPrices[lang] !== undefined">Lowest price: <price [price]="lowestPrices[lang]"></price></h5>
          </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="projectId != undefined" style="text-align: right;">See more <a routerLink="/pdetail/{{projectId}}" routerLinkActive="active">details</a>.</div>
