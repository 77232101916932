<div>
  <h1>Payment methods</h1>
  <p>
    This page allows you to edit your credit card information.<br/>
    Please note that this is used to automatically charge you when you download a translated file from a project.
  </p>
  <p *ngIf="isTranslator()">
    It is only necessary to add a payment method if you intend to register projects. </p>
  <div *ngIf="currentCard">
    <label>Current card:</label>
    <span style="font-size: small; height: 30px; display: flex; align-items: center;">
      <img *ngIf="paymentImage" class="cardImage"
           src="{{paymentImage}}"
           title="{{currentCard.brand}}" alt="{{currentCard.brand}}">
      <span style="font-weight: bold;">XXXX XXXX XXXX {{currentCard.last4}}</span>
    </span>
  </div>
  <div [hidden]="!changeMode">
    <form #checkout="ngForm" (ngSubmit)="onSubmit(checkout)" class="checkout">
      <div class="form-row">
        <label for="card-info">Card information</label>
        <div id="card-info" #cardInfo style="min-width: 350px;"></div>
        <div id="card-errors" role="alert" *ngIf="error">{{ error }}</div>
        <button type="submit">Save</button>
      </div>
    </form>
  </div>
  <div style="margin-top: 25px;">
    <button (click)="changePaymentMethods()">
      {{currentCard? 'Edit' : 'Add'}}
    </button>
    <button (click)="clearPaymentMethods()" [(disabled)]="!currentCard">Clear</button>
  </div>
</div>
