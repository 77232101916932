import {Component} from "@angular/core";
import {MatDialogRef} from "@angular/material/dialog";
import {UserService} from "../service/user.service";


@Component({
  selector: 'user-invite-dialog',
  templateUrl: 'user.invite.dialog.html',
  styleUrls: ['./user.settings.component.css']
})
export class UserInviteDialog {

  //TODO fill in a better text as placeholder
  inviteMessage = "Hi, \n" +
    "I would like to invite you to lilo, a translation platform that I use for my translation process.\n" +
    "I hope you can try it out, and would love to receive work from you through this platform.\n\n" +
    "Best,\n";
  currentValue: string = undefined;
  addresses: string[] = [];

  constructor(public dialogRef: MatDialogRef<UserInviteDialog>,
              private userService: UserService) {

  }

  removeAddress(address: string) {
    this.addresses.splice(this.addresses.indexOf(address), 1)
  }

  addAddress() {
    if (this.currentValue != undefined)
      this.addresses.push(this.currentValue);
    this.currentValue = undefined
  }

  sendInvites() {
    //Make sure the address which is in the input field is also added to the list of addresses
    this.addAddress();
    this.userService.sendInvites(this.addresses, this.inviteMessage).subscribe(a => {
        if (a == "success") {
          console.debug("Invites have been sent.");
          this.dialogRef.close();
        }
      }
    )
  }
}
