import {map} from 'rxjs/operators';
import {Component} from "@angular/core";
import {MatDialog} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {UserService} from "../service/user.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {SimpleDialog} from "../simple-dialog.component";
import {ErrorSnackbarComponent} from "../error.snackbar.component";


@Component({
  selector: 'mail-activation-screen',
  template: '' +
    '<h1>Welcome to LiLo</h1>' +
    '<h3>Your partner for taking charge of your translation projects</h3>' +
  '<div *ngIf="!user">' +
  'We are currently processing your activation.<br/>' +
  'This should only take a couple of seconds, you will be redirected automatically.<br/>' +
  'If not, please refresh this page.' +
  '</div>' +
  '<div *ngIf="user">' +
  '<form>' +
  '<div class="row">' +
    '<h4>We have successfully activated your account. Simply go ahead and set your password!</h4>' +
  '</div>' +
  '<div class="row">' +
  '<label for="password">Password: </label><input name="password" id="password" type="password" [(ngModel)]="password"/>' +
  '</div>' +
  '<div class="row">' +
  '<label for="confirmPassword">Confirm password: </label><input name="confirmPassword" id="confirmPassword" type="password" [(ngModel)]="confirmPassword"/>' +
  '</div>' +
  '<div class="row">' +
  '<button type="submit" [disabled]="!checkPasswordEqual()" (click)="updatePassword()">Save</button>' +
  '</div>' +
  '</form>' +
  '</div>',
  styleUrls: ['./user.settings.component.css']
})
export class MailActivationScreen {

  user;
  callback: string;
  password;
  confirmPassword;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private userService: UserService,
              private dialog: MatDialog,
              private snackbar: MatSnackBar) {

  }

  ngOnInit() {
    this.route.params.pipe(
      map((params: Params) => params['authkey']))
      .subscribe(key =>
        this.userService.activateAccount(key).subscribe(
          result => {
            this.user = result;
          }, error1 => console.error("Error activating user account", error1)
        )
      );
    this.route.queryParams.pipe(
      map((params: Params) => params['callback']))
      .subscribe(cb => {
          this.callback = cb;
        }
      )
  }

  updatePassword() {
    //Check the password
    if (this.checkPasswordEqual()) {
      //Call the update password, and subscribe to the result
      this.userService.updatePassword(this.user.id, this.password).subscribe(r => {
        //If the result is success (so the password is changed) show a success dialog
        if (r == "success") {
          let dialogRef = this.dialog.open(SimpleDialog, {
            width: "400px",
            panelClass: 'simpleDialog',
            data: {dialogMessage: "Your account was successfully activated, and your password set."}
          });
          //When the successdialog is closed, we sing in to LiLo and directly navigate to the profile URL so the user can complete their profile.
          dialogRef.afterClosed().subscribe(
            n =>
              this.userService.signInWithMail(this.user.email, this.password).subscribe(next => {
                  if (this.callback) {
                    this.router.navigateByUrl(this.callback)
                  } else {
                    this.router.navigate(["user", this.user.id])
                  }
                }
              )
          );
        } else {
          let ref = this.snackbar.openFromComponent(ErrorSnackbarComponent, {
            duration: 2000,
            data: {errorText: 'Something went wrong, please try again later.'}
          });
        }
        },
        error => {
          let ref = this.snackbar.openFromComponent(ErrorSnackbarComponent, {
            duration: 2000,
            data: {errorText: 'Something went wrong, please try again later.'}
          });
          console.error("Error updating password", error);
        })
    }
  }

  checkPasswordEqual(): boolean {
    return this.password && this.password.length > 0 && this.password == this.confirmPassword;
  }
}
