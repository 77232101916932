/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./user.settings.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "@angular/common";
import * as i4 from "./integrations.setting.component";
import * as i5 from "../service/user.service";
import * as i6 from "../service/hubspot.service";
import * as i7 from "@angular/router";
var styles_IntegrationsSettingComponent = [i0.styles];
var RenderType_IntegrationsSettingComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_IntegrationsSettingComponent, data: {} });
export { RenderType_IntegrationsSettingComponent as RenderType_IntegrationsSettingComponent };
function View_IntegrationsSettingComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hubspotTestMessage; _ck(_v, 1, 0, currVal_0); }); }
export function View_IntegrationsSettingComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 21, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Integrations"])), (_l()(), i1.ɵeld(3, 0, null, null, 18, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Hubspot"])), (_l()(), i1.ɵeld(6, 0, null, null, 13, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 5, "textarea", [], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 8).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 8)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 8)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.user.hubspot.hapiKey = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 16384, null, 0, i2.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DefaultValueAccessor]), i1.ɵdid(10, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(12, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i1.ɵeld(13, 0, null, null, 1, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.testHubspot() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Test"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_IntegrationsSettingComponent_1)), i1.ɵdid(16, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(17, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 1, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.createHubspotProject() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Translate hubspot pages"])), (_l()(), i1.ɵeld(20, 0, null, null, 1, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.save() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Save"]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.user.hubspot.hapiKey; _ck(_v, 10, 0, currVal_7); var currVal_8 = _co.hubspotTestMessage; _ck(_v, 16, 0, currVal_8); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 12).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 12).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 12).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 12).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 12).ngClassValid; var currVal_5 = i1.ɵnov(_v, 12).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 12).ngClassPending; _ck(_v, 7, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_IntegrationsSettingComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "integrations-settings", [], null, null, null, View_IntegrationsSettingComponent_0, RenderType_IntegrationsSettingComponent)), i1.ɵdid(1, 114688, null, 0, i4.IntegrationsSettingComponent, [i5.UserService, i6.HubspotService, i7.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var IntegrationsSettingComponentNgFactory = i1.ɵccf("integrations-settings", i4.IntegrationsSettingComponent, View_IntegrationsSettingComponent_Host_0, { user: "user" }, {}, []);
export { IntegrationsSettingComponentNgFactory as IntegrationsSettingComponentNgFactory };
