<div>

  <div style="display: flex; justify-content: space-between; align-items: center; width: 75%;">
    <h1 style="flex: 1 1 auto; padding-right: 50px;">Personal data</h1>
    <a *ngIf="user"
       (click)="openDialog()"
       style="font-size: smaller; text-align: right; flex: 1 1 auto; margin-right: 50px; margin-left: 50px;">
      View my profile</a>
  </div>

  <form>
    <div class="row">
      <label>First name*</label>
      <input id="firstname" type="text" name="firstname" [(ngModel)]="user.firstName" required #firstname="ngModel"/>
      <div *ngIf="firstname.invalid && (firstname.dirty || firstname.touched)"
           class="alert alert-danger">
        <div *ngIf="firstname.errors.required">
          First name is required.
        </div>
      </div>
    </div>
    <div class="row">
      <label>Last name*</label>
      <input type="text" name="lastname" id="lastname" [(ngModel)]="user.lastName" #lastname="ngModel" required/>
      <div *ngIf="lastname.invalid && (lastname.dirty || lastname.touched)"
           class="alert alert-danger">
        <div *ngIf="lastname.errors.required">
          Last name is required.
        </div>
      </div>
    </div>
    <div class="row">
      <label>E-mail address*</label>
      <input type="email" name="email" [(ngModel)]="user.email" #email="ngModel" required email/>
      <div *ngIf="email.invalid && (email.dirty || email.touched)"
           class="alert alert-danger">
        <div *ngIf="email.errors.required">
          E-mail is required.
        </div>
        <div *ngIf="email.errors.email && !email.errors.required">
          Ooops... this is not a valid email address.
        </div>
      </div>
    </div>
    <div class="row">
      <label>Job title</label><input type="text" name="headline" [(ngModel)]="user.headline"/>
    </div>
    <div class="row">
      <label>Profile picture</label>
      <mugshot [user]="user" [width]="'100px'" [height]="'100px'" [showIncomplete]="false"></mugshot>
    </div>
    <div>
      <div style="width: 100px;">
        <profile-pic-upload [userid]="user.id" (picEmitter)="reloadUser($event)"></profile-pic-upload>
      </div>
    </div>
    <div class="row">
      <label>Tell us a little bit about yourself</label>
      <textarea name="description" [(ngModel)]="user.description" style="height: 100px;"></textarea>
    </div>
    <div class="row emptyRow"><label> </label></div> <!--empty row-->
    <div class="row" *ngIf="languages">
      <label>Mother tongue</label>
      <select [(ngModel)]="user.language" name="language">
        <option *ngFor="let language of languages" [ngValue]="language">{{language|languagePipe|async}}</option>
      </select>
    </div>
    <div class="row" *ngIf="countries">
      <label>Residence</label>
      <div class="multiValueRow">
        <p>City</p>
        <input type="text" name="city" [(ngModel)]="user.city" placeholder="Your city of residence"/>
        <p style="margin-left: 30px;">Country</p>
        <select [(ngModel)]="user.location" name="location">
          <option *ngFor="let country of countries" [ngValue]="country.display">{{country.display}}</option>
        </select>
      </div>
    </div>
    <div class="row">
      <label>Timezone</label>
      <mat-form-field style="width: 75%">
        <input matInput [matAutocomplete]="auto" name="timezone"
               title="Start typing to filter the list of timezones. Clear any input to see the full, unfiltered list."
               class="matInput"
               [formControl]="timezoneCtrl"/>
        <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption [displayWith]="displayTimezone">
          <mat-option *ngFor="let timezone of filteredTimezones | async" [value]="timezone">
            {{timezone.value}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <div class="row">
      <label style="padding-top: 0px;">Currency</label>
      <select name="currency" [(ngModel)]="user.currency">
        <option *ngFor="let cur of currencies" [ngValue]="cur.code">{{cur.value}}</option>
      </select>
    </div>
    <div style="margin-top: 20px; margin-bottom: 20px;">
      <div class="row">
        <label>Phone number</label><input type="tel" name="phone" [(ngModel)]="user.phone"/>
      </div>
      <div class="row">
        <label>LinkedIn</label><input type="text" name="linkedin" [(ngModel)]="user.linkedin" placeholder="e.g. https://www.linkedin.com/in/xxxxxxxx"/>
      </div>
      <div class="row">
        <label>Skype</label><input type="text" name="skype" [(ngModel)]="user.skype"/>
      </div>
    </div>
    <div class="row emptyRow"><label> </label></div> <!--empty row-->
    <div class="row" *ngIf="isTranslator" style="display: flex; align-items: center;">
      <input type="checkbox" id="enablerequests" name="enablerequests" [(ngModel)]="user.enableRequests"/>
      <label for="enablerequests" style="font-size: 15px; padding: 0; margin-left: 10px;">I also want to be able to
        request translations.</label>
    </div>
    <div class="row" *ngIf="!isTranslator" style="display: flex; align-items: center;">
      <input type="checkbox" id="enableinternalcomps" name="enableinternalcomps"
             [(ngModel)]="internalTranslator"/>
      <label for="enableinternalcomps" style="font-size: 15px; padding: 0; margin-left: 10px;">My colleagues can choose
        me to do a translation.</label>
    </div>
    <div *ngIf="internalTranslator" style="margin: 25px 0 10px 32px;">
      <div style="font-size: small">Please specify here for which translations a colleague can choose you (you'll become
        an internal translator):
      </div>
      <div>
        <ul *ngIf="user.internalCompetences">
          <li *ngFor="let competence of user.internalCompetences">
            <div style="display: flex">
              <strong>{{competence.activity}}</strong>&nbsp;from&nbsp;<strong>{{competence.source|languagePipe|async}}</strong>&nbsp;to&nbsp;<strong>{{competence.target|languagePipe|async}}</strong>
              <img class="btnRemove" src="../../assets/minus.png"
                   (click)="removeInternalCompetence(competence)"/>
            </div>
          </li>
        </ul>
        <div style="display: flex;">
          <div style="margin-left: 5px">
            <label for="activity">Task type</label>
            <select id="activity" [(ngModel)]="activity" name="activity">
              <option *ngFor="let act of activities" [ngValue]="act">{{act}}</option>
            </select>
          </div>
          <div style="margin-left: 5px">
            <label for="source">Source language</label>
            <select id="source" [(ngModel)]="source" name="source">
              <option *ngFor="let language of languages" [ngValue]="language">{{language|languagePipe|async}}</option>
            </select>
          </div>
          <div style="margin-left: 5px">
            <label for="target">Target language</label>
            <select id="target" [(ngModel)]="target" name="target">
              <option *ngFor="let language of dialectLanguages" [ngValue]="language">{{language|languagePipe|async}}
              </option>
            </select>
          </div>
        </div>
        <div>
          <button (click)="addInternalCompetence()" class="btnSmall"
                  style="display: flex; flex-direction: row; align-items: center; justify-content: space-evenly; border-radius: 4px; min-height: 20px;"
                  [disabled]="activity == undefined || source == undefined || target == undefined">
            <img src="../../assets/add.png" style="width: 20px; height: 20px; margin-right: 2px;">Add
          </button>
        </div>
      </div>
    </div>
    <div class="row emptyRow"><label> </label></div> <!--empty row-->

    <div class="row">
      <button type="submit" (click)="save()"
              [disabled]="firstname.invalid || lastname.invalid || email.invalid">Save
      </button>
    </div>
  </form>

</div>
