import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {LanguageService} from "../service/language.service";
import {Observable} from "rxjs";
import {filter, flatMap, map, switchMap} from "rxjs/operators";


@Component({
  selector: 'lang-select-list',
  template: `
	  <div>
		  <div class="searchLangs">
			  <input type="text" [(ngModel)]="searchText"
					 placeholder="Search languages" (input)="filterLangs()" id="languageSearchInput"/></div>
		  <div class="languagesList">
			  <div *ngFor="let lang of filteredLangs" class="languageItem"
				   [ngStyle]="{'background-color': (isSelected(lang)?'var(--background-gray)' : 'transparent')}"
				   (click)="toggleLang(lang)">
				  <button>
			  {{lang|languagePipe|async}}<span *ngIf="isSelected(lang)" class="languageItemSelected">&nbsp;
                <img src="../../assets/icon_accept.svg" alt="selected">
          </span>
				  </button>
			  </div>
		  </div>
	  </div>`,
  styleUrls: ['lang-select.component.css'],
})
export class LangSelectListComponent implements OnInit, AfterViewInit {

  @Input()
  selectedLangs: string[] = [];
  @Input()
  languages: string[] = [];
  @Input()
  dialect = true;

  searchText: string = undefined;

  @Output()
  eventEmitter = new EventEmitter<any>();

  /*todo check why the observable itself cannot be used in the *ngFor with async*/
  filteredLangsObservable: Observable<string[]>;
  filteredLangs: string[] = [];

  constructor(private languageService: LanguageService) {

  }

  ngOnInit() {
    let languageObservable: Observable<string[]>;
    if (this.dialect)
      languageObservable = this.languageService.getDialectLanguages();
    else
      languageObservable = this.languageService.getLanguages();
    languageObservable.subscribe(a => {
      this.filteredLangs = a;
      this.languages = a
    });
    this.filteredLangsObservable = languageObservable
      .pipe(
        flatMap(a => a),
        map(a => <string>a),
        switchMap(a => this.languageService.codeToDescription(a).map(desc => [a, desc])),
        filter(a => {
          if (this.searchText === undefined)
            return true;
          return a[1].toLocaleLowerCase().indexOf(this.searchText.toLocaleLowerCase()) >= 0
        }),
        map(a => a[0])
      ).toArray();
    if (this.selectedLangs == undefined) this.selectedLangs = [];
  }

  ngAfterViewInit() {
    this.focusOnSearchInput();
  }

  focusOnSearchInput() {
    document.getElementById("languageSearchInput").focus();
  }

  toggleLang(language: string) {
    let index = this.selectedLangs.indexOf(language);
    if (index >= 0) {
      this.onRemove(language)
    } else {
      this.onSelect(language)
    }
  }

  //No emitting needed because the array is the same object through the components
  onSelect(language: string) {
    this.selectedLangs.push(language);
    this.eventEmitter.emit({language: language, type: 'add'});
    this.searchText = undefined;
    this.focusOnSearchInput();
  }

  //No emitting needed because the array is the same object through the components
  onRemove(language: string) {
    this.selectedLangs.splice(this.selectedLangs.indexOf(language), 1);
    this.eventEmitter.emit({language: language, type: 'remove'})
  }

  isSelected(language: string) {
    return this.selectedLangs.indexOf(language) >= 0;
  }

  filterLangs() {
    if (this.searchText === undefined)
      this.filteredLangs = this.languages;
    else {
      this.filteredLangs = [];
      this.filteredLangsObservable.subscribe(a => {
        this.filteredLangs = a
      })
    }
  }
}
