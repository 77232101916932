/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./colleague-select.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./colleague-select-list.component.ngfactory";
import * as i4 from "./colleague-select-list.component";
import * as i5 from "./colleague-select.component";
import * as i6 from "../../service/user.service";
var styles_ColleagueSelectComponent = [i0.styles];
var RenderType_ColleagueSelectComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ColleagueSelectComponent, data: {} });
export { RenderType_ColleagueSelectComponent as RenderType_ColleagueSelectComponent };
function View_ColleagueSelectComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" In order to select a colleague you need to log in first.\n"]))], null, null); }
function View_ColleagueSelectComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "colleagueButton"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "colleagueDiv"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeColleague(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", " ", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "remove"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.firstName; var currVal_1 = _v.context.$implicit.lastName; _ck(_v, 3, 0, currVal_0, currVal_1); }); }
function View_ColleagueSelectComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["style", "display: flex; flex-wrap: wrap;"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ColleagueSelectComponent_4)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.filteredSelection; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ColleagueSelectComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["choose colleague(s)"]))], null, null); }
function View_ColleagueSelectComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["add colleague(s)"]))], null, null); }
function View_ColleagueSelectComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Loading... "]))], null, null); }
function View_ColleagueSelectComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" No other users found for your company. "]))], null, null); }
function View_ColleagueSelectComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "colleague-select-list", [], null, [[null, "eventEmitter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("eventEmitter" === en)) {
        var pd_0 = (_co.onColleagueUpdate($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_ColleagueSelectListComponent_0, i3.RenderType_ColleagueSelectListComponent)), i1.ɵdid(2, 4308992, null, 0, i4.ColleagueSelectListComponent, [], { selectedColleagues: [0, "selectedColleagues"], colleagues: [1, "colleagues"] }, { eventEmitter: "eventEmitter" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.filteredSelection; var currVal_1 = _co.colleagues; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_ColleagueSelectComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "colleagueListDialog"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["style", "width: 100%; text-align: right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["style", "cursor:pointer;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeColleagueChooser() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ColleagueSelectComponent_8)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ColleagueSelectComponent_9)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ColleagueSelectComponent_10)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 1, "div", [["class", "colleagueListDialogClose"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeColleagueChooser() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Close"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.colleagues === undefined); _ck(_v, 5, 0, currVal_0); var currVal_1 = ((_co.colleagues !== undefined) && (_co.colleagues.length === 0)); _ck(_v, 7, 0, currVal_1); var currVal_2 = ((_co.colleagues !== undefined) && (_co.colleagues.length > 0)); _ck(_v, 9, 0, currVal_2); }, null); }
function View_ColleagueSelectComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 11, "div", [["class", "colleagueSelectMain"], ["style", "display: flex; flex-wrap: wrap;"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ColleagueSelectComponent_3)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 8, "div", [["class", "colleagueButton openColleaguePick"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 5, "div", [["class", "colleagueChooseDiv"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleColleagueChooser() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ColleagueSelectComponent_5)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ColleagueSelectComponent_6)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ColleagueSelectComponent_7)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.filteredSelection !== undefined); _ck(_v, 3, 0, currVal_0); var currVal_1 = ((_co.filteredSelection === undefined) || (_co.filteredSelection.length === 0)); _ck(_v, 8, 0, currVal_1); var currVal_2 = ((_co.filteredSelection !== undefined) && (_co.filteredSelection.length > 0)); _ck(_v, 10, 0, currVal_2); var currVal_3 = _co.colleagueDialogOpen; _ck(_v, 12, 0, currVal_3); }, null); }
export function View_ColleagueSelectComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ColleagueSelectComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ColleagueSelectComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.userService.isLoggedIn(); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.userService.isLoggedIn(); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_ColleagueSelectComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "colleague-select", [], null, null, null, View_ColleagueSelectComponent_0, RenderType_ColleagueSelectComponent)), i1.ɵdid(1, 638976, null, 0, i5.ColleagueSelectComponent, [i6.UserService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ColleagueSelectComponentNgFactory = i1.ɵccf("colleague-select", i5.ColleagueSelectComponent, View_ColleagueSelectComponent_Host_0, { selectedColleagues: "selectedColleagues" }, { onSelectedColleaguesChange: "onSelectedColleaguesChange" }, []);
export { ColleagueSelectComponentNgFactory as ColleagueSelectComponentNgFactory };
