import {Component, EventEmitter, Input, OnInit, Output, OnDestroy} from "@angular/core";
import {debounceTime, switchMap} from 'rxjs/operators';
import {PricingService} from "../../service/pricing.services";
import {Project} from "../../dto/project";
import {InvoiceService} from "../../service/invoice.service";
import {Pricing} from "../../dto/job";
import { Subscription } from 'rxjs';
import { SubscriptionHandler } from '../../util/subscription.handler';


@Component({
  selector: 'project-update-overview',
  templateUrl: './project-update-overview.component.html',
  styleUrls: ['../project.component.css'],
})
export class ProjectUpdateOverviewComponent implements OnInit, OnDestroy {

  @Input()
  project: Project;
  @Input()
  canUpdate = false;
  @Output()
  updateOrderEmitter = new EventEmitter();

  pricing: Pricing = undefined;
  priceOrig = 0;
  priceDiff = 0;
  projectInvoices;

  subscriptionHandler = new SubscriptionHandler(this);

  constructor(private pricingService: PricingService,
              private invoiceService: InvoiceService) {

  }

  ngOnInit() {
    this.pricingService.getTotalProjectPrice(this.project.id)
      .subscribe(p => {
        this.pricing = p;
        this.setOrigPrice();
      });
    const pricingSubscriptions = this.pricingService.enrollForProject(this.project.id)
      .pipe(
        debounceTime(500),
        switchMap(p => this.pricingService.getTotalProjectPrice(this.project.id)),
      ).subscribe(p => {
      this.pricing = p;
      this.updatePriceDiff();
    });
    this.subscriptionHandler.push(pricingSubscriptions);
    if (this.project.chargeid != null) {
      this.invoiceService.findRegisteredProjectSalesInvoices(this.project.id)
        .subscribe(invs => {
          this.projectInvoices = invs
        }, error => console.error("Error retrieving project invoices", error));
    }
  }

  ngOnDestroy() {

  }

  setOrigPrice() {
    if (this.project.startdate != undefined)
      this.priceOrig = this.pricing.totalIncVat();
    else if (this.project.checkoutarchs != undefined && this.project.checkoutarchs.length > 0) {
      let sortedArchs = (<any[]>this.project.checkoutarchs)
        .sort(v => v.archivedate)
        .reverse();
      // this total price includes VAT
      this.priceOrig = sortedArchs[0].totalprice;
    }
    this.updatePriceDiff();
  }

  private updatePriceDiff() {
    // Determine difference between new and old price, to display to user
    if (this.priceOrig != null && this.pricing != null) {
      this.priceDiff = this.pricing.totalIncVat() - this.priceOrig;
    }
  }

  updateOrder() {
    this.updateOrderEmitter.emit("updateOrder");
  }
}
