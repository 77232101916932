import {Observable, of as observableOf} from 'rxjs';
import {map} from 'rxjs/operators';
import {Component, EventEmitter, Inject, Input, OnInit, Output} from "@angular/core";
import {Vendor} from "../../dto/vendor";
import {VendorService} from "../../service/vendor.service";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {PricingService} from "../../service/pricing.services";
import {StatisticsService} from "../../service/statistics.service";
import {SimpleDialog} from "../../simple-dialog.component";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {UserService} from "../../service/user.service";
import {TaskSpec} from "../../dto/job";
import {extractSourceLang, extractTargetLang} from "../../util/jobutil";


@Component({
  selector: 'vendor-selector',
  templateUrl: './vendor-selector.component.html',
  styleUrls: ['../project.component.css'],
  animations: [trigger('appearIn', [
    state('in', style({opacity: 1})),
    transition('void => *', [
      style({
        opacity: 0
      }),
      animate('0.5s ease-in')
    ])
  ])]
})
export class VendorSelectorComponent implements OnInit {

  @Input() pId: string;
  @Input() langpair: string;
  @Input() activity: string;
  @Output() vendorEmitter = new EventEmitter<Vendor>();

  allVendors: Observable<Vendor[]>;
  filteredVendors: Observable<Vendor[]>;
  vendorcount: number = 0;
  allVendorCount: number = 0;
  userId = null;
  userEmail = null;
  contactRequestResult = null;

  taskSpec: TaskSpec;
  filterText;

  loading = true;

  constructor(private vendorService: VendorService,
              private pricingService: PricingService,
              private statisticsService: StatisticsService,
              private userService: UserService,
              private dialog: MatDialog,) {

  }

  ngOnInit(): void {
    this.loading = true;
    this.userEmail = null;
    this.contactRequestResult = null;
    if (this.langpair && this.activity) {
      this.taskSpec = new TaskSpec();
      this.taskSpec.projectId = this.pId;
      this.taskSpec.activity = this.activity;
      this.taskSpec.sourcelanguage = extractSourceLang(this.langpair);
      this.taskSpec.targetlanguage = extractTargetLang(this.langpair);
      // TODO: make separate call for getting the competence count, rather than having to retrieve ALL competences for a simple count
      if (this.userService.isLoggedIn()) {
        let currentUser = this.userService.getCurrentUser();
        this.userId = currentUser.id;
        this.userEmail = currentUser.email;
      }
      this.allVendors = this.vendorService.getCompetences(this.langpair, this.activity, this.userId);
      this.allVendors.subscribe(c => this.allVendorCount = c.length);
      let fetchVendors;
      if (this.pId != null) {
        fetchVendors = this.vendorService.getBestProjectCompetences(this.pId, this.langpair, this.activity, 'Translation', "3")
      } else {
        fetchVendors = this.vendorService.getBestCompetences(this.langpair, this.activity, this.userId)
      }
      this.filteredVendors = fetchVendors
        .map(v => v.slice(0, 3))//Slice the vendors so only the first 3 partners are shown
        .map(v => {
          this.vendorcount = v.length;
          return v;
        })
        .map(v => {
          this.loading = false;
          return v;
        })
        .flatMap(v => {
          if (this.vendorcount == 0) {
            return this.getDefaultFilteredVendors()
          } else
            return observableOf(v)
        });
    }
  }

  selectVendor(vendor: Vendor): void {
    this.vendorEmitter.emit(vendor);
  }

  showMorePartners() {
    let dialogRef = this.dialog.open(VendorSelectorDialog, {
      width: '80%',
      height: '80%',
      data: {
        projectId: this.pId,
        langpair: this.langpair,
        activity: this.activity,
        showPricing: this.pId != undefined,
        taskSpec: this.taskSpec,
        userId: this.userId
      }
    });
    dialogRef.afterClosed().subscribe(result => {
        if (result instanceof Vendor) {
          this.selectVendor(result)
        }
      }
    );
    return dialogRef;
  }

  setFilteredVendors() {
    this.filteredVendors = this.getDefaultFilteredVendors();
  }

  getDefaultFilteredVendors(): Observable<Vendor[]> {
    return this.allVendors.pipe(
      map(v => filterVendors(v, this.filterText)),
      map(v => v.slice(0, 3)),//Slice the vendors so only the first 3 partners are shown
      map(v => {
        this.vendorcount = v.length;
        return v;
      }),)
  }

  onSubmitContactRequest() {
    this.userService.sendContactRequest(this.userEmail, this.taskSpec, this.userId).subscribe(v => {
      this.contactRequestResult = "Great! We received your query and will get back to you as soon as possible.";
    }, error => {
      console.error("Error sending contact request", error);
      this.contactRequestResult = "An error occurred sending your request. " +
        "Please try again and, if the problem persists, contact us directly at info@lilo.global.";
    });
  }
}


@Component({
  selector: 'vendor-select-dialog',
  template: `
    <div>
      <div>
        <div style="text-align: right; width: 100%; font-size: larger;" (click)="dialogRef.close()">X</div>
      </div>
      <input type="text" name="search" class="searchField" placeholder="Search for translator" [(ngModel)]="filterText"
             (change)="setFilteredVendors()"/>
      <vendor-list (eventEmitter)="selectVendor($event)" [vendors]="filteredVendors"
                   [showPricing]="showPricing" [taskSpec]="taskSpec"></vendor-list>
      <button (click)="dialogRef.close()" class="btnSmall">Close</button>
    </div>
  `,
  styleUrls: ['../project.component.css']
})
export class VendorSelectorDialog {

  langpair: string;
  activity: string;
  showPricing: boolean;
  taskSpec;
  userId: null;
  allVendors: Observable<Vendor[]>;
  filteredVendors: Observable<Vendor[]>;
  filterText;

  constructor(public dialogRef: MatDialogRef<SimpleDialog>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private vendorService: VendorService) {

  }

  ngOnInit(): void {
    this.langpair = this.data.langpair;
    this.activity = this.data.activity;
    this.showPricing = this.data.showPricing;
    this.userId = this.data.userId;
    this.taskSpec = this.data.taskSpec;
    if (this.langpair && this.activity) {
      this.allVendors = this.vendorService.getCompetences(this.langpair, this.activity, this.userId);
      this.setFilteredVendors()
    }
  }

  selectVendor(event) {
    this.dialogRef.close(event)
  }

  setFilteredVendors() {
    this.filteredVendors = this.allVendors.pipe(
      map(v => filterVendors(v, this.filterText)))
  }
}

function filterVendors(vendors: Vendor[], text: string): Vendor[] {
  if (text) {
    return vendors.filter(v => {
      return v.name.trim().toLocaleLowerCase().indexOf(text.trim().toLocaleLowerCase()) >= 0
    });
  }
  return vendors;
}
