import {interval as observableInterval} from 'rxjs';
import {map} from 'rxjs/operators';
import {Component, Input, OnInit} from '@angular/core';


@Component({
  selector: 'countdown',
  template: `
    <div *ngIf="_seconds != undefined" [ngStyle]="{'color': _overdue ? 'orange' : 'var(--main-color)'}">
      <span *ngIf="_days > 0" style="color: inherit;">{{_days}} days, </span>
      <span *ngIf="_days > 0 || _hours > 0" style="color: inherit;">{{_hours}} hours and </span>
      <span style="color: inherit;">{{_minutes}} minutes</span>
      <span *ngIf="showsuffixlabel && _overdue && showoverdue" style="color: inherit;"> overdue</span>
      <span *ngIf="showsuffixlabel && !(_overdue && showoverdue)" style="color: inherit;"> left</span>
    </div>
  `,
})
export class CountdownComponent implements OnInit {

  @Input()
  enddate;
  @Input()
  showoverdue: boolean;
  @Input()
  showsuffixlabel: boolean;

  _diff: number;
  _days: number;
  _hours: number;
  _minutes: number;
  _seconds: number;
  _overdue: boolean;

  constructor() {
  }

  ngOnInit(): void {
    this._diff = this.getTimeDiff();
    this.calcTimers(this._diff);
    // Re-calculate every 30 seconds
    observableInterval(30000)
      .pipe(map(() =>
        this.getTimeDiff()
      ))
      .subscribe((diff) => {
        this._diff = diff;
        this.calcTimers(this._diff);
      });
  }

  private getTimeDiff(): number {
    return Date.parse(this.enddate) - Date.parse(new Date().toString());
  }

  private calcTimers(diff: number): void {
    this._overdue = diff < 0;
    if (this._overdue)
      diff = diff * -1;
    if (!this._overdue || this.showoverdue) {
      this._days = this.getDays(diff);
      this._hours = this.getHours(diff);
      this._minutes = this.getMinutes(diff);
      this._seconds = this.getSeconds(diff);
    } else {
      this._days = 0;
      this._hours = 0;
      this._minutes = 0;
      this._seconds = 0;
    }
  }

  getDays(t): number {
    return Math.floor(t / (1000 * 60 * 60 * 24));
  }

  getHours(t): number {
    return Math.floor((t / (1000 * 60 * 60)) % 24);
  }

  getMinutes(t): number {
    return Math.floor((t / 1000 / 60) % 60);
  }

  getSeconds(t): number {
    return Math.floor((t / 1000) % 60);
  }
}
