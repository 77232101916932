
      <div>
          <div>
              <h4 style="margin-bottom: 15px;">You are uploading the translated file for</h4>
              <div style="display: flex; flex-direction: column; align-items: center;">
                  <frontpage [pId]="task.projectId" [fileName]="taskFile.name"
                             [showIconIfUnavailable]="false"></frontpage>
                  <h4><strong>{{taskFile.name}}</strong></h4>
              </div>
          </div>
          <div *ngIf="!uploading && taskFilePath == null">
              <p style="margin-top: 15px;">Please make sure the file you upload is fully translated and that you took
                  all necessary guidelines into account.<br/>
                  After it is uploaded, the file will be marked as completed. When all files within your task are
                  complete, the task itself will become available for invoicing.</p>
              <p *ngIf="uploadError" style="color: red; margin-top: 25px;">
            <span *ngIf="uploadError == 'noxliff'">Oops, the file you tried to upload did not seem to be an XLIFF, with extension <strong>.xlf</strong>. <br/>
                Ensure you select the correct one and try again. If you do feel it is correct, contact us through the 'Can we help?' function on the lower right.
            </span>
                  <span *ngIf="uploadError == 'other'">Drat! There was an error uploading your file. <br/>
                Please try again, and if the problem persists, contact us through the 'Can we help?' function on the lower right.
            </span>
              </p>
              <div style="margin: 25px; display: flex; justify-content: space-evenly; align-items: center;">
                  <label for="uploadFile" class="lblUpload">Select file</label>
                  <input id="uploadFile" type="file" (change)="upload($event)" accept="{{expectedFileTypes}}"
                         placeholder="Upload" style="display: none;"/>
                  <button (click)="dialogRef.close()">Cancel</button>
              </div>
          </div>
          <div *ngIf="uploading" style="margin-top: 25px;">
              <p>Hang on while we validate your file...</p>
              <div style="width: 100%; text-align: center;"><img src="../../assets/loading.gif"
                                                                 style="width:100px; height:100px;"></div>
          </div>
          <div *ngIf="taskFilePath != null">
              <p style="margin-top: 25px;">Selected file:
                  <span style="color: var(--second-color); font-weight: bold;">{{selectedFile.name}}</span>
                  <span *ngIf="selectedFile.size" style="font-size: 12px;"> &nbsp;({{selectedFile.size | filesize}}
                      )</span></p>
              <p style="margin-top: 25px;">By continuing you are confirming that you have translated the file
                  completely and to the best of your ability.</p>
              <div style="margin: 25px; display: flex; justify-content: space-evenly; align-items: flex-start;">
                  <button (click)="confirmUpload()">Upload</button>
                  <button (click)="dialogRef.close()">Cancel</button>
              </div>
          </div>
      </div>