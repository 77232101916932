import {ConnectableObservable, Observable, Subject, throwError as observableThrowError} from 'rxjs';
import {catchError, filter, last, map, publish, tap} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {DomSanitizer} from '@angular/platform-browser';
import {Task} from "../dto/job";
import {ProjectService} from "./project.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {HttpClient, HttpEventType, HttpParams, HttpRequest} from "@angular/common/http";
import {IntervalObservable} from "rxjs/observable/IntervalObservable";
import {getBaseSocketUrl, getBaseUrl, normalizeFileName} from "../util/httpclient";
import {environment} from '../../environments/environment';


@Injectable()
export class FileService {

  public messages: Observable<any>;
  private ws: Subject<any>;

  private FILES_SOCKET_URL = getBaseSocketUrl() + "/files/feed";
  private FILES_API = getBaseUrl() + "/api/v1/files";


  constructor(private http: HttpClient,
              private sanitizer: DomSanitizer,
              private projectService: ProjectService,
              private snackbar: MatSnackBar) {
    console.debug("FileService initializing => connects to " + this.FILES_API);
  }

  initFileFeed() {
    this.ws = Observable.webSocket(this.FILES_SOCKET_URL);
    //Keep the socket alive (every 4 minutes, server timeout is set to 5 minutes)
    IntervalObservable.create(30000).subscribe(o =>
      this.ws.next({'action': 'keepalive'})
    );
    this.messages = this.makeHot(this.ws).pipe(filter(m => m != null));
    // this.messages.subscribe(msg => console.log("> Received " + JSON.stringify(msg) + " @" + this.FILES_SOCKET_URL),
    //   error => console.error(error));
    //Subscribe for the snackbar
    if (environment.isDebug) {
      this.messages.pipe(map(m => m.data),
        map(m => {
          let filename = m.filename;
          let langpair = m.langpair;
          let generatedFor = "";
          if (filename)
            generatedFor += " " + filename;
          if (langpair) {
            generatedFor += " " + langpair
          }
          return m.action + " for: " + generatedFor;
        }),)
        .subscribe(v => this.snackbar.open(v, '', {
          duration: 2000
        }));
    }
  }

  //For more info see analyses.service.ts
  makeHot<T>(cold: Observable<T>): Observable<T> {
    let obs = cold.pipe(publish()) as ConnectableObservable<T>;
    obs.connect();
    return obs;
  }

  enrollForProject(pId: string): Observable<any> {
    return this.enroll(pId)
      .map(msg => msg.data);
  }

  enrollForProjectAndFile(pId: string, fileName: string): Observable<any> {
    return this.enroll(pId).pipe(
      filter(msg => {
        return msg.filename === fileName;
      }),
      map(msg => msg.data));
  }

  private enroll(pId: string) {
    if (!this.ws || this.ws.closed)
      this.initFileFeed();
    //To enroll you have to send a message to the socket with the action "enroll" and to which objects you want to enroll
    this.ws.next({'action': 'enroll', 'pid': pId});
    //The JSON structure of the message normally has the objects parameters to identify and a "data" object which contains the actual information
    //E.g. {"pid":"10", "data":{"action":"PDF", "langname":"damocles.docx"}}
    return this.messages.pipe(filter(msg => {
      return msg.pid == pId;
    }));
  }

  uploadProjectFile(file, pId, type: string, extraParams: Map<string, any> = null): Observable<any> {
    let formData: FormData = new FormData();
    let fileName = normalizeFileName(file.name);
    formData.append('uploadFile', file, fileName);
    formData.append('title', fileName);
    let params: HttpParams = new HttpParams();
    if (type != null && type.length > 0)
      params = params.append("usage", type);
    // Add extra params if specified
    if (extraParams != null)
      extraParams.forEach((v, k) => params = params.append(k, v));
    return this.http.post(this.FILES_API + "/project/upload/" + pId, formData, {
      responseType: 'text',
      params: params
    });
  }

  uploadProjectFileWithProgress(file, pId, type: string, progress: Subject<number>, extraParams: Map<string, any> = null): Observable<any> {
    let formData: FormData = new FormData();
    let fileName = normalizeFileName(file.name);
    formData.append('uploadFile', file, fileName);
    formData.append('title', fileName);
    let params: HttpParams = new HttpParams();
    if (type != null && type.length > 0)
      params = params.append("usage", type);
    // Add extra params if specified
    if (extraParams != null)
      extraParams.forEach((v, k) => params = params.append(k, v));
    const req = new HttpRequest('POST', this.FILES_API + "/project/upload/" + pId, formData, {
      reportProgress: true,
      responseType: 'text',
      params: params
    });
    return this.http.request(req).pipe(
      map(event => {
        console.debug("Uploading file " + file.name + " =>", event);
        // Determine upload percentage based on the event
        if (event.type == HttpEventType.UploadProgress)
          return Math.round(100 * event.loaded / event.total);
        else if (event.type == HttpEventType.Response)
          return 100;
        else return -1;
      }),
      tap(percent => {
        // Signal the progress percentage to the subject
        if (percent >= 0) progress.next(percent)
      }),
      last(), // (simply returns last value)
      catchError(error => {
        console.error("Error during upload of file " + file.name + " => ", error);
        const msg = `${error.status} ${error.statusText} - ${error.url}`;
        return observableThrowError(new Error(msg))
      })
    );
  }

  getProfilePic(id: string): Observable<any> {
    return Observable.empty();
    /*return this.http.get(this.FILES_API + "/users/profile/" + id, {responseType: "blob"}).pipe(
      map(blob => {
        let urlCreator = window.URL;
        return this.sanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(blob));
      }));*/
  }

  uploadProfilePicture(file, userid): Observable<any> {
    let formData: FormData = new FormData();
    formData.append('uploadFile', file, normalizeFileName(file.name));
    formData.append('requestedname', 'profilepic.png');
    return this.http.post(this.FILES_API + "/users/profile/" + userid, formData, {responseType: 'text'})
  }

  getCompanyDocumentationList(id: string): Observable<any> {
    return this.fileContent('company', id, 'documentation')
  }

  uploadCompanyDocumentation(file, companyId): Observable<any> {
    let formData: FormData = new FormData();
    formData.append('uploadFile', file, normalizeFileName(file.name));
    return this.http.post(this.FILES_API + '/company/documentation/' + companyId, formData, {responseType: 'text'});
  }

  uploadFile(file: File, pId: string): Observable<any> {
    return this.uploadProjectFile(file, pId, null);
  }

  uploadDocFile(file: File, entityId: string, entityType: string = 'project'): Observable<any> {
    if (entityType === 'project') {
      return this.uploadProjectFile(file, entityId, 'documentation');
    } else if (entityType === 'company') {
      return this.uploadCompanyDocumentation(file, entityId);
    } else {
      throw Error('Entity type ' + entityType + ' is not recognized');
    }
  }

  // TODO check why delete cannot be used "CROSS-error"
  removeDocFile(filename: string, entityId: string, entityType: string): Observable<any> {
    const params = new HttpParams().set('filename', filename);
    return this.http.post(this.FILES_API + '/' + entityType + '/remove/documentation/' + entityId, undefined,
     {responseType: 'text', params: params})
  }

  uploadPdfDocFile(file: File, pId: string, source4pdf: string): Observable<any> {
    let extraParam = new Map<string, any>();
    extraParam.set("source4pdf", source4pdf);
    return this.uploadProjectFile(file, pId, "docpdf", extraParam);
  }

  uploadPDFForInvoice(invoice: any, file: File) {
    let formData: FormData = new FormData();
    formData.append('uploadFile', file, normalizeFileName(file.name));
    return this.http.post(this.FILES_API + "/invoice/upload/" + invoice.company.id + "/" + invoice.id, formData, {responseType: 'text'})
  }

  uploadTranslation(file: File, fileName: string, task: Task, processType: string) {
    let formData: FormData = new FormData();
    let transFileName = normalizeFileName(file.name);
    formData.append('uploadFile', file, transFileName);
    formData.append('title', transFileName);
    let extentionRegex = /(?:\.([^.]+))?$/;
    let params = new HttpParams().set("processType", processType)
      .set("extension", "." + extentionRegex.exec(transFileName)[1]);
    return this.http.post(this.FILES_API + "/task/upload/" + task.id + "/" + encodeURIComponent(fileName), formData, {
      responseType: 'text',
      params: params
    });
  }

  confirmTranslation(downloadPath: string, fileName: string, task: Task, processType: string) {
    return this.http.post(this.FILES_API + "/task/confirm/" + task.id + "/" + encodeURIComponent(fileName),
      downloadPath, {responseType: 'text'});
  }

  getFrontpage(pId, fileName: string): Observable<any> {
    return this.http.get(this.FILES_API + "/frontpage/" + pId + "/" + encodeURIComponent(fileName),
      {responseType: "blob"}).pipe(
      map(blob => {
        let urlCreator = window.URL;
        return this.sanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(blob));
      }));
  }

  downloadSource(pId: string, fileName: string) {
    return this.http.get(this.FILES_API + "/dl/project/" + pId + "/" + encodeURIComponent(fileName),
      {responseType: "blob"});
  }

  downloadDoc(entityId: string, fileName: string, type: string = 'project', ) {
    const params = new HttpParams().set("usage", "documentation");
    return this.http.get(this.FILES_API + '/dl/' + type + '/' + entityId + '/' + encodeURIComponent(fileName),
      {
        responseType: 'blob',
        params
      });
  }

  downloadXliff(fileName: string, task: Task): Observable<any> {
    return this.http.get(this.FILES_API + "/bestxliff/" + task.projectId + "/" +
      encodeURIComponent(task.sourcelanguage + "_" + task.targetlanguage) + "/" +
      encodeURIComponent(fileName),
      {responseType: "blob"});
  }

  downloadSDLXliff(fileName: string, task: Task): Observable<any> {
    return this.http.get(this.FILES_API + "/bestxliff/sdl/" + task.projectId + "/" +
      encodeURIComponent(task.sourcelanguage + "_" + task.targetlanguage) + "/" +
      encodeURIComponent(fileName),
      {responseType: "blob"});
  }

  downloadTMX(fileName: string, task: Task): Observable<any> {
    return this.http.get(this.FILES_API + "/tmx/" + task.projectId + "/" +
      encodeURIComponent(task.sourcelanguage + "_" + task.targetlanguage) + "/" +
      encodeURIComponent(fileName),
      {responseType: "blob"});
  }

  downloadPDF(fileName: string, task: Task): Observable<any> {
    return this.http.get(this.FILES_API + "/pdf/" + task.projectId + "/" + encodeURIComponent(fileName),
      {responseType: "blob"});
  }

  downloadSegHtml(projectId: any, fileName: any): Observable<any> {
    return this.http.get(this.FILES_API + "/seghtml/" + projectId + "/" + encodeURIComponent(fileName), {responseType: "blob"})
  }

  isSegHtmlAvailable(projectId: any, fileName: any): Observable<boolean> {
    return this.http.get(this.FILES_API + "/available/" + projectId + "/seg2html?filename=" + encodeURIComponent(fileName), {responseType: "json"}).pipe(
      map(l => <Array<any>>l),
      map(a => a.length > 0),)
  }

  downloadFinal(pId: string, langPair: string, fileName: string) {
    return this.http.get(this.FILES_API + "/final/" + pId + "/" + encodeURIComponent(langPair) + "/" + encodeURIComponent(fileName),
      {responseType: "blob"});
  }

  fetchAvailableFinals(pId: string, langPair: string) {
    return this.http.get(this.FILES_API + "/available/" + pId + "/" + encodeURIComponent(langPair) + "/2orig", {responseType: "json"})
  }

  downloadInvoicePDF(entityId, invoiceId) {
    return this.http.get(this.FILES_API + "/dl/invoice/" + entityId + "/" + invoiceId, {responseType: "blob"})
  }

  availableXliffs(task: Task) {
    return this.http.get(this.FILES_API + "/availablefortask/" + task.id, {responseType: "json"})
  }

  availablePdfs(task: Task) {
    return this.http.get(this.FILES_API + "/available/" + task.projectId + "/pdf", {responseType: "json"})
  }

  availableTMXs(task: Task) {
    let languagepair = task.sourcelanguage + "_" + task.targetlanguage;
    let params = new HttpParams().set("usage", "tmx");
    return this.http.get(this.FILES_API + "/available/" + task.projectId + "/" + encodeURIComponent(languagepair) + "/tm", {
      responseType: "json",
      params: params
    })
  }

  fileContent(type: string, id: string, subDir: string) {
    console.debug(type);
    let qryParams = (subDir != null ? "?subdir=" + encodeURIComponent(subDir) : "");
    return this.http.get(this.FILES_API + "/view/" + type.toLowerCase() + "/" + id + qryParams, {responseType: "json"})
  }

  download(type: string, id: string, subDir: string, fileName: string) {
    let qryParams = (subDir != null ? "?subdir=" + encodeURIComponent(subDir) : "");
    return this.http.get(this.FILES_API + "/admin/dl/" + type.toLowerCase() + "/" + id + "/" + encodeURIComponent(fileName) + qryParams,
      {responseType: "blob"});
  }

  fileExists(type: string, id: string, subDir: string, fileName: string) {
    let qryParams = (subDir != null ? "?subdir=" + encodeURIComponent(subDir) : "");
    return this.http.get(this.FILES_API + "/admin/check/" + type.toLowerCase() + "/" + id + "/" + encodeURIComponent(fileName) + qryParams,
      {responseType: "text"});
  }

  addFile(fileToAdd: File, type: string, id: string, subDir: string, confirmedReplace: boolean) {
    let formData: FormData = new FormData();
    formData.append('uploadFile', fileToAdd, fileToAdd.name);
    let params: HttpParams = new HttpParams();
    params = params.append("confirmedReplace", confirmedReplace ? "true" : "false");
    if (subDir != null && subDir.length > 0) {
      params = params.append("subDir", subDir);
    }
    return this.http.post(this.FILES_API + "/admin/add/" + type + "/" + id, formData, {
      responseType: 'text',
      params: params
    });
  }

  removeFile(files: any[], type: string, id: string, subDir: string) {
    let fileNamesToRemove = new Array();
    files.forEach(f => {
      fileNamesToRemove.push(f.name);
    });
    let params: HttpParams = new HttpParams();
    if (subDir != null && subDir.length > 0)
      params = params.append("subDir", subDir);
    return this.http.post(this.FILES_API + "/admin/remove/" + type + "/" + id, JSON.stringify(fileNamesToRemove), {
      responseType: 'text',
      params: params
    });
  }

  renameFile(newName: string, type: string, id: string, subDir: string, fileName: string) {
    let params: HttpParams = new HttpParams();
    if (subDir != null && subDir.length > 0)
      params = params.append("subDir", subDir);
    return this.http.post(this.FILES_API + "/admin/rename/" + type + "/" + id + "/" + encodeURIComponent(fileName), newName, {
      responseType: 'text',
      params: params
    });
  }

  createDir(folderName: string, type: string, id: string, subDir: string) {
    let params: HttpParams = new HttpParams();
    if (subDir != null && subDir.length > 0)
      params = params.append("subDir", subDir);
    return this.http.post(this.FILES_API + "/admin/createdir/" + type + "/" + id, folderName, {
      responseType: 'text',
      params: params
    });
  }
}
