
	  <mat-dialog-content class="projectDialog">
		  <h3>Unable to extract content for</h3>
		  <h3 style="margin-bottom: 15px;">{{file.name}}</h3>
		  <p>We were sadly not able to extract the content to be translated out of this file.
			  This implies that we can't run any analysis and that the translation will need to be done in the
			  original file format.<br/>
			  We would like to ask you to specify the number of words in the document (as accurately as you can).
		  </p>
		  <div style="margin: 15px 0; width: 100%;">
			  <div style="display: flex; justify-content: center;">
				  <label style="margin-right: 5px; padding: 3px; font-size: 15px;">Amount of words:</label>
				  <input type="number" step="1" [(ngModel)]="words"/>
			  </div>
		  </div>
		  <p>Sorry for this inconvenience, we are working on better algorithms to be able to
			  handle more file types.</p>
		  <div class="buttons">
		  <button class="confirm" (click)="save()" [disabled]="!enableSave()">Save & close</button>
			  <button class="cancel" (click)="dialogRef.close()">Cancel</button>
		  </div>
	  </mat-dialog-content>