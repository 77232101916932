/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../projectcomponent/project.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./hubspot-page.component.ngfactory";
import * as i3 from "./hubspot-page.component";
import * as i4 from "@angular/common";
import * as i5 from "../projectcomponent/util/language-selector.component.ngfactory";
import * as i6 from "../projectcomponent/util/language-selector.component";
import * as i7 from "../service/language.service";
import * as i8 from "./hubspot-project-create.component";
import * as i9 from "../service/user.service";
import * as i10 from "../service/hubspot.service";
import * as i11 from "@angular/router";
var styles_HubspotProjectCreateComponent = [i0.styles];
var RenderType_HubspotProjectCreateComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HubspotProjectCreateComponent, data: {} });
export { RenderType_HubspotProjectCreateComponent as RenderType_HubspotProjectCreateComponent };
function View_HubspotProjectCreateComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "hubspot-page", [], null, [[null, "onSelect"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onSelect" === en)) {
        var pd_0 = (_co.pageClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_HubspotPageComponent_0, i2.RenderType_HubspotPageComponent)), i1.ɵdid(2, 114688, null, 0, i3.HubspotPageComponent, [], { page: [0, "page"], allPages: [1, "allPages"] }, { onSelect: "onSelect" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.context.$implicit; var currVal_1 = _co.pages; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_HubspotProjectCreateComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HubspotProjectCreateComponent_3)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.$implicit.language && _v.context.$implicit.language.startsWith(_co.filterLanguage)); _ck(_v, 2, 0, currVal_0); }, null); }
function View_HubspotProjectCreateComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "boxing"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HubspotProjectCreateComponent_2)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.pages; _ck(_v, 2, 0, currVal_0); }, null); }
function View_HubspotProjectCreateComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["src", "../../assets/loading_small.gif"]], null, null, null, null, null))], null, null); }
function View_HubspotProjectCreateComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.submit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Create project"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HubspotProjectCreateComponent_5)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.creatingProject; _ck(_v, 4, 0, currVal_0); }, null); }
export function View_HubspotProjectCreateComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Translate your hubspot pages"])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "language-selector", [["title", "Language"]], null, [[null, "onLanguageSelect"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onLanguageSelect" === en)) {
        var pd_0 = (_co.languageSelected($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_LanguageSelectorComponent_0, i5.RenderType_LanguageSelectorComponent)), i1.ɵdid(5, 114688, null, 0, i6.LanguageSelectorComponent, [i7.LanguageService], { title: [0, "title"] }, { onLanguageSelect: "onLanguageSelect" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HubspotProjectCreateComponent_1)), i1.ɵdid(7, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HubspotProjectCreateComponent_4)), i1.ɵdid(9, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Language"; _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.pages; _ck(_v, 7, 0, currVal_1); var currVal_2 = (_co.selectedPages.length > 0); _ck(_v, 9, 0, currVal_2); }, null); }
export function View_HubspotProjectCreateComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "hubspot-project-create", [], null, null, null, View_HubspotProjectCreateComponent_0, RenderType_HubspotProjectCreateComponent)), i1.ɵdid(1, 114688, null, 0, i8.HubspotProjectCreateComponent, [i9.UserService, i10.HubspotService, i11.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HubspotProjectCreateComponentNgFactory = i1.ɵccf("hubspot-project-create", i8.HubspotProjectCreateComponent, View_HubspotProjectCreateComponent_Host_0, {}, {}, []);
export { HubspotProjectCreateComponentNgFactory as HubspotProjectCreateComponentNgFactory };
