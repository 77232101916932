import {filter, map, switchMap} from 'rxjs/operators';
import {ChangeDetectorRef, Component, Input} from "@angular/core";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {UserService} from "../service/user.service";
import {InvoiceService} from "../service/invoice.service";
import {TaskService} from "../service/task.service";
import {ProjectService} from "../service/project.service";


@Component({
  selector: "invoice-overview",
  templateUrl: './invoice.overview.component.html',
  styleUrls: ['./invoicing.component.css'],
})
export class InvoiceOverviewComponent {

  @Input("user")
  private userInput = null;

  public user;
  public company;

  public purchaseRegisteredInvoices;
  public invoiceableTaskCount;
  public salesRegisteredInvoices;
  public unpaidProjects: any[] = [];
  public openProjectAmount = 0.0;

  constructor(private route: ActivatedRoute,
              private router: Router,
              public userService: UserService,
              public invoiceService: InvoiceService,
              public projectService: ProjectService,
              public taskService: TaskService,
              private cdRef: ChangeDetectorRef) {
  }

  public ngOnInit(): void {
    this.salesRegisteredInvoices = [];
    if (this.userInput != null) {
      this.setUser(this.userInput);
    }
    this.route.params.pipe(
      filter(params => this.userInput === null && params['id'] != null),
      switchMap((params: Params) => this.userService.getUser(params['id']))
    ).subscribe(c => this.setUser(c));
  }

  private setUser(user: any): void {
    this.user = user;
    if (user.companyId != undefined) {
      this.userService.findCompanyInfo(user.companyId).subscribe(c => this.setCompany(c))
    }
    this.invoiceService.findRegisteredUserSalesInvoices(this.user.id).pipe(
      map(invs => invs as any[]))
      .subscribe(invs => {
          invs.forEach(inv => this.salesRegisteredInvoices.push(inv));
          this.cdRef.detectChanges();
        },
        error => {
          console.error("Error getting sales invoices", error);
        }
      );
    this.cdRef.detectChanges();
  }

  private setCompany(company: any): void {
    this.company = company;
    if (this.userService.isCurrentUserTranslator) {
      this.invoiceService.findRegisteredPurchaseInvoices(this.company.id)
        .subscribe(
          invs => {
            this.purchaseRegisteredInvoices = invs;
            this.cdRef.detectChanges();
          },
          error => console.error("Error getting purchase invoices", error)
        );
      this.taskService.getInvoiceableTasks().subscribe(tasks => {
          this.invoiceableTaskCount = tasks.length;
        this.cdRef.detectChanges();
        }
      );
    }
    this.invoiceService.findRegisteredCompanySalesInvoices(this.company.id).pipe(
      map(invs => invs as any[]))
      .subscribe(invs => {
          invs.forEach(inv => this.salesRegisteredInvoices.push(inv));
          this.cdRef.detectChanges();
        },
        error => {
          console.error("Error getting company sales invoices", error)
        }
      );
    this.projectService.getMyUnpaidProjects(this.company.id).subscribe(projects => {
        this.unpaidProjects = projects;
        this.openProjectAmount = 0;
        if (projects != null && projects.length > 0) {
          projects.forEach(p => {
            if (p.totalprice) {
              this.openProjectAmount += p.totalprice;
            }
          });
          this.cdRef.detectChanges();
        }
      },
      error => {
        console.error("Error getting unpaid projects", error);
      }
    );
  }

  public isTranslator(): boolean {
    return this.userService.isCurrentUserTranslator;
  }

  public openInvoice(inv): void {
    this.router.navigate(['/invoice', inv.id]);
  }

  public openNewInvoice(): void {
    this.router.navigate(['/invoice', 'new'])
  }

  public gotoProject(id: number): void {
    this.router.navigate(['/pdetail', id]);
  }
}
