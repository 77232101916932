import {filter, map, publish} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {Project} from "../dto/project";
import {ConnectableObservable, Observable, Subject} from "rxjs";
import "rxjs";
import {HttpClient} from "@angular/common/http";
import {IntervalObservable} from "rxjs/observable/IntervalObservable";
import {Pricing} from "../dto/job";
import {getBaseSocketUrl, getBaseUrl, normalizeFileName} from "../util/httpclient";
import {ServiceParam} from '../mock-activities';


@Injectable()
export class ProjectService {
  PROJECT_API: string = getBaseUrl() + "/api/v1/projects";
  private PROJECT_SOCKET_URL = getBaseSocketUrl() + "/projects/feed";

  public messages: Observable<any>;
  private ws: Subject<any>;


  constructor(private http: HttpClient) {
    console.debug("ProjectService initializing => connects to " + this.PROJECT_API);
  }

  initProjectFeed() {
    this.ws = Observable.webSocket(this.PROJECT_SOCKET_URL);
    //Keep the socket alive (every 4 minutes, server timeout is set to 5 minutes)
    IntervalObservable.create(30000).subscribe(o =>
      this.ws.next({'action': 'keepalive'})
    );
    this.messages = this.makeHot(this.ws).pipe(filter(m => m != null));
    this.messages.subscribe(msg => {
      },
      /*msg => console.log("> Received " + JSON.stringify(msg) + " @" + this.PROJECT_SOCKET_URL),*/
      error => console.error("Error receiving project messages", error));
  }

  //For more info see analyses.service.ts
  makeHot<T>(cold: Observable<T>): Observable<T> {
    let obs = cold.pipe(publish()) as ConnectableObservable<T>;
    obs.connect();
    return obs;
  }

  enrollForProject(pId: string): Observable<any> {
    return this.enroll(pId)
      .map(msg => msg.data);
  }

  enrollForProjectAndFile(pId: string, fileName: string): Observable<any> {
    return this.enrollForProject(pId).pipe(
      filter(data => {
        return data.filename === fileName;
      }));
  }

  private enroll(pId: string) {
    if (!this.ws || this.ws.closed)
      this.initProjectFeed();
    //To enroll you have to send a message to the socket with the action "enroll" and to which objects you want to enroll
    this.ws.next({'action': 'enroll', 'pid': pId});
    //The JSON structure of the message normally has the objects parameters to identify and a "data" object which contains the actual information
    //E.g. {"pid":"10", "data":{"action":"PDF", "langname":"damocles.docx"}}
    return this.messages.pipe(filter(msg => {
      // console.log("PROJECT MSG RECEIVED", msg);
      return msg.pid == pId;
    }));
  }

  getQuickAccessProjects(limit: number, min: number): Observable<Project[]> {
    return this.http.get<Project[]>(this.PROJECT_API + "/quickaccess?limit=" + limit + "&min=" + min);
  }

  getMyProjects(): Observable<Project[]> {
    return this.http.get<Project[]>(this.PROJECT_API);
  }

  getMyProjectsWithStatus(statuses: string[]): Observable<Project[]> {
    if (statuses != null && statuses.length > 0) {
      let url = this.PROJECT_API + "?" + statuses.map(s => "status=" + encodeURIComponent(s)).join("&");
      return this.http.get<Project[]>(url);
    } else return this.getMyProjects();
  }

  getMyUnpaidProjects(companyId): Observable<Project[]> {
    return this.http.get<Project[]>(this.PROJECT_API + "/find/unpaid/" + companyId);
  }

  getProject(id: string): Observable<Project> {
    return this.http.get<Project>(this.PROJECT_API + "/" + id);
  }

  createProject(p: Project, ip: string): Observable<any> {
    let qryParams = (ip != null ? "?uip=" + ip : "");
    return this.http.post(this.PROJECT_API + "/insert" + qryParams, JSON.stringify(p), {responseType: 'json'});
  }

  initProject(p: Project, ip: string): Observable<any> {
    let qryParams = (ip != null ? "?uip=" + ip : "");
    return this.http.post(this.PROJECT_API + "/init" + qryParams, JSON.stringify(p), {responseType: 'json'});
  }

  draftProject(p: string, ip: string): Observable<any> {
    let qryParams = (ip != null ? "?uip=" + ip : "");
    return this.http.post(this.PROJECT_API + "/draft/" + p + qryParams, JSON.stringify(p), {responseType: 'json'});
  }

  cancelProject(p: Project): Observable<any> {
    return this.http.post(this.PROJECT_API + "/cancel/" + p.id, "", {responseType: 'text'});
  }

  startProject(p: Project, user: string, pricingElement: Pricing, saveSharesAsDefault: boolean, ip: string): Observable<any> {
    let qryParams = (ip != null ? "?uip=" + ip : "");
    // Note that the price sent along here will be the 'totalprice' stored on the project
    let body = "{\"project\":" + JSON.stringify(p) +
      ", \"requestedby\":" + JSON.stringify(user) +
      ", \"totalprice\":" + JSON.stringify(pricingElement.totalIncVat().toFixed(2)) +
      ", \"pricingElement\":" + JSON.stringify(pricingElement) +
      ", \"saveDefaultShares\":" + JSON.stringify(saveSharesAsDefault) + "}";
    return this.http.post(this.PROJECT_API + "/start/" + p.id + qryParams, body, {responseType: 'json'});
  }

  addSourceFileData(file: string, pId: string): Observable<any> {
    return this.http.post(this.PROJECT_API + "/add/sourcefile/" + pId, normalizeFileName(file), {responseType: 'json'})
  }

  addSourceFilesData(file: string[], pId: string): Observable<any> {
    let fileArray: string[] = [];
    file.forEach(fn => {
      fileArray.push(normalizeFileName(fn));
    });
    return this.http.post(this.PROJECT_API + "/add/sourcefiles/" + pId, JSON.stringify(fileArray), {responseType: 'json'})
  }

  addDocFileData(file: string, source4pdf: string, pId: string): Observable<any> {
    let body = "{" +
      "\"file\": " + JSON.stringify(normalizeFileName(file)) +
      (source4pdf != null ? ", \"source4pdf\": " + JSON.stringify(source4pdf) : "") +
      "}";
    return this.http.post(this.PROJECT_API + "/add/docfile/" + pId, body, {responseType: 'json'})
  }

  removeSourceFile(fileName: string, pId: string): Observable<any> {
    return this.http.post(this.PROJECT_API + "/remove/sourcefile/" + pId + "/" + encodeURIComponent(fileName), "",
      {responseType: "text"})
  }

  removeDocFile(fileName: string, pId: string) {
    return this.http.post(this.PROJECT_API + "/remove/docfile/" + pId + "/" + encodeURIComponent(fileName), "",
      {responseType: "text"})
  }

  setService(project: Project): Observable<any> {
    return this.http.post(this.PROJECT_API + '/setservice/' + project.id, project.service,
    {responseType : 'text'})
  }

  addLangPairData(serviceParam: ServiceParam, pId: string): Observable<any> {
    return this.http.post(this.PROJECT_API + "/add/serviceparam/" + pId,
      JSON.stringify(serviceParam), {responseType: 'json'});
  }

  removeLangPair(pId: string, languagePair: string) {
    return this.http.post(this.PROJECT_API + "/remove/langpair/" + pId + "/" + encodeURIComponent(languagePair), "",
      {responseType: "text"})
  }

  getFiles(p: Project, type: string): any[] {
    if (p && p.files) {
      return p.files.filter(v => {
        return type != null ? v.type === type : true;
      });
    }
    return [];
  }

  getSourceLanguage(project: Project): string {
    if (project != null) {
      if (project.langpairs != null && project.langpairs.length > 0) {
        return project.langpairs[0].source;
      }
      if (project.files != null && project.files.length > 0) {
        let sourceLang: string;
        project.files
          .filter(f => f.sourcelang != null)
          .forEach(f => {
            sourceLang =  f.sourcelang;
          });
        return sourceLang;
      }
    }
    return undefined;
  }

  getSourceLanguageObs(project: Project): Observable<string> {
    return Observable.of(this.getSourceLanguage(project));
  }

  getTargetLanguages(project: Project): string[] {
    let targets = [];
    if (project && project.langpairs) {
      project.langpairs.map(lp => lp.target).filter(t => targets.indexOf(t) === -1).forEach(t => {
        if (targets.indexOf(t) === -1)
          targets.push(t);
      });
    }
    return targets;
  }

  getTargetLanguagesObs(project: Project): Observable<string[]> {
    return Observable.of(this.getTargetLanguages(project))
  }

  getFileNames(project: Project, type: string): string[] {
    return this.getFiles(project, type).map(f => f.name);
  }

  getProjectBaseInfo(projectId: string) {
    return this.http.get(this.PROJECT_API + "/baseinfo/" + projectId)
  }

  setTitle(project: Project) {
    return this.http.post(this.PROJECT_API + "/settitle/" + project.id, project.title, {responseType: 'text'})
  }

  setDueDate(project: Project) {
    return this.http.post(this.PROJECT_API + "/setduedate/" + project.id, (<Date>project.duedate).toISOString(), {responseType: 'text'})
  }

  setShares(pId: string, projectShares: any[]) {
    return this.http.post(this.PROJECT_API + '/setshares/' + pId, JSON.stringify(projectShares), {responseType: 'text'})
  }

  setFileLanguage(pId: string, fileName, lang) {
    return this.http.post(this.PROJECT_API + "/files/updatelang/" + pId + "/" + encodeURIComponent(fileName), lang, {responseType: 'text'})
  }

  setFileWordCount(pId: string, fileName, wc: number) {
    return this.http.post(this.PROJECT_API + "/files/updatewordcount/" + pId + "/" + encodeURIComponent(fileName) + "/" + wc, "", {responseType: 'text'})
  }

  setFileProcessType(pId: string, filename: string, processType: string): Observable<string> {
    return this.http.post(this.PROJECT_API + '/files/updateprocesstype/' + pId + '/' + encodeURIComponent(filename),
      processType, {responseType: 'text'})
  }

  setAltFileTypeAsked(pId: string, filename: string): Observable<string> {
    return this.http.post(this.PROJECT_API + '/files/altfiletype/asked/' + pId + '/' + encodeURIComponent(filename),
      true,
      {responseType: 'text'}
    )
  }

  setRequestedBy(project: Project, currentUser) {
    return this.http.post(this.PROJECT_API + "/setrequestedby/" + project.id, {userId: currentUser.id}, {responseType: 'text'})
  }

  getFileProgress(pId: string, fileName: string): Observable<any> {
    return this.http.get(this.PROJECT_API + "/files/progress/" + pId + "/" + encodeURIComponent(fileName), {responseType: 'json'});
  }

  setMtEnabledForProject(pId: string, enabled: boolean) {
    return this.http.post(this.PROJECT_API + "/updatemtenabled/" + pId,
      enabled, {responseType: 'text'})
  }

  setPaymentMethod(project: Project, value: string): Observable<any>{
    return this.http.post(this.PROJECT_API + '/updatepaymentmethod/' +  project.id, value, {responseType: 'text'});
  }


  calculateProposedDueDate(projectId: string): Observable<any> {
    return this.http.get(this.PROJECT_API + "/calc/duration/" + projectId, {responseType: 'json'}).pipe(
      map(d => <number>d),
      map(d => d + 24),)
  }

  getAllProjects(status: string): Observable<Project[]> {
    let qryParams = (status != null ? "?status=" + encodeURIComponent(status) : "");
    return this.http.get<Project[]>(this.PROJECT_API + "/admin/find" + qryParams);
  }

  getProjectsForCleanup(olderThanMonths: number, status: string): Observable<Project[]> {
    let qryParams = "?otmnths=" + encodeURIComponent(olderThanMonths);
    qryParams += "&status=" + encodeURIComponent(status);
    return this.http.get<Project[]>(this.PROJECT_API + "/admin/cleanup" + qryParams);
  }

  cleanupProjects(olderThanMonths: number, status: string): Observable<number> {
    let qryParams = "?otmnths=" + encodeURIComponent(olderThanMonths);
    qryParams += "&status=" + encodeURIComponent(status);
    return this.http.delete<number>(this.PROJECT_API + "/admin/cleanup" + qryParams);
  }
}
