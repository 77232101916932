import {Component, EventEmitter, Input, Output} from "@angular/core";
import {TaskService} from "../../service/task.service";
import {switchMap, tap} from "rxjs/operators";
import {PricingService} from "../../service/pricing.services";
import {Task} from "../../dto/job";
import {UserService} from "../../service/user.service";
import {isTranslationTask} from "../../util/jobutil";
import {Router} from '@angular/router';


@Component({
  selector: 'project-task',
  templateUrl: './project-task.component.html',
  styleUrls: ['../project.component.css'],
})
export class ProjectTaskComponent {

  @Input()
  task: Task;
  @Input()
  isQuote = false;
  @Output()
  taskChangeEmitter = new EventEmitter<any>();
  @Input()
  state = undefined;
  user = undefined;
  mtCost = undefined;
  serviceFee = undefined;

  constructor(private userService: UserService,
              private taskService: TaskService,
              private pricingService: PricingService,
              private router: Router) {

  }

  ngOnInit() {
    this.loadProfilePic();
    if (this.state == null) {
      this.initTaskState();
    }
    this.initFixedCosts();
  }

  initTaskState() {
    if (this.task === undefined) {
      this.state = undefined;
      return;
    }
    if (this.task.completed !== undefined) {
      this.state = 'completed';
      return;
    }
    if (this.task.acceptdate !== undefined) {
      this.state = 'accepted';
      return;
    }
    if (this.task.rejectdate !== undefined) {
      this.state = 'rejected';
      return;
    }
    if (this.task.counterProposal !== undefined) {
      this.state = 'counterproposed';
      return;
    }
    if (this.task.senddate !== undefined) {
      this.state = 'send';
      return;
    }
  }

  getPrice() {
    let price = 0;
    if (this.task.price != null) {
      price = this.task.price;
    }
    if (this.mtCost != null) {
      price = +price + +this.mtCost;
    }
    if (this.serviceFee != null) {
      price = +price + +this.serviceFee;
    }
    return price;
  }

  getProposalPrice() {
    let price = this.getPrice();
    if(this.task.counterProposal.extraCost != null){
      price = +price + +this.task.counterProposal.extraCost;
    }
    return price;
  }

  loadProfilePic() {
    if (this.task !== undefined && this.task.vendorId !== undefined) {
      this.userService.getUser(this.task.vendorId)
        .subscribe(next => {
            this.user = next;
          }, error => this.user = undefined
        );
    } else {
      this.user = undefined;
    }
  }

  acceptCounterProposal() {
    this.taskService.acceptProposalTask(this.task)
      .pipe(
        switchMap(t => this.taskService.getTask(this.task.id)),
        tap(t => this.task = t),
        tap(t => this.initTaskState())
      )
      .subscribe(t =>
        this.taskChangeEmitter.emit("accept")
      );
  }

  removeTask() {
    this.taskChangeEmitter.emit('cancel')
  }

  private initFixedCosts() {
    if (isTranslationTask(this.task)) {
      const langpair = {source: this.task.sourcelanguage, target: this.task.targetlanguage};
      this.pricingService.getServiceFeeAndMTCost(this.task.projectId, langpair)
        .subscribe(c => {
          this.mtCost = c['mtCost'];
          this.serviceFee = c['serviceFee'];
        });
    }
  }

  rateTranslator() {
    this.router.navigate(['/newreview', this.task.id], {queryParams: {callback: this.router.url}});
  }
}
