
    <div style="display: flex; align-items: flex-start;">
      <div style="display: flex;" *ngIf="selectedParameters.getService()==='Translation'">
        <div class="langpairSelect">
          <h3>from </h3>
          <lang-select [single]="true" [selectedLangs]="selectedParameters['sourceLanguage']" [dialect]="false"
                (eventEmitter)="onSourceLanguageUpdate($event)" #sourcelangselect></lang-select>
          <h3> to </h3>
        </div>
        <lang-select [selectedLangs]="selectedParameters['targetLanguages']" (eventEmitter)="onTargetLanguageUpdate($event)"
              #targetlangselect></lang-select>
      </div>
      <div style="display: flex;" *ngIf="selectedParameters.getService()==='Editing'">
        <div class="langpairSelect">
          <h3>Language </h3>
          <lang-select [single]="true" [selectedLangs]="selectedParameters['sourceLanguage']" [dialect]="true"
                (eventEmitter)="onSourceLanguageUpdate($event)" #sourcelangselect></lang-select>
        </div>
      </div>
		<button *ngIf="hasChanges"
				[disabled]="!selectedParameters.isValid()"
				class="nextButton" (click)="emitParameters()">Next
		</button>
	  </div>