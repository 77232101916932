import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function scrollToBottom(elementId: string) {
  Observable.create(obs => {
    setTimeout(() => {
      obs.next(document.getElementById(elementId))
    }, 250)
  })
    .pipe(
      map(s => document.getElementById(elementId)),
    )
    .subscribe(element => {
      if (element != undefined) {
        element.scrollTop = element.scrollHeight;
      }
    });
}

export function existsAndNotEmpty(value: any) {
  return value != undefined && true && value.length > 0;
}
