import {Component, Input, OnChanges, OnInit} from "@angular/core";
import {TaskService} from "../service/task.service";
import {UserService} from "../service/user.service";
import * as FileSaver from "file-saver";
import {InvoiceService} from "../service/invoice.service";
import {FileService} from "../service/file.service";
import {Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: "purchase-invoice",
  templateUrl: './purchase-invoice.component.html',
  styleUrls: ['./invoicing.component.css'],
})
export class PurchaseInvoiceComponent implements OnInit, OnChanges {

  @Input()
  public invoice: any;
  @Input()
  public user: any;
  @Input()
  public company: any;

  public isExisting = false;
  public tasks: any[];
  public selectedTasks: any[] = [];
  public totalInvoiceAmount: number = 0.0;
  public totalInvitationReward: number = 0.0;
  public invoicePdfName: string;
  public invoicePdf: any;

  private includeInvitationReward = false;
  private invitationRewards: any[];

  constructor(private router: Router,
              private snackbar: MatSnackBar,
              private taskService: TaskService,
              private invoiceService: InvoiceService,
              private fileService: FileService,
              public userService: UserService) {
  }

  public ngOnInit(): void {
    this.init();
  }

  public ngOnChanges(): void {
    this.init();
  }

  private init(): void {
    this.isExisting = this.invoice != null && this.invoice.id != null;
    if (this.isExisting) {
      this.totalInvoiceAmount = this.invoice.total;
      // The tasks are fetched through the invoice details
      this.taskService.findTaskForInvoice(this.invoice.id).subscribe(tasks => {
        // For registered invoices the tasks are always all the selected tasks
        this.tasks = tasks;
        this.selectedTasks = tasks;
      });
      this.invitationRewards = this.invoice.rewards;
      this.calculateInvitationTotalRewards()
    } else {
      this.invoice = {};
      this.invoice.type = "PURCHASE";
      this.taskService.getInvoiceableTasks().subscribe(tasks => {
          this.tasks = tasks;
        }
      );
      this.userService.getTotalPendingInviteRewards().subscribe(rewards => {
        this.invitationRewards = rewards;
        this.calculateInvitationTotalRewards()
      });
    }
  }

  public selectionChange(tasks): void {
    if (!this.isExisting) {
      this.selectedTasks = tasks.selectedOptions.selected.map(item => item.value);
      this.calculateTotal()
    }
  }

  private calculateTotal(): void {
    if (!this.isExisting) {
      this.totalInvoiceAmount = 0.0;
      this.selectedTasks.map(t => parseFloat("" + t.price)).forEach(p => {
        this.totalInvoiceAmount += p;
      });
      if (this.includeInvitationReward) {
        this.totalInvoiceAmount += this.totalInvitationReward;
      }
    }
  }

  private calculateInvitationTotalRewards(): void {
    this.totalInvitationReward = 0.0;
    if (this.invitationRewards != undefined) {
      this.invitationRewards.map(reward => reward.rewardAmount)
        .forEach(rewardAmount => this.totalInvitationReward = this.totalInvitationReward += rewardAmount);
    }
  }

  public allDataComplete(): boolean {
    return this.selectedTasks.length > 0 && this.invoice.number && this.invoice.duedate && this.invoice.date && this.invoicePdf != null;
  }

  public setInvoicePDFFile(event): void {
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      this.invoicePdf = fileList[0];
    }
  }

  public downloadInvoicePDF(): void {
    let entityId = this.getInvoiceEntityId();
    this.fileService.downloadInvoicePDF(entityId, this.invoice.id).subscribe(
      n => {
        FileSaver.saveAs(n, this.invoice.number + ".pdf", true);
      }
    );
  }

  private getInvoiceEntityId(): string {
    if (this.company != null && this.company.id != null)
      return this.company.id;
    return this.user.id;
  }

  public registerInvoice(): void {
    this.invoice.total = this.totalInvoiceAmount;
    let rewards = [];
    if (this.includeInvitationReward)
      rewards = this.invitationRewards;
    this.invoiceService.registerInvoice(this.invoice, this.selectedTasks, rewards, this.company).subscribe(invoiceId => {
        this.invoice.id = invoiceId;
        this.fileService.uploadPDFForInvoice(this.invoice, this.invoicePdf).subscribe(
          v => this.snackbar.open("PDF uploaded", "", {
            duration: 2000
          })
        );
        this.snackbar.open("Invoice registered", "", {
          duration: 2000
        });
        this.router.navigate(['/invoice', this.invoice.id]);
      }
    );
  }
}
