<div class="main">
  <div class="tasklist">
    <h3>Unaccepted tasks</h3>
    <task-list
      [tasks]="unacceptedTask"
    ></task-list>
    <div *ngIf="!hasUnacceptedTask" class="tasklistempty">
      <h4>You have no tasks waiting to be accepted.</h4>
      <p>You'll get an e-mail notification when a task comes in.</p>
    </div>
    <div *ngIf="hasUnacceptedTask">
      <p class="taskwarn">
        Try to keep your list of unaccepted tasks as small as possible. Your
        responsiveness is rated and shown to the customer.
      </p>
    </div>
  </div>
  <div class="tasklist">
    <h3>Accepted tasks</h3>
    <task-list
      [tasks]="acceptedTask"
    ></task-list>
    <div *ngIf="!hasAcceptedTask" class="tasklistempty">
      <h4>You have no accepted tasks.</h4>
    </div>
  </div>
  <div class="tasklist">
    <h3>Completed tasks</h3>
    <task-list
      [tasks]="completedTask"
    ></task-list>
    <div *ngIf="!hasCompletedTask" class="tasklistempty">
      <h4>You have no completed tasks.</h4>
    </div>
  </div>
</div>
