/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./user.settings.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./invite.setting.component";
import * as i4 from "../service/user.service";
var styles_InviteSettingComponent = [i0.styles];
var RenderType_InviteSettingComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InviteSettingComponent, data: {} });
export { RenderType_InviteSettingComponent as RenderType_InviteSettingComponent };
function View_InviteSettingComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Loading "]))], null, null); }
function View_InviteSettingComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" You do not have any invites yet, please use the invite button at the top to send some. "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["More info on invites."]))], null, null); }
function View_InviteSettingComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.sendDate(_v.parent.context.$implicit); _ck(_v, 1, 0, currVal_0); }); }
function View_InviteSettingComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "inviteRow"], ["style", "display: flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InviteSettingComponent_5)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""]))], function (_ck, _v) { var currVal_1 = (_v.context.$implicit.senddate != undefined); _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.address; _ck(_v, 2, 0, currVal_0); var currVal_2 = _co.inviteState(_v.context.$implicit); _ck(_v, 7, 0, currVal_2); }); }
function View_InviteSettingComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "inviteHead"], ["style", "display: flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Send to"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Send on"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["State"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InviteSettingComponent_4)), i1.ɵdid(9, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.invites; _ck(_v, 9, 0, currVal_0); }, null); }
export function View_InviteSettingComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Invites"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InviteSettingComponent_1)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InviteSettingComponent_2)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InviteSettingComponent_3)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.invites == undefined); _ck(_v, 4, 0, currVal_0); var currVal_1 = ((_co.invites != undefined) && (_co.invites.length == 0)); _ck(_v, 6, 0, currVal_1); var currVal_2 = ((_co.invites != undefined) && (_co.invites.length > 0)); _ck(_v, 8, 0, currVal_2); }, null); }
export function View_InviteSettingComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "invite-settings", [], null, null, null, View_InviteSettingComponent_0, RenderType_InviteSettingComponent)), i1.ɵdid(1, 114688, null, 0, i3.InviteSettingComponent, [i4.UserService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InviteSettingComponentNgFactory = i1.ɵccf("invite-settings", i3.InviteSettingComponent, View_InviteSettingComponent_Host_0, { user: "user" }, {}, []);
export { InviteSettingComponentNgFactory as InviteSettingComponentNgFactory };
