<div class="langSelectMain">
  <div style="display: flex; flex-wrap: wrap;">
    <div *ngFor="let lang of selectedLangs" class="languageButton">
      <div (click)="removeLanguage(lang)" class="languageDiv">
        <p>{{lang|languagePipe|async}}</p><div class="remove">×</div></div>
    </div>
  </div>
  <div *ngIf="selectedLangs.length === 0 || single === false"
       [ngClass]="{ 'languageButton': true, 'openlangpick': true, 'langButtonPulse': doPulse() }">
    <div (click)="openLanguageChooser()" class="languageChooseDiv">
      <div *ngIf="single === false">
        <p *ngIf="selectedLangs.length === 0">choose language(s)</p>
        <p *ngIf="selectedLangs.length > 0">add language(s)</p>
      </div>
      <div *ngIf="single === true && (selectedLangs === undefined || selectedLangs.length === 0)">
        <p>choose language</p>
      </div>
    </div>
    <div *ngIf="langDialogOpen" class="languageListDialog">
      <lang-select-list style="flex: 1 1 auto;"
                        [selectedLangs]="selectedLangs" [dialect]="dialect"
                        (eventEmitter)="onLanguageUpdate($event)"></lang-select-list>
      <div style="flex: 0 0 auto; padding: 2px 0px 2px 15px;">
        <span (click)="closeLanguageChooser()" style="cursor: pointer;" title="Close">×</span>
      </div>
    </div>
  </div>
</div>
