import {from as observableFrom, Subject} from 'rxjs';
import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild, Input} from "@angular/core";
import {ProjectService} from "../../../service/project.service";
import {FileService} from "../../../service/file.service";


@Component({
  selector: 'documentation-list',
  templateUrl: './documentation-list.component.html',
  styleUrls: ['../../project.component.css'],
})
export class DocumentationListComponent implements OnInit {

  @Input()
  files = [];
  @Input()
  allowRemoval = false;
  @Input()
  entityId: string = undefined;
  @Input()
  entityType: string = 'project';

  @Output()
  fileRemovalEmmit = new EventEmitter<string>()

  constructor(private projectService: ProjectService,
              private fileService: FileService,
              ) {
  }

  ngOnInit() {

  }

  removeFile(file: File) {
    this.files.splice(this.files.indexOf(file), 1);
    this.fileRemovalEmmit.emit(file.name);
    if (this.entityType === 'project') {
      this.projectService.removeDocFile(file.name, this.entityId).subscribe(v => {
        // Do nothing
      });
    } else {
      this.fileService.removeDocFile(file.name, this.entityId, this.entityType).subscribe(v => console.debug(v));
    }
  }



  removeDocFile(docFileName: string){

   }
}
